import styled from 'styled-components';
import {
  BREAKPOINT_TABLET,
  BREAKPOINT_PHONE,
  BREAKPOINT_SMALL_PHONE,
} from '../constants/constants';
import { MouseEventHandler } from 'react';

export interface BaseProps {
  $cursor?: 'pointer';
  onClick?: MouseEventHandler<HTMLDivElement>;
  as?: any;
  $wordBreak?: 'break-word';
  $whiteSpace?: 'normal' | 'pre-line' | 'nowrap' | 'pre' | 'pre-wrap' | 'inherit';
  $color?: string;
  $withTextOverflow?: boolean;
  $isFullWidth?: boolean;
  $textAlign?: 'center' | 'left' | 'right';
  $fontWeight?: '300' | '400' | '500' | '600' | '700' | '800';
  $pL?: string;
  $pR?: string;
  $pT?: string;
  $pB?: string;
  $mL?: string;
  $mR?: string;
  $mT?: string;
  $mB?: string;
  $textStyle?:
    | 'p10'
    | 'p12'
    | 'p13'
    | 'p14'
    | 'p15'
    | 'p16'
    | 'p18'
    | 'p20'
    | 'p24'
    | 'p36'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4';
  $textStyleTablet?: string;
  $textStyleMobile?: string;
  $letterSpacing?: string;
  $isCenter?: boolean;
  $maxW?: string;
  $minW?: string;
  $height?: string;
}

export const BaseStyled = styled.div<BaseProps>`
  width: ${(props) => (props.$isFullWidth ? '100%' : 'auto')};
  color: ${(props) => props.$color};
  word-break: ${(props) => props.$wordBreak};
  white-space: ${(props) => props.$whiteSpace};
  text-align: ${(props) => props.$textAlign};
  font-weight: ${(props) => props.$fontWeight};

  padding-left: ${(props) => props.$pL};
  padding-right: ${(props) => props.$pR};
  padding-top: ${(props) => props.$pT};
  padding-bottom: ${(props) => props.$pB};

  margin-left: ${(props) => props.$mL};
  margin-right: ${(props) => props.$mR};
  margin-top: ${(props) => props.$mT};
  margin-bottom: ${(props) => props.$mB};
  white-space: ${(props) => props.$whiteSpace};
  letter-spacing: ${(props) => props.$letterSpacing};

  max-width: ${(props) => props.$maxW};
  min-width: ${(props) => props.$minW};
  cursor: ${({ $cursor }) => $cursor};

  ${(props) => props.$isCenter && 'margin-left: auto; margin-right: auto;'}

  ${(props) =>
    props.$withTextOverflow &&
    `
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
  `}

  ${(props) =>
    props.$textStyle === 'p10' &&
    `
      font-size: 10px;
      font-weight: 500;
      line-height: 150%;
  `}
  
  ${(props) =>
    props.$textStyle === 'p12' &&
    `
    font-size: 12px;
    line-height: 1.35;
  `}
  
  ${(props) =>
    props.$textStyle === 'p13' &&
    `
    font-size: 13px;
    line-height: 1.5;
  `}
  
  ${(props) =>
    props.$textStyle === 'p14' &&
    `
    font-size: 14px;
    line-height: 1.35;
  `}

  ${(props) =>
    props.$textStyle === 'p16' &&
    `
    font-size: 16px;
    line-height: 1.35;
  `}

  ${(props) =>
    props.$textStyle === 'p15' &&
    `
    font-size: 15px;
    line-height: 1.5;

       
    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      font-size: 13px;
    }
  `}
  
  ${(props) =>
    props.$textStyle === 'p18' &&
    `
    font-size: 18px;
    line-height: 1.5;
    
    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      font-size: 15px;
    }
    
    @media screen and (max-width: ${BREAKPOINT_SMALL_PHONE}) {
      font-size: 14px;
    }
  `}
  
  ${(props) =>
    props.$textStyle === 'p20' &&
    `
    font-size: 20px;
    line-height: 1.5;
    
    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      font-size: 18px;
    }
  `}

  ${(props) =>
    props.$textStyle === 'p24' &&
    `
    font-size: 24px;
    line-height: 36px;
    
    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      font-size: 15px;
      line-height: 23px;
    }
    
    @media screen and (max-width: ${BREAKPOINT_SMALL_PHONE}) {
      font-size: 15px;
      line-height: 22px;
    }
  `}

  ${(props) =>
    props.$textStyle === 'p36' &&
    `
    font-size: 36px;
    font-weight: 900;
    line-height: 1.35;
    margin-bottom: 30px;
    
    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      margin-bottom: 14px;
    }
    `}

  ${(props) =>
    props.$textStyle === 'h1' &&
    `
    font-size: 56px;
    font-weight: 900;
    line-height: 68px;
    margin-bottom: 30px;
    
    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      font-size: 46px;
      line-height: 56px;
    }
    
    @media screen and (max-width: ${BREAKPOINT_SMALL_PHONE}) {
      font-size: 36px;
      line-height: 46px;
    }
  `}

  ${(props) =>
    props.$textStyle === 'h2' &&
    `
    font-size: 46px;
    font-weight: ${props.$fontWeight || 900};
    line-height: 58px;
    margin-bottom: 30px;
    
    @media screen and (max-width: ${BREAKPOINT_TABLET}) {
      font-size: 36px;
      line-height: 44px;
    }
    
    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 20px;
    }
    
    @media screen and (max-width: ${BREAKPOINT_SMALL_PHONE}) {
      font-size: 24px;
      line-height: 30px;
    }
  `}

  ${(props) =>
    props.$textStyle === 'h3' &&
    `
    font-size: 28px;
    font-weight: 900;
    line-height: 1.5;
    
    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      font-size: 24px;
    }
  `}

  ${(props) =>
    props.$textStyle === 'h4' &&
    `
    font-size: 28px;
    font-weight: 900;
    line-height: 1.5;
    
    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      font-size: 18px;
    }
  `}
`;
