/* eslint-disable react/no-children-prop */
import React from 'react';

import { PageProps } from './PageTypes';
import { SystemPage } from './PageStyled';

export const Page = (props: PageProps) => {
  const { children } = props;
  return <SystemPage children={children} />;
};
