/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

export const scenario_3_23_0: Scenario = {
  scenarioId: SceneId.scenario_3_23_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Новости парка',
    text: 'Вам следует загрузить или проверить свою видеовизитку. Для этого нажмите на кнопку «Загрузить видеовизитку» вверху экрана.',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_23: Scenario = {
  scenarioId: SceneId.scenario_3_23,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Парк «Большая перемена»',
    text: 'Вы и Вжух проводите экскурсию по парку «Большая перемена». София восхищена его масштабами, технологичностью и красотой и хочет очень внимательно рассмотреть все уголки «Большой перемены».',
    buttons: ['Продолжить'],
  },
};
export const scenario_3_23_01: Scenario = {
  scenarioId: SceneId.scenario_3_23_01,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Парк «Большая перемена»',
    text: 'Экскурсия займет много времени. Возвращайтесь в игру 22 мая, когда София удовлетворит свое любопытство и исследует каждую замечательную достопримечательность парка.',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_23_02: Scenario = {
  scenarioId: SceneId.scenario_3_23_02,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Парк «Большая перемена»',
    text: 'Вы возвращаетесь в парк «Большая перемена», вас встречают Вжух, Малевич и София. Экскурсия для Софии окончена, гостья осталась в полном восторге от красоты «Большой перемены».',
    buttons: ['Продолжить'],
  },
};

const EXIT_ID = 'EXIT_ID';

export const scenario_3_23_1: Scenario = {
  scenarioId: SceneId.scenario_3_23_1,
  type: ScenarioType.chat,
  data: {
    title: 'Парк «Большая перемена»',
    scene_id: 'scenario_3_23_1',
    startId: 'Q1/0',
    steps: {
      ...splitStep({
        person: Interlocutors.CAT,
        id: 'Q1',
        text: `{{NAME}}, благодаря твоей помощи, смекалке и отваге, парк «Большая перемена» полностью восстановлен и готов к приему посетителей 😻

А наша игра близится к завершению. Тебе, {{NAME}}, предстоит принять участие в последнем испытании игры, которое состоит из «Доброго дела» и двух заданий по вызову.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Здорово! Я [[рад/рада]], что [[добралась/добрался]] до последнего испытания игры. Расскажи, пожалуйста, поподробнее про «Доброе дело» и задания по вызовам?',
            nextStep: 'Q2',
          },
        ],
      }),
      Q2: {
        person: Interlocutors.CAT,
        id: 'Q2',
        text: 'Ты уже много добрых дел [[совершил/совершила]] в нашем парке, а делаешь ли ты добрые дела в реальной жизни? Подготовь рассказ о своем добром поступке (оказание помощи пожилым людям, уход за животными в приюте, высадка деревьев и т.д.) и загрузи его в свой игровой профиль. Рассказ должен быть небольшой от 100 до 2500 символов.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо, про «Доброе дело» я все [[понял/поняла]]. А что такое задания по вызовам?',
            nextStep: 'Q3/0',
          },
        ],
      },
      ...splitStep({
        person: Interlocutors.CAT,
        id: 'Q3',
        text: `Тебе предлагается 12 испытаний, которые мы назвали «Вызовы». Каждый вызов — это два задания на определенную тему. Сначала внимательно изучи все «Вызовы» в своем профиле, а потом выбери тот, который тебе наиболее интересен.

Для того, чтобы пройти игру до конца, не нужно выполнять все 12 вызовов. Обязательно нужно выбрать и успешно справиться только с одним вызовом. В каждом вызове тебя ждет два задания: одно офлайн и одно онлайн. Завершить прохождение своего вызова ты [[должен /должна]] до 29 мая 23:59 по Москве. Но не откладывай на последний момент. Начиная загружать файл с решением в 23:55, ты можешь не успеть, так как на загрузку файла нужно время.

Задания по вызовам ты найдешь в своем профиле. Файл с заданием удобнее будет заполнять, сохранять и размещать в игре с компьютера.

ЗАПОМНИ! «Доброе дело» и «Вызовы» — это ОБЯЗАТЕЛЬНЫЕ испытания, чтобы пройти последний этап игры.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ок, спасибо, я [[понял/поняла]]',
            nextStep: 'Q4/0',
          },
        ],
      }),
      ...splitStep({
        person: Interlocutors.CAT,
        id: 'Q4',
        text: `У меня есть еще одна приятная новость, {{NAME}}. Набранные за тесты и задачи баллы ты сможешь посмотреть 30 мая с 12:00 по Москве в своем профиле 😺

Баллы, набранные за видеовизитку, обязательные финальные задания по вызовам, а также свой рейтинг, ты увидишь 4 июня.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Круто! Буду ждать!',
            nextStep: EXIT_ID,
          },
        ],
      }),
      [EXIT_ID]: {
        person: Interlocutors.TEACHER,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
