/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';
import interlocutors from '../../../interlocutors';

export const scenario_egg3_0: Scenario = {
  scenarioId: SceneId.scenario_egg3_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `Ничего себе! Видимо, кто-то когда-то обронил здесь несколько монет. Ну, хозяина уже не найти, так что теперь они твои!`,
    buttons: ['Закрыть'],
  },
};
