import { BREAKPOINT_PHONE, BREAKPOINT_TABLET } from '../../constants/constants';
import styled from 'styled-components';
import { LastMessageDividerProps } from './LastMessageDividerTypes';

export const StyledLastMessageDivider = styled.div<LastMessageDividerProps>`
  height: 20px;

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    height: 72px;
  }

  @media screen and (min-width: ${BREAKPOINT_TABLET}) {
    height: 92px;
  }
`;
