/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../scenario';
import interlocutors from '../../../interlocutors';

export const scenario_egg6_0: Scenario = {
  scenarioId: SceneId.scenario_egg6_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: 'МГУ',
    person: Interlocutors.SYSTEM,
    text: `Вы слышите подозрительные звуки, доносящиеся с самой макушки сосны…`,
    buttons: ['Закрыть'],
  },
};

export const scenario_egg6_1: Scenario = {
  scenarioId: SceneId.scenario_egg6_1,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `А, это ты! А я тут на воробьев охочусь! Эта сосна — одна из самых древних в местном саду… На ней еще мой прадед воробьев гонял! А теперь она перешла по охотничьему наследству мне 😻`,
    buttons: ['Закрыть'],
  },
};
