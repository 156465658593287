import { Viewport as ViewportFifth } from '../../../fifthGrade/components/Viewport/Viewport';
import { Viewport as ViewportSixth } from '../../../sixthGrade/components/Viewport/Viewport';
import { Viewport as ViewportSeventh } from '../../../seventhGrade/components/Viewport/Viewport';

import { App as AppFifth } from '../../../fifthGrade/App/App';
import { App as AppSixth } from '../../../sixthGrade/app/App';
import { App as AppSeventh } from '../../../seventhGrade/app/App';

import storeFifth from '../../../fifthGrade/redux';
import storeSixth from '../../../sixthGrade/redux';
import storeSeventh from '../../../seventhGrade/redux';

enum GradeEnum {
  Fifth = 5,
  Sixth = 6,
  Seventh = 7,
}

export const getCurrentGradeConfig = (grade: number) => {
  let Viewport, App, Store;

  if (grade === GradeEnum.Fifth) {
    Viewport = ViewportFifth;
    App = AppFifth;
    Store = storeFifth;
  } else if (grade === GradeEnum.Sixth) {
    Viewport = ViewportSixth;
    App = AppSixth;
    Store = storeSixth;
  } else {
    Viewport = ViewportSeventh;
    App = AppSeventh;
    Store = storeSeventh;
  }

  return { Viewport, App, Store };
};
