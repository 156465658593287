import { PointsOfInterest } from '../../types';
import * as checks from '../../hooks/scenarioHelpers/stageChecks';
import { ScenarioState } from '../../redux/scenario';
import { ID, to } from '../../hooks/scenarioHelpers/ID';

type PlaceOpened = Partial<Record<string, (scenarioState: ScenarioState) => boolean>>;

const placeOpened: PlaceOpened = {
  [PointsOfInterest.mgu]: (scenarioState) => checks.notWelcomeTest(scenarioState),

  [PointsOfInterest.hersones]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.baggage]]), // работает

  [PointsOfInterest.lake]: (scenarioState) => Boolean(scenarioState.scenarios?.[to[ID.baggage]]), // работает

  [PointsOfInterest.sochi]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.welcomeGrandmother]]), // работает

  [PointsOfInterest.moscowCity]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.moscowCityThirdAct]]), // работает

  [PointsOfInterest.patriot]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.moscowCityThirdAct]]), // работает

  [PointsOfInterest.innopolice]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.stage2_act1_innopolice]]), // работает

  [PointsOfInterest.arch]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.stage2_act1_arch]]), // работает (посмотреть по варианты сделать по другому)

  [PointsOfInterest.kurgan]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.stage2_act1_arch]]), // работает (посмотреть по варианты сделать по другому)

  [PointsOfInterest.space]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.stage2_act2_cosmodrom]]), // работает

  [PointsOfInterest.ostankino]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.stage2_act2_ostankino]]), // работает

  [PointsOfInterest.bridge]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.stage2_act3_bridge]]), // работает

  [PointsOfInterest.hermitage]: (scenarioState) =>
    Boolean(scenarioState.scenarios[to[ID.stage3_act1_hermitage]]), // работает
};

export const checkPlaceOpened = (placeName: string, scenarioState: ScenarioState) => {
  return placeOpened[placeName]?.(scenarioState);
};
