import { SceneId, Step } from '../../resources/scenario';
import scenes from '../../resources/scenes';

export const stage1RequiredScenarios: SceneId[][] = [
  [SceneId.scenario_1_1], // без теста
  [SceneId.scenario_1_3],
  [SceneId.scenario_1_7, SceneId.scenario_1_8, SceneId.scenario_1_9],
  [SceneId.scenario_1_14],
  [SceneId.scenario_1_17],
  [SceneId.scenario_1_18, SceneId.scenario_1_19],
  [SceneId.scenario_1_23],
  [SceneId.scenario_1_25_1], // в теории может быть необходим 1_25_4
];

export const s2a1RequiredScenarios: SceneId[][] = [
  [SceneId.scenario_2_0_1], // без теста
  [SceneId.scenario_2_1_1],
  [SceneId.scenario_2_2_1],
  [SceneId.scenario_2_2_4],
  [SceneId.scenario_2_4],
];

export const s2a2RequiredScenarios: SceneId[][] = [
  [SceneId.scenario_2_9], // нет теста
  [SceneId.scenario_2_10],
  [SceneId.scenario_2_10_1],
  [SceneId.scenario_2_11],
  [SceneId.scenario_2_13], // нет теста
  [SceneId.scenario_2_14],
  [SceneId.scenario_2_15], // нет теста
];

export const s2a3RequiredScenarios: SceneId[][] = [
  [SceneId.scenario_2_20_1], // нет теста
  [SceneId.scenario_2_21_1],
  [SceneId.scenario_2_22_1],
  [SceneId.scenario_2_23],
];

export const s3a1RequiredScenarios: SceneId[][] = [
  [SceneId.scenario_3_1],
  [SceneId.scenario_3_2],
  [SceneId.scenario_3_3],
  [SceneId.scenario_3_4], // нет теста
];

export const s3a2a3RequiredScenarios: SceneId[][] = [
  [SceneId.scenario_3_11], // нет теста
  [SceneId.scenario_3_12], // нет теста
  [SceneId.scenario_3_13], // нет теста
  [SceneId.scenario_3_14], // нет теста
  [SceneId.scenario_3_15], // нет теста
  [SceneId.scenario_3_16],
  [SceneId.scenario_3_17],
  [SceneId.scenario_3_18],
  [SceneId.scenario_3_19],

  [SceneId.scenario_3_21], // нет теста
  [SceneId.scenario_3_22_1],
  [SceneId.scenario_3_23_1], // нет теста
];

export const ALL_REQUIRED_SCENARIOS: SceneId[][] = [
  ...stage1RequiredScenarios,
  ...s2a1RequiredScenarios,
  ...s2a2RequiredScenarios,
  ...s2a3RequiredScenarios,
  ...s3a1RequiredScenarios,
  ...s3a2a3RequiredScenarios,
];

type Progress = Partial<Record<SceneId, Step[]>>;

enum GroupStatus {
  exist,
  skipped_not_required,
  skipped,
}
function checkGroup(sceneIds: SceneId[], state: Progress): GroupStatus {
  let status = GroupStatus.exist;
  for (let i = 0; i < sceneIds.length; i++) {
    if (!state[sceneIds[i]]) {
      if (scenes[sceneIds[i]].isRequired) {
        return GroupStatus.skipped;
      }
      status = GroupStatus.skipped_not_required;
    }
  }
  return status;
}

export function hasBlanks(scenarios: Progress) {
  let hasBlankH = GroupStatus.exist;
  for (let i = 0; i < ALL_REQUIRED_SCENARIOS.length; i++) {
    const currentStatus = checkGroup(ALL_REQUIRED_SCENARIOS[i], scenarios);
    // если группа пройдена, а ранее есть пробел
    if (currentStatus === GroupStatus.exist && hasBlankH === GroupStatus.skipped) {
      return true;
    }
    hasBlankH = Math.max(currentStatus, hasBlankH) as GroupStatus;
  }
  // если дошли до сюда, значит даже если hasBlank, то после "пробела" нет пройденых сценариев
  return false;
}
