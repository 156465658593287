import { Action, ThunkAction } from '@reduxjs/toolkit';
import store from '../redux';
import { SceneId, Step } from '../resources/scenario';

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type RootState = ReturnType<typeof store.getState>;

export enum PointsOfInterest {
  mgu = 'mgu',
  ostankino = 'ostankino',
  space = 'space',
  lake = 'lake',
  sochi = 'sochi',
  innopolice = 'innopolice',
  hermitage = 'hermitage',
  bridge = 'bridge',
  moscowCity = 'moscowCity',
  train = 'train',
  kurgan = 'kurgan',
  hersones = 'hersones',
  patriot = 'patriot',
  arch = 'arch',

  innopoliceBush = 'innopoliceBush',
  mguBush = 'mguBush',
  ostankinoBush = 'ostankinoBush',
  hermitageBush = 'hermitageBush',
  lakeBush = 'lakeBush',
  train2 = 'train2',
  hersonesSteps = 'hersonesSteps',
}

export interface IQuestionProtocol {
  duration: number;
  answers: Record<string, number>;
}
export interface ISubtestProtocol {
  duration: number;
  questions: Record<string, IQuestionProtocol>;
}
export interface IProtocol {
  duration: number;
  subtests: Record<string, ISubtestProtocol>;
}

export interface TestProtocol {
  test: string;
  protocol: IProtocol;
}

export enum TestStatus {
  'process' = 'process',
  'checking' = 'checking',
  'completed' = 'completed',
}

export enum TestType {
  'personality' = 'Твой код (тип личности)',
  'interests' = 'Твой выбор (интересы)',
  'thinking' = 'Твой вектор (типы мышления)',
  'communicativeness' = 'Коммуникативность',
  'creativity' = 'Креативность',
  'altruism' = 'Альтруизм',
  'erudition_tech' = 'Эрудиция в технической сфере',
  'erudition_hum' = 'Эрудиция в гуманитарной сфере',
  'logic' = 'Логика',
}

export const TestID: Record<TestType, string> = {
  [TestType.personality]: 'tvoi-kod-junior',
  [TestType.interests]: 'tvoi-vybor-junior',
  [TestType.thinking]: 'tvoi-vektor-junior',
  [TestType.erudition_tech]: '20240528-144022-3448',
  [TestType.erudition_hum]: '20240528-151153-9700',
  [TestType.communicativeness]: 'communicativeness',
  [TestType.creativity]: 'creativity',
  [TestType.altruism]: 'altruism',
  [TestType.logic]: 'logic',
};

export interface UnsavedScripts {
  [userId: string]: Partial<Record<SceneId, Step[]>>;
}
