/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const lakeComeback_1_1_0: Scenario = {
  scenarioId: SceneId.lakeComeback_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.CAT,
    title: 'Байкал',
    text: `Ох уж наш вечно голодный Малевич. Но не переживай — я знаю, где нам добыть рыбу! Пойдём проведаем Бабулю.`,
    buttons: ['Продолжить'],
  },
};

export const lakeComeback_1_1_1: Scenario = {
  scenarioId: SceneId.lakeComeback_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Байкал',
    text: `На берегу озера сидит Бабуля с удочкой в руках. Увидев её, Вжух необычайно оживился, его глаза заблестели.`,
    buttons: ['Продолжить'],
  },
};
