/* eslint-disable react/no-children-prop */
import React, { FC, useMemo } from 'react';
import {
  ButtonPrimaryStyled,
  ButtonBlueStyled,
  ButtonGhostStyled,
  ButtonGreyStyled,
  ButtonPurpleStyled,
  ButtonDarkGreyStyled,
  ButtonNewGreyStyled,
} from './ButtonStyled';
import { BaseProps } from '../../styles/styledBase';

interface ButtonStyledProps extends BaseProps {
  $center?: boolean;
  $style?: 'primary' | 'secondary' | 'blue' | 'purple' | 'ghost' | 'grey' | 'darkGrey' | 'newgrey';
  $size?: 'sm' | 's' | 'md' | 'lg';
  $noborder?: boolean;
  $full?: boolean;
  $isRound?: boolean;
  $isRoundMobileButton?: boolean;
}

export interface ButtonProps extends ButtonStyledProps {
  onClick?: () => void;
  children: React.ReactNode;

  disabled?: boolean;
  inProgress?: boolean;

  $isActive?: boolean;
}

const ButtonStyles: Record<string, any> = {
  blue: ButtonBlueStyled,
  purple: ButtonPurpleStyled,
  ghost: ButtonGhostStyled,
  grey: ButtonGreyStyled,
  newgrey: ButtonNewGreyStyled,
  darkGrey: ButtonDarkGreyStyled,
  primary: ButtonPrimaryStyled,
};

export const Button: FC<ButtonProps> = (props) => {
  const { $style, children, inProgress = false, ...baseProps } = props;

  const loading = useMemo(
    () => (inProgress ? <div>Подождите...</div> : children),
    [inProgress, children],
  );

  const Component = ButtonStyles[$style as string];

  return <Component as='button' type='button' {...baseProps} children={loading} />;
};

Button.defaultProps = {
  $style: 'primary',
  $size: 'md',
  disabled: false,
};
