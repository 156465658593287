/* eslint-disable max-len */
import {
  Step,
  SceneId,
  StepType,
  Scenario,
  ScenarioType,
  Interlocutors,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const scenario_2_6: Scenario = {
  scenarioId: SceneId.scenario_2_6,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    scene_id: 'scenario_2_6',
    startId: 'Q1',
    title: 'Иннополис',
    steps: {
      Q1: {
        // ТЕСТ НА АЛЬТРУИЗМ 3
        text: `Вот вы и снова в Иннополисе. Вы направляетесь в Университет и поднимаетесь к КВА. Гарин активировал процесс поиска Интеллекта 2.0.  

          Этот процесс займет некоторое время. Может быть вы пока поможете навести порядок в Иннополисе?
          `,
        id: 'Q1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Согласиться',
            nextStep: 'Q2',
          },
          {
            id: 'b2',
            text: 'Отказаться',
            nextStep: 'Q3',
          },
        ],
      },
      Q2: {
        // ТЕСТ НА АЛЬТРУИЗМ 4
        text: `Вы соглашаетесь навести порядок в Иннополисе. 
          Для вас есть три задания на выбор:
          `,
        id: 'Q2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Найти ровер с пиццей',
            nextStep: 'Q2_1',
          },
          {
            id: 'b2',
            text: 'Собрать все беспилотные такси на стоянке',
            nextStep: 'Q2_2',
          },
          {
            id: 'b3',
            text: 'Рассортировать все файлы с базами данных факультетов',
            nextStep: 'Q2_3',
          },
        ],
      },
      Q2_1: {
        text: `Ты соглашаешься найти ровер с пиццей. Проще не бывает: пиццу вы находите по запаху, без всяких технологий. Прихватив кусочек Пепперони с собой, вы с котом отправляетесь дальше.`,
        callToAction: 'Найти ровер с пиццей',
        id: 'Q2_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Кажется, всё, пора отправляться к Гарину',
            nextStep: 'Q3',
          },
        ],
      },
      Q2_2: {
        text: `Ты соглашаешься собрать все беспилотные такси на стоянке. «Скачаю приложение Университета и соберу их, это должно быть не так сложно, но пару минут займёт», — думаешь ты.`,
        callToAction: 'Собрать все беспилотные такси на стоянке',
        id: 'Q2_2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Кажется, всё, пора отправляться к Гарину',
            nextStep: 'Q3',
          },
        ],
      },
      Q2_3: {
        text: `Ты соглашаешься рассортировать все файлы с базами данных факультетов. Ух, работёнка самая тяжёлая. Базы данных перемещались на серверах, это займёт много времени.`,
        callToAction: 'Рассортировать все файлы с базами данных факультетов',
        id: 'Q2_3',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Кажется, всё, пора отправляться к Гарину',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        text: `Голос Гарина произносит: «Всё готово, мы его нашли — он в Таинственном лесу. Возьми накопитель и загрузи Интеллект 2.0 на него, чтобы я смог его перепрограммировать и вернуть жителям парка память».`,
        id: 'Q3',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться на поиски Интеллекта 2.0',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
