import styled, { css } from 'styled-components';
import {
  BREAKPOINT_PHONE,
  BREAKPOINT_SMALL_PHONE,
  BREAKPOINT_TABLET,
} from '../../../common/shared/constants/constants';

export const StyledLogo = styled.img<{ $isOnboarding?: boolean }>`
  ${({ $isOnboarding }) => {
    if ($isOnboarding) {
      return css`
        width: 170px;
        height: 58px;

        @media screen and (max-width: ${BREAKPOINT_TABLET}) {
          width: 140px;
          height: 48px;
        }

        @media screen and (max-width: ${BREAKPOINT_PHONE}) {
          width: 112px;
          height: 30px;
        }
      `;
    }

    return css`
      width: 130px;
      height: 35px;

      @media screen and (min-width: ${BREAKPOINT_SMALL_PHONE}) {
        width: 160px;
        height: 55px;
      }
    `;
  }}
`;
