/* eslint-disable no-constant-condition */
import React, { useState } from 'react';
import useBoolState from '../../common/shared/hooks/useBoolState';
import { ReactTooltip } from '../../common/shared/ui/ReactTooltip';
import { Router } from './providers/routerProvider/Router';
import PageLoading from '../components/PageLoader';
import { GameModal } from '../components/GameModal';
import { InputToolbarModal } from '../components/InputToolbarModal';

import { NetworkOverlay } from '../components/NetworkOverlay';
import useFirstDataLoad from './hooks/useFirstDataLoad';
import ErrorBoundary from './providers/errorBoundary/ErrorBoundary';

import { ModalWindow } from '../../common/widgets/modalWindow/ModalWindow';

export const App = (props: { isQueryClassesChanged: boolean }) => {
  const { isQueryClassesChanged } = props;

  const [isLoading, setLoading] = useBoolState(true);
  const [state, setState] = useState(isQueryClassesChanged);

  // первая подгрузка данных
  useFirstDataLoad(setLoading);

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <>
      <ErrorBoundary>
        {state ? <ModalWindow setState={setState} /> : null}
        <Router />
        <ReactTooltip />
        <InputToolbarModal />
        <NetworkOverlay />
      </ErrorBoundary>
    </>
  );
};
