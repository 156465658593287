import styled from 'styled-components';
import colors from '../../../common/shared/constants/colors';
import { BREAKPOINT_PHONE } from '../../../common/shared/constants/constants';

import { Wrapper } from '../../../common/shared/ui/Wrapper';

import bg from '../../../assets/images/pageloader_bg.jpg';

export const StyledLayoutBackground = styled.div`
  top: 0;
  left: 0;
  z-index: 0;
  position: fixed;

  width: 100%;
  height: 100%;

  background-size: cover;
  background-position: center;
  background-image: url(${bg});
  background-color: ${colors.base40};
`;

export const StyledLayoutAuth = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledLayoutAuthContentWrapper = styled(Wrapper)`
  display: flex;
  overflow: hidden;

  height: 100%;
  margin: 0 auto;

  position: relative;
  z-index: 10;

  & > * + * {
    margin-left: 10px;
  }

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

export const StyledLayoutAuthSide = styled.div`
  width: 198px;
  min-width: 198px;

  & > * + * {
    margin-top: 10px;
  }
`;

export const StyledLayoutAuthContent = styled.div`
  width: 100%;
  overflow: hidden;
`;
