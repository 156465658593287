/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

const TK_TEST: TestStep = {
  id: 'TK_TEST',
  person: Interlocutors.SYSTEM,
  type: StepType.test,
  external_id: 'test-tvoi-kod-junior',
  path: 'test-tvoi-kod-junior-210401.json',
  name: 'Твой Код 1/1',
  order: [
    {
      description:
        'Восемь вариантов, так что хотя бы на два ответь «Точно да» и на два «Определенно нет».',
      subtest: 'T2',
      question: 'Q2',
    },
  ],
  nextStep: 'Q6',
};

export const scenario_3_6_i: Scenario = {
  scenarioId: SceneId.scenario_3_6_i,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.TEACHER,
    title: 'МГУ',
    text: `«Итак, нам с тобой нужно высадить растения, которые привезли из башни «Эволюция». Предлагаю начать».`,
    buttons: ['Отправиться в Ботанический сад МГУ'],
  },
};

export const scenario_3_6: Scenario = {
  scenarioId: SceneId.scenario_3_6,
  type: ScenarioType.chat,
  data: {
    title: 'МГУ',
    scene_id: 'scenario_3_6',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.SYSTEM,
        id: 'Q1',
        text: `Огромные кадки и ящики с саженцами, колбы с ростками и чаши Петри с микроорганизмами выстроились у входа в Ботанический сад МГУ. Всё это предстоит тебе распределить по разделам.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Сортировать растения',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        person: Interlocutors.SYSTEM,
        id: 'Q2',
        text: `Начнем с грибов: куда их высадить?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'К растениям-паразитам',
            nextStep: 'Q2_1',
          },
          {
            id: 'b2',
            text: 'К мохообразным растениям',
            nextStep: 'Q2_2',
          },
          {
            id: 'b3',
            text: 'Грибы нужно высадить отдельно',
            nextStep: 'Q3',
          },
        ],
      },
      Q2_1: {
        person: Interlocutors.SYSTEM,
        id: 'Q2_1',
        text: `Грибы бывают паразитами, но всё же они не относятся 
        к растениям, давай подумаем ещё.
        `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Подобрать другое место',
            nextStep: 'Q2',
          },
        ],
      },
      Q2_2: {
        person: Interlocutors.SYSTEM,
        id: 'Q2_2',
        text: `Мохообразные или мхи — это отдельный вид. Грибы лучше высадить отдельно от них.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Подобрать другое место',
            nextStep: 'Q2',
          },
        ],
      },
      Q3: {
        person: Interlocutors.SYSTEM,
        id: 'Q3',
        text: `Правильно! Грибы — совершенно обособленный вид и высаживать их нужно отдельно. 

        Теперь приступим к папоротникам: можно ли их высадить в «Аптекарском огороде»?
        `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, можно',
            nextStep: 'Q4',
          },
          {
            id: 'b2',
            text: 'Нет, нельзя',
            nextStep: 'Q3_2',
          },
        ],
      },
      Q3_2: {
        person: Interlocutors.SYSTEM,
        id: 'Q3_2',
        text: `А вот и можно!`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Узнать почему',
            nextStep: 'Q4',
          },
        ],
      },
      Q4: {
        person: Interlocutors.SYSTEM,
        id: 'Q4',
        text: `Совершенно верно! Папоротники используются в медицине, поэтому мы смело можем высадить их в «Аптекарском огороде».

        Ну что ж, последняя задача: на какую кафедру МГУ мы отнесем сведения о растениях?
        `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'На кафедру микологии.',
            nextStep: 'Q4_1',
          },
          {
            id: 'b2',
            text: 'На кафедру ботаники.',
            nextStep: 'Q4_2',
          },
          {
            id: 'b3',
            text: 'На кафедру гистологии.',
            nextStep: 'Q4_3',
          },
        ],
      },
      Q4_1: {
        person: Interlocutors.SYSTEM,
        id: 'Q4_1',
        text: `На кафедре микологии изучают грибы. Давай выберем другую кафедру?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Выбрать другую кафедру',
            nextStep: 'Q4',
          },
        ],
      },
      Q4_2: {
        person: Interlocutors.SYSTEM,
        id: 'Q4_2',
        text: `Совершенно верно. Сведения о растениях нужно передать на кафедру ботаники.
        Пройди небольшой тест, это поможет понять, что тебе делать дальше`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Пройти тест',
            nextStep: 'TK_TEST',
          },
        ],
      },
      Q4_3: {
        person: Interlocutors.SYSTEM,
        id: 'Q4_3',
        text: `На кафедре гистологии изучают жизнедеятельность тканей живых организмов. Давай выберем другую кафедру?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Выбрать другую кафедру',
            nextStep: 'Q4',
          },
        ],
      },

      TK_TEST: TK_TEST, // ТЕСТ код остаток (1 вопрос)

      Q6: {
        person: Interlocutors.TEACHER,
        id: 'Q6',
        text: `«{{NAME}}, спасибо тебе за помощь, мы тут справимся дальше сами. Отправляйся к бабуле в Херсонес.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться в Херсонес',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.SYSTEM,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.SYSTEM,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
