import { starAnimation } from './../SuccessPage/SuccessPageStyled';
import styled from 'styled-components';
import { Wrapper } from '../../../common/shared/ui/Wrapper';
import { BREAKPOINT_PHONE, BREAKPOINT_TABLET } from '../../../common/shared/constants/constants';

export const StyledFinishPage = styled(Wrapper)`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  padding: 30px;

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    padding: 16px 24px 0;
  }
`;

export const StyledFinishPageWrapper = styled.div`
  max-width: 570px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    max-width: 424px;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    max-width: 100%;

    justify-content: flex-start;
  }
`;

export const StyledButtonWrapper = styled.div`
  // @media screen and (max-width: ${BREAKPOINT_PHONE}) {
  //   width: calc(100% - 48px);
  //
  //   left: 24px;
  //   bottom: 20px;
  //   position: absolute;
  //
  //   & > button {
  //     width: 100%;
  //   }
  // }
`;

export const StyledFinishPageWrapperText = styled.div`
  z-index: 15;
  position: relative;

  margin-top: 56px;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    margin-top: 40px;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    margin-top: 0px;
    padding-bottom: 20px;

    position: static;
  }
`;

export const StyledPersonsWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;

  align-items: flex-end;
  justify-content: flex-end;

  height: 100%;
  width: calc(100% - 60px - 570px);

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    width: calc(100% - 60px - 424px + 70px);
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    position: static;
    width: 100%;
  }
`;

export const StyledPersonsImage = styled.div`
  display: flex;
  position: relative;
  z-index: 10;

  width: 100%;
  /* height: 100%; */
  max-height: 80%;

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    max-height: 100%;
  }
`;

export const StyledPersons = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center bottom;
`;

export const StyledStar1 = styled.img`
  animation: ${starAnimation} 2s infinite linear;

  top: -20%;
  left: 18%;
  position: absolute;

  width: 10%;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    top: -15%;
    left: 30%;
  }
`;

export const StyledStar2 = styled.img`
  animation: ${starAnimation} 2s infinite linear;

  top: 0%;
  left: -18%;
  position: absolute;

  width: 7%;
  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    left: -2%;
    top: 75%;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    top: 5%;
    left: 2%;
  }
`;

export const StyledStar3 = styled.img`
  animation: ${starAnimation} 2s infinite linear;

  top: 26%;
  left: -4%;
  position: absolute;

  width: 5%;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    left: -10%;
    top: 50%;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    display: none;
  }
`;

export const StyledStar4 = styled.img`
  animation: ${starAnimation} 2s infinite linear;

  top: 0%;
  right: 0%;
  position: absolute;

  width: 6%;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    right: 20%;
    top: -20%;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    width: 8%;
    right: 8%;
    top: -20%;
  }
`;

export const StyledStar5 = styled.img`
  animation: ${starAnimation} 2s infinite linear;

  top: 35%;
  right: 0;
  position: absolute;

  width: 4%;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    top: 27%;
    right: 10%;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    right: 5%;
  }
`;

export const StyledNextBtn = styled.div`
  background: #ffe074;
  color: #1b1b1b;
  border: 1px solid #1b1b1b;
  font-size: 22px;
  border-radius: 16px;
  padding: 10px;
  width: fit-content;
  cursor: pointer;
  margin: 30px 0;
`;
