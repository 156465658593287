import React from 'react';

const IconArrowDown: React.FC = () => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.3333 7.58337L8.49992 10.4167L5.66658 7.58337'
      stroke='#1B1B1B'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default IconArrowDown;
