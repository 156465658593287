/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../types/scenario';
import interlocutors from '../../interlocutors';

export const scenario_1_10: Scenario = {
  scenarioId: SceneId.scenario_1_10,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `{{NAME}}, здесь уже столько замечательных изменений, и все благодаря тебе! 😻

Мы все славно поработали. С 16 апреля тебя будут ждать новые квесты. Возвращайся, будем с нетерпением ждать! 😺`,
    buttons: ['Закрыть'],
  },
};
