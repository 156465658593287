/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../../types/scenario';
import { TEST_TVOI_VECTOR_2 } from '../../tests/test_tvoi_vector';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const mguDictaphone_1_1: Scenario = {
  scenarioId: SceneId.mguDictaphone_1_1,
  type: ScenarioType.chat,
  data: {
    title: 'МГУ',
    scene_id: 'mguDictaphone_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.TEACHER,
        id: 'Q1',
        text: `Ребята, я так рада вас видеть! 

        Хотите позаниматься в библиотеке? Мы тут потихоньку порядок наводим.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Сейчас совсем не можем, Светлана Васильевна. Но нужна ваша помощь! У вас есть диктофон?',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        person: Interlocutors.TEACHER,
        id: 'Q2',
        text: `Конечно, диктофоны есть. Но я вам их не могу просто так дать, у вас нет членства в Клубе Друзей Библиотеки.

        Для получения членства нужно заполнить анкету, вот эту.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Запуск теста',
            nextStep: 'TEST_TVOI_VECTOR_2',
          },
        ],
      },

      // Тест - Твой Вектор 2/3
      TEST_TVOI_VECTOR_2: TEST_TVOI_VECTOR_2,

      Q3: {
        person: Interlocutors.TEACHER,
        id: 'Q3',
        text: `Чудесные ответы! Вы теперь официально Друзья Библиотеки.

        Возьмите диктофон в том нижнем ящике.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Большое спасибо! До свидания!',
            nextStep: 'Q4',
          },
        ],
      },

      Q4: {
        person: Interlocutors.ALIEN,
        id: 'Q4',
        text: `Щщщщщщ…`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Светлана Васильевна, это э-э…',
            nextStep: 'Q5',
          },
        ],
      },

      Q5: {
        person: Interlocutors.TEACHER,
        id: 'Q5',
        text: `Какой он милый! И лохматый… 

        Ну куда же он в таком неопрятном виде пойдёт?
        
        Позволите причесать вашего друга?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, наверное?',
            nextStep: 'Q6',
          },
        ],
      },

      Q6: {
        person: Interlocutors.ALIEN,
        id: 'Q6',
        text: `Щщщщщ… `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ничего себе, сколько с него шерсти сыпется! Я, пожалуй, заберу пучок на память',
            nextStep: 'Q7',
          },
        ],
      },

      Q7: {
        person: Interlocutors.TEACHER,
        id: 'Q7',
        text: `Всё, ну какой симпатяга вышел!`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо! Но нам пора!',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.SYSTEM,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.SYSTEM,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
