import styled from 'styled-components';
import colors from '../../../common/shared/constants/colors';
import { Wrapper } from '../../../common/shared/ui/Wrapper';

import { BREAKPOINT_PHONE } from '../../../common/shared/constants/constants';

export const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  z-index: 9999999;
  position: relative;

  background: ${colors.white};
  border-bottom: 1px solid ${colors.grey220};

  height: 85px;
  min-height: 85px;

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    height: 110px;
    min-height: 110px;
  }
`;

export const StyledHeaderWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
