/* eslint-disable max-len */
import { Step, StepType, Interlocutors, TestStep } from '../../../types/scenario';
import { splitStep } from '../../../utils/ScenarioUtils';

const TVOI_KRUGOZOR_T2_STEP: TestStep = {
  id: 'TVOI_KRUGOZOR_T2_STEP',
  person: Interlocutors.ENTREPRENEUR,
  type: StepType.test,
  external_id: '20230529-211915-4598',
  path: 'bp23_-_Твой_кругозор_Junior_(t2)_-_20230530.json',
  name: 'Твой кругозор 2/1',
  order: [
    { timeout: 60, question: 'Q1', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q2', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q4', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q5', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q6', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q10', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q11', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q13', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q14', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q20', subtest: 'T2', description: '' },
  ],
  nextStep: 'TVOI_KRUGOZOR_T2_FINISH/0',
};

export const TVOI_KRUGOZOR_T2: Record<string, Step> = {
  TVOI_KRUGOZOR_T2_STEP,
  ...splitStep({
    person: Interlocutors.ENTREPRENEUR,
    id: 'TVOI_KRUGOZOR_T2_FINISH',
    text: `Ну вот и весь тест 😊

    Как он тебе? Понравился?`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Ты был прав. Тест оказался очень интересным!',
        nextStep: 'Q13',
      },
      {
        id: 'b2',
        text: 'Ох, Сан Саныч! Было сложновато, но я, кажется, [[справился/справилась]]...',
        nextStep: 'Q13',
      },
    ],
  }),
};
