import React from 'react';

export const IconMap: React.FC = () => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      /* eslint-disable-next-line max-len */
      d='M17.5731 0.141491C17.8385 0.314111 18 0.619031 18 0.947372V14.2105C18 14.5694 17.8074 14.8974 17.5025 15.0579L12.1025 17.9C11.8491 18.0333 11.5509 18.0333 11.2975 17.9L6.3 15.2697L1.30249 17.9C1.02351 18.0468 0.692181 18.0311 0.426843 17.8585C0.161505 17.6859 0 17.381 0 17.0526V2.84211C0 2.43433 0.247887 2.0723 0.615393 1.94335L6.01539 0.0486209C6.24184 -0.0308392 6.489 -0.0123492 6.70249 0.100021L11.7 2.73028L16.6975 0.100021C16.9765 -0.0468192 17.3078 -0.0311292 17.5731 0.141491ZM10.8029 4.37481L7.2029 2.48007V13.6248L10.8029 15.5196V4.37481ZM12.5983 15.5196L16.1983 13.6248V2.48007L12.5983 4.37481V15.5196ZM5.40078 13.6252V2.26196L1.80078 3.52512V15.52L5.40078 13.6252Z'
      fill='currentColor'
    />
  </svg>
);
