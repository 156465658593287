import { ChallengeData } from '../Challenge/ChallengeTypes';

import newsIcon from '../../../assets/images/challenges/challengeNews.svg';
import doItIcon from '../../../assets/images/challenges/challengeDoIt.svg';
import doGoodIcon from '../../../assets/images/challenges/challengeDoGood.svg';
import futureIcon from '../../../assets/images/challenges/challengeFuture.svg';
import travelIcon from '../../../assets/images/challenges/challengeTravel.svg';
import natureIcon from '../../../assets/images/challenges/challengeNature.svg';
import healthIcon from '../../../assets/images/challenges/challengeHealth.svg';
import changesIcon from '../../../assets/images/challenges/challengeChanges.svg';
import rememberIcon from '../../../assets/images/challenges/challengeRemember.svg';
import undertakeIcon from '../../../assets/images/challenges/challengeUndertake.svg';
import tellAboutMainIcon from '../../../assets/images/challenges/challengeTellAboutMain.svg';
import serveTheFatherlandIcon from '../../../assets/images/challenges/challengeServeTheFatherland.svg';

import createFutureDoc from '../../../assets/challendges/Создавай будущее.docx';
import knowRussiaDoc from '../../../assets/challendges/Познавай Россию.docx';
import saveNatureDoc from '../../../assets/challendges/Сохраняй природу.docx';
import openNewsDoc from '../../../assets/challendges/Открывай новое.docx';
import beHealthyDoc from '../../../assets/challendges/Будь здоров.docx';
import changeWorldAroundDoc from '../../../assets/challendges/Меняй мир вокруг.docx';
import doGoodDoc from '../../../assets/challendges/Делай добро.docx';
import tellAboutMainDoc from '../../../assets/challendges/Расскажи о главном.docx';
import undertakeDoc from '../../../assets/challendges/Предпринимай.docx';
import serveTheFatherlandDoc from '../../../assets/challendges/Служи Отечеству.docx';
import rememberDoc from '../../../assets/challendges/Помни.docx';
import doitDoc from '../../../assets/challendges/Твори.docx';

export interface ChallengeBackendData {
  urlDoc?: string;
  urlPost?: string;
}

export enum ChallengeKey {
  'general' = 'general',
  'createFuture' = 'createFuture',
  'knowRussia' = 'knowRussia',
  'saveNature' = 'saveNature',
  'openNews' = 'openNews',
  'beHealthy' = 'beHealthy',
  'changeWorldAround' = 'changeWorldAround',
  'doGood' = 'doGood',
  'tellAboutMain' = 'tellAboutMain',
  'undertake' = 'undertake',
  'serveTheFatherland' = 'serveTheFatherland',
  'remember' = 'remember',
  'doit' = 'doit',
}

enum ChallengeName {
  general = 'Общий челендж',
  createFuture = 'Создавай будущее',
  knowRussia = 'Познавай Россию',
  saveNature = 'Сохраняй природу',
  openNews = 'Открывай новое',
  beHealthy = 'Будь здоров',
  changeWorldAround = 'Меняй мир вокруг',
  doGood = 'Делай добро',
  tellAboutMain = 'Расскажи о главном',
  undertake = 'Предпринимай',
  serveTheFatherland = 'Служи Отечеству',
  remember = 'Помни',
  doit = 'Твори',
}

const dobroeDeloText = `Размести здесь ссылку на пост в социальной сети «ВКонтакте» о твоём «Добром деле». Это рассказ о том, какие добрые дела ты делаешь в жизни. Может быть ты уже давно волонтёр? Или помогаешь своим родителям, присматривая за братом или сестрой? Или выгуливаешь собаку пожилой соседки? Или регулярно участвуешь в субботниках? Мы точно знаем — возможностей совершить добрый поступок великое множество. Обрати внимание: твои пост и профиль во «ВКонтакте» должны быть открытыми и доступными до конца дистанционного этапа, иначе мы не сможем его проверить.`;

const config: Record<string, ChallengeData> = {
  general: {
    //  новый общий челендж
    icon: futureIcon,
    doc: createFutureDoc,
    value: ChallengeKey.general,
    title: ChallengeName.general,

    description:
      // eslint-disable-next-line max-len
      '',

    offlineQuest:
      // eslint-disable-next-line max-len
      dobroeDeloText,
  },
  createFuture: {
    icon: futureIcon,
    doc: createFutureDoc,
    value: ChallengeKey.createFuture,
    title: ChallengeName.createFuture,

    description:
      // eslint-disable-next-line max-len
      `Направление для тех, кто не представляет свою жизнь без создания инноваций, интересуется современными гаджетами, роботами и искусственным интеллектом и мечтает стать частью науки и инженерии!<br><br>

      Если ты выберешь этот вызов, тебе нужно будет сделать два задания:<br>
      1. Проанализируй реальное или будущее влияние 6 открытий/технологий на одну из сфер жизни.<br>
      2. Запиши аудиоподкаст или сними видеоролик с рассказом об открытии/новой технологии.`,

    offlineQuest:
      // eslint-disable-next-line max-len
      dobroeDeloText,
  },
  knowRussia: {
    icon: travelIcon,
    doc: knowRussiaDoc,
    value: ChallengeKey.knowRussia,
    title: ChallengeName.knowRussia,

    description:
      // eslint-disable-next-line max-len
      `Любишь путешествовать и увлекаешься туризмом? Тогда это направление тебе идеально подойдет. Ты узнаешь, каким должен стать туризм современности и будущего, какая инфраструктура необходима для его развития, как привлекать туристов. И, конечно, предложишь свои инициативы!<br><br>

      Если ты выберешь этот вызов, тебе нужно будет сделать два задания:<br>
      1. Разработай концепцию семейной поездки по твоему региону сразу для трех поколений семьи.<br>
      2. Придумай, разработай и проведи квиз по одному из кластеров городов.`,

    offlineQuest:
      // eslint-disable-next-line max-len
      dobroeDeloText,
  },
  saveNature: {
    icon: natureIcon,
    doc: saveNatureDoc,
    value: ChallengeKey.saveNature,
    title: ChallengeName.saveNature,

    description:
      // eslint-disable-next-line max-len
      `Забота о природных ресурсах и экологии планеты – актуальная тема. Она объединяет молодых практиков и опытных специалистов в области новых технологий – от мусоропереработки до дизайна экологичных автомобилей. Цель вызова – понять, что происходит с планетой и определить способы обеспечения ее сохранности для будущих поколений.<br><br>

      Если ты выберешь этот вызов, тебе нужно будет сделать два задания:<br>
      1. Придумай пять способов использования новых технологий для улучшения окружающей среды.<br>
      2. Разработай и проведи игру на тему «Природа моего региона».`,

    offlineQuest:
      // eslint-disable-next-line max-len
      dobroeDeloText,
  },
  openNews: {
    icon: newsIcon,
    doc: openNewsDoc,
    value: ChallengeKey.openNews,
    title: ChallengeName.openNews,

    description:
      // eslint-disable-next-line max-len
      `Вызов связан с развитием образовательных технологий. Это направление поможет участникам пофантазировать на тему того, как может выглядеть современная школа, образовательный процесс, роли педагогов, учеников и родителей. Участники предложат свои идеи, которые сделают образование более интересным с применением современных игровых и цифровых технологий и подходов.<br><br>

      Если ты выберешь этот вызов, тебе нужно будет сделать два задания:<br>
      1. Предложи новый функционал в профиле Сферум в VK Мессенджере для облегчения образовательного процесса и общения.<br>
      2. Подготовь интерактивное занятие, которое позволит узнать об атомных профессиях.`,

    offlineQuest:
      // eslint-disable-next-line max-len
      dobroeDeloText,
  },
  beHealthy: {
    icon: healthIcon,
    doc: beHealthyDoc,
    value: ChallengeKey.beHealthy,
    title: ChallengeName.beHealthy,

    description:
      // eslint-disable-next-line max-len
      `Правильное питание, спорт, психологический настрой, современная медицина. Если тебе близки эти слова, выбирай данное направление. Здесь ты узнаешь, как обеспечить качественной медициной всех людей, реализуешь свою мечту в проведении исследований и открытии нового в сфере медицины.<br><br>

      Если ты выберешь этот вызов, тебе нужно будет сделать два задания:<br>
      1. Разработай марафон по теме рационального питания или физической активности.<br>
      2. Проведи мастер-класс по теме физической активности на тему здорового образа жизни.`,

    offlineQuest:
      // eslint-disable-next-line max-len
      dobroeDeloText,
  },
  changeWorldAround: {
    icon: changesIcon,
    doc: changeWorldAroundDoc,
    value: ChallengeKey.changeWorldAround,
    title: ChallengeName.changeWorldAround,

    description:
      // eslint-disable-next-line max-len
      `Хочешь развивать инфраструктуру мегаполисов, городов, сёл и деревень России, делать жизнь людей максимально комфортной? Тогда выбирай это направление, где ты сможешь предложить свои проекты современных архитектурных и ландшафтных решений и идеи по развитию населенных пунктов!<br><br>

      Если ты выберешь этот вызов, тебе нужно будет сделать два задания:<br>
      1. Предложи креативную концепцию преобразования «проблемной» школьной зоны.<br>
      2. Создай мини-клумбу и напиши 5 советов начинающему садоводу.`,

    offlineQuest:
      // eslint-disable-next-line max-len
      dobroeDeloText,
  },
  doGood: {
    doc: doGoodDoc,
    icon: doGoodIcon,
    value: ChallengeKey.doGood,
    title: ChallengeName.doGood,

    description:
      // eslint-disable-next-line max-len
      `Это направление объединяет тех, кому свойственны добрые поступки, и желающих участвовать в создании и развитии общественных инициатив, направленных на помощь людям из разных уголков России, по одному из пяти основных направлений: социальное, экологическое, культурное, спортивное и медицинское.<br><br>

      Если ты выберешь этот вызов, тебе нужно будет сделать два задания:<br>
      1. Придумай марафон для своих сверстников по формированию привычки делать добро.<br>
      2. Придумай, как можно воплотить в жизнь идею «Доброго дерева».`,

    offlineQuest:
      // eslint-disable-next-line max-len
      dobroeDeloText,
  },
  tellAboutMain: {
    icon: tellAboutMainIcon,
    doc: tellAboutMainDoc,
    value: ChallengeKey.tellAboutMain,
    title: ChallengeName.tellAboutMain,

    description:
      // eslint-disable-next-line max-len
      `Направление для будущих журналистов, телеведущих, редакторов, блогеров и операторов. Эксперты из медиаиндустрии поделятся с участниками советами по созданию популярного блога, записи подкаста или съемки документального фильма. В этом вызове ты также узнаешь, как меняется профессия журналиста, как реагировать на мнение подписчиков, что такое качественный и интересный контент, и будет ли существовать телевидение через 10 лет.<br><br>

      Если ты выберешь этот вызов, тебе нужно будет сделать два задания:<br>
      1. Выбери пять известных людей из разных профессий и придумай вопросы для интервью с ними.<br>
      2. Запиши аудио-подкаст или сними видеоролик «Топ-3 моих любимых фильма/книги».`,

    offlineQuest:
      // eslint-disable-next-line max-len
      dobroeDeloText,
  },
  undertake: {
    icon: undertakeIcon,
    doc: undertakeDoc,
    value: ChallengeKey.undertake,
    title: ChallengeName.undertake,

    description:
      // eslint-disable-next-line max-len
      `Направление для начинающих бизнесменов, кто ставит целью открытие своего дела и превращение бизнес-идеи в реальный проект. Вызов способствует развитию детского социального и коммерческого предпринимательства. Тебя научат составлять бизнес-план по реализации проекта, а еще найдут тех, кто поможет его осуществить.<br><br>

      Если ты выберешь этот вызов, тебе нужно будет сделать два задания:<br>
      1. Придумай 5 бизнес-идей, которые ты можешь реализовать уже сегодня.<br>
      2. Запиши аудиоподкаст или сними видеоролик с историей успеха российского предпринимателя.`,

    offlineQuest:
      // eslint-disable-next-line max-len
      dobroeDeloText,
  },
  serveTheFatherland: {
    icon: serveTheFatherlandIcon,
    doc: serveTheFatherlandDoc,
    value: ChallengeKey.serveTheFatherland,
    title: ChallengeName.serveTheFatherland,

    description:
      // eslint-disable-next-line max-len
      `Этот вызов создан для тех, кому близки чувство патриотизма и забота о гражданах страны. В рамках направления участники узнают больше о защите государства и познакомятся с другими ребятами, готовыми предложить идеи на благо общества.<br><br>

      Если ты выберешь этот вызов, тебе нужно будет сделать два задания:<br>
      1. Придумай календарь «Служи Отечеству» с важными датами твоего региона.<br>
      2. Придумай, разработай и проведи квиз, связанный с профессией служению отечеству.`,

    offlineQuest:
      // eslint-disable-next-line max-len
      dobroeDeloText,
  },
  remember: {
    icon: rememberIcon,
    doc: rememberDoc,
    value: ChallengeKey.remember,
    title: ChallengeName.remember,

    description:
      // eslint-disable-next-line max-len
      `Патриотизм – это культурный код, который объединяет наше общество. Он помогает понять, как прошлое, будущее и настоящее связаны между собой. Этот вызов подойдет ребятам, желающим сохранить историческую память России и предложить свои инициативы, чтобы не забывать важные памятные даты и государственные праздники.<br><br>

      Если ты выберешь этот вызов, тебе нужно будет сделать два задания:<br>
      1. Разработай квиз, посвященный истории твоего родного региона.<br>
      2. Расскажи о вкладе в историю России известной личности твоего города, населённого пункта или района.`,

    offlineQuest:
      // eslint-disable-next-line max-len
      dobroeDeloText,
  },
  doit: {
    doc: doitDoc,
    icon: doItIcon,
    value: ChallengeKey.doit,
    title: ChallengeName.doit,

    description:
      // eslint-disable-next-line max-len
      `В этом вызове ты можешь дать волю своим талантам! Профессионалы своего дела расскажут о развитии искусства сегодня. Ждем творческих личностей, готовых проявить себя на все 100!<br><br>

      Если ты выберешь этот вызов, тебе нужно будет сделать два задания:<br>
      1. Придумай задания, которые связывают хобби и творческое развитие ребенка с темой «Проведи лето с пользой»<br>
      2. Придумай и сними флэшмоб на одну из тематик в творческом направлении.`,

    offlineQuest:
      // eslint-disable-next-line max-len
      dobroeDeloText,
  },
};

export default config;
