/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT';

const person = Interlocutors.PETROV;

export const scenario_2_13: Scenario = {
  scenarioId: SceneId.scenario_2_13,
  type: ScenarioType.chat,
  data: {
    title: 'Иннополис',
    scene_id: 'scenario_2_13',
    startId: 'Q0/0',
    steps: {
      ...splitStep({
        id: 'Q0',
        person,
        text: `Привет, {{NAME}}! Ты как раз во время! Вижу, тебе удалось добыть все детали! [[Какой/какая]] же ты молодец! Давай их скорее сюда! 😍

Тепловая пушка почти готова, осталось только добавить те детали, которые ты [[принес/принесла]].`,
        nextStep: 'Q1',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Поиск деталей оказался очень увлекательным! По пути я [[узнал/узнала]] много нового и интересного! Вот все четыре детали, держи...',
            nextStep: 'Q1',
          },
          {
            id: 'b2',
            text: 'Конечно, пришлось повозиться, но я [[справилась/справился]]! Вот они, держи...',
            nextStep: 'Q1',
          },
        ],
      }),
      Q1: {
        person: Interlocutors.SYSTEM,
        id: 'Q1',
        type: StepType.message,
        text: `Петров добавляет недостающие детали к тепловой пушке.`,
        nextStep: 'Q2/0',
      },
      ...splitStep({
        id: 'Q2',
        person,
        text: `Вот это пушка так пушка! Лучшее из моих творений! Если бы не твоя помощь, то ничего бы не вышло! Спасибо тебе огромное! 😍

Скорее же включим ее в розетку и вынесем на улицу! Обитатели «Большой перемены» с нетерпением ждут потепления!`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ура! Мы сделали это! Давай включим ее и разморозим здесь все!',
            nextStep: 'Q3',
          },
        ],
      }),
      Q3: {
        person: Interlocutors.SYSTEM,
        id: 'Q3',
        type: StepType.message,
        text: `Поздравляем! Вы собрали пушку и «Большая перемена» начала размораживаться.`,
        nextStep: 'Q4/0',
      },
      ...splitStep({
        person,
        id: 'Q4',
        text: `Конечно, даже если она будет работать на всю катушку, потребуется какое-то время для разморозки парка... 

Но ничего! У меня есть еще одна идея! 😉`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Интересно, и какая идея?',
            nextStep: 'Q5/0',
          },
          {
            id: 'b2',
            text: 'Твоя очередная нестандартная идейка? Ну, выкладывай!',
            nextStep: 'Q5/0',
          },
        ],
      }),
      ...splitStep({
        person,
        id: 'Q5',
        text: `Пока пушка работает и отогревает «Большую перемену», мы пульнем в небо ракету, она разгонит ледяные тучи и поможет солнечным лучам проникнуть в парк. Так процесс разморозки пойдет в 2 раза быстрее! 😍

Вот только… Ракету украли… 🙁`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Не переживай! Я как раз [[выиграл/выиграла]] ракету в викторине, давай используем ее!',
            nextStep: EXIT_ID,
          },
          {
            id: 'b2',
            text: 'Да, жалко, конечно, будет использовать мою новую ракету. Я ведь в ней даже еще ни разу не [[посидел/посидела]]. Но раз для общего дела надо, то давай используем ее...',
            nextStep: EXIT_ID,
          },
        ],
      }),
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
