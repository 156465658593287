/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_kurganAlienFound_1_1_1: Scenario = {
  scenarioId: SceneId.stg2_kurganAlienFound_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Мамаев Курган',
    text: `Вы с Вжухом следуете за Женей на большую возвышенность — Мамаев курган. Место поражает своими масштабами и величеством. Вы видите много впечатляющих памятников, окруженных цветами.

    Удивительно, но на первый взгляд с ним ничего странного не происходит.`,
    buttons: ['Начать'],
  },
};
