/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../types/scenario';
import interlocutors from '../../interlocutors';
import { splitStep } from '../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT';
const TO_MAP_STEP = 'TO_MAP_STEP';

const person = Interlocutors.ENTREPRENEUR;

export const scenario_1_9_0: Scenario = {
  scenarioId: SceneId.scenario_1_9_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Башня Останкино',
    person: Interlocutors.SYSTEM,
    text: `Вы подходите к макету Останкинской телебашни. Она поражает своими грандиозными размерами!

У входа в телебашню стоит делового вида мужчина и поглядывает на часы. Вы начинаете с ним разговор...`,
    buttons: ['Начать'],
  },
};

const TEST_TVOI_VIBOR_2: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'Q7',
  name: 'Твой выбор 2/5',
  nextStep: 'Q8',
  order: [
    {
      description: '',
      subtest: 'T4',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person,
  type: StepType.test,
};

export const scenario_1_9: Scenario = {
  scenarioId: SceneId.scenario_1_9,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Башня Останкино',
    scene_id: 'scenario_1_9',
    startId: 'Q0/0',
    steps: {
      ...splitStep({
        type: StepType.message,
        id: 'Q0',
        person,
        text: `А, это же наш новый товарищ. {{NAME}}, если правильно помню. Меня зовут Сан Саныч. Я человек дела 👨‍💼

Я всегда держу слово и считаю, что честным трудом можно добиться чего угодно! Поэтому я выбрал для себя профессию предпринимателя.

Мой главный жизненный принцип: хочешь что-то получить — заработай! Я тоже когда-то был школьником, как ты, а теперь создаю полезные для людей проекты и неплохо на этом зарабатываю 😊`,
        nextStep: 'Q1',
      }),
      Q1: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q1',
        person,
        text: 'Поэтому меня привели в парк свои интересы. Так вот, скажу тебе сразу начистоту. Та искрящаяся штука в тумане МОЯ. Я точно знаю, где ее приспособить 😏',
        buttons: [
          {
            id: 'b1',
            text: 'Приятно познакомиться',
            nextStep: 'Q2',
          },
          {
            id: 'b2',
            text: 'Что-что за штука?',
            nextStep: 'Q3',
          },
          {
            id: 'b3',
            text: 'Извините, кажется, мне в другую сторону',
            nextStep: TO_MAP_STEP,
          },
        ],
      },
      Q2: {
        type: StepType.message,
        id: 'Q2',
        nextStep: 'Q2_2',
        person,
        text: 'Кхм, ну ладно. Добро пожаловать в Останкинскую телебашню! Настоящая расположена в Москве, а у нас тут копия, конечно же. Так вот, это телевизионная и радиовещательная башня, высота которой составляет 540,1 м.',
      },
      Q2_2: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q2_2',
        person,
        text: 'Если будешь в столице, обязательно сходи туда с родителями. Сверху открывается потрясающий вид на город. Мой друг там экскурсии водит. Я сам был, очень понравилось.',
        callToAction: 'Договорились?',
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо, буду иметь в виду',
            nextStep: 'Q3_0',
          },
          {
            id: 'b2',
            text: 'Спасибо, я знаю об этой башне',
            nextStep: 'Q3_0',
          },
        ],
      },
      Q3: {
        type: StepType.message,
        id: 'Q3',
        nextStep: 'Q3_01',
        person,
        text: 'А ты думаешь из-за чего весь сыр-бор? Почему парк обесточен и куда делись люди?..',
      },
      Q3_01: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q3_01',
        person,
        text: 'Но пока это все неважно. Давай к делу.',
        buttons: [
          {
            id: 'b1',
            text: 'Ок, спрошу об этом позже',
            nextStep: 'Q2',
          },
          {
            id: 'b2',
            text: 'Ну и ладно, тогда я пойду дальше',
            nextStep: TO_MAP_STEP,
          },
        ],
      },
      Q3_0: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q3_0',
        person,
        text: 'Связь с вышками разорвана 😔',
        callToAction: 'Поможешь восстановить и наладить вещание?',
        buttons: [
          {
            id: 'b1',
            text: 'Да, конечно',
            nextStep: 'Q3_1',
          },
        ],
      },
      Q3_1: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q3_1',
        person,
        text: 'Отлично, рад слышать! Для восстановления связи нам нужно определиться с темой первого репортажа. А то связь мы наладим, а показывать людям нечего — нехорошо.',
        callToAction: 'Выбери, о чем нам рассказать?',
        buttons: [
          {
            id: 'b1',
            text: 'Нужно поднять всем настроение и показать смешные видео с животными',
            nextStep: 'Q4',
          },
          {
            id: 'b2',
            text: 'Думаю, можно начать с новостей о том, что сейчас происходит в парке',
            nextStep: 'Q4',
          },
          {
            id: 'b3',
            text: 'Предлагаю снять репортаж обо мне, а то вдруг не все в курсе, что помощь уже здесь',
            nextStep: 'Q5',
          },
          {
            id: 'b4',
            text: 'Считаю важным поднять боевой дух, нужно подготовить какой-нибудь мотивирующий ролик',
            nextStep: 'Q4',
          },
          {
            id: 'b5',
            text: 'Может быть взять интервью у Учительницы?',
            nextStep: 'Q4',
          },
        ],
      },
      Q4: {
        person,
        type: StepType.message,
        id: 'Q4',
        nextStep: 'Q6',
        text: 'Да, хорошая идея, так и поступим. Хотя ты считаешь, что пока не время для полноценного репортажа, мы с другими обитателями хотим узнать больше информации о тебе — нашем спасителе. Поэтому будем задавать вопросы, не удивляйся 😉',
      },
      Q5: {
        person,
        type: StepType.message,
        id: 'Q5',
        nextStep: 'Q6',
        text: 'Отличная идея! Обитатели парка с радостью посмотрят такой репортаж. Сам подготовить материал и задать все вопросы не смогу, ведь у меня очень много дел, так что остальные мне помогут 😉',
      },
      Q6: {
        person,
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q6',
        text: 'Итак, первый вопрос. Можешь выбрать несколько вариантов из предложенных.',
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо',
            nextStep: 'Q7',
          },
        ],
      },
      Q7: TEST_TVOI_VIBOR_2,
      Q8: {
        text: '{{NAME}}, спасибо за ответы и за помощь! Теперь уж Останкино будет работать так же хорошо, как и прежде 😉',
        id: 'Q8',
        person,
        type: StepType.message,
        nextStep: 'Q9',
      },
      Q9: {
        text: 'Думаю, тебя ждут в других местах парка. До встречи!',
        id: 'Q9',
        person,
        type: StepType.message,
        nextStep: EXIT_ID,
      },
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_STEP]: {
        person,
        id: TO_MAP_STEP,
        type: StepType.to_map_step,
      },
    },
  },
};

export const scenario_1_9_1: Scenario = {
  scenarioId: SceneId.scenario_1_9_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.ENTREPRENEUR].name,
    person: Interlocutors.ENTREPRENEUR,
    text: 'Мы все еще делаем репортаж, твоя помощь пока не нужна.',
    buttons: ['Закрыть'],
  },
};

export const scenario_1_9_2: Scenario = {
  scenarioId: SceneId.scenario_1_9_2,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: '{{NAME}}, тут сейчас никого нет, все разбежались по делам. Приходи попозже.',
    buttons: ['Закрыть'],
  },
};
