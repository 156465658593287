/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  Step,
  StepType,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

export const scenario_3_13_0: Scenario = {
  scenarioId: SceneId.scenario_3_13_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Иннополис',
    text: 'Вы пришли в инженерную мастерскую Петрова в Иннополисе, здесь Синий продолжил свой рассказ...',
    buttons: ['Продолжить'],
  },
};

const EXIT_ID = 'EXIT_ID';

const ALT: Record<string, Step> = {
  ALT1: {
    person: Interlocutors.TEACHER,
    id: 'ALT1',
    text: 'Кстати, я как раз по пути сюда видела объявление, что в этом приюте, кажется, какие-то трудности… 😔',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Ой, а что там случилось?',
        nextStep: 'ALT2',
      },
      {
        id: 'b2',
        text: 'Не думаю, что там могут быть трудности, ведь в этом приюте достаточно много волонтеров',
        nextStep: 'Q5',
      },
    ],
  },
  ALT2: {
    person: Interlocutors.TEACHER,
    id: 'ALT2',
    text: 'Сегодня в приют привезли сразу 10 новых щенков. Сотрудники не ожидали такого неожиданного пополнения и срочно ищут волонтера для выгула собак, иначе малыши останутся без прогулки. А щенкам так важно гулять для гармоничного роста и развития.',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Я знаю отличный сервис по выгулу собак. Там работают настоящие профессионалы. Вот их номер телефона. (Игровое время не будет заморожено)',
        nextStep: 'ALT3',
      },
      {
        id: 'b2',
        text: 'Давайте я помогу найти выгульщика собак! По пути я [[видел/видела]] много ребят, [[уверен/уверена]] они согласятся. (Игровое время будет заморожено на 30 минут)',
        nextStep: 'ALT4',
      },
      {
        id: 'b3',
        text: 'Давайте я погуляю со щенками! (Игровое время будет заморожено на 3 часа)',
        nextStep: 'ALT5/0',
      },
    ],
  },
  ALT3: {
    person: Interlocutors.TEACHER,
    id: 'ALT3',
    type: StepType.message,
    text: `Спасибо большое, сейчас же с ними свяжусь. А пока продолжай слушать рассказ нашего пушистого гостя.`,
    nextStep: 'Q5',
  },
  ALT4: {
    person: Interlocutors.TEACHER,
    id: 'ALT4',
    type: StepType.message,
    text: `Спасибо тебе большое! Уверена, что ты [[нашел/нашла]] лучшего выгульщика для маленьких щенков. А теперь продолжим слушать увлекательный рассказ Синего.`,
    nextStep: 'Q5',
  },
  ...splitStep({
    person: Interlocutors.TEACHER,
    id: 'ALT5',
    type: StepType.message,
    text: `Сотрудники приюта передают большое спасибо! Пока ты [[гулял/гуляла]] со щенками, они нашли на завтра и последующие дни волонтеров. Ты их очень [[выручил/выручила]] 😍

А Синий как раз отдохнул и готов продолжить свой увлекательный рассказ.`,
    nextStep: 'Q5',
  }),
};

export const scenario_3_13: Scenario = {
  scenarioId: SceneId.scenario_3_13,
  type: ScenarioType.chat,
  data: {
    title: 'Иннополис',
    scene_id: 'scenario_3_13',
    startId: 'Q1/0',
    steps: {
      ...splitStep({
        person: Interlocutors.BLUE_CAT,
        id: 'Q1',
        type: StepType.message,
        text: `Вот здесь все и произошло! У меня дыхание перехватило от страха! Я попятился назад, случайно наткнулся на какой-то странный твердый предмет и нечаянно его включил. 

В тот же миг во все стороны повалил густой туман! 🙀`,
        nextStep: 'Q2',
      }),
      Q2: {
        person: Interlocutors.PETROV,
        id: 'Q2',
        type: StepType.message,
        text: `Так вот оно что! Это, Синий, ты испугался моей инженерной мастерской в Иннополисе и случайно наткнулся на выключатель недоделанной дым-машины...`,
        nextStep: 'Q3/0',
      },
      ...splitStep({
        person: Interlocutors.BLUE_CAT,
        id: 'Q3',
        type: StepType.message,
        text: `Мне хоть и удалось быстро нащупать рычаг и выключить этот аппарат дыма, но вокруг уже лежал непроглядный туман! Я испугался, что натворил дел и пустился наутек, дабы ничего больше случайно не задеть своей неуклюжей задней частью! 😿

Сидел, прятался в кустах, потом решил пойти в приют для животных, попросить еды и помощи. Пришел, а там маленькие щенки подняли ужасный лай! Их лай пробудил воспоминания, как меня еще маленьким синим котенком напугал и загнал на дерево соседский пес… У меня, конечно, девять жизней, но все они мне очень дороги и я побежал прочь из этого приюта 😿`,
        nextStep: 'Q4',
      }),
      Q4: {
        person: Interlocutors.TEACHER,
        id: 'Q4',
        type: StepType.message,
        text: `Какой кошмар! Неудивительно, что после таких «приключений» ты даже имя свое забыл.`,
        nextStep: 'ALT1',
      },

      ...ALT,

      Q5: {
        person: Interlocutors.CAT,
        id: 'Q5',
        type: StepType.message,
        text: `Синий, и что было дальше? Куда ты пошел после приюта?`,
        nextStep: 'Q6',
      },
      Q6: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q6',
        type: StepType.message,
        text: `После приюта я пошел туда, где вы меня нашли. Я увидел какие-то развалины и подумал, что там особо никто не ходит, и я смогу наконец-то перевести дух.`,
        nextStep: 'Q7',
      },
      Q7: {
        person: Interlocutors.PETROV,
        id: 'Q7',
        type: StepType.message,
        text: `Это место называется Херсонес.`,
        nextStep: 'Q8/0',
      },
      ...splitStep({
        person: Interlocutors.BLUE_CAT,
        id: 'Q8',
        text: `А, Херсонес... Очень красивое название 😻

Но даже там, в развалинах Херсонеса, меня снова ждали приключения. Когда я было решил, что можно отдохнуть, я снова увидел ЕЕ! Эту чудовищную дым-машину 🙀`,
        type: StepType.message,
        nextStep: 'Q8-1/0',
      }),
      ...splitStep({
        person: Interlocutors.PETROV,
        id: 'Q8-1',
        text: `Да, точно! Я же как раз попросил своего лаборанта отнести машину в Херсонес для тестирования. Но потом она куда-то делась, снова опустился туман и все заледенело…

Так значит, это...`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Неужели, это ты, Синий?',
            nextStep: 'Q9/0',
          },
        ],
      }),
      ...splitStep({
        person: Interlocutors.BLUE_CAT,
        id: 'Q9',
        type: StepType.message,
        text: `Да... Это моя вина 😿

Когда я снова увидел дым-машину, то забеспокоился, что кто-то такой же неуклюжий как я случайно наткнется на нее и включит. Поэтому решил перетащить ее в яму, подальше от греха и людских глаз.

Но пока перетаскивал, выключатель случайно зацепился за камень и сломался! И снова повалили этот дым, и чем дольше работала машина, тем холоднее становилось! Я понял, что натворил дел и что нужно срочно исправлять ситуацию! 🙀`,
        nextStep: 'Q10',
      }),
      Q10: {
        person: Interlocutors.PETROV,
        id: 'Q10',
        type: StepType.message,
        text: `И как же ты думал все исправить?`,
        nextStep: 'Q11',
      },
      Q11: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q11',
        type: StepType.message,
        text: `Я вспомнил, что видел в вашем парке большую библиотеку. Вот и решил, что в книгах найду чертежи машины и пойму, как ее починить.`,
        nextStep: 'Q12',
      },
      Q12: {
        person: Interlocutors.TEACHER,
        id: 'Q12',
        type: StepType.message,
        text: `Ты, наверное, имеешь в виду библиотеку МГУ — нашего главного университета.`,
        nextStep: 'Q13/0',
      },
      ...splitStep({
        person: Interlocutors.BLUE_CAT,
        id: 'Q13',
        type: StepType.message,
        text: `Да, наверное.

Туман к этому времени стал очень густым и я со всех четырех лап устремился к библиотеке. Но по пути, кажется, в спешке и по неосторожности кого-то сбил… 😿`,
        nextStep: 'Q14',
      }),
      Q14: {
        person: Interlocutors.TEACHER,
        id: 'Q14',
        type: StepType.message,
        text: `Кажется, этим кем-то была я 😔

Я как раз закончила работу и вышла из МГУ, чтобы направиться домой. Но повсюду уже был туман. А пройдя 10 метров, меня толкнуло что-то мягкое и я очутилась в яме. 

Так вот что это было… Вернее, кто 🙂`,
        nextStep: 'Q15',
      },
      Q15: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q15',
        type: StepType.message,
        text: `Ох! Дорогая Светлана Васильевна! Приношу Вам глубочайшие извинения, я вовсе не хотел Вас сбивать 🙀`,
        nextStep: 'Q16/0',
      },
      ...splitStep({
        id: 'Q16',
        person: Interlocutors.TEACHER,
        type: StepType.message,
        text: `Ничего, котик, ребята быстро меня нашли и вытащили из ямы. Все обошлось 😊

Тебе удалось найти чертежи в библиотеке?`,
        nextStep: 'Q17',
      }),
      Q17: {
        person: Interlocutors.PETROV,
        id: 'Q17',
        type: StepType.message,
        text: `Догадываюсь, что не удалось. Ведь это мое авторское изобретение, чертежи от машины есть только в моей инженерной мастерской.`,
        nextStep: 'Q18/0',
      },
      ...splitStep({
        person: Interlocutors.BLUE_CAT,
        id: 'Q18',
        text: `Да, Петров прав. Ничего нужного в библиотеке не было. Поэтому я помчался обратно в то место, где видел машину впервые. 

Там я нашел чертежи и понял, что мне нужна особенная ручка-переключатель, похожая на рычаг.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'И судя по тому, что из дым-машины на месте выключателя торчала ручка стоп-крана из поезда, дальше ты побежал к поезду?',
            nextStep: 'Q19',
          },
        ],
      }),
      Q19: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q19',
        type: StepType.message,
        text: `Да, но не сразу. Сначала я попробовал поискать рычаг в самой мастерской.`,
        nextStep: 'Q20',
      },
      Q20: {
        person: Interlocutors.PETROV,
        id: 'Q20',
        type: StepType.message,
        text: `А в мастерской такого не оказалось…Ведь он был единственный в своем роде. `,
        nextStep: 'Q21',
      },
      Q21: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q21',
        type: StepType.message,
        text: `Именно! Не оказалось... И, не теряя ни минуты, я помчался в другое место. Пойдемте, я вас отведу.`,
        nextStep: EXIT_ID,
      },

      [EXIT_ID]: {
        person: Interlocutors.TEACHER,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
