/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg3_meetingWithZhenya_1_1_0: Scenario = {
  scenarioId: SceneId.stg3_meetingWithZhenya_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Встреча с Женей',
    text: `Отойдя от Эрмитажа, вы вдруг увидели Женю, спешащую в сторону МГУ. Завидев вас, она улыбнулась и пошла навстречу.`,
    buttons: ['Начать'],
  },
};
