import React from 'react';

import PrivateRoute from '../PrivateRoute';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import DebugPage from '../../pages/DebugPage';
import LoginPage from '../../pages/LoginPage';
import ErrorPage from '../../pages/ErrorPage';
import ProfilePage from '../../pages/ProfilePage';
import OnboardingPage from '../../pages/OnboardingPage';

import { routes } from '../../../common/shared/constants/routes';
import { LayoutAuth } from '../../components/Layout/LayoutAuth';
import { ChatPageContainer as ChatPage } from '../../pages/ChatPage/ChatPage';
import { MapPage } from '../../pages/MapPage/MapPage';
import { useSelector } from 'react-redux';
import { selectScenarios, selectToken, selectUserState } from '../../redux/user/userGetters';
import { selectActiveChatScenario, selectHasUnsaved } from '../../redux/scenario/scenarioGetters';

import { FreezeModal } from '../FreezeModal';
import { isDebug } from '../../../common/shared/constants/constants';
import { HasUnsavedPage } from '../../pages/HasUnsavedPage';

const Router = () => {
  const userState = useSelector(selectUserState);
  const token = useSelector(selectToken);
  const userScenarios = useSelector(selectScenarios).scenarios;

  const scene = useSelector(selectActiveChatScenario);
  const hasUnsaved = useSelector(selectHasUnsaved);

  return (
    <BrowserRouter>
      <Switch>
        <Route path={routes.login}>{token ? <Redirect to={routes.map} /> : <LoginPage />}</Route>

        <Route path={routes.loginDenied}>
          <ErrorPage
            title='К сожалению, Вы не можете принять участие в игре'
            btnText='Вернуться на главную'
            description=''
            isLoginDeniedPage={true}
            hideButton={false}
          />
        </Route>

        <Route path={routes.error}>
          <ErrorPage
            title='500 упс...'
            btnText='Вернуться в профиль'
            description='Упс. Что-то пошло не так, но мы скоро все починим'
          />
        </Route>

        <PrivateRoute path={routes.onboarding}>
          {userState && userState.isOnboardingCompleted ? (
            <Redirect to={routes.map} />
          ) : (
            <OnboardingPage />
          )}
        </PrivateRoute>

        <PrivateRoute exact path={routes.dashboard}>
          {userState && !userState.isOnboardingCompleted ? (
            <Redirect to={routes.onboarding} />
          ) : (
            <LayoutAuth>
              <MapPage />
            </LayoutAuth>
          )}
        </PrivateRoute>

        <PrivateRoute exact path={routes.map}>
          <LayoutAuth>
            <MapPage />
          </LayoutAuth>
        </PrivateRoute>
        <PrivateRoute exact path={routes.sync}>
          {hasUnsaved ? (
            <LayoutAuth>
              <HasUnsavedPage />
            </LayoutAuth>
          ) : (
            <Redirect to={routes.dashboard} />
          )}
        </PrivateRoute>

        <PrivateRoute exact path={routes.profile}>
          <LayoutAuth withGrid withMentor>
            <ProfilePage />
          </LayoutAuth>
        </PrivateRoute>

        <PrivateRoute exact path={routes.chat}>
          {!scene || Object.keys(userScenarios).length === 0 ? (
            <Redirect to={routes.map} />
          ) : (
            <LayoutAuth withGrid isFixed>
              <ChatPage />
            </LayoutAuth>
          )}
        </PrivateRoute>

        {isDebug && (
          <Route exact path={routes.debug}>
            <DebugPage />
          </Route>
        )}

        <Route>
          <ErrorPage />
        </Route>
      </Switch>
      <FreezeModal />
    </BrowserRouter>
  );
};

export default Router;
