/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg3_innopolice_1_1: Scenario = {
  scenarioId: SceneId.stg3_innopolice_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Иннополис',
    scene_id: 'stg3_innopolice_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `О, вы уже вернулись! А у нас как раз время обеда. Угощайтесь 🥪`,
        id: 'Q1',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо! А мы были на космодроме. Там такой ветер, что ракета не взлетит. Кстати, получилось починить передатчик?',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        text: `Починить-починили. Но без подходящего электропитания сигнал не добьёт до корабля пришельцев. Поэтому нужно доставить к Останкинской башне не только радиостанцию, но и батареи для неё. Вот они, 4 ящика!

        Саныч, придется нам снова побыть грузчиками!
        `,
        id: 'Q2',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Нужно придумать другой вариант! Это будет слишком тяжело.',
            nextStep: 'Q3',
          },
        ],
      },

      Q3: {
        text: `Позвольте посмотреть, как устроено электропитание в вашем приборе.`,
        id: 'Q3',
        person: Interlocutors.ALIEN,
        type: StepType.message,
        nextStep: 'Q4',
      },

      Q4: {
        text: `Пожалуйста, вот ноль, фаза, земля…`,
        id: 'Q4',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Сложно 🤓',
            nextStep: 'Q5',
          },
        ],
      },

      Q5: {
        text: `Квилл покопался в проводах радиостанции, а потом достал из-за пазухи светящуюся зеленым коробку и показал Петрову.`,
        id: 'Q5',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q6',
      },

      Q6: {
        text: `Это батарея, такие использует наш вид. Я думаю, её мощности хватит, чтобы питать этот радиопередатчик для связи с нашим кораблём.`,
        id: 'Q6',
        person: Interlocutors.ALIEN,
        type: StepType.message,
        nextStep: 'Q7',
      },

      Q7: {
        text: `Квилл подключил свою батарею к радиопередатчику, все лампочки на корпусе тут же загорелись. Он выставил мощность на максимум, после чего достал карманное радио Бабули и что-то покрутил на нём.`,
        id: 'Q7',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q8',
      },

      Q8: {
        text: `Не перестаю удивляться. Вот это технологии!`,
        id: 'Q8',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Вот бы и нам такое изобрести! ',
            nextStep: 'Q9',
          },
        ],
      },

      Q9: {
        text: `Я настроил радиопередатчик. Теперь он транслирует сигнал на частоте моего вида в космос и перенаправляет его на карманное радио Бабули.`,
        id: 'Q9',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Молодец, Квилл! То есть теперь мы можем связываться с кораблём твоих сородичей откуда угодно?',
            nextStep: 'Q10',
          },
        ],
      },

      Q10: {
        text: `Верно. Нам это понадобится на космодроме.

        И запомните: не связывайтесь с кораблём без веской причины. Мой вид этого терпеть не может!`,
        id: 'Q10',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо, Квилл.',
            nextStep: 'Q11',
          },
        ],
      },

      Q11: {
        text: `Пойдемте скорее на космодром! Нам еще весь парк спасать.`,
        id: 'Q11',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Идём!',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
