import { ReactElement, RefObject } from 'react';

export enum MapSourceStage {
  'stage1' = 'stage1',
  'stage2' = 'stage2',
  'stage2act2' = 'stage2act2',
  'stage2act3' = 'stage2act3',
  'stage3act1' = 'stage3act1',
  'stage3act2' = 'stage3act2',
}

enum MapSourceSet {
  'mobile' = 'mobile',
  'laptop' = 'laptop',
  'desktop' = 'desktop',
}

export interface MapSourceConfig {
  map: Record<MapSourceStage, Record<MapSourceSet, string>>;
  fog: Record<MapSourceStage, Record<MapSourceSet, string>>;
}

export interface MapSourceProps {
  pins?: ReactElement[];
  stage: MapSourceStage;
  innerRef: RefObject<HTMLDivElement>;
  currentStageName: any;
}
