/* eslint-disable max-len */
import React, { Fragment, useCallback, useState } from 'react';
import { ChallengeProps } from './ChallengeTypes';
import * as S from './ChallengeStyled';

import colors from '../../../common/shared/constants/colors';
import IconArrowDown from '../../../assets/icons/IconArrowDown';

import { useDispatch } from 'react-redux';
import { setChallengeState, setOnCallSelected } from '../../redux/user/userActions';
import { checkLinkValid } from '../../redux/scenario/scenarioActions';

import { Box } from '../../../common/shared/ui/Box';
import { Text } from '../../../common/shared/ui/Text';
import Link from '../Link';
import { Input } from '../../../common/shared/ui/Input';
import { Button } from '../../../common/shared/ui/Button';
import UploadFiles from '../UploadFiles';
import useViewport from '../../hooks/useViewport';
import { GameModal } from '../GameModal';
import { Interlocutors } from '../../types/scenario';
import config from '../Challenges/config';
import { selectUserFiles, selectUserInfo } from '../../redux/user/userGetters';
import { useSelector } from 'react-redux';

const Challenge = (props: ChallengeProps) => {
  const { data, userData } = props;
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const [isOpened, setIsOpened] = useState(false);
  const [postLink, setPostLink] = useState('');
  const userFiles = useSelector(selectUserFiles);

  const challengeUrl = userFiles?.find((url: any) => !url.mimetype.includes('video'));

  const { isMobile } = useViewport();

  const toggleOpened = useCallback(() => {
    setIsOpened((value) => !value);
  }, []);

  const onSaveDoc = useCallback(
    (urlDoc: string) => {
      return new Promise<boolean>((resolve) => {
        GameModal.open(
          'Система',
          'После нажатия кнопки «Отправить» ты не сможешь внести изменения. И твои ответы отправятся на проверку. Ты [[уверен/уверена]], что выполнил все задания до конца?',
          Interlocutors.SYSTEM,
          ['Отправить', 'Отмена'],
          true,
          (btn) => {
            if (btn === 'Отправить') {
              dispatch(setChallengeState(data.value, { urlDoc }));
              resolve(false);
              return;
            }
            return;
          },
        );
      });
    },
    [props, dispatch],
  );

  const onCallSelected = useCallback(() => {
    GameModal.open(
      'Система',
      'После нажатия кнопки «Выбрать» ты не сможешь изменить вызов. Ты [[уверен/уверена]] в своем выборе?',
      Interlocutors.SYSTEM,
      ['Выбрать', 'Отменить'],
      true,
      (btn) => {
        if (btn === 'Выбрать') {
          let number = 0;
          Object.values(config).filter((item: any, index) => {
            if (data.value === item.value) {
              number = index;
            }
          });
          dispatch(setOnCallSelected(number));
          return;
        }
      },
    );
  }, [props, postLink, dispatch]);

  return (
    <S.StyledChallenge>
      <S.StyledChallengeTopContainer onClick={toggleOpened}>
        <Box $align='center'>
          <S.StyledChallengeIcon src={props.data.icon} alt={props.data.title} />
          <Text $textStyle='p15'>{props.data.title}</Text>

          {userData?.urlDoc && userData?.urlPost && (
            <svg
              style={{ marginLeft: 12 }}
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M4.49951 12.4545L9.65576 17L19.4995 7'
                stroke='#808191'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          )}
        </Box>
        <S.StyledMore $isOpened={isOpened}>
          {!isMobile && (
            <Text $textStyle='p13' $color={colors.blueButton}>
              {isOpened ? 'Скрыть' : 'Раскрыть'}
            </Text>
          )}
          <div />
          <IconArrowDown />
        </S.StyledMore>
      </S.StyledChallengeTopContainer>
      <S.StyledChallengeBottomContainer $isOpened={isOpened}>
        {/* <Text $textStyle={'p14'} $fontWeight={'800'} $color={colors.title}>
          Онлайн и оффлайн задание
        </Text> */}
        <Text $textStyle='p13' isHtml>
          {userInfo?.challenge ? data.description.split('<br>')[0] : data.description}
        </Text>

        {/* {!userInfo?.challenge && !userInfo?.files.challenge ? (
          <Button
            disabled={false}
            $maxW='200px'
            $minW='120px'
            $mT={'12px'}
            $style='blue'
            onClick={onCallSelected}>
            <Box $justify='center' $isWrap={false} $direction='row'>
              Выбрать вызов
            </Box>
          </Button>
        ) : null} */}
      </S.StyledChallengeBottomContainer>
      {/* <div>
        {!userData?.urlDoc && userInfo?.challenge && !userInfo?.files.challenge ? (
          <>
            <Text $textStyle='p13'>
              Скачай файл с полным описанием заданий. Выполни их в этом же файле и загрузи в
              соответствующее поле. Обрати внимание: загрузить можно только один файл! Если тебе
              необходимо объединить несколько файлов в один, воспользуйся сервисом{' '}
              <a href='https://www.ilovepdf.com/ru' target='_blank' rel='noopener noreferrer'>
                https://www.ilovepdf.com/ru
              </a>{' '}
              или любым другим для объединения файлов
            </Text>
            <Button
              disabled={false}
              $maxW='200px'
              $minW='120px'
              $mT='16px'
              $mB='16px'
              $style='blue'>
              <Text $color={colors.white} $textStyle='p13' $isFullWidth>
                <Link displayAs='a' $textStyle={'p13'} $color={colors.white} to={data.doc}>
                  Скачать файл
                </Link>
              </Text>
            </Button>
          </>
        ) : null}

        {userInfo?.challenge && !userInfo?.files.challenge ? (
          <S.StyledChallengeUploadContainer>
            <UploadFiles
              description='1 файл формата .doc,.docx,.txt,.odt,.pdf до 10 Мбайт'
              uploadLink='/documents'
              accept='.doc,.docx,.txt,.odt,.pdf'
              $size={!isMobile ? 'small' : 'large'}
              type='progress'
              onSaveHandler={onSaveDoc}
              instruction={{ url: data.doc, title: data.title }}
              fileType='challenge'
            />
          </S.StyledChallengeUploadContainer>
        ) : null}
      </div> */}
      <Box $direction='column'>
        {userInfo?.files.challenge ? (
          <Fragment>
            <Text $textStyle={'p14'} $color={colors.title}>
              Отправленный тобой файл с выполненным заданием ты можешь посмотреть
              <Link displayAs='a' $textStyle={'p14'} $color={colors.blue} to={challengeUrl?.url}>
                {' '}
                по ссылке
              </Link>
            </Text>
          </Fragment>
        ) : null}
      </Box>
    </S.StyledChallenge>
  );
};

export default Challenge;
