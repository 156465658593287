/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../scenario';
import interlocutors from '../../../interlocutors';

export const scenario_2_8: Scenario = {
  scenarioId: SceneId.scenario_2_8,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `Привет, {{NAME}}, ты [[видел/видела]]? На Космодром привезли новую ракету! Какая она красивая! 😍

Только пока не наша… Поговаривают, ее привезли бизнесмены из Москвы и хотят разыграть в викторине.

Кстати! Чуть не забыл! Петров повсюду тебя ищет! Кажется, ему снова нужна твоя помощь. Скорее беги в Иннополис, он ждет тебя.`,
    buttons: ['Закрыть'],
  },
};
