/* eslint-disable max-len */
import { Interlocutors, Step, StepType, SystemStepType } from '../../../types/scenario';
import { splitStep } from '../../../utils/ScenarioUtils';
import { images } from './images';

const person = Interlocutors.ENTREPRENEUR;

export const LOGIC_TEST_PART1: Record<string, Step> = {
  ...splitStep({
    person,
    id: 'LOGIC_TEST_PART1_1',
    text: `Смотри, условия достаточно простые. Тебе нужно решить 10 логических задачек за 20 минут. Какие-то из них будут проще, какие-то посложнее и, возможно, даже понадобится ручка и лист бумаги, чтобы что-то вычислять.

Важный момент: принять участие в этой викторине можно только один раз. Повторно к нему вернуться будет нельзя. А это очень важно, понимаешь? Поучаствовать в викторине нужно обязательно!`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Да, понимаю',
        nextStep: 'LOGIC_TEST_PART1_2/0',
      },
    ],
  }),
  ...splitStep({
    person,
    id: 'LOGIC_TEST_PART1_2',
    text: `Итак, сначала я буду говорить задание или показывать картинку, тебе нужно найти ответ на вопрос, вписать в поле ввода и нажать «Отправить ответ». Некоторые задания потребуют последовательного ввода и отправки ответов, как та задачка про гномов. Будь [[внимателен/внимательна]]! Но не переживай, ты поймешь из вопроса что и как нужно ответить.

Не торопись, на все 10 вопросов у тебя будет 20 минут, но и не затягивай сильно с поиском решения!`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Хорошо, все понятно!',
        nextStep: 'LOGIC_TEST_PART1_3',
      },
    ],
  }),
  LOGIC_TEST_PART1_3: {
    person,
    id: 'LOGIC_TEST_PART1_3',
    type: StepType.message,
    nextStep: 'LOGIC_TEST_PART1_4',
    text: 'Ну тогда поехали! Организаторы уже запускают таймер! И удачи нам!',
  },
  LOGIC_TEST_PART1_4: {
    person,
    nextStep: 'LOGIC_TEST_PART1_Q1',
    id: 'LOGIC_TEST_PART1_4',
    type: StepType.system,
    _meta: {
      type: SystemStepType.limitedTimeGroup,
      time: 20 * 60,
      timeoutStep: 'Q12',
      exitStep: 'TEST_EXIT',
    },
  },

  LOGIC_TEST_PART1_Q1: {
    person,
    id: 'LOGIC_TEST_PART1_Q1',
    text: 'Найди зашифрованное слово',
    image: { uri: images.logic_1, title: '' },
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q1',
    nextStep: 'LOGIC_TEST_PART1_Q2',
  },
  LOGIC_TEST_PART1_Q2: {
    person,
    id: 'LOGIC_TEST_PART1_Q2',
    text: 'Напиши истинное математическое равенство, где вместо букв стоят цифры: ЛИСА + ВОЛК = ЗВЕРИ',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q2',
    nextStep: 'LOGIC_TEST_PART1_Q3',
  },
  LOGIC_TEST_PART1_Q3: {
    person,
    id: 'LOGIC_TEST_PART1_Q3',
    text: 'Расставь коробки в порядке увеличения массы. Желтая коробка тяжелее красной, желтая и зеленая вместе по массе равны двум красным.',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q3',
    nextStep: 'LOGIC_TEST_PART1_Q4',
  },
  LOGIC_TEST_PART1_Q4: {
    person,
    id: 'LOGIC_TEST_PART1_Q4',
    text: 'Выбери самую легкую коробку',
    image: { uri: images.logic_2, title: '' },
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q4',
    nextStep: 'LOGIC_TEST_PART1_Q5',
  },
  LOGIC_TEST_PART1_Q5: {
    person,
    id: 'LOGIC_TEST_PART1_Q5',
    text: 'Реши, какие числа должны быть на месте фигур (квадрата, звездочки, треугольника и круга) так, чтобы стрелки шли от большего числа к меньшему',
    image: { uri: images.logic_3, title: '' },
    type: StepType.message,
    nextStep: 'LOGIC_TEST_PART1_Q5_1',
  },
  LOGIC_TEST_PART1_Q5_1: {
    person,
    id: 'LOGIC_TEST_PART1_Q5_1',
    text: 'Что на месте квадрата?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_1',
    nextStep: 'LOGIC_TEST_PART1_Q5_2',
  },
  LOGIC_TEST_PART1_Q5_2: {
    person,
    id: 'LOGIC_TEST_PART1_Q5_2',
    text: 'На месте звездочки?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_2',
    nextStep: 'LOGIC_TEST_PART1_Q5_3',
  },
  LOGIC_TEST_PART1_Q5_3: {
    person,
    id: 'LOGIC_TEST_PART1_Q5_3',
    text: 'На месте треугольника?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_3',
    nextStep: 'LOGIC_TEST_PART1_Q5_4',
  },
  LOGIC_TEST_PART1_Q5_4: {
    person,
    id: 'LOGIC_TEST_PART1_Q5_4',
    text: 'На месте круга?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_4',
    nextStep: 'LOGIC_TEST_PART1_Q6',
  },

  LOGIC_TEST_PART1_Q6: {
    person,
    id: 'LOGIC_TEST_PART1_Q6',
    text: 'Напиши через запятую всех ребят слева направо по старшинству. Справа самый старший. Даня старше всех. Марк моложе четверых. Тимур старше одного и младше троих. Сеня моложе Гоши.',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q6',
    nextStep: 'LOGIC_TEST_PART1_Q7',
  },

  LOGIC_TEST_PART1_Q7: {
    person,
    id: 'LOGIC_TEST_PART1_Q7',
    text: 'Дуб толще клена, но тоньше баобаба. Осина тоньше клена, но толще рябины.',
    type: StepType.message,
    nextStep: 'LOGIC_TEST_PART1_Q7_1',
  },
  LOGIC_TEST_PART1_Q7_1: {
    person,
    id: 'LOGIC_TEST_PART1_Q7_1',
    text: 'Напиши, какое дерево самое тонкое?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q7_1',
    nextStep: 'LOGIC_TEST_PART1_Q7_2',
  },
  LOGIC_TEST_PART1_Q7_2: {
    person,
    id: 'LOGIC_TEST_PART1_Q7_2',
    text: 'Напиши, какое дерево самое толстое?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q7_2',
    nextStep: 'LOGIC_TEST_PART1_Q8',
  },

  LOGIC_TEST_PART1_Q8: {
    person,
    id: 'LOGIC_TEST_PART1_Q8',
    text: 'Напиши, кто самый молодой? Сергей Петрович старше, чем Антон Андреевич, но моложе, чем Василий Витальевич. А Пётр Петрович — старший брат Сергея Петровича.',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q8',
    nextStep: 'LOGIC_TEST_PART1_Q9',
  },

  LOGIC_TEST_PART1_Q9: {
    person,
    id: 'LOGIC_TEST_PART1_Q9',
    text: 'У Сони, Маши, Антона, Кости и Юры есть домашние животные. У каждого живет или собака, или кошка, или попугай. Девочки не держат собак, а мальчики попугаев. У Сони и Маши разные питомцы, а у Маши с Антоном одинаковые. У Сони нет кошки. У Кости и Юры одинаковые животные, а у Антона с Костей разные. Какие животные живут у каждого?',
    type: StepType.message,
    nextStep: 'LOGIC_TEST_PART1_Q9_1',
  },
  LOGIC_TEST_PART1_Q9_1: {
    person,
    id: 'LOGIC_TEST_PART1_Q9_1',
    text: 'Кто живет у Сони?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q9_1',
    nextStep: 'LOGIC_TEST_PART1_Q9_2',
  },
  LOGIC_TEST_PART1_Q9_2: {
    person,
    id: 'LOGIC_TEST_PART1_Q9_2',
    text: 'Кто живет у Маши?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q9_2',
    nextStep: 'LOGIC_TEST_PART1_Q9_3',
  },
  LOGIC_TEST_PART1_Q9_3: {
    person,
    id: 'LOGIC_TEST_PART1_Q9_3',
    text: 'Кто живет у Антона?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q9_3',
    nextStep: 'LOGIC_TEST_PART1_Q9_4',
  },
  LOGIC_TEST_PART1_Q9_4: {
    person,
    id: 'LOGIC_TEST_PART1_Q9_4',
    text: 'Кто живет у Кости?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q9_4',
    nextStep: 'LOGIC_TEST_PART1_Q9_5',
  },
  LOGIC_TEST_PART1_Q9_5: {
    person,
    id: 'LOGIC_TEST_PART1_Q9_5',
    text: 'Кто живет у Юры?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q9_5',
    nextStep: 'LOGIC_TEST_PART1_Q10',
  },
  LOGIC_TEST_PART1_Q10: {
    person,
    id: 'LOGIC_TEST_PART1_Q10',
    text: 'На столе стоят вазы: голубая, зеленая, розовая и оранжевая. Третьей в ряду стоит та ваза, название цвета которой содержит больше всего букв. А зеленая стоит между оранжевой и розовой. Какая ваза стоит последней?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q10',
    nextStep: 'Q8',
  },
};
