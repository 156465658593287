/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId, StepType } from '../../../scenario';

const EXIT_ID = 'EXIT_ID';

export const scenario_3_21: Scenario = {
  scenarioId: SceneId.scenario_3_21,
  type: ScenarioType.chat,
  data: {
    title: 'Иннополис',
    scene_id: 'scenario_3_21',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q1',
        type: StepType.message,
        text: `Любимая хозяйка! Я думал, что никогда больше Вас не увижу! 😻`,
        nextStep: 'Q2',
      },
      Q2: {
        person: Interlocutors.SOFIA,
        id: 'Q2',
        type: StepType.message,
        text: `Малевич, котик мой! Какое счастье видеть тебя живым! Дай-ка на тебя взглянуть... Как же ты исхудал, бедняга! Да и, наверное, столько всего натерпелся! 😭`,
        nextStep: 'Q3',
      },
      Q3: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q3',
        type: StepType.message,
        text: `Ох! Натерпелся, не то слово! Я Вам все обязательно расскажу, когда мы вернемся домой. А сейчас давайте я Вас познакомлю со своими спасителями 😺`,
        nextStep: 'Q4',
      },
      Q4: {
        person: Interlocutors.SOFIA,
        id: 'Q4',
        type: StepType.message,
        text: `Дорогие! Спасибо вам огромное за спасение моего Малевича! Я просто не представляю своей жизни без него! Это очень редкая порода котов, с тонкой душевной организацией, выведенная моим отцом.`,
        nextStep: 'Q5',
      },
      Q5: {
        person: Interlocutors.CAT,
        id: 'Q5',
        text: `Да, мы все отметили аристократичные манеры Малевича и его необычные говор. Мы все здесь очень рады, что вы воссоединились 😺`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да… Хоть Малевич и натворил дел, но все в итоге хорошо закончилось!',
            nextStep: 'Q6',
          },
        ],
      },
      Q6: {
        person: Interlocutors.SOFIA,
        id: 'Q6',
        type: StepType.message,
        text: `Господи! Каких таких дел? Я все компенсирую! 😱`,
        nextStep: 'Q7',
      },
      Q7: {
        person: Interlocutors.CAT,
        id: 'Q7',
        text: `Что Вы! Никаких компенсаций! В нашем парке давно не было таких веселых деньков! Мы еще спасибо должны сказать за все приключения, которые нас очень сплотили и сделали «Большую перемену» еще лучше! 😺`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Пойдемте, мы Вам лучше экскурсию проведем по нашему чудесному парку',
            nextStep: 'Q8',
          },
        ],
      },
      Q8: {
        person: Interlocutors.CAT,
        id: 'Q8',
        type: StepType.message,
        text: `Кстати! С сегодняшнего дня парк снова открывает свои двери для посетителей! Поэтому у нас сегодня будет очень оживленно. А ребят встретят интересные испытания и вызовы.`,
        nextStep: 'Q9',
      },
      Q9: {
        person: Interlocutors.SOFIA,
        id: 'Q9',
        type: StepType.message,
        text: `С большим удовольствием прогуляюсь по вашему парку!`,
        nextStep: EXIT_ID,
      },

      [EXIT_ID]: {
        person: Interlocutors.TEACHER,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
