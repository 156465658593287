import appSlice, { windowSizes } from '../../redux/app';
import { AppThunk } from '../../types';

const {
  setViewport: setViewportAction,
  share: shareAction,
  setShowMobileMenu,
  setShowVideoAttachmentModal,
} = appSlice.actions;

export const setViewport =
  (viewport: windowSizes): AppThunk =>
  async (dispatch) => {
    dispatch(setViewportAction(viewport));
  };

export const share =
  (shared: Record<string | number, any>): AppThunk =>
  async (dispatch) => {
    dispatch(shareAction(shared));
  };

export const setShowMobileMenuAsync =
  (value: boolean): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    if (state.app.isShowMobileMenu === value) return;

    dispatch(setShowMobileMenu(value));
  };

export const setShowVideoAttachmentModalAsync =
  (value: boolean): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    if (state.app.isShowVideoAttachmentModal === value) return;

    dispatch(setShowVideoAttachmentModal(value));
  };
