import styled, { css } from 'styled-components';
import { HeroProps } from './HeroTypes';
import { BREAKPOINT_PHONE } from '../../../common/shared/constants/constants';
import { BaseStyled } from '../../../common/shared/styles/styledBase';

export const StyledHero = styled.div<HeroProps>`
  display: flex;
  flex-direction: column;

  position: relative;
  width: 100%;

  background: #ffffff;
  border-radius: 16px;
`;

export const StyledApperanceHero = styled.div<{ $background: string; $isAvatar?: boolean }>`
  ${({ $isAvatar }) =>
    $isAvatar
      ? css`
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 20px;
          overflow: hidden;
        `
      : css`
          width: 100%;
          height: 208px;
          border-radius: 16px;
        `}

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $background }) => $background && `background: ${$background};`}

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    ${({ $isAvatar }) => ($isAvatar ? '' : 'height: 425px;')}
  }
`;

export const StyledClothesHero = styled.div<HeroProps>`
  padding: 20px 20px 60px;

  & > * + * {
    margin-top: 40px;
  }
`;

export const StyledApperanceHeroSaveButtonGenderItem = styled.div<{ $active?: boolean }>`
  width: 90px;
  height: 90px;
  border-radius: 16px;
  background: #eeeeee;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  ${({ $active }) => $active && `border: 2px solid #3e68ff;`}

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    width: 46px;
    height: 46px;
    border-radius: 8px;
  }
`;

export const StyledApperanceHeroSaveButtonGenderItemImage = styled.img`
  width: 70px;
  height: 70px;

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    width: 34px;
    height: 34px;
  }
`;

export const StyledApperanceHeroSaveButtonGenderContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 10px;
  }
`;

export const StyledApperanceHeroSaveButtonGender = styled(BaseStyled)`
  width: 90px;
  height: 28px;
  border-radius: 9px;
  background: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledApperanceHeroSaveButton = styled.div`
  left: 0;
  bottom: 0;
  padding: 15px;

  height: 100%;

  max-width: 240px;
  position: absolute;
  z-index: 20;

  button {
    white-space: nowrap;
    padding: 5px 22px;
  }

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    padding: 20px 30px;
  }
`;

export const StyledApperanceHeroView = styled.div<{ $isAvatar?: boolean }>`
  height: ${({ $isAvatar }) => ($isAvatar ? '130%' : '100%')};
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    user-select: none;
    pointer-events: none;
  }
`;

export const StyledApperanceHeroViewHair = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;

  z-index: 3;
`;

export const StyledApperanceHeroViewFace = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;

  z-index: 2;
`;

export const StyledApperanceHeroViewBody = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;

  z-index: 1;
`;

export const StyledApperanceHeroViewBase = styled.img`
  height: 100%;
`;
