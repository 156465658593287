/* eslint-disable max-len */
import {
  Action,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  Step,
  StepType,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT_ID';
const person = Interlocutors.GRANDMOTHER;

const ALT: Record<string, Step> = {
  ...splitStep({
    person,
    id: 'ALT1',
    text: `А вот и поезд!

Эх, жалко только, что мой внучок Костик сегодня не с нами. Ему бы очень понравилось расследовать это дело. Да и на ярмарку он тоже хотел посмотреть, но не смог прийти… 😔`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'А что случилось? Почему он не пришел?',
        nextStep: 'ALT2',
      },
      {
        id: 'b2',
        text: 'Ну ничего, ярмарка будет еще несколько дней, а с расследованием все-таки тянуть нельзя...',
        nextStep: 'Q1',
      },
    ],
  }),
  ALT2: {
    person,
    id: 'ALT2',
    text: 'Да никак не может освоить математику. Я не знаю, как ему объяснить, а много денег на репетиторов нет. Вот и приходится ему самому сидеть с утра до ночи… Но только толку от этого мало 🙁️ ',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Не переживайте, Бабуля, все наладится. Многие школьники через это проходят, математика — непростая наука. (Игровое время не будет заморожено)',
        nextStep: 'ALT3',
      },
      {
        id: 'b2',
        text: 'Я знаю, как Вам быть. На Ютубе есть бесплатный канал по математике, там все очень понятно объясняют и к тому же это абсолютно бесплатно. Давайте, я Вам покажу. (Игровое время будет заморожено на 5 минут)',
        nextStep: 'ALT4',
      },
      {
        id: 'b3',
        text: 'А давайте я подтяну Вашего внука по провисающим темам. (Игровое время будет заморожено на 30 минут)',
        nextStep: 'ALT5',
      },
    ],
  },
  ALT3: {
    person,
    id: 'ALT3',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Я [[рад/рада]], что [[смог/смогла]] Вас приободрить! Давайте вернемся к поиску улик',
        nextStep: 'Q1',
      },
    ],
    text: `Спасибо, {{NAME}}! [[Успокоил/Успокоила]] сердце старушки 😊`,
  },
  ALT4: {
    person,
    id: 'ALT4',
    text: `Вот здорово — до чего техника дошла! Теперь мой Костик точно освоит этот непростой предмет! 😊`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Ну вот и здорово! А теперь давайте вернемся к поиску улик',
        nextStep: 'Q1',
      },
    ],
  },
  ALT5: {
    person,
    id: 'ALT5',
    text: `[[Какой/Какая]] же ты [[умный/умная]]! Теперь Костик точно выровняет оценки в школе. Большое тебе спасибо, {{NAME}}! 😊`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: '[[Рад/Рада]], что [[смог/смогла]] помочь! А теперь давайте вернемся к поиску улик',
        nextStep: 'Q1',
      },
    ],
  },
};

export const scenario_3_2_i: Scenario = {
  scenarioId: SceneId.scenario_3_2_i,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Эрмитаж',
    text: `Вас встречает спутанный «Интеллектом 2.0» Эрмитаж. Атланты разбрелись кто куда, один сидит на ступеньках и чешет ногу, другой плавает в Неве. Внутри герои картин разбежались по разным местам: купидон со стрелами летает по бульварам Монмартра, юноша с лютней с картины Караваджо играет Венере, мужчины с картины Веласкеса пытаются позавтракать яйцами Фаберже.`,
    buttons: ['Поговорить со Вжухом'],
  },
};

export const scenario_3_2: Scenario = {
  scenarioId: SceneId.scenario_3_2,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Эрмитаж',
    scene_id: 'scenario_3_2',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.SYSTEM,
        id: 'Q1',
        text: `«Вжух, как нам навести порядок в этом хаосе? Должно же быть какое-то средство»?

          «Что ещё, Вжух, этому мему уже лет 5», — отвечает тебе кот.
          
          «Малевич, булочка моя», — Софья кидается к коту.
          `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Поговорить со Вжухом',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        person: Interlocutors.CAT,
        id: 'Q2',
        text: `«Как меня можно было с ним спутать», — надулся кот.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Поговорить с Малевичем',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q3',
        text: `Спасибо, что спасли моего человека. За это я помогу вам. Вы не сможете навести тут порядок сами, потребуется помощь. Следуйте за мной.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Следовать за Малевичем',
            nextStep: 'Q4',
          },
        ],
      },
      Q4: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q4',
        text: `Вы подходите к огромной золотой композиции, созданной из множества деталей: большой павлин восседает сверху на дереве, справа от него стоит петух, а слева — сова и белка с орешком.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Малевич, что это?',
            nextStep: 'Q5',
          },
        ],
      },
      Q5: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q5',
        text: `О, многие думают, что это просто часы. Но Эрмитажные коты знают, что это ещё и тайный ход. 
          Нужно завести механизм в правильном порядке, тогда он нам откроется. 
          
          Легенда гласит: «Первая просыпается ночью, второй затмевает красотой мир, третий пробуждает к новой жизни».
          
          Ну что ж, давайте пробовать. Заводите.
          `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Завести в порядке: Белку, Павлина, Петуха',
            nextStep: 'Q5_1',
          },
          {
            id: 'b2',
            text: 'Завести в порядке: Сову, Павлина, Петуха',
            nextStep: 'Q5_2',
          },
          {
            id: 'b3',
            text: 'Завести в порядке: Сову, Павлина, Белку',
            nextStep: 'Q5_1',
          },
        ],
      },
      Q5_1: {
        person: Interlocutors.SYSTEM,
        id: 'Q5_1',
        text: `Ты заводишь механизм. Ничего не происходит. Кажется, нужно выбрать другую комбинацию. Вспомни легенду.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Попробовать ещё раз',
            nextStep: 'Q5',
          },
        ],
      },
      Q5_2: {
        person: Interlocutors.SYSTEM,
        id: 'Q5_2',
        text: `Ты заводишь механизм. Сначала тишина. И вдруг животные оживают: сова открывает глаза, павлин раскрывает свой хвост, а петух поднимает клюв. Получилось!
          Фигуры разъезжаются, под часами открывается тайный ход. Ну что ж, в путь!
          `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спуститься в ход',
            nextStep: 'Q6',
          },
        ],
      },
      Q6: {
        // ТЕСТ НА АЛЬТРУИЗМ 10
        person: Interlocutors.SYSTEM,
        id: 'Q6',
        text: `Вы спускаетесь в подземелья Эрмитажа. На древних стенах висят масляные лампы, коридоры расходятся в разных направлениях. 
          Вдруг вы замечаете мышку, попавшую в мышеловку. Вжух с Малевичем шипят.
          `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спасти мышку',
            nextStep: 'Q7',
          },
          {
            id: 'b2',
            text: 'Пройти мимо',
            nextStep: 'Q9',
          },
        ],
      },
      Q7: {
        person: Interlocutors.SYSTEM,
        id: 'Q7',
        text: `Игнорируя шипение и недовольство котов, вы освобождаете мышь из мышеловки. 

          «Спасибо», — отвечает мышка. «Как я могу вас  отблагодарить»?
          
          «Нам нужно достать Хронограф. Мы должны восстановить порядок в Эрмитаже и парке», — отвечает Малевич.
          
          Мышонок пугается, но, подумав, отвечает: «Я вас провожу, вы спасли мне жизнь».
          `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Следовать за мышонком',
            nextStep: 'Q8',
          },
        ],
      },
      Q8: {
        // ТЕСТ НА АЛЬТРУИЗМ 11
        person: Interlocutors.SYSTEM,
        id: 'Q8',
        text: `Ты снимаешь лампу со стены, и вы отправляетесь следом за крошечным мышонком по тёмным подземельям Эрмитажа. 

          Вдруг вам преграждает дорогу высокая грозная статуя. Двухметровая богиня с головой львицы сидит на троне. 
          
          «Я - владычица пустыни, дарующая жизнь, Сехмет. Остановись предо мной. Дальше пройдут не все. Один из вас должен остаться со мной».
          `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Остаться с богиней Сехмет',
            nextStep: 'Q8_1',
          },
          {
            id: 'b2',
            text: 'Попросить Вжуха остаться с богиней Сехмет',
            nextStep: 'Q8_2',
          },
        ],
      },
      Q8_1: {
        // ТЕСТ НА АЛЬТРУИЗМ
        person: Interlocutors.SYSTEM,
        id: 'Q8_1',
        text: `Ты остаешься с богиней Сехмет, а твои друзья двигаются дальше. 

          Вдруг богиня обращается к тебе: «Только смелый и благородный человек пожертвует собой ради друзей. Открыв своё сердце, ты откроешь двери». На коленях богини появляется ключ. 
          
          «Спеши, друзьям нужна твоя помощь», — говорит богиня и замирает.
          `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Забрать ключ и догнать друзей',
            nextStep: 'Q9',
          },
        ],
      },
      Q8_2: {
        person: Interlocutors.SYSTEM,
        id: 'Q8_2',
        text: `Оставив друга с богиней, вы отправляетесь дальше. 
          Немного пройдя,вы замечаете огромные каменные двери.
          `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Подойти к дверям',
            nextStep: 'Q9',
          },
        ],
      },
      Q9: {
        person: Interlocutors.SYSTEM,
        id: 'Q9',
        text: `На высоких каменных дверях нарисованы мыши: они словно идут друг за другом, неся в руках свечи, книги и камни. 

          Надпись на дверях гласит: «Двьри Mysь. Молви дроугь и гряди».
          `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Открыть дверь ключом Mysь',
            nextStep: 'Q10',
          },
          {
            id: 'b2',
            text: 'Попросить мышонка помочь',
            nextStep: 'Q9_2',
          },
          {
            id: 'b3',
            text: 'Попросить помощи у Малевича',
            nextStep: 'Q9_2',
          },
        ],
      },
      Q9_2: {
        person: Interlocutors.SYSTEM,
        id: 'Q9_2',
        text: `Все вместе вы произносите вместе слово «Друг». Именно это и есть разгадка к шифру врат. Ведь только друзьям открываются двери Mysь.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Войти во врата',
            nextStep: 'Q10',
          },
        ],
      },
      Q10: {
        person: Interlocutors.SYSTEM,
        id: 'Q10',
        text: `За дверями на постаменте под колпаком вы видите золотой хронограф. 
          На постаменте надпись «Искусство способно остановить время и ускорить его». Публий Сыр. Мышь.
          `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Забрать Хронограф и подняться в залы Эрмитажа',
            nextStep: 'Q11',
          },
        ],
      },
      Q11: {
        person: Interlocutors.SYSTEM,
        id: 'Q11',
        text: `Вы забираете Хронограф и поднимаетесь наверх к экспозициям. 
          Вы запускаете его механизм и персонажи картин начинают возвращаться на свои места, атланты устало возвращаются под своды Эрмитажа, а статуи занимают прежние позы. Мыслитель допивает кофе и садится думать о вечности.
          
          «Спасибо вам, дальше мы сами», — говорит Малевич. 
          «Думаю, пора нам отправиться к Петрову», — говоришь ты.
          `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Попрощаться с Софьей и Малевичем и отправиться в «Москва-Сити»',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.TEACHER,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
