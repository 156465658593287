/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg3_meetingWithZhenya_1_1: Scenario = {
  scenarioId: SceneId.stg3_meetingWithZhenya_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Встреча с Женей',
    scene_id: 'stg3_meetingWithZhenya_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Привет! А Петров сказал срочно всем идти в МГУ. Вы не слышали? `,
        id: 'Q1',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Нет, но у нас тут важное дело. Ищем, чем удивить пришельцев!',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        text: `Хм, я, конечно, не пришелец, но меня всегда удивляют древности. 

        А тут неподалеку как раз есть очень древнее место. Пойдём, посмотрим!
        `,
        id: 'Q2',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Э-э… Ладно 😬',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
