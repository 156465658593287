import React from 'react';
import { Text } from '../Text';
import { HeaderModalVideoText } from './ModalAttachmentVideoStyled';
import colors from '../../constants/colors';

export const IntroductionText = () => {
  return (
    <HeaderModalVideoText $textStyle='p18'>
      <Text $textStyle='p18' $fontWeight='800' $color={colors.title} $mB='10px'>
        Видеовизитка
      </Text>
      Прием видеовизиток завершен
      {/* Запиши видеовизитку продолжительностью 1-2 минуты. В ней расскажи самое важное и интересное о
      себе. Это могут быть твои достижения или хобби, мечта, или проект, который планируешь
      реализовать. Рассказывай о том, что для тебя важно, что ценишь, и чем по-настоящему гордишься.
      <br />
      <br />
      Еще мы очень хотим узнать о твоих родных или о тех людях, которых ты считаешь своей семьей.
      Ведь именно семья помогла тебе стать тем, кем ты являешься сейчас. Возможно, у вас есть
      какие-то семейные традиции или знаменитые родственники, которыми вы гордитесь. Или твоя семья
      поддерживает тебя так, что тебе любая задача по плечу.
      <br />
      <br /> В визитке мы хотим видеть тебя и слышать твой голос. Ты можешь монтировать видео,
      вставлять изображения, части других видео, но не забывай, что важно не качество видео, а
      рассказ о тебе. Хотя мы очень любим необычные рассказы… Даже простая видеовизитка, снятая
      одним дублем на камеру телефона, это здорово, если рассказывает о прекрасном человеке. Таком,
      как ты. <br />
      <br />
      Немного технических подробностей:
      <li>формат - mр4,</li>
      <li>объём файла - до 50 Мбайт,</li>
      <li>продолжительность - до двух минут.</li> */}
    </HeaderModalVideoText>
  );
};
