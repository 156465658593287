import React from 'react';

import { StyledHeader, StyledHeaderWrapper } from './HeaderStyled';

import Logo from '../../components/Logo';
import MobileMenu from '../../components/MobileMenu';
import HeaderUserBar from '../../components/HeaderUserBar';

export const Header: React.FC = () => {
  return (
    <>
      <StyledHeader>
        <StyledHeaderWrapper>
          <Logo withLink />
          <HeaderUserBar />
        </StyledHeaderWrapper>
      </StyledHeader>

      <MobileMenu />
    </>
  );
};
