import React, { ChangeEvent, KeyboardEvent, useCallback } from 'react';
import { NickNameProps } from './NickNameTypes';

import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo } from '../../redux/user/userGetters';
import { setActiveSceneVariablesAsync } from '../../redux/scenario/scenarioActions';

import colors from '../../../common/shared/constants/colors';

import { Box } from '../../../common/shared/ui/Box';
import { Text } from '../../../common/shared/ui/Text';
import { Button } from '../../../common/shared/ui/Button';
import NormalInput from '../NormalInput';
import { StyledNickName } from './NickNameStyled';
import useViewport from '../../hooks/useViewport';
import { MentorForm } from '../MentorForm';

export const formatNickName = (str: string): string => {
  str = str.replace(/\s/g, '');

  if (str.length > 0) {
    str = str[0].toUpperCase() + str.slice(1);
  } else if (str.length === 1) {
    str = str[0].toUpperCase();
  }

  if (str.length > 16) {
    return str.slice(0, 16);
  }

  return str;
};

const onKeyPress = (event: KeyboardEvent) => {
  const isSpaceInput = event.charCode === 32;
  if (isSpaceInput) {
    event.preventDefault();
  }
};

const NickName = (props: NickNameProps) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserInfo);

  const { isMobile } = useViewport();

  const [isEditing, setEditingState] = React.useState(false);
  const [nickName, setNickName] = React.useState(user?.profile?.name || '');

  const onSubmit = useCallback(() => {
    if (isEditing) {
      if (nickName !== user?.profile?.name) {
        const variables = user?.variables || {};

        const copy = { ...variables };
        copy.NAME = nickName;

        dispatch(setActiveSceneVariablesAsync(copy));
      }

      setEditingState(false);
    } else {
      setEditingState(true);
    }
  }, [isEditing, nickName, dispatch, user]);

  const onChangeNickName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setNickName(formatNickName(e.target.value));
  }, []);

  return (
    <StyledNickName>
      <Box $align='center' $isWrap={false} $direction={'row'} $justify={'space-between'}>
        <Text $textStyle='p18' $color={colors.title} $fontWeight='800'>
          Никнейм
        </Text>
        {isEditing ? null : (
          <div onClick={onSubmit} style={{ cursor: 'pointer' }}>
            <Text $textStyle='p18' $fontWeight={'600'} $color={colors.blueButton}>
              {'Изменить'}
            </Text>
          </div>
        )}
      </Box>
      <NormalInput
        maxLength={16}
        value={nickName}
        disabled={!isEditing}
        onKeyPress={onKeyPress}
        onChange={onChangeNickName}
      />
      {isEditing ? (
        <Button
          $maxW='120px'
          $minW='120px'
          $mL={'0'}
          $mT={isMobile ? '12px' : '0'}
          $style='blue'
          onClick={onSubmit}>
          <Box $justify='center' $isWrap={false} $direction='row'>
            {'Сохранить'}
          </Box>
        </Button>
      ) : null}
      {/* {isMobile ? <MentorForm /> : null} */}
    </StyledNickName>
  );
};

export default NickName;
