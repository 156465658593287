import { colors } from '../../constants/colors';
import { BREAKPOINT_SMALL_PHONE } from '../../constants/constants';
import styled, { css } from 'styled-components';

export const StyledDivider = styled.div<{
  $isMobileMenuDivider?: boolean;
  $isOnboardingDivider?: boolean;
}>`
  display: flex;
  height: 1px;
  min-height: 1px;
  background: ${colors.grey220};

  margin-top: 8px;
  margin-bottom: 8px;

  @media screen and (min-width: ${BREAKPOINT_SMALL_PHONE}) {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  ${(p) => {
    if (p.$isOnboardingDivider) {
      return css`
        margin-left: 15px;
      `;
    }

    if (p.$isMobileMenuDivider) {
      return css`
        margin-left: -14px;
        width: calc(100% + 28px);
      `;
    }

    return css`
      margin-left: 16px;
      margin-right: 16px;
    `;
  }}
`;
