/* eslint-disable max-len */
import {
  Conditions,
  Interlocutors,
  QuestionButton,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../types/scenario';
import { splitStep } from '../../../utils/ScenarioUtils';

const buttons: QuestionButton[] = [
  {
    id: 'b1',
    text: 'Что тут все же произошло?',
    nextStep: 'Q5',
  },
  {
    id: 'b2',
    text: 'Где мы сейчас находимся?',
    nextStep: 'Q6/0',
  },
  {
    id: 'b3',
    text: 'Расскажи о себе!',
    nextStep: 'Q7',
  },
  {
    id: 'b4',
    text: 'Что дальше?',
    nextStep: 'Q8',
  },
];

const TO_LK_STEP = 'TO_LK_STEP';
const EXIT_ID = 'EXIT_ID';
// начать отсюда
export const scenario_1_1: Scenario = {
  scenarioId: SceneId.scenario_1_1,
  type: ScenarioType.chat,
  data: {
    scene_id: 'scenario_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        type: StepType.text_question,
        id: 'Q1',
        person: Interlocutors.CAT,
        text: 'Как тебя зовут?',
        callToAction: 'Напиши свое имя',
        nextStep: 'Q2',
        variable: 'NAME',
      },
      Q2: {
        type: StepType.message,
        id: 'Q2',
        person: Interlocutors.CAT,
        text: `О, так значит,ты помнишь кто ты, {{NAME}}! 😻 Видишь ли, у нас тут происходит что-то необъяснимое: никто из обитателей парка не помнит, как его зовут и кто он. Люди ведут себя странно, насколько я могу судить со своей кошачьей позиции.`,
        nextStep: 'Q2_2',
      },
      Q2_2: {
        type: StepType.message,
        id: 'Q2_2',
        person: Interlocutors.CAT,
        text: `Вот например, одна бабуля почему-то вращает маяк, часы идут в обратную сторону (да, я знаю в какую сторону идут часы, ведь меня кормят по часам), люди появляются там, где их не должно быть, а там где они раньше были, их больше нет. Небо меняет цвет каждые два часа, в городе возникают странные дыры, в которых пропадают предметы и люди, а море вращается воронкой и очень-очень тихо — ни шелеста деревьев, ни пения птиц.🙀`,
        nextStep: 'Q2_3',
      },
      Q2_3: {
        type: StepType.question,
        id: 'Q2_3',
        person: Interlocutors.CAT,
        maxAnswers: 1,
        text: `Даже я не помню, как всё это произошло, но я хотя бы помню, как меня зовут. Кстати, я Вжух. Но если ты всё помнишь, то ты единственный, кто сможет нам помочь. 😿`,
        callToAction: 'Ну что, ты поможешь?',
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q3',
            text: 'Да, конечно!',
          },
          {
            id: 'b2',
            nextStep: 'Q4',
            text: 'Слушай, мне хотелось бы в этом побольше разобраться.',
          },
        ],
      },
      Q3: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q3',
        person: Interlocutors.CAT,
        text: 'Отлично! Для начала предлагаю отправиться к морю. Может быть у тебя есть ко мне вопросы, пока мы идем?',
        callToAction: 'Выбери вариант',
        buttons: buttons,
      },
      Q4: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q4',
        person: Interlocutors.CAT,
        text: 'Да, тут определенно не помешает информация, я постараюсь ответить на твои вопросы.',
        callToAction: 'Выбери вариант',
        buttons: buttons,
      },
      Q5: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q5',
        person: Interlocutors.CAT,
        text: 'Как я говорил, жители парка потеряли память, что-то странное творится с природой и реальностью. Но мы все выясним.',
        callToAction: 'Выбери вариант',
        buttons: buttons,
      },
      ...splitStep({
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q6',
        person: Interlocutors.CAT,
        text: `Ты находишься в «Большой перемене». Это уникальный парк, где собраны копии самых интересных и удивительных достопримечательностей России!

        Наш парк — это место, где каждый занимался любимым делом (я, например, лежал на солнышке у моря и ловил радужных рыб). В лесах и морях жили прекрасные создания, а люди радовались жизни, пока не произошли очень странные дела… 😿
        
        Сейчас в парке полный хаос: люди забыли себя и друг друга, никто не запускает ракеты в космос, не программирует новых андроидов, не учит детей, небо меняет цвет по два раза в день, море то пропадает, то появляется в виде черного пятна!🙀
        
        Мы не теряем надежду и знаем, что ты восстановишь прежнюю жизнь в парке!😻
        
        Скоро лето и ты должен был провести свои лучшие каникулы, а жители должны были отпраздновать день рождения парка «Большая перемена», но теперь всё зависит только от тебя. Я бы хотел ещё разок погреться на солнышке и поесть рыбку, приготовленную бабулей😸`,
        callToAction: 'Выбери вариант',
        buttons: buttons,
      }),
      Q7: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q7',
        person: Interlocutors.CAT,
        text: 'Я — кот, и кажется, я живу здесь. Но это неточно. 😸',
        callToAction: 'Выбери вариант',
        buttons: buttons,
      },
      Q8: {
        type: StepType.message,
        id: 'Q8',
        person: Interlocutors.CAT,
        text: 'Пойдем, я покажу тебе наш когда-то замечательный парк 😿',
        nextStep: EXIT_ID,
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
