/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const scenario_egg5_0: Scenario = {
  scenarioId: SceneId.scenario_egg5_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: 'Природные достояния России',
    person: Interlocutors.SYSTEM,
    text: `Вы заметили у озера что-то блестящее, но это оказались блики на воде. В солнечную погоду водная гладь становится словно зеркало и отражает лучи.`,
    buttons: ['Закрыть'],
  },
};
