import { useRunScenarioAsync } from '../useRunScenarioAsync';
import { useCallback } from 'react';
import { Interlocutors, SceneId, Step } from '../../resources/scenario';
import { PointsOfInterest, UnsavedScripts } from '../../types';
import { PointsClickHandlers } from './types';
import { GameModal } from '../../components/GameModal';
import LocalStorage from '../../../common/shared/utils/LocalStorage';
import { STORE_UNSAVED_SCRIPTS } from '../../../common/shared/constants/constants';

export function createNavigateToPointLink(text: string, point: PointsOfInterest) {
  return `<a class="point-anchor" data-point="${point}">${text}</a>`;
}

export function useRunScenarioSequence() {
  const runScenario = useRunScenarioAsync();
  return useCallback(
    (
      id: SceneId,
      onSubmitId: SceneId,
      onCompleteId: SceneId = onSubmitId,
      skipIfExist: Partial<Record<SceneId, any>> = {},
    ) => {
      if (skipIfExist[onSubmitId]) {
        runScenario(onCompleteId);
        return;
      }
      if (skipIfExist[id]) {
        runScenario(onSubmitId);
        return;
      }
      runScenario(id, () => runScenario(onSubmitId));
    },
    [runScenario],
  );
}

export function toArray(
  handlers: Partial<Record<PointsOfInterest, (() => void) | undefined>>,
): PointsClickHandlers {
  const res: PointsClickHandlers = [];
  for (const pointId in handlers) {
    const point = pointId as PointsOfInterest;
    const handler = handlers[point];
    if (handler) {
      res.push({
        point,
        handler,
      });
    }
  }
  return res;
}

export function preventLeaveTestOnClick(e: any) {
  // @ts-ignore
  if (window.lockChatExit) {
    e.preventDefault();
    GameModal.open(
      'Система',
      'Вам нужно ответить на этот вопрос, иначе «Большая перемена» останется без ракеты.',
      Interlocutors.SYSTEM,
      [],
      true,
    );
  }
}

export function storeUnsavedScript(userId: string, sceneId: SceneId, history: Step[]) {
  const saved = LocalStorage.get<UnsavedScripts>(STORE_UNSAVED_SCRIPTS) || {};
  const user = saved[userId] || {};
  user[sceneId] = history;
  const res = { ...saved, [userId]: user };
  LocalStorage.set(STORE_UNSAVED_SCRIPTS, res);
}

export function removeUnsavedScript(userId: string, sceneId: SceneId) {
  const saved = LocalStorage.get<UnsavedScripts>(STORE_UNSAVED_SCRIPTS) || {};
  const user = saved[userId] || {};
  delete user[sceneId];
  const res = { ...saved, [userId]: user };
  LocalStorage.set(STORE_UNSAVED_SCRIPTS, res);
}
