import styled from 'styled-components';
import colors from '../../../common/shared/constants/colors';

export const StyledBackpack = styled.div`
  padding: 20px;
  border-radius: 16px;
  background: ${colors.white};

  & > * + * {
    margin-top: 20px;
  }
`;
