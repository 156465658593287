import React from 'react';
import { Button } from '../../../common/shared/ui/Button';
import { NewModal } from '../../../common/shared/ui/NewModal';
import { Box } from '../../../common/shared/ui/Box';
import { Icon } from '../../../common/shared/ui/Icon';

import imageSystem from '../../../assets/images/persons/system.svg';

import {
  MapModalButtonsStyled,
  MapModalImgStyled,
  MapModalTextStyled,
  MapModalTitleStyled,
} from '../../../fifthGrade/pages/MapPage/MapPageStyled';
import { DividerStyled, TitleWrapperStyled } from '../../../fifthGrade/components/GameModal';
import { TemplatedText } from '../../../fifthGrade/components/TemplatedText';

export const ModalWindow = ({ setState }: any): React.ReactElement => {
  const handleClick = () => {
    setState(false);
  };

  return (
    <NewModal
      title={
        <TitleWrapperStyled>
          <MapModalTitleStyled>Система</MapModalTitleStyled>
          <MapModalImgStyled src={imageSystem} />
          <DividerStyled />
        </TitleWrapperStyled>
      }
      isOpen={true}>
      <MapModalTextStyled $textStyle='p18'>
        <TemplatedText isHTML>
          Мы зафиксировали смену класса в настройках профиля, и поэтому твой прогресс был обнулён.
          Не переживай и начни игру заново.
        </TemplatedText>
        <TemplatedText isHTML>Желаем успехов в конкурсе!</TemplatedText>
      </MapModalTextStyled>
      <MapModalButtonsStyled>
        <Button key={'buttonText'} $textStyle='p18' $fontWeight='500' onClick={handleClick}>
          <Box $justify='space-between' $isWrap={false} $align='center'>
            <div>Закрыть</div>
            <Icon $mL='12px' icon='ico_chevron' />
          </Box>
        </Button>
      </MapModalButtonsStyled>
    </NewModal>
  );
};
