import { useMemo } from 'react';
import { ID, to } from './scenarioHelpers/ID';
import { useSelector } from 'react-redux';
import { selectScenarios } from '../redux/user/userGetters';

export function useIsMentorVisible() {
  const scenarios = useSelector(selectScenarios);
  return useMemo(
    () => {
      return false;
    },
    // Boolean(
    //   scenarios.scenarios?.[to[ID.trainRepairHandle]] ||
    //     scenarios.startedScenarios?.[to[ID.trainRepairHandle]],
    // ),
    [scenarios],
  );
}
