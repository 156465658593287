import 'reset-css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './common/shared/styles/_fonts.css';
import './fifthGrade/utils/ScenarioValidator';

import Main from './main';
import GlobalStyles from './common/shared/styles/GlobalStyles';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalStyles />
      <Main />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
