import React from 'react';

import logout from '../../../assets/images/logout.svg';
import useLogout from '../../hooks/useLogout';
import useViewport from '../../hooks/useViewport';

import Burger from '../Burger/Burger';
import HeaderUser from '../HeaderUser';
import { Button } from '../../../common/shared/ui/Button';
import { StyledHeaderUserBar } from './HeaderUserBarStyled';

const HeaderUserBar = () => {
  const { isMobile } = useViewport();
  const { handleLogout } = useLogout();

  if (isMobile) return <Burger />;

  return (
    <StyledHeaderUserBar>
      <HeaderUser />
      <Button $size='s' $style='grey' onClick={handleLogout}>
        <img src={logout} alt='icon' />
      </Button>
    </StyledHeaderUserBar>
  );
};

export default HeaderUserBar;
