/* eslint-disable max-len */
import {
  Step,
  SceneId,
  StepType,
  Scenario,
  ScenarioType,
  Interlocutors,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const scenario_2_8_i: Scenario = {
  scenarioId: SceneId.scenario_2_8_i,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Мост на остров Русский',
    text: `Гигантский вантовый мост длиной 3,1 километра протянулся от Владивостока до Русского острова через пролив Босфор. 

    Но тут вы понимаете, что машин нет. Как же пересечь пролив?
    `,
    buttons: ['Искать транспорт'],
  },
};

export const scenario_2_8: Scenario = {
  scenarioId: SceneId.scenario_2_8,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    scene_id: 'scenario_2_8',
    startId: 'Q1',
    title: 'Мост на остров Русский',
    steps: {
      Q1: {
        // ТЕСТ НА АЛЬТРУИЗМ 7
        text: `В поисках транспорта вы бродите по набережной, где замечаете старичка-отшельника, который что-то тянет из воды.  
          Он машет вам рукой и зовет к себе.
          `,
        id: 'Q1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Подойти к старичку',
            nextStep: 'Q1_1',
          },
          {
            id: 'b2',
            text: 'Пройти мимо',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        text: `Вы проходите дальше по набережной и замечаете брошенный велосипед. Кажется, на нем можно добраться до острова, хоть это и займёт немало времени.`,
        id: 'Q3',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться на остров Русский',
            nextStep: EXIT_ID,
          },
        ],
      },
      Q1_1: {
        // ТЕСТ НА АЛЬТРУИЗМ 8
        text: `Вы подходите к старичку и замечаете, что в руках у него большие сети, течение несёт их в пролив. 

          «Помогите мне вытянуть сети, я ловлю морских ежей. Если вы поможете мне, то я угощу вас вкусным ужином».
          `,
        id: 'Q1_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Простите, у нас нет времени',
            nextStep: 'Q3',
          },
          {
            id: 'b2',
            text: 'Вытянуть сети, но отказаться от ужина',
            nextStep: 'Q2',
          },
          {
            id: 'b3',
            text: 'Вытянуть сети и согласиться на ужин',
            nextStep: 'Q4',
          },
        ],
      },
      Q2: {
        text: `Вы помогаете старичку вытянуть сети, но отказываетесь от ужина. Он спрашивает вас, куда вы направляетесь и вы отвечаете, что вам нужно попасть на остров Русский. Старичок улыбается и кивает на паром: он работает его смотрителем уже 20 лет. 
          Он предлагает вам помочь переправиться на остров.
          `,
        id: 'Q2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться на остров Русский',
            nextStep: EXIT_ID,
          },
        ],
      },
      Q4: {
        text: `Вы помогаете старичку вытянуть сети и с благодарностью соглашаетесь на ужин. Старичок жарит на костре ароматную рыбу и морских ежей. Вы ужинаете под шум воды и крики чаек. 

          Старичок спрашивает, знаете ли вы легенду про пиратское золото?
          `,
        id: 'Q4',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Послушать легенду про золото',
            nextStep: 'Q5',
          },
        ],
      },
      Q5: {
        text: `Старичок рассказывает легенду: “Существует легенда, что хунхузские разбойники добывали на острове Аскольд благородный металл и прятали его на Русском. Только перед этим накладывали на него страшное проклятье. Позже моряки выгнали пиратов с острова. Пираты, в свою очередь, не успели захватить награбленное золото и спешно закопали его в недрах острова Русский. И, по слухам, именно на месте захоронения местные жители периодически видят в лесу блеск золотых монет.” 
        
        Дослушав легенду, вы благодарите отшельника и прощаетесь. Он спрашивает вас, куда вы направляетесь, и вы отвечаете, что вам нужно попасть на остров Русский. Старичок улыбается и кивает на паром: он работает его смотрителем уже 20 лет. 
        Он предлагает вам помочь переправиться на остров.`,
        id: 'Q5',
        person: Interlocutors.SYSTEM, // !!!! TODO: заменить на отшельника
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться на остров Русский',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
