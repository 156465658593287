import React from 'react';

import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../redux/user/userGetters';

import { getFullName } from '../../utils/ScenarioUtils';
import { HeaderUserProps } from './HeaderUserTypes';
import { StyledHeaderUser, StyledHeaderUserName } from './HeaderUserStyled';
import { useNavigateToProfile } from '../../hooks/useNavigateToProfile';
import { Avatar } from '../../components/Avatar';
import { useIsProfileAvailable } from '../../hooks/useIsProfileAvailable';

const HeaderUser = (props: HeaderUserProps) => {
  const user = useSelector(selectUserInfo);

  const navigateToProfile = useNavigateToProfile();
  const isProfileAvailable = useIsProfileAvailable();

  if (!user) return null;

  return (
    <StyledHeaderUser>
      <Avatar onClick={isProfileAvailable ? navigateToProfile : undefined} />
      <StyledHeaderUserName $textStyle='p13'>
        {user.profile?.name || getFullName(user.name_first, user.name_last)}
      </StyledHeaderUserName>
    </StyledHeaderUser>
  );
};

export default HeaderUser;
