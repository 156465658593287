/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_innopolice_1_1_0: Scenario = {
  scenarioId: SceneId.stg2_innopolice_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.CAT,
    title: 'Иннополис',
    text: `Благодаря запущенным вами в первом этапе солнечным батареям, в Иннополисе снова горит свет и многие посетители и студенты вернулись сюда.`,
    buttons: ['Начать'],
  },
};
