import React, { useCallback, useEffect, useState } from 'react';

import { Box } from '../../../common/shared/ui/Box';
import { Button } from '../../../common/shared/ui/Button';
import ItemsBar from '../ItemsBar';

import { HeroProps } from './HeroTypes';
import { femaleConfig, maleConfig, ProfileConfig } from './config';

import { setHeroAsync } from '../../redux/user/userActions';
import { selectUserInfo } from '../../redux/user/userGetters';
import { useDispatch, useSelector } from 'react-redux';

import {
  StyledHero,
  StyledClothesHero,
  StyledApperanceHero,
  StyledApperanceHeroView,
  StyledApperanceHeroViewFace,
  StyledApperanceHeroViewHair,
  StyledApperanceHeroViewBody,
  StyledApperanceHeroViewBase,
  StyledApperanceHeroSaveButton,
  StyledApperanceHeroSaveButtonGender,
  StyledApperanceHeroSaveButtonGenderItem,
  StyledApperanceHeroSaveButtonGenderContainer,
  StyledApperanceHeroSaveButtonGenderItemImage,
} from './HeroStyled';

import colors from '../../../common/shared/constants/colors';
import { Logger } from '../../../common/shared/utils/Logger';
import useHeroConfig from '../../hooks/useHeroConfig';

const Hero = (props: HeroProps) => {
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);

  const [isEdit, setEditState] = React.useState(false);
  const [isLoading, setLoadingState] = React.useState(false);
  const [isButtonEnabled, setButtonEnabled] = useState(false);

  const {
    hair,
    face,
    body,
    config,
    background,
    setHair,
    setFace,
    setBody,
    setBackground,
    setMaleConfig,
    setFemaleConfig,
  } = useHeroConfig();

  useEffect(() => setButtonEnabled(false), [isEdit]);
  useEffect(() => setButtonEnabled(true), [hair, face, body, background]);

  const toggleEditState = useCallback(async () => {
    if (isLoading) return;

    if (isEdit) {
      setLoadingState(true);

      try {
        const newConfig: ProfileConfig = {
          bodyId: body.id,
          hairId: hair.id,
          faceId: face.id,
          backgroundId: background.id,
          male: config.id === maleConfig.id,
          female: config.id === femaleConfig.id,

          name: userInfo?.profile?.name || '',
        };

        await dispatch(setHeroAsync(newConfig));
        setEditState(false);
      } catch (ignore) {
        alert('Что-то пошло не так при сохранении изменений');
        Logger.log(ignore);
      } finally {
        setLoadingState(false);
      }
    } else {
      setEditState(true);
    }
  }, [isEdit, config, body, hair, face, userInfo, background, isLoading, dispatch]);

  return (
    <StyledHero>
      <StyledApperanceHero $background={background.icon}>
        <StyledApperanceHeroView>
          <StyledApperanceHeroViewHair src={hair.template} />
          <StyledApperanceHeroViewFace src={face.template} />
          <StyledApperanceHeroViewBody src={body.template} />
          <StyledApperanceHeroViewBase src={config.base} />
        </StyledApperanceHeroView>

        <StyledApperanceHeroSaveButton>
          <Box $justify='space-between' $height='100%' $direction='column'>
            {isEdit && !userInfo?.profile?.bodyId ? (
              <StyledApperanceHeroSaveButtonGenderContainer>
                <StyledApperanceHeroSaveButtonGender $textStyle='p13' $color={colors.text}>
                  Выбери пол
                </StyledApperanceHeroSaveButtonGender>
                <StyledApperanceHeroSaveButtonGenderItem
                  $active={config.id === maleConfig.id}
                  onClick={setMaleConfig}>
                  <StyledApperanceHeroSaveButtonGenderItemImage src={maleConfig.preview} />
                </StyledApperanceHeroSaveButtonGenderItem>
                <StyledApperanceHeroSaveButtonGenderItem
                  $active={config.id === femaleConfig.id}
                  onClick={setFemaleConfig}>
                  <StyledApperanceHeroSaveButtonGenderItemImage src={femaleConfig.preview} />
                </StyledApperanceHeroSaveButtonGenderItem>
              </StyledApperanceHeroSaveButtonGenderContainer>
            ) : (
              <div />
            )}

            <Button
              disabled={isEdit && !isButtonEnabled}
              inProgress={isLoading}
              $style='ghost'
              $full
              $center
              $fontWeight='500'
              onClick={toggleEditState}>
              {isEdit ? 'Сохранить' : 'Изменить внешний вид'}
            </Button>
          </Box>
        </StyledApperanceHeroSaveButton>
      </StyledApperanceHero>
      {isEdit && (
        <StyledClothesHero>
          <ItemsBar items={config.hair} active={hair} onSelect={setHair} title='Прическа' />
          <ItemsBar items={config.face} active={face} onSelect={setFace} title='Лицо' />
          <ItemsBar items={config.body} active={body} onSelect={setBody} title='Торс' />
          <ItemsBar
            isBackground
            items={config.background}
            active={background}
            onSelect={setBackground}
            title='Цвет фона'
          />
        </StyledClothesHero>
      )}
    </StyledHero>
  );
};

export default Hero;
