import styled, { keyframes } from 'styled-components';
import { BREAKPOINT_PHONE } from '../../../common/shared/constants/constants';
import { CatSuggestionsProps } from './CatSuggestionsTypes';

const cloudAnimation = keyframes`
  0% {
    transform: scale(1,1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1,1);
  }
`;
export const StyledCatSuggestions = styled.img<CatSuggestionsProps>`
  animation: ${cloudAnimation} 1.5s infinite linear;

  width: 76px;
  height: 76px;
  object-fit: cover;

  border-radius: 50%;
  border: 4px solid #ff6060;

  left: 24px;
  bottom: 24px;
  z-index: 100;
  position: absolute;

  cursor: pointer;

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    width: 64px;
    height: 64px;

    left: 20px;
    bottom: 20px;
    border-width: 3px;
  }
`;
