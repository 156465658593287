import * as checks from './stageChecks';
import { ID, to } from './ID';
import { PointsOfInterest } from '../../types';
import { SceneId } from '../../types/scenario';
import { createNavigateToPointLink, toArray } from './utils';
import { RunModalScenario, ScenarioHelperResponse } from './types';
import { ScenarioState } from '../../redux/scenario';
import store from '../../redux';
import { checkTestsAsync } from '../../redux/tests/testsActions';

export function stage1(
  scenarioState: ScenarioState,
  runScenario: (id: SceneId) => void,
  runModalScenario: RunModalScenario,
): ScenarioHelperResponse {
  /**
   * акт 0 Приветственный экран
   */

  if (checks.notCatWelcome(scenarioState)) {
    // store.dispatch(checkTestsAsync());
    return {
      pointsHandlers: [],
      autorunScenario: {
        id: to[ID.catWelcome],
      },
    };
  }

  /**
   *    МГУ    2 ЧАСТЬ
   */

  if (checks.notCityPresented(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.cityPresentation] },
    };
  }

  if (checks.notWelcomeTest(scenarioState)) {
    const mguNavigatePoint = createNavigateToPointLink('МГУ', PointsOfInterest.mgu);
    return {
      helpMessage: `Иди в ${mguNavigatePoint} на карте парка «Большая перемена». Там тебя ждет кое-что интересное.`,
      pointsHandlers: [
        {
          point: PointsOfInterest.mgu,
          handler: () =>
            runModalScenario(
              to[ID.welcomeTestIntro],
              to[ID.welcomeTest],
              to[ID.welcomeTest],
              scenarioState.scenarios,
            ),
        },
      ],
    };
  }

  /**
   * ЮНЕСКО (Байкал)     3 ЧАСТЬ
   */

  if (checks.notBaikalIntroChecked(scenarioState) && !checks.notWelcomeTest(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.lakePopup] },
    };
  }

  if (!scenarioState.scenarios?.[to[ID.lake]] && !checks.notBaikalIntroChecked(scenarioState)) {
    const baikalNavigatePoint = createNavigateToPointLink('ЮНЕСКО (Байкал)', PointsOfInterest.lake);

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.lake]: () =>
          runModalScenario(to[ID.lakeIntro], to[ID.lake], to[ID.lake], scenarioState.scenarios),
      }),
      helpMessage: `Иди в ${baikalNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   * ИННОПОЛИС     4 ЧАСТЬ
   */

  if (checks.notInnopolicePopupCompleted(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.innopolicePopup] },
    };
  }

  if (!scenarioState.scenarios?.[to[ID.innopolice]] && scenarioState.scenarios?.[to[ID.lake]]) {
    const innopoliceNavigatePoint = createNavigateToPointLink(
      'Иннополис',
      PointsOfInterest.innopolice,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.innopolice]: () =>
          runModalScenario(
            to[ID.innopoliceIntro],
            to[ID.innopolice],
            to[ID.innopolice],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${innopoliceNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   * ЭРМИТАЖ    5 ЧАСТЬ
   */

  if (checks.notHermitagePopupCompleted(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.hermitagePopup] },
    };
  }

  if (!scenarioState.scenarios[to[ID.hermitage]] && scenarioState.scenarios[to[ID.innopolice]]) {
    const hermitageNavigatePoint = createNavigateToPointLink(
      'Эрмитажу',
      PointsOfInterest.hermitage,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.hermitage]: () =>
          runModalScenario(
            to[ID.hermitageIntro],
            to[ID.hermitage],
            to[ID.hermitage],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди к ${hermitageNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   * BAIKAL - возвращение     6 ЧАСТЬ
   */

  if (checks.notBaikalComebackPopupCompleted(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.lakeComebackPopup] },
    };
  }

  if (
    !scenarioState.scenarios?.[to[ID.lakeComeback]] &&
    !checks.notBaikalComebackPopupCompleted(scenarioState)
  ) {
    const baikalReturnNavigatePoint = createNavigateToPointLink(
      'ЮНЕСКО (Байкал)',
      PointsOfInterest.lake,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.lake]: () =>
          runModalScenario(
            to[ID.lakeComebackIntro],
            to[ID.lakeComeback],
            to[ID.lakeComeback],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${baikalReturnNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   * ЭРМИТАЖ - КОРМЛЕНИЕ МАЛЕВИЧА     7 ЧАСТЬ
   */

  if (
    !scenarioState.scenarios?.[to[ID.hermitageFeeding]] &&
    scenarioState.scenarios?.[to[ID.lakeComeback]]
  ) {
    const hermitageNavigatePoint = createNavigateToPointLink(
      'Эрмитажу',
      PointsOfInterest.hermitage,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.hermitage]: () =>
          runModalScenario(
            to[ID.hermitageFeedingIntro],
            to[ID.hermitageFeeding],
            to[ID.hermitageFeeding],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди к ${hermitageNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   *      ОЛИМПИЙСКИЙ ПАРК    8 ЧАСТЬ
   */
  //

  if (checks.notOlympicParkPopupCompleted(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.olympicParkPopup] },
    };
  }

  if (
    !scenarioState.scenarios?.[to[ID.olympicPark]] &&
    scenarioState.scenarios?.[to[ID.hermitageFeeding]]
  ) {
    const baikalReturnNavigatePoint = createNavigateToPointLink('СОЧИ', PointsOfInterest.sochi);

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.sochi]: () =>
          runModalScenario(
            to[ID.olympicParkIntro],
            to[ID.olympicPark],
            to[ID.olympicPark],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${baikalReturnNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   *     ЗНАКОМСТВО С ИНОПЛАНЕТЯНИНОМ     9 ЧАСТЬ
   */

  if (checks.notAlienPopupCompleted(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.alienPopup] },
    };
  }

  if (
    !checks.notAlienPopupCompleted(scenarioState) &&
    !scenarioState.scenarios?.[to[ID.alienIntro]]
  ) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.alienIntro] },
    };
  }

  if (
    !checks.notAlienPopupCompleted(scenarioState) &&
    scenarioState.scenarios?.[to[ID.alienIntro]] &&
    !scenarioState.scenarios?.[to[ID.alien]]
  ) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.alien] },
    };
  }

  /**
   *    МОСКВА-СИТИ    10 ЧАСТЬ
   */
  //

  if (checks.notMoscowCityPopupCompleted(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.moscowCityPopup] },
    };
  }

  if (
    !scenarioState.scenarios?.[to[ID.moscowCityDialogue]] &&
    scenarioState.scenarios?.[to[ID.alien]]
  ) {
    const moscowReturnNavigatePoint = createNavigateToPointLink(
      'МОСКВА-СИТИ',
      PointsOfInterest.moscowCity,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.moscowCity]: () =>
          runModalScenario(
            to[ID.moscowCityIntro],
            to[ID.moscowCityDialogue],
            to[ID.moscowCityDialogue],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${moscowReturnNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   *    МГУ - ДОБЫЧА ДИКТОФОНА    11 ЧАСТЬ
   */
  //

  if (checks.notMguDictaphonePopupCompleted(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.mguDictaphonePopup] },
    };
  }

  if (
    !scenarioState.scenarios?.[to[ID.mguDictaphone]] &&
    scenarioState.scenarios?.[to[ID.moscowCityDialogue]]
  ) {
    const mguDictaphoneReturnNavigatePoint = createNavigateToPointLink('МГУ', PointsOfInterest.mgu);

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.mgu]: () =>
          runModalScenario(
            to[ID.mguDictaphoneIntro],
            to[ID.mguDictaphone],
            to[ID.mguDictaphone],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${mguDictaphoneReturnNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   * заглушка
   */

  // return {
  //   pointsHandlers: [],
  //   autorunScenario: {
  //     id: to[ID.stop_1],
  //     unsaved: false,
  //   },
  // };

  // /**
  //  *    ПРО МОСКВА СИТИ      12 ЧАСТЬ
  //  */
  // //

  if (
    !scenarioState.scenarios?.[to[ID.oboutMoscow]] &&
    scenarioState.scenarios?.[to[ID.mguDictaphone]]
  ) {
    const oboutMoscowReturnNavigatePoint = createNavigateToPointLink(
      'Москва-сити',
      PointsOfInterest.moscowCity,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.moscowCity]: () =>
          runModalScenario(
            to[ID.oboutMoscowIntro],
            to[ID.oboutMoscow],
            to[ID.oboutMoscow],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${oboutMoscowReturnNavigatePoint}. Тебя там ждут.`,
    };
  }

  // /**
  //  *   ИННОПОЛИС - ПРО ИННОПОЛИС   13 ЧАСТЬ
  //  */
  // //

  if (
    !scenarioState.scenarios?.[to[ID.oboutInnopolice]] &&
    scenarioState.scenarios?.[to[ID.oboutMoscow]]
  ) {
    const oboutInnopoliceReturnNavigatePoint = createNavigateToPointLink(
      'ИННОПОЛИС',
      PointsOfInterest.innopolice,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.innopolice]: () =>
          runModalScenario(
            to[ID.oboutInnopoliceIntro],
            to[ID.oboutInnopolice],
            to[ID.oboutInnopolice],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${oboutInnopoliceReturnNavigatePoint}. Тебя там ждут.`,
    };
  }

  return {
    pointsHandlers: [],
  };
}
