/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  Step,
  StepType,
  SystemStepType,
} from '../../../scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';
import { images } from '../../tests/images';
import { TEST_LOGIC_MAIN_2024 } from '../../tests/test_logic_main_2024';

const LOGIC: Record<string, Step> = {
  LOGIC_TEST: {
    person: Interlocutors.PETROV,
    nextStep: 'LOGIC_TEST_PART1_Q5',
    id: 'LOGIC_TEST',
    type: StepType.system,
    _meta: {
      type: SystemStepType.limitedTimeGroup,
      time: 2 * 60,
      timeoutStep: 'LOGIC_TEST_TIMEOUT',
      exitStep: 'Q20',
    },
  },

  LOGIC_TEST_PART1_Q5: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5',
    text: 'Расположи числа 4, 2, 6, 1 на месте фигур (квадрата, звездочки, треугольника и круга) так, чтобы стрелки шли от большего числа к меньшему:',
    image: { uri: images.logic_fix, title: '' },
    type: StepType.message,
    nextStep: 'LOGIC_TEST_PART1_Q5_1',
  },
  LOGIC_TEST_PART1_Q5_1: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5_1',
    text: 'Что на месте квадрата?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_1',
    nextStep: 'LOGIC_TEST_PART1_Q5_2',
  },
  LOGIC_TEST_PART1_Q5_2: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5_2',
    text: 'На месте звездочки?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_2',
    nextStep: 'LOGIC_TEST_PART1_Q5_3',
  },
  LOGIC_TEST_PART1_Q5_3: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5_3',
    text: 'На месте треугольника?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_3',
    nextStep: 'LOGIC_TEST_PART1_Q5_4',
  },
  LOGIC_TEST_PART1_Q5_4: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5_4',
    text: 'На месте круга?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_4',
    nextStep: 'Q20',
  },
  LOGIC_TEST_TIMEOUT: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_TIMEOUT',
    text: '{{NAME}}, увы, время закончилось 🙁',
    type: StepType.message,
    nextStep: 'Q21',
  },
};

const EXIT_ID = 'EXIT_ID';

export const scenario_3_1: Scenario = {
  scenarioId: SceneId.scenario_3_1,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Иннополис',
    scene_id: 'scenario_3_1',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.CAT,
        id: 'Q1',
        type: StepType.message,
        text: `Почему все на меня так странно смотрят? Что я сделал? 🙀`,
        nextStep: 'Q2',
      },
      Q2: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q2',
        type: StepType.message,
        text: `Я раскрыл твою тайну! Я знаю, что это все ты! Из-за тебя все неприятности в «Большой перемене»! 😑`,
        nextStep: 'Q3',
      },
      Q3: {
        person: Interlocutors.CAT,
        id: 'Q3',
        type: StepType.message,
        text: `Как я? Почему я? 🙀`,
        nextStep: 'Q4/0',
      },
      ...splitStep({
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q4',
        type: StepType.message,
        text: `У меня есть доказательства. После того как ракету украли, я обследовал Космодром и нашел клок шерсти. Я сдал шерсть в лабораторию и оказалось, что она принадлежит КОТУ! 😑

Так как эрмитажные коты никогда не покидают службу в музее, то остаешься только ты! 😤`,
        nextStep: 'Q5',
      }),
      Q5: {
        person: Interlocutors.TEACHER,
        id: 'Q5',
        type: StepType.message,
        text: `Я тоже припоминаю, что когда пришел туман, меня будто что-то теплое и мягкое толкнуло в яму рядом с МГУ… Но я уверена, что это не Вжух, он не мог так поступить 😔`,
        nextStep: 'Q6/0',
      },
      ...splitStep({
        person: Interlocutors.PETROV,
        id: 'Q6',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Что???',
            nextStep: 'Q7',
          },
        ],
        text: `Да, он и мои колбы разбил, я видел запись с видеокамер из лаборатории! Думал, что выйдешь сухим из воды, раз смог добыть новые? 😤`,
      }),
      // 'Q7-1': {
      //   person: Interlocutors.PETROV,
      //   id: 'Q7-1',
      //   type: StepType.message,
      //   text: `Да-да! В первый раз Сан Саныч напутал и неправильно задал вопрос.`,
      //   nextStep: 'Q7-2',
      // },
      // 'Q7-2': {
      //   person: Interlocutors.ENTREPRENEUR,
      //   id: 'Q7-2',
      //   type: StepType.message,
      //   text: `Да, было, каюсь...  🙁 Неправильно записал… 😔`,
      //   nextStep: 'Q7-3',
      // },
      // 'Q7-3': {
      //   person: Interlocutors.PETROV,
      //   id: 'Q7-3',
      //   type: StepType.message,
      //   text: `А вот во второй, когда я стал проверять, заметил, что листок с задачей был какой-то странный и как будто поцарапан когтями. Точно это дело лап Вжуха!`,
      //   nextStep: 'Q7-4',
      // },
      // 'Q7-4': {
      //   person: Interlocutors.CAT,
      //   id: 'Q7-4',
      //   type: StepType.message,
      //   text: `Да что ты такое говоришь?! Да я бы никогда не стал так кого-то подставлять! Тем более своего друга! 🙀`,
      //   nextStep: 'Q7',
      // },
      Q7: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q7',
        type: StepType.message,
        text: `Да, теперь припоминаю… Сметанные следы на моей разгромленной кухне тоже были похожи на кошачьи, и веревка ведь была в шерсти! Вполне возможно, что в кошачьей… 🤔`,
        nextStep: 'Q8/0',
      },
      ...splitStep({
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q8',
        type: StepType.message,
        text: `Я же говорю, это все Вжух! Из-за него все наши несчастья! И в поезде наверняка тоже он нашкодил. На днях меня пригласил начальник депо поговорить про дела парка, он рассказал, что кто-то дернул и унес ручку стоп-крана. Теперь «Большая перемена» осталась еще и без поезда из-за этого негодника! 😤

Поезд не тронется с места, если ручка не будет возвращена на место 😔`,
        nextStep: 'Q9/0',
      }),
      ...splitStep({
        person: Interlocutors.CAT,
        id: 'Q9',
        type: StepType.message,
        text: `Да вы что?! Конечно же, я не мог этого всего сделать! Ну а колбы разбил случайно, поэтому и попросил помочь мне добыть новые — не хотел, чтобы ты, Петров, расстраивался, ведь знал, как эти колбы важны! 🙀
        
        Да и на время падения Светланы Васильевны в яму у меня есть алиби! Я прятался в Олимпийском парке, потому что испугался… 😿`,
        nextStep: 'Q10',
      }),
      Q10: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q10',
        type: StepType.message,
        text: `А тебя там кто-то видел?`,
        nextStep: 'Q11',
      },
      Q11: {
        person: Interlocutors.CAT,
        id: 'Q11',
        type: StepType.message,
        text: `Не уверен, я был там один… 😿`,
        nextStep: 'Q12',
      },
      Q12: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q12',
        text: 'Тогда предлагаю провести расследование… [[Согласен/Согласна]], {{NAME}}?',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, что-то я сомневаюсь, что это Вжух так везде набедокурил. Надо разобраться!',
            nextStep: 'Q13',
          },
          {
            id: 'b2',
            text: 'Это точно был не Вжух! Он помогал мне с восстановлением парка! В таком случае, зачем ему все ломать?!',
            nextStep: 'Q14/0',
          },
          {
            id: 'b3',
            text: 'Похоже, что это был Вжух… Уж слишком много необъяснимых совпадений…',
            nextStep: 'Q15/0',
          },
        ],
      },
      Q13: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q13',
        type: StepType.message,
        text: `Давай разбираться вместе. Предлагаю начать с осмотра поезда. Может быть, там мы найдем зацепку 🧐`,
        nextStep: 'Q16',
      },
      ...splitStep({
        person: Interlocutors.GRANDMOTHER,
        id: 'Q14',
        type: StepType.message,
        text: `Вот и мне это кажется подозрительным… Давай поищем улики и во всем разберемся? 

Предлагаю начать с осмотра поезда 🧐`,
        nextStep: 'Q16',
      }),
      ...splitStep({
        person: Interlocutors.GRANDMOTHER,
        id: 'Q15',
        type: StepType.message,
        text: `Вот и мне кажется, что совпадений слишком много, а первая заповедь детектива: всегда проверяй совпадения и опирайся только на факты!

Предлагаю осмотреть поезд и поискать там улики 🧐`,
        nextStep: 'Q16',
      }),
      Q16: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q16',
        type: StepType.message,
        text: `Ладно, Бабуля, Вы правы, нам нужны неопровержимые доказательства. Идите осматривать поезд, а я пока пригляжу за нашим подозреваемым 😑`,
        nextStep: 'Q17',
      },
      Q17: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q17',
        text: `{{NAME}}, пойдем к поезду, надо уже наконец выяснить, кто зачинщик всех этих странностей, творящихся в нашем славном парке`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Пойдемте, конечно!',
            nextStep: 'Q21',
          },
        ],
      },
      // Q18: {
      //   person: Interlocutors.PETROV,
      //   id: 'Q18',
      //   text: 'А ты [[готов/готова]] сейчас на него ответить?',
      //   type: StepType.question,
      //   maxAnswers: 1,
      //   buttons: [
      //     {
      //       id: 'b1',
      //       text: 'Давай, я попробую…',
      //       nextStep: 'Q18_1',
      //     },
      //     {
      //       id: 'b2',
      //       text: 'Да-да, я уже даже [[взял/взяла]] ручку и листик для расчетов',
      //       nextStep: 'Q18_1',
      //     },
      //   ],
      // },
      // Q18_1: {
      //   person: Interlocutors.SYSTEM,
      //   id: 'Q16',
      //   type: StepType.message,
      //   text: `Данный тест является тренировочным и не приносит баллов за конкурс.`,
      //   nextStep: 'Q19/0',
      // },
      // ...splitStep({
      //   person: Interlocutors.PETROV,
      //   id: 'Q19',
      //   text: `Тогда поехали!

      //   У тебя будет ровно 2 минуты на решение. Нужно будет дать 4 ответа, вписывай их по очереди — по одному в поле ввода и затем жми «Отправить ответ».`,
      //   type: StepType.question,
      //   maxAnswers: 1,
      //   buttons: [
      //     {
      //       id: 'b1',
      //       text: 'Хорошо',
      //       nextStep: 'LOGIC_TEST',
      //     },
      //   ],
      // }),

      // ...LOGIC,

      // Q20: {
      //   person: Interlocutors.PETROV,
      //   id: 'Q20',
      //   text: `А теперь я тщательно спрячу твой ответ и отнесу его прямиком в свой кабинет.`,
      //   type: StepType.question,
      //   maxAnswers: 1,
      //   buttons: [
      //     {
      //       id: 'b1',
      //       text: 'Петров, ты уж, пожалуйста, повнимательнее с этим!',
      //       nextStep: 'Q21',
      //     },
      //   ],
      // },
      Q21: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q21',
        text: `Давай перед поездом пройдём с тобой контрольный тест на логику. Но учти, что он будет посложнее предыдущих. Справишься?`,
        type: StepType.question,
        nextStep: 'Q22',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ух, я даже не знаю, так волнительно! Ну давайте попробуем!',
            nextStep: 'Q22',
          },
        ],
      },

      Q22: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q22',
        text: `У тебя будет 20 минут. Возможно, тебе понадобится ручка и лист бумаги, чтобы что-то вычислять. Очень внимательно читай вопросы.
        
        Важный момент: пройти тест можно только один раз. Повторно к нему вернуться будет нельзя. А это очень важно, понимаешь?
        
        Обрати внимание: так как ты проходишь тест после 16:00 7 июня, то баллы за этот тест тебе не будут начисляться.
        
        Выходить из теста во время его прохождения нельзя. Но если ты все-таки выйдешь из игры после запуска теста, независимо от причин, почему это произошло, таймер будет продолжать работать, а время, отведенное на тест, истекать. 
        
        Вопросы нельзя проматывать и возвращаться к предыдущим вопросам, поэтому прежде, чем начать тест, проверь, что у тебя:
           -   стабильно работает Интернет
           -   заряжено устройство, с которого ты проходишь тест
           -   и никто тебе не помешает в ближайшие 20 минут`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Я [[готов/готова]]!',
            nextStep: 'TEST_LOGIC_MAIN_2024_START',
          },
        ],
      },

      ...TEST_LOGIC_MAIN_2024,

      Q23: {
        person: Interlocutors.CAT,
        id: 'Q23',
        text: `{{NAME}}, скорее иди с Бабулей к поезду, наверняка там найдутся улики, доказывающие мою невиновность!`,
        type: StepType.message,
        nextStep: EXIT_ID,
      },

      Q24: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q24',
        text: `“{{NAME}}, увы, время закончилось 🙁

        Идите с Бабулей к поезду, начинайте расследование 😉`,
        type: StepType.message,
        nextStep: EXIT_ID,
      },

      [EXIT_ID]: {
        person: Interlocutors.TEACHER,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
