/* eslint-disable no-constant-condition */
import React, { useCallback, useEffect, useState } from 'react';

import useBoolState from '../../common/shared/hooks/useBoolState';
import useFirstDataLoad from './hooks/useFirstDataLoad';

import { ReactTooltip } from '../../common/shared/ui/ReactTooltip';
import PageLoading from '../components/PageLoader';
import { InputToolbarModal } from '../components/InputToolbarModal';
import { NetworkOverlay } from '../components/NetworkOverlay';
import Router from '../components/Router';

import ErrorBoundary from '../../common/app/providers/ErrorBoundary';
import { ModalWindow } from '../../common/widgets/modalWindow/ModalWindow';

export const App = (props: { isQueryClassesChanged: boolean }): any => {
  const { isQueryClassesChanged } = props;

  const [isLoading, setLoading] = useBoolState(true);
  const [state, setState] = useState(isQueryClassesChanged);

  // первая подгрузка данных
  useFirstDataLoad(setLoading);

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <>
      <ErrorBoundary>
        {state ? <ModalWindow setState={setState} /> : null}
        <Router />
        <ReactTooltip />
        <InputToolbarModal />
        <NetworkOverlay />
      </ErrorBoundary>
    </>
  );
};
