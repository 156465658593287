/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../types/scenario';

export const scenario_blanks: Scenario = {
  scenarioId: SceneId.scenario_blanks,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Внимание',
    text: `Похоже, есть незавершенные этапы игры. Мы перекинем вас в них для корректного завершения, а потом вернем в точку, где вы остановились.`,
    buttons: ['Продолжить'],
  },
};
