export const routes = {
  dashboard: '/',

  sync: '/sync',

  map: '/map',
  chat: '/chat',
  login: '/login',
  ratings: '/ratings',
  profile: '/profile',
  success: '/success',
  register: '/register',
  onboarding: '/welcome',

  error: '/oops',
  loginDenied: '/login-denied',
  debug: '/debug',
};
