/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_mgu_1_1_0: Scenario = {
  scenarioId: SceneId.stg2_mgu_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'МГУ',
    text: `В библиотеке МГУ книги больше не летают под потолком. Удивительно, но всё на своих местах. 

    В дальнем углу стоит Светлана Васильевна, окруженная толпой ребят. Вы подходите ближе, учительница увлеченно рассказывает про викторину.`,
    buttons: ['Продолжить'],
  },
};
