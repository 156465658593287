import React from 'react';

export const IconInfo: React.FC = () => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      /* eslint-disable-next-line max-len */
      d='M9 18C4.02943 18 0 13.9705 0 9C0 4.02943 4.02943 0 9 0C13.9705 0 18 4.02943 18 9C18 13.9705 13.9705 18 9 18ZM9.00078 1.80029C5.02433 1.80029 1.80078 5.02384 1.80078 9.00029C1.80078 12.9768 5.02433 16.2003 9.00078 16.2003C12.9773 16.2003 16.2008 12.9768 16.2008 9.00029C16.2008 5.02384 12.9773 1.80029 9.00078 1.80029ZM9 7.875C9.62136 7.875 10.125 8.37864 10.125 9V12.6C10.125 13.2214 9.62136 13.725 9 13.725C8.37864 13.725 7.875 13.2214 7.875 12.6V9C7.875 8.37864 8.37864 7.875 9 7.875ZM9 4.50026C8.37864 4.50026 7.875 5.00394 7.875 5.62526C7.875 6.24658 8.37864 6.75026 9 6.75026C9.62136 6.75026 10.125 6.24658 10.125 5.62526C10.125 5.00394 9.62136 4.50026 9 4.50026Z'
      fill='currentColor'
    />
  </svg>
);
