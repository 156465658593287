import styled from 'styled-components';

import colors from '../../../common/shared/constants/colors';
import { NormalInputProps } from './NormalInputTypes';

const disabledColor = 'rgb(240, 240, 240)';

export const StyledNormalInput = styled.input<NormalInputProps>`
  width: 100%;

  padding: 12px 16px;
  border-radius: 12px;

  outline: none;
  box-shadow: none;
  border: 1px solid ${colors.grey2};

  transition: all 250ms;

  &:disabled {
    background: ${disabledColor};
    border-color: ${colors.grey210};
  }

  &:focus,
  &:hover {
    &:not(:disabled) {
      border-color: ${colors.text};
    }
  }
`;
