/* eslint-disable react/no-children-prop */
import React, { useCallback, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { modalPortalId } from './ModalProvider';
import {
  ModalBackground,
  ModalBodyStyled,
  ModalContainerStyled,
  ModalTitleStyled,
  ModalWrapperStyled,
  ModalCrossStyled,
} from './NewModalStyle';
import { useMount } from '../../hooks/useMount';

export interface IModalProps {
  isOpen?: boolean;
  isInputToolbarModal?: boolean;
  title?: string | React.ReactChild;
  onClose?(): void;
}

interface ModalPortalContainerProps {
  onClose?: () => void;
  onClickBody: (e: any) => void;
  isInputToolbarModal?: boolean;
  title: React.ReactNode;
  children: React.ReactNode;
}

const ModalPortalContainer = (props: ModalPortalContainerProps) => {
  const { onClose, title, children } = props;
  const [isCenterModal, setCenterModalState] = React.useState(true);

  const content = React.useRef<HTMLDivElement>(null);

  const handleResizeCallback = useCallback(() => {
    if (content?.current) {
      const screenHeight = window.innerHeight;
      const containerHeight = content?.current.clientHeight;

      const heightCrossElement = 60;
      const isContentLargeWindow = containerHeight + heightCrossElement > screenHeight;

      setCenterModalState(!isContentLargeWindow);
    }
  }, []);

  useMount(
    () => {
      handleResizeCallback();
      window.addEventListener('resize', handleResizeCallback);
    },
    () => {
      window.removeEventListener('resize', handleResizeCallback);
    },
  );

  return (
    <ModalWrapperStyled $isCenterModal={isCenterModal}>
      <ModalContainerStyled ref={content} $isInputToolbarModal={props.isInputToolbarModal}>
        {!props.isInputToolbarModal && onClose && <ModalCrossStyled onClick={onClose} />}
        <div style={{ display: 'flex' }}>
          <div style={{ width: '100%' }}>
            <ModalTitleStyled $textStyle='h2'>{title}</ModalTitleStyled>
          </div>
          {props.isInputToolbarModal && onClose && (
            <ModalCrossStyled $isRelative onClick={onClose} />
          )}
        </div>
        <ModalBodyStyled>{children}</ModalBodyStyled>
      </ModalContainerStyled>
      <ModalBackground onClick={onClose} />
    </ModalWrapperStyled>
  );
};

export const NewModal: React.FC<IModalProps> = (props) => {
  const { isOpen, children, title, onClose } = props;

  const handleKeyPress = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 27 && onClose) onClose();
    },
    [onClose],
  );

  useEffect(() => {
    if (onClose) {
      window.addEventListener('keydown', handleKeyPress);

      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    }
  }, [onClose, handleKeyPress]);

  const onClickBody = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <ModalPortalContainer
      title={title}
      children={children}
      isInputToolbarModal={props.isInputToolbarModal}
      onClose={onClose}
      onClickBody={onClickBody}
    />,
    document.getElementById(modalPortalId)!,
  );
};

NewModal.defaultProps = {
  title: 'Заголовок',
};
