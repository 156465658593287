import styled from 'styled-components';
import colors from '../../../../common/shared/constants/colors';
import { BREAKPOINT_PHONE, GAP } from '../../../../common/shared/constants/constants';
import { IChatAnswerStyled } from '../../chat/ChatAnswer/ChatAnswerTypes';

export const ChatTextAreaStyled = styled.textarea`
  width: 100%;
  background: none;
  border: none;
  resize: none;
  outline: none;
  max-height: 150px;
  min-height: auto;
  font-family: inherit;

  font-size: 14px;
  font-weight: 500;
  line-height: 125%;

  color: #1b1b1b;
`;

export const ChatRadioStyled = styled.div<IChatAnswerStyled>`
  cursor: pointer;
  user-select: none;
  width: 100%;
  background: ${colors.white};
  border: 2px solid;
  text-align: left;
  outline: none;
  border-radius: ${GAP.m};
  margin: ${GAP.xxxs} 0;
  border-color: ${({ $current }) => ($current ? colors.blueMessage : colors.white)};
  padding: ${GAP.xs} ${GAP.ss};

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    padding: ${GAP.s} ${GAP.l};
  }
`;
