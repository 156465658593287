/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../../types/scenario';
import interlocutors from '../../../interlocutors';

const EXIT_ID = 'EXIT';
const TO_MAP_STEP = 'TO_MAP_STEP';

const person = Interlocutors.TEACHER;

/**
 * тип мышления
 */
const TEST_TVOI_VEKTOR_2: TestStep = {
  external_id: 'test-tvoi-vektor-junior-210401',
  id: 'Q3',
  name: 'Твой вектор 1/6',
  nextStep: 'Q4',
  order: [
    {
      description: 'Ты можешь выбрать несколько вариантов из предложенных, но не больше пяти.',
      subtest: 'T1',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vektor-junior-210401.json',
  person,
  type: StepType.test,
};

/**
 * интересы
 */
const TEST_TVOI_VIBOR: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'TEST_TVOI_VIBOR',
  name: 'Твой выбор',
  nextStep: 'Q8',
  order: [
    {
      description: 'Ты можешь выбрать несколько вариантов из предложенных, но не больше пяти.',
      subtest: 'T1',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person,
  type: StepType.test,
};

const TEST_TVOI_VIBOR_2: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'TEST_TVOI_VIBOR_2',
  name: 'Твой выбор',
  nextStep: 'Q14',
  order: [
    {
      description: 'Ты можешь выбрать несколько вариантов из предложенных, но не больше пяти.',
      subtest: 'T1',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person,
  type: StepType.test,
};

const TEST_TVOI_VIBOR_3: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'TEST_TVOI_VIBOR_3',
  name: 'Твой выбор',
  nextStep: 'Q4',
  order: [
    {
      description: 'Ты можешь выбрать несколько вариантов из предложенных, но не больше пяти.',
      subtest: 'T1',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person,
  type: StepType.test,
};

export const scenario_1_19: Scenario = {
  scenarioId: SceneId.scenario_1_19,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'МГУ',
    scene_id: 'scenario_1_19',
    startId: 'Q1',
    steps: {
      Q1: {
        id: 'Q1',
        text: `Вы попадаете в МГУ. В коридорах тихо и темно, не слышно ни звука. Надо найти кого-то, кто сможет помочь в поисках Гарина или Петрова.`,
        callToAction: 'Исследуйте пространства университета.',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1_1',
            text: 'Поищу в библиотеке',
          },
          {
            id: 'b2',
            nextStep: 'Q1_2',
            text: 'Пройдусь по лекционным залам',
          },
          {
            id: 'b3',
            nextStep: 'Q1_3',
            text: 'Схожу в столовую, а что?',
          },
          {
            id: 'b4',
            nextStep: 'Q1_4',
            text: 'Посмотрю на кафедрах',
          },
        ],
      },
      Q1_1: {
        id: 'Q1_1',
        type: StepType.question,
        maxAnswers: 1,
        text: `За массивными деревянными дверями спрятались ряды книг: старинные, в кожаных переплётах, новенькие учебники в глянцевых обложках, журналы, справочники 
          и карты. Библиотекаря нет. 
          На столе лежит толстый старый фолиант с золотыми буквами «Летопись МГУ».
          `,
        callToAction: 'Поищу в библиотеке',
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1_1_1',
            text: 'Посмотреть книгу',
          },
        ],
      },
      Q1_1_1: {
        id: 'Q1_1_1',
        type: StepType.question,
        maxAnswers: 1,
        text: `Ты обнаруживаешь в книге несколько закладок. Может быть, там что-то интересное?.`,
        callToAction: 'Открой вкладку вкладку',
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1_1_2',
            text: 'Сокол Сапсан',
          },
          {
            id: 'b2',
            nextStep: 'Q1_1_3',
            text: 'Ботанический сад МГУ',
          },
        ],
      },
      Q1_1_2: {
        id: 'Q1_1_2',
        type: StepType.question,
        maxAnswers: 1,
        text: `На территории Москвы сокол сапсан известен ещё с допетровских времён: во времена правления Василия III его изображения чеканили на монетах. Изначально он гнездился на высоких деревьях, затем облюбовал колокольни церквей и Кремлёвские башни.

          После серии выпусков птенцов на волю на Главном здании сформировалась пара соколов, для которой была оборудована гнездовая ниша на 30-ом этаже. Пара соколов сапсанов, загнездившаяся на Главном здании МГУ, начала размножаться, положив начало естественному восстановлению этого редкого вида, исчезнувшего в Московском регионе к началу 1970-х годов.
          `,
        callToAction: 'Посмотреть вкладку сокол сапсан',
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1_1_3',
            text: 'Ботанический сад МГУ',
          },
          {
            id: 'b2',
            nextStep: 'Q1',
            text: 'Вернуться в главное здание',
          },
        ],
      },
      Q1_1_3: {
        id: 'Q1_1_3',
        type: StepType.question,
        maxAnswers: 1,
        text: `Ботанический сад МГУ — старейший научно-ботанический сад России.

          В состав входят два сада: Аптекарский огород и Ботанический сад Биологического факультета на Воробьёвых горах.
          
          Аптекарский огород
          В 1706 году по указу Петра I за Сухаревой башней был заложен огород для выращивания лекарственных трав. С этого огорода сад и ведет свою историю. «Аптекарский огород» — это филиал тропических и субтропических культур. 
          
          Ботанический сад на Воробьёвых горах
          
          15 марта 1948 года началось строительство нового здания биологического факультета. Для кафедры экспериментальных исследований факультета 
          создали «Агроботанический сад», чтобы учёные и студенты могли наблюдать за ростом растений.
          
          В структуру сада входят центры систематики и географии растений, сектора флоры, дендрологии и садовых растений.
          `,
        callToAction: 'Посмотреть вкладку Ботанический сад МГУ',
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1_1_2',
            text: 'Сокол сапсан',
          },
          {
            id: 'b2',
            nextStep: 'Q1',
            text: 'Вернуться в главное здание',
          },
        ],
      },
      Q1_2: {
        id: 'Q1_2',
        type: StepType.question,
        maxAnswers: 1,
        text: `Вы осматриваете лекционные залы. Старинные аудитории с деревянными партами и многоуровневыми кафедрами, улетающими вверх. Посреди зала стоит стол преподавателя, за ним расположена зелёная грифельная доска. Кто-то написал на ней: P (A) = m/n
          A1 + A2 + A3 + A4 + A5
          Вы замечаете надпись на парте.
          `,
        callToAction: 'Пройтись по лекционным залам',
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1_2_1',
            text: 'Присмотреться к надписи на парте',
          },
        ],
      },
      Q1_2_1: {
        id: 'Q1_2_1',
        type: StepType.question,
        maxAnswers: 1,
        text: `На парте надпись: 

          Iuro me futurum bonum atque castum
          Nec amicum relicturum miserum.
          Audio vocem et festino hoc vocatu
          Via aspera ad illud futurum.
          
          Хм, кажется это на латыни.
          `,
        callToAction: 'Присмотреться к надписи на парте',
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1',
            text: 'Вернуться в главное здание',
          },
        ],
      },
      Q1_3: {
        id: 'Q1_3',
        type: StepType.question,
        maxAnswers: 1,
        text: `Вы попадаете в главную столовую Университета. 
          С невероятно высокого потолка спускаются большие продолговатые лампы. 
          
          На столах стоят тарелки, чашки, за прилавком лежит чепец кассира, рядом с кассой на тарелочке лежит мелочь. Словно тут только что обедали, болтали и общались студенты, и вдруг пропали.
          `,
        callToAction: 'Сходить в столовую',
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1',
            text: 'Вернуться в главное здание',
          },
        ],
      },
      Q1_4: {
        id: 'Q1_4',
        type: StepType.question,
        maxAnswers: 1,
        text: `Вы поднимаетесь на лифте и попадаете в управление Университета. В коридорах темно, только в одном кабинете горит тусклый свет. Вы открываете дверь. 
          За дверью — деревянный стол, кресло. Зелёная лампа — она подсвечивает весь кабинет. 
          На столе стоит чашка с кофе, лежит фотография и бумага с записями.
          `,
        callToAction: 'Посмотреть на кафедре',
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1_4_1',
            text: 'Осмотреть чашку с кофе',
          },
        ],
      },
      Q1_4_1: {
        id: 'Q1_4_1',
        type: StepType.question,
        maxAnswers: 1,
        text: `В чашке — ещё теплый кофе. На чашке — надпись: «Светлане Васильевне от выпуска 2021 — вы лучший учитель».  

          Рядом с чашкой кофе лежит фотография.
          `,
        callToAction: 'Осмотреть чашку с кофе',
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1_4_2',
            text: 'Посмотреть фотографию',
          },
        ],
      },
      Q1_4_2: {
        id: 'Q1_4_2',
        type: StepType.question,
        maxAnswers: 1,
        text: `На фотографии — молодая женщина стоит на болоте в резиновых сапогах и костюме цвета хаки. Она улыбается и протягивает в камеру какое-то растение алого цвета с острыми как иглы отростками, напоминающими зубы. 

          Под фотографией ты замечаешь бумагу из блокнота, на ней какие-то заметки.
          `,
        callToAction: 'Посмотреть фотографию',
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1_4_3',
            text: 'Посмотреть бумагу',
          },
        ],
      },
      Q1_4_3: {
        id: 'Q1_4_3',
        type: StepType.question,
        maxAnswers: 1,
        text: `Вы поднимаете бумагу — лист очевидно вырван из блокнота. Это список дел из 10 пунктов, все они зачёркнуты, за исключением последнего: «Удобрить Дионею».

          Кажется, у меня есть зацепки, пора проанализировать то, что мы нашли.
          `,
        callToAction: 'Посмотреть бумагу',
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q2',
            text: 'Проанализировать зацепки',
          },
          {
            id: 'b2',
            nextStep: 'Q1',
            text: 'Вернуться в главное здание',
          },
        ],
      },
      Q2: {
        id: 'Q2',
        type: StepType.message,
        text: `Кажется, у нас есть зацепки, давай подумаем над всем, что удалось узнать. 
          А пока предлагаю тебе пройти тест на тип мышления:
          `,
        callToAction: '',
        person: Interlocutors.CAT,
        nextStep: 'Q3',
      },
      Q3: TEST_TVOI_VEKTOR_2, // ТЕСТ: Твой вектор (тип мышления) 2
      Q4: {
        id: 'Q4',
        text: `Ура, тест пройден! Мы нашли отгадку — нужно найти Ботанический сад.`,
        callToAction: 'Найди Ботанический сад',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.CAT,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q5',
            text: 'Посетить Ботанический сад',
          },
        ],
      },
      Q5: {
        id: 'Q5',
        text: `Вы попали в Ботанический сад. Удивительные растения сменяют друг друга, цветы, светящиеся в темноте, поющие кустарники. 

        Перед вами возникает большой цветок с 7 лепестками, каждый из которых имеет свой цвет: жёлтый, зелёный, фиолетовый, красный, синий, оранжевый, голубой. 
        
        Перед цветком установлена табличка: 
        Aliis aliud expedit
        Цветок, определяющий интересы
        `,
        callToAction: 'Найди Ботанический сад',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q6',
            text: 'Узнать о своих интересах',
          },
        ],
      },
      Q6: {
        id: 'Q6',
        text: `Оооо, у нас тут интересный экземпляр. Давай-ка посмотрим, какие у тебя интересы.`,
        callToAction: 'Узнать о своих интересах',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: TEST_TVOI_VIBOR.id,
            text: 'Узнать о своих интересах',
          },
        ],
      },
      TEST_TVOI_VIBOR, // ТЕСТ НА ИНТЕРЕСЫ
      Q8: {
        id: 'Q8',
        text: `Вы продвигаетесь вглубь сада и слышите крики. 
        Вы с Вжухом бежите на крик и вашим глазам представляется удивительное зрелище: трехметровый цветок, точь-в-точь такой же, как на фото в кабинете, зажал в пасти свитер женщины, она машет вам рукой.
        `,
        callToAction: 'Найди Ботанический сад',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q9',
            text: 'Поговорить с женщиной',
          },
        ],
      },
      Q9: {
        id: 'Q9',
        text: `Как хорошо, что вы сюда добрались! Не понимаю, куда все подевались. Помогите мне выбраться, пожалуйста. Я сама не помню, что делать, но я уверена, что я знала раньше.`,
        callToAction: 'Найди Ботанический сад',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.TEACHER,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q10',
            text: 'Поговорить с Вжухом',
          },
        ],
      },
      Q10: {
        id: 'Q10',
        text: `{{NAME}}, я думаю, это растение — Дионея, которую мы видели на фотографии. Знаешь, я слышал, что есть в Москве одна старейшая библиотека — там можно найти книги на все темы на свете. Может, попробуем поискать информацию там?`,
        callToAction: 'Найди Ботанический сад',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.CAT,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q11',
            text: 'Отправиться в Библиотеку',
          },
        ],
      },
      Q11: {
        id: 'Q11',
        text: `Вы огибаете памятник Достоевскому, проходите высокие колонны и попадаете в здание Российской государственной библиотеки. Вы поднимаетесь по большой мраморной лестнице и попадаете в просторный зал Библиотеки.`,
        callToAction: 'Найди Ботанический сад',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q12',
            text: 'Отправиться в архив',
          },
        ],
      },
      Q12: {
        id: 'Q12',
        text: `Вы спускаетесь на лифте в архив. Вдоль стен рядами стоят шкафчики с наименованиями в алфавитном порядке.
        «Думаю, надо поискать в шкафчике на «Д», — советует Вжух.
        `,
        callToAction: 'Найди Ботанический сад',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: TEST_TVOI_VIBOR_2.id,
            text: 'Поищите в архиве',
          },
        ],
      },
      TEST_TVOI_VIBOR_2, // !!!!! ДОБАВИТЬ ТЕСТ
      Q14: {
        id: 'Q14',
        text: `Ура! Мы нашли координаты книги. Давай воспользуемся пневмопочтой 
        Пневмопочта — «воздушная почта». Опусти карточку с названием и координатами книги в пневмопочту и получи её в читальном зале.
        Знаешь, я думаю, стоит положить фотографию цветка в капсулу с почтой, чтобы библиотекарь наиболее точно отыскал цветок в картотеке.
        `,
        callToAction: '',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.CAT,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q15',
            text: 'Воспользоваться пневматической почтой и отправиться в читальный зал',
          },
        ],
      },
      Q15: {
        id: 'Q15',
        text: `Вы поднимаетесь в читальный зал, на пункте выдачи вас уже ждёт древняя потрепанная книга «Загадочные растения Нового Света. Перевод Велимудра Мухоморова. 1786 год. Переиздание 1930».`,
        callToAction: 'Отправиться в читальный зал',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q16',
            text: 'Прочитать статью про Дионею',
          },
        ],
      },
      Q16: {
        id: 'Q16',
        text: `Вы открываете книгу на странице 365. 
        Dionaea muscipula — вид хищных растений из рода Дионея семейства Росянковые (Droseracea). Растение болотистых областей восточного побережья Нового Света. 
        
        Венерина — мухоловка, питается насекомыми и паукообразными, ловит своих жертв «пастью». Растение улавливает своих жертв волосками на поверхности листьев. Для захлопывания ловчего аппарата необходимо оказать механическое воздействие минимум на два волоска на листе с интервалом не более 20 секунд. 
        
        Такая избирательность обеспечивает защиту от случайного захлопывания в ответ на падение объектов, не имеющих питательной ценности (капли дождя, мусор и т.д.). 
        
        Более того, переваривание начинается, как минимум после пятикратной стимуляции чувствительных волосков.
        
        Разжать пасть крайне трудно, но можно. Главное не задеть волоски. Однажды автор сего фолианта спас своего друга из гигантской мухоловки, используя весло.
        `,
        callToAction: 'Прочитать статью про Дионею',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q17',
            text: 'Поговорить с Вжухом',
          },
        ],
      },
      Q17: {
        id: 'Q17',
        text: `«Кажется, мы должны разжать пасть цветку. Главное — не задеть усики, а то оно озвереет. У тебя же осталась отмычка с маяка»?`,
        callToAction: 'Поговорить с Вжухом',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.CAT,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q18',
            text: 'Отправиться в Ботанический сад',
          },
        ],
      },
      Q18: {
        id: 'Q18',
        text: `«Ох, как я рада вас видеть, вы вернулись», — Светлана Васильевна машет вам сверху. 
        «Да, сейчас мы вас спустим», — говоришь ты. Главное, не бойтесь, я постараюсь не задеть его усики.
        `,
        callToAction: 'Поговорить с Вжухом',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.TEACHER,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q19',
            text: 'Открыть пасть мухоловке',
          },
        ],
      },
      Q19: {
        id: 'Q19',
        text: `Вы разжимаете пасть мухоловке отмычкой. Растение разжимает свою хватку и Светлана Васильевна приземляется на мшистую поляну. 

        Но вдруг вслед за Светланой Васильевной растение выплёвывает электронный пропуск.
        `,
        callToAction: 'Открыть пасть мухоловке',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q20',
            text: 'Забрать пропуск',
          },
        ],
      },
      Q20: {
        id: 'Q20',
        text: `Вы рассматриваете пропуск. На нём фотография улыбающегося молодого мужчины и надпись: «Иван Петров, Старший научный сотрудник, Факультет вычислительной математики и кибернетики».`,
        callToAction: 'Открыть пасть мухоловке',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: EXIT_ID,
            text: 'Выйти на карту',
          },
        ],
      },
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_STEP]: {
        person,
        id: TO_MAP_STEP,
        type: StepType.to_map_step,
      },
    },
  },
};

export const scenario_1_19_1: Scenario = {
  scenarioId: SceneId.scenario_1_19_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.TEACHER].name,
    person: Interlocutors.TEACHER,
    text: `{{NAME}}, книги меня совсем увлекли, так соскучилась по библиотеке! Приходи попозже, я скоро закончу.`,
    buttons: ['Закрыть'],
  },
};

/**
 * эрмитаж
 */
export const scenario_1_19_4: Scenario = {
  scenarioId: SceneId.scenario_1_19_4,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Научная библиотека Эрмитажа',
    scene_id: 'scenario_1_19_4',
    startId: 'Q1',
    steps: {
      Q1: {
        id: 'Q1',
        text: `На берегу реки Невы расположилось красивое здание Научной библиотеки Эрмитажа. Тут есть сведения о самых необычных творениях природы и человека. Поищи информацию о странном растении тут.`,
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q2',
            text: 'Отправиться в архив',
          },
        ],
      },
      Q2: {
        id: 'Q2',
        text: `Вы спускаетесь на лифте в архив. Вдоль стен рядами стоят шкафчики с наименованиями в алфавитном порядке.
        «Думаю, надо поискать в шкафчике на «Д», — советует Вжух.
        `,
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: TEST_TVOI_VIBOR_3.id,
            text: 'Поищите в архиве',
          },
        ],
      },
      TEST_TVOI_VIBOR_3, // !!!!! ДОБАВИТЬ ТЕСТ
      Q4: {
        id: 'Q4',
        text: `Ура! Мы нашли координаты книги. Давай воспользуемся пневмопочтой. 
        
        Пневмопочта — «воздушная почта». Опусти карточку с названием и координатами книги в пневмопочту и получи её в читальном зале.
        
        Знаешь, я думаю, стоит положить фотографию цветка в капсулу с почтой, чтобы библиотекарь наиболее точно отыскал цветок в картотеке.
        `,
        callToAction: '',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.CAT,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q5',
            text: 'Воспользоваться пневматической почтой и отправиться в читальный зал',
          },
        ],
      },
      Q5: {
        id: 'Q5',
        text: `Вы поднимаетесь в читальный зал, на пункте выдачи вас уже ждёт древняя потрепанная книга «Загадочные растения Нового Света. Перевод Велимудра Мухоморова. 1786 год. Переиздание 1930».`,
        callToAction: 'Отправиться в читальный зал',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q6',
            text: 'Прочитать статью про Дионею',
          },
        ],
      },
      Q6: {
        id: 'Q6',
        text: `Вы открываете книгу на странице 365. 
        Dionaea muscipula — вид хищных растений из рода Дионея семейства Росянковые (Droseracea). Растение болотистых областей восточного побережья Нового Света. 
        
        Венерина — мухоловка, питается насекомыми и паукообразными, ловит своих жертв «пастью». Растение улавливает своих жертв волосками на поверхности листьев. Для захлопывания ловчего аппарата необходимо оказать механическое воздействие минимум на два волоска на листе с интервалом не более 20 секунд.
                
        Такая избирательность обеспечивает защиту от случайного захлопывания в ответ на падение объектов, не имеющих питательной ценности (капли дождя, мусор и т.д.). 

        Более того, переваривание начинается, как минимум после пятикратной стимуляции чувствительных волосков.

        Разжать пасть крайне трудно, но можно. Главное не задеть волоски. Однажды автор сего фолианта спас своего друга из гигантской мухоловки, используя весло.
        `,
        callToAction: 'Прочитать статью про Дионею',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q7',
            text: 'Поговорить с Вжухом',
          },
        ],
      },
      Q7: {
        id: 'Q7',
        text: `«Кажется, мы должны разжать пасть цветку. Главное — не задеть усики, а то оно озвереет. У тебя же осталась отмычка с маяка»?`,
        callToAction: 'Отправиться в Ботанический сад',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.CAT,
        buttons: [
          {
            id: 'b1',
            nextStep: EXIT_ID,
            text: 'Выйти на карту',
          },
        ],
      },
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_STEP]: {
        person,
        id: TO_MAP_STEP,
        type: StepType.to_map_step,
      },
    },
  },
};

export const scenario_1_19_5: Scenario = {
  scenarioId: SceneId.scenario_1_19_5,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.SYSTEM].name,
    person: Interlocutors.SYSTEM,
    text: `Приходи попозже`,
    buttons: ['Закрыть'],
  },
};

/**
 * МГУ (возвращение в сад)
 */
export const scenario_1_19_6: Scenario = {
  scenarioId: SceneId.scenario_1_19_6,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'МГУ (ботанический сад)',
    scene_id: 'scenario_1_19_6',
    startId: 'Q1',
    steps: {
      Q1: {
        id: 'Q1',
        text: `«Ох, как я рада вас видеть, вы вернулись», — Светлана Васильевна машет вам сверху. 
        «Да, сейчас мы вас спустим», — говоришь ты. Главное, не бойтесь, я постараюсь не задеть его усики.
        `,
        callToAction: 'Поговорить с Вжухом',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.TEACHER,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q2',
            text: 'Открыть пасть мухоловке',
          },
        ],
      },
      Q2: {
        id: 'Q2',
        text: `Вы разжимаете пасть мухоловке отмычкой. Растение разжимает свою хватку и Светлана Васильевна приземляется на мшистую поляну. 

        Но вдруг вслед за Светланой Васильевной растение выплёвывает электронный пропуск.
        `,
        callToAction: 'Открыть пасть мухоловке',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q3',
            text: 'Забрать пропуск',
          },
          {
            id: 'b2',
            nextStep: EXIT_ID,
            text: 'Поговорить с учительницей',
          },
        ],
      },
      Q3: {
        id: 'Q3',
        text: `Вы рассматриваете пропуск. На нём фотография улыбающегося молодого мужчины и надпись: «Иван Петров, Старший научный сотрудник, Факультет вычислительной математики и кибернетики».`,
        callToAction: 'Открыть пасть мухоловке',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: EXIT_ID,
            text: 'Выйти на карту',
          },
        ],
      },
      Q4: {
        id: 'Q4',
        text: `Приходи попозже`,
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.SYSTEM,
        buttons: [
          {
            id: 'b1',
            nextStep: EXIT_ID,
            text: 'Выйти на карту',
          },
        ],
      },
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_STEP]: {
        person,
        id: TO_MAP_STEP,
        type: StepType.to_map_step,
      },
    },
  },
};

export const scenario_1_19_7: Scenario = {
  scenarioId: SceneId.scenario_1_19_7,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.SYSTEM].name,
    person: Interlocutors.SYSTEM,
    text: `Приходи попозже`,
    buttons: ['Закрыть'],
  },
};
