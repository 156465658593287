/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const olympicPark_1_1_1: Scenario = {
  scenarioId: SceneId.olympicPark_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Олимпийский парк в Сочи',
    person: Interlocutors.SYSTEM,
    text: `Вы подходите к масштабному макету Олимпийского парка. Перед вами поражающие своими размерами спортивные сооружения.

    В парке не видно людей, но с футбольного стадиона слышен шум. Вы решаете посмотреть, что там происходит.`,
    buttons: ['Начать'],
  },
};
