import styled from 'styled-components';
import { HeaderUserBarProps } from './HeaderUserBarTypes';

export const StyledHeaderUserBar = styled.div<HeaderUserBarProps>`
  display: flex;

  & > * + * {
    margin-left: 20px;
  }
`;
