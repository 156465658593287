/* eslint-disable max-len */
import {
  Action,
  SceneId,
  StepType,
  Scenario,
  ScenarioType,
  Interlocutors,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

export const scenario_2_22: Scenario = {
  scenarioId: SceneId.scenario_2_22,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Москва-Сити',
    person: Interlocutors.SYSTEM,
    text: `Вы подходите к Москва-Сити. В размороженном виде эта достопримечательность кажется еще более грандиозной. У входа стоит Сан Саныч и раздраженно разговаривает с кем-то по телефону. Вдруг он замечает вас, вешает трубку, и начинает разговор...`,
    buttons: ['Начать'],
  },
};

const person = Interlocutors.ENTREPRENEUR;
const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const scenario_2_22_1: Scenario = {
  scenarioId: SceneId.scenario_2_22_1,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Москва-Сити',
    scene_id: 'scenario_2_22_1',
    startId: 'Q0/0',
    steps: {
      ...splitStep({
        id: 'Q0',
        person,
        text: `Привет, {{NAME}}! Как здорово снова видеть тебя в нашем парке! Благодаря твоим усилиям здесь началась настоящая весна! Видишь, как здорово солнечные лучи бликуют на окнах небоскребов! Ох, красота! 😍

Не обращай внимания на мое слегка взбудораженное состояние… Все дело в подготовке к параду 😔`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А что случилось?',
            nextStep: 'ALT1',
          },
          {
            action: Action.GiveBook,
            id: 'b2',
            text: 'Я и [[сам/сама]] приятно [[удивлен/удивлена]] такому чудесному преображению «Большой перемены»!',
            nextStep: 'Q1',
          },
        ],
      }),
      ALT1: {
        person,
        id: 'ALT1',
        text: 'Я хочу снять наш парад с воздуха! Отправил сегодня своего помощника потренироваться в видеосъемке на телефон, а он не может разобраться, как это делать! Вот я и нервничаю, ведь до парада осталось совсем немного времени! 😱',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            action: Action.GiveBook,
            id: 'b1',
            text: 'Я знаю, что нужно делать! Вам для помощника нужно взять обучающий экспресс-курс по съемке видео на телефон, и тогда он точно отлично снимет парад! (Игровое время не будет заморожено)',
            nextStep: 'ALT2',
          },
          {
            action: Action.GiveBook,
            id: 'b2',
            text: 'Да, вот это незадача! Давайте, я помогу Вам найти мастера по видеосъемке, который быстро обучит Вашего помощника необходимым навыкам. (Игровое время будет заморожено на 20 минут)',
            nextStep: 'ALT3/0',
          },
          {
            action: Action.GiveBook,
            id: 'b3',
            text: 'Давайте, я [[сам/сама]] помогу Вашему помощнику разобраться со съемкой видео? Можете быть спокойны, вместе мы точно справимся и клип про парад будет снят на высшем уровне! (Игровое время будет заморожено на 2 часа)',
            nextStep: 'ALT3/0',
          },
        ],
      },
      ALT2: {
        person,
        id: 'ALT2',
        type: StepType.message,
        text: `Точно! Так и поступлю! В благодарность за такой дельный совет я хочу подарить тебе кое-что особенное! Вот держи — это книга Стивена Хокинга с его личным автографом! Однажды он приезжал к нам в парк на научную конференцию и мне удалось раздобыть автограф. Это очень ценный экземпляр! 😊`,
        nextStep: 'Q2/0',
      },
      ...splitStep({
        person,
        id: 'ALT3',
        type: StepType.message,
        text: `Я так благодарен тебе за то, что ты [[потратил/потратила]] свое время! Теперь мы точно снимем прекрасный клип о нашем параде. 

За твою помощь я хочу подарить тебе кое-что особенное! Вот держи — это книга Стивена Хокинга с его личным автографом! Однажды он приезжал к нам в парк на научную конференцию и мне удалось раздобыть автограф. Это очень ценный экземпляр! 😊`,
        nextStep: 'Q2/0',
      }),
      Q1: {
        person,
        id: 'Q1',
        type: StepType.message,
        text: `Кстати, в благодарность за твою помощь в парке я хочу подарить кое-что особенное! Вот держи — это книга Стивена Хокинга с его личным автографом! Однажды он приезжал к нам в парк на научную конференцию и мне удалось раздобыть автограф. Это очень ценный экземпляр! 😊`,
        nextStep: 'Q2/0',
      },
      ...splitStep({
        id: 'Q2',
        person,
        text: `Ах да, я напал на след нашего воришки ракеты! Именно из-за него у нас в парке начали происходить все неурядицы. Я не уверен на 100%, поэтому не могу рассказать подробности. Пока… 

Для начала хочу убедиться в своей догадке 😑`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Вот это новости! Расскажите, пожалуйста!',
            nextStep: 'Q3/0',
          },
          {
            id: 'b2',
            text: 'Ух ты! Неужели Вы узнали главную тайну «Большой перемены»?! Вы обязательно должны мне все рассказать!',
            nextStep: 'Q3/0',
          },
        ],
      }),
      ...splitStep({
        person,
        id: 'Q3',
        type: StepType.message,
        text: `Все, что я могу тебе рассказать на данный момент — ВЫ ВСТРЕЧАЛИСЬ. Ничего более конкретного сообщить не могу… 

Но хочу тебя предупредить: гляди в оба!`,
        nextStep: EXIT_ID,
      }),

      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
