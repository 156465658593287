/* eslint-disable max-len */
import { Step, SceneId, StepType, Scenario, ScenarioType, Interlocutors } from '../../../scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';
import { images } from '../../tests/images';

export const scenario_2_4_0: Scenario = {
  scenarioId: SceneId.scenario_2_4_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Эрмитаж',
    text: `Вы находитесь у точной копии Зимнего дворца. Его пышная настенная лепнина поражает воображение! У главных ворот топчется Бабуля. Вы начинаете с ней диалог...`,
    buttons: ['Начать'],
  },
};

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

const CREATIVE_TEST: { [id: string]: Step } = {
  ...splitStep({
    person: Interlocutors.TEACHER,
    id: 'CREATIVE_TEST',
    callToAction: 'Напиши и отправь',
    type: StepType.question,
    maxAnswers: 1,
    text: `Перед тобой несколько картин известных художников абстракционистов. Придумай им названия.

На обдумывание каждой картины у тебя будет 1 минута, поэтому не затягивай.

Напоминаю, названия не могут нарушать Положение о конкурсе, особенно пункт 1.9. Если хочешь изучить Положение, оно есть по <a href="https://bolshayaperemena.online/api/link/short/4beffe5b9d" target="_blank">ссылке</a>

В общем, твои ответы должны быть приличными и цензурными 😊

Придумай название для каждой картины, впиши в поле ниже и отправь. 

Обращаю внимание, что нужно обязательно нажать кнопку «Отправить ответ», до окончания времени на секундомере, иначе ответ не будет засчитан.`,
    buttons: [
      {
        id: 'b1',
        text: 'Начать',
        nextStep: 'CREATIVE_TEST_1',
      },
    ],
  }),
  CREATIVE_TEST_1: {
    person: Interlocutors.TEACHER,
    id: 'CREATIVE_TEST_1',
    variable: 'CREATIVE_TEST_1',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_1, title: '' },
    nextStep: 'CREATIVE_TEST_2',
    timeout: 60,
  },
  CREATIVE_TEST_2: {
    person: Interlocutors.TEACHER,
    id: 'CREATIVE_TEST_2',
    variable: 'CREATIVE_TEST_2',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_2, title: '' },
    nextStep: 'CREATIVE_TEST_3',
    timeout: 60,
  },
  CREATIVE_TEST_3: {
    person: Interlocutors.TEACHER,
    id: 'CREATIVE_TEST_3',
    variable: 'CREATIVE_TEST_3',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_3, title: '' },
    nextStep: 'CREATIVE_TEST_4',
    timeout: 60,
  },
  CREATIVE_TEST_4: {
    person: Interlocutors.TEACHER,
    id: 'CREATIVE_TEST_4',
    variable: 'CREATIVE_TEST_4',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_4, title: '' },
    nextStep: 'CREATIVE_TEST_5',
    timeout: 60,
  },
  CREATIVE_TEST_5: {
    person: Interlocutors.TEACHER,
    id: 'CREATIVE_TEST_5',
    variable: 'CREATIVE_TEST_5',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_5, title: '' },
    nextStep: 'CREATIVE_TEST_6',
    timeout: 60,
  },
  CREATIVE_TEST_6: {
    person: Interlocutors.TEACHER,
    id: 'CREATIVE_TEST_6',
    variable: 'CREATIVE_TEST_6',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_6, title: '' },
    nextStep: 'Q6',
    timeout: 60,
  },
};

export const scenario_2_4: Scenario = {
  scenarioId: SceneId.scenario_2_4,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    timeoutMessage: 'К сожалению, время на ответ истекло.',
    scene_id: 'scenario_2_4',
    startId: 'Q1/0',
    title: 'Эрмитаж',
    steps: {
      ...splitStep({
        person: Interlocutors.GRANDMOTHER,
        text: `{{NAME}}, ты как раз вовремя! Бесценные экспонаты вот-вот покроются льдом и будут навсегда испорчены! 😱

Мы не можем этого допустить. Скорее помоги спрятать их в ящики с утеплителем.`,
        nextStep: 'Q2/0',
        id: 'Q1',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А где это мы?',
            nextStep: 'Q2/0',
          },
        ],
      }),
      ...splitStep({
        id: 'Q2',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        maxAnswers: 1,
        text: `Мы в Зимнем дворце — это главный императорский дворец России. Его называют Зимним, потому что императоры жили здесь зимой, а лето проводили в загородных летних резиденциях. 

Сейчас этот дворец является частью самого большого музея нашей страны — Эрмитажа. По площади он занимает третье место в мире и насчитывает 365 залов.

В нашем парке находится точная копия дворца, а настоящий расположен в культурной столице России — Санкт-Петербурге.

Мало кто знает, что работы всемирно известных великих художников Леонардо да Винчи и Микеланджело хранятся здесь.`,
        callToAction: 'Мы не можем позволить им замерзнуть!',
        buttons: [
          {
            id: 'b1',
            text: 'Ого, конечно! Что нужно делать?',
            nextStep: 'Q3/0',
          },
        ],
      }),
      ...splitStep({
        person: Interlocutors.GRANDMOTHER,
        id: 'Q3',
        text: `Нужно снимать картины со стен и укладывать их в ящики. Будь очень [[внимателен/внимательна]] и [[аккуратен/аккуратна]], главное — не потерять таблички с названиями, которые висят рядом с каждой картиной. Они маленькие и их легко упустить из виду. 

Пойдем, учительница и Вжух уже вовсю помогают с картинами, они покажут тебе, что делать.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ок, будет сделано!',
            nextStep: 'Q4/0',
          },
        ],
      }),
      ...splitStep({
        person: Interlocutors.TEACHER,
        id: 'Q4',
        text: `Как хорошо, что вы здесь! У нас случился маленький конфуз… дело в том, что…даже не знаю как сказать… Видите ли, Вжух…хотел как можно быстрее спасти все картины и случайно забыл про карточки с названиями! Что же теперь делать? Картины не могут остаться без названий… 😱

Необходимо срочно придумать названия картинам!`,
        type: StepType.question,
        maxAnswers: 1,
        callToAction: 'Поможешь нам?',
        buttons: [
          {
            id: 'b1',
            text: 'Да, конечно, я [[готов/готова]]!',
            nextStep: 'CREATIVE_TEST/0',
          },
          {
            id: 'b2',
            text: 'А вдруг я не справлюсь?',
            nextStep: 'Q5',
          },
        ],
      }),
      Q5: {
        person: Interlocutors.TEACHER,
        id: 'Q5',
        text: 'У тебя прекрасная фантазия! Мы уверены, что ты придумаешь отличные новые названия всем картинам!',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ну хорошо, я попробую',
            nextStep: 'Q5_1',
          },
        ],
      },
      Q5_1: {
        person: Interlocutors.SYSTEM,
        id: 'Q5_1',
        text: 'Данный тест является тренировочным и не приносит баллов за конкурс.',
        type: StepType.message,
        nextStep: 'CREATIVE_TEST/0',
      },
      ...CREATIVE_TEST,
      Q6: {
        person: Interlocutors.TEACHER,
        text: 'Спасибо тебе огромное за помощь! Я не сомневаюсь, что новые названия получились даже лучше прежних 😍',
        id: 'Q6',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо, я [[старался/старалась]]',
            nextStep: 'Q7',
          },
          {
            id: 'b2',
            text: 'Это было совсем не сложно',
            nextStep: 'Q7',
          },
        ],
      },
      Q7: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q7',
        type: StepType.question,
        text: 'А теперь нужно упаковать оставшиеся картины!',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, конечно, время не ждет! Показывайте, в каких залах еще нужно поработать!',
            nextStep: 'Q8',
          },
        ],
      },
      Q8: {
        person: Interlocutors.SYSTEM,
        id: 'Q8',
        text: 'Вы заканчиваете упаковывать картины в Эрмитаже, как вдруг с улицы раздается какой-то шум. Вы выглядываете из окна и видите, как одна из сотрудниц Эрмитажа бегает по дворику и зовет кошку.',
        type: StepType.question,
        maxAnswers: 1,
        delay: 7000,
        buttons: [
          {
            id: 'b1',
            text: 'Узнать, что случилось',
            nextStep: 'ALT_Q1/0',
          },
        ],
      },

      ...splitStep({
        person: Interlocutors.CAT,
        id: 'ALT_Q1',
        type: StepType.message,
        text: `Ох уж эти эрмитажные коты — настоящие аристократы! Ты знаешь, что Эрмитаж знаменит своими котами, которые несут официальную службу по поимке крыс и мышей? Да-да, такая у них полезная работа!

Они главные смотрители и настоящий символ Эрмитажа.

Так вот, один из этих самых «символов» — кошка Марта. Это существо тонкой душевной организации. Она так перепугалась нашествия ледяного тумана, что пустилась бежать, куда глаза глядят  - и вот результат! Сидит теперь на дереве, мерзнет, а спуститься сама никак не может.`,
        nextStep: 'ALT_Q2',
      }),
      ALT_Q2: {
        person: Interlocutors.TEACHER,
        id: 'ALT_Q2',
        text: 'Бедная Марта! Что же с ней теперь будет?!',
        nextStep: 'ALT_Q3/0',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Не переживайте! Я [[сам/сама]] сниму Марту с дерева. Ведь у меня как раз есть лестница! (Использовать лестницу) (игровое время будет заморожено  на 3 минуты)',
            nextStep: 'ALT_Q4/0',
          },
          {
            id: 'b2',
            text: 'А давайте позовем охранника и он спустит Марту. (игровое время будет заморожено  на 5 минут)',
            nextStep: 'ALT_Q4/0',
          },
          {
            id: 'b3',
            text: 'А у вас есть любимое лакомство Марты? Может, она слезет ради него? (игровое время не будет заморожено)',
            nextStep: 'ALT_Q4/0',
          },
        ],
      },
      ...splitStep({
        id: 'ALT_Q4',
        person: Interlocutors.TEACHER,
        type: StepType.question,
        maxAnswers: 1,
        text: `Марта спасена! Как же здорово, что ты [[решил/решила]] помочь. Спасибо тебе! 

А теперь я отнесу бедняжку обратно в Эрмитаж отогревать и отпаивать теплым молоком.`,
        buttons: [
          {
            id: 'b1',
            text: 'Вернуться к упаковке картин',
            nextStep: 'Q9',
          },
        ],
      }),
      Q9: {
        id: 'Q9',
        text: 'Спасибо тебе огромное за помощь с экспонатами! Теперь все они точно будут в целости и сохранности! 😍',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        maxAnswers: 1,
        delay: 7000,
        buttons: [
          {
            id: 'b1',
            text: 'Пожалуйста!',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
