import Api from '../utils/Api';

import { AxiosPromise } from 'axios';
import { ApiChoice, ApiChoicesResponse } from '../types/api/user';
import { IProtocol } from '../types';

class ChoicesApi {
  static getChoices(): AxiosPromise<ApiChoicesResponse> {
    return Api.call(`/choices`);
  }

  static postChoice(id: string, protocol: IProtocol): AxiosPromise<ApiChoice> {
    const data = {
      status: 'completed',
      type: 'msu',
      data: {
        test: id,
        protocol,
      },
    };
    return Api.call(`/choices`, {
      data,
      method: 'post',
    });
  }
}

export default ChoicesApi;
