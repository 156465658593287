import styled from 'styled-components';
import { FogStyledProps } from './FogTypes';

const TOP_TOLERANCE = 35;
const LEFT_TOLERANCE = 10;

export const FogStyled = styled.img<FogStyledProps>`
  position: absolute;
  z-index: 3;
  width: 18%;
  height: 62%;
  overflow: hidden;
  pointer-events: none;
  top: ${(props) => props.top - TOP_TOLERANCE}%;
  left: ${(props) => props.left - LEFT_TOLERANCE}%;
  transform: ${(props) => (props.isBridge ? 'scale(0.7) rotate(-6deg)' : 'initial')};

  /* width: 150px;
  height: 150px;*/
  /* background-color: black; */
`;
