/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const scenario_3_20: Scenario = {
  scenarioId: SceneId.scenario_3_20,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Иннополис',
    text: 'Вы сидите в кафе холла Иннополиса, ожидая Софию. Вдруг дверь резко открывается и в помещение стремительно и грациозно входит элегантная девушка. По просиявшему взгляду Малевича вы понимаете, что это и есть София. Малевич радостно подскакивает и бежит в ее теплые объятия...',
    buttons: ['Продолжить'],
  },
};
