/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

export const scenario_3_15_0: Scenario = {
  scenarioId: SceneId.scenario_3_15_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Поезд в «Артек»',
    text: 'Вы находитесь у входа в вагон поезда в «Артек». Синий набирает код от двери и вы заходите внутрь.',
    buttons: ['Продолжить'],
  },
};

const EXIT_ID = 'EXIT_ID';

export const scenario_3_15: Scenario = {
  scenarioId: SceneId.scenario_3_15,
  type: ScenarioType.chat,
  data: {
    title: 'Поезд в «Артек»',
    scene_id: 'scenario_3_15',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q1',
        text: `48 — запомнил правильно.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Погоди, откуда ты знаешь код от замка?',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q2',
        type: StepType.message,
        text: `Мы с Софией часто практиковались в логических задачках для развлечения, поэтому я с легкостью справился.`,
        nextStep: 'Q3',
      },
      Q3: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q3',
        type: StepType.message,
        text: `Однажды я ездил к морю на точно таком же поезде с моей хозяйкой Софией и вспомнил, что здесь как раз должен быть подходящий рычаг — ручка стоп-крана.

Я внимательно прочел инструкцию и понял, что если я просто вытащу ручку, то поезд будет обездвижен. Поэтому я сбегал в Херсонес, взял там кирку и решил приделать ее на место ручки стоп-крана.`,
        nextStep: 'Q4',
      },
      Q4: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q4',
        type: StepType.message,
        text: `Но, кажется, кирка не подошла…`,
        nextStep: 'Q5',
      },
      Q5: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q5',
        text: `Да, Бабуля, кирка не подошла. Становилось все холоднее, уже все вокруг заледенело, поэтому мне надо было быстро выбрать: либо поезд, либо машина 😿`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Теперь все встало на свои места. Ты решил, что остановить ледяной дым важнее, поэтому взял ручку стоп-крана и отправился в Херсонес чинить машину. Там мы тебя и нашли.',
            nextStep: 'Q6',
          },
        ],
      },
      Q6: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q6',
        type: StepType.message,
        text: `Да, все было именно так. Мне очень стыдно, что я натворил здесь дел! Из-за меня «Большая перемена» столько натерпелась! 🙀`,
        nextStep: 'Q7',
      },
      Q7: {
        person: Interlocutors.PETROV,
        id: 'Q7',
        type: StepType.message,
        text: `Да нет, это все из-за моей дым-машины! Надо было мне сконструировать ее получше и не оставлять без присмотра 😔`,
        nextStep: 'Q8',
      },
      Q8: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q8',
        type: StepType.message,
        text: `Нет, Петров! Синий испугался моей недоработанной 10G вышки. Стало быть, все из-за меня 🙁️`,
        nextStep: 'Q9',
      },
      Q9: {
        person: Interlocutors.TEACHER,
        id: 'Q9',
        type: StepType.message,
        text: `В том, что случилось никто не виноват! А особенно не виноват Вжух...`,
        nextStep: 'Q10',
      },
      Q10: {
        person: Interlocutors.PETROV,
        id: 'Q10',
        type: StepType.message,
        text: `Вжух, дружище! Прости нас!`,
        nextStep: 'Q11',
      },
      Q11: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q11',
        type: StepType.message,
        text: `Вжух, миленький! ПРОСТИ! Я и не хотел в это верить, но сам понимаешь.. Улики.. 😔`,
        nextStep: 'Q12',
      },
      Q12: {
        person: Interlocutors.CAT,
        id: 'Q12',
        type: StepType.message,
        text: `Друзья, мне, конечно, было неприятно, но я понимаю, что все действительно указывало как будто на меня. Если бы я не знал, что не делал этого, то тоже на себя подумал. Так что, дорогие, конечно же, ваши извинения приняты 😺`,
        nextStep: 'Q13/0',
      },
      ...splitStep({
        id: 'Q13',
        person: Interlocutors.GRANDMOTHER,
        text: `Как хорошо, что нам все удалось выяснить! А что я говорила — никогда нельзя делать поспешных выводов 😊

Ну, кажется у нас появилась новая миссия!`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Какая миссия?',
            nextStep: 'Q14',
          },
        ],
      }),
      Q14: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q14',
        type: StepType.message,
        text: `Надо найти хозяйку Синего и вернуть его домой! Ты [[готов/готова]] отправиться на поиски Софии?`,
        nextStep: 'Q15',
      },
      Q15: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q15',
        text: 'О, моя София! Если вы поможете мне вернуться к любимой хозяйке, моя благодарность вам будет глубже Марианской впадины! 😻',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Давайте найдем Софию!',
            nextStep: 'Q16',
          },
          {
            id: 'b2',
            text: 'Похоже, вам снова без меня не справиться',
            nextStep: 'Q16',
          },
        ],
      },
      Q16: {
        person: Interlocutors.PETROV,
        id: 'Q16',
        type: StepType.message,
        text: `Погодите, это не единственная миссия! Еще нам надо вернуть ручку стоп-крана обратно в поезд! Иначе ребята не смогут поехать в «Артек»! Да и в парк к нам никто не приедет. А для этого надо сначала надо найти подходящий рычаг-выключатель для дым-машины.`,
        nextStep: 'Q17',
      },
      Q17: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q17',
        text: 'Без паники! Я знаю что надо делать. Здесь недалеко есть парк «Патриот». Его начальник Степанов — мой давний друг. У них там много всякой военной техники и, наверняка, найдется подходящий рычаг. Давайте пойдем туда, а по пути составим план поиска Софии.',
        type: StepType.question,
        callToAction: 'Идет?',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Идет! Давайте уже пойдем скорее!',
            nextStep: EXIT_ID,
          },
          {
            id: 'b2',
            text: 'Как всегда отличный план, Сан Саныч!',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.TEACHER,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
