import { AppThunk, IProtocol, TestType } from '../../types';
import { mergeProtocols, parseTests, parseTestTvoiKod } from '../../utils/testsParser';
import { SceneId } from '../../types/scenario';
import { Optional } from '../user';
import ChoicesApi from '../../api/choices';
import { TEST_PROBLEM_KEY } from '../../../common/shared/constants/constants';
import { TestID } from '../../types';
import { ApiChoice } from '../../types/api/user';
import testsSlice from './index';
import { TestItem } from '../../hooks/useTestsResults';
import { setVariablesAsync } from '../user/userActions';
import { useAppDispatch } from '../../hooks/useAppDispatch';

/**
 * TODO: !!!!!! отрефакторить этот файл и поменять id сцен тестов на новые
 */

const { setTestResults, addTestResult, setActiveTestResult } = testsSlice.actions;

export const setActiveTestResultAsync =
  (data?: TestItem): AppThunk =>
  async (dispatch) => {
    dispatch(setActiveTestResult(data));
  };

const checkTvoiKodAsync = (): AppThunk => async (dispatch, getState) => {
  const tests = getState().tests.testResults;
  const scenarios = getState().scenario.scenarios;
  let completed = 0;
  const ids = [SceneId.scenario_1_3, SceneId.scenario_1_8, SceneId.scenario_2_9];
  let protocol: Optional<IProtocol>;

  if (tests[TestID[TestType.personality]]) {
    return;
  }

  for (let i = 0; i < ids.length; i++) {
    const definedScenario = scenarios[ids[i]];
    if (definedScenario) {
      ++completed;
      const part = parseTestTvoiKod(definedScenario);
      protocol = protocol ? mergeProtocols(protocol, part) : part;
    }
  }

  if (completed === 3 && protocol) {
    const response = await ChoicesApi.postChoice(TestID[TestType.personality], protocol);
    dispatch(addTestResult({ [TestID[TestType.personality]]: response.data }));
  }
};

const checkTvoiVyborAsync = (): AppThunk => async (dispatch, getState) => {
  const tests = getState().tests.testResults;
  if (tests[TestID[TestType.interests]]) {
    return;
  }
  const scenarios = getState().scenario.scenarios;
  let completed = 0;
  const ids = [SceneId.scenario_1_18, SceneId.scenario_1_19, SceneId.scenario_3_1];
  let protocol: Optional<IProtocol>;

  for (let i = 0; i < ids.length; i++) {
    const h = scenarios[ids[i]];
    if (h) {
      ++completed;
      const part = parseTests(h, TestType.interests, 'json');
      protocol = protocol ? mergeProtocols(protocol, part) : part;
    }
  }
  if (completed === 3 && protocol) {
    const res = await ChoicesApi.postChoice(TestID[TestType.interests], protocol);
    dispatch(addTestResult({ [TestID[TestType.interests]]: res.data }));
  }
};

const checkTvoiVectorAsync = (): AppThunk => async (dispatch, getState) => {
  const tests = getState().tests.testResults;
  if (tests[TestID[TestType.thinking]]) {
    return;
  }
  let completed = 0;
  const scenarios = getState().scenario.scenarios;
  const ids = [SceneId.scenario_1_8_5, SceneId.scenario_1_19, SceneId.scenario_3_3];
  let protocol: Optional<IProtocol>;

  for (let i = 0; i < ids.length; i++) {
    const h = scenarios[ids[i]];
    if (h && h.findIndex((i) => i.id.indexOf('TEST') !== -1)) {
      ++completed;
      const part = parseTests(h, TestType.thinking, 'json');
      protocol = protocol ? mergeProtocols(protocol, part) : part;
    }
  }

  if (completed === 3 && protocol) {
    const res = await ChoicesApi.postChoice(TestID[TestType.thinking], protocol);
    dispatch(addTestResult({ [TestID[TestType.thinking]]: res.data }));
  }
};

const checkEruditionTechAsync = (): AppThunk => async (dispatch, getState) => {
  const tests = getState().tests.testResults;
  if (tests[TestID[TestType.erudition_tech]]) {
    return;
  }
  const scenarios = getState().scenario.scenarios;
  const h = scenarios[SceneId.scenario_3_5];

  if (h) {
    const protocol = parseTests(h, TestType.erudition_tech, 'json', 'TEST/TVOI_KRUGOZOR_T2_STEP');
    if (protocol) {
      const res = await ChoicesApi.postChoice(TestID[TestType.erudition_tech], protocol);
      dispatch(addTestResult({ [TestID[TestType.erudition_tech]]: res.data }));
    }
  }
};

const checkEruditionHumAsync = (): AppThunk => async (dispatch, getState) => {
  const tests = getState().tests.testResults;
  if (tests[TestID[TestType.erudition_hum]]) {
    return;
  }
  const scenarios = getState().scenario.scenarios;
  const h = scenarios[SceneId.scenario_3_5];

  if (h) {
    const protocol = parseTests(h, TestType.erudition_hum, 'json', 'TEST/TVOI_KRUGOZOR_G2_STEP');
    if (protocol) {
      const res = await ChoicesApi.postChoice(TestID[TestType.erudition_hum], protocol);
      dispatch(addTestResult({ [TestID[TestType.erudition_hum]]: res.data }));
    }
  }
};

const checkLogicTestAsync = (): AppThunk => async (dispatch, getState) => {
  const tests = getState().tests.testResults;
  const scenarios = getState().scenario.scenarios;
  const definedScenario = scenarios[SceneId.scenario_3_6];

  if (tests[TestID[TestType.logic]]) {
    return;
  }

  if (definedScenario) {
    const protocol = parseTests(definedScenario, TestType.logic, 'js');
    if (protocol) {
      const response = await ChoicesApi.postChoice(TestID[TestType.logic], protocol);
      dispatch(addTestResult({ [TestID[TestType.logic]]: response.data }));
    }
  }
};

const checkCreativityTestAsync = (): AppThunk => async (dispatch, getState) => {
  const tests = getState().tests.testResults;
  const scenarios = getState().scenario.scenarios;
  let completed = 0;
  const ids = [SceneId.scenario_2_3, SceneId.scenario_2_4];
  let protocol: Optional<IProtocol>;

  if (tests[TestID[TestType.creativity]]) {
    return;
  }

  for (let i = 0; i < ids.length; i++) {
    const definedScenario = scenarios[ids[i]];
    if (definedScenario) {
      ++completed;
      const part = parseTests(definedScenario, TestType.creativity, 'js', 'CREATIVE');
      protocol = protocol ? mergeProtocols(protocol, part) : part;
    }
  }

  if (completed === 2 && protocol) {
    const response = await ChoicesApi.postChoice(TestID[TestType.creativity], protocol);
    dispatch(addTestResult({ [TestID[TestType.creativity]]: response.data }));
  }
};

function normalizeTestResults(tests: ApiChoice[]) {
  const res: Record<string, ApiChoice> = {};
  for (let i = 0; i < tests.length; i++) {
    res[tests[i].data.test] = tests[i];
  }
  return res;
}

let isFetching = false;

export const checkTestsAsync = (): AppThunk => async (dispatch) => {
  // чтобы запросы не шли два раза
  if (isFetching) return;

  isFetching = true;

  const choices = await ChoicesApi.getChoices();
  const data = normalizeTestResults(choices.data);

  dispatch(setTestResults(data));
  dispatch(checkTvoiKodAsync());
  dispatch(checkTvoiVyborAsync());
  dispatch(checkTvoiVectorAsync());
  dispatch(checkEruditionTechAsync());
  dispatch(checkEruditionHumAsync());
  dispatch(checkLogicTestAsync());

  const timeout = setTimeout(() => {
    isFetching = false;
    clearTimeout(timeout);
  }, 2000);
};
