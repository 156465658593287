export enum FinishStepType {
  'stage1' = 'stage1',
  'stage2' = 'stage2',
  'finish' = 'finish',
  'reg_finished' = 'reg_finished',
  'competition_finished' = 'competition_finished',
  'top600' = 'top600',
  'reserve' = 'reserve',
  'fail' = 'fail',
  'end_main_game' = 'end_main_game',
  'moreThreshold' = 'afterThreshold', // больше порога
  'lessThreshold' = 'beforeThreshold', // меньше порога
}

export interface FinishPageProps {
  onNextClick?: () => void;
  onProfileClick?: () => void;
  finishStep: FinishStepType;
  nextStageAvailable?: boolean;
}
