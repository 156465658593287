/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import { VICT } from './VIKT';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg2_mguQuiz_1_1: Scenario = {
  scenarioId: SceneId.stg2_mguQuiz_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    timeoutMessage: 'К сожалению, время для ответа истекло 🙁',
    title: 'МГУ',
    scene_id: 'stg2_mguQuiz_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.TEACHER,
        text: `Ребята, всем здравствуйте!

        Мы начинаем викторину для поддержки проектов учеников.
        
        Она будет состоять из вопросов о достопримечательностях парка «Большая перемена».
        
        Желаю всем удачи!`,
        id: 'Q1',
        type: StepType.message,
        nextStep: 'VICT2',
      },

      ...VICT,

      Q2: {
        text: `Поздравляю вас с успешным завершением викторины!`,
        id: 'Q2',
        person: Interlocutors.TEACHER,
        type: StepType.question,
        nextStep: 'Q3',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо!',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        text: `Ребята, это были последние вопросы. Вы большие молодцы, что дошли до конца викторины! Ура!

        Приглашаю теперь всех подойти за призами!`,
        id: 'Q3',
        person: Interlocutors.TEACHER,
        type: StepType.question,
        nextStep: 'Q4',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Вжух, пойдём скорее 😁',
            nextStep: 'Q4',
          },
        ],
      },

      Q4: {
        id: 'Q4',
        person: Interlocutors.TEACHER,
        text: `{{name}}, ты просто превосходно ответил на все вопросы! Видна тщательная подготовка к викторине.

        Вот твой приз - микросхема для лабораторного компьютера. Хрупкая и ценная вещь! 
        
        Удачи с твоим исследовательским проектом!`,
        type: StepType.question,
        nextStep: EXIT_ID,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо, Светлана Васильевна! Было очень интересно поучаствовать!',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
