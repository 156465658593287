/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg3_space_1_1_0_i: Scenario = {
  scenarioId: SceneId.stg3_space_1_1_0_i,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.CAT,
    title: 'Космодром',
    text: `Я часто смотрел на планеты через свой квантовый телескоп. И ваша, Земля, показалась мне такой красивой, что я смотрел на неё каждый день и мечтал попасть к вам на экскурсию. 

    Потом однажды я воспользовался нашим лучом телепортации, чтобы перенестись на Землю. Прицелился лучом в большую прямоугольную площадку, покрытую травой, и так попал на Землю.`,
    buttons: ['Начать'],
  },
};
