/* eslint-disable max-len */
import {
  Conditions,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  Step,
  StepType,
  SystemStepType,
} from '../../../scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';
import { LOGIC_TEST_PART1 } from '../../tests/logicTestPart1';
import { TEST_LOGIC_MAIN_2023 } from '../../tests/test_logic_main_2023';

const EXIT_ID = 'EXIT';
const TO_MAP_ID = 'TO_MAP_ID';

const person = Interlocutors.ENTREPRENEUR;

export const scenario_2_11_0: Scenario = {
  scenarioId: SceneId.scenario_2_11_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Новости парка',
    person: Interlocutors.SYSTEM,
    text: `Вы находитесь рядом с большими стеклянными высотками. У входа в главный небоскреб стоит Сан Саныч. Он начинает с вами разговор...`,
    buttons: ['Начать'],
  },
};

const ALT_EXIT = 'ALT_EXIT';

const ALT: Record<string, Step> = {
  ...splitStep({
    person: Interlocutors.SYSTEM,
    id: 'ALT1',
    text: `Вы зашли внутрь главного небоскреба и вызвали лифт. Вам улыбнулась удача: двери ближайшего лифта тут же открылись — не пришлось долго ждать. Вы заходите в пустой лифт, нажимаете на 90 этаж, двери начинают закрываться, как вдруг...

    Вы видите, как к лифту еле идет неизвестный человек… У него падают папки бумаг, незнакомец их собирает и что-то кричит. Чтобы услышать, что он кричит, нужно подождать 1 минуту, пока он соберет все папки и подойдет ближе. Вы согласны задержаться на 1 минуту?`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Если мы задержимся, то можем не успеть на викторину... Мы не можем ждать, поехали!',
        nextStep: ALT_EXIT,
      },
      {
        id: 'b2',
        text: 'Я [[согласен/согласна]] подождать. (Игровое время будет заморожено на 1 минуту)',
        nextStep: 'ALT2',
      },
    ],
  }),
  ALT2: {
    person,
    id: 'ALT2',
    text: 'Кажется, он кричит, чтобы мы придержали лифт… Ему тоже наверх, на 66 этаж.',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Нет, Сан Саныч, надо ехать! Времени у нас впритык. (Игровое время не будет заморожено)',
        nextStep: ALT_EXIT,
      },
      {
        id: 'b2',
        text: 'Давайте лучше попросим охранника вызвать другой лифт. У нас все-таки очень мало времени... (Игровое время будет заморожено на 2 минуты)',
        nextStep: 'ALT3',
      },
      {
        id: 'b3',
        text: 'Думаю, стоит подождать! (Игровое время будет заморожено на 5 минут)',
        nextStep: 'ALT3',
      },
    ],
  },
  ALT3: {
    person: Interlocutors.SYSTEM,
    id: 'ALT3',
    type: StepType.message,
    text: `Незнакомец благодарит вас за помощь.`,
    nextStep: ALT_EXIT,
  },
};

const LOGIC_TEST_EXAMPLE: Record<string, Step> = {
  ...splitStep({
    person,
    id: 'LOGIC_TEST_EXAMPLE_1',
    type: StepType.message,
    text: `Итак, время пошло! Три гнома: Пили, Ели и Спали, — нашли в пещере алмаз, топаз и медный таз (каждый нашел что-то одно). У Ели капюшон красный, а борода длиннее, чем у Пили. У того, кто нашел таз, самая длинная борода, а капюшон синий. Гном с самой короткой бородой нашел алмаз. Нужно определить, кто что нашел?`,
    nextStep: 'LOGIC_TEST_EXAMPLE_2',
  }),
  LOGIC_TEST_EXAMPLE_2: {
    person,
    nextStep: 'LOGIC_TEST_EXAMPLE_3',
    id: 'LOGIC_TEST_EXAMPLE_2',
    type: StepType.system,
    _meta: {
      type: SystemStepType.limitedTimeGroup,
      time: 3 * 60,
      timeoutStep: 'LOGIC_TEST_EXAMPLE_7',
      exitStep: 'LOGIC_TEST_EXAMPLE_6',
    },
  },
  LOGIC_TEST_EXAMPLE_3: {
    person,
    id: 'LOGIC_TEST_EXAMPLE_3',
    variable: 'LOGIC_TEST_EXAMPLE_3',
    type: StepType.text_question,
    text: `Напиши, что нашел Пили?`,
    nextStep: 'LOGIC_TEST_EXAMPLE_4',
  },
  LOGIC_TEST_EXAMPLE_4: {
    person,
    id: 'LOGIC_TEST_EXAMPLE_4',
    variable: 'LOGIC_TEST_EXAMPLE_4',
    type: StepType.text_question,
    text: `Напиши, что нашел Ели?`,
    nextStep: 'LOGIC_TEST_EXAMPLE_5',
  },
  LOGIC_TEST_EXAMPLE_5: {
    person,
    id: 'LOGIC_TEST_EXAMPLE_5',
    variable: 'LOGIC_TEST_EXAMPLE_5',
    type: StepType.text_question,
    text: `Напиши, что нашел Спали?`,
    nextStep: 'LOGIC_TEST_EXAMPLE_6',
  },
  LOGIC_TEST_EXAMPLE_6: {
    person,
    id: 'LOGIC_TEST_EXAMPLE_6',
    text: `Ну вот и размялись немного! 😊`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Ага',
        nextStep: 'Q10',
      },
    ],
  },
  LOGIC_TEST_EXAMPLE_7: {
    person,
    id: 'LOGIC_TEST_EXAMPLE_7',
    type: StepType.message,
    text: `Все, время вышло.`,
    nextStep: 'LOGIC_TEST_EXAMPLE_8',
  },
  LOGIC_TEST_EXAMPLE_8: {
    person,
    id: 'LOGIC_TEST_EXAMPLE_8',
    text: `Ну ладно, хоть ты и не [[успел/успела]] ответить, суть вопросов [[понял/поняла]].`,
    nextStep: 'LOGIC_TEST_EXAMPLE_6',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Ага',
        nextStep: 'Q10',
      },
    ],
  },
};

export const scenario_2_11: Scenario = {
  scenarioId: SceneId.scenario_2_11,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Москва-Сити',
    scene_id: 'scenario_2_11',
    startId: 'Q1',
    steps: {
      Q1: {
        type: StepType.message,
        id: 'Q1',
        text: 'Привет, {{NAME}}! Быстро, однако, Вжух тебе передал, что у меня для тебя есть новости! Ах да, ты должно быть [[впечатлен/впечатлена]] видом этих небоскребов? Ведь в этой части «Большой перемены» ты еще не [[бывал/бывала]].',
        person,
        nextStep: 'Q2/0',
      },
      ...splitStep({
        person,
        id: 'Q2',
        text: `Перед тобой полноразмерная копия Москва-Сити. Это развивающийся деловой район Москвы. Он состоит из башен, всего их 20 и шесть еще строятся. Высота самой высокой из них составляет 374 метра, это целых 97 этажей! С ума сойти, как высоко! 😍

Мы как раз стоим у входа в нее, я перевожу сюда свой офис, чтобы быть в самой гуще предпринимательских событий.

Но это еще не все! Москва-Сити — это пространство, в котором можно заниматься бизнесом, жить и интересно проводить время. Например, здесь есть музей. Он расположен на 56 этаже и оттуда открывается совершенно потрясающий вид! А еще это такой музей, где экспонаты можно и даже нужно трогать руками! 😊`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, я знаю про Москва-Сити',
            nextStep: 'Q3',
          },
          {
            id: 'b2',
            text: 'Это очень интересно, но зачем Вы меня искали?',
            nextStep: 'Q4',
          },
        ],
      }),
      Q3: {
        id: 'Q3',
        text: 'Вот здорово! Ты вообще молодец, много про что знаешь! 😊',
        person,
        type: StepType.message,
        nextStep: 'Q5',
      },
      Q4: {
        person,
        id: 'Q4',
        type: StepType.message,
        text: `Ой, что-то я увлекся...`,
        nextStep: 'Q5',
      },
      Q5: {
        person,
        id: 'Q5',
        text: 'У меня для тебя есть важные новости! Ты, должно быть, уже [[слышал/слышала]], что на Космодроме появилась новая ракета?',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, припоминаю, что-то такое [[слышал/слышала]]...',
            nextStep: 'Q6',
          },
        ],
      },
      ...splitStep({
        id: 'Q6',
        person,
        text: `Так вот! Ракета просто восхитительная! 😍 Как только я увидел ее, тут же влюбился! Но увы, она не наша... Пока не наша! Ее привезли бизнесмены и хотят разыграть в викторине. 
        
        Нашему парку НЕОБХОДИМА ракета! Как же Космодром и без ракеты, понимаешь? ☹️
        
        Но есть одно маааааленькое условие… В викторине могут участвовать только школьники 5-7 класса. А я «слегка» не подхожу… Поэтому без твоей помощи никак не обойтись!`,
        type: StepType.question,
        maxAnswers: 1,
        callToAction: 'Ты в деле?',
        buttons: [
          {
            id: 'b1',
            text: 'Разумеется, в деле! Нельзя упустить такой шанс!',
            nextStep: 'Q7',
          },
        ],
      }),
      Q7: {
        person,
        id: 'Q7',
        text: `Тогда поспешим! Викторина начнется уже через 15 минут на 90-м этаже! Хоть лифты здесь и скоростные, но все равно времени впритык! Добираться туда примерно 9 минут. Должны успеть!`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, давайте поспешим!',
            nextStep: 'ALT1/0',
          },
        ],
      },
      ...ALT,
      [ALT_EXIT]: {
        person: Interlocutors.SYSTEM,
        id: ALT_EXIT,
        type: StepType.message,
        text: 'Вы стремительно поднимаетесь на 90 этаж.',
        nextStep: 'Q8/0',
      },
      ...splitStep({
        id: 'Q8',
        person,
        text: `Слушай, {{NAME}}! Викторина вот-вот начнется, поэтому предлагаю тебе немного размять мозги.

Я знаю, какого рода вопросы там будут, поэтому давай, чтобы ты [[был/была]] [[готов/готова]], решим одну из пробных задачек сейчас. Как раз успеем, пока поднимаемся.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо, давайте',
            nextStep: 'Q9/0',
          },
        ],
      }),
      ...splitStep({
        person,
        id: 'Q9',
        text: `У тебя будет 3 минуты на решение. Будь [[внимателен/внимательна]], сначала я расскажу задачку, а потом буду последовательно спрашивать ответы. Не переживай, я еще подскажу, что когда писать.

Кстати, задачка может быть сложная для решения в уме, поэтому доставай ручку и бумагу для расчетов.`,
        type: StepType.question,
        maxAnswers: 1,
        callToAction: 'Начнем?',
        buttons: [
          {
            id: 'b1',
            text: 'Да, я [[готов/готова]]!',
            nextStep: 'LOGIC_TEST_EXAMPLE_1/0',
          },
        ],
      }),

      ...LOGIC_TEST_EXAMPLE,

      Q10: {
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q11/0',
        id: 'Q10',
        text: 'Двери лифта открываются на 90 этаже. Вы спешите на викторину.',
      },
      ...splitStep({
        person,
        id: 'Q11',
        text: `Ох! Чуть не опоздали! Хорошо, что у организаторов викторины произошла техническая заминка и даже осталось время, чтобы отдышаться.
          
          Так, уже начинают. Посмотрим, что надо делать…
          
          Ну что, [[готов/готова]] побороться за ракету для парка?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, давайте начнем!',
            nextStep: 'Q11_1',
          },
        ],
      }),
      Q11_1: {
        person,
        type: StepType.message,
        nextStep: 'Q11_2',
        id: 'Q11_1',
        text: 'Смотри, условия достаточно простые. Тебе нужно решить 10 логических задачек за 20 минут. Какие-то из них будут проще, какие-то посложнее и, возможно, даже понадобится ручка и лист бумаги чтобы что-то вычислять.',
      },
      Q11_2: {
        person,
        type: StepType.question,
        id: 'Q11_2',
        text: 'Важный момент: принять участие в этой викторине можно только один раз. Повторно вернуться к ней будет нельзя. А это очень важно, понимаешь? Поучаствовать в викторине нужно обязательно!',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Начать тест',
            nextStep: 'TEST_LOGIC_MAIN_START',
          },
        ],
      },

      // тут был тест logicTestPart1

      ...TEST_LOGIC_MAIN_2023, // КОНТРОЛЬНЫЙ ТЕСТ НА ЛОГИКУ

      Q12: {
        person: Interlocutors.SYSTEM,
        id: 'Q12',
        type: StepType.message,
        text: `Спасибо! Викторина завершена. О результатах вам скоро сообщат. Пожалуйста, ожидайте.`,
        nextStep: 'Q12_1',
      },
      Q12_1: {
        person,
        id: 'Q12_1',
        _meta: {},
        type: StepType.system,
        condition: {
          type: Conditions.IS_LOGIC_PART1_HAS_ANSWERS,
          variants: ['Q16/0', 'Q13/0'],
        },
      },
      ...splitStep({
        person,
        id: 'Q13',
        text: `Не расстраивайся! Хотя ты ничего не [[ответил/ответила]], организаторы викторины решили дать нам еще один шанс и задать вспомогательный вопрос. 

Мы сможем победить в викторине, если ты правильно ответишь на него. 😊`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо',
            nextStep: 'Q14',
          },
        ],
      }),
      Q14: {
        person,
        id: 'Q14',
        text: 'За какие заслуги мост на остров Русский был занесен в Книгу рекордов Гиннеса?',
        type: StepType.question,
        callToAction: 'Выбери ответ',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Самый длинный в мире вантовый мост',
            nextStep: 'Q15/0',
          },
          {
            id: 'b2',
            text: 'Самый красивый в мире вантовый мост',
            nextStep: 'Q14-1',
          },
          {
            id: 'b3',
            text: 'Самый старый в мире вантовый мост',
            nextStep: 'Q14-2',
          },
        ],
      },
      'Q14-1': {
        person,
        id: 'Q14-1',
        callToAction: 'Выбери ответ',
        text: 'Нет, этот ответ неверный. Подумай еще.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Самый длинный в мире вантовый мост',
            nextStep: 'Q15/0',
          },
          {
            id: 'b3',
            text: 'Самый старый в мире вантовый мост',
            nextStep: 'Q14-2',
          },
        ],
      },
      'Q14-2': {
        person,
        id: 'Q14-2',
        callToAction: 'Выбери ответ',
        text: 'Точно не самый старый, я уверен.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Самый длинный в мире вантовый мост',
            nextStep: 'Q15/0',
          },
          {
            id: 'b2',
            text: 'Самый красивый в мире вантовый мост',
            nextStep: 'Q14-1',
          },
        ],
      },
      ...splitStep({
        person,
        id: 'Q15',
        text: `Совершенно верно! 😍

Ты с легкостью [[справился/справилась]] с дополнительным вопросом! Наш ответ принят.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Круто!',
            nextStep: 'Q16/0',
          },
        ],
      }),
      ...splitStep({
        person,
        id: 'Q16',
        text: `
        Думаю, что все будет отлично! Мое предпринимательское чутье подсказывает, что ракета у нас в руках! 😍
        Кстати, хочешь посмотреть место под мой новый офис, пока ждем результатов? Это как раз на 45 этаже… Правда, там еще мало что обустроено, я ничего не успеваю из-за этого оледенения!`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, я с радостью посмотрю!',
            nextStep: 'Q17',
          },
        ],
      }),
      Q17: {
        person: Interlocutors.SYSTEM,
        id: 'Q17',
        type: StepType.message,
        text: `Вы спускаетесь на лифте на 45 этажей ниже в новый офис Сан Саныча.`,
        nextStep: 'Q18/0',
      },
      ...splitStep({
        person,
        id: 'Q18',
        text: `Люблю этот вид! А еще на этом этаже офисы всех самых важных компаний. Так я смогу намного быстрее договариваться с ними о сотрудничестве по благоустройству «Большой перемены». 😊

С твоим появлением парк уже начал здорово преображаться! Одна победа в викторине чего стоит! Уверен, что ты возьмешь главный приз.

Но я, как настоящий предприниматель, считаю, что нельзя останавливаться на достигнутом. Поэтому я и перебираюсь сюда — в сердце предпринимательства «Большой перемены». 

У меня грандиозные планы: хочу открыть много новых достопримечательностей. Для этого нужно создавать новые партнерские связи.

Да вот только с этим переездом беда! Я один не успеваю навести порядок в кабинете. Договорился о встрече с потенциальными партнерами здесь через час. Они могут внести значимый финансовый вклад в развитие «Большой перемены». В общем, из-за бардака встреча под угрозой! 🙁`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Эта встреча действительно настолько важна?',
            nextStep: 'Q18_1',
          },
          {
            id: 'b2',
            text: 'Здорово, что парк с моим появлением начал преображаться! Спасибо за похвалу, я [[побежал/побежала]] помогать Петрову',
            nextStep: 'Q20',
          },
        ],
      }),
      Q18_1: {
        person,
        id: 'Q18_1',
        text: 'Эти партнеры могут нам очень здорово помочь.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ну да, кажется, это важно… Я помогу! (Игровое время будет заморожено на 1 час)',
            nextStep: 'Q19',
          },
          {
            id: 'b2',
            text: 'Понимаю, но я очень спешу собрать все детали для тепловой пушки! Давайте, я позову Вжуха Вам на помощь! (Игровое время будет заморожено на 5 минут)',
            nextStep: 'Q20',
          },
          {
            id: 'b3',
            text: 'Очень сочувствую Вам, Сан Саныч! Но я не могу помочь, я очень спешу. (Игровое время не будет заморожено)',
            nextStep: 'Q20',
          },
        ],
      },
      Q19: {
        person: Interlocutors.SYSTEM,
        id: 'Q19',
        type: StepType.message,
        text: `Вы начинаете разбирать вещи и обнаруживаете среди них что-то поблескивающее. Приглядываетесь и видите вторую деталь для тепловой пушки — зеркало-отражатель. Вы берете зеркало в руки...`,
        nextStep: 'Q21/0',
      },
      Q20: {
        person: Interlocutors.SYSTEM,
        id: 'Q20',
        type: StepType.message,
        text: `Вы уже собираетесь уходить, как вдруг замечаете среди неразобранных вещей что-то поблескивающее. Подходите ближе и видите вторую деталь для тепловой пушки — зеркало-отражатель. Вы берете зеркало в руки...`,
        nextStep: 'Q21/0',
      },
      ...splitStep({
        person,
        id: 'Q21',
        type: StepType.message,
        text: `Что там у тебя? О, это же зеркало для тепловой пушки! Ума не приложу, как оно сюда попало! Вам с Петровым оно явно нужнее. 

Скорее клади его в свои вещи и беги искать остальные детали! Надеюсь, вы с Петровым успеете собрать вашу тепловую пушку, пока все здесь окончательно не заледенело!

Ах да! Не забудь сообщить мне о результатах викторины, когда их объявят.`,
        nextStep: EXIT_ID,
      }),
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },

      TEST_EXIT: {
        person,
        id: 'TEST_EXIT',
        type: StepType.message,
        text: `Эх… Жаль, конечно, что ты [[ушел/ушла]] с викторины, не стоило этого делать... 🙁`,
        nextStep: 'Q13/0',
      },
    },
  },
};

export const scenario_2_11_4: Scenario = {
  scenarioId: SceneId.scenario_2_11_4,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: 'Результаты викторины',
    person: Interlocutors.SYSTEM,
    text: `Поздравляем! Вы стали победителем викторины! Ракета ваша. Она будет ждать на Космодроме!`,
    buttons: ['Закрыть'],
  },
};

export const scenario_2_11_5: Scenario = {
  scenarioId: SceneId.scenario_2_11_5,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Новости парка',
    person: Interlocutors.SYSTEM,
    text: `Вы нашли две детали от тепловой пушки. Осталось еще две. Внимательно изучите карту. Кажется, Вжух оставил на ней подсказки...`,
    buttons: ['Закрыть'],
  },
};
