/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT';
const TO_MAP_ID = 'TO_MAP_ID';

const person = Interlocutors.CAT;

export const scenario_egg1_0: Scenario = {
  scenarioId: SceneId.scenario_egg1_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Новости парка',
    person: Interlocutors.SYSTEM,
    text: `Вы находитесь рядом с поездом, который отвезет победителей игры в «Артек». Но только если «Большая перемена» будет разморожена…

Вы осматриваете поезд и сквозь полузамерзшее окно видите внутри одного из вагонов недостающую деталь — нагревательный элемент.`,
    buttons: ['Начать'],
  },
};

export const scenario_egg1: Scenario = {
  scenarioId: SceneId.scenario_egg1,
  type: ScenarioType.chat,
  data: {
    title: 'Поезд в «Артек»',
    scene_id: 'scenario_egg1',
    startId: 'Q0/0',
    steps: {
      ...splitStep({
        id: 'Q0',
        person,
        text: `О, {{NAME}}, и ты здесь?! Ты тоже видишь это? Там внутри, кажется, нагревательный элемент! 🙀

Скорее открывай дверь!`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, я пытаюсь, но ничего не выходит!',
            nextStep: 'Q1/0',
          },
        ],
      }),
      ...splitStep({
        id: 'Q1',
        person,
        text: `Смотри, тут есть табличка с текстом, возможно, там инструкция, как открыть дверь.

Вот только шрифт слишком мелкий, ничего не возможно разглядеть. Что же делать? Мы не можем упустить деталь… 😿`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'О, я же могу воспользоваться ЛУПОЙ и прочесть увеличенный текст!',
            nextStep: 'Q2',
          },
          {
            id: 'b2',
            text: 'Хм, что-то я не [[уверен/уверена]], что в вагоне нужная мне деталь. Пойду-ка поищу где-нибудь еще...',
            nextStep: TO_MAP_ID,
          },
        ],
      }),
      Q2: {
        person: Interlocutors.SYSTEM,
        id: 'Q2',
        text: `С помощью лупы вы смогли рассмотреть текст таблички:
Замок поезда «Большая перемена» — «Артек» открывается с помощью цифрового кода. Внутрь попасть смогут только самые смекалистые ребята. Чтобы узнать код от замка, надо решить задачу, ответ на которую и есть код.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Думаю, я с легкостью справлюсь с задачей!',
            nextStep: 'Q3/0',
          },
          {
            id: 'b2',
            text: 'Мне в любом случае нужна деталь внутри вагона, придется решать…',
            nextStep: 'Q3/0',
          },
        ],
      },
      ...splitStep({
        id: 'Q3',
        person,
        text: `Смотри, здесь задачка! 

Три курицы за три дня несут три яйца. Сколько яиц снесут 12 таких же кур за 12 дней?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: '144',
            nextStep: 'Q3-1',
          },
          {
            id: 'b2',
            text: '48',
            nextStep: 'Q4',
          },
          {
            id: 'b3',
            text: '36',
            nextStep: 'Q3-2',
          },
        ],
      }),
      'Q3-1': {
        person,
        id: 'Q3-1',
        text: 'Кажется, это неверный ответ... Замок все еще закрыт.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: '48',
            nextStep: 'Q4',
          },
          {
            id: 'b2',
            text: '36',
            nextStep: 'Q3-2',
          },
        ],
      },
      'Q3-2': {
        person,
        id: 'Q3-2',
        text: 'Нет, замок не открывается..',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: '144',
            nextStep: 'Q3-1',
          },
          {
            id: 'b2',
            text: '48',
            nextStep: 'Q4',
          },
        ],
      },
      Q4: {
        person,
        id: 'Q4',
        type: StepType.message,
        text: `Ура! Дверь открылась! Значит, ответ был верным.`,
        nextStep: 'Q5',
      },
      Q5: {
        person: Interlocutors.SYSTEM,
        id: 'Q5',
        type: StepType.message,
        text: `Вы заходите в вагон поезда в «Артек» и находите недостающую деталь — нагревательный элемент.`,
        nextStep: EXIT_ID,
      },
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
