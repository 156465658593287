import { useCallback, useState } from 'react';

export const useBoolState = (
  defaultValue = false,
): [boolean, (value: boolean) => void, () => void, () => void, () => void] => {
  const [state, setState] = useState(defaultValue);

  const setTrue = useCallback(() => setState(true), []);
  const setFalse = useCallback(() => setState(false), []);
  const toggle = useCallback(() => setState(!state), [state]);

  return [state, setState, setTrue, setFalse, toggle];
};
export default useBoolState;
