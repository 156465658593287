import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { selectScenarios } from '../../redux/user/userGetters';
import { notFirstStageCompleted } from '../scenarioHelpers/stageChecks';
import { stage1RequiredScenarios } from './requiredScenarios';

export function useIsFirstStageCompleted() {
  const scenarioState = useSelector(selectScenarios);

  return useMemo(() => {
    if (!scenarioState) {
      return false;
    }
    for (let i = 0; i < stage1RequiredScenarios.length; i++) {
      for (let j = 0; j < stage1RequiredScenarios[i].length; j++) {
        if (!scenarioState.scenarios[stage1RequiredScenarios[i][j]]) {
          return false;
        }
      }
    }
    // return !notFirstStageCompleted(scenarioState); // из-за переделки сценария в проверке этой сцены нет необходимости
    return true;
  }, [scenarioState]);
}
