import * as checks from './stageChecks';
import { ID, to } from './ID';
import { PointsOfInterest } from '../../types';
import { SceneId } from '../../types/scenario';
import { createNavigateToPointLink, toArray } from './utils';
import { MapPinType, PointsClickHandlers, RunModalScenario, ScenarioHelperResponse } from './types';
import { skipScenario } from '../../redux/scenario/scenarioActions';
import { ScenarioState } from '../../redux/scenario';
import store from '../../redux';
import { checkTestsAsync } from '../../redux/tests/testsActions';

export function stage2(
  scenarioState: ScenarioState,
  runScenario: (id: SceneId, onSubmit?: (btn: string) => void) => void,
  runModalScenario: RunModalScenario,
  dispatch: (action: (...args: any[]) => void) => void,
): ScenarioHelperResponse {
  /**
   *    ИНТРО
   */

  if (!scenarioState.scenarios?.[to[ID.stg2intro]]) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.stg2introPopup], runOnSubmit: to[ID.stg2intro] },
    };
  }

  /**
   *    Иннополис    1 ЧАСТЬ
   */

  if (
    scenarioState.scenarios?.[to[ID.stg2intro]] &&
    checks.notstg2InnopolicePopupCompleted(scenarioState)
  ) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.stg2InnopolicePopup] },
    };
  }

  if (!scenarioState.scenarios?.[to[ID.stg2Innopolice]]) {
    const stg2InnopoliceNavigatePoint = createNavigateToPointLink(
      'Иннополис',
      PointsOfInterest.innopolice,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.innopolice]: () =>
          runModalScenario(
            to[ID.stg2Innopolice],
            to[ID.stg2Innopolice],
            to[ID.stg2Innopolice],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${stg2InnopoliceNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   *    МГУ       2 ЧАСТЬ
   */

  if (checks.notstg2MguPopupCompleted(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.stg2MguPopup] },
    };
  }

  if (
    scenarioState.scenarios?.[to[ID.stg2Innopolice]] &&
    !scenarioState.scenarios?.[to[ID.stg2Mgu]]
  ) {
    const stg2MguNavigatePoint = createNavigateToPointLink('МГУ', PointsOfInterest.mgu);

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.mgu]: () =>
          runModalScenario(
            to[ID.stg2MguIntro],
            to[ID.stg2Mgu],
            to[ID.stg2Mgu],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${stg2MguNavigatePoint}. Тебя там ждут.`,
    };
  }

  if (!scenarioState.scenarios?.[to[ID.stg2MguAfter]]) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.stg2MguAfter] },
    };
  }

  // БАЙКАЛ          3 ЧАСТЬ

  if (scenarioState.scenarios?.[to[ID.stg2Mgu]] && !scenarioState.scenarios?.[to[ID.stg2Lake]]) {
    const stg2LakeNavigatePoint = createNavigateToPointLink('Озеро Байкал', PointsOfInterest.lake);

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.lake]: () =>
          runModalScenario(
            to[ID.stg2LakeIntro],
            to[ID.stg2Lake],
            to[ID.stg2Lake],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди на ${stg2LakeNavigatePoint}. Тебя там ждут.`,
    };
  }

  // ЭРМИТАЖ          4 ЧАСТЬ

  if (
    scenarioState.scenarios?.[to[ID.stg2Lake]] &&
    !scenarioState.scenarios?.[to[ID.stg2Hermitage]]
  ) {
    const stg2HermitageNavigatePoint = createNavigateToPointLink(
      'Эрмитаж',
      PointsOfInterest.hermitage,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.hermitage]: () =>
          runModalScenario(
            to[ID.stg2HermitageIntro],
            to[ID.stg2Hermitage],
            to[ID.stg2Hermitage],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${stg2HermitageNavigatePoint}. Тебя там ждут.`,
    };
  }

  // Олимпийский парк          5 ЧАСТЬ

  if (
    scenarioState.scenarios?.[to[ID.stg2Hermitage]] &&
    !scenarioState.scenarios?.[to[ID.stg2OlympicPark]]
  ) {
    const stg2OlympicParkNavigatePoint = createNavigateToPointLink(
      'Олимпийский парк',
      PointsOfInterest.sochi,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.sochi]: () =>
          runModalScenario(
            to[ID.stg2OlympicParkIntro],
            to[ID.stg2OlympicPark],
            to[ID.stg2OlympicPark],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${stg2OlympicParkNavigatePoint}. Тебя там ждут.`,
    };
  }

  // Москва  Сити          6 ЧАСТЬ

  if (
    scenarioState.scenarios?.[to[ID.stg2OlympicPark]] &&
    !scenarioState.scenarios?.[to[ID.stg2MoscowCity]]
  ) {
    const oboutMoscowReturnNavigatePoint = createNavigateToPointLink(
      'Москва-сити',
      PointsOfInterest.moscowCity,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.moscowCity]: () =>
          runModalScenario(
            to[ID.stg2MoscowCityIntro],
            to[ID.stg2MoscowCity],
            to[ID.stg2MoscowCity],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${oboutMoscowReturnNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   *    МГУ          7 ЧАСТЬ
   */

  if (checks.notstg2MguQuizPopupCompleted(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.stg2MguQuizPopup] },
    };
  }

  if (scenarioState.scenarios?.[to[ID.stg2Mgu]] && !scenarioState.scenarios?.[to[ID.stg2MguQuiz]]) {
    const stg2MguNavigatePoint = createNavigateToPointLink('МГУ', PointsOfInterest.mgu);

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.mgu]: () =>
          runModalScenario(
            to[ID.stg2MguQuizIntro],
            to[ID.stg2MguQuiz],
            to[ID.stg2MguQuiz],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${stg2MguNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   *    ИННОПОЛИС ПЕТРОВ         7 ЧАСТЬ
   */

  if (checks.notstg2InnopolicePetrovPopupCompleted(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.stg2InnopolicePetrovPopup] },
    };
  }

  if (
    scenarioState.scenarios?.[to[ID.stg2MguQuiz]] &&
    !scenarioState.scenarios?.[to[ID.stg2InnopolicePetrov]]
  ) {
    const stg2MguNavigatePoint = createNavigateToPointLink(
      'Иннополис',
      PointsOfInterest.innopolice,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.innopolice]: () =>
          runModalScenario(
            to[ID.stg2InnopolicePetrovIntro],
            to[ID.stg2InnopolicePetrov],
            to[ID.stg2InnopolicePetrov],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${stg2MguNavigatePoint}. Тебя там ждут.`,
    };
  }

  // заглушка;

  /* return {
    pointsHandlers: [],
    autorunScenario: {
      id: to[ID.stop_1],
      unsaved: false,
    },
  }; */

  /**
   *    БАЙКАЛ      8 ЧАСТЬ
   */

  if (checks.notstg2stg2LakeOrderFromGrannyPopupPopupCompleted(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.stg2LakeOrderFromGrannyPopup] },
    };
  }

  if (
    scenarioState.scenarios?.[to[ID.stg2InnopolicePetrov]] &&
    !scenarioState.scenarios?.[to[ID.stg2LakeOrderFromGranny]]
  ) {
    const stg2LakeNavigatePoint = createNavigateToPointLink('Озеро Байкал', PointsOfInterest.lake);

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.lake]: () =>
          runModalScenario(
            to[ID.stg2LakeOrderFromGrannyIntro],
            to[ID.stg2LakeOrderFromGranny],
            to[ID.stg2LakeOrderFromGranny],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди на ${stg2LakeNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   *    Мамаев Курган      9 ЧАСТЬ
   */

  if (checks.notstg2KurganAlienFoundPopupCompleted(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.stg2KurganAlienFoundPopup] },
    };
  }

  if (
    scenarioState.scenarios?.[to[ID.stg2LakeOrderFromGranny]] &&
    !scenarioState.scenarios?.[to[ID.stg2KurganAlienFound]]
  ) {
    const stg2KurganNavigatePoint = createNavigateToPointLink(
      'Мамаев Курган',
      PointsOfInterest.kurgan,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.kurgan]: () =>
          runModalScenario(
            to[ID.stg2KurganAlienFoundIntro],
            to[ID.stg2KurganAlienFound],
            to[ID.stg2KurganAlienFound],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${stg2KurganNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   *    МОСКВА СИТИ      10 ЧАСТЬ
   */

  if (
    scenarioState.scenarios?.[to[ID.stg2KurganAlienFound]] &&
    !scenarioState.scenarios?.[to[ID.stg2MoscowCityMeetingGranny]]
  ) {
    const oboutMoscowReturnNavigatePoint = createNavigateToPointLink(
      'Москва-сити',
      PointsOfInterest.moscowCity,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.moscowCity]: () =>
          runModalScenario(
            to[ID.stg2MoscowCityMeetingGrannyIntro],
            to[ID.stg2MoscowCityMeetingGranny],
            to[ID.stg2MoscowCityMeetingGranny],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${oboutMoscowReturnNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   *    ОСТАНКИНСКАЯ БАШНЯ      11 ЧАСТЬ
   */

  if (checks.notstg2OstankinoAlienInvasionPopupCompleted(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.stg2OstankinoAlienInvasionPopup] },
    };
  }

  if (
    scenarioState.scenarios?.[to[ID.stg2MoscowCityMeetingGranny]] &&
    !scenarioState.scenarios?.[to[ID.stg2OstankinoAlienInvasion]]
  ) {
    const stg2OstankinoNavigatePoint = createNavigateToPointLink(
      'Останкинскую башню',
      PointsOfInterest.ostankino,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.ostankino]: () =>
          runModalScenario(
            to[ID.stg2OstankinoAlienInvasionIntro],
            to[ID.stg2OstankinoAlienInvasion],
            to[ID.stg2OstankinoAlienInvasion],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${stg2OstankinoNavigatePoint}. Тебя там ждут.`,
    };
  }

  return {
    //pointsHandlers: eggs,
    pointsHandlers: [],
  };
}
