/* eslint-disable max-len */
import {
  Action,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  Step,
  StepType,
} from '../../../scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';
import { TVOI_KRUGOZOR_T2_ON_CHECK } from '../../tests/tvoi_krugozor_t2_on_ckeck';
import { TVOI_KRUGOZOR_G2_ON_CHECK } from '../../tests/tvoi_krugozor_g2_on_check';

const EXIT_ID = 'EXIT_ID';

const ALT: Record<string, Step> = {
  ALT1: {
    person: Interlocutors.GRANDMOTHER,
    id: 'ALT1',
    text: `Это же моя подруга Евдокия! Кажется, она не может войти 😔`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Наверное, нужно подойти и спросить, что у нее случилось',
        nextStep: 'ALT2',
      },
      {
        id: 'b2',
        text: 'Я уже [[приложил/приложила]] пропуск, нет времени ждать 10 минут',
        nextStep: 'Q1',
      },
    ],
  },
  ALT2: {
    person: Interlocutors.GRANDMOTHER,
    id: 'ALT2',
    text: 'Наверняка она не поняла, как правильно оформить пропуск в терминале Иннополиса, ввела не все данные, и теперь ее пропуск недействителен 🙁',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Давайте, я помогу Вашей подруге правильно ввести данные и оформить работающий пропуск. (Игровое время будет заморожено на 15 минут)',
        nextStep: 'ALT3/0',
      },
      {
        id: 'b2',
        text: 'Давайте позвоним по телефону администратору и попросим помочь Евдокии с терминалом для оформления пропусков. А сами пойдем искать Петрова. (Игровое время будет заморожено на 7 минут)',
        nextStep: 'ALT4/0',
      },
      {
        id: 'b3',
        text: 'Давайте посоветуем Евдокии позвонить администратору. Он обязательно ей поможет. А сами пойдем искать Петрова. (Игровое время не будет заморожено)',
        nextStep: 'ALT5/0',
      },
    ],
  },
  ...splitStep({
    person: Interlocutors.GRANDMOTHER,
    id: 'ALT3',
    type: StepType.message,
    text: `Спасибо тебе огромное! Даже не знаю, сколько бы Евдокия еще здесь топталась, если бы не ты! 😍

А теперь пойдем искать Петрова.
`,
    nextStep: 'Q1',
  }),
  ...splitStep({
    person: Interlocutors.GRANDMOTHER,
    id: 'ALT4',
    type: StepType.message,
    text: `Как хорошо ты [[придумал/придумала]] с администратором! Евдокия в надежных руках 😊

А нам пора искать Петрова.`,
    nextStep: 'Q1',
  }),
  ...splitStep({
    person: Interlocutors.GRANDMOTHER,
    id: 'ALT5',
    type: StepType.message,
    text: `О, это хорошая идея, давай!

А нам пора искать Петрова.`,
    nextStep: 'Q1',
  }),
};

const ALT_PETROV: Record<string, Step> = {
  ALT_PETROV1: {
    person: Interlocutors.PETROV,
    id: 'ALT_PETROV1',
    text: `Надеюсь, Светлана Васильевна скоро вернется… А то дел в лаборатории накопилась целая куча 😔`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Светлана Васильевна надолго ушла? А то ведь ты нам очень нужен',
        nextStep: 'ALT_PETROV2',
      },
      {
        id: 'b2',
        text: 'Да, надеюсь она скоро вернется, ведь у нас к тебе тоже есть дело… ',
        nextStep: 'Q3',
      },
    ],
  },
  ALT_PETROV2: {
    person: Interlocutors.PETROV,
    id: 'ALT_PETROV2',
    text: 'Светлане Васильевне для следующего занятия нужна 3D ручка. У нее есть картриджи, но ручки нет. Вот она и побежала в МГУ, чтобы там поискать, вдруг найдется.',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Так у меня же как раз есть 3D ручка. Скорее звони Светлане Васильевне и скажи, чтобы возвращалась. Я дам ей свою ручку для урока. (Игровое время будет заморожено на 10 минут и вы лишитесь 3D ручки)',
        nextStep: 'ALT_PETROV3/0',
        action: Action.Lost3dPen,
      },
      {
        id: 'b2',
        text: 'Надо позвонить Сан Санычу, он любитель современных технологичных вещей. Скорее всего, у него есть 3D ручка. (Игровое время будет заморожено на 5 минут)',
        nextStep: 'ALT_PETROV4/0',
      },
      {
        id: 'b3',
        text: 'Да уж, должно быть Светлана Васильевна переживает, что не может найти 3D ручку. (Игровое время не будет заморожено)',
        nextStep: 'Q3',
      },
    ],
  },
  ...splitStep({
    person: Interlocutors.TEACHER,
    id: 'ALT_PETROV3',
    type: StepType.message,
    text: `Спасибо тебе огромное, {{NAME}}! Теперь занятие пройдет по плану 😊 

Я верну тебе ручку после урока.`,
    nextStep: 'Q3',
  }),
  ...splitStep({
    person: Interlocutors.PETROV,
    id: 'ALT_PETROV4',
    type: StepType.message,
    text: `Как здорово ты [[придумал/придумала]] с Сан Санычем! И чего я сам не догадался! Позвоню Светлане Васильевне и скажу, что ручку скоро доставят в Иннополис.`,
    nextStep: 'Q3',
  }),
};

export const scenario_3_3_0: Scenario = {
  scenarioId: SceneId.scenario_3_3_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Иннополис',
    text: 'Вы с Бабулей заходите в главное здание Иннополиса и прикладываете свои пропуска к турникетам на входе. Пропуском можно воспользоваться один раз в 10 минут. Бабуля проходит первой, оборачивается и удивленно восклицает. Вы замираете у турникета...',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_3: Scenario = {
  scenarioId: SceneId.scenario_3_3,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    timeoutMessage: 'К сожалению, время для ответа истекло 🙁',
    title: 'Иннополис',
    scene_id: 'scenario_3_3',
    startId: 'ALT1',
    steps: {
      ...ALT,

      Q1: {
        person: Interlocutors.SYSTEM,
        id: 'Q1',
        text: 'Вы находите Петрова в лектории Иннополиса и видите перед ним класс учеников…',
        type: StepType.message,
        nextStep: 'Q2/0',
      },
      ...splitStep({
        person: Interlocutors.PETROV,
        id: 'Q2',
        text: `О, привет, {{NAME}}! Здравствуйте, Бабуля! А я сегодня подменяю Светлану Васильевну. Она поручила мне провести для ребят очень интересный тест. 

{{NAME}}, хочешь с нами? Тебе наверняка понравится 😉`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо, что предложил! Я как раз [[хотел/хотела]] размять мозги, поэтому с удовольствием пройду твой тест!',
            nextStep: 'Q2_1',
          },
          {
            id: 'b2',
            text: 'Ну раз ты говоришь, что тест очень интересный, я в деле!',
            nextStep: 'Q2_1',
          },
        ],
      }),

      Q2_1: {
        person: Interlocutors.PETROV,
        id: 'Q2_1',
        text: `Тест состоит из 2х частей по 10 вопросов. На прохождение каждой части будет отведено ровно 10 минут. Ты [[должен/должна]] выбрать 1 вариант ответа из предложенных, и у тебя будет всего 1 минута на каждый вопрос. Очень внимательно читай вопросы.
        
        Важный момент: пройти тест можно только один раз. Повторно к нему вернуться будет нельзя. 
        
        Обрати внимание: так как ты проходишь тест после 16:00 7 июня, то баллы за этот тест тебе не будут начисляться.
        
        Выходить из теста во время его прохождения нельзя. Но если ты все-таки выйдешь из игры после запуска теста, независимо от причин, почему это произошло, таймер будет продолжать работать, а время, отведенное на тест, истекать. 
        
        Вопросы нельзя проматывать и возвращаться к предыдущим вопросам, поэтому прежде, чем начать тест, проверь, что у тебя:
           -   стабильно работает Интернет
           -   заряжено устройство, с которого ты проходишь тест
           -   и никто тебе не помешает в ближайшие 20 минут`,
        callToAction: 'Начнем?',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            action: Action.LostPick,
            id: 'b1',
            text: 'Да, давай!',
            nextStep: 'TVOI_KRUGOZOR_G2_STEP',
          },
        ],
      },

      ...TVOI_KRUGOZOR_G2_ON_CHECK,

      Q2_2: {
        person: Interlocutors.PETROV,
        id: 'Q2_2',
        text: `И еще 10 вопросов. [[Готов/Готова]]?`,
        callToAction: 'Начнем?',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, давай!',
            nextStep: 'TVOI_KRUGOZOR_T2_STEP',
          },
        ],
      },

      // Вторая часть теста Твой кругозор 2\2

      ...TVOI_KRUGOZOR_T2_ON_CHECK,

      ...ALT_PETROV,

      Q3: {
        person: Interlocutors.PETROV,
        id: 'Q3',
        type: StepType.message,
        text: `А кстати, зачем вы меня искали?`,
        nextStep: 'Q4',
      },
      Q4: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q4',
        type: StepType.message,
        text: `Мы тебе кирку принесли!`,
        nextStep: 'Q5',
      },
      Q5: {
        person: Interlocutors.PETROV,
        id: 'Q5',
        type: StepType.message,
        text: `Какую такую кирку? Зачем?`,
        nextStep: 'Q6/0',
      },

      ...splitStep({
        person: Interlocutors.GRANDMOTHER,
        id: 'Q6',
        type: StepType.message,
        text: `Помнишь, мы приступили к расследованию дела Вжуха и решили начать распутывать этот клубок с поезда в «Артек»? В вагоне, где НЕКТО (предположительно Вжух) нажал стоп-кран, мы нашли эту кирку. 

Ты очень умный, вот мы и подумали, что ты можешь знать, откуда эта вещь взялась...
`,
        nextStep: 'Q7',
      }),
      Q7: {
        person: Interlocutors.PETROV,
        id: 'Q7',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            action: Action.LostPick,
            id: 'b1',
            text: 'Отдать кирку',
            nextStep: 'Q8',
          },
        ],
        text: `Вот оно что! Ну, показывайте свою кирку. Надо ее осмотреть.`,
      },
      Q8: {
        person: Interlocutors.SYSTEM,
        id: 'Q8',
        type: StepType.message,
        text: `Вы передаете кирку Петрову.`,
        nextStep: 'Q9/0',
      },
      ...splitStep({
        person: Interlocutors.PETROV,
        id: 'Q9',
        type: StepType.message,
        text: `Хм… Кажется, я такую уже где-то видел, но не припомню где... 🤔

Значит, воспользуемся любимым методом Шерлока Холмса — дедукцией! Эту кирку явно активно использовали в работе, она совсем неновая. К тому же на ней куски глины… Значит, ее использовали для работы с твердой породой земли… 🤔`,
        nextStep: 'Q10',
      }),
      Q10: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q10',
        type: StepType.message,
        text: `Но у нас в «Большой перемене» нет ни шахт, ни горнодобывающих предприятий… А в зоне Природных достояний вообще нельзя ничего копать.`,
        nextStep: 'Q11/0',
      },
      ...splitStep({
        person: Interlocutors.PETROV,
        id: 'Q11',
        type: StepType.message,
        text: `Точно! Копать! Я вспомнил, где видел подобные инструменты — на археологических раскопках в ХЕРСОНЕСЕ. Следующую улику надо искать там 🧐

Пойдемте все вместе. Теперь расследование и меня заинтересовало.`,
        nextStep: EXIT_ID,
      }),

      [EXIT_ID]: {
        person: Interlocutors.TEACHER,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};

// const ERUDITION_TEST_STEP: TestStep = {
//   id: 'ERUDITION_TEST_STEP',
//   person: Interlocutors.PETROV,
//   type: StepType.test,
//   external_id: '20210425-004355-7293',
//   path: 'erudition-20210425-002940-6023.json',
//   name: 'Эрудиция 2/2',
//   order: [
//     { timeout: 60, question: 'Q1', subtest: 'T1', description: '' },
//     { timeout: 60, question: 'Q2', subtest: 'T1', description: '' },
//     { timeout: 60, question: 'Q3', subtest: 'T1', description: '' },
//     { timeout: 60, question: 'Q4', subtest: 'T1', description: '' },
//     { timeout: 60, question: 'Q5', subtest: 'T1', description: '' },
//     { timeout: 60, question: 'Q6', subtest: 'T1', description: '' },
//     { timeout: 60, question: 'Q7', subtest: 'T1', description: '' },
//     { timeout: 60, question: 'Q9', subtest: 'T1', description: '' },
//     { timeout: 60, question: 'Q11', subtest: 'T1', description: '' },
//     { timeout: 60, question: 'Q19', subtest: 'T1', description: '' },
//   ],
//   nextStep: 'ERUDITION_TEST_FINISH/0',
// };

// const ERUDITION_TEST: Record<string, Step> = {
//   ERUDITION_TEST_1: {
//     person: Interlocutors.PETROV,
//     id: 'ERUDITION_TEST_1',
//     text:
//       'Тест состоит из 10 вопросов. Ты [[должен/должна]] выбрать 1 вариант ответа из предложенных, и у тебя будет всего 1 минута на каждый вопрос.',
//     type: StepType.question,
//     callToAction: 'Начнем?',
//     maxAnswers: 1,
//     buttons: [
//       {
//         id: 'b1',
//         text: 'Да, давай!',
//         nextStep: 'intermediateStep',
//       },
//     ],
//   },

//   intermediateStep: {
//     person: Interlocutors.SYSTEM,
//     id: 'intermediateStep',
//     text: 'Данный тест является тренировочным и не приносит баллов за конкурс.',
//     type: StepType.question,
//     maxAnswers: 1,
//     buttons: [
//       {
//         id: 'b1',
//         text: 'запуск теста',
//         nextStep: 'ERUDITION_TEST_STEP',
//       },
//     ],
//   },

//   ERUDITION_TEST_STEP,
//   ...splitStep({
//     person: Interlocutors.PETROV,
//     id: 'ERUDITION_TEST_FINISH',
//     text: `Ну вот и весь тест 😊

//       Как он тебе? Понравился?`,
//     type: StepType.question,
//     maxAnswers: 1,
//     buttons: [
//       {
//         id: 'b1',
//         text: 'Ты был прав. Тест оказался очень интересным!',
//         nextStep: 'ALT_PETROV1',
//       },
//       {
//         id: 'b2',
//         text: 'Ох, Петров! Было сложновато, но я, кажется, [[справился/справилась]]...',
//         nextStep: 'ALT_PETROV1',
//       },
//     ],
//   }),
// };
