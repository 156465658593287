import { SceneId, Step } from '../../types/scenario';
import scenes from '../../resources/scenes';

export const stage1RequiredScenarios: SceneId[][] = [
  [SceneId.scenario_1_1],
  [SceneId.lake_1_1],
  [SceneId.innopolice_1_1],
  [SceneId.hermitage_1_1],
  [SceneId.lakeComeback_1_1],
  [SceneId.hermitageFeeding_1_1],
  [SceneId.olympicPark_1_1],
  [SceneId.alien_1_1],
  [SceneId.moscowCity_1_1],
  [SceneId.mguDictaphone_1_1],
  [SceneId.oboutMoscow_1_1],
  [SceneId.oboutInnopolice_1_1],
];

export const s2a1RequiredScenarios: SceneId[][] = [
  [SceneId.stg2_innopolice_1_1],
  [SceneId.stg2_mgu_1_1],
  [SceneId.stg2_lake_1_1],
  [SceneId.stg2_hermitage_1_1],
];

export const s2a2RequiredScenarios: SceneId[][] = [
  [SceneId.stg2_olympicPark_1_1],
  [SceneId.stg2_moscowCity_1_1],
  [SceneId.stg2_mguQuiz_1_1],
  [SceneId.stg2_innopolicePetrov_1_1],
];

export const s2a3RequiredScenarios: SceneId[][] = [
  [SceneId.stg2_lakeOrderFromGranny_1_1],
  [SceneId.stg2_kurganAlienFound_1_1],
  [SceneId.stg2_moscowCityMeetingGranny_1_1],
  [SceneId.stg2_ostankinoAlienInvasion_1_1],
];

export const s3a1RequiredScenarios: SceneId[][] = [
  [SceneId.stg3_ostankino_1_1],
  [SceneId.stg3_space_1_1],
  [SceneId.stg3_innopolice_1_1],
  [SceneId.stg3_spaceAlienShip_1_1], // нет теста
];

export const s3a2a3RequiredScenarios: SceneId[][] = [
  [SceneId.scenario_3_11], // нет теста
  [SceneId.scenario_3_12], // нет теста
  [SceneId.scenario_3_13], // нет теста
  [SceneId.scenario_3_14], // нет теста
  [SceneId.scenario_3_15], // нет теста
  [SceneId.scenario_3_16],
  [SceneId.scenario_3_17],
  [SceneId.scenario_3_18],
  [SceneId.scenario_3_19],

  [SceneId.scenario_3_21], // нет теста
  [SceneId.scenario_3_22_1],
  [SceneId.scenario_3_23_1], // нет теста
];

export const ALL_REQUIRED_SCENARIOS: SceneId[][] = [
  ...stage1RequiredScenarios,
  ...s2a1RequiredScenarios,
  ...s2a2RequiredScenarios,
  ...s2a3RequiredScenarios,
  ...s3a1RequiredScenarios,
  ...s3a2a3RequiredScenarios,
];

type Progress = Partial<Record<SceneId, Step[]>>;

enum GroupStatus {
  exist,
  skipped_not_required,
  skipped,
}
function checkGroup(sceneIds: SceneId[], state: Progress): GroupStatus {
  let status = GroupStatus.exist;
  for (let i = 0; i < sceneIds.length; i++) {
    if (!state[sceneIds[i]]) {
      if (scenes[sceneIds[i]].isRequired) {
        return GroupStatus.skipped;
      }
      status = GroupStatus.skipped_not_required;
    }
  }
  return status;
}

export function hasBlanks(scenarios: Progress) {
  let hasBlankH = GroupStatus.exist;
  for (let i = 0; i < ALL_REQUIRED_SCENARIOS.length; i++) {
    const currentStatus = checkGroup(ALL_REQUIRED_SCENARIOS[i], scenarios);
    // если группа пройдена, а ранее есть пробел
    if (currentStatus === GroupStatus.exist && hasBlankH === GroupStatus.skipped) {
      return true;
    }
    hasBlankH = Math.max(currentStatus, hasBlankH) as GroupStatus;
  }
  // если дошли до сюда, значит даже если hasBlank, то после "пробела" нет пройденых сценариев
  return false;
}
