import React from 'react';

export const IconSupport: React.FC = () => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      /* eslint-disable-next-line max-len */
      d='M9 0C4.0372 0 0 4.0372 0 9C0 13.9628 4.0372 18 9 18C13.9628 18 18 13.9628 18 9C18 4.0372 13.9628 0 9 0ZM9 14.25C8.58595 14.25 8.25005 13.914 8.25005 13.5C8.25005 13.086 8.58595 12.75 9 12.75C9.41405 12.75 9.74995 13.086 9.74995 13.5C9.74995 13.914 9.41405 14.25 9 14.25ZM10.1872 9.48148C9.92175 9.6037 9.74995 9.87149 9.74995 10.1633V10.5C9.74995 10.914 9.41473 11.25 9 11.25C8.58527 11.25 8.25005 10.914 8.25005 10.5V10.1633C8.25005 9.28798 8.76448 8.48557 9.55879 8.11876C10.323 7.76706 10.875 6.83322 10.875 6.37495C10.875 5.34155 10.0342 4.5 9 4.5C7.96577 4.5 7.12505 5.34155 7.12505 6.37495C7.12505 6.789 6.78969 7.12505 6.37495 7.12505C5.96022 7.12505 5.625 6.789 5.625 6.37495C5.625 4.51428 7.13919 2.99995 9 2.99995C10.8608 2.99995 12.375 4.51428 12.375 6.37495C12.375 7.38831 11.496 8.87778 10.1872 9.48148Z'
      fill='currentColor'
    />
  </svg>
);
