/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const alien_1_1_1: Scenario = {
  scenarioId: SceneId.alien_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Знакомство с Инопланетянином',
    text: `Тут вы заметили позади Вжуха странное белое существо.`,
    buttons: ['Начать'],
  },
};
