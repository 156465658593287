import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectScenarios } from '../../redux/user/userGetters';
import { notRocketStarted } from '../scenarioHelpers/stageChecks';
import { s2a2RequiredScenarios } from './requiredScenarios';

export function useIsSecondStageSecondActCompleted() {
  const scenarioState = useSelector(selectScenarios);
  return useMemo(() => {
    if (!scenarioState || !scenarioState.scenarios) {
      return false;
    }

    for (let i = 0; i < s2a2RequiredScenarios.length; i++) {
      for (let j = 0; j < s2a2RequiredScenarios[j].length; j++) {
        if (!scenarioState.scenarios[s2a2RequiredScenarios[i][j]]) {
          return false;
        }
      }
    }
    // return !notRocketStarted(scenarioState);
    return true;
  }, [scenarioState]);
}
