import React from 'react';
import {
  MapModalImgStyled,
  MapModalTextStyled,
  MapModalTitleStyled,
  MapModalButtonsStyled,
} from '../pages/MapPage/MapPageStyled';

import styled from 'styled-components';
import colors from '../../common/shared/constants/colors';
import interlocutors from '../resources/interlocutors';

import { Box } from '../../common/shared/ui/Box';
import { Icon } from '../../common/shared/ui/Icon';
import { Button } from '../../common/shared/ui/Button';
import { NewModal } from '../../common/shared/ui/NewModal';
import { TemplatedText } from '../components/TemplatedText';
import { GameModalHooks } from './GameModalHooks';

import { Interlocutors } from '../types/scenario';
import { TemplatedTextDescribed } from '../../common/shared/ui/Text/TextStyled';

const TitleWrapperStyled = styled.div`
  position: relative;
`;

const DividerStyled = styled.div`
  height: 1px;
  width: 100%;
  background: ${colors.grey210};
`;

interface GameModalProps {}
interface GameModalState {
  closable: boolean;
  title: string;
  text: string;
  person: Interlocutors;
  isOpen: boolean;
  buttons: string[];
  onSubmit?: (buttons: string) => void;
  described?: string;
  buttonToExitTheGame?: boolean;
}
export class GameModal extends React.Component<GameModalProps> {
  static instance?: GameModal;

  state: GameModalState = {
    title: '',
    text: '',
    person: Interlocutors.SYSTEM,
    isOpen: false,
    buttons: [],
    onSubmit: undefined,
    closable: false,
    described: '',
    buttonToExitTheGame: false,
  };

  constructor(props: GameModalProps) {
    super(props);
    GameModal.instance = this;
  }

  componentWillUnmount() {
    if (GameModal.instance === this) {
      GameModal.instance = undefined;
    }
  }

  open(
    title: string,
    text: string,
    person: Interlocutors,
    buttons: string[],
    closable: boolean,
    onSubmit?: (buttons: string) => void,
    described?: string,
    buttonToExitTheGame?: boolean,
  ) {
    this.setState({
      isOpen: true,
      title,
      text,
      person,
      buttons,
      closable,
      onSubmit,
      described,
      buttonToExitTheGame,
    });
  }
  static open(
    title: string,
    text: string,
    person: Interlocutors,
    buttons: string[],
    closable: boolean,
    onSubmit?: (buttons: string) => void,
    described?: string,
    buttonToExitTheGame?: boolean,
  ) {
    GameModal.instance?.open(
      title,
      text,
      person,
      buttons,
      closable,
      onSubmit,
      described,
      buttonToExitTheGame,
    );
  }
  close = () => {
    this.setState({ isOpen: false });
  };
  onPressButton(button: string) {
    this.close();
    this.state.onSubmit?.(button);
  }
  static close() {
    GameModal.instance?.close();
  }
  render() {
    const { title, person, text, buttons, isOpen, closable, described, buttonToExitTheGame } =
      this.state;

    return (
      <NewModal
        onClose={closable ? this.close : undefined}
        title={
          <TitleWrapperStyled>
            <MapModalTitleStyled>{title}</MapModalTitleStyled>
            <MapModalImgStyled src={interlocutors[person].imgModal} />
            <DividerStyled />
          </TitleWrapperStyled>
        }
        isOpen={isOpen}>
        <MapModalTextStyled $textStyle='p18'>
          <TemplatedText isHTML>{text}</TemplatedText>
        </MapModalTextStyled>
        {described && <TemplatedTextDescribed>{described}</TemplatedTextDescribed>}
        <MapModalButtonsStyled>
          {buttons.map((buttonText) => (
            <Button
              key={buttonText}
              $textStyle='p18'
              $fontWeight='500'
              onClick={() => this.onPressButton(buttonText)}>
              <Box $justify='space-between' $isWrap={false} $align='center'>
                <div>{buttonText}</div>
                <Icon $mL='12px' icon='ico_chevron' />
              </Box>
            </Button>
          ))}
        </MapModalButtonsStyled>
        {buttonToExitTheGame && <GameModalHooks />}
      </NewModal>
    );
  }
}
