import React from 'react';
import { StepCore } from '../../resources/scenario';

import { Text } from '../../../common/shared/ui/Text';
import styled from 'styled-components';
import colors from '../../../common/shared/constants/colors';
import { TemplatedText } from '../../components/TemplatedText';

interface Props {
  step: StepCore;
}

const StyledCallToAction = styled.div`
  display: flex;
  margin: 14px 0 0 0;

  > span {
    padding: 4px 14px;
    border-radius: 8px;
    background: ${colors.orange};
  }
`;

export function CallToAction(props: Props) {
  if (!props.step.callToAction) {
    return null;
  }

  return (
    <StyledCallToAction>
      <Text $color={colors.white}>
        <TemplatedText>{props.step.callToAction}</TemplatedText>
      </Text>
    </StyledCallToAction>
  );
}
