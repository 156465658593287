import { Step, StepType, Interlocutors, SystemStepType } from '../../../types/scenario';
import { splitStep } from '../../../utils/ScenarioUtils';
import { images } from '../tests/images';

// ТЕСТ: КРЕАТИВНОСТЬ 2 кубик

export const CREATIVE_TEST_2: { [id: string]: Step } = {
  ...splitStep({
    id: 'CREATIVE_ELEPHANT',
    person: Interlocutors.ALIEN,
    delay: 7000,
    type: StepType.message,
    text: `Перед тобой игрушечный слон. Как можно его изменить, чтобы детям стало интереснее играть? Придумай самые удачные, оригинальные и необычные способы изменения игрушечного слона. 
    
    Не беспокойся о том, насколько сложно будет осуществить твои изменения. Думай только, как можно улучшить эту игрушку. Перечисли все возможные изменения слона и запиши ниже. Напоминаю, у тебя на это есть 10 минут.
    
    Записывай по одному варианту в поле ввода и обязательно жми кнопку «Отправить ответ». Для следующей твоей идеи появится новое поле ввода. То есть нужно писать и отправлять по одной идее за раз. Всего ты можешь предложить 15 вариантов улучшений, так что чем больше — тем лучше.
    
    И не забудь, твои варианты должны быть приличными и осмысленными.`,
    nextStep: 'CREATIVE1',
  }),
  CREATIVE1: {
    person: Interlocutors.ALIEN,
    id: 'CREATIVE1',
    type: StepType.message,
    image: { uri: images.elephant, title: '' },
    text: `Ну все, время пошло! А вот и слон, которого ты будешь улучшать. Пиши свои варианты!`,
    nextStep: 'CREATIVE2',
  },
  CREATIVE2: {
    id: 'CREATIVE2',
    person: Interlocutors.ALIEN,
    type: StepType.system,
    _meta: {
      type: SystemStepType.limitedTimeInput,
      time: 10 * 60,
      maxAnswers: 15,
      exitStep: 'Q11',
    },
    nextStep: 'CREATIVE3',
  },
  CREATIVE3: {
    id: 'CREATIVE3',
    person: Interlocutors.ALIEN,
    text: '',
    type: StepType.text_question,
    variable: 'CREATIVE_ELEPHANT',
    nextStep: 'CREATIVE3',
  },
};
