/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';
import interlocutors from '../../../interlocutors';

export const ostankino: Scenario = {
  scenarioId: SceneId.scenario_1_5_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: 'Останкино откроет свои двери 12 апреля в 9 вечера по Москве. Приходи 😊',
    buttons: ['Закрыть'],
  },
};

export const mgu: Scenario = {
  scenarioId: SceneId.scenario_1_5_2,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: 'МГУ  откроет свои двери 12 апреля в 9 вечера по Москве. Приходи 😊',
    buttons: ['Закрыть'],
  },
};

export const space: Scenario = {
  scenarioId: SceneId.scenario_1_5_3,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: 'Попасть на Космодром можно после 12 апреля. Приходи 😊',
    buttons: ['Закрыть'],
  },
};

export const baikal: Scenario = {
  scenarioId: SceneId.scenario_1_5_3,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: 'Попасть на Космодром можно после 12 апреля. Приходи 😊',
    buttons: ['Закрыть'],
  },
};
