/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../scenario';
import interlocutors from '../../../interlocutors';

export const scenario_2_5: Scenario = {
  scenarioId: SceneId.scenario_2_5,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: '{{NAME}}, да ты уже [[прошел/прошла]] все задания! Круто! Возвращайся 3 мая, тебя будут ждать новые приключения!',
    buttons: ['Закрыть'],
  },
};

export const scenario_2_5_1: Scenario = {
  scenarioId: SceneId.scenario_2_5_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Иннополис',
    person: Interlocutors.PETROV,
    text: 'Привет, {{NAME}}! Я все еще в процессе создания тепловой пушки, твоя помощь пока не нужна.',
    buttons: ['Закрыть'],
  },
};

export const scenario_2_5_2: Scenario = {
  scenarioId: SceneId.scenario_2_5_2,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Космодром',
    person: Interlocutors.ENTREPRENEUR,
    text: 'Я почти вышел на след похитителя ракеты!.. Возвращайся позже, возможно, в следующий раз для тебя найдется здесь работенка.',
    buttons: ['Закрыть'],
  },
};

export const scenario_2_5_3: Scenario = {
  scenarioId: SceneId.scenario_2_5_3,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Эрмитаж',
    person: Interlocutors.GRANDMOTHER,
    text: 'Как же здорово, что ты [[помог/помогла]] нам с экспонатами! Но сейчас вся работа сделана и пока что твоя помощь не нужна.',
    buttons: ['Закрыть'],
  },
};

export const scenario_2_5_4: Scenario = {
  scenarioId: SceneId.scenario_2_5_4,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'МГУ',
    person: Interlocutors.CAT,
    text: 'Сейчас здесь никого нет, все разбежались по делам. Возвращайся позже.',
    buttons: ['Закрыть'],
  },
};

export const scenario_2_5_5: Scenario = {
  scenarioId: SceneId.scenario_2_5_5,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Природные достояния России',
    person: Interlocutors.CAT,
    text: 'Здесь твоя помощь пока не нужна. Приходи в другой раз.',
    buttons: ['Закрыть'],
  },
};

export const scenario_2_5_6: Scenario = {
  scenarioId: SceneId.scenario_2_5_6,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Олимпийский парк в Сочи',
    person: Interlocutors.CAT,
    text: 'На спортивных объектах все тихо. Пока тут нечего делать.',
    buttons: ['Закрыть'],
  },
};

export const scenario_2_5_7: Scenario = {
  scenarioId: SceneId.scenario_2_5_7,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Башня Останкино',
    person: Interlocutors.CAT,
    text: 'Кажется, здесь никого нет. Приходи попозже.',
    buttons: ['Закрыть'],
  },
};
