/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT_ID';

export const scenario_3_11: Scenario = {
  scenarioId: SceneId.scenario_3_11,
  type: ScenarioType.chat,
  data: {
    title: 'Херсонес',
    scene_id: 'scenario_3_11',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q1',
        type: StepType.message,
        text: `СТОЙ! Не делай этого ни в коем случае!!! Если вытащить ручку, парк снова заледенеет!!! 🙀`,
        nextStep: 'Q2',
      },
      Q2: {
        person: Interlocutors.PETROV,
        id: 'Q2',
        type: StepType.message,
        text: `ЧТОООООО? Кто ты такой? 😱`,
        nextStep: 'Q4',
      },
      Q4: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q4',
        text: 'Батюшки! Да что же это здесь творится?!! 🤔',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ой, кто это? Какой-то синий… Вжух???',
            nextStep: 'Q5/0',
          },
        ],
      },
      ...splitStep({
        person: Interlocutors.PETROV,
        id: 'Q5',
        text: `Это же кот! Действительно, вылитый Вжух, только синий! 😱

Что ты здесь делаешь и почему сидишь возле моей дым-машины?`,
        type: StepType.message,
        nextStep: 'Q6/0',
      }),
      ...splitStep({
        id: 'Q6',
        person: Interlocutors.BLUE_CAT,
        type: StepType.message,
        text: `Многоуважаемые, подождите! Не трогайте ручку… Она сдерживает пусковой механизм дым-машины. Если ее вытащить, то все здесь снова покроется ледяным туманом!

Видите ли… Я...`,
        nextStep: 'Q7/0',
      }),
      ...splitStep({
        person: Interlocutors.GRANDMOTHER,
        id: 'Q7',
        type: StepType.message,
        text: `Да погодите вы с расспросами, давайте сначала накормим бедолагу! Посмотрите, какой он худенький: кожа да кости, шерсть местами вылезла и выглядит он очень напуганным 😔

Котик, не бойся, пойдем с нами. Тут как раз рядом Москва-Сити, там и накормим, и согреем.

А ты, Петров, пока позвони Сан Санычу, пусть найдет Светлану Васильевну, возьмет Вжуха, и встретимся в Москва-Сити.`,
        nextStep: EXIT_ID,
      }),
      [EXIT_ID]: {
        person: Interlocutors.TEACHER,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
