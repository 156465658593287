import React from 'react';
import { useSelector } from 'react-redux';
import { ScenarioNameProps } from './ScenarioNameTypes';
import { StyledScenarioName } from './ScenarioNameStyled';
import { selectActiveChatScenario } from '../../redux/scenario/scenarioGetters';

import { Text } from '../../../common/shared/ui/Text';
import useViewport from '../../hooks/useViewport';
import interlocutors from '../../resources/interlocutors';
import { ScenarioType } from '../../types/scenario';

const ScenarioName = (props: ScenarioNameProps) => {
  const { isMobile } = useViewport();
  const scene = useSelector(selectActiveChatScenario);

  if (!scene || isMobile) return null;
  if (scene.type !== ScenarioType.chat) return null;

  const isHaveTitle = scene.data.title;
  const label = isHaveTitle ? 'Текущая локация:' : 'Ваш собеседник:';

  return (
    <StyledScenarioName>
      <Text $textStyle='p12' $color='grey'>
        {label}
      </Text>
      <Text $textStyle='p20' $fontWeight='700'>
        {scene.data.title || interlocutors[scene.data.steps[scene.data.startId].person].name}
      </Text>
    </StyledScenarioName>
  );
};

export default ScenarioName;
