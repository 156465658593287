/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId, StepType, TestStep } from '../../scenario';
import { splitStep } from '../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT';
const TO_MAP_STEP = 'TO_MAP_STEP';

const person = Interlocutors.GRANDMOTHER;

const TEST_TVOI_VIBOR_5: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'TEST_TVOI_VIBOR_5',
  name: 'Твой выбор 5/5',
  nextStep: 'Q9',
  order: [
    {
      description:
        'Хочется, чтобы труд был тебе в радость, поэтому выбери то, чем хочешь здесь заняться.',
      subtest: 'T5',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person,
  type: StepType.test,
};

export const scenario_1_23: Scenario = {
  scenarioId: SceneId.scenario_1_23,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Природные достояния России',
    scene_id: 'scenario_1_23',
    startId: 'Q1/0',
    steps: {
      ...splitStep({
        text: `А, {{NAME}}, ну здравствуй, здравствуй. 
        
        Ты как раз вовремя. Пока тебя не было, деревья в парке подросли, и цветы вот-вот распустятся! Думаю, детвора тоже скоро вернется в «Большую перемену»… Но только где же они будут играть? 🙁`,
        callToAction: 'Ведь ты же поможешь?',
        id: 'Q1',
        type: StepType.message,
        person,
        nextStep: 'Q2/0',
      }),

      ...splitStep({
        id: 'Q2',
        type: StepType.message,
        text: `Здесь нет ни скамеек, ни каруселей, ни фонарей! Нужно скорее обустроить парк! Без тебя с этой задачей мы точно не справимся! 😣

        Сан Саныч обещал помочь с материалами для благоустройства, но есть одна загвоздка. Он все-таки предприниматель и сказал, что продаст нам материалы только за правильные ответы в викторине.`,
        person,
        nextStep: 'Q3/0',
      }),
      ...splitStep({
        id: 'Q3',
        person,
        text: `Я уже многое позабыла и никак не справлюсь с вопросами Сан Саныча 🙁

        Но, может быть, вместе мы найдем правильные ответы.`,
        callToAction: 'Поможешь мне?',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Запросто отвечу на все вопросы!',
            nextStep: 'Q4',
          },
        ],
      }),
      Q4: {
        id: 'Q4',
        person,
        callToAction: 'Выбери вариант',
        text: 'Отлично! Всего будет три вопроса и вот первый. Как называется главный стадион Олимпийского парка в Сочи?',
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q4-1',
            text: 'Фишт',
          },
          {
            id: 'b2',
            nextStep: 'Q4-2',
            text: 'Лужники',
          },
          {
            id: 'b3',
            nextStep: 'Q4-3',
            text: 'Олимп',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      'Q4-1': {
        id: 'Q4-1',
        person,
        text: 'Ну точно, это «Фишт»!',
        nextStep: 'Q5',
        type: StepType.message,
      },
      'Q4-2': {
        id: 'Q4-2',
        person,
        callToAction: 'Выбери вариант',
        type: StepType.question,
        maxAnswers: 1,
        text: 'Ну нет. «Лужники» — это московский стадион. Вроде как нужный нам стадион назван в честь горной вершины Кавказского хребта и построен в форме ракушки.',
        buttons: [
          {
            id: 'b1',
            text: 'Фишт',
            nextStep: 'Q4-1',
          },
          {
            id: 'b2',
            text: 'Олимп',
            nextStep: 'Q4-3',
          },
        ],
      },
      'Q4-3': {
        id: 'Q4-3',
        person,
        callToAction: 'Выбери вариант',
        type: StepType.question,
        maxAnswers: 1,
        text: `Нет, я помню, что «Олимп» находится в Ростове-на-Дону. Кстати, его полное название «Олимп имени Виктора Понедельника».`,
        buttons: [
          {
            id: 'b1',
            text: 'Фишт',
            nextStep: 'Q4-1',
          },
          {
            id: 'b2',
            text: 'Лужники',
            nextStep: 'Q4-2',
          },
        ],
      },
      Q5: {
        id: 'Q5',
        person,
        callToAction: 'Выбери вариант',
        text: 'Следующий вопрос. В каком году проходили зимние Олимпийские игры в Сочи? ',
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q5-1',
            text: '2010',
          },
          {
            id: 'b2',
            nextStep: 'Q5-2',
            text: '2014',
          },
          {
            id: 'b3',
            nextStep: 'Q5-3',
            text: '2015',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      'Q5-1': {
        id: 'Q5-1',
        person,
        callToAction: 'Выбери вариант',
        text: 'Нет-нет, я точно помню, что в 2010 году зимняя Олимпиада была в канадском Ванкувере. Этот ответ не подходит, давай еще подумаем.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: '2014',
            nextStep: 'Q5-2',
          },
          {
            id: 'b2',
            text: '2015',
            nextStep: 'Q5-3',
          },
        ],
      },
      'Q5-3': {
        id: 'Q5-3',
        person,
        callToAction: 'Выбери вариант',
        text: 'Мне кажется, в 2015 году вообще не было игр. Видишь ли, Олимпиада проходит каждые два года, причем зимняя и летняя чередуются.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: '2010',
            nextStep: 'Q5-1',
          },
          {
            id: 'b2',
            text: '2014',
            nextStep: 'Q5-2',
          },
        ],
      },
      'Q5-2': {
        id: 'Q5-2',
        person,
        type: StepType.message,
        nextStep: 'Q6',
        text: 'Конечно! Я тоже вспомнила! Это было в 2014 году! 😍',
      },
      Q6: {
        id: 'Q6',
        person,
        callToAction: 'Выбери вариант',
        text: 'Ну и последний вопрос. Арены для каких соревнований есть в сочинском Олимпийском парке?',
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q6-1',
            text: 'Теннис, бадминтон, пинг-понг',
          },
          {
            id: 'b2',
            nextStep: 'Q6-2',
            text: 'Фигурное катание, конькобежный спорт, хоккей, керлинг',
          },
          {
            id: 'b3',
            nextStep: 'Q6-3',
            text: 'Триатлон, гребля, парусный спорт',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      'Q6-1': {
        id: 'Q6-1',
        person,
        callToAction: 'Выбери вариант',
        text: 'Нет, этого точно не было на зимней Олимпиаде 2014 года. Давай еще поразмышляем...',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Фигурное катание, конькобежный спорт, хоккей, керлинг',
            nextStep: 'Q6-2',
          },
          {
            id: 'b2',
            text: 'Триатлон, гребля, парусный спорт',
            nextStep: 'Q6-3',
          },
        ],
      },
      'Q6-3': {
        id: 'Q6-3',
        person,
        callToAction: 'Выбери вариант',
        text: 'Эти виды спорта летние, а сочинский Олимпийский парк остался после зимней Олимпиады.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Теннис, бадминтон, пинг-понг',
            nextStep: 'Q6-1',
          },
          {
            id: 'b2',
            text: 'Фигурное катание, конькобежный спорт, хоккей, керлинг',
            nextStep: 'Q6-2',
          },
        ],
      },
      'Q6-2': {
        id: 'Q6-2',
        person,
        text: 'Ну точно! Я ведь даже была там на соревнованиях по фигурному катанию! 😍',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Круто!',
            nextStep: 'Q7/0',
          },
          {
            id: 'b2',
            text: 'Это было совсем несложно',
            nextStep: 'Q7/0',
          },
        ],
      },
      ...splitStep({
        id: 'Q7',
        person,
        text: `Не зря по округе ходят слухи о твоей эрудиции! Спасибо! Мы заработали все необходимое для благоустройства парка! 😊

        Теперь можно приступать.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо',
            nextStep: 'Q8',
          },
        ],
      }),
      Q8: {
        id: 'Q8',
        person,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ок',
            nextStep: TEST_TVOI_VIBOR_5.id,
          },
        ],
        text: 'Ответь, пожалуйста, на вопрос. Можешь выбрать несколько вариантов, но не больше пяти.',
      },
      TEST_TVOI_VIBOR_5,
      Q9: {
        id: 'Q9',
        person,
        type: StepType.question,
        maxAnswers: 1,
        text: 'Вот теперь можно приступать к работе по благоустройству. Тебя уже ждут! А как закончишь — возвращайся, у меня есть особенный подарок за твою помощь 😊',
        buttons: [
          {
            id: 'b1',
            text: 'Я мигом!',
            nextStep: 'Q9-1',
          },
        ],
      },
      'Q9-1': {
        id: 'Q9-1',
        person,
        type: StepType.message,
        text: 'Ты пока работай, я скоро вернусь.',
        nextStep: 'Q9-2',
      },
      'Q9-2': {
        id: 'Q9-2',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        text: 'Ты занимаешься благоустройством парка...',
        nextStep: 'Q10',
      },
      Q10: {
        timeout: 15,
        delay: 15000,
        id: 'Q10',
        person,
        type: StepType.message,
        nextStep: 'Q11/0',
        text: `Неужели ты уже [[справился/справилась]]? Дай-ка взглянуть!
        
        Действительно! Как же хорошо стало в парке! Теперь, когда ребятишки вернутся, им точно здесь понравится! 🥰`,
      },
      ...splitStep({
        id: 'Q11',
        person,
        type: StepType.message,
        nextStep: 'Q12/0',
        text: `А за твою помощь я хочу подарить тебе эту ВЕРЕВКУ. Но это непростая веревка.

Однажды я вдруг проснулась ночью от странного шороха на кухне. До тумана у нас в «Большой перемене» все было спокойно, поэтому неизвестные шорохи с кухни были из ряда вон выходящим событием.`,
      }),
      ...splitStep({
        id: 'Q12',
        person,
        type: StepType.message,
        nextStep: 'Q13/0',
        text: `Я тихонько подкралась посмотреть, что происходит 🧐
 
        Никого не оказалось, но, представляешь, моя прелестная кухня была перевернута с ног на голову! Дверь холодильника нараспашку открыта — кто-то стащил оттуда сосиски! Все залито сметаной… Похоже, ночной воришка пытался открыть банку, но не смог и просто разбил! 😱
         
        И весь пол был усыпан маленькими сметанными следами... А рядом лежала эта самая ВЕРЕВКА, покрытая шерстью.`,
      }),
      ...splitStep({
        id: 'Q13',
        person,
        type: StepType.message,
        nextStep: EXIT_ID,
        text: `С нее все и началось, с этой веревки. Именно после той ночи у нас начали твориться странности, а потом и вовсе пришел туман! 🙁

Загадку этой веревки мы так и не смогли разгадать.

Держи, теперь она твоя. Возможно, с ее помощью ты сумеешь докопаться до истины и помочь этому месту приобрести прежний прекрасный вид.`,
      }),
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_STEP]: {
        person,
        id: TO_MAP_STEP,
        type: StepType.to_map_step,
      },
    },
  },
};
