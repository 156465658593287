/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId, StepType, TestStep } from '../../scenario';
import { splitStep } from '../../../utils/ScenarioUtils';
import interlocutors from '../../interlocutors';

const EXIT_ID = 'EXIT';
const TO_MAP_STEP = 'TO_MAP_STEP';
const TO_MAP_STEP_SAVE = 'TO_MAP_STEP_SAVE';

const person = Interlocutors.ENTREPRENEUR;

export const scenario_1_18_0: Scenario = {
  scenarioId: SceneId.scenario_1_18_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Олимпийский парк в Сочи',
    person: Interlocutors.SYSTEM,
    text: `Вы подходите к масштабному макету Олимпийского парка. Перед вами поражающие своими размерами спортивные сооружения.

У входа на стадион стоит Сан Саныч. Вы начинаете с ним разговор...`,
    buttons: ['Начать'],
  },
};

const TEST_TVOI_VIBOR_3: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'TEST_TVOI_VIBOR_3',
  name: 'Твой выбор 3/5',
  nextStep: 'Q19',
  order: [
    {
      description: '',
      subtest: 'T3',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person,
  type: StepType.test,
};

export const scenario_1_18: Scenario = {
  scenarioId: SceneId.scenario_1_18,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Олимпийский парк в Сочи',
    scene_id: 'scenario_1_18',
    startId: 'Q1/0',
    steps: {
      ...splitStep({
        text: `О, здравствуй, {{NAME}}! С башней все так же отлично. Да и первый репортаж мы наконец-то подготовили. Уже пустили в эфир, всем нравится 😊 

А теперь перед нами стоит НОВАЯ ЗАДАЧА — нужно навести здесь порядок после этого странного тумана. Нам же совсем негде заниматься спортом, а спорт — это здоровье 😔`,
        id: 'Q1',
        type: StepType.question,
        maxAnswers: 1,
        callToAction: 'Поможешь?',
        person,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q2',
            text: 'Да, конечно',
          },
        ],
      }),
      Q2: {
        id: 'Q2',
        type: StepType.message,
        nextStep: 'Q3',
        text: 'Мы сейчас в копии Олимпийского парка. Настоящий находится на берегу Черного моря в городе Сочи. ',
        person,
      },
      Q3: {
        id: 'Q3',
        person,
        type: StepType.question,
        maxAnswers: 1,
        text: 'Это очень особенный город, ведь там есть и море, и горы! Летом можно загорать и купаться, а зимой хоть и светит яркое солнце, но снег в горах не тает, и можно кататься на лыжах и сноуборде! 😍',
        buttons: [
          {
            id: 'b1',
            text: 'А я там [[был/была]]',
            nextStep: 'Q4',
          },
          {
            id: 'b2',
            text: 'Наверное, там очень круто отдыхать!',
            nextStep: 'Q7',
          },
        ],
      },
      Q4: {
        id: 'Q4',
        text: 'Как здорово! Тебе там понравилось?',
        person,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q5',
            text: 'Да, было классно!',
          },
          {
            id: 'b2',
            nextStep: 'Q6',
            text: 'Да как-то не очень',
          },
        ],
      },
      Q5: {
        id: 'Q5',
        person,
        nextStep: 'Q8',
        text: 'Там действительно здорово отдыхается 😊',
        type: StepType.message,
      },
      Q6: {
        id: 'Q6',
        person,
        type: StepType.message,
        nextStep: 'Q8',
        text: 'Ну что ж, и такое бывает 🤷‍️',
      },
      Q7: {
        id: 'Q7',
        person,
        type: StepType.message,
        nextStep: 'Q8',
        text: 'Да, там определенно стоит побывать! 😊',
      },
      Q8: {
        id: 'Q8',
        text: 'В общем, именно из-за такого классного климата Сочи был выбран главным городом зимних Олимпийских игр 2014 года.',
        nextStep: 'Q9',
        person,
        type: StepType.message,
      },
      Q9: {
        id: 'Q9',
        text: 'Самое знаменитое сооружение парка — стадион «Фишт», он назван в честь горной вершины Кавказского хребта и построен в форме ракушки. Здесь были проведены потрясающие церемонии, посвященные началу и окончанию игр. Стадион настолько большой, что может вместить в себя население небольшого города!',
        nextStep: 'Q10',
        type: StepType.message,
        person,
      },
      Q10: {
        id: 'Q10',
        text: 'Еще в сочинском олимпийском парке есть арены для соревнований по фигурному катанию, конькобежному спорту, хоккею, керлингу и многим другим видам спорта.',
        type: StepType.question,
        person,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q11/0',
            text: 'А где и когда появились Олимпийские игры?',
          },
          {
            id: 'b2',
            nextStep: 'Q16',
            text: 'Спасибо за интересную информацию, но с чем же надо помочь?',
          },
        ],
      },
      ...splitStep({
        id: 'Q11',
        text: `Первые Олимпийские игры прошли много веков назад в Древней Греции, а участвовать в них могли только мужчины. 

        И по сей день Олимпийские игры известны во всем мире, и спортсмены из разных стран принимают в них участие, чтобы стать олимпийскими чемпионами.`,
        type: StepType.question,
        maxAnswers: 1,
        person,
        buttons: [
          {
            id: 'b1',
            text: 'Я тоже занимаюсь спортом!',
            nextStep: 'Q12',
          },
          {
            id: 'b2',
            text: 'Ничего себе сколько этим играм уже лет!',
            nextStep: 'Q14',
          },
        ],
      }),
      Q12: {
        id: 'Q12',
        text: 'А я сразу понял, что ты [[спортсмен/спортсменка]]! 😍 Какой вид спорта?',
        type: StepType.text_question,
        variable: 'SPORT',
        nextStep: 'Q13',
        person,
      },
      Q13: {
        id: 'Q13',
        text: 'Уважаю спортивных людей! Ты [[большой/большая]] молодец! Как я уже сказал, спорт — это здоровье! 💪',
        person,
        type: StepType.message,
        nextStep: 'Q15',
      },
      Q14: {
        id: 'Q14',
        nextStep: 'Q15',
        person,
        type: StepType.message,
        text: 'Да! Знаешь, спорт не зря всегда был в почете, ведь как я уже сказал, спорт — это здоровье! 💪',
      },
      Q15: {
        person,
        id: 'Q15',
        type: StepType.question,
        maxAnswers: 1,
        text: 'Ну, вернемся к нашей задаче?',
        buttons: [
          {
            id: 'b1',
            text: 'Да, давайте',
            nextStep: 'Q16',
          },
        ],
      },
      ...splitStep({
        text: `Понимаешь, туман и тут наделал дел. Раньше мы проводили различные турниры, состязания, а теперь не то что соревнования не устроить, даже утреннюю пробежку не совершить 😔
        Нужно тут все прибрать и отстроить. Я выделю тебе помощников.`,
        id: 'Q16',
        person,
        type: StepType.message,
        nextStep: 'Q17',
      }),
      Q17: {
        id: 'Q17',
        text: 'Выбери, чем бы ты хотел заняться, а все остальное я поручу другим.',
        type: StepType.question,
        maxAnswers: 1,
        person,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо',
            nextStep: TEST_TVOI_VIBOR_3.id,
          },
        ],
      },
      TEST_TVOI_VIBOR_3,
      Q19: {
        id: 'Q19',
        text: 'Отлично! Спасибо! Благодаря этим ответам, мы подберем занятие, которое придется тебе по вкусу. Ведь делать то, что действительно нравится, вдвойне приятно 😊',
        person,
        nextStep: EXIT_ID,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'И вам спасибо! С радостью займусь!',
            nextStep: 'Q20/0',
          },
        ],
      },
      ...splitStep({
        id: 'Q20',
        text: `О! Я вижу, ты закончил и потрясающе справился с поставленной задачей!

Теперь обитатели «Большой Перемены» снова смогут вести активный образ жизни. Мы все тебе очень благодарны! 😊`,
        nextStep: 'Q21/0',
        person,
        type: StepType.message,
      }),
      ...splitStep({
        id: 'Q21',
        text: `А что это за блестящая штука у тебя в руках? Это же ЛУПА, которую я выронил здесь еще до тумана во время утренней пробежки! 

За твою помощь я ее тебе ДАРЮ! Она может пригодиться тебе в будущем 😉`,
        nextStep: EXIT_ID,
        person,
        type: StepType.message,
      }),
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_STEP]: {
        person,
        id: TO_MAP_STEP,
        type: StepType.to_map_step,
      },
      [TO_MAP_STEP_SAVE]: {
        person,
        id: TO_MAP_STEP_SAVE,
        type: StepType.to_map_step,
      },
    },
  },
};

export const scenario_1_18_1: Scenario = {
  scenarioId: SceneId.scenario_1_18_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.ENTREPRENEUR].name,
    person: Interlocutors.ENTREPRENEUR,
    text: `{{NAME}}, у нас перерыв на зарядку. Если хочешь, тоже разомнись пока.`,
    buttons: ['Закрыть'],
  },
};
