/* eslint-disable max-len */
import { StepType, Interlocutors, TestStep } from '../../../types/scenario';

// TODO: Собрать все тесты связанные с тестом "Твой выбор здесь" - неудобно когда они в файлах где используется, нет возможности поиска

export const TEST_TVOI_VIBOR_2: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'TEST_TVOI_VIBOR_2',
  name: 'Твой выбор 2/5',
  nextStep: 'Q5',
  order: [
    {
      description: '',
      subtest: 'T4',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person: Interlocutors.GRANDMOTHER,
  type: StepType.test,
};
