/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg3_bridgeRussianIsland_1_1_0: Scenario = {
  scenarioId: SceneId.stg3_bridgeRussianIsland_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.PETROV,
    title: 'Мост',
    text: `У нас в парке совсем недавно мост открыли после ремонта! Он тут недалеко. Уверен, с моста будет самый красивый вид на запуск.`,
    buttons: ['Начать'],
  },
};
