/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg3_hermitage_1_1_1: Scenario = {
  scenarioId: SceneId.stg3_hermitage_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Эрмитаж',
    text: `Около здания Эрмитажа ни души. Внутри вы тоже никого не находите. Поблуждав несколько минут по залам с разными картинами, Квилл берёт вас за руку и тянет в зал, над входом в который висит табличка «Авангард».`,
    buttons: ['Начать'],
  },
};
