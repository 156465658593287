import React, { useCallback, useEffect, useMemo } from 'react';

import { selectUserInfo } from '../../redux/user/userGetters';

import { useLocation } from 'react-router';

import FinishPage from '../../pages/FinishPage';

import { Map } from '../../components/Map/Map';
import { GameModal } from '../../components/GameModal';

import { Interlocutors, SceneId } from '../../types/scenario';
import { ID, to } from '../../hooks/scenarioHelpers/ID';
import interlocutors from '../../resources/interlocutors';

import useMountOverflowHidden from '../../../common/shared/hooks/useMountOverflowHidden';
import { useIsFirstStageCompleted } from '../../hooks/stageProgressChecks/useIsFirstStageCompleted';
import { useScenarioHelper } from '../../hooks/scenarioHelpers/useScenarioHelper';
import { useAutorunScenarioEffect } from '../../hooks/scenarioHelpers/useAutorunScenarioEffect';

import { useDispatch, useSelector } from 'react-redux';
import { saveHistoryAsync } from '../../redux/user/userActions';
import { selectScenarios } from '../../redux/user/userGetters';
import { FinishStepType } from '../FinishPage/FinishPageTypes';
import { checkTestsAsync } from '../../redux/tests/testsActions';

export const isScoreCalculated = (user: any) => {
  if (user && user.state && user.state.manualCheckedScore) {
    // проверка на наличие хоть каких-нибудь оценок
    let hasScore = false;

    user?.state?.manualCheckedScore.forEach((score: any) => {
      if (score.value) {
        hasScore = true;
      }
    });

    return hasScore;
  }
};

const calculateAverageScore = (user: any) => {
  if (user && user.state && user.state.manualCheckedScore) {
    let total = 0;
    user?.state?.manualCheckedScore.forEach((score: any) => {
      if (score.value) {
        total += score.value;
      }
    });

    const average = 500 / total;

    return average;
  }
  return -100;
};

export const calculateTotalScore = (user: any) => {
  if (user && user.state && user.state.manualCheckedScore) {
    let total = 0;
    user?.state?.manualCheckedScore.forEach((score: any) => {
      if (score.value) {
        total += score.value;
      }
    });

    return total;
  }
  return null;
};

// текущее время
const curDateInMs = Date.now();
// дата запуска игры
const startGameDateInMs = new Date(2022, 1, 29).getTime(); // the month is 0-indexed
// таймер открытия игры
export const gameIsOpen = startGameDateInMs < curDateInMs ? true : false; // закрыли игру на пару дней

// количество дней до открытия
const daysToOpen = (startGameDateInMs - curDateInMs) / 1000 / 60 / 60 / 24;

/**
 * Какой этап открыть:
 * 0 - всё закрыто
 * 1 - открыт только первый этап
 * 2 - открыт первый и второй этапы
 * 3 - открыты все три этапа
 */
export let openStage = 3;

if (!gameIsOpen) {
  openStage = 0;
}

export const MapPage = () => {
  useMountOverflowHidden();

  const dispatch = useDispatch();
  const isStageCompleted = useIsFirstStageCompleted();
  const { autorunScenario, pointsHandlers, helpMessage } = useScenarioHelper();
  const scenariosState = useSelector(selectScenarios);

  const isCanViewFinishFirstStagePage = false;
  // isStageCompleted && !scenariosState.scenarios?.[to[ID.fictive_StageCompleted]];

  const isCanViewFinishSecondStagePage = false;
  // !scenariosState.scenarios?.[to[ID.fictive_SecondStageCompleted]];

  useAutorunScenarioEffect(
    autorunScenario,
    isCanViewFinishFirstStagePage || isCanViewFinishSecondStagePage,
  );

  const onNextClick = useCallback(() => {
    const id: SceneId = isCanViewFinishSecondStagePage
      ? to[ID.fictive_SecondStageCompleted]
      : to[ID.fictive_StageCompleted];

    dispatch(saveHistoryAsync(id, [], true));
  }, [dispatch, isCanViewFinishSecondStagePage]);

  const onNextClickAfterEnd = useCallback(() => {
    window.open('https://vk.com/bpcontest', '__blank');
  }, []);

  const onCatSuggestionClick = useMemo(() => {
    if (!helpMessage) {
      return undefined;
    }

    return () => {
      GameModal.open(
        interlocutors[Interlocutors.CAT].name,
        helpMessage,
        Interlocutors.CAT,
        [],
        true,
      );
    };
  }, [helpMessage]);

  const scenarios = useSelector(selectScenarios);
  const user = useSelector(selectUserInfo);

  const isGameFinished = scenarios.scenarios?.[to[ID.stg3BridgeRussianIsland]];

  /**
   * закрываем игру на пару дней
   */
  //return <FinishPage finishStep={FinishStepType.end_main_game} onNextClick={onNextClickAfterEnd} />;

  useEffect(() => {
    dispatch(checkTestsAsync());
  }, [scenariosState]);

  console.log(FinishStepType.stage1, isCanViewFinishFirstStagePage);
  if (openStage > 0) {
    // закрыли игру до 14 числа

    let nextStageAvailable = false; // доступен следующий этап

    if (isCanViewFinishFirstStagePage) {
      nextStageAvailable = openStage > 1 ? true : false;
      return (
        <FinishPage
          finishStep={FinishStepType.stage1}
          onNextClick={onNextClick}
          nextStageAvailable={nextStageAvailable}
        />
      );
    }

    if (isCanViewFinishSecondStagePage) {
      nextStageAvailable = openStage > 2 ? true : false;
      return (
        <FinishPage
          finishStep={FinishStepType.stage2}
          onNextClick={onNextClick}
          nextStageAvailable={nextStageAvailable}
        />
      );
    }

    if (isGameFinished) {
      return <FinishPage finishStep={FinishStepType.finish} />;
    }

    return (
      <>
        <Map onCatSuggestionClick={onCatSuggestionClick} clickHandlers={pointsHandlers} />
      </>
    );
  }

  let type = FinishStepType.fail;
  const threshold = 342; // пороговое значение баллов

  // if (user && user.score >= RESERVE_SCORE) {
  //   type = FinishStepType.reserve;
  // }
  // if (user && user.score >= TOP600_SCORE) {
  //   type = FinishStepType.top600;
  // }

  if (isScoreCalculated(user)) {
    //const average = calculateAverageScore(user);
    const total = calculateTotalScore(user);
    // console.log('\n\n====   total   ====\n\n', total);

    // if (total >= RESERVE_SCORE) {
    //   type = FinishStepType.reserve;
    // }
    // if (total >= TOP600_SCORE) {
    //   type = FinishStepType.top600;
    // }

    if (total && total >= threshold) {
      type = FinishStepType.moreThreshold;
      //type = FinishStepType.top600;
    } else {
      type = FinishStepType.lessThreshold;
      //type = FinishStepType.fail;
    }
  }

  // if (!user?.state?.isScoreCalculated) {        // закончился прием конкурсных заданий и тп
  //   type = FinishStepType.competition_finished;
  // }

  return <FinishPage finishStep={type} onNextClick={onNextClickAfterEnd} />;
};
