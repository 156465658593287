// @ts-nocheck
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ErrorPage } from '../../../fifthGrade/pages/ErrorPage';
import { routes } from '../../shared/constants/routes';

export interface ErrorBoundaryState {
  isError?: boolean;
}

class ErrorBoundary extends React.Component<RouteComponentProps, ErrorBoundaryState> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      isError: false,
    };

    this.resetErrorState = this.resetErrorState.bind(this);
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);
    this.setState({ isError: true });
  }

  resetErrorState() {
    this.props.history.push(routes.dashboard);
    this.setState({ isError: false });
  }

  render() {
    if (this.state.isError) {
      return (
        <ErrorPage
          isErrorBoundary
          onClick={this.resetErrorState}
          title='Произошла ошибка'
          btnText='Вернуться на главную'
          description='Упс. Что-то пошло не так, но мы скоро все починим'
        />
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
