/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';
import interlocutors from '../../../interlocutors';

export const lake_1_2: Scenario = {
  scenarioId: SceneId.lake_1_2,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `{{NAME}}, вот и обещанный сюрприз! 🎉

    Мы подготовили твой личный кабинет. Ты можешь создать своего персонажа и посмотреть предметы, которые были собраны в игре! 😺`,
    buttons: ['Далее'],
  },
};
