/* eslint-disable max-len */
import {
  Conditions,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../../types/scenario';
import { getCondition, getError } from '../mgu/scenario_1_14';

const ERUDITION_TEST: TestStep = {
  id: 'ERUDITION_TEST',
  person: Interlocutors.PETROV,
  type: StepType.test,
  external_id: 'test-tvoi-krugozor-junior-t3-210519',
  path: 'test-tvoi-krugozor-junior-t3-210519.json',
  name: 'Эрудиция 1/2',
  order: [
    { timeout: 60, question: 'Q1', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q2', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q4', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q5', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q6', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q10', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q11', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q13', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q14', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q20', subtest: 'T2', description: '' },
  ],
  nextStep: 'Q6',
};

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const innopolice_1_1: Scenario = {
  scenarioId: SceneId.innopolice_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Иннополис',
    scene_id: 'scenario_2_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Петров, наверное, у себя в мастерской. Пойдём — нам нужна вон та дверь.`,
        id: 'Q1',
        person: Interlocutors.CAT, // !!!! TODO заменить на Андроид
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо. На ней, кажется, кодовый замок',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        text: `Да, но тебе повезло — я знаю пароль! 

              Мы с Петровым такие хорошие друзья, что пароль — это мой день рождения!`,
        id: 'Q2',
        person: Interlocutors.CAT, // !!!! TODO заменить на Андроид
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ничего себе! И когда твой день рождения?',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        id: 'Q3',
        person: Interlocutors.CAT,
        type: StepType.text_question,
        text: `Одиннадцатого ноября!`,
        callToAction: '{{NAME}}, вводи скорее код, а то у меня лапки. Всего 4 цифры.',
        nextStep: 'Q3_1',
        variable: 'IS_KEY_VALID',
      },
      ...getCondition('Q3_1', 'Q4', 'ERROR_ONE', Conditions.IS_PASSWORD_IN_INNOPOLICE_VALID),

      ERROR_ONE: {
        id: 'ERROR_ONE',
        text: `Вводишь неправильно. Давай ещё раз попробуем.`,
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо ',
            nextStep: 'Q2',
          },
        ],
      },

      Q4: {
        id: 'Q4',
        text: `Хмм…

        Код правильный, но дверь не открывается.`,
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'И что же нам делать?',
            nextStep: 'Q5',
          },
        ],
      },

      Q5: {
        id: 'Q5',
        text: `Странно, в окнах не горит свет. `,
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, и вокруг нет никого',
            nextStep: 'Q6',
          },
        ],
      },

      Q6: {
        id: 'Q6',
        text: `Похоже и в Иннополисе какая-то мистика творится. Но Петрова нам найти всё равно нужно. 

        Пойдём отсюда. Я, кажется, придумал, как мы будем его искать.`,
        person: Interlocutors.CAT,
        type: StepType.message,
        nextStep: 'Q7',
      },

      Q7: {
        id: 'Q7',
        text: `{{NAME}}, скорее бежим на всех лапах в Эрмитаж! Я знаю как нам найти Петрова!`,
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Побежали, Вжух!',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
