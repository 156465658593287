/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg2_olympicPark_1_1: Scenario = {
  scenarioId: SceneId.stg2_olympicPark_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Олимпийский Парк',
    scene_id: 'stg2_olympicPark_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.CAT,
        text: `Напряженная игра! Но кого бы нам расспросить про существо?`,
        id: 'Q1',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Давай не будем никого отвлекать от игры. Поищем следы сами!',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        text: `Вы обходите стадион по кругу и натыкаетесь на небольшие следы фиолетовой слизи на траве.`,
        id: 'Q2',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q3',
      },
      Q3: {
        text: `Надо же, совсем как та, что анализирует Петров! 

        Интересно, это свежие следы или вы тогда плохо убрались? 😉`,
        id: 'Q3',
        person: Interlocutors.CAT,
        type: StepType.question,
        nextStep: 'Q4',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Понятия не имею. Но это лучше, чем ничего. Пойдём искать дальше!',
            nextStep: 'Q4',
          },
        ],
      },

      Q4: {
        text: `На стене стадиона вы обнаруживаете блестящую табличку. На ней выгравирован текст.`,
        id: 'Q4',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q5',
      },

      Q5: {
        text: `«Стадион “Фишт” назван в честь горной вершины Кавказского хребта и построен в форме ракушки. Здесь были проведены церемонии, посвященные началу и окончанию зимних Олимпийских игр 2014 года в Сочи. Вместимость стадиона — более 40 тысяч человек.»`,
        id: 'Q5',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        nextStep: 'Q6',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ого, повезло, что хоть по этой табличке подготовились к викторине! ',
            nextStep: 'Q6',
          },
        ],
      },

      Q6: {
        text: `Да, всё повторили.

        Конца матча дожидаться нет времени, поэтому пойдем дальше.`,
        id: 'Q6',
        person: Interlocutors.CAT,
        type: StepType.message,
        nextStep: EXIT_ID,
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
