/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../scenario';

export const scenario_2_12: Scenario = {
  scenarioId: SceneId.scenario_2_12,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Новости парка',
    person: Interlocutors.SYSTEM,
    text: `Поздравляем! Вы нашли все детали от тепловой пушки.`,
    buttons: ['Закрыть'],
  },
};
