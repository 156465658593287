/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg3_spaceMeetingWithAliens_1_1_1: Scenario = {
  scenarioId: SceneId.stg3_spaceMeetingWithAliens_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Космодром',
    text: `Вместе со всеми своими друзьями из парка вы подходите к космодрому. 

    Ветра на космодроме нет, ракета всё ещё на месте, а в небе вы видите зелёное свечение, сильнее чем раньше. Оно подсказывает, что корабль пришельцев опустился ближе к земле. 
    
    Кажется, пришельцы с нетерпением ждут момента, чтобы подобрать Квилла.`,
    buttons: ['Начать'],
  },
};
