/* eslint-disable max-len */
import {
  Action,
  Conditions,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';
import { LOGIC_TEST_PART2 } from '../../tests/logicTestPart2';

export const scenario_3_16_0: Scenario = {
  scenarioId: SceneId.scenario_3_16_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Парк «Патриот»',
    text: 'Вы находитесь в точной копии парка «Патриот». Глаза разбегаются от обилия различной военной техники.',
    buttons: ['Продолжить'],
  },
};

const EXIT_ID = 'EXIT_ID';

export const scenario_3_16: Scenario = {
  scenarioId: SceneId.scenario_3_16,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Парк «Патриот»',
    scene_id: 'scenario_3_16',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q1',
        text: `Вот это простор, [[согласен/согласна]], {{NAME}}? Уверен, что в этой достопримечательности ты еще не [[бывал/бывала]].`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, я здесь впервые. А где мы?',
            nextStep: 'Q2',
          },
          {
            id: 'b2',
            text: 'Впервые вижу подобное место. Перед нами настоящая военная техника?',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q2',
        text: 'Мы находимся в военно-патриотическом парке культуры и отдыха Вооруженных Сил Российской Федерации «Патриот». Здесь у нас полномасштабная копия, а настоящий парк «Патриот» открылся 16 июня 2015 года в Подмосковье.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А  почему парк называется «Патриот»?',
            nextStep: 'Q3/0',
          },
        ],
      },
      ...splitStep({
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q3',
        text: `Свое название парк получил неслучайно: здесь все пронизано патриотизмом. Здесь есть музей авиации, музей бронетанковой техники, музей артиллерии, спортивные тренажеры, исторические выставки, экспозиции образцов вооружений и техники. 

Но самое интересное это то, что здесь можно не только смотреть на экспонаты, но и поездить, полетать на военной технике, пострелять из боевого оружия, прыгнуть с парашютом! Хочешь покататься на танке, {{NAME}}?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ух ты! Как здесь интересно! Я бы и не против прокатиться, но сейчас у нас есть задачи поважнее! ',
            nextStep: 'Q4',
          },
          {
            id: 'b2',
            text: 'Вот это да! Даже не [[знал/знала]], что в России такое есть. Это очень интересно, но давайте не будем забывать про нашу миссию и поищем Вашего друга.',
            nextStep: 'Q4',
          },
        ],
      }),
      Q4: {
        person: Interlocutors.SYSTEM,
        id: 'Q4',
        type: StepType.message,
        text: `К вам подходит смотритель достопримечательности. Он сообщает, что начальник парка «Патриот» сейчас занят на учениях и не может лично вас поприветствовать. Смотритель передает письмо от начальника Степанова. Сан Саныч читает послание...`,
        nextStep: 'Q5/0',
      },
      ...splitStep({
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q5',
        callToAction: 'Что думаешь, {{NAME}}?',
        text: `Смотрите-ка, Степанов — настоящий выдумщик. По пути я успел созвониться с ним и выяснил, что у него как раз есть новенький ненужный стоп-кран от военного поезда. А в записке он пишет, что если мы хотим заполучить этот стоп-кран, надо пройти полосу препятствий из головоломок! 

Вот это стратег! Похоже, нам придется разгадать его головоломки.
`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: '[[Согласен/Согласна]] с Вами Сан Саныч. Большая удача, что у Вашего друга есть стоп-кран. Мы не можем его упустить. Давайте решать!',
            nextStep: 'LOGIC_TEST_PART2_1/0',
          },
          {
            id: 'b2',
            text: 'Я всегда [[рад/рада]] размять мозги интересными задачками!',
            nextStep: 'LOGIC_TEST_PART2_1/0',
          },
        ],
      }),

      ...LOGIC_TEST_PART2,
      Q6: {
        id: 'Q6',
        person: Interlocutors.SYSTEM,
        type: StepType.system,
        condition: {
          type: Conditions.IS_LOGIC_PART2_HAS_ANSWERS,
          variants: ['Q7', 'Q8'],
        },
        _meta: {},
      },

      Q7: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q7',
        text: 'Кажется, ты [[справился/справилась]] со всеми головоломками 😊',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да уж, головоломки Вашего друга были нелегкими!',
            nextStep: 'Q9',
          },
          {
            id: 'b2',
            text: 'Ничего сложного! Как орешки щелкать!',
            nextStep: 'Q9',
          },
        ],
      },

      ...splitStep({
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q8',
        text: `Да уж, похоже Степанов немного перестарался с головоломками… 🙁️

Ну ничего, в письме есть дополнительный вопрос! Давай-ка посмотрим, что там?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Давайте',
            nextStep: 'Q8-1',
          },
        ],
      }),
      'Q8-1': {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q8-1',
        text: 'Что такое древнегреческий полис?',
        type: StepType.question,
        callToAction: 'Выбери ответ',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Город с особой формой общественного устройства, где все граждане имели определенные права и обязанности',
            nextStep: 'Q8-1-1',
          },
          {
            id: 'b2',
            text: 'Документ, по которому древние греки могли получить бесплатное лечение в больнице',
            nextStep: 'Q8-1-2',
          },
          {
            id: 'b3',
            text: 'Так в древней Греции называли полицейских',
            nextStep: 'Q8-1-3',
          },
        ],
      },
      'Q8-1-1': {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q8-1-1',
        text: 'Совершенно верно! Ты очень [[умный/умная]]!',
        type: StepType.message,
        nextStep: 'Q9',
      },
      'Q8-1-2': {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q8-1-2',
        text: 'Увы, нет. Подумай еще.',
        type: StepType.question,
        callToAction: 'Выбери ответ',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Город с особой формой общественного устройства, где все граждане имели определенные права и обязанности',
            nextStep: 'Q8-1-1',
          },
          {
            id: 'b3',
            text: 'Так в древней Греции называли полицейских',
            nextStep: 'Q8-1-3',
          },
        ],
      },
      'Q8-1-3': {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q8-1-3',
        text: 'Оригинальный вариант, но, к сожалению, неверный.',
        type: StepType.question,
        callToAction: 'Выбери ответ',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Город с особой формой общественного устройства, где все граждане имели определенные права и обязанности',
            nextStep: 'Q8-1-1',
          },
          {
            id: 'b2',
            text: 'Документ, по которому древние греки могли получить бесплатное лечение в больнице',
            nextStep: 'Q8-1-3',
          },
        ],
      },
      Q9: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q9',
        type: StepType.message,
        text: `Смотри-ка, смотритель парка «Патриот» снова идет к нам.`,
        nextStep: 'Q10/0',
      },
      ...splitStep({
        person: Interlocutors.SYSTEM,
        id: 'Q10',
        action: Action.GiveStop,
        type: StepType.message,
        text: `К вам снова подходит смотритель и передает, что начальник парка «Патриот» рад поделиться стоп-краном с таким отважным и сообразительным человеком, как вы. 

Сан Саныч берет у смотрителя стоп-кран и передает вам на хранение.`,
        nextStep: 'Q11/0',
      }),
      ...splitStep({
        person: Interlocutors.CAT,
        id: 'Q11',
        type: StepType.message,
        text: `Это же целый стоп-кран! 😻

Теперь нам можно не доставать рычаг стоп-крана из дым-машины, а оставить частью ее механизма. И мы можем отправиться в поезд и заменить стоп-кран на новый! 😺`,
        nextStep: 'Q12',
      }),
      Q12: {
        person: Interlocutors.PETROV,
        id: 'Q12',
        type: StepType.message,
        text: `Пойдемте же скорее к поезду и починим его уже наконец!`,
        nextStep: EXIT_ID,
      },

      [EXIT_ID]: {
        person: Interlocutors.TEACHER,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
