import { Step, StepType, Interlocutors, SystemStepType } from '../../../types/scenario';
import { splitStep } from '../../../utils/ScenarioUtils';

// ТЕСТ: КРЕАТИВНОСТЬ 3 чай

export const CREATIVE_TEST_3: { [id: string]: Step } = {
  CREATIVE_TEA: {
    id: 'CREATIVE_TEA',
    person: Interlocutors.CAT,
    delay: 7000,
    type: StepType.message,
    text: `Почему люди выбрасывают пустые коробки?! Ведь из них можно сделать много интересного! Придумай как можно интересно и необычно использовать пустые коробки – любого размера и в любом количестве. Не ограничивай свою фантазию тем , что ты когда-то слышал или видел. Придумай столько способов использования коробок, сколько ты сможешь, и запиши их в полях для ответов ниже.`,
    nextStep: 'CREATIVE_TEA_1',
  },
  CREATIVE_TEA_1: {
    person: Interlocutors.CAT,
    id: 'CREATIVE_TEA_1',
    type: StepType.message,
    text: `Ну все, время пошло! Пиши свои варианты!`,
    nextStep: 'CREATIVE_TEA_2',
  },
  CREATIVE_TEA_2: {
    id: 'CREATIVE_TEA_2',
    person: Interlocutors.CAT,
    type: StepType.system,
    _meta: {
      type: SystemStepType.limitedTimeInput,
      time: 10 * 60,
      maxAnswers: 15,
      exitStep: 'Q7',
    },
    nextStep: 'CREATIVE_TEA_3',
  },
  CREATIVE_TEA_3: {
    id: 'CREATIVE_TEA_3',
    person: Interlocutors.CAT,
    text: '',
    type: StepType.text_question,
    variable: 'CREATIVE_TEA',
    nextStep: 'CREATIVE_TEA_3',
  },
};
