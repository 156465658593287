/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId, StepType } from '../../../scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

export const scenario_2_20: Scenario = {
  scenarioId: SceneId.scenario_2_20,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: 'Новости парка',
    person: Interlocutors.SYSTEM,
    text: `Ура! Благодаря запуску ракеты и бесперебойной работе тепловой пушки всё окончательно растаяло и наступила весна!
Обитатели парка радостно готовятся к торжественному параду в честь Дня Победы.`,
    buttons: ['Закрыть'],
  },
};

const person = Interlocutors.CAT;
const EXIT_ID = 'EXIT_ID';

export const scenario_2_20_1: Scenario = {
  scenarioId: SceneId.scenario_2_20_1,
  type: ScenarioType.chat,
  data: {
    title: 'Парк "Большая перемена"',
    scene_id: 'scenario_2_20_1',
    startId: 'Q0/0',
    steps: {
      ...splitStep({
        id: 'Q0',
        person,
        text: `Привет, {{NAME}}! Обитатели «Большой перемены» потихоньку возвращаются в парк к своим привычным занятиям. Пушка окончательно растопила лед, а солнышко сияет на небе каждый день. Все начинает расцветать и преображаться! 😻

Кстати, у нас тут праздник на носу! Все активно готовятся к параду в честь 9 Мая. Ты же знаешь, что это за праздник?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Конечно! Это праздник победы в Великой Отечественной войне!',
            nextStep: 'Q1/0',
          },
        ],
      }),
      ...splitStep({
        id: 'Q1',
        person,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Пойдем!',
            nextStep: EXIT_ID,
          },
        ],
        text: `Я в тебе не сомневался! Ты настоящий знаток истории нашей страны 😻

        Кстати, 9 мая у нас будет проходить большой парад на Мамаевом кургане. Пойдем, я покажу тебе где это.`,
        nextStep: 'Q2/0',
      }),
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
