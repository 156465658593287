/* eslint-disable max-len */
import {
  Step,
  SceneId,
  StepType,
  Scenario,
  ScenarioType,
  Interlocutors,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const scenario_2_7_i: Scenario = {
  scenarioId: SceneId.scenario_2_7_i,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'ЮНЕСКО (Байкал)',
    text: `Вы подходите к достопримечательности «Природные достояния России» и полной грудью вдыхаете прекрасный свежий воздух! 

    На первый взгляд ничего необычного тут не происходит. 
    
    У берега озера стоит задорная старушка и кормит белок орешками. Вы начинаете с ней разговор...`,
    buttons: ['Найти дорогу к озеру'],
  },
};

export const scenario_2_7: Scenario = {
  scenarioId: SceneId.scenario_2_7,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    scene_id: 'scenario_2_7',
    startId: 'Q1',
    title: 'ЮНЕСКО (Байкал)',
    steps: {
      Q1: {
        text: `Вы смотрите на карту, вам необходимо определить местоположение озера Байкал. 

          Выберите верные координаты.
          `,
        id: 'Q1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: '31°29′27″ с. ш. 35°28′47″ в. д.',
            nextStep: 'Q1_1',
          },
          {
            id: 'b2',
            text: '53°13′00″ с. ш. 107°45′00″ в. д.',
            nextStep: 'Q1_2',
          },
          {
            id: 'b3',
            text: '55°28′56″ с. ш. 35°27′10″ в. д.',
            nextStep: 'Q1_3',
          },
        ],
      },
      Q1_1: {
        text: `Упс, кажется это Азовское море. Это, конечно, водоём, но не Байкал. Попробуй ещё раз.`,
        id: 'Q1_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Вернуться к карте',
            nextStep: 'Q1',
          },
        ],
      },
      Q1_3: {
        text: `Москва-река. Тут вроде бы даже водятся раки, но до Байкала водам Москвы-реки далеко. Попробуй ещё раз.`,
        id: 'Q1_3',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Вернуться к карте',
            nextStep: 'Q1',
          },
        ],
      },
      Q1_2: {
        text: `Ура, вы с Вжухом на Байкале. Теперь нужно расставить палатку на ночь.`,
        id: 'Q1_2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Поставить палатку',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        text: `Выбери правильное место и алгоритм установки палатки.`,
        id: 'Q2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Поставить палатку у берега, натянуть пол палатки на колышки, затем вставить в палатку стойки, развернув, забить в землю колышки и натянуть палатку на них.',
            nextStep: 'Q2_1',
          },
          {
            id: 'b2',
            text: 'Поставить палатку у деревьев, натянуть пол палатки на колышки, затем вставить в палатку стойки, развернув, привязать края палатки к деревьям.',
            nextStep: 'Q2_2',
          },
          {
            id: 'b3',
            text: 'Поставить палатку под горкой, вставить в палатку стойки, развернув, забить в землю колышки и натянуть палатку на них.',
            nextStep: 'Q2_3',
          },
        ],
      },
      Q2_1: {
        text: `Вы верно установили палатку, но неправильно выбрали место, с озера Байкал дуют сильнейшие ветра, лучше выбрать более закрытое место для ночлега.`,
        id: 'Q2_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Попробовать ещё раз',
            nextStep: 'Q2',
          },
        ],
      },
      Q2_3: {
        text: `Вы не развернули пол палатки и поставили её под горку. Чтобы ночью на вас сверху не посыпались камешки от ветра, лучше выбрать более подходящее место для ночлега.`,
        id: 'Q2_3',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Попробовать ещё раз',
            nextStep: 'Q2',
          },
        ],
      },
      Q2_2: {
        text: `Отлично! Здесь можно уютно заночевать: деревья прикрывают вас от ветра, палатка натянута и установлена верно.`,
        id: 'Q2_2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться спать',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        // ТЕСТ НА АЛЬТРУИЗМ 5
        text: `Вы ложитесь спать, но вдруг до вас доносится какой-то отдаленный писк. Может быть кому-то нужна помощь?`,
        id: 'Q3',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Найти источник писка',
            nextStep: 'Q4',
          },
          {
            id: 'b2',
            text: 'Завернуться в спальный мешок и продолжить спать',
            nextStep: 'Q6',
          },
        ],
      },
      Q4: {
        // ТЕСТ НА АЛЬТРУИЗМ 6
        text: `Вы идете на звук и видите Байкальскую нерпу, запутавшуюся в сетях. Нерпа смотрит на вас большими как блюдца глазами. Надо ей помочь.`,
        id: 'Q4',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Я тут ничем не помогу. Уйти.',
            nextStep: 'Q6',
          },
          {
            id: 'b2',
            text: 'Надо позвать лесничего, сам я не справлюсь.',
            nextStep: 'Q4_1',
          },
          {
            id: 'b3',
            text: 'Распутать сети самостоятельно.',
            nextStep: 'Q4_2',
          },
        ],
      },
      Q4_1: {
        text: `Ты зовёшь на помощь. Очевидно, лесничего тут нет, ты с отчаянием смотришь на нерпу. Вдруг из воды появляется существо, похожее на человека, но с жабрами и большими глазами, ростом в 3 метра. Оно распутывает нерпу, кивает тебе головой и уходит обратно в воду.`,
        id: 'Q4_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Подойти к нерпе',
            nextStep: 'Q5',
          },
        ],
      },
      Q4_2: {
        text: `Ты достаешь из кармана швейцарский нож и режешь сети, опутывающие нерпу. Вжух клыками и когтями раздирает веревки. После продолжительных усилий нерпа освобождена.`,
        id: 'Q4_2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Подойти к нерпе',
            nextStep: 'Q5',
          },
        ],
      },
      Q5: {
        text: `Вы со Вжухом подходите к нерпе и она неожиданно говорит: «Спасибо. Мы, Байкальские нерпы, не подпускаем людей к себе. Только Ихтиандров! Возвращайся на Байкал и, когда-нибудь, я помогу тебе». Сказава это, нерпа уплывает.`,
        id: 'Q5',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Двигаться дальше',
            nextStep: 'Q6',
          },
        ],
      },
      Q6: {
        text: `Вы прощаетесь с Байкалом: глубокие прозрачные воды, мистические столбы и скалистые пещеры остаются позади. Кругобайкальская железная дорога, а затем Транссибирская магистраль мчат вас на восток. 

          Наконец, вы прибываете к следующей точке вашего маршрута, мосту, ведущему на остров Русский.
          `,
        id: 'Q6',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Осмотреть мост',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
