import { Interlocutors, StepType, TestStep } from '../../../types/scenario';

// TODO: Собрать все тесты связанные с тестом "Твой вектор здесь" - неудобно когда они в файлах где используется, нет возможности поиска

export const TEST_TVOI_KOD_3: TestStep = {
  id: 'START_TEST_TVOI_KOD_3',
  person: Interlocutors.SYSTEM,
  type: StepType.test,
  external_id: 'test-tvoi-kod-junior',
  path: 'test-tvoi-kod-junior-210401.json',
  name: 'Твой Код 3/3',
  order: [
    {
      description: ``,
      subtest: 'T2',
      question: 'Q2',
    },
  ],
  nextStep: 'Q4',
};
