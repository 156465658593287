import styled, { keyframes } from 'styled-components';

import colors from '../../../common/shared/constants/colors';
import SpriteStar from '../../../assets/images/onboarding_sprite_star.svg';

import {
  BREAKPOINT_PHONE,
  BREAKPOINT_TABLET,
  BREAKPOINT_LAPTOP,
  BREAKPOINT_SMALL_PHONE,
} from '../../../common/shared/constants/constants';

interface WithPageNumProps {
  $pageNum?: number;
}

const fadeInAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const OnboardingRight = styled.div``;

export const OnboardingRightStep1 = styled(OnboardingRight)`
  width: 90%;
  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    width: 100%;
  }
`;

export const OnboardingRightStep2 = styled(OnboardingRight)`
  width: 110%;

  @media screen and (max-width: ${BREAKPOINT_LAPTOP}) {
    width: 130%;
    margin-left: -15%;
  }
  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    width: 100%;
    margin-left: auto;
  }
`;

export const OnboardingRightStep3 = styled(OnboardingRight)`
  max-width: 100%;
  position: relative;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    right: -20%;
  }
  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    right: auto;
    width: 70%;
  }
  @media screen and (max-width: ${BREAKPOINT_SMALL_PHONE}) {
    width: 53%;
  }
`;

export const OnboardingRightStep4 = styled(OnboardingRight)`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: -125%;
  max-height: 120%;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    display: none;
  }
`;

export const OnboardingPic = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  object-fit: contain;
  animation: ${fadeInAnimation} 1s;
`;

export const OnboardingPic3 = styled(OnboardingPic)`
  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    right: -210px;
    position: relative;
  }

  @media screen and (max-width: ${BREAKPOINT_SMALL_PHONE}) {
    right: -210px;
    position: relative;
  }
`;

const starAnimation = keyframes`
  0% {
    transform: translateX(0px) translateY(0px) rotate(0deg) scale(1,1);
  }
  50% {
    transform: translateX(0px) translateY(0px) rotate(0deg) scale(1,1);
  }
  51% {
    transform: translateX(0px) translateY(0px) rotate(0deg) scale(1.1,1.1);
  }
  99% {
    transform: translateX(0px) translateY(0px) rotate(0deg) scale(1.1,1.1);
  }
  100% {
    transform: translateX(0px) translateY(0px) rotate(0deg) scale(1,1);
  }
`;

export const OnboardingStar = styled.img`
  display: block;
  position: absolute;
  max-width: 128px;
  animation: ${starAnimation} 2s infinite linear;
  backface-visibility: hidden;

  @media screen and (max-width: ${BREAKPOINT_LAPTOP}) {
    top: -150px;
  }

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    max-width: 85px;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    max-width: 68px;
    top: 0;
    left: 30px;
  }

  @media screen and (max-width: ${BREAKPOINT_SMALL_PHONE}) {
    left: -30px;
  }
`;

export const OnboardingStarR = styled(OnboardingStar)`
  right: 0;
  top: -50px;
  animation-duration: 4s;

  @media screen and (max-width: ${BREAKPOINT_LAPTOP}) {
    right: -30px;
  }

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    max-width: 85px;
    right: 0;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    max-width: 32px;
    top: 80px;
    right: 30px;
    left: unset;
  }

  @media screen and (max-width: ${BREAKPOINT_SMALL_PHONE}) {
    right: -20px;
  }
`;

export const OnboardingStarB = styled(OnboardingStar)`
  left: 20px;
  top: 60%;
  max-width: 78px;
  animation-duration: 4s;

  @media screen and (max-width: ${BREAKPOINT_LAPTOP}) {
    left: -30px;
  }

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    max-width: 85px;
    right: 0;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    max-width: 32px;
    left: 30px;
  }

  @media screen and (max-width: ${BREAKPOINT_SMALL_PHONE}) {
    left: 0;
  }
`;

const cloudAnimation = keyframes`
  0% {
    transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
  }
  50% {
    transform: translateX(0px)translateY(-10px)rotate(0deg)scale(1,1);
  }
  100% {
    transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
  }
`;

const OnboardingCloud = styled.img`
  display: block;
  position: absolute;
  max-width: 184px;
  animation: ${cloudAnimation} 3.2s infinite linear;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    max-width: 98px;
  }
`;
export const OnboardingCloud1 = styled(OnboardingCloud)`
  top: 10%;
  left: -100px;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    left: 0;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    max-width: 79px;
    left: unset;
    right: -40px;
    top: 20%;
  }
  @media screen and (max-width: ${BREAKPOINT_SMALL_PHONE}) {
    max-width: 50px;
  }
`;
export const OnboardingCloud2 = styled(OnboardingCloud)`
  top: 55%;
  left: -100px;
  max-width: 153px;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    max-width: 58px;
    left: 0;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    max-width: 33px;
  }
`;
export const OnboardingCloud3 = styled(OnboardingCloud)`
  top: 40%;
  right: -50px;
  max-width: 112px;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    max-width: 73px;
    right: 0;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    top: 60%;
    right: -70px;
    max-width: 47px;
  }
`;

export const OnboardingFormWrapper = styled.div`
  position: relative;
  width: 460px;

  height: 100%;
  display: flex;
  align-items: flex-end;

  @media screen and (max-width: ${BREAKPOINT_LAPTOP}) {
    width: 340px;
  }
`;

export const OnboardingPageLeft = styled.div<WithPageNumProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 90px 100px;
  z-index: 10;

  @media screen and (max-width: ${BREAKPOINT_LAPTOP}) {
    margin: 50px;
  }

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    margin: 30px;

    ${(props) =>
      props.$pageNum === 3 &&
      `
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    `}
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    margin: 30px 0;
  }
`;

export const OnboardingPageRight = styled.div<WithPageNumProps>`
  position: absolute;
  max-width: 35%;
  max-height: 90%;
  right: 10%;
  bottom: 0;

  ${(props) =>
    props.$pageNum === 3 &&
    `
    top: 0;
    height: 45%;
    max-height: 45%;
  `}

  @media screen and (max-width: ${BREAKPOINT_LAPTOP}) {
    right: 5%;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    max-width: 90%;
    max-height: 55%;
    right: auto;
    left: 5%;
  }
  @media screen and (max-width: ${BREAKPOINT_SMALL_PHONE}) {
    max-width: 70%;
    max-height: 50%;
    left: 15%;
  }

  ${(props) =>
    props.$pageNum === 3 &&
    `
    @media screen and (max-width: ${BREAKPOINT_TABLET}) {
      display: none;
    }
  `}
`;

export const OnboardingContent = styled.div<WithPageNumProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  animation: ${fadeInAnimation} 1.5s;

  max-width: 54%;

  z-index: 10;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    max-width: 64.5%;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    max-width: 100%;
    justify-content: flex-start;
    padding-top: 26px;
    max-height: 75vh;

    ${(props) =>
      props.$pageNum === 3 &&
      `
      text-align: left;
    `}
  }
`;

export const OnboardingButtonWrap = styled.div`
  display: flex;
  margin: 0 -15px;

  button {
    width: auto;
    max-width: 230px;
    margin: 0 15px;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    margin: 0 -5px;
    button {
      width: 100%;
      max-width: 100%;
      min-width: unset;
      margin: 15px 5px 0;
      font-size: 16px;
    }
  }
`;

export const OnboardingPaginationWrapper = styled.div<WithPageNumProps>`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    ${(props) => props.$pageNum === 3 && 'display: none;'}
  }
`;
export const OnboardingPaginationEmpty = styled.div`
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid ${colors.text};
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    width: 14px;
    height: 14px;
  }
`;
export const OnboardingPaginationFilled = styled(OnboardingPaginationEmpty)`
  background: ${colors.text};
`;

export const OnboardingList = styled.ul`
  display: block;
  margin-top: 50px;
  max-width: 514px;

  li {
    display: flex;
    margin-bottom: 36px;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      display: block;
      position: relative;
      content: '';
      top: 3px;
      width: 30px;
      height: 30px;
      min-width: 30px;
      background: url('${SpriteStar}') no-repeat center;
      background-size: contain;
      margin-right: 20px;
    }
  }

  @media screen and (max-width: ${BREAKPOINT_LAPTOP}) {
    margin-top: 30px;

    li {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 16px;

      &:before {
        width: 24px;
        height: 24px;
      }
    }
  }

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    max-width: 348px;
    margin-left: auto;
    margin-right: auto;

    li {
      font-size: 15px;
      line-height: 1.5;
      margin-bottom: 24px;
      text-align: left;

      &:before {
        top: 0;
      }
    }
  }
`;

export const OnboardingMainTitle = styled.div`
  font-size: 56px;
  font-weight: 900;
  line-height: 68px;
  margin-bottom: 30px;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    font-size: 36px;
    line-height: 44px;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: ${BREAKPOINT_SMALL_PHONE}) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const OnboardingFormText = styled.div`
  font-size: 24px;
  line-height: 1.5;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    font-size: 15px;
  }
`;

export const HiddenOnTablet = styled.div`
  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    display: none;
  }
`;
export const MobileVideoForm = styled.div`
  display: none;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    display: block;
    margin: 30px auto 0;
    width: 378px;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    width: 100%;
  }
`;

export const OboardingVideoProblems = styled.div`
  margin-top: 14px;
`;
