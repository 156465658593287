/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_lake_1_1_1: Scenario = {
  scenarioId: SceneId.stg2_lake_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Байкал',
    text: `Вы видите Бабулю, которая мирно отдыхает на берегу озера. Как будто, с вашего последнего визита ничего не изменилось.`,
    buttons: ['Продолжить'],
  },
};
