import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { selectScenarios } from '../../redux/user/userGetters';
import { notStage2Act2Announced } from '../scenarioHelpers/stageChecks';
import { s2a1RequiredScenarios } from './requiredScenarios';

export function useIsSecondStageFirstActCompleted() {
  const scenarioState = useSelector(selectScenarios);
  return useMemo(() => {
    if (!scenarioState) {
      return false;
    }
    for (let i = 0; i < s2a1RequiredScenarios.length; i++) {
      for (let j = 0; j < s2a1RequiredScenarios[i].length; j++) {
        if (!scenarioState.scenarios[s2a1RequiredScenarios[i][j]]) {
          return false;
        }
      }
    }
    return true;
  }, [scenarioState]);
}
