/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../scenario';

export const scenario_2_16: Scenario = {
  scenarioId: SceneId.scenario_2_16,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Новости парка',
    person: Interlocutors.SYSTEM,
    text: `Поздравляем! Вы успешно прошли все испытания. «Большая перемена» начала активно размораживаться и совсем скоро примет прежний облик.

    Новые приключения будут ждать вас позже!`,
    buttons: ['Закрыть'],
  },
};

export const scenario_2_16_1: Scenario = {
  scenarioId: SceneId.scenario_2_16_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Мост на остров Русский',
    person: Interlocutors.TEACHER,
    text: `Рабочие давно закончили работу и отправились по домам. Новых заданий для тебя нет.`,
    buttons: ['Закрыть'],
  },
};

export const scenario_2_16_2: Scenario = {
  scenarioId: SceneId.scenario_2_16_2,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Поезд в «Артек»',
    person: Interlocutors.CAT,
    text: `А здесь никого нет...`,
    buttons: ['Закрыть'],
  },
};

export const scenario_2_16_3: Scenario = {
  scenarioId: SceneId.scenario_2_16_3,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Иннополис',
    person: Interlocutors.CAT,
    text: `Здесь никого нет. Все ждут окончательной разморозки парка.`,
    buttons: ['Закрыть'],
  },
};

export const scenario_2_16_4: Scenario = {
  scenarioId: SceneId.scenario_2_16_4,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Москва-Сити',
    person: Interlocutors.ENTREPRENEUR,
    text: `Дела идут своим чередом. Здесь для тебя заданий на данный момент нет.`,
    buttons: ['Закрыть'],
  },
};

export const scenario_2_16_5: Scenario = {
  scenarioId: SceneId.scenario_2_16_5,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Космодром',
    person: Interlocutors.PETROV,
    text: `Ракета и пушка делают свое дело! «Большая перемена» отогревается! Пока новых заданий больше нет.`,
    buttons: ['Закрыть'],
  },
};

export const scenario_2_16_0_5: Scenario = {
  scenarioId: SceneId.scenario_2_16_0_5,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Космодром',
    person: Interlocutors.CAT,
    text: `Тут пока никого нет, все куда-то разбрелись. Посмотри в других местах.`,
    buttons: ['Закрыть'],
  },
};

export const scenario_2_16_1_5: Scenario = {
  scenarioId: SceneId.scenario_2_16_1_5,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Космодром',
    person: Interlocutors.PETROV,
    text: `Ракета и пушка отлично справляются! Классно мы с тобой придумали, {{NAME}}! Твоя помощь здесь пока что не нужна 😉`,
    buttons: ['Закрыть'],
  },
};

export const scenario_2_16_1_3: Scenario = {
  scenarioId: SceneId.scenario_2_16_1_3,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Космодром',
    person: Interlocutors.PETROV,
    text: `{{NAME}}, тебе сюда пока рановато. Изучи хорошенько карту и возвращайся 😉`,
    buttons: ['Закрыть'],
  },
};
