// eslint-disable @typescript-eslint/no-non-null-assertion

import React from 'react';

import { Text } from '../../common/shared/ui/Text';
import { Button } from '../../common/shared/ui/Button';
import { NewModal } from '../../common/shared/ui/NewModal';
import ChatAnswerRadio from './chat/InputToolbar/ChatAnswerRadio';

import { Question, QuestionButton } from '../types/scenario';
import { OptionButton } from './chat/InputToolbar/InputToolbar';
import { ChatButtonStyled } from './chat/InputToolbar/InputToolbarStyled';

import styled from 'styled-components';
import colors from '../../common/shared/constants/colors';

const TitleWrapperStyled = styled.div`
  position: relative;
`;

const DividerStyled = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  background: ${colors.grey210};
`;

const defaultState: InputToolbarModalState = {
  options: [],
  lastStep: undefined,

  selectedButtons: [],
  isOpen: false,
  onSubmit: undefined,
};

interface InputToolbarModalProps {}

interface InputToolbarModalState {
  isOpen: boolean;
  selectedButtons: QuestionButton[];

  lastStep?: Question;
  options: OptionButton[];
  onSubmit?: (buttons: QuestionButton[]) => void;
}
export class InputToolbarModal extends React.Component<
  InputToolbarModalProps,
  InputToolbarModalState
> {
  static instance?: InputToolbarModal;

  static close() {
    InputToolbarModal.instance?.close();
  }

  close = () => {
    this.setState({ isOpen: false });
  };

  static open(
    lastStep: Question,
    options: OptionButton[],
    onSubmit: (selectedButtons: QuestionButton[]) => void,
  ) {
    InputToolbarModal.instance?.open(lastStep, options, onSubmit);
  }

  open(
    lastStep: Question,
    options: OptionButton[],
    onSubmit: (selectedButtons: QuestionButton[]) => void,
  ) {
    this.setState({
      ...defaultState,
      isOpen: true,
      options,
      lastStep,
      onSubmit,
    });
  }

  constructor(props: InputToolbarModalProps) {
    super(props);
    InputToolbarModal.instance = this;

    this.state = { ...defaultState };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillUnmount() {
    if (InputToolbarModal.instance === this) {
      InputToolbarModal.instance = undefined;
    }
  }

  onSubmit() {
    this.close();

    if (this.state.onSubmit) {
      this.state.onSubmit(this.state.selectedButtons);
    }
  }

  setSelectedButtons = (selectedButtons: QuestionButton[]) => {
    this.setState({ selectedButtons });
  };

  toggleButton = (button: string) => {
    const { buttons, maxAnswers } = this.state.lastStep as Question;

    const btn = (buttons || []).find((b) => b.id === button);

    if (!btn) return;

    if (maxAnswers === 1) {
      this.setSelectedButtons([btn]);
    } else {
      const cp = [...this.state.selectedButtons];
      const index = cp.findIndex((b) => b.id === btn.id);

      if (index !== -1) {
        cp.splice(index, 1);
      } else {
        if (this.state.selectedButtons.length === maxAnswers) {
          return;
        }
        cp.push(btn);
      }

      this.setSelectedButtons(cp);
    }
  };

  render() {
    const { isOpen, options, selectedButtons, lastStep } = this.state;

    return (
      <NewModal
        isInputToolbarModal
        onClose={this.close}
        title={
          <TitleWrapperStyled>
            <Text $textStyle='h3'>{lastStep && lastStep.text}</Text>
            {/* {lastStep && lastStep.maxAnswers && (
              <Text $textStyle='p16' $mT='16px' $color='grey' $fontWeight='400'>
                Выбери {lastStep && lastStep.maxAnswers} из {options.length} вариантов
              </Text>
            )} */}
            <DividerStyled />
          </TitleWrapperStyled>
        }
        isOpen={isOpen}>
        <ChatAnswerRadio
          withoutMaxHeight
          options={options}
          value={selectedButtons}
          onToggle={this.toggleButton}
        />
        <ChatButtonStyled>
          <Button $style='blue' onClick={this.onSubmit} disabled={selectedButtons.length === 0}>
            Отправить ответ
          </Button>
        </ChatButtonStyled>
      </NewModal>
    );
  }
}
