import React from 'react';

import { Button } from '../../../common/shared/ui/Button';

import { ChatMessageButtonsProps } from './ChatMessageButtonsTypes';
import { StyledChatMessageButtons } from './ChatMessageButtonsStyled';

import { AnswerStep, StepType } from '../../types/scenario';

const BEAUTY_TEXT: Record<string, string> = {
  'Не знаю': 'Не знаю 🤔',
  'Точно да': 'Точно да 👍',
  'Определенно нет': 'Определенно нет 👎',
};

function getBeautyText(text: string) {
  return BEAUTY_TEXT[text] || text;
}

const ChatMessageButtons = (props: ChatMessageButtonsProps) => {
  const { nextStep, step } = props;

  if (step.type !== StepType.question) return null;
  if (!step.buttons?.length) return null;
  if (!step.isHorizontal) return null;

  const buttons = step.buttons;

  const answerValue = nextStep && (nextStep as AnswerStep).data.value;
  const answerButton = Array.isArray(answerValue) && answerValue[0];

  return (
    <StyledChatMessageButtons>
      {answerButton ? (
        <Button $style='blue' $isActive $size='sm' disabled>
          {getBeautyText(answerButton.text)}
        </Button>
      ) : (
        buttons.map((a) => (
          <Button $style='blue' $size='sm' key={a.text} onClick={() => props.onAnswer([a])}>
            {getBeautyText(a.text)}
          </Button>
        ))
      )}
    </StyledChatMessageButtons>
  );
};

export default ChatMessageButtons;
