/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import { TVOI_KRUGOZOR_G2_ON_CHECK } from '../../tests/tvoi_krugozor_g2_on_check';
import { TVOI_KRUGOZOR_T2_ON_CHECK } from '../../tests/tvoi_krugozor_t2_on_check';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg2_ostankinoAlienInvasion_1_1: Scenario = {
  scenarioId: SceneId.stg2_ostankinoAlienInvasion_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Останкинская телебашня',
    timeoutMessage: 'К сожалению, время для ответа истекло 🙁',
    scene_id: 'stg2_ostankinoAlienInvasion_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.LIFT,
        text: `Здравствуйте, незнакомцы! Я — лифт, проводник между землёй и облаками!`,
        id: 'Q1',
        type: StepType.question,
        nextStep: 'Q2',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ээ… Вжух, что за чудеса?',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        text: `Не знаю… Наверное тут из-за нашего пришельца лифты ожили?`,
        id: 'Q2',
        person: Interlocutors.CAT,
        type: StepType.question,
        nextStep: 'Q3',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ну ладно. Здравствуй лифт! Как нам попасть наверх?',
            nextStep: 'Q3',
          },
        ],
      },

      Q3: {
        text: `Слышал о Ваших достижениях в парке. Я отвезу вас, если {{NAME}} ответит на вопросы теста.`,
        id: 'Q3',
        person: Interlocutors.LIFT,
        type: StepType.question,
        nextStep: 'Q3_1',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отлично! Я как раз [[хотел/хотела]] размять мозги, поэтому с удовольствием пройду твой тест!',
            nextStep: 'Q3_1',
          },
          {
            id: 'b2',
            text: 'Главное, чтобы не пешком! Я в деле!',
            nextStep: 'Q3_1',
          },
        ],
      },
      Q3_1: {
        text: `Тест состоит из 2х частей по 10 вопросов. На прохождение каждой части будет отведено ровно 10 минут. Ты [[должен/должна]] выбрать 1 вариант ответа из предложенных, и у тебя будет всего 1 минута на каждый вопрос. Очень внимательно читай вопросы.
        
        Важный момент: пройти тест можно только один раз. Повторно к нему вернуться будет нельзя. 
        
        Обрати внимание: так как ты проходишь тест после 16:00 7 июня, то баллы за этот тест тебе не будут начисляться.
        
        Выходить из теста во время его прохождения нельзя. Но если ты все-таки выйдешь из игры после запуска теста, независимо от причин, почему это произошло, таймер будет продолжать работать, а время, отведенное на тест, истекать. 
        
        Вопросы нельзя проматывать и возвращаться к предыдущим вопросам, поэтому прежде, чем начать тест, проверь, что у тебя:
           -   стабильно работает Интернет
           -   заряжено устройство, с которого ты проходишь тест
           -   и никто тебе не помешает в ближайшие 20 минут`,
        id: 'Q3_1',
        person: Interlocutors.LIFT,
        type: StepType.question,
        nextStep: 'TVOI_KRUGOZOR_G2_STEP',
        maxAnswers: 1,
        callToAction: 'Начнем?',
        buttons: [
          {
            id: 'b1',
            text: 'Да, давай!',
            nextStep: 'TVOI_KRUGOZOR_G2_STEP',
          },
        ],
      },

      ...TVOI_KRUGOZOR_G2_ON_CHECK,

      Q4_0: {
        text: `И еще 10 вопросов. [[Готов/Готова]]?`,
        id: 'Q4_0',
        person: Interlocutors.LIFT,
        type: StepType.question,
        nextStep: 'Q5',
        maxAnswers: 1,
        callToAction: 'Начнем?',
        buttons: [
          {
            id: 'b1',
            text: 'Да, давай!',
            nextStep: 'TVOI_KRUGOZOR_T2_STEP',
          },
        ],
      },

      ...TVOI_KRUGOZOR_T2_ON_CHECK,

      Q4: {
        text: `Ура! Мы, наконец, на вершине!
        
        А я ведь очень люблю эту башню и часто бывал здесь раньше. Рассказать тебе про неё, пока ждём Сан Саныча с радиопередатчиком?`,
        id: 'Q4',
        person: Interlocutors.CAT,
        type: StepType.question,
        nextStep: 'Q5',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Давай!',
            nextStep: 'Q5',
          },
        ],
      },

      Q5: {
        text: `Добро пожаловать в Останкинскую телебашню! Настоящая расположена в Москве, а у нас тут копия, конечно же. Так вот, это телевизионная и радиовещательная башня, высота которой составляет 540,1 м.

        Если будешь в столице, обязательно сходи туда с родителями. Сверху открывается потрясающий вид на город. Мой друг там экскурсии водит. Я сам был, очень понравилось.`,
        id: 'Q5',
        person: Interlocutors.CAT,
        type: StepType.question,
        nextStep: 'Q6',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Какая высокая! И какой красивый отсюда вид на весь парк! ',
            nextStep: 'Q6',
          },
        ],
      },

      Q6: {
        text: `Двери лифта открываются, и из него показываются Сан Саныч и Петров. Они вносят в комнату тяжёлый ящик с кучей переключателей и проводов и ставят на стол.`,
        id: 'Q6',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        nextStep: 'Q7',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Так быстро раздобыли передатчик? Чудеса, да и только!',
            nextStep: 'Q7',
          },
        ],
      },

      Q7: {
        text: `Да, из Москвы прямая доставка к нам! Осталось только подключить и можно связываться с космосом.`,
        id: 'Q7',
        person: Interlocutors.PETROV,
        type: StepType.message,
        nextStep: 'Q8',
      },

      Q8: {
        text: `Самое сложное — занести вот этот ящик в лифт. А без Петрова я бы и в лифт его не занёс и загадки лифта не решил.`,
        id: 'Q8',
        person: Interlocutors.ENTREPRENEUR,
        type: StepType.question,
        nextStep: 'Q9',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Здорово, что мы работаем в команде',
            nextStep: 'Q9',
          },
        ],
      },

      Q9: {
        text: `Подключил питание, мы готовы к трансляции.`,
        id: 'Q9',
        person: Interlocutors.PETROV,
        type: StepType.question,
        nextStep: 'Q10',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Класс! С чего начнём? ',
            nextStep: 'Q10',
          },
        ],
      },

      Q10: {
        text: `На передатчике загорелось множество кнопок и лампочек. Комнату наполнило тихое шипение устройства. 

        Пришелец подошел ближе к передатчику и стал пристально смотреть на него.`,
        id: 'Q10',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q11',
      },

      Q11: {
        text: `Шшшшш…

        Щщщщщщщщщ…`,
        id: 'Q11',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        nextStep: 'Q12',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Мне теперь кажется, что он передразнивает нашу радиостанцию.',
            nextStep: 'Q12',
          },
        ],
      },

      Q12: {
        text: `А-ха-ха! Да нет, это просто белый шум, ведь мы не настроены ни на одну волну. Вот тут настраивается волна. А здесь — мощность радиосигнала. 

        Прибор очень мощный, поэтому мощность стоит повышать осторожно, чтобы ничего не сломалось.`,
        id: 'Q12',
        person: Interlocutors.PETROV,
        type: StepType.question,
        nextStep: 'Q13',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ага, понятно. Непонятно только, на какую волну мы будем настраиваться?',
            nextStep: 'Q13',
          },
        ],
      },

      Q13: {
        text: `Пришелец подошёл к радиопередатчику и начал аккуратно крутить ручку волны приёма. `,
        id: 'Q13',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        nextStep: 'Q14',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хмм… Наверное, ему лучше знать, на какой волне его вид общается.',
            nextStep: 'Q14',
          },
        ],
      },

      Q14: {
        text: `Шшшшшщ… щщщщ… хчхчхчхч…`,
        id: 'Q14',
        person: Interlocutors.ALIEN,
        type: StepType.message,
        nextStep: 'Q15',
      },

      Q15: {
        text: `Хчхчхчхч… фффффф… …конец-то …т …лна …оторой …ня …лышно`,
        id: 'Q15',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        nextStep: 'Q16',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'О, я кажется могу разобрать слова. Мы поймали голоса из космоса!',
            nextStep: 'Q16',
          },
        ],
      },

      Q16: {
        text: `Я …десь …ами …оворю`,
        id: 'Q16',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        nextStep: 'Q17',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Вы понимаете, что говорят?',
            nextStep: 'Q17',
          },
        ],
      },

      Q17: {
        text: `Вдруг голос из динамиков радиостанции стал чётким, помехи пропали.  

        Пришелец вдруг перестал настраивать волну, но стал махать руками, пытаясь привлечь к себе внимание.`,
        id: 'Q17',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q18',
      },

      Q18: {
        text: `Эй, это не голоса из космоса! Это мой голос! Я тут!`,
        id: 'Q18',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        nextStep: 'Q19',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ух-ты! Ты умеешь говорить?',
            nextStep: 'Q19',
          },
        ],
      },

      Q19: {
        text: `Мой народ освоил более совершенные методы общения через радиоволны, без лишних звуков. Но чтобы вы смогли услышать меня, пришлось настроить ваше устройство на мою частоту.

        Меня, кстати, зовут Квилл.`,
        id: 'Q19',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        nextStep: 'Q20',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Привет, Квилл! Мы очень рады наконец-то пообщаться. Кстати, это Вжух, Сан Саныч и Петров. А я — {{name}}',
            nextStep: 'Q20',
          },
        ],
      },

      Q20: {
        text: `Да, мы очень рады первому межпланетному контакту! 

        Сможем наладить торговлю и грузоперевозки.`,
        id: 'Q20',
        person: Interlocutors.ENTREPRENEUR,
        type: StepType.question,
        nextStep: 'Q21',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, но сначала надо связаться с сородичами Квилла',
            nextStep: 'Q21',
          },
        ],
      },

      Q21: {
        text: `Раз уж Квилл настроился на правильную волну, теперь нужно плавно и осторожно увеличивать мощность до тех пор, пока мы не достигнем его родню`,
        id: 'Q21',
        person: Interlocutors.PETROV,
        type: StepType.question,
        nextStep: 'Q22',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Квилл, аккуратно двигай этот переключатель вверх',
            nextStep: 'Q22',
          },
        ],
      },

      Q22: {
        text: `Тут Квилл резко дёрнул вверх рычаг мощности сигнала на радиопередатчике. Послышался громкий треск электричества. Прибор завибрировал и, будто бы, вся башня вместе с ним.

        Через пару секунд погас свет в комнате и радиостанция выключилась..`,
        id: 'Q22',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q23',
      },

      Q23: {
        text: `Я же просил — аккуратно! Кажется, сломали прибор.`,
        id: 'Q23',
        person: Interlocutors.PETROV,
        type: StepType.question,
        nextStep: 'Q24',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Но мы же успели отправить послание в космос? ',
            nextStep: 'Q24',
          },
        ],
      },

      Q24: {
        text: `Не знаю, не знаю. Времени для передачи было совсем немного. `,
        id: 'Q24',
        person: Interlocutors.PETROV,
        type: StepType.message,
        nextStep: 'Q25',
      },

      Q25: {
        text: `Внезапно облака вокруг Останкинской Башни осветились зелёным. 

        Из-за них появились очертания чего-то, похожего на космический корабль. Квилл радостно запрыгал и захлопал в ладоши.
        `,
        id: 'Q25',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: EXIT_ID,
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
