/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg2_hermitage_1_1: Scenario = {
  scenarioId: SceneId.stg2_hermitage_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Эрмитаж',
    scene_id: 'stg2_hermitage_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Мяу!`,
        id: 'Q1',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'И тебе привет, Женя!',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        text: `Привет! Малевича тут, похоже, нет, поэтому просто расскажу:

        Мы ищем друга, он маленький, покрыт весь белой шерстью и разговаривает, шипя.`,
        id: 'Q2',
        person: Interlocutors.CAT,
        type: StepType.message,
        nextStep: 'Q3',
      },
      Q3: {
        text: `Женя берёт вас за руку и ведёт в соседний зал. На двери табличка: «Фотовыставка “Мамаев Курган”».

        В зале Женя встала напротив фотографии большой статуи женщины с мечом и указала на неё рукой. `,
        id: 'Q3',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q4',
      },

      Q4: {
        text: `Мяу мяяяу мррр мяу, мяу мяу мяу. Мрррр мяяяяу мяу мяу — мяу мяу`,
        id: 'Q4',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        nextStep: 'Q5',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ээ… Вжух, ты что-нибудь понял?',
            nextStep: 'Q5',
          },
        ],
      },

      Q5: {
        text: `Женя говорит, что ваш друг был у нас, и ему очень понравилась эта фотография.`,
        id: 'Q5',
        person: Interlocutors.BLUE_CAT,
        type: StepType.question,
        nextStep: 'Q6',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Малевич! Как вовремя ты появился. ',
            nextStep: 'Q6',
          },
        ],
      },

      Q6: {
        text: `Всегда пожалуйста.

        Могу ли я вам чем-нибудь еще помочь?`,
        id: 'Q6',
        person: Interlocutors.BLUE_CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Вообще-то да. Мне нужно подготовиться к важной викторине. Расскажи, пожалуйста, ещё раз про Эрмитаж.',
            nextStep: 'Q7',
          },
        ],
      },
      Q7: {
        text: `С превеликой радостью!

        Мы в Зимнем дворце — это главный императорский дворец России. Его называют Зимним, потому что императоры жили здесь зимой, а лето проводили в загородных летних резиденциях. 
         
        Сейчас этот дворец является частью самого большого музея нашей страны — Эрмитажа. По площади он занимает третье место в мире и насчитывает 365 залов.
        
        В нашем парке находится точная копия дворца, а настоящий расположен в культурной столице России — Санкт-Петербурге.
        
        Мало кто знает, что работы всемирно известных великих художников Леонардо да Винчи и Микеланджело хранятся здесь.`,
        id: 'Q7',
        person: Interlocutors.BLUE_CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Точно, всё вспомнил. Спасибо, Малевич! ',
            nextStep: 'Q8',
          },
        ],
      },
      Q8: {
        text: `Да, спасибо тебе. Обязательно ещё вас навестим, когда закончим со спасением парка 😁`,
        id: 'Q8',
        person: Interlocutors.CAT,
        type: StepType.message,
        nextStep: 'Q9',
      },
      Q9: {
        text: `Статуя с фото, которое показала Женя, она ведь есть у нас в парке. Неспроста это всё. Но сейчас нужно закончить подготовку к викторине. 

        Идём, скорее!`,
        id: 'Q9',
        person: Interlocutors.CAT,
        type: StepType.message,
        nextStep: EXIT_ID,
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
