/* eslint-disable max-len */
import {
  Conditions,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg2_innopolice_1_1: Scenario = {
  scenarioId: SceneId.stg2_innopolice_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Иннополис',
    scene_id: 'stg2_innopolice_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Привет, {{name}}! Эх, у нас тут просто кошмар! 😱

              Помнишь мой анализатор?`,
        id: 'Q1',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, что случилось? Он сломался?',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        text: `Нет, но… Образцы шерсти нашего чудного гостя, вода из Байкала — прибор всё обследовал. Только вот посмотреть результаты, кроме как через компьютер, нельзя никак.`,
        id: 'Q2',
        person: Interlocutors.PETROV,
        type: StepType.message,
        nextStep: 'Q3',
      },
      Q3: {
        text: `Так вот же он, стоит, компьютер!`,
        id: 'Q3',
        person: Interlocutors.CAT,
        type: StepType.message,
        nextStep: 'Q4',
      },
      Q4: {
        text: `Не включается. Видимо, из-за скачка напряжения перегорела микросхема.

        И запасных таких у нас в парке нет! Придётся заказывать и долго ждать.`,
        id: 'Q4',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А я [[видел/видела]] похожие компьютеры в МГУ',
            nextStep: 'Q5',
          },
        ],
      },
      Q5: {
        text: `Верно, это они. Но там они нужны для проектов ребят-учащихся. 

        Не можем же мы забрать их компьютер!`,
        id: 'Q5',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Что же нам делать? ',
            nextStep: 'Q6',
          },
        ],
      },
      Q6: {
        text: `А Светлана Васильевна ведь говорила, что скоро будет викторина. Приз — всё для создания учебного проекта.

        Пусть нашим проектом будет исследование этого неизвестного существа. И когда выиграем — попросим призом компьютер!`,
        id: 'Q6',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А какие там вопросы?',
            nextStep: 'Q7',
          },
        ],
      },
      Q7: {
        text: `Этого я не знаю. Но уверен, что ты с любыми справишься, {{name}}!

        Пойдём в МГУ и расспросим Светлану Васильевну.`,
        id: 'Q7',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Пойдём скорее!',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
