/* eslint-disable max-len */
import instruction from '../../../assets/Инструкция.pdf';

const isDevelop = process.env.NODE_ENV === 'development';
export const isDebug = localStorage.getItem('isDebug') || isDevelop;

export const STORE_USER_INFO = 'STORE_USER_INFO';
export const STORE_USER_TOKEN = 'STORE_USER_TOKEN';

export const USER_FILES = 'USER_FILES';

export const STORE_CHAT_PAGE_PROGRESS = 'STORE_CHAT_PAGE_PROGRESS';

export const STORE_UNSAVED_SCRIPTS = 'STORE_UNSAVED_SCRIPTS';

export const STORE_VERSION = 'STORE_VERSION';

export const CURRENT_VERSION = '5';
export const RELOGIN_VERSION = '5';

export const RATING_LIST = 'RATING_LIST';
export const RATING_PAGINATION = 'RATING_PAGINATION';

export const BREAKPOINT_LAPTOP = '1400px';
export const BREAKPOINT_TABLET = '1024px';
export const BREAKPOINT_PHONE = '767px';
export const BREAKPOINT_SMALL_PHONE = '340px';

export const LAST_USER_COORDINATES = 'LAST_USER_COORDINATES';

export const LAST_MAP_COORDS = 'LAST_MAP_COORDS';

export enum GAP {
  xxxs = '2px',
  xxs = '4px',
  xs = '6px',
  s = '8px',
  ss = '12px',
  m = '16px',
  l = '20px',
  xl = '22px',
  xxl = '26px',
  x3l = '30px',
}

export const VIDEO_REWRITED_KEY = 'video_rewrited';
export const TEST_PROBLEM_KEY = 'has_tests_problems';

export const LOCK_REGISTRATION_DATE = new Date('2021-05-25T21:00:00.000Z');

// Для финальной заглушки
export const TOP600_SCORE = 379;
export const RESERVE_SCORE = 374;

// export const TOP600_TEXT = `Поздравляем!!!
// Ты вошел в число участников, набравших максимальный балл. Мы ждем тебя на этапе «Онлайн-собеседование».
// Для записи на онлайн-собеседование необходимо  прочитать внимательно <a target="_blank" href=${instruction}>инструкцию</a> и пройти по <a href="https://bigchange2.записьонлайн.рф">ссылке</a>.
// Пожалуйста, не откладывайте и запишитесь, как можно скорее.
// Удачи!`;
export const TOP600_TEXT = `Поздравляем! Ты набрал больше 341 балла и прошел в следующий этап – онлайн-собеседование. 
<br/>
В ближайшие три дня с тобой свяжутся наши коллеги, чтобы записать тебя на него. Собеседование состоится в период с 11 по 15 июня. 
Обязательно ознакомься с правилами его проведения в <a href="https://bolshayaperemena.online/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5_%D0%BF%D0%BE_%D0%BA%D0%BE%D0%BDhttps:/bolshayaperemena.online/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5_%D0%BF%D0%BE_%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D1%83_2022.pdf%D0%BA%D1%83%D1%80%D1%81%D1%83_2022.pdf" target="_blank">Положении о конкурсе</a>. <br/>
Обрати внимание, что для связи мы используем те контактные данные (телефон, e-mail), которые указаны в твоем личном кабинете на платформе <a href="https://большаяперемена.онлайн/" target="_blank">«Большая перемена»</a>. 
<br/>
Обязательно проверь, корректные ли данные указаны. Это можно сделать в разделе «Настройки».`;

export const RESERVE_TEXT = `Твои баллы позволили тебе войти в число участников, попавших в резерв. Это значит, что в случае отказа от участия в финале одного из участников, набравшего больше 379 баллов, у тебя появляется возможность принять участие в финале. Для того, чтобы подтвердить свою готовность войти в резерв, тебе необходимо пройти онлайн-собеседование.
Для записи на онлайн-собеседование необходимо  прочитать внимательно <a target="_blank" href=${instruction}>инструкцию</a> и пройти по <a href="https://bigchange2.записьонлайн.рф">ссылке</a>.
Пожалуйста, не откладывай и запишись, как можно скорее. 
Удачи!`;
// export const FAIL_TEXT = `К сожалению, набранные тобой баллы меньше 374, что не позволило тебе пройти в финальный этап конкурса или попасть в резерв.
// Не расстраивайся, в <a href="https://vk.com/bpcontest">нашем сообществе в контакте</a> тебя ждет ещё много замечательных конкурсов.
// Удачи!`;
// export const FAIL_TEXT = `Привет! К сожалению, ты набрал меньше 342 баллов, что не позволяет тебе пройти в следующий этап. Но даже не думай расстраиваться, ведь это была хорошая тренировка для участия в других конкурсах.
// <br/>Кстати, доступные тебе задания размещены в личном кабинете на платформе <a href="https://bolshayaperemena.online/" target="_blank">«Большая перемена»</a>. Желаем тебе удачи!`

export const FAIL_TEXT = `Привет! Мы рады тебе сообщить, что теперь ты официально в команде «Большой перемены».

Теперь тебе доступно больше возможностей на нашей платформе, а также тебя ждут интересные конкурсы и уникальные мероприятия, доступные только участникам сообщества!

Но, к сожалению, ты набрал меньше 342 баллов, что не позволяет тебе пройти в следующий этап основного конкурса. 

Доступные тебе задания размещены в личном кабинете на платформе <a href="https://bolshayaperemena.online/" target="_blank">«Большая перемена»</a>. Успехов! Не прощаемся!`;
