/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef } from 'react';
import ReactTooltip from 'react-tooltip';

import { CatSuggestionsProps } from './CatSuggestionsTypes';
import { StyledCatSuggestions } from './CatSuggestionsStyled';

import { Interlocutors } from '../../types/scenario';
import interlocutors from '../../resources/interlocutors';

let lastClick = 0;

const cat = interlocutors[Interlocutors.CAT];

const tooltipMessage = 'Если ты не знаешь что делать дальше, дважды нажми на меня, я подскажу!';

const CatSuggestions = (props: CatSuggestionsProps) => {
  const node = useRef<HTMLImageElement>(null);

  const { onClick } = props;

  // TODO: подписаться на роутинг???
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const handleClick = () => {
    const newLastClick = new Date().valueOf();
    const isDoubleClick = newLastClick - lastClick < 400;

    lastClick = newLastClick;

    if (isDoubleClick) {
      onClick();
      if (node.current) {
        ReactTooltip.hide(node.current);
      }
    }
  };

  return (
    <StyledCatSuggestions
      ref={node}
      src={cat.img}
      onClick={handleClick}
      data-place='right'
      data-tip={tooltipMessage}
    />
  );
};

export default CatSuggestions;
