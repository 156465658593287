import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  MapModalImgStyled,
  MapModalTextStyled,
  MapModalTitleStyled,
} from '../pages/MapPage/MapPageStyled';
import interlocutors from '../resources/interlocutors';

import { Text } from '../../common/shared/ui/Text';
import { NewModal } from '../../common/shared/ui/NewModal';
import { TemplatedText } from '../components/TemplatedText';

import colors from '../../common/shared/constants/colors';
import { Interlocutors } from '../types/scenario';
import { useFrozenState } from '../hooks/useFrozenState';
import { addZero } from '../../common/shared/utils/addZero';

const TitleWrapperStyled = styled.div`
  position: relative;
`;

const DividerStyled = styled.div`
  height: 1px;
  width: 100%;
  background: ${colors.grey210};
`;

function Timer({ time }: { time: number }) {
  const H = addZero(Math.floor(time / 1000 / 60 / 60));
  const M = addZero(Math.floor((time / 1000 / 60) % 60));
  const S = addZero(Math.floor((time / 1000) % 60));

  return <Text>Осталось: {`${H}:${M}:${S}`}</Text>;
}

export function FreezeModal() {
  const {
    isOpened,
    text = '',
    title = '',
    start,
    duration,
    resetFreezeState,
  } = useFrozenState(true);

  const [now, setNow] = useState(new Date().valueOf());
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (start && duration && isOpened) {
      const from = new Date(start).valueOf();
      const newTimer = from + duration * 60 * 1000 - now;

      if (newTimer > 0) {
        setTimer(from + duration * 60 * 1000 - now);
        setTimeout(() => setNow(new Date().valueOf()), 1000);
      } else {
        resetFreezeState();
      }
    }
  }, [start, duration, now, isOpened, resetFreezeState]);

  return (
    <NewModal
      isOpen={isOpened}
      title={
        <TitleWrapperStyled>
          <MapModalTitleStyled>{title}</MapModalTitleStyled>
          <MapModalImgStyled src={interlocutors[Interlocutors.SYSTEM].imgModal} />
          <DividerStyled />
        </TitleWrapperStyled>
      }>
      <MapModalTextStyled $textStyle='p18'>
        <TemplatedText>{text}</TemplatedText>
        <Timer time={timer} />
      </MapModalTextStyled>
    </NewModal>
  );
}
