/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../types/scenario';
import interlocutors from '../../interlocutors';

export const scenario_stop_1: Scenario = {
  scenarioId: SceneId.scenario_stop_1,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: interlocutors.CAT.name,
    person: Interlocutors.CAT,
    text: `Доступная часть игры пройдена. 20 мая в твоем профиле в игре появятся задания по вызову — реши и отправь их до 4 июня 16:00 по московскому времени. 
    
    А в игру возвращайся 4 июня — тебя ждут контрольные тесты, которые будут оцениваться. 4 июня также станет доступно задание «Доброе дело». Реши тесты и выполни задание до 7 июня 16:00 по московскому времени.
    
    Обрати внимание: одновременно решать задания по вызову и проходить тесты в игре нельзя. Желаем тебе удачи, отважный герой!`,
    buttons: [],
    described: `Если не видишь изменений при входе в игру, обнови страницу в настройках браузера мобильного устройства или через сочетание клавиш Ctrl+F5 на компьютере.`,
    buttonToExitTheGame: true,
  },
};
