/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import { CREATIVE_TEST_3 } from '../CREATIVE_TEST_3';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg3_lake_1_1: Scenario = {
  scenarioId: SceneId.stg3_lake_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Байкал',
    scene_id: 'stg3_lake_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Бабуля, наверное, тоже ушла в МГУ к Петрову. И нам бы поторопиться туда. Вдруг, это что-то очень важное!`,
        id: 'Q1',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Точно что-то важное! Бабуля даже чай не допила, смотрите 🧐',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        text: `Давайте как раз приберёмся. Квилл, брось, пожалуйста, чайный пакетик в компостер. Вон он, у стены избушки стоит.`,
        id: 'Q2',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ого, а что такое компостер?',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        text: `Смотри, органический мусор, который мы оставляем после себя, можно переработать в компост — это такое удобрение. Компост производят особые бактерии, насекомые, червяки. Им нужен только кислород и тепло. 

        Мы складываем подходящие отходы в ящик, компостер, и вот эти организмы перерабатывают питательные вещества в отходах, создавая удобрение, которое мы потом можем использовать в саду!`,
        id: 'Q3',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ого, как здорово! Я [[думал/думала]], удобрения только в магазинах продаются 😬',
            nextStep: 'Q4',
          },
        ],
      },

      Q4: {
        text: `А-ха-ха-ха!

        Моя мама на даче всегда делает компост, а потом выращивает на нем много всего вкусного. Если у вас есть дача, предложи родителям тоже попробовать.`,
        id: 'Q4',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо за рассказ, Женя! ',
            nextStep: 'Q4_1',
          },
        ],
      },
      Q4_1: {
        text: `Так, с чайными пакетиками мы разобрались.. А что же с пустыми коробками? 

        Интересно, зачем Бабуля их оставила здесь?`,
        id: 'Q4_1',
        person: Interlocutors.GUIDE,
        type: StepType.message,
        nextStep: 'Q5',
      },

      Q5: {
        text: `Кажется, у меня есть идея! Может нам рассказать пришельцам, как можно интересно и необычно использовать пустые коробки? Звучит очень полезно!`,
        id: 'Q5',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Точно, Вжух!',
            nextStep: 'Q6',
          },
        ],
      },

      Q6: {
        text: `Отлично! Давайте покажем, как много пользы мы можем извлечь даже из таких обыденных вещей!
        
        Только учти, что данный тест не приносит баллов в конкурсе!
        
        Справишься с этим, {{name}}?`,
        id: 'Q6',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, давай начнем придумывать 🤓',
            nextStep: 'CREATIVE_TEA',
          },
        ],
      },

      // Тест на криативность - задания с чайным пакетиком

      ...CREATIVE_TEST_3,

      Q7: {
        text: `Земляне, мы немного впечатлены вашей находчивостью. Найти столько способов распорядиться такой незначительной вещью — похвально…`,
        id: 'Q7',
        person: Interlocutors.ALIENSHIP,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ну раз уж мы смогли вас удивить — верните всё, как было в нашем парке!',
            nextStep: 'Q8',
          },
        ],
      },

      Q8: {
        text: `Мы не закончили речь!
        
        Однако, развитые формы жизни, не создают лишних отходов, а поэтому и не имеют проблем с их использованием.
        
        Наш вид, например, просто не пьёт чай и тем более не пользуется тем, что вы называете “коробками”!
        
        Поэтому, опять же, решение о трансформации вашего парка не изменяем — отклонить! 
        
        И ваши потуги уже начинают утомлять, поэтому просим более не беспокоить по пустякам.
        
        Квилл, вылетай уже к нам. Всё равно землянам нас не убедить!`,
        id: 'Q8',
        person: Interlocutors.ALIENSHIP,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Какие же вы упрямые! Это нечестно!',
            nextStep: 'Q9',
          },
        ],
      },

      Q9: {
        text: `{{name}}, я останусь с вами, пока мы их не убедим. Не отчаивайся, мы найдём способ!`,
        id: 'Q9',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо, Квилл. ',
            nextStep: 'Q10',
          },
        ],
      },

      Q10: {
        text: `Да, мы не сдадимся! Идём теперь в МГУ — узнаем, зачем Петров всех звал туда. Возможно, он нам поможет переубедить пришельцев.`,
        id: 'Q10',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо, идём.',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
