import { SceneId } from '../../types/scenario';
import { MapPinType, RunModalScenario, ScenarioHelperResponse } from './types';
import { PointsOfInterest } from '../../types';
import { ID, to } from './ID';
import * as checks from './stageChecks';
import { ScenarioState } from '../../redux/scenario';
import { createNavigateToPointLink, toArray } from './utils';
import { UserAppState } from '../../redux/user';
import { ChallengeKey } from '../../components/Challenges/config';
import { checkTestsAsync } from '../../redux/tests/testsActions';
import store from '../../redux';

export function stage3(
  scenarioState: ScenarioState,
  runScenario: (id: SceneId, onSubmit?: (btn: string) => void) => void,
  runModalScenario: RunModalScenario,
  dispatch: (action: (...args: any[]) => void) => void,
  userState: UserAppState,
): ScenarioHelperResponse {
  /**
   *    ОСТАНКИНСКАЯ БАШНЯ      1 ЧАСТЬ
   */

  if (!scenarioState.scenarios?.[to[ID.stg3Ostankino]]) {
    const stg3OstankinoNavigatePoint = createNavigateToPointLink(
      'Останкинскую башню',
      PointsOfInterest.ostankino,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.ostankino]: () =>
          runModalScenario(
            to[ID.stg3OstankinoIntro],
            to[ID.stg3Ostankino],
            to[ID.stg3Ostankino],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${stg3OstankinoNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   *    КОСМОДРОМ      2 ЧАСТЬ
   */

  if (
    scenarioState.scenarios?.[to[ID.stg3Ostankino]] &&
    !scenarioState.scenarios?.[to[ID.stg3Space]]
  ) {
    const stg3SpaceNavigatePoint = createNavigateToPointLink('Космодром', PointsOfInterest.space);

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.space]: () =>
          runModalScenario(
            to[ID.stg3SpaceIntro],
            to[ID.stg3Space],
            to[ID.stg3Space],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${stg3SpaceNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   *    ИННОПОЛИС      3 ЧАСТЬ
   */

  if (
    scenarioState.scenarios?.[to[ID.stg3Space]] &&
    !scenarioState.scenarios?.[to[ID.stg3Innopolice]]
  ) {
    const stg3InnopoliceNavigatePoint = createNavigateToPointLink(
      'Иннополис',
      PointsOfInterest.innopolice,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.innopolice]: () =>
          runModalScenario(
            to[ID.stg3InnopoliceIntro],
            to[ID.stg3Innopolice],
            to[ID.stg3Innopolice],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${stg3InnopoliceNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   *    КОСМОДРОМ      4 ЧАСТЬ
   */

  if (
    scenarioState.scenarios?.[to[ID.stg3Innopolice]] &&
    !scenarioState.scenarios?.[to[ID.stg3SpaceAlienShip]]
  ) {
    const stg3SpaceAlienShipNavigatePoint = createNavigateToPointLink(
      'Космодром',
      PointsOfInterest.space,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.space]: () =>
          runModalScenario(
            to[ID.stg3SpaceAlienShipIntro],
            to[ID.stg3SpaceAlienShip],
            to[ID.stg3SpaceAlienShip],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${stg3SpaceAlienShipNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   *    ЭРМИТАЖ      5 ЧАСТЬ
   */

  if (
    scenarioState.scenarios?.[to[ID.stg3SpaceAlienShip]] &&
    !scenarioState.scenarios?.[to[ID.stg3Hermitage]]
  ) {
    const stg3HermitageNavigatePoint = createNavigateToPointLink(
      'Эрмитаж',
      PointsOfInterest.hermitage,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.hermitage]: () =>
          runModalScenario(
            to[ID.stg3HermitageIntro],
            to[ID.stg3Hermitage],
            to[ID.stg3Hermitage],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${stg3HermitageNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   *    ВСТРЕЧА С ЖЕНЕЙ      6 ЧАСТЬ
   */

  if (checks.notMeetingWithZhenyaIntroCompleted(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: {
        id: to[ID.stg3MeetingWithZhenyaIntro],
        runOnSubmit: to[ID.stg3MeetingWithZhenya],
      },
    };
  }

  /**
   *    ЭРМИТАЖ      7 ЧАСТЬ
   */

  if (
    !checks.notMeetingWithZhenyaIntroCompleted(scenarioState) &&
    !scenarioState.scenarios?.[to[ID.stg3Hersones]]
  ) {
    const stg3HersonesNavigatePoint = createNavigateToPointLink(
      'Херсонес',
      PointsOfInterest.hersones,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.hersones]: () =>
          runModalScenario(
            to[ID.stg3HersonesIntro],
            to[ID.stg3Hersones],
            to[ID.stg3Hersones],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${stg3HersonesNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   *    БАЙКАЛ    8 ЧАСТЬ
   */

  if (
    scenarioState.scenarios?.[to[ID.stg3Hersones]] &&
    checks.notstg3LakePopupCompleted(scenarioState)
  ) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.stg3LakePopup] },
    };
  }

  if (
    scenarioState.scenarios?.[to[ID.stg3Hersones]] &&
    !scenarioState.scenarios?.[to[ID.stg3Lake]]
  ) {
    const stg3LakeNavigatePoint = createNavigateToPointLink('Озеро Байкал', PointsOfInterest.lake);

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.lake]: () =>
          runModalScenario(
            to[ID.stg3LakeIntro],
            to[ID.stg3Lake],
            to[ID.stg3Lake],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди на ${stg3LakeNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   *    МГУ      9 ЧАСТЬ
   */

  if (scenarioState.scenarios?.[to[ID.stg3Lake]] && !scenarioState.scenarios?.[to[ID.stg3Mgu]]) {
    const stg3MguNavigatePoint = createNavigateToPointLink('МГУ', PointsOfInterest.mgu);

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.mgu]: () =>
          runModalScenario(
            to[ID.stg3MguIntro],
            to[ID.stg3Mgu],
            to[ID.stg3Mgu],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${stg3MguNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   *    Космодром    10 ЧАСТЬ
   */

  if (
    scenarioState.scenarios?.[to[ID.stg3Mgu]] &&
    checks.notstg3SpaceMeetingWithAliensPopupCompleted(scenarioState)
  ) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.stg3SpaceMeetingWithAliensPopup] },
    };
  }

  if (
    scenarioState.scenarios?.[to[ID.stg3Mgu]] &&
    !scenarioState.scenarios?.[to[ID.stg3SpaceMeetingWithAliens]]
  ) {
    const stg3SpaceMeetingWithAliensNavigatePoint = createNavigateToPointLink(
      'Космодром',
      PointsOfInterest.space,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.space]: () =>
          runModalScenario(
            to[ID.stg3SpaceMeetingWithAliensIntro],
            to[ID.stg3SpaceMeetingWithAliens],
            to[ID.stg3SpaceMeetingWithAliens],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${stg3SpaceMeetingWithAliensNavigatePoint}. Тебя там ждут.`,
    };
  }

  /**
   *    Космодром    10 ЧАСТЬ
   */

  if (
    scenarioState.scenarios?.[to[ID.stg3SpaceMeetingWithAliens]] &&
    checks.notstg3BridgeRussianIslandPopupCompleted(scenarioState)
  ) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.stg3BridgeRussianIslandPopup] },
    };
  }

  if (
    scenarioState.scenarios?.[to[ID.stg3SpaceMeetingWithAliens]] &&
    !scenarioState.scenarios?.[to[ID.stg3BridgeRussianIsland]]
  ) {
    const stg3BridgeRussianIslandNavigatePoint = createNavigateToPointLink(
      'Мост',
      PointsOfInterest.bridge,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.bridge]: () =>
          runModalScenario(
            to[ID.stg3BridgeRussianIslandIntro],
            to[ID.stg3BridgeRussianIsland],
            to[ID.stg3BridgeRussianIsland],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди на ${stg3BridgeRussianIslandNavigatePoint}. Тебя там ждут.`,
    };
  }

  // КОНЕЦ
  return { pointsHandlers: [] };
}
