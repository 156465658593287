/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { v4 as uuidv4 } from 'uuid';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  AnswerStep,
  Interlocutors,
  Message,
  QuestionButton,
  SceneId,
  Step,
  StepType,
  SystemStep,
  SystemStepType,
  ToMapStep,
} from '../../types/scenario';

import { LastMessageDivider } from '../../../common/shared/ui/LastMessageDivider';
import {
  StyledChatPage,
  StyledChatPageInputToolbar,
  StyledChatPageMessages,
  StyledChatPageMessagesWrapper,
} from '../../pages/ChatPage/ChatPageStyled';
import { useSelector } from 'react-redux';
import { saveHistoryAsync, saveStartedScenariosAsync } from '../../redux/user/userActions';
import { isDebug } from '../../../common/shared/constants/constants';
import {
  selectActiveChatHistory,
  selectActiveChatScenario,
  selectActiveChatVariables,
  selectGlobalTimer,
} from '../../redux/scenario/scenarioGetters';
import {
  checkCondition,
  resetActiveChatScenario,
  setActiveSceneHistoryAsync,
  setActiveSceneVariablesAsync,
  setGlobalScenarioTimer,
  skipActiveScenarioAsync,
} from '../../redux/scenario/scenarioActions';
import { MessagesContainer } from '../../components/chat/MessagesContainer';
import { selectScenarios } from '../../redux/user/userGetters';
import { Button } from '../../../common/shared/ui/Button';
import { Box } from '../../../common/shared/ui/Box';
import colors from '../../../common/shared/constants/colors';
import { routes } from '../../../common/shared/constants/routes';
import { useHistory } from 'react-router';
import { useMount } from '../../../common/shared/hooks/useMount';
import { Logger } from '../../../common/shared/utils/Logger';
import { InputToolbarModal } from '../../components/InputToolbarModal';
import { ID, to } from '../../hooks/scenarioHelpers/ID';
import useMountOverflowHidden from '../../../common/shared/hooks/useMountOverflowHidden';
import { useFrozenState } from '../../hooks/useFrozenState';
import { useAppDispatch } from '../../hooks/useAppDispatch';

export const ChatPageContainer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const isSaved = useRef(false);
  const lastRef = useRef<HTMLDivElement>(null);

  const [isCanAnswer, setCanAnswer] = React.useState(false);
  const [lastSentMessageId, setLastSentMessageId] = React.useState('');

  const scene = useSelector(selectActiveChatScenario)!; // активный сценарий
  const sceneHistory = useSelector(selectActiveChatHistory); // история активного сценария

  const { isOpened: isActiveFrozenGame } = useFrozenState();

  useMount(
    () => {},
    () => {
      dispatch(resetActiveChatScenario());
      InputToolbarModal.close();
    },
  );

  const setSceneHistory = useCallback(
    (steps: Step[]) => {
      if (steps.length === 0) {
        return;
      }
      // если шаг сохраненный в истории не совпадает с первым шагом.
      // решает баг с изменением первого сценария в Останкино
      if (steps[0].id !== scene.data.startId) {
        return;
      }

      try {
        const lastElement = steps[steps.length - 1];

        // время добавляется к каждому последнему сообщению
        steps[steps.length - 1] = {
          ...lastElement,
          date: lastElement.date || new Date().toUTCString(),
        };

        dispatch(setActiveSceneHistoryAsync(steps));
      } catch (ignore) {
        Logger.log(ignore);
      }
    },
    [scene, dispatch],
  );

  const lastTimeLimitedInputMessage = useMemo(() => {
    let s: SystemStep | undefined = undefined;
    for (let i = sceneHistory.length - 1; i >= 0; i--) {
      const step = sceneHistory[i];
      if (
        step?.type === StepType.system &&
        (step as SystemStep)._meta.type === SystemStepType.limitedTimeInput
      ) {
        s = step as SystemStep;
        break;
      }
    }
    return s && sceneHistory.findIndex((step) => step.id === s?._meta.exitStep) === -1
      ? s
      : undefined;
  }, [sceneHistory]);

  const lastTimeLimitedGroupMessage = useMemo(() => {
    let s: SystemStep | undefined = undefined;
    for (let i = sceneHistory.length - 1; i >= 0; i--) {
      const step = sceneHistory[i];
      if (
        step?.type === StepType.system &&
        (step as SystemStep)._meta.type === SystemStepType.limitedTimeGroup
      ) {
        s = step as SystemStep;
        break;
      }
    }
    return s &&
      sceneHistory.findIndex(
        (step) => step.id === s?._meta.exitStep || step.id === s?._meta.timeoutStep,
      ) === -1
      ? s
      : undefined;
  }, [sceneHistory]);

  const time = useSelector(selectGlobalTimer);
  const setTime = useCallback(
    (timer: number) => {
      dispatch(setGlobalScenarioTimer(timer));
    },
    [dispatch],
  );

  const ref = useRef<Step[]>([]);
  useEffect(() => {
    ref.current = sceneHistory;
  }, [sceneHistory]);
  const pushToHistory = useCallback(
    (step: Step) => {
      setSceneHistory([...ref.current, step]);
    },
    [setSceneHistory],
  );

  useEffect(() => {
    const now = new Date().valueOf();
    if (lastTimeLimitedInputMessage) {
      const mDate = new Date(lastTimeLimitedInputMessage.date!).valueOf();
      const t = lastTimeLimitedInputMessage._meta.time - Math.round((now - mDate) / 1000);
      if (t > 0) {
        setTime(t);
      } else {
        const step = scene.data.steps[lastTimeLimitedInputMessage._meta.exitStep];
        if (step) {
          pushToHistory(step);
        }
      }
      return () => setTime(0);
    }
    if (lastTimeLimitedGroupMessage) {
      const mDate = new Date(lastTimeLimitedGroupMessage.date!).valueOf();
      const t = lastTimeLimitedGroupMessage._meta.time - Math.round((now - mDate) / 1000);
      if (t > 0) {
        setTime(t);
      } else {
        const step = scene.data.steps[lastTimeLimitedGroupMessage._meta.timeoutStep];
        if (step) {
          pushToHistory(step);
        }
      }
      return () => setTime(0);
    }
  }, [lastTimeLimitedInputMessage, lastTimeLimitedGroupMessage, setTime, scene, pushToHistory]);

  useEffect(() => {
    if (time) {
      const timeout = setTimeout(() => setTime(time - 1), 1000);
      return () => clearTimeout(timeout);
    } else {
      if (lastTimeLimitedInputMessage) {
        const step = scene.data.steps[lastTimeLimitedInputMessage._meta.exitStep];
        if (step) {
          pushToHistory(step);
        }
      }
      if (lastTimeLimitedGroupMessage) {
        const step = scene.data.steps[lastTimeLimitedGroupMessage._meta.timeoutStep];
        if (step) {
          pushToHistory(step);
        }
      }
    }

    // осознанное решение
    // менять с осторожностью
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pushToHistory, scene.data.steps, setTime, time]);

  // переменные
  const sceneVariables = useSelector(selectActiveChatVariables);
  const setSceneVariables = useCallback(
    (variables: Record<string, string>) => {
      dispatch(setActiveSceneVariablesAsync(variables));
    },
    [dispatch],
  );

  const scenarioState = useSelector(selectScenarios);
  // восстановление состояния для чата (если уже начинали)
  useEffect(() => {
    if (typeof scenarioState.startedScenarios === 'object') {
      if (scenarioState.startedScenarios[scene.scenarioId]) {
        const started = [...scenarioState.startedScenarios[scene.scenarioId]!];
        if (scene.scenarioId === to[ID.welcomeTest]) {
          // id с которого переход на шаг с ссылкой
          const linkStep = started.findIndex((item) => item.id === 'Q4');
          // следующий раздел после ссылки - текст почему решил участвовать
          const afterLink = started.findIndex((item) => item.id === 'Q8');
          // если пользователь застрял на этапе с видеовизиткой
          // - удалить это из истории и перейти дальше
          // если видеовизитку в чате он прикрепил то можно оставить в истории
          if (linkStep !== -1 && afterLink === -1) {
            started.splice(linkStep);
            // добавляю обновленный шаг Q4 который ведет сразу на Q8
            started.push(scene.data.steps['Q4']);
            setSceneHistory(started || []);
            return;
          }
        }
        const history = [...(scenarioState.startedScenarios[scene.scenarioId] || [])];
        if (scene.scenarioId === SceneId.scenario_1_25_1) {
          const condStep = history.find(
            (item) => item.type === StepType.system && item.condition,
          ) as SystemStep | undefined;
          if (condStep) {
            const res: string = dispatch(checkCondition(condStep.condition!));
            if (res === 'TO_MAP_ID') {
              history.splice(history.length - 2);
            }
            setSceneHistory(history);
            return;
          }
        }
        const toMapStep = history.find((item) => {
          if (item.type === StepType.to_map_step) {
            return true;
          }
          if (item.type === StepType.answer) {
            const ans = item as AnswerStep;
            if (Array.isArray(ans.data.value)) {
              const buttons = ans.data.value;
              // console.log('buttons==', buttons[0].nextStep);
              // console.log('scene==', scene.data.steps);

              return scene?.data?.steps[buttons[0]?.nextStep]?.type === StepType.to_map_step;
            }
          }
          return false;
        }) as ToMapStep | undefined;
        let sliceIndex = history.length;
        if (toMapStep) {
          for (let i = history.length - 1; i > 0; i--) {
            if (history[i].type === StepType.answer) {
              sliceIndex = i;
              break;
            }
          }
        }
        setSceneHistory(history.slice(0, sliceIndex));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scene, setSceneHistory, setSceneVariables]);

  const lastSaved = useRef('');
  // сохранение состояния чата
  useEffect(() => {
    for (let i = sceneHistory.length - 1; i >= 0; i--) {
      const step = sceneHistory[i];
      const isMessageStartTimer =
        step.type === StepType.system &&
        ((step as SystemStep)._meta.type === SystemStepType.limitedTimeInput ||
          (step as SystemStep)._meta.type === SystemStepType.limitedTimeGroup);

      if (
        step.type === StepType.answer ||
        step.type === StepType.text_question ||
        isMessageStartTimer
      ) {
        if (!lastSaved.current || lastSaved.current !== step.id) {
          lastSaved.current = step.id;
          dispatch(saveStartedScenariosAsync(sceneHistory));
        }
        return;
      }
    }
  }, [dispatch, sceneHistory]);

  const lastStep = sceneHistory[sceneHistory.length - 1];

  const saveState = useCallback(() => {
    if (!isSaved.current) {
      isSaved.current = true;
      dispatch(saveHistoryAsync(scene.scenarioId, sceneHistory, true));
    }
  }, [dispatch, scene.scenarioId, sceneHistory]);

  // костыль, чтобы вызвался эффект ниже
  useEffect(() => {
    if (
      lastStep.type === StepType.answer ||
      lastStep.type === StepType.exit ||
      lastStep.type === StepType.system ||
      lastStep.type === StepType.to_map_step ||
      lastStep.type === StepType.to_lk_step
    ) {
      setLastSentMessageId(lastStep.id);
    }

    if (lastStep.type === StepType.exit) {
      saveState();
    }
  }, [lastStep, saveState]);

  useEffect(() => {
    if (isActiveFrozenGame) return;

    if (lastSentMessageId && lastSentMessageId === sceneHistory[sceneHistory.length - 1].id) {
      const nextStep = lastStep.nextStep ? scene.data.steps[lastStep.nextStep] : undefined;

      switch (lastStep.type) {
        case StepType.message:
          if (lastStep.condition) {
            // @ts-ignore
            const conditionResult: string = dispatch(checkCondition(lastStep.condition));
            const step = scene.data.steps[conditionResult];
            setSceneHistory([...sceneHistory, step]);
          } else {
            if (nextStep) {
              setSceneHistory([...sceneHistory, nextStep]);
            }
          }
          setCanAnswer(false);
          break;
        case StepType.system:
          if (lastStep.condition) {
            // @ts-ignore
            const conditionResult: string = dispatch(checkCondition(lastStep.condition));
            const step = scene.data.steps[conditionResult];
            setSceneHistory([...sceneHistory, step]);
          } else {
            if (nextStep) {
              setSceneHistory([...sceneHistory, nextStep]);
            }
          }
          setCanAnswer(false);
          break;

        case StepType.text_question:
          setCanAnswer(true);
          break;

        case StepType.question:
          setCanAnswer(!lastStep.isHorizontal);
          break;

        case StepType.to_map_step:
          setCanAnswer(true);
          break;
        case StepType.to_lk_step:
          setCanAnswer(true);
          break;
        case StepType.exit:
          setCanAnswer(true);
          saveState();
          break;

        case StepType.answer:
          setCanAnswer(false);
          if (nextStep) setSceneHistory([...sceneHistory, nextStep]);
          break;

        default:
          break;
      }
    }
  }, [
    isActiveFrozenGame,
    scene,
    sceneHistory,
    lastStep,
    saveState,
    setSceneHistory,
    lastSentMessageId,
    dispatch,
  ]);

  const handleAnswer = React.useCallback(
    (value: string | QuestionButton[]) => {
      const isButtons = typeof value !== 'string';

      const asButtons = value as QuestionButton[];
      const nextStepId: string =
        isButtons && asButtons.length === 1 ? asButtons[0].nextStep! : lastStep.nextStep!;

      const answer: AnswerStep = {
        id: uuidv4(),
        type: StepType.answer,
        person: Interlocutors.ME,
        date: new Date().toUTCString(),
        action: isButtons ? asButtons[0].action : undefined,

        answerType: isButtons ? 'button' : 'input',
        data: { value: isButtons ? asButtons : (value as string) },
        nextStep: nextStepId,
      };

      const newHistory = [...sceneHistory, answer];

      switch (lastStep.type) {
        case StepType.question:
          setSceneHistory(newHistory);
          break;

        case StepType.text_question:
          setSceneVariables({
            ...sceneVariables,
            ...{ [lastStep.variable]: (value as string).trim() },
          });

          if (lastTimeLimitedInputMessage) {
            let count = 1;

            for (let i = sceneHistory.length - 1; i > 0; i--) {
              if (sceneHistory[i].type === StepType.answer) ++count;
              if (sceneHistory[i].id === lastTimeLimitedInputMessage.id) break;
            }

            const nextStep =
              count === lastTimeLimitedInputMessage._meta.maxAnswers
                ? scene.data.steps[lastTimeLimitedInputMessage._meta.exitStep]
                : scene.data.steps[answer.nextStep!];
            setSceneHistory([...sceneHistory, answer, nextStep]);
          } else {
            setSceneHistory(newHistory);
          }
          break;

        default:
          break;
      }

      if (
        (lastStep.type === StepType.question || lastStep.type === StepType.text_question) &&
        !lastTimeLimitedInputMessage
      ) {
        setCanAnswer(false);
      }
    },
    [
      scene,
      lastStep,
      sceneHistory,
      sceneVariables,
      lastTimeLimitedInputMessage,
      setSceneHistory,
      setSceneVariables,
    ],
  );

  const subscribedOnExit = useRef(false);
  useEffect(() => {
    if (sceneHistory.findIndex((s) => s.id === 'LOGIC_TEST_PART1_1') !== -1) {
      if (!subscribedOnExit.current) {
        if (sceneHistory.findIndex((s) => s.id === 'Q12' || s.id === 'TEST_EXIT') === -1) {
          subscribedOnExit.current = true;
          const listener = (e: any) => {
            if (e) {
              e.preventDefault();
              e.returnValue = 'Вы уверены что хотите выйти? Результаты теста будут потеряны'; //required for Chrome
            }
            return 'Вы уверены что хотите выйти? Результаты теста будут потеряны';
          };
          window.addEventListener('beforeunload', listener);
          // @ts-ignore
          window.lockChatExit = true;
          return () => {
            window.removeEventListener('beforeunload', listener);
            // @ts-ignore
            window.lockChatExit = false;
          };
        }
      }
    }
  }, [sceneHistory]);

  const onTimeout = useCallback(() => {
    const lastMessage = sceneHistory[sceneHistory.length - 1];
    const message: Message = {
      type: StepType.message,
      id: uuidv4(),
      text: scene.data.timeoutMessage || '',
      nextStep: lastMessage.nextStep!,
      person: lastMessage.person,
      date: new Date().toUTCString(),
    };

    pushToHistory(message);
    dispatch(saveStartedScenariosAsync([...sceneHistory, message]));
  }, [sceneHistory, scene, setSceneHistory]);

  useEffect(() => {
    setTimeout(() => lastRef && lastRef.current?.scrollIntoView({ behavior: 'smooth' }), 100);
  }, [sceneHistory, lastSentMessageId]);

  const onSkipActiveScenario = useCallback(() => {
    dispatch(skipActiveScenarioAsync(() => history.replace(routes.map)));
  }, [dispatch, history]);

  return (
    <React.Fragment>
      <StyledChatPage>
        <StyledChatPageMessages>
          <StyledChatPageMessagesWrapper>
            <MessagesContainer
              lastStep={lastStep}
              messages={sceneHistory}
              variables={sceneVariables}
              onTimeout={onTimeout}
              onAnswer={handleAnswer}
              onMessageSent={setLastSentMessageId}
            />
            <LastMessageDivider />
            <div />
          </StyledChatPageMessagesWrapper>
        </StyledChatPageMessages>
        <div ref={lastRef}>
          <StyledChatPageInputToolbar
            key={sceneHistory.length}
            variables={sceneVariables}
            lastStep={lastStep}
            instantShow={Boolean(lastTimeLimitedInputMessage)}
            onAnswer={handleAnswer}
            isCanAnswer={isCanAnswer}
          />
        </div>

        {isDebug && (
          <Button
            $size='lg'
            $style='blue'
            onClick={onSkipActiveScenario}
            $textStyle='p18'
            $isCenter
            $mB={'16px'}>
            <Box $color={colors.white} $justify='space-between' $align='center'>
              Пропустить сценарий
            </Box>
          </Button>
        )}
      </StyledChatPage>
    </React.Fragment>
  );
};
