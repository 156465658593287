import styled from 'styled-components';
import { LinkProps } from './LinkTypes';
import { BaseStyled } from '../../../common/shared/styles/styledBase';

export const LinkStyled = styled(BaseStyled)<LinkProps>`
  a {
    color: ${(props) => props.$color};
    text-decoration: none;
  }
`;
