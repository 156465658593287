/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  Step,
  StepType,
  SystemStepType,
} from '../../../scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

export const scenario_2_10_0: Scenario = {
  scenarioId: SceneId.scenario_2_10_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: 'Новости парка',
    person: Interlocutors.SYSTEM,
    text: `Вы начинаете свой путь к мосту, как вдруг видите озадаченную Бабулю.`,
    buttons: ['Поговорить с Бабулей'],
  },
};

const EXIT_ID = 'EXIT_ID';

export const scenario_2_10: Scenario = {
  scenarioId: SceneId.scenario_2_10,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    scene_id: 'scenario_2_10',
    startId: 'ALT_Q1/0',
    title: 'Мост на остров Русский',
    steps: {
      ...splitStep({
        person: Interlocutors.GRANDMOTHER,
        id: 'ALT_Q1',
        text: `Здравствуй, [[дорогой/дорогая]] {{NAME}}! Ох, вот, снова надела не те очки и никак не могу разглядеть, есть ли здесь пешеходный переход? 🙁

Я очень спешу на Космодром. Говорят, новую ракету привезли, хочу на нее посмотреть.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Вы знаете, там за углом как раз есть пешеходный переход. Можете спокойно перейти дорогу там самостоятельно. (Игровое время не будет заморожено)',
            nextStep: 'ALT_Q2',
          },
          {
            id: 'b2',
            text: 'Ох, я так спешу, но тут как раз Вжух рядом, он Вам обязательно поможет. Эй, Вжух, иди сюда! А я [[побежал/побежала]] искать детали для тепловой пушки! (Игровое время будет заморожено на 5 минут)',
            nextStep: 'ALT_Q2',
          },
          {
            id: 'b3',
            text: 'Да, конечно, пойдемте со мной! Тут как раз недалеко есть пешеходный переход. Я вас провожу и помогу перейти дорогу. (Игровое время будет заморожено на 15 минут)',
            nextStep: 'ALT_Q2',
          },
        ],
      }),
      ALT_Q2: {
        person: Interlocutors.GRANDMOTHER,
        id: 'ALT_Q2',
        text: 'Ой, спасибо! Ой, [[подсобил/подсобила]]! Я знала, что ты не оставишь старушку в беде! 😍',
        nextStep: EXIT_ID,
        type: StepType.message,
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};

const CREATIVE_EXAMPLE: Record<string, Step> = {
  ...splitStep({
    person: Interlocutors.TEACHER,
    id: 'CREATIVE_EXAMPLE_1',
    text: `Пока рабочие трудятся, давай скоротаем время и поиграем в простую игру. Я назову предмет, а ты придумаешь как можно больше вариантов его использования. 

Обрати внимание, у тебя будет всего 5 минут. Записывай по одному варианту в поле ввода и обязательно жми кнопку «Отправить ответ». 

Для следующей твоей идеи появится новое поле ввода. То есть нужно писать и отправлять по одной идее за раз. Максимально ты можешь придумать 7 вариантов.`,
    type: StepType.question,
    maxAnswers: 1,
    callToAction: 'Поиграем?',
    buttons: [
      {
        id: 'b1',
        text: 'Да, давайте!',
        nextStep: 'CREATIVE_EXAMPLE_2',
      },
    ],
  }),
  CREATIVE_EXAMPLE_2: {
    person: Interlocutors.TEACHER,
    id: 'CREATIVE_EXAMPLE_2',
    type: StepType.message,
    text: `Придумай, пожалуйста, как можно больше интересных и необычных вариантов использования школьной линейки. Напоминаю, у тебя есть 5 минут. И не забудь, твои варианты должны быть приличными и осмысленными.`,
    nextStep: 'CREATIVE_EXAMPLE_3',
  },
  CREATIVE_EXAMPLE_3: {
    person: Interlocutors.TEACHER,
    id: 'CREATIVE_EXAMPLE_3',
    type: StepType.system,
    _meta: {
      type: SystemStepType.limitedTimeInput,
      time: 5 * 60,
      maxAnswers: 7,
      exitStep: 'Q7/0',
    },
    nextStep: 'CREATIVE_EXAMPLE_4',
  },
  CREATIVE_EXAMPLE_4: {
    id: 'CREATIVE_EXAMPLE_4',
    person: Interlocutors.TEACHER,
    text: '',
    type: StepType.text_question,
    variable: 'CREATIVE_EXAMPLE',
    nextStep: 'CREATIVE_EXAMPLE_4',
  },
};

export const scenario_2_10_1_0: Scenario = {
  scenarioId: SceneId.scenario_2_10_1_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Новости парка',
    person: Interlocutors.SYSTEM,
    text: `Перед вами грандиозное сооружение — мост на остров Русский. Вы видите энергичную Светлану Васильевну и начинаете с ней разговор.`,
    buttons: ['Начать'],
  },
};

export const scenario_2_10_1: Scenario = {
  scenarioId: SceneId.scenario_2_10_1,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    scene_id: 'scenario_2_10_1',
    startId: 'Q1/0',
    title: 'Мост на остров Русский',
    steps: {
      ...splitStep({
        person: Interlocutors.TEACHER,
        id: 'Q1',
        text: `Привет,  {{NAME}}! Ты как раз вовремя! Видишь этот огромный мост? Это точная копия моста на остров Русский. Оригинал моста находится в самой восточной точке России — городе Владивосток. Он соединяет материк с островом Русский, чтобы живущие на острове люди могли удобно добираться до большой земли. 

Мост на остров Русский внесен в Книгу рекордов Гиннеса, как самый длинный вантовый мост в мире. А еще ты [[мог/могла]] видеть его на российской купюре номиналом 2000 рублей.

Наш мост не уступает оригиналу и соединяет «Большую перемену» с окружающим миром. Но вот только он совсем заледенел, по нему совершенно невозможно передвигаться! 🙁

Мы хотим покрыть мост противоскользящим покрытием. Когда снова станет тепло, покрытие уйдет вместе со льдом.`,
        type: StepType.question,
        callToAction: 'Ты же поможешь нам?',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ну конечно, как всегда, без меня никак…',
            nextStep: 'Q2',
          },
          {
            id: 'b2',
            text: 'Да, конечно, что надо делать?',
            nextStep: 'Q2',
          },
        ],
      }),
      Q2: {
        person: Interlocutors.TEACHER,
        id: 'Q2',
        type: StepType.message,
        text: `Вон там стоят коробки со специальным антискользящим покрытием, надо помочь перенести их на мост и отдать рабочим.`,
        nextStep: 'Q3',
      },
      Q3: {
        person: Interlocutors.SYSTEM,
        id: 'Q3',
        type: StepType.system,
        _meta: {},
        text: `Вы начинаете переносить коробки и вдруг, подняв одну из них, обнаруживаете первую деталь для тепловой пушки — это золотой болт.`,
        nextStep: 'Q4',
      },
      Q4: {
        person: Interlocutors.TEACHER,
        id: 'Q4',
        type: StepType.message,
        text: `Вот это да! Ты [[нашел/нашла]] первую деталь для тепловой пушки Петрова! 😍`,
        nextStep: 'Q5',
      },
      Q5: {
        person: Interlocutors.TEACHER,
        id: 'Q5',
        type: StepType.message,
        text: `Как ты здорово справляешься! Благодаря твоей помощи работа пошла в два раза быстрее! 😍`,
        nextStep: 'Q6',
      },
      Q6: {
        person: Interlocutors.SYSTEM,
        id: 'Q6',
        _meta: {},
        type: StepType.system,
        text: `Вы перенесли все коробки и видите, как рабочие быстро покрывают мост специальным антискользящим покрытием.`,
        nextStep: 'CREATIVE_EXAMPLE_1/0',
      },
      ...CREATIVE_EXAMPLE,

      ...splitStep({
        person: Interlocutors.TEACHER,
        id: 'Q7',
        text: `Спасибо за варианты! И рабочие как раз тоже справились со своей задачей — мост больше нескользкий! 😊

Большое спасибо, что [[задержался/задержалась]] и [[помог/помогла]] нам с мостом! 😊

За это я хочу подарить тебе толстовку! Она очень мягкая, теплая и стильная. Как раз согреет тебя, пока «Большая перемена» не будет разморожена.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Пожалуйста!',
            nextStep: 'Q8',
          },
          {
            id: 'b2',
            text: 'Это было совсем нетрудно!',
            nextStep: 'Q8',
          },
        ],
      }),
      Q8: {
        person: Interlocutors.TEACHER,
        id: 'Q8',
        text: 'Здорово, что так быстро получилось управиться с мостом, но теперь появилась одна загвоздка… Посмотри, сколько осталось пустых картонных коробок! Почему люди их выбрасывают?! Ведь из них можно сделать много интересного! Коробки могут еще послужить во благо! 🙁',
        type: StepType.question,
        callToAction: 'Ты [[согласен/согласна]]?',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, действительно могут!',
            nextStep: 'Q8_1',
          },
        ],
      },
      Q8_1: {
        person: Interlocutors.SYSTEM,
        id: 'Q8_1',
        type: StepType.message,
        text: `Данный тест является тренировочным и не приносит баллов за конкурс.`,
        nextStep: 'Q9',
      },
      Q9: {
        person: Interlocutors.TEACHER,
        id: 'Q9',
        type: StepType.message,
        text: `Тогда я предлагаю тебе придумать как можно больше способов интересного и необычного использования пустых картонных коробок: любого размера и в любом количестве. Не ограничивай свою фантазию тем, что ты когда-то [[слышал/слышала]] или [[видел/видела]]. Придумай столько способов использования коробок, сколько сможешь.`,
        nextStep: 'Q10/0',
      },
      ...splitStep({
        id: 'Q10',
        delay: 7000,
        text: `Как и в прошлый раз, записывай по одному варианту в поле ввода и обязательно жми кнопку «Отправить ответ». Для следующей твоей идеи появится новое поле ввода. 

То есть нужно писать и отправлять по одной идее за раз. Максимально ты можешь придумать 15 вариантов. У тебя будет ровно 10 минут.  И не забудь, твои варианты должны быть приличными и осмысленными.`,
        person: Interlocutors.TEACHER,
        nextStep: 'Q11',
        type: StepType.message,
      }),
      Q11: {
        person: Interlocutors.TEACHER,
        id: 'Q11',
        type: StepType.message,
        delay: 7000,
        text: `Ну все, запускаю таймер! Пиши первый вариант, отправляй, следом второй, отправляй и так далее!`,
        nextStep: 'CREATIVE_TEST',
      },

      CREATIVE_TEST: {
        person: Interlocutors.TEACHER,
        id: 'CREATIVE_TEST',
        type: StepType.system,
        _meta: {
          type: SystemStepType.limitedTimeInput,
          time: 10 * 60,
          maxAnswers: 15,
          exitStep: 'Q12/0',
        },
        nextStep: 'CREATIVE_TEST_BOX',
      },
      CREATIVE_TEST_BOX: {
        id: 'CREATIVE_TEST_BOX',
        person: Interlocutors.TEACHER,
        text: '',
        type: StepType.text_question,
        variable: 'CREATIVE_TEST_BOX',
        nextStep: 'CREATIVE_TEST_BOX',
      },

      ...splitStep({
        id: 'Q12',
        person: Interlocutors.TEACHER,
        type: StepType.message,
        nextStep: 'Q13',
        text: `Как классно ты [[придумал/придумала]]! Теперь «Большая перемена» станет еще и местом разумного потребления и экологичного использования отходов.😍

Смотри, к нам спешит Вжух. Кажется, он хочет тебе что-то сказать...`,
      }),
      Q13: {
        person: Interlocutors.CAT,
        id: 'Q13',
        text: '{{NAME}}! У Сан Саныча есть интересные новости для тебя, он сейчас в воооон тех сверкающих небоскребах Москва-Сити.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо, что сказал!',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
