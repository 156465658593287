import React, { useMemo } from 'react';

import { routes } from '../../../common/shared/constants/routes';
import { useLocation } from 'react-router-dom';
import useViewport from '../../hooks/useViewport';

import ScenarioName from '../../components/ScenarioName';

import { Header } from '../../widgets/Header/Header';
import { Sidebar } from '../../widgets/Sidebar/Sidebar';
import {
  StyledLayoutAuth,
  StyledLayoutAuthSide,
  StyledLayoutBackground,
  StyledLayoutAuthContent,
  StyledLayoutAuthContentWrapper,
} from '../../components/Layout/LayoutAuthStyled';
import { MentorForm } from '../MentorForm';
import styled from 'styled-components';
import colors from '../../../common/shared/constants/colors';
import { useIsMentorVisible } from '../../hooks/useIsMentorVisible';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../redux/user/userGetters';
import { selectGlobalTimer, selectTimer } from '../../redux/scenario/scenarioGetters';
import { ScenarioTimer } from '../../../sixthGrade/components/ScenarioTimer';

interface ILayoutAuthProps {
  isFixed?: boolean;
  withGrid?: boolean;
  withMentor?: boolean;
  children: React.ReactNode;
}

const StyledMentorForm = styled.div`
  padding: 10px;
  border-radius: 16px;
  background: ${colors.white};

  & > * + * {
    margin-top: 10px;
  }
`;

export const LayoutAuth = ({ withGrid, children, withMentor }: ILayoutAuthProps) => {
  const { isMobile } = useViewport();
  const location = useLocation();

  const user = useSelector(selectUserInfo);
  const variables = user?.variables;
  const isMentorVisible = useIsMentorVisible();

  const messageTimer = useSelector(selectTimer);
  const globalTimer = useSelector(selectGlobalTimer);

  const body = useMemo(() => {
    if (withGrid) {
      return (
        <React.Fragment>
          <StyledLayoutAuthContentWrapper withoutPadding={isMobile}>
            {!isMobile && (
              <StyledLayoutAuthSide>
                <ScenarioName />
                <ScenarioTimer
                  messageTimer={messageTimer}
                  globalTimer={globalTimer}
                  isMobile={isMobile}
                />
                <Sidebar />
                {/* {withMentor && isMentorVisible && Boolean(variables?.mentorId) && ( */}
                {withMentor && isMentorVisible && (
                  <StyledMentorForm>
                    <MentorForm />
                  </StyledMentorForm>
                )}
              </StyledLayoutAuthSide>
            )}
            <StyledLayoutAuthContent>{children}</StyledLayoutAuthContent>
          </StyledLayoutAuthContentWrapper>
          <StyledLayoutBackground />
        </React.Fragment>
      );
    }

    return children;
  }, [withGrid, children, isMobile, withMentor, isMentorVisible, variables?.mentorId]);

  return (
    <StyledLayoutAuth>
      <Header />
      {body}
    </StyledLayoutAuth>
  );
};
