/* eslint-disable max-len */
import { StepType, Interlocutors, TestStep } from '../../../types/scenario';

// TODO: Собрать все тесты связанные с тестом "Твой вектор здесь" - неудобно когда они в файлах где используется, нет возможности поиска

export const TEST_TVOI_VECTOR: TestStep = {
  external_id: 'test-tvoi-vektor-junior-210401',
  path: 'test-tvoi-vektor-junior-210401.json',
  id: 'Q4',
  name: 'Твой вектор 1/3',
  person: Interlocutors.BLUE_CAT,
  type: StepType.test,
  maxAnswers: 2,
  nextStep: 'Q6',
  order: [
    {
      description:
        'С помощью твоих ответов мы определим, какие направления будут главными для изучения в университете. Отвечай честно и выбирай только то, что тебе близко.',
      subtest: 'T1',
      question: 'Q39',
    },
    {
      description: 'Теперь давай выберем, что студенты будут узнавать на дополнительных занятиях.',
      subtest: 'T1',
      question: 'Q16',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q17',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q11',
    },
    {
      description: 'А теперь давай выберем для студентов, чем им лучше заняться в свободное время.',
      subtest: 'T1',
      question: 'Q10',
    },
    {
      description: 'Ну и последний вопрос просто о тебе.',
      subtest: 'T1',
      question: 'Q21',
    },
  ],
};

export const TEST_TVOI_VECTOR_2: TestStep = {
  maxAnswers: 2,
  external_id: 'test-tvoi-vektor-junior-210401',
  id: 'TEST_TVOI_VECTOR_2',
  name: 'Твой вектор 2/3',
  nextStep: 'Q3',
  order: [
    {
      description:
        'Хорошо, когда есть увлечения, они наполняют жизнь красками. Я вот люблю ухаживать за цветами, играть с внуками и разгадывать детективные загадки. А ты?',
      subtest: 'T1',
      question: 'Q1',
    },
    {
      description:
        'Знаешь, {{NAME}}, у тебя ведь сейчас очень интересный возраст. Тебе уже достаточно лет, чтобы начать задумываться о своем будущем, о том, как ты хочешь прожить свою жизнь. И при этом, у тебя впереди много времени, чтобы осуществить все свои задумки и желания.',
      subtest: 'T1',
      question: 'Q9',
    },
    {
      description: 'Хорошо, пойдем дальше.',
      subtest: 'T1',
      question: 'Q20',
    },
    {
      description: '{{NAME}}, а как у тебя дела со школой?',
      subtest: 'T1',
      question: 'Q27',
    },
    {
      description: 'Ты знаешь, что важно не только хорошо трудиться, но и хорошо отдыхать?',
      subtest: 'T1',
      question: 'Q28',
    },
    {
      description: '{{NAME}}, последний вопрос и закончим.',
      subtest: 'T1',
      question: 'Q36',
    },
  ],
  path: 'test-tvoi-vektor-junior-210401.json',
  person: Interlocutors.TEACHER,
  type: StepType.test,
};
