import React from 'react';

import { StyledScenarioTimer } from './ScenarioTimerStyled';

import { Text } from '../../../common/shared/ui/Text';
import colors from '../../../common/shared/constants/colors';
import { addZero } from '../../../common/shared/utils/addZero';

export interface ScenarioTimerProps {
  isForChat?: boolean;
  isMobile?: boolean;
  messageTimer?: number;
  globalTimer?: number;
}

export const ScenarioTimer = (props: ScenarioTimerProps) => {
  const { isForChat, isMobile, messageTimer, globalTimer } = props;

  const timer = Math.max(messageTimer!, globalTimer!);

  if (!timer || (isMobile && !isForChat)) return null;

  const minutes = addZero(Math.floor(timer / 60));
  const seconds = addZero(Math.floor(timer % 60));

  return (
    <StyledScenarioTimer>
      <Text $textStyle='p12' $color={'grey'}>
        Осталось времени:
      </Text>
      <Text $textStyle={'p20'} $fontWeight='700' $color={timer < 10 ? colors.red : colors.text}>
        {minutes}:{seconds}
      </Text>
    </StyledScenarioTimer>
  );
};
