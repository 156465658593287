import React, { FC, Fragment, memo, useCallback, useMemo, useState } from 'react';

import PicStep1 from '../../../assets/images/onboarding_cat.svg';
import PicStep2 from '../../../assets/images/onboarding_people.svg';
import PicStep3 from '../../../assets/images/onboarding_tower.svg';
import SpriteStar from '../../../assets/images/onboarding_sprite_star.svg';
import SpriteCloudL from '../../../assets/images/onboarding_sprite_cloud1.svg';
import SpriteCloudR from '../../../assets/images/onboarding_sprite_cloud2.svg';

import {
  OnboardingButtonWrap,
  OnboardingCloud1,
  OnboardingCloud2,
  OnboardingCloud3,
  OnboardingContent,
  OnboardingMainTitle,
  OnboardingPageLeft,
  OnboardingPageRight,
  OnboardingPaginationEmpty,
  OnboardingPaginationFilled,
  OnboardingPaginationWrapper,
  OnboardingPic,
  OnboardingPic3,
  OnboardingRightStep1,
  OnboardingRightStep2,
  OnboardingRightStep3,
  OnboardingStar,
  OnboardingStarB,
  OnboardingStarR,
} from './OnboardingPageStyled';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setStateAsync } from '../../redux/user/userActions';

import { Text } from '../../../common/shared/ui/Text';
import { routes } from '../../../common/shared/constants/routes';
import { Box } from '../../../common/shared/ui/Box';
import Logo from '../../components/Logo';
import { Button } from '../../../common/shared/ui/Button';
import { Icon } from '../../../common/shared/ui/Icon';
import { Page } from '../../../common/pages/Page';

const screensContent = [
  {
    // title: (
    //   <Text displayAs='div' $textStyle='h2'>
    //     Давай отправимся в путь!
    //   </Text>
    // ),
    description: (
      <Text displayAs='div' $textStyle='p24'>
        <div style={{ overflowY: 'auto', maxHeight: '55vh' }}>
          Ты попадаешь ко входу в парк развлечений и открытий «Большая перемена», только вместо шума
          и ярких огней весь парк находится в тумане, все замерло и будто ожидает чего-то…
          <br />
          <br />
          К тебе подходит пушистое создание и смотрит умными глазами. Кажется, оно понимает твою
          речь.
          <br />
          <br />
        </div>
      </Text>
    ),
    right: (
      <OnboardingRightStep3>
        <OnboardingCloud1 src={SpriteCloudL} />
        <OnboardingCloud2 src={SpriteCloudL} />
        <OnboardingCloud3 src={SpriteCloudR} />
        <OnboardingPic3 src={PicStep3} />
      </OnboardingRightStep3>
    ),
  },
  // {
  //   title: <OnboardingMainTitle>Привет!</OnboardingMainTitle>,
  //   description: (
  //     <Text displayAs='div' $textStyle='p24'>
  //       Меня зовут Вжух, я говорящий кот парка «Большая перемена». А как тебя зовут? Расскажи о
  //       себе!
  //     </Text>
  //   ),
  //   right: (
  //     <OnboardingRightStep1>
  //       <OnboardingStar src={SpriteStar} />
  //       <OnboardingStarR src={SpriteStar} />
  //       <OnboardingStarB src={SpriteStar} />
  //       <OnboardingPic src={PicStep1} />
  //     </OnboardingRightStep1>
  //   ),
  // },
  // {
  //   title: (
  //     <Text displayAs='div' $textStyle='h2'>
  //       {' '}
  //     </Text>
  //   ),
  //   description: (
  //     <Text displayAs='div' $textStyle='p24'>
  //       Чтобы рассказать Вжуху о себе, запиши видеовизитку продолжительностью 1-2 минуты.
  //       <br />
  //       <br />В ней:
  //       <br />
  //       <br /> Расскажи самое важное и интересное о себе. Это могут быть твои достижения или хобби,
  //       мечта, или проект, который планируешь реализовать. Рассказывай о том, что для тебя важно,
  //       что ценишь, и чем по-настоящему гордишься.
  //       <br />
  //       <br /> Обязательно расскажи о том, чего ждешь от участия в конкурсе.
  //     </Text>
  //   ),
  //   right: (
  //     <OnboardingRightStep2>
  //       <OnboardingPic src={PicStep2} />
  //     </OnboardingRightStep2>
  //   ),
  // },
];

const Pagination = (props: { currentPage: number; countPages: number }) => {
  const { currentPage, countPages } = props;

  const items = useMemo(() => {
    const arr = [];

    for (let i = 0; i < countPages; i++) {
      arr.push(
        i > currentPage ? (
          <OnboardingPaginationEmpty key={i} />
        ) : (
          <OnboardingPaginationFilled key={i} />
        ),
      );
    }

    return arr;
  }, [currentPage, countPages]);

  return <OnboardingPaginationWrapper $pageNum={currentPage}>{items}</OnboardingPaginationWrapper>;
};

export const OnboardingWrap = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const items = screensContent;
  const history = useHistory();

  const handleNext = useCallback(() => {
    if (page + 1 <= items.length - 1) {
      setPage(page + 1);
      return;
    }

    dispatch(setStateAsync({ isOnboardingCompleted: true }));
    history.push(routes.map);
  }, [history, items, page, dispatch]);

  const handlePrev = useCallback(() => {
    if (0 <= page - 1) setPage(page - 1);
  }, [page, setPage]);

  const nextBtn = useMemo(() => ({ text: 'Далее', onClick: handleNext }), [handleNext]);

  return (
    <Fragment>
      <OnboardingPageLeft $pageNum={page}>
        <Box $justify='space-between' $align='center'>
          <Pagination currentPage={page} countPages={items.length} />
          <Logo isOnboarding />
        </Box>
        <OnboardingContent $pageNum={page}>
          {/* {items[page].title} */}
          {items[page].description}
        </OnboardingContent>
        <OnboardingButtonWrap>
          {!!page && (
            <Button
              $textStyle='p18'
              $fontWeight='500'
              $style='ghost'
              $minW='230px'
              onClick={handlePrev}>
              <Box $justify='space-between' $align='center'>
                <Icon isReverse icon='ico_chevron' />
                Назад
              </Box>
            </Button>
          )}
          <Button $textStyle='p18' $fontWeight='500' $minW='230px' onClick={nextBtn.onClick}>
            <Box $justify='space-between' $align='center'>
              {nextBtn.text}
              <Icon icon='ico_chevron' />
            </Box>
          </Button>
        </OnboardingButtonWrap>
      </OnboardingPageLeft>

      <OnboardingPageRight $pageNum={page}>{items[page].right}</OnboardingPageRight>
    </Fragment>
  );
};

const OnboardingPage: FC = () => (
  <Page>
    <OnboardingWrap />
  </Page>
);

export default memo(OnboardingPage);
