/* eslint-disable max-len */
import { Step, StepType, Interlocutors, TestStep } from '../../../types/scenario';

const TVOI_KRUGOZOR_G2_STEP: TestStep = {
  id: 'TVOI_KRUGOZOR_G2_STEP',
  person: Interlocutors.ENTREPRENEUR,
  type: StepType.test,
  external_id: '20230529-211847-4972',
  path: 'bp23_-_Твой_кругозор_Junior_(g2)_-_20230530.json',
  name: 'Твой кругозор 2/1',
  order: [
    { timeout: 60, question: 'Q1', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q2', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q3', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q4', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q5', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q6', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q7', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q9', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q11', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q19', subtest: 'T1', description: '' },
  ],
  nextStep: 'FINISH',
};

export const TVOI_KRUGOZOR_G2: Record<string, Step> = {
  TVOI_KRUGOZOR_G2_STEP,
  FINISH: {
    person: Interlocutors.SYSTEM,
    id: 'Q12',
    text: ``,
    callToAction: 'Начнем',
    type: StepType.message,
    nextStep: 'Q12',
  },
};
