import React, { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react';
import { Box } from '../../common/shared/ui/Box';
import { Text } from '../../common/shared/ui/Text';
import colors from '../../common/shared/constants/colors';
import NormalInput from './NormalInput';
import useBoolState from '../../common/shared/hooks/useBoolState';
import { selectUserInfo } from '../redux/user/userGetters';
import useViewport from '../hooks/useViewport';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveSceneVariablesAsync } from '../redux/scenario/scenarioActions';

const onKeyPress = (event: KeyboardEvent) => {
  const isSpaceInput = event.charCode === 32;
  if (isSpaceInput) {
    event.preventDefault();
  }
};

function useInputValue(
  value: string,
): [string, (e: ChangeEvent<HTMLInputElement> | string) => void] {
  const [state, setState] = useState(value);
  const onChange = useCallback((e: ChangeEvent<HTMLInputElement> | string) => {
    if (typeof e === 'string') {
      setState(e);
    } else {
      setState(e.target.value);
    }
  }, []);

  return [state, onChange];
}

export function MentorForm() {
  const user = useSelector(selectUserInfo);

  const { isMobile } = useViewport();
  const dispatch = useDispatch();

  const variables = user?.variables;
  const [mentorEmail, setMentorEmail] = useState(variables?.mentorEmail || '');
  const [mentorId, setMentorId] = useState(variables?.mentorId || '');

  const [isMentorEditing] = useBoolState(true);
  const [mentorFirstName, setMentorFirstName] = useInputValue(
    user?.variables?.mentorFirstName || '',
  );
  const [mentorLastName, setMentorLastName] = useInputValue(user?.variables?.mentorLastName || '');
  const [mentorMiddleName, setMentorMiddleName] = useInputValue(
    user?.variables?.mentorMiddleName || '',
  );
  const onChangeMentorEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMentorEmail(e.target.value);
    saveVariable('mentorEmail', e.target.value);
  }, []);
  const onChangeMentorId = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMentorId(e.target.value);
    saveVariable('mentorId', e.target.value);
  }, []);
  const onChangeMentorFirstName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMentorFirstName(e);
    saveVariable('mentorFirstName', e.target.value);
  }, []);
  const onChangeMentorMiddleName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMentorMiddleName(e);
    saveVariable('mentorMiddleName', e.target.value);
  }, []);
  const onChangeMentorLastName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setMentorLastName(e);
    saveVariable('mentorLastName', e.target.value);
  }, []);

  const saveVariable = (variable: any, value: any) => {
    const variables = user?.variables || {};

    const copy = { ...variables };
    copy[variable] = value;

    dispatch(setActiveSceneVariablesAsync(copy));
  };

  // if (!mentorEmail) {
  //   return null;
  // }
  return (
    <>
      <Box $align='center' $isWrap={false} $direction={'row'} $justify={'space-between'}>
        <Text $textStyle={isMobile ? 'p18' : 'p14'} $color={colors.title} $fontWeight='800'>
          Наставник
        </Text>
      </Box>
      <NormalInput
        placeholder={'Фамилия'}
        maxLength={100}
        value={mentorLastName}
        disabled={!isMentorEditing}
        onKeyPress={onKeyPress}
        onChange={onChangeMentorLastName}
      />
      <NormalInput
        placeholder={'Имя'}
        maxLength={100}
        value={mentorFirstName}
        disabled={!isMentorEditing}
        onKeyPress={onKeyPress}
        onChange={onChangeMentorFirstName}
      />
      <NormalInput
        placeholder={'Отчество'}
        maxLength={100}
        value={mentorMiddleName}
        disabled={!isMentorEditing}
        onKeyPress={onKeyPress}
        onChange={onChangeMentorMiddleName}
      />
      <NormalInput
        placeholder={'E-mail наставника'}
        maxLength={100}
        value={mentorEmail}
        disabled={!isMentorEditing}
        onKeyPress={onKeyPress}
        onChange={onChangeMentorEmail}
      />
      <NormalInput
        placeholder={'ID наставника в Большой перемене'}
        maxLength={100}
        value={mentorId}
        disabled={!isMentorEditing}
        onKeyPress={onKeyPress}
        onChange={onChangeMentorId}
      />
    </>
  );
}
