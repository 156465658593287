import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiChoice } from '../../types/api/user';
import { TestItem } from '../../hooks/useTestsResults';

export interface TestsState {
  testResults: Record<string, ApiChoice>;
  activeTestResult?: TestItem;
}

const initialState: TestsState = {
  testResults: {},
};

const testsSlice = createSlice({
  name: 'scenario',
  initialState,
  reducers: {
    setTestResults: (state, action: PayloadAction<Record<string, ApiChoice>>) => {
      state.testResults = action.payload;
    },
    addTestResult: (state, action: PayloadAction<Record<string, ApiChoice>>) => {
      state.testResults = { ...state.testResults, ...action.payload };
    },
    setActiveTestResult: (state, action) => {
      state.activeTestResult = action.payload;
    },
  },
});

export default testsSlice;
