/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';
import interlocutors from '../../../interlocutors';

export const lake_1_4: Scenario = {
  scenarioId: SceneId.lake_1_4,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.GRANDMOTHER].name,
    person: Interlocutors.GRANDMOTHER,
    text: `{{NAME}}, у меня тут пока все под контролем, не переживай. Помоги пока кому-нибудь еще.`,
    buttons: ['Закрыть'],
  },
};
