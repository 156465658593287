import { AppThunk } from '../../types';
import {
  AnswerStep,
  ChatScenario,
  Conditions,
  ModalScenario,
  QuestionButton,
  Scenario,
  ScenarioType,
  SceneId,
  Step,
  StepCondition,
  StepType,
  SystemStep,
} from '../../types/scenario';
import * as H from 'history';
import { GameModal } from '../../components/GameModal';
import { testToSteps } from '../../utils/ScenarioUtils';
import { routes } from '../../../common/shared/constants/routes';
import scenarioSlice from '../../redux/scenario/index';
import { saveHistoryAsync, setUserVideoAsync, setVariablesAsync } from '../user/userActions';
import { isDebug } from '../../../common/shared/constants/constants';
import { Logger } from '../../utils/Logger';

import { getFrozenState } from '../../hooks/useFrozenState';
import { setFreezeStateAction } from '../freeze/freezeActions';
import { ID, to } from '../../hooks/scenarioHelpers/ID';

function getVariable(variables: Record<string, string>, variableName: string) {
  return variables[variableName] || '';
}

const { setChatScenario, setActiveSceneHistory, setSceneVariables, resetChatScenario } =
  scenarioSlice.actions;

export const setScenarioTimer = scenarioSlice.actions.setScenarioTimer;
export const setGlobalScenarioTimer = scenarioSlice.actions.setGlobalScenarioTimer;
export const setAllScenarios = scenarioSlice.actions.setAllScenarios;

export const setActiveSceneHistoryAsync =
  (history: Step[]): AppThunk =>
  async (dispatch, getState) => {
    const reduxState = getState();
    const activeScenarioId = reduxState.scenario.activeChatScenario?.scenarioId;

    if (activeScenarioId) {
      const scenarioState = getState().scenario;
      if (scenarioState) {
        const result = getFrozenState(scenarioState);
        if (result) dispatch(setFreezeStateAction(result));
      }
    }

    dispatch(setActiveSceneHistory(history));
  };

export const setActiveSceneVariablesAsync =
  (variables: Record<string, string>): AppThunk =>
  async (dispatch, getState) => {
    const copy = { ...variables };

    if (copy.NAME) {
      const trimmed = copy.NAME.trim();
      copy.NAME = trimmed[0].toUpperCase() + trimmed.slice(1);
    }
    dispatch(setSceneVariables(copy));
    await dispatch(setVariablesAsync(copy));
  };

/**
 * Этот метод именно для пропуска пользователем.
 * В случае если он решил не проходить сценарий (например переводить Бабулю через дорогу)
 * Другой метод импользуется нами для быстрого перемещения по игре
 */
export const skipScenario =
  (sceneId: SceneId): AppThunk =>
  async (dispatch) => {
    await dispatch(saveHistoryAsync(sceneId, [], true));
  };

/**
 * список замороженных сценариев (при добавлении их в список - они станут некликабельными на карте)
 */
const listDisabledScenarioIds: string[] = []; // ['scenario_1_8', 'scenario_1_9']

/**
 * возвращает true, если сцену надо заблокировать
 * иначе false
 * @param disabledId scenarioId
 */
const checkIdForDisable = (id: string) => listDisabledScenarioIds.includes(id);

export const runScenarioAsync =
  (scenario: Scenario, onSubmit?: (button: string) => void, history?: H.History): AppThunk =>
  (dispatch, getState) => {
    Logger.log('SCENARIO', scenario);
    /**
     * таким образом можно сделать красные пины на карте некликабельными
     */
    if (checkIdForDisable(scenario.scenarioId)) {
      return;
    }

    if (scenario.type === ScenarioType.modal) {
      const modalScenario = scenario.data as ModalScenario;
      const _onSubmit = async (btn: string) => {
        await dispatch(saveHistoryAsync(scenario.scenarioId, [], true));
        if (onSubmit) {
          onSubmit(btn);
        }
      };
      if (modalScenario.closable) {
        dispatch(saveHistoryAsync(scenario.scenarioId, [], true));
      }
      // _onSubmit('asd');
      GameModal.open(
        modalScenario.title,
        modalScenario.text,
        modalScenario.person,
        modalScenario.buttons,
        modalScenario.closable,
        _onSubmit,
        modalScenario.described,
        modalScenario.buttonToExitTheGame,
      );
    } else {
      const chatScenario = scenario.data as ChatScenario;
      const copy: ChatScenario = { ...chatScenario, steps: {} };
      for (const stepId in chatScenario.steps) {
        const step = chatScenario.steps[stepId];
        if (step.type === StepType.test) {
          const { steps } = testToSteps(step);
          copy.steps = { ...copy.steps, ...steps };
        } else {
          copy.steps[stepId] = step;
        }
      }
      dispatch(setChatScenario({ ...scenario, data: copy }));
      history?.push(routes.chat);
    }
  };

export const resetActiveChatScenario = (): AppThunk => (dispatch) => {
  dispatch(resetChatScenario());
};

const isVideoAttached = (): AppThunk<boolean> => (dispatch, getState) => {
  return Boolean(getState().user.info?.files?.presentation);
};

export function checkLinkValid(link: string) {
  // eslint-disable-next-line max-len,no-useless-escape, prettier/prettier
  const pattern = /^((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)$/g;
  return Boolean(pattern.test(link));
}

const isLinkValid = (): AppThunk<boolean> => (dispatch, getState) => {
  // todo: разобраться тут. Переменные не подгружаются, если сценарий уже начат до этого
  // поэтому при валидации ссылки падала страница
  const link = getVariable(getState().scenario.sceneVariables, 'LINK').trim();
  const isValid = checkLinkValid(link);
  if (isValid) {
    dispatch(setUserVideoAsync(link));
  }
  return isValid;
};

const isAnswersValid = (): AppThunk<boolean> => (dispatch, getState) => {
  const chatHistory = getState().scenario.activeSceneHistory;
  let metaStep: SystemStep | undefined = undefined;
  let index = chatHistory.length - 2;
  const answers: AnswerStep[] = [];
  for (; index > 0; index--) {
    if (chatHistory[index].type === StepType.system) {
      metaStep = chatHistory[index] as SystemStep;
      break;
    }
    if (chatHistory[index].type === StepType.answer) {
      answers.unshift(chatHistory[index] as AnswerStep);
    }
  }
  if (!metaStep) {
    return false;
  }
  const meta = metaStep._meta;
  const answersCounter: { [key: string]: number } = {};
  answers.forEach((item) => {
    const buttons: QuestionButton[] = typeof item.data.value === 'string' ? [] : item.data.value;
    if (!answersCounter[buttons[0].text]) {
      answersCounter[buttons[0].text] = 0;
    }
    answersCounter[buttons[0].text]++;
  });
  for (const answer in meta) {
    if (meta[answer] > (answersCounter[answer] || 0)) {
      return false;
    }
  }
  return true;
};

const isAboutMyselfValid = (): AppThunk<boolean> => (dispatch, getState) => {
  const about = getVariable(getState().scenario.sceneVariables, 'TEST_COMMUNICATIVE_GOAL')
    .trim()
    .toLowerCase();
  return about.length > 10;
};

const isCom3Valid = (): AppThunk<boolean> => (dispatch, getState) => {
  const com3 = getVariable(getState().scenario.sceneVariables, 'COM_TEST_3').trim().toLowerCase();
  return Boolean(com3 && com3 !== 'не знаю');
};

const isCom4Valid = (): AppThunk<boolean> => (dispatch, getState) => {
  const com4 = getVariable(getState().scenario.sceneVariables, 'COM_TEST_4').trim().toLowerCase();
  return Boolean(com4 && com4 !== 'не знаю');
};

const isAvatarCreated = (): AppThunk<boolean> => (dispatch, getState) => {
  const avatar = getState().user.info?.profile;
  return Boolean(avatar?.bodyId);
};

const isHelpWithEntrepreneur = (): AppThunk<boolean> => (dispatch, getState) => {
  const scenarios = getState().scenario.scenarios;
  if (!scenarios) {
    return false;
  }
  return Boolean(scenarios[SceneId.scenario_1_25_2]);
};

const isLogicPart1HasAnswers = (): AppThunk<boolean> => (dispatch, getState) => {
  const variables = getState().user?.info?.variables;
  if (!variables) {
    return false;
  }
  return Boolean(variables.LOGIC_TEST_PART1_Q1);
};

const isLogicPart2HasAnswers = (): AppThunk<boolean> => (dispatch, getState) => {
  const variables = getState().user?.info?.variables;
  if (!variables) {
    return false;
  }
  return Boolean(variables.LOGIC_TEST_PART2_Q1);
};

const hasCoins = (): AppThunk<boolean> => (dispatch, getState) => {
  return Boolean(getState().scenario?.scenarios?.[to[ID.hermitageEgg]]);
};

const hasRope = (): AppThunk<boolean> => (dispatch, getState) => {
  const scenarioState = getState().scenario;
  if (!scenarioState || !scenarioState.scenarios) {
    return false;
  }
  let value = false;
  if (scenarioState.scenarios[to[ID.grandMomThirdAct]]) {
    value = true;

    const s25_1 =
      scenarioState.scenarios[to[ID.helpTeacher]] ||
      scenarioState.startedScenarios?.[to[ID.helpTeacher]];

    if (s25_1) {
      const twineStep = s25_1.findIndex((step) => step.id === 'Q3' || step.id === 'Q4');
      if (twineStep !== -1) {
        return false;
      }
    }
  }
  return value;
};

export const checkCondition =
  (condition: StepCondition): AppThunk<string> =>
  (dispatch) => {
    let value = false;
    switch (condition.type) {
      case Conditions.IS_VIDEO_ATTACHED:
        value = dispatch(isVideoAttached());
        break;
      case Conditions.IS_LAST_ANSWERS_VALID:
        value = dispatch(isAnswersValid());
        break;
      case Conditions.IS_ABOUT_MYSELF_VALID:
        value = dispatch(isAboutMyselfValid());
        break;
      case Conditions.IS_COM_TEST_STEP3_VALID:
        value = dispatch(isCom3Valid());
        break;
      case Conditions.IS_COM_TEST_STEP4_VALID:
        value = dispatch(isCom4Valid());
        break;
      case Conditions.IS_AVATAR_CREATED:
        value = dispatch(isAvatarCreated());
        break;
      case Conditions.IS_HELP_WITH_ENTREPRENEUR:
        value = dispatch(isHelpWithEntrepreneur());
        break;
      case Conditions.IS_LOGIC_PART1_HAS_ANSWERS:
        value = dispatch(isLogicPart1HasAnswers());
        break;
      case Conditions.IS_LOGIC_PART2_HAS_ANSWERS:
        value = dispatch(isLogicPart2HasAnswers());
        break;
      case Conditions.HAS_COINS:
        value = dispatch(hasCoins());
        break;
      case Conditions.HAS_ROPE:
        value = dispatch(hasRope());
        break;
    }
    return condition.variants[value ? 0 : 1];
  };

export const skipActiveScenarioAsync =
  (onSuccess: () => void): AppThunk =>
  async (dispatch, getState) => {
    if (isDebug) {
      const { activeChatScenario, activeSceneHistory } = getState().scenario;
      if (activeChatScenario) {
        try {
          await dispatch(saveHistoryAsync(activeChatScenario.scenarioId, activeSceneHistory, true));
          onSuccess();
        } catch (ignore) {
          Logger.log(ignore);
        }
      }
    }
  };
