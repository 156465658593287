/* eslint-disable max-len */
import React from 'react';

export const IconPhone: React.FC = () => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.61044 9.39357C11.9347 12.7169 12.6888 8.87218 14.8054 10.9873C16.8459 13.0272 18.0187 13.4359 15.4334 16.0205C15.1095 16.2808 13.052 19.4118 5.82115 12.183C-1.41058 4.95325 1.71864 2.89362 1.97896 2.56988C4.57054 -0.0218765 4.9722 1.15774 7.01273 3.19769C9.12929 5.31366 5.2862 6.07026 8.61044 9.39357Z'
      stroke='#808191'
      strokeOpacity='0.6'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
