import styled, { css } from 'styled-components';
import { MapSourceStage } from './MapSourceTypes';

interface StageProps {
  $stage: MapSourceStage;
}

interface ImageProps {
  $transparent?: boolean;
  $hidden?: boolean;
}

export const StyledMapSourceWrapper = styled.div<StageProps>`
  width: 100%;
  margin: 0 auto;
  position: relative;

  ${({ $stage }) => {
    switch ($stage) {
      //case MapSourceStage.stage1:
      // return css`
      //   min-width: 2326px;

      //   @media screen and (min-width: 2000px) {
      //     min-width: 4000px;
      //   }

      //   @media screen and (min-width: 4000px) {
      //     min-width: 6000px;
      //   }
      // `;
      case MapSourceStage.stage1:
      case MapSourceStage.stage2:
      case MapSourceStage.stage2act2:
      case MapSourceStage.stage2act3:
      case MapSourceStage.stage3act1:
      default:
        return css`
          min-width: 5620px;

          @media screen and (min-width: 2000px) {
            min-width: 9664px;
          }

          @media screen and (min-width: 4000px) {
            min-width: 14496px;
          }
        `;
    }
  }}
`;

export const StyledMapSource = styled.div<StageProps>`
  top: 0;
  left: 0;
  position: absolute;

  height: 0;
  width: 100%;

  ${({ $stage }) => {
    switch ($stage) {
      //case MapSourceStage.stage1:
      // return css`
      //   padding-bottom: 46.43164230438521%;
      // `;
      case MapSourceStage.stage1:
      case MapSourceStage.stage2:
      case MapSourceStage.stage2act2:
      case MapSourceStage.stage2act3:
      case MapSourceStage.stage3act1:
      case MapSourceStage.stage3act2:
      default:
        return css`
          padding-bottom: 18.75%;
        `;
    }
  }}
`;

export const StyledMapSourceImage = styled.img<ImageProps>`
  top: 0;
  left: 0;
  z-index: 2;
  position: absolute;

  object-fit: cover;

  width: 100%;
  height: 100%;
  min-height: 100%;

  transition: opacity 2s;
  opacity: ${(props) => (props.$hidden ? 0 : 1)};

  background: ${(props) =>
    props.$transparent
      ? undefined
      : css`linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.9) 4.04%,
      rgba(255, 255, 255, 0.0001) 37.27%
    ),
    #dbf3ff;`};
`;
