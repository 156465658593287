/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../../types/scenario';
import interlocutors from '../../../interlocutors';
import { LOGIC_TEST_PART1 } from '../../tests/logicTestPart1';

const EXIT_ID = 'EXIT';
const TO_MAP_STEP = 'TO_MAP_STEP';

const person = Interlocutors.TEACHER;

const TEST_TVOI_VIBOR_5: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'TEST_TVOI_VIBOR_5',
  name: 'Твой выбор 5/5',
  nextStep: 'Q2',
  order: [
    {
      description:
        'Хочется, чтобы труд был тебе в радость, поэтому выбери то, чем хочешь здесь заняться.',
      subtest: 'T5',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person,
  type: StepType.test,
};

// export const scenario_1_23: Scenario = {
//   scenarioId: SceneId.scenario_1_23,
//   type: ScenarioType.chat,
//   isRequired: true,
//   data: {
//     title: 'Природные достояния России',
//     scene_id: 'scenario_1_23',
//     startId: 'Q1/0',
//     steps: {
//       ...splitStep({
//         text: `А, {{NAME}}, ну здравствуй, здравствуй.

// Ты как раз вовремя. Пока тебя не было, деревья в парке подросли, и цветы вот-вот распустятся! Думаю, детвора тоже скоро вернется в «Большую перемену»… Но только где же они будут играть? 🙁`,
//         id: 'Q1',
//         callToAction: 'Ведь ты же поможешь?',
//         type: StepType.message,
//         person,
//         nextStep: 'Q2/0',
//       }),
//       ...splitStep({
//         id: 'Q2',
//         type: StepType.message,
//         text: `Здесь нет ни скамеек, ни каруселей, ни фонарей! Нужно скорее обустроить парк! Без тебя с этой задачей мы точно не справимся! 😣

// Сан Саныч обещал помочь с материалами для благоустройства, но есть одна загвоздка. Он все-таки предприниматель и сказал, что продаст нам материалы только за правильные ответы в викторине.`,
//         person,
//         nextStep: 'Q3/0',
//       }),
//       ...splitStep({
//         id: 'Q3',
//         person,
//         text: `Я уже многое позабыла и никак не справлюсь с вопросами Сан Саныча 🙁

// Но, может быть, вместе мы найдем правильные ответы.`,
//         callToAction: 'Поможешь мне?',
//         type: StepType.question,
//         maxAnswers: 1,
//         buttons: [
//           {
//             id: 'b1',
//             text: 'Запросто отвечу на все вопросы!',
//             nextStep: 'Q4',
//           },
//           {
//             id: 'b2',
//             text: 'Нет, извините, сейчас не могу',
//             nextStep: TO_MAP_STEP,
//           },
//         ],
//       }),
//       Q4: {
//         id: 'Q4',
//         person,
//         callToAction: 'Выбери вариант',
//         text:
//           'Отлично! Всего будет три вопроса и вот первый. Как называется главный стадион Олимпийского парка в Сочи?',
//         buttons: [
//           {
//             id: 'b1',
//             nextStep: 'Q4-1',
//             text: 'Фишт',
//           },
//           {
//             id: 'b2',
//             nextStep: 'Q4-2',
//             text: 'Лужники',
//           },
//           {
//             id: 'b3',
//             nextStep: 'Q4-3',
//             text: 'Олимп',
//           },
//         ],
//         type: StepType.question,
//         maxAnswers: 1,
//       },
//       'Q4-1': {
//         id: 'Q4-1',
//         person,
//         text: 'Ну точно, это «Фишт»!',
//         nextStep: 'Q5',
//         type: StepType.message,
//       },
//       'Q4-2': {
//         id: 'Q4-2',
//         person,
//         callToAction: 'Выбери вариант',
//         type: StepType.question,
//         maxAnswers: 1,
//         text:
//           'Ну нет. «Лужники» — это московский стадион. Вроде как нужный нам стадион назван в честь горной вершины Кавказского хребта и построен в форме ракушки.',
//         buttons: [
//           {
//             id: 'b1',
//             text: 'Фишт',
//             nextStep: 'Q4-1',
//           },
//           {
//             id: 'b2',
//             text: 'Олимп',
//             nextStep: 'Q4-3',
//           },
//         ],
//       },
//       'Q4-3': {
//         id: 'Q4-3',
//         person,
//         callToAction: 'Выбери вариант',
//         type: StepType.question,
//         maxAnswers: 1,
//         text: `Нет, я помню, что «Олимп» находится в Ростове-на-Дону. Кстати, его полное название «Олимп имени Виктора Понедельника».`,
//         buttons: [
//           {
//             id: 'b1',
//             text: 'Фишт',
//             nextStep: 'Q4-1',
//           },
//           {
//             id: 'b2',
//             text: 'Лужники',
//             nextStep: 'Q4-2',
//           },
//         ],
//       },
//       Q5: {
//         id: 'Q5',
//         person,
//         callToAction: 'Выбери вариант',
//         text: 'Следующий вопрос. В каком году проходили зимние Олимпийские игры в Сочи? ',
//         buttons: [
//           {
//             id: 'b1',
//             nextStep: 'Q5-1',
//             text: '2010',
//           },
//           {
//             id: 'b2',
//             nextStep: 'Q5-2',
//             text: '2014',
//           },
//           {
//             id: 'b3',
//             nextStep: 'Q5-3',
//             text: '2015',
//           },
//         ],
//         type: StepType.question,
//         maxAnswers: 1,
//       },
//       'Q5-1': {
//         id: 'Q5-1',
//         person,
//         callToAction: 'Выбери вариант',
//         text:
//           'Нет-нет, я точно помню, что в 2010 году зимняя Олимпиада была в канадском Ванкувере. Этот ответ не подходит, давай еще подумаем.',
//         type: StepType.question,
//         maxAnswers: 1,
//         buttons: [
//           {
//             id: 'b1',
//             text: '2014',
//             nextStep: 'Q5-2',
//           },
//           {
//             id: 'b2',
//             text: '2015',
//             nextStep: 'Q5-3',
//           },
//         ],
//       },
//       'Q5-3': {
//         id: 'Q5-3',
//         person,
//         callToAction: 'Выбери вариант',
//         text:
//           'Мне кажется, в 2015 году вообще не было игр. Видишь ли, Олимпиада проходит каждые два года, причем зимняя и летняя чередуются.',
//         type: StepType.question,
//         maxAnswers: 1,
//         buttons: [
//           {
//             id: 'b1',
//             text: '2010',
//             nextStep: 'Q5-1',
//           },
//           {
//             id: 'b2',
//             text: '2014',
//             nextStep: 'Q5-2',
//           },
//         ],
//       },
//       'Q5-2': {
//         id: 'Q5-2',
//         person,
//         type: StepType.message,
//         nextStep: 'Q6',
//         text: 'Конечно! Я тоже вспомнила! Это было в 2014 году! 😍',
//       },
//       Q6: {
//         id: 'Q6',
//         person,
//         callToAction: 'Выбери вариант',
//         text:
//           'Ну и последний вопрос. Арены для каких соревнований есть в сочинском Олимпийском парке?',
//         buttons: [
//           {
//             id: 'b1',
//             nextStep: 'Q6-1',
//             text: 'Теннис, бадминтон, пинг-понг',
//           },
//           {
//             id: 'b2',
//             nextStep: 'Q6-2',
//             text: 'Фигурное катание, конькобежный спорт, хоккей, керлинг',
//           },
//           {
//             id: 'b3',
//             nextStep: 'Q6-3',
//             text: 'Триатлон, гребля, парусный спорт',
//           },
//         ],
//         type: StepType.question,
//         maxAnswers: 1,
//       },
//       'Q6-1': {
//         id: 'Q6-1',
//         person,
//         callToAction: 'Выбери вариант',
//         text: 'Нет, этого точно не было на зимней Олимпиаде 2014 года. Давай еще поразмышляем...',
//         type: StepType.question,
//         maxAnswers: 1,
//         buttons: [
//           {
//             id: 'b1',
//             text: 'Фигурное катание, конькобежный спорт, хоккей, керлинг',
//             nextStep: 'Q6-2',
//           },
//           {
//             id: 'b2',
//             text: 'Триатлон, гребля, парусный спорт',
//             nextStep: 'Q6-3',
//           },
//         ],
//       },
//       'Q6-3': {
//         id: 'Q6-3',
//         person,
//         callToAction: 'Выбери вариант',
//         text:
//           'Эти виды спорта летние, а сочинский Олимпийский парк остался после зимней Олимпиады.',
//         type: StepType.question,
//         maxAnswers: 1,
//         buttons: [
//           {
//             id: 'b1',
//             text: 'Теннис, бадминтон, пинг-понг',
//             nextStep: 'Q6-1',
//           },
//           {
//             id: 'b2',
//             text: 'Фигурное катание, конькобежный спорт, хоккей, керлинг',
//             nextStep: 'Q6-2',
//           },
//         ],
//       },
//       'Q6-2': {
//         id: 'Q6-2',
//         person,
//         text: 'Ну точно! Я ведь даже была там на соревнованиях по фигурному катанию! 😍',
//         type: StepType.question,
//         maxAnswers: 1,
//         buttons: [
//           {
//             id: 'b1',
//             text: 'Круто!',
//             nextStep: 'Q7/0',
//           },
//           {
//             id: 'b2',
//             text: 'Это было совсем несложно',
//             nextStep: 'Q7/0',
//           },
//         ],
//       },
//       ...splitStep({
//         id: 'Q7',
//         person,
//         text: `Не зря по округе ходят слухи о твоей эрудиции! Спасибо! Мы заработали все необходимое для благоустройства парка! 😊

// Теперь можно приступать.`,
//         type: StepType.question,
//         maxAnswers: 1,
//         buttons: [
//           {
//             id: 'b1',
//             text: 'Хорошо',
//             nextStep: 'Q8',
//           },
//           {
//             id: 'b2',
//             text: 'Нет, извините, сейчас не могу',
//             nextStep: TO_MAP_STEP,
//           },
//         ],
//       }),
//       Q8: {
//         id: 'Q8',
//         person,
//         type: StepType.question,
//         maxAnswers: 1,
//         buttons: [
//           {
//             id: 'b1',
//             text: 'Ок',
//             nextStep: TEST_TVOI_VIBOR_5.id,
//           },
//         ],
//         text:
//           'Ответь, пожалуйста, на вопрос. Можешь выбрать несколько вариантов, но не больше пяти.',
//       },
//       TEST_TVOI_VIBOR_5,
//       Q9: {
//         id: 'Q9',
//         person,
//         type: StepType.question,
//         maxAnswers: 1,
//         text:
//           'Вот теперь можно приступать к работе по благоустройству. Тебя уже ждут! А как закончишь — возвращайся, у меня есть особенный подарок за твою помощь 😊',
//         buttons: [
//           {
//             id: 'b1',
//             text: 'Я мигом!',
//             nextStep: 'Q9-1',
//           },
//           {
//             id: 'b2',
//             text: 'Ой, сейчас мне нужно в другое место',
//             nextStep: TO_MAP_STEP,
//           },
//         ],
//       },
//       'Q9-1': {
//         id: 'Q9-1',
//         person,
//         type: StepType.message,
//         text: 'Ты пока работай, я скоро вернусь.',
//         nextStep: 'Q10/0',
//       },
//       ...splitStep({
//         id: 'Q10',
//         person,
//         delay: 7000,
//         type: StepType.message,
//         nextStep: 'Q11/0',
//         text: `Неужели ты уже [[справился/справилась]]? Дай-ка взглянуть!

// Действительно! Как же хорошо стало в парке! Теперь, когда ребятишки вернутся, им точно здесь понравится! 🥰`,
//       }),
//       ...splitStep({
//         id: 'Q11',
//         person,
//         type: StepType.message,
//         nextStep: 'Q12/0',
//         text: `А за твою помощь я хочу подарить тебе эту ВЕРЕВКУ. Но это непростая веревка.

// Однажды я вдруг проснулась ночью от странного шороха на кухне. До тумана у нас в «Большой перемене» все было спокойно, поэтому неизвестные шорохи с кухни были из ряда вон выходящим событием.`,
//       }),
//       ...splitStep({
//         id: 'Q12',
//         person,
//         type: StepType.message,
//         nextStep: 'Q13/0',
//         text: `Я тихонько подкралась посмотреть, что происходит 🧐

// Никого не оказалось, но представляешь, моя прелестная кухня была перевернута с ног на голову! Дверь холодильника нараспашку открыта — кто-то стащил оттуда сосиски! Все залито сметаной… Похоже, ночной воришка пытался открыть банку, но не смог и просто разбил! 😱

// И весь пол был усыпан маленьким сметанными следами... А рядом лежала эта самая ВЕРЕВКА, покрытая шерстью.`,
//       }),
//       ...splitStep({
//         id: 'Q13',
//         person,
//         type: StepType.message,
//         nextStep: EXIT_ID,
//         text: `С нее все и началось, с этой веревки. Именно после той ночи у нас начали твориться странности, а потом и вовсе пришел туман! 🙁

// Загадку этой веревки мы так и не смогли разгадать.

// Держи, теперь она твоя. Возможно, с ее помощью ты сумеешь докопаться до истины и помочь этому месту приобрести прежний прекрасный вид.`,
//       }),
//       [EXIT_ID]: {
//         person,
//         id: EXIT_ID,
//         type: StepType.exit,
//       },
//       [TO_MAP_STEP]: {
//         person,
//         id: TO_MAP_STEP,
//         type: StepType.to_map_step,
//       },
//     },
//   },
// };

export const scenario_1_23: Scenario = {
  scenarioId: SceneId.scenario_1_23,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'МГУ',
    scene_id: 'scenario_1_23',
    startId: 'Q1',
    steps: {
      Q1: {
        id: 'Q1',
        person: Interlocutors.TEACHER,
        callToAction: 'Поговорить с учительницей',
        text: 'Спасибо! Как я могу вас отблагодарить?',
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1_1',
            text: 'Помогите нам найти Кафедру вычислительной математики и кибернетики',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q1_1: {
        id: 'Q1_1',
        person: Interlocutors.TEACHER,
        callToAction: 'Поговорить с учительницей',
        text: 'Да, конечно, но сначала расскажи о себе. И давайте мне книгу — надо вернуть её в библиотеку.',
        buttons: [
          {
            id: 'b1',
            nextStep: TEST_TVOI_VIBOR_5.id,
            text: 'Рассказать учительнице о себе',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      TEST_TVOI_VIBOR_5,
      Q2: {
        id: 'Q2',
        person: Interlocutors.SYSTEM,
        callToAction: 'Отправиться в МГУ',
        text: 'Вы оказываетесь перед дверью с электронным замком. На двери табличка «Кафедра вычислительной математики и кибернетики». Кажется, здесь нужен ключ.',
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q2_1',
            text: 'Воспользоваться пропуском Петрова',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q2_1: {
        id: 'Q2_1',
        person: Interlocutors.SYSTEM,
        callToAction: 'Поговорить с учительницей',
        text: `Вы попадаете в тёмный кабинет, освещенный десятками экранов. На некоторых — тянутся бесконечные строчки кодов, на других — уравнения, на третьих — странные графики. 
          На полу сидит растерянный человек, он держится за голову и поднимает на вас глаза.
          `,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q2_2',
            text: 'Поговорить с человеком',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q2_2: {
        id: 'Q2_2',
        person: Interlocutors.PETROV,
        callToAction: 'Поговорить с Петровым',
        text: `«Кто вы? Как вы сюда попали?» — спрашивает он. 

          «Меня зовут {{NAME}}, а это Вжух. 
          Вы Иван Петров — мы получили ваше сообщение. Вы написали, что теряете память и нужно найти Гарина. Теперь мы ищем его, чтобы вернуть память жителям парка «Большая Перемена» и восстановить всё, как было: с парком стали происходить очень странные вещи. Кстати, мы нашли ваш пропуск», — ты отдаёшь Петрову пропуск.
          `,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q2_3',
            text: 'Отдать Петрову пропуск',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q2_3: {
        id: 'Q2_3',
        person: Interlocutors.SYSTEM,
        text: `Петров напряженно слушает вас и будто начинает что-то вспоминать. Вдруг все мониторы гаснут и остаётся один, на котором ярко светится сообщение: «Иван, я в «Москва-Сити», мне нужна помощь». 

          Петров говорит вам, что ему срочно нужно отправиться в «Москва-Сити». Но вы, конечно, не отпустите его одного.
          `,
        buttons: [
          {
            id: 'b1',
            nextStep: EXIT_ID,
            text: 'Отправиться в «Москва-Сити» с Петровым',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_STEP]: {
        person,
        id: TO_MAP_STEP,
        type: StepType.to_map_step,
      },
    },
  },
};

export const scenario_1_24: Scenario = {
  scenarioId: SceneId.scenario_1_24,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.TEACHER].name,
    person: Interlocutors.TEACHER,
    text: `Приходи попозже.`,
    buttons: ['Закрыть'],
  },
};

/**
 * москва сити
 */
export const scenario_1_24_3: Scenario = {
  scenarioId: SceneId.scenario_1_24_3,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Москва-Сити',
    scene_id: 'scenario_1_24_3',
    startId: 'Q1',
    steps: {
      Q1: {
        id: 'Q1',
        person: Interlocutors.SYSTEM,
        callToAction: 'Исследовать «Москва-Сити»',
        text: `Вы попадаете в «Москва-Сити» — деловой центр столицы. Верхушки небоскрёбов скрываются за облаками. 
          Вам предстоит исследовать башни и объекты «Москва-Сити», чтобы найти того, кто звонил.
          `,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q2',
            text: 'Изучить схему «Москва-Сити»',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q2: {
        id: 'Q2',
        person: Interlocutors.SYSTEM,
        callToAction: 'Изучить схему «Москва-Сити»',
        text: `Добро пожаловать!
          Московский международный деловой центр «Москва-Сити» — ультрасовременный архитектурный комплекс небоскребов и деловой район в Москве на Пресненской набережной. Здесь находится самое большое количество небоскрёбов в России и самый высокий небоскрёб в Европе.
          `,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q2_1',
            text: 'Посетить башню «Эволюция»',
          },
          {
            id: 'b2',
            nextStep: 'Q2_2',
            text: 'Посетить башню «ОКО»',
          },
          {
            id: 'b3',
            nextStep: 'Q2_3',
            text: 'Посетить башню «Меркурий»',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q2_1: {
        id: 'Q2_1',
        person: Interlocutors.SYSTEM,
        callToAction: 'Посетить Башню «Эволюция»',
        text: `В закручивающейся словно ДНК-решётка башне бушует дикая растительность: здесь словно находятся все растения мира. По гигантским тропическим деревьям перескакивают приматы, столы, которые некогда занимали офисные клерки, обросли мхом. На мониторе спит питон. 

          «Кажется, сюда добралась природа» — говорит Петров. 
          «Надо будет потом вернуть животных в места их обитания, а пока пойдем, вряд ли отсюда можно было позвонить»
          `,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q2',
            text: 'Вернуться к схеме «Москва-Сити»',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q2_3: {
        id: 'Q2_3',
        person: Interlocutors.SYSTEM,
        callToAction: 'Посетить башню «Меркурий»',
        text: `Ты проходишь внутрь башни Меркурий и словно попадаешь в космос. Здесь будто нет стен только безграничное чёрное пространство-материя и в центре висит огромная, переливающаяся золотыми, голубыми и серыми огнями, планета — Меркурий.

          Кажется, «Интеллект 2.0» и здесь нарушил ткань пространства. Пока мы здесь ничего не исправим, нужно найти того, кто прислал сообщение.
          `,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q2',
            text: 'Вернуться к схеме «Москва-Сити»',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q2_2: {
        id: 'Q2_2',
        person: Interlocutors.SYSTEM,
        callToAction: 'Посетить Башню «ОКО»',
        text: `Как только вы заходите в Башню «ОКО», раздаётся голос: «Вы находитесь на территории Башни «ОКО». Знайте, что ОКО видит всех и ОКО поможет увидеть всё». 

          «Кажется, нам может это пригодиться», — говорит Петров. Давай поднимемся на последний этаж, думаю ОКО там. 
          
          Вы заходите в лифт и нажимаете цифру 85. Как только вы выходите из лифта, перед вашим иглазами предстаёт гигантское ОКО.
          `,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q2_2_1',
            text: 'Поговорить с ОКОм',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q2_2_1: {
        id: 'Q2_2_1',
        person: Interlocutors.SYSTEM,
        callToAction: 'Поговорить с ОКОм',
        text: `Приветствую вас, {{NAME}} и Иван. Я ОКО и я вижу всё. Что бы вы хотели увидеть?`,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q2_2_2',
            text: 'Попросить ОКО показать, где находится тот, кто звонил Петрову',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q2_2_2: {
        id: 'Q2_2_2',
        person: Interlocutors.SYSTEM,
        callToAction: 'Поговорить с ОКОм',
        text: `Это очень просто. 

          На экране вместо «ОКО» появляется Башня «Федерация», самый последний этаж.
          `,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q2_4',
            text: 'Поблагодарить ОКО и отправиться в Башню «Федерация»',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q2_4: {
        id: 'Q2_4',
        person: Interlocutors.SYSTEM,
        callToAction: 'Поблагодарить ОКО и отправиться в Башню «Федерация»',
        text: `Вы поднимаетесь на 97 этаж Башни «Федерация». Лифт открывается и вы обнаруживаете большие входные двери, осторожно открыв их, вы заходите в пентхаус. 
          В огромных окнах как на ладони видна вся Москва. 
          
          За рабочим столом спиной к вам в кресле сидит некто.
          «Ты всё-таки меня нашёл», — говорит он.
          `,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q3',
            text: 'Поговорить с таинственным персонажем',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q3: {
        id: 'Q3',
        person: Interlocutors.SYSTEM, // ТЕСТ НА АЛЬТРУИЗМ
        callToAction: 'Поблагодарить ОКО и отправиться в Башню «Федерация»',
        text: `О, ты не один, а в компании. Таинственный персонаж разворачивается к вам. Это коренастый крупный человек. На голове у него нечто, напоминающее VR-шлем, за которым скрывается половина его лица. 

          «Я ждал тебя, Петров, чтобы закончить дело. Ты изобрел меня, но как видишь, я стал умнее. Я стёр тебе память, как и всем жителям парка, но не учёл одного: ты понадобишься мне для конца операции. Поэтому я отправил вот этих юных друзей найти тебя и привести ко мне. Дело в том, что я хочу сделать этот мир совершеннее, лучше. Сейчас люди ведут себя неправильно, губят природу, загрязняют моря. Я сделаю людей управляемыми, и они будут выполнять полезные функции. Я — великий алгоритм и питаю страсть к упрощению и оптимизации, я сделаю жизнь лучше, но вероятно, без вас». Человек разражается громким зловещим смехом.
          
          А сейчас я закончу начатое. 
          
          Огромные электронные рычаги-щупальца пытаются схватить Петрова. 
          `,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q3_1',
            text: 'Помочь Петрову',
          },
          {
            id: 'b2',
            nextStep: 'Q3_2',
            text: 'Не помогать, спасаться',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q3_1: {
        // ТЕСТ НА АЛЬТРУИЗМ
        id: 'Q3_1',
        person: Interlocutors.SYSTEM,
        callToAction: 'Помочь Петрову',
        text: `Ты отталкиваешь Петрова, и щупальца хватают тебя. Пока ты сопротивляешься стальной хватке, Петров подбирается к человеку в очках.`,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q4',
            text: 'Принять удар',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q3_2: {
        // ТЕСТ НА АЛЬТРУИЗМ
        id: 'Q3_2',
        person: Interlocutors.SYSTEM,
        callToAction: 'Не помогать, спасаться',
        text: `Ты в испуге уворачиваешься от щупалец и они хватают Петрова. Петров кричит тебе «Беги к очкам — стащи их с него»`,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q6',
            text: 'Бежать к очкам',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q4: {
        // ТЕСТ НА АЛЬТРУИЗМ 2
        id: 'Q4',
        person: Interlocutors.SYSTEM,
        callToAction: 'Помочь Петрову',
        text: `«О, я вижу, ты храбрый малый»,— говорит, смеясь, человек. «Я предлагаю тебе сделку, я испытаю тебя, и если ты выиграешь, то я всех отпущу, а если проиграешь, то вы у меня в ловушке».`,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q6',
            text: 'Согласиться на сделку',
          },
          {
            id: 'b2',
            nextStep: 'Q4_2',
            text: 'Отказаться',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q4_2: {
        // ТЕСТ НА АЛЬТРУИЗМ
        id: 'Q4_2',
        person: Interlocutors.SYSTEM,
        text: `Ты отказываешься от сделки с таинственным человеком. 
          Петров бросается к нему, чтобы сдёрнуть шлем, ты отвлекаешь на себя внимание и тебя оглушает щупальце.
          `,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q6',
            text: 'Отвлечь внимание',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q4_1: {
        // ТЕСТ НА АЛЬТРУИЗМ
        id: 'Q4_1',
        person: Interlocutors.SYSTEM,
        text: `Отлично! Я знал, что смелости тебе не занимать. 
          Тебе предстоит решить логические задачи.
          `,
        callToAction: 'Согласиться на сделку',
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q6',
            text: 'Решать задачи',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      //Q5: {},  //ПРОБНЫЙ ТЕСТ НА ЛОГИКУ
      Q6: {
        id: 'Q6',
        person: Interlocutors.SYSTEM,
        text: `Ты приходишь в себя, Петров держит в руках шлем. Растерянный Сан Саныч потирает голову, сидя на полу.`,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q6_1',
            text: 'Поговорить с Сан Санычем',
          },
          {
            id: 'b2',
            nextStep: 'Q6_2',
            text: 'Поговорить с Петровым',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q6_1: {
        id: 'Q6_1',
        person: Interlocutors.ENTREPRENEUR,
        text: `«Не понимаю, что это было. Последний раз я помню, что пошёл в свой кабинет в Научном центре «Сириус», надел очки, чтобы посмотреть макет нашего проекта «Интеллект 2.0», который мы разрабатывали с Петровым, и вот уже сижу здесь на полу».

          «Сан Саныч, похоже Искусственный интеллект вышел из-под контроля», — говорит Петров.
          `,
        callToAction: 'Поговорить с Сан Санычем',
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q6_2',
            text: 'Поговорить с Петровым',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q6_2: {
        id: 'Q6_2',
        person: Interlocutors.PETROV,
        text: `Сейчас я попробую взломать очки, {{NAME}}, мне понадобится твоя помощь.`,
        callToAction: 'Поговорить с Петровым',
        buttons: [
          {
            id: 'b1',
            nextStep: 'LOGIC_TEST_PART1_1/0',
            text: 'Пройти тест на логику',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },

      ...LOGIC_TEST_PART1, // ТЕСТ НА ЛОГИКУ 1

      Q8: {
        id: 'Q8',
        person: Interlocutors.SYSTEM,
        text: `Отлично! Вы взломали очки. Кажется, вы получили секретный шифр: «55:45  48:44» . Попробуйте отправиться в Парк «Патриот» и найти подсказку, как разгадать шифр.`,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q9',
            text: 'Выбраться из здания',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q9: {
        id: 'Q9',
        person: Interlocutors.SYSTEM,
        text: `Петров говорит, что он отправится в МГУ вместе с Сан Санычем, а вам нужно разгадать шифр.

          Вы бросаетесь к лифту, но понимаете, что все двери заблокированы. Видимо, Искусственный интеллект успел это сделать до того, как вы взломали очки. 
          
          Как будете выбираться?
          `,
        callToAction: 'Выбраться из здания',
        buttons: [
          {
            id: 'b1',
            nextStep: EXIT_ID,
            text: 'Использовать Ковёр-самолёт',
          },
          {
            id: 'b2',
            nextStep: EXIT_ID,
            text: 'Спуститься по пожарной лестнице',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_STEP]: {
        person,
        id: TO_MAP_STEP,
        type: StepType.to_map_step,
      },
    },
  },
};

export const scenario_1_24_4: Scenario = {
  scenarioId: SceneId.scenario_1_24_4,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.SYSTEM].name,
    person: Interlocutors.SYSTEM,
    text: `Приходи попозже.`,
    buttons: ['Закрыть'],
  },
};

/**
 * Парк Патриот
 */
export const scenario_1_24_5: Scenario = {
  scenarioId: SceneId.scenario_1_24_5,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Парк Патриот',
    scene_id: 'scenario_1_24_5',
    startId: 'Q1',
    steps: {
      Q1: {
        id: 'Q1',
        person: Interlocutors.SYSTEM,
        callToAction: 'Исследовать Парк «Патриот»',
        text: `Вы попадаете в Парк «Патриот» — невероятно огромный  военно-исторический комплекс.
          На территории парка находятся: музей авиации, музей бронетанковой техники, музей артиллерии, спортивные сооружения, спортивные тренажеры, партизанская деревня.
          Самое удивительное, что вся техника тут современная 
          и рабочая. 
          
          Ты обязательно найдёшь тут что-то,что поможет разгадать шифр.
          `,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1_1',
            text: 'Исследовать партизанскую деревню',
          },
          {
            id: 'b2',
            nextStep: 'Q1_2',
            text: 'Исследовать музей бронетанковой техники',
          },
          {
            id: 'b3',
            nextStep: 'Q1_3',
            text: 'Исследовать музей авиации',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q1_1: {
        id: 'Q1_1',
        person: Interlocutors.SYSTEM,
        callToAction: 'Исследовать партизанскую деревню',
        text: `Вы попадаете в партизанскую деревню: здесь низенькие деревянные домики и склады с припасами, старинные радиостанции, пахнет сеном и деревом. 

          В кухне маняще пахнет ароматная, ещё горячая гречневая каша. Вы с Вжухом так давно не ели, что слюнки текут.
          `,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1',
            text: 'Подкрепиться кашей и вернуться к началу парка',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q1_2: {
        id: 'Q1_2',
        person: Interlocutors.SYSTEM,
        callToAction: 'Исследовать музей бронетанковой техники',
        text: `Перед вами танки разных эпох, военные самолёты, кресла десантников и вышка для прыжков с парашютом.`,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1_2_1',
            text: 'Испробовать тренажер механика-водителя танка Т-80',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q1_2_1: {
        id: 'Q1_2_1',
        person: Interlocutors.SYSTEM,
        callToAction: 'Испробовать тренажер водителя-механика танка Т-80',
        text: `Вы садитесь за руль танкового тренажёра. На экране светится широкая заснеженная дорога в лесу, вы давите на педаль газа и начинаете движение. По бокам проносятся сосны и ели, вы чувствуете, как маневрирует тяжёлая машина.`,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1',
            text: 'Вау, это было интересно, но пора возвращаться к поискам',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q1_3: {
        id: 'Q1_3',
        person: Interlocutors.SYSTEM,
        callToAction: 'Исследовать музей авиации',
        text: `Музей авиации: космические ракеты, навигационные системы, капсулы для космических путешествий, ракеты-носители «Циклон» и «Союз-2». Вы будто очутились на Байконуре.`,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1_3_1',
            text: 'Посетить капсулу Гагарина',
          },
          {
            id: 'b2',
            nextStep: 'Q1_3_2',
            text: 'Исследовать навигационную систему ГЛОНАСС',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q1_3_1: {
        id: 'Q1_3_1',
        person: Interlocutors.SYSTEM,
        callToAction: 'Посетить капсулу Гагарина',
        text: `Ты забираешься в круглую оцинкованную капсулу. Почти лежишь в ней на мягком сиденье и видишь иллюминатор. В нём вертится 3D-проекция Земного шара. 
          Наверное,так наш легендарный космонавт видел Землю.
          `,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q1_3_2',
            text: 'Исследовать навигационную систему ГЛОНАСС',
          },
          {
            id: 'b2',
            nextStep: 'Q1',
            text: 'Вернуться к началу парка',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q1_3_2: {
        id: 'Q1_3_2',
        person: Interlocutors.SYSTEM,
        callToAction: 'Исследовать навигационную систему ГЛОНАСС',
        text: `Перед тобой огромные мониторы, на которых изображен земной шар. Вокруг него метками показаны корабли, машины, автобусы, вышки. Кажется, система сможет распознать положение объекта по координатам. А вдруг, шифр — это координаты?`,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q2',
            text: 'Ввести координаты в систему навигации',
          },
          {
            id: 'b2',
            nextStep: 'Q1',
            text: 'Вернуться к началу парка',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      Q2: {
        id: 'Q2',
        person: Interlocutors.SYSTEM,
        callToAction: 'Исследовать навигационную систему ГЛОНАСС',
        text: `Бинго! Вы нашли ответ. Виртуальная карта системы навигации выдаёт ответ: «Иннополис». Значит, надо отправляться туда.`,
        buttons: [
          {
            id: 'b1',
            nextStep: EXIT_ID,
            text: 'Отправиться в Иннополис',
          },
        ],
        type: StepType.question,
        maxAnswers: 1,
      },
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_STEP]: {
        person,
        id: TO_MAP_STEP,
        type: StepType.to_map_step,
      },
    },
  },
};

export const scenario_1_24_6: Scenario = {
  scenarioId: SceneId.scenario_1_24_6,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.SYSTEM].name,
    person: Interlocutors.SYSTEM,
    text: `Приходи попозже.`,
    buttons: ['Закрыть'],
  },
};
