import styled from 'styled-components';
import colors from '../../../common/shared/constants/colors';

import InputToolbar from '../../components/chat/InputToolbar';

import { BaseProps } from '../../../common/shared/styles/styledBase';
import { TextStyled } from '../../../common/shared/ui/Text';
import {
  BREAKPOINT_PHONE,
  BREAKPOINT_TABLET,
  GAP,
} from '../../../common/shared/constants/constants';

export const StyledChatPage = styled.div`
  height: 100%;
  background: ${colors.background};
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: calc(100dvh - 168px);

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    border-radius: ${GAP.m};
  }
`;

export const StyledChatPagePerson = styled(TextStyled)<BaseProps>`
  display: none;
  padding: 0 ${GAP.xl};

  margin-top: 18px;
  margin-bottom: 26px;

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    padding: 0 20px;
    display: block;
  }

  @media screen and (min-width: ${BREAKPOINT_TABLET}) {
    padding: 0 30px;
  }
`;

export const StyledChatPageMessages = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: scroll;
  flex-direction: column-reverse;

  padding: 0 14px;

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    padding: 0 20px;
  }

  @media screen and (min-width: ${BREAKPOINT_TABLET}) {
    padding: 0 30px;
  }
`;

export const StyledChatPageMessagesWrapper = styled.div`
  width: 100%;
`;

export const StyledChatPageInputToolbar = styled(InputToolbar)`
  width: 100%;
  padding: ${GAP.xl};
  padding-top: 0;
`;
