/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_mgu_1_1_1: Scenario = {
  scenarioId: SceneId.stg2_mgu_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.TEACHER,
    title: 'МГУ',
    text: `Ребята, я так рада вас всех видеть! Пока вас не было, мы с Санычем подготовили для вас кое-что очень интересное!`,
    buttons: ['Начать'],
  },
};
