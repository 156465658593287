/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

export const scenario_2_1_i: Scenario = {
  scenarioId: SceneId.scenario_2_1_i,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Иннополис',
    text: `На широких просторах раскинулся инновационный город Иннополис. Но что-то здесь не так: дроны зависли в воздухе и стоит пронзительная тишина. 

    К вам подъезжает робот и говорит: «Добро пожаловать в Иннополис. Я Андроид Андрей, разработанный в научном Университете Сколково. Чем я могу вам помочь?»`,
    buttons: ['Ты знаешь, где найти программиста Гарина?'],
  },
};

const ERUDITION_TEST: TestStep = {
  id: 'ERUDITION_TEST',
  person: Interlocutors.PETROV,
  type: StepType.test,
  external_id: 'test-tvoi-krugozor-junior-t3-210519',
  path: 'test-tvoi-krugozor-junior-t3-210519.json',
  name: 'Эрудиция 1/2',
  order: [
    { timeout: 60, question: 'Q1', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q2', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q4', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q5', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q6', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q10', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q11', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q13', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q14', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q20', subtest: 'T2', description: '' },
  ],
  nextStep: 'Q6',
};

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';
const person = Interlocutors.SYSTEM;

export const scenario_2_1: Scenario = {
  scenarioId: SceneId.scenario_2_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Иннополис',
    scene_id: 'scenario_2_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `«Информация засекречена, пожалуйста, назовите код доступа». 

          Кода вы не знаете, но, возможно, сможете его найти в городе.
          `,
        callToAction: 'Ты знаешь, где найти программиста Гарина?',
        id: 'Q1',
        person: Interlocutors.SYSTEM, // !!!! TODO заменить на Андроид
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Расскажи про Иннополис',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        text: `Иннополис — первый российский город для ИТ-специалистов, расположенный в месте слияния двух рек — Волги и Свияги.
          Здесь учёные и предприниматели создают инновационные  
          и технологичные проекты. 
          
          Здесь есть: 
          
          
          Технопарк имени А.С.Попова
          Технопарк имени Н.И.Лобачевского  
          Университет, школа, лицей и детский сад
          
          Куда отправимся для начала?
          `,
        callToAction: 'Расскажи про Иннополис',
        id: 'Q2',
        person: Interlocutors.SYSTEM, // !!!! TODO заменить на Андроид
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Посетить технопарк имени А.С.Попова',
            nextStep: 'Q2_1',
          },
          {
            id: 'b2',
            text: 'Посетить технопарк имени Н.И.Лобачевского',
            nextStep: 'Q2_2',
          },
        ],
      },
      Q2_1: {
        text: `В белоснежном большом здании технопарка расположились сотни компаний, работающих над инновациями.`,
        callToAction: 'Посетить технопарк имени А.С.Попова',
        id: 'Q2_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Посмотреть на табличку с компаниями',
            nextStep: 'Q2_1_1',
          },
        ],
      },
      Q2_1_1: {
        text: `Среди многочисленных логотипов твоё внимание привлекает один: на нем изображён ковер, изогнувшийся, будто он стоит. Надпись: «Ковёр Технолоджис: решения для полётов».`,
        callToAction: 'Посетить табличку с компаниями',
        id: 'Q2_1_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Выйти на Квантовый бульвар',
            nextStep: 'Q2',
          },
        ],
      },
      Q2_2: {
        text: `Вы заходите в технопарк Н.И.Лобачевского. Внутри пятиэтажного технопарка, построенного в форме бумеранга, расположились офисы и конференц-залы. 

          Вы заходите в открытую переговорную комнату и видите что-то на столе.
          `,
        callToAction: 'Посетить технопарк имени Н.И.Лобачевского',
        id: 'Q2_2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Рассмотреть предмет',
            nextStep: 'Q2_2_1',
          },
        ],
      },
      Q2_2_1: {
        text: `Вы берёте в руки предмет, размером с ноготь. Это электронное насекомое, похожее на муху. На пузе у него —  наносхемы, на спине — наклейка «Г».

          «Кажется, нам нужно попасть в Университет», — замечает кот.
          `,
        callToAction: 'Посетить технопарк имени Н.И.Лобачевского',
        id: 'Q2_2_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться в Университет',
            nextStep: 'Q2_3',
          },
        ],
      },
      Q2_3: {
        text: `Вас встречает голос: «Перезапустите систему. Перезапустите систему. Данные будут потеряны через 0 часов 59 минут».

          Голос резко обрывается. Ты видишь, что в воздухе парят коды и цифры, стена будто разорвана: в ней словно пропал кусок, а в образовавшемся отверстии то появляется, то пропадает изображение леса. 
          
          «Нужно найти главный компьютер»,- понимаешь ты.
          `,
        callToAction: 'Отправиться в Университет',
        id: 'Q2_3',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Исследовать лаборатории',
            nextStep: 'Q2_3_1',
          },
        ],
      },
      Q2_3_1: {
        text: `Ты смотришь на схему университета. Перед глазами множество названий факультетов и кабинетов: «Киберфизические системы», «Анализ данных», «Блокчейн», «Интеллектуальная робототехника». Наконец, ты находишь название: «Центральная лаборатория — 5 этаж, кабинет Х».
          Кажется, тебе именно туда.
          `,
        callToAction: 'Исследовать лаборатории',
        id: 'Q2_3_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Подняться в центральную лабораторию',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        text: `Вы поднимаетесь к Центральной лаборатории. Открываете дверь кабинета и перед вами в самом центре тёмной комнаты предстаёт огромная жаба. 
          Над ней голограмма с надписью «Проект КВА».
          `,
        id: 'Q3',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Поговорить с Вжухом',
            nextStep: 'Q4',
          },
        ],
      },
      Q4: {
        text: `«Я могу ошибаться, но кажется, жабы любят насекомых», — вдруг замечает Вжух.`,
        id: 'Q4',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Дать жабе насекомое',
            nextStep: 'Q5',
          },
        ],
      },
      Q5: {
        text: `Ты достаёшь из кармана муху, найденную в технопарке. Жаба тут же открывает глаза и ловко хватает муху языком. 
          «Добро пожаловать в КВА. КВА — это квантовый компьютер, управляющий всеми технологическими процессами в парке «Большая перемена». Для авторизации пройдите техническую проверку», — говорит мягкий женский голос.
          `,
        id: 'Q5',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Пройти технический тест',
            nextStep: 'Q5_0_1',
          },
        ],
      },
      Q5_0_1: {
        text: `Предлагаем тебе пройти тест на эрудицию в технической сфере. Он оценит уровень твоей любознательности и широту кругозора и займет не более 10 минут. Тебе будет предъявлено 10 заданий, в каждом из которых по 4 варианта ответа, и только один из них верный. Время на каждый вопрос ограничено (60 секунд). Обращай внимание на счетчики времени на экране! Не рекомендуем долго думать над вариантами, лучше ориентироваться на свои знания и предположения и давать оперативные ответы. И старайся не пользоваться никакими справочниками и дополнительными ресурсами, ведь тест оценивает именно твой уровень эрудиции. Также имей в виду, что ты можешь вернуться назад только на один вопрос, чтобы исправить свой ответ. А теперь вперед, и желаем успехов!
        
        Данный тест является тренировочным и не приносит баллов на конкурсе.`,
        id: 'Q5_0_1',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'ERUDITION_TEST',
      },
      ERUDITION_TEST, // ТЕСТ: Эрудиция в технической сфере 1
      Q6: {
        text: `«Тест пройден, доступ открыт», — сообщает КВА.
          На голограмме над головой лягушки появляется фигура мужчины в чёрной водолазке и голубых джинсах. 
          
          «Привет {{NAME}}, я Гарин — Генеральный Активационный Резервный Интеллект. Я создан для того, чтобы контролировать работу Искусственного интеллекта 
          в парке «Большая перемена». 
          
          «Вжух, Гарин — это система», — ты с удивлением поворачиваешься к коту.
          `,
        id: 'Q6',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Поговорить с Гариным',
            nextStep: 'Q7',
          },
        ],
      },
      Q7: {
        text: `«Искусственный интеллект вышел из строя и захватил город, жители потеряли память, Петров послал нас к вам, что нам делать?» — спрашиваешь ты. 

          «Центральный процессор вышел из строя, распознаю вирус. Необходимо восстановить связь центрального процессора со мной и уничтожить вирус», — отвечает Гарин. 
          
          «Произвожу поиск разрывов».
          `,
        callToAction: 'Поговорить с Гариным',
        id: 'Q7',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Подождать, пока Гарин найдёт разрывы в каналах связи',
            nextStep: 'Q8',
          },
        ],
      },
      Q8: {
        text: `Гарин сообщает: «Точка разрыва установлена. Отправляйтесь к Триумфальной арке и постарайтесь устранить разрыв. Возьмите маршрутизатор — он поможет передать сигнал связи на КВА».`,
        id: 'Q8',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться к Триумфальной арке',
            nextStep: EXIT_ID,
          },
        ],
      },

      //       Q3: {
      //         person,
      //         id: 'Q3',
      //         type: StepType.message,
      //         text: `Да, я поражен твоими знаниями! 😍`,
      //         nextStep: 'Q5/0',
      //       },
      //       Q4: {
      //         person,
      //         id: 'Q4',
      //         type: StepType.message,
      //         text: `Ну ничего, я сейчас расскажу 😊`,
      //         nextStep: 'Q5/0',
      //       },
      //       ...splitStep({
      //         type: StepType.question,
      //         maxAnswers: 1,
      //         id: 'Q5',
      //         person,
      //         text: `Иннополис построен на основе высокотехнологичных разработок, и в этом заключается его основная эксклюзивность. Экология, созданная безопасная среда, большие возможности для получения образования.

      // В город приезжают много интересных лекторов, занимаются роботами и высокими технологиями, а еще тут можно познакомиться с ребятами со всего света!`,
      //         buttons: [
      //           {
      //             id: 'b1',
      //             text: 'Хочу там побывать!',
      //             nextStep: 'Q6',
      //           },
      //           {
      //             id: 'b2',
      //             text: 'Ну да, интересно в целом',
      //             nextStep: 'Q6',
      //           },
      //         ],
      //       }),
      //       Q6: {
      //         person,
      //         id: 'Q6',
      //         type: StepType.message,
      //         text: `Многие, отправляясь сюда, принимают решение остаться или вдохновляются на новые творения. Молодежь со всей России приезжает, чтобы познакомиться с особенностями жизненного уклада и технологическими новшествами.`,
      //         nextStep: 'Q7',
      //       },
      //       Q7: {
      //         text:
      //           'Иннополис был создан для развития информационных технологий, но ледяной туман заморозил все лаборатории. А ведь без новых открытий сейчас никуда, [[сам/сама]] понимаешь… 🙁',
      //         id: 'Q7',
      //         person,
      //         type: StepType.question,
      //         maxAnswers: 1,
      //         buttons: [
      //           {
      //             id: 'b1',
      //             text: 'Да, и что же делать?',
      //             nextStep: 'Q8',
      //           },
      //         ],
      //       },
      //       Q8: {
      //         person,
      //         id: 'Q8',
      //         text:
      //           'Я уже начал проектировать новую тепловую пушку. Ее мощности должно хватить, чтобы разморозить парк. И я очень рассчитываю на твою помощь 😔',
      //         type: StepType.question,
      //         maxAnswers: 1,
      //         buttons: [
      //           {
      //             id: 'b1',
      //             text: 'Конечно, я помогу!',
      //             nextStep: 'Q9/0',
      //           },
      //           {
      //             id: 'b2',
      //             text: 'Ну да, куда же вы без меня..',
      //             nextStep: 'Q9/0',
      //           },
      //         ],
      //       },
      //       ...splitStep({
      //         person,
      //         id: 'Q9',
      //         text: `Я хочу узнать, могу ли рассчитывать на твою смекалку в процессе создания пушки, поэтому задам 10 вопросов.

      // Ты [[должен/должна]] выбрать 1 вариант ответа из предложенных, и у тебя будет всего 1 минута на каждый вопрос.`,
      //         type: StepType.question,
      //         maxAnswers: 1,
      //         buttons: [
      //           {
      //             id: 'b1',
      //             text: '[[Понял/Поняла]].',
      //             nextStep: 'ERUDITION_TEST',
      //           },
      //         ],
      //       }),
      //       ERUDITION_TEST,
      //       Q10: {
      //         person,
      //         id: 'Q10',
      //         type: StepType.message,
      //         text: `Вопросы были непростыми, но ты молодец! 👍 `,
      //         nextStep: 'Q11/0',
      //       },
      //       ...splitStep({
      //         person,
      //         id: 'Q11',
      //         text: `Тепловая пушка — штука сложная, и нам нужна особенная микросхема. К сожалению, в нашей лаборатории ее нет, но я знаю, что такая точно есть у Сан Саныча.

      // Он сейчас как раз недалеко — на Космодроме! Беги к нему и раздобудь микросхему. Скорее, иначе уже к вечеру мы все превратимся в снеговиков! ⛄️`,
      //         type: StepType.question,
      //         maxAnswers: 1,
      //         buttons: [
      //           {
      //             id: 'b1',
      //             text: 'У меня сейчас есть задачи поважнее, я [[пошел/пошла]]',
      //             nextStep: EXIT_ID,
      //           },
      //           {
      //             id: 'b2',
      //             text: 'Не успеешь моргнуть, как я уже вернусь с микросхемой!',
      //             nextStep: EXIT_ID,
      //           },
      //         ],
      //       }),
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
