import React from 'react';

import { useSelector } from 'react-redux';
import { windowSizes } from './../redux/app/index';
import { selectViewport } from '../redux/app/appGetters';

const viewportsMobile = [windowSizes.smallphone, windowSizes.phone];

const useViewport = () => {
  const viewport = useSelector(selectViewport);
  const isMobile = React.useMemo(() => viewportsMobile.includes(viewport), [viewport]);

  return { isMobile };
};

export default useViewport;
