/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_ostankinoAlienInvasion_1_1_1: Scenario = {
  scenarioId: SceneId.stg2_ostankinoAlienInvasion_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Останкино',
    text: `Вы подходите к макету Останкинской телебашни. Она поражает своими грандиозными размерами!

    Вы заходите внутрь башни и видите кабину лифта с открытыми дверьми. Вы решаете заглянуть внутрь и проверить, работает ли он.`,
    buttons: ['Начать'],
  },
};
