import { PointsOfInterest } from '../../types/index';
import * as checks from '../../hooks/scenarioHelpers/stageChecks';
import { ScenarioState } from '../../redux/scenario';
import { ID, to } from '../../hooks/scenarioHelpers/ID';

type PlaceOpened = Partial<Record<string, (scenarioState: ScenarioState) => boolean>>;

const placeOpened: PlaceOpened = {
  [PointsOfInterest.mgu]: (scenarioState) => checks.notWelcomeTest(scenarioState),

  [PointsOfInterest.ostankino]: (scenarioState) =>
    Boolean(
      scenarioState.scenarios?.[to[ID.welcomeEntrepreneur]] &&
        scenarioState.scenarios?.[to[ID.welcomePetrov]],
    ),

  [PointsOfInterest.space]: (scenarioState) =>
    Boolean(
      scenarioState.scenarios?.[to[ID.welcomePetrov]] &&
        scenarioState.scenarios?.[to[ID.welcomeTeacher]],
    ),

  [PointsOfInterest.lake]: (scenarioState) =>
    Boolean(
      !checks.notGrandMomWelcome(scenarioState) &&
        scenarioState.scenarios?.[to[ID.welcomeEntrepreneur]],
    ),

  [PointsOfInterest.sochi]: (scenarioState) =>
    Boolean(
      scenarioState.scenarios?.[to[ID.sochiInfo]] && !checks.notGrandMomWelcome(scenarioState),
    ),

  [PointsOfInterest.innopolice]: (scenarioState) =>
    Boolean(!checks.notSchemaRequested(scenarioState)),

  [PointsOfInterest.moscowCity]: (scenarioState) => Boolean(!checks.notMoscowCity(scenarioState)),

  [PointsOfInterest.bridge]: (scenarioState) => Boolean(!checks.notBridgeBoxHelp(scenarioState)),

  [PointsOfInterest.arch]: (scenarioState) => Boolean(!checks.notArchCompleted(scenarioState)),

  [PointsOfInterest.kurgan]: (scenarioState) =>
    Boolean(
      !checks.notKurganQuest(scenarioState) &&
        !checks.notHermitagePictureSaved(scenarioState) &&
        !checks.notStage2act3Started(scenarioState),
    ),

  [PointsOfInterest.hermitage]: (scenarioState) =>
    Boolean(!checks.notHermitagePictureSaved(scenarioState)),

  [PointsOfInterest.hersones]: (scenarioState) =>
    Boolean(!checks.notHersonesCompleted(scenarioState)),

  [PointsOfInterest.train]: (scenarioState) =>
    Boolean(!checks.notTrainEggDetailReceived(scenarioState)),
};

export const checkPlaceOpened = (placeName: string, scenarioState: ScenarioState) => {
  return placeOpened[placeName]?.(scenarioState);
};
