/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg3_mgu_1_1: Scenario = {
  scenarioId: SceneId.stg3_mgu_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'МГУ',
    scene_id: 'stg3_mgu_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `О, {{name}}, Женя, Вжух, Квилл, наконец и вы подошли! Теперь все в сборе.`,
        id: 'Q1',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Привет! А что тут происходит? Женя сказала, что у тебя ко всем важное дело.',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        text: `Конечно, важное — парк надо спасти! Я тут всех собрал, чтобы устроить мозговой штурм и придумать, чем удивить пришельцев.

        Поэтому друзья, давайте предлагать, что бы нам такое сделать?`,
        id: 'Q2',
        person: Interlocutors.PETROV,
        type: StepType.message,
        nextStep: 'Q3',
      },
      Q3: {
        text: `А что если снять фильм и показать пришельцам, что такое кино?`,
        id: 'Q3',
        person: Interlocutors.CAT,
        type: StepType.message,
        nextStep: 'Q4',
      },

      Q4: {
        text: `Или организовать масштабное экологическое мероприятие!`,
        id: 'Q4',
        person: Interlocutors.GUIDE,
        type: StepType.message,
        nextStep: 'Q5',
      },

      Q5: {
        text: `А может быть сфотографировать природные красоты и устроить фотовыставку? `,
        id: 'Q5',
        person: Interlocutors.BLUE_CAT,
        type: StepType.message,
        nextStep: 'Q6',
      },

      Q6: {
        text: `Давайте устроим встречу с ветеранами ВОВ? Почтим их память и позволим многим узнать больше про подвиги.`,
        id: 'Q6',
        person: Interlocutors.TEACHER,
        type: StepType.message,
        nextStep: 'Q7',
      },

      Q7: {
        text: `Или организуем марафон добрых дел, чтобы каждый смог сделать мир лучше.`,
        id: 'Q7',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.message,
        nextStep: 'Q8',
      },

      Q8: {
        text: `А можно же отправиться в путешествие по России и показать её разнообразную культуру!`,
        id: 'Q8',
        person: Interlocutors.ENTREPRENEUR,
        type: StepType.message,
        nextStep: 'Q9',
      },

      Q9: {
        text: `Или показать наши современные технологии — роботов, 3D печать!`,
        id: 'Q9',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: `Ого, столько замечательных идей!`,
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
