/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_lakeOrderFromGranny_1_1_0: Scenario = {
  scenarioId: SceneId.stg2_lakeOrderFromGranny_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.CAT,
    title: 'МГУ',
    text: `Пришелец, с ума сойти можно! Понятия не имею, где его искать! В парке ещё так много мест, где мы не смотрели.`,
    buttons: ['Начать'],
  },
};
