import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { selectScenarios } from '../../redux/user/userGetters';
import { notHersonesCompleted } from '../scenarioHelpers/stageChecks';
import { s3a1RequiredScenarios } from './requiredScenarios';

export function useIsThirdStageFirstActCompleted() {
  const scenarioState = useSelector(selectScenarios);
  return useMemo(() => {
    if (!scenarioState || !scenarioState.scenarios) {
      return false;
    }
    for (let i = 0; i < s3a1RequiredScenarios.length; i++) {
      for (let j = 0; j < s3a1RequiredScenarios[i].length; j++) {
        if (!scenarioState.scenarios[s3a1RequiredScenarios[i][j]]) {
          return false;
        }
      }
    }
    return !notHersonesCompleted(scenarioState);
  }, [scenarioState]);
}
