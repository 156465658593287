import { RootState } from '../../types';

export const selectViewport = (state: RootState) => state.app.viewport;

export const selectShared = (key: string | number) => (state: RootState) => state.app.shared[key];

// modals

export const selectMobileMenuShow = (state: RootState) => state.app.isShowMobileMenu;

export const selectAttachmentVideoModalShow = (state: RootState) =>
  state.app.isShowVideoAttachmentModal;
