import { createSlice } from '@reduxjs/toolkit';
import {
  USER_FILES,
  STORE_USER_INFO,
  STORE_USER_TOKEN,
} from '../../../common/shared/constants/constants';

import Api from '../../utils/Api';
import LocalStorage from '../../../common/shared/utils/LocalStorage';

import { SceneId, Step } from '../../resources/scenario';
import { ProfileConfig } from '../../components/Hero/config';

import { ChallengeKey, ChallengeBackendData } from '../../components/Challenges/config';

import { ScoreItem } from '../../../common/widgets/Scores/ScoresTypes';

export interface UserAppState {
  isViewedProfile?: boolean;
  isOnboardingCompleted?: boolean;

  score?: ScoreItem[];
  manualCheckedScore?: ScoreItem;
  isScoreCalculated?: boolean;
  rightThingHistory?: string;
  challenges?: Partial<Record<ChallengeKey, ChallengeBackendData>>;
}

export type Optional<T> = undefined | T;

export type LegacyUserState = UserAppState & {
  variables?: Record<string, string>;
  unsavedVariables?: Record<string, string>;
  profile?: ProfileConfig;
  scenarios?: Partial<Record<SceneId, Step[]>>;
  startedScenarios?: Partial<Record<SceneId, Step[]>>;
};

export interface UserInfo {
  state: Partial<UserAppState>;
  profile: null | ProfileConfig;
  variables: null | Record<string, string>;
  score: number;
  info: string;

  id: string;
  user_id: number;
  created_at: string;
  updated_at: string;
  email: string;
  name_last: string;
  name_first: string;
  name_middle: string;
  files: UserFiles;
  grade: number;
  challenge: number;
}

export interface UserFiles {
  essay: boolean;
  presentation: boolean;
  challenge: boolean;
}

export interface UserState {
  token?: string;
  info?: UserInfo;
  userFiles?: any;
}

let isSendYandexMetrikaUserId = false;

const initialState: UserState = {
  token: LocalStorage.get<string>(STORE_USER_TOKEN),
  info: LocalStorage.get<UserInfo>(STORE_USER_INFO),
  userFiles: LocalStorage.get<UserInfo>(USER_FILES),
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.token = action.payload;

      Api.setToken(action.payload);
      LocalStorage.set(STORE_USER_TOKEN, action.payload);
    },
    logout: (state) => {
      // state.token = '';
      LocalStorage.clear();
      // Api.setToken('');
      window.location.href = String(process.env.REACT_APP_API_LOGOUT);
    },
    setInfo: (state, action) => {
      state.info = action.payload;
      LocalStorage.set(STORE_USER_INFO, action.payload);

      if (!isSendYandexMetrikaUserId && state.info?.user_id) {
        try {
          const apiYM = (window as any).ym;

          if (apiYM) {
            apiYM(74719792, 'setUserID', state.info.user_id);
            isSendYandexMetrikaUserId = true;
          }
        } catch (ignore) {
          console.log(ignore);
        }
      }
    },
    setChalleng: (state, action) => {
      if (state.info) {
        state.info.challenge = action.payload;
      }
    },
    setChallangeFlag: (state, action) => {
      if (state.info) {
        state.info.files.challenge = action.payload;
      }
    },
    setUserFiles: (state, action) => {
      state.userFiles = action.payload;
    },
  },
});

export default userSlice;
