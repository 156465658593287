/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT';

const person = Interlocutors.PETROV;

export const scenario_2_15_0: Scenario = {
  scenarioId: SceneId.scenario_2_15_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: 'Новости парка',
    person: Interlocutors.SYSTEM,
    text: `Вы стоите перед прекрасной новенькой ракетой, которую недавно выиграли в викторине.`,
    buttons: ['Закрыть'],
  },
};

export const scenario_2_15: Scenario = {
  scenarioId: SceneId.scenario_2_15,
  type: ScenarioType.chat,
  data: {
    title: 'Космодром',
    scene_id: 'scenario_2_15',
    startId: 'Q0/0',
    steps: {
      ...splitStep({
        id: 'Q0',
        person,
        text: `Ну что, остался последний шаг! 

Это твоя ракета, поэтому кнопку «Пуск» в рубке управления [[должен/должна]] нажать именно ты 😉`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Уже не терпится!',
            nextStep: 'Q1/0',
          },
          {
            id: 'b2',
            text: 'Ух! Никогда не [[запускал/запускала]] настоящие ракеты! Даже волнительно…Но я [[готов/готова]]!',
            nextStep: 'Q1/0',
          },
        ],
      }),
      ...splitStep({
        id: 'Q1',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        text: `Вы нажимаете кнопку «Пуск» и слышите обратный отсчет системы управления: «Десять, девять, восемь, семь…» 

Вместе с Петровым вы замираете в предвкушении.

«Три, два, один... ПОЕХАЛИ!»

Вы смотрите вдаль стремительно набирающей высоту ракете и чувствуете радость!`,
        nextStep: 'Q2/0',
      }),
      ...splitStep({
        person,
        id: 'Q2',
        text: `Смотри, {{NAME}}! У нас получилось! Ледяные облака РАССЕИВАЮТСЯ! 😍

Неужели «Большая перемена» снова будет прежней?! Какое же это счастье! 😍

Кстати, ты это чувствуешь?..`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Что чувствую?',
            nextStep: 'Q3/0',
          },
          {
            id: 'b2',
            text: 'Хм… Ничего такого не чувствую...',
            nextStep: 'Q3/0',
          },
        ],
      }),
      ...splitStep({
        person,
        id: 'Q3',
        text: `ПОТЕПЛЕЛО! 😊

Благодаря тебе обитатели парка снова увидели солнечные лучи! Да и тепловая пушка продолжает работать. 

Аж не верится, что весь этот ледяной кошмар закончился! Ты снова нам [[помог/помогла]]! Все здесь очень тебе благодарны! 😍`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да ладно, мне было в радость вам помочь!',
            nextStep: EXIT_ID,
          },
          {
            id: 'b2',
            text: 'И что бы вы только без меня делали?!',
            nextStep: EXIT_ID,
          },
        ],
      }),
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
