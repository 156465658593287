/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const moscowCity_1_1_1: Scenario = {
  scenarioId: SceneId.moscowCity_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Москва-Сити',
    text: `Вы находитесь рядом с большими стеклянными высотками. Кажется, что тут должно работать множество людей, но на этажах никого не видно.

    У входа в главный небоскреб стоит делового вида мужчина и поглядывает на часы. Вы начинаете с ним разговор...`,
    buttons: ['Начать'],
  },
};
