/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';
import interlocutors from '../../../interlocutors';

export const scenario_1_2: Scenario = {
  scenarioId: SceneId.scenario_1_2,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `Сейчас парк совсем грустно выглядит, правда? Чтобы переместиться в какую-либо из его частей, кликни на красный маркер на ней. 

    А вот и МГУ — пойдём скорее внутрь!`,
    buttons: ['Закрыть'],
  },
};
