/* eslint-disable max-len */
import {
  Step,
  SceneId,
  Scenario,
  StepType,
  ScenarioType,
  Interlocutors,
  TestStep,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

export const scenario_2_2_i: Scenario = {
  scenarioId: SceneId.scenario_2_2,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Триумфальная арка',
    text: `У Триумфальной арки вы обнаруживаете инженера Петрова. Он стоит со странной штукой в руках, похожей на антенну и, кажется, пытается что-то поймать.`,
    buttons: ['Поговорить с Петровым'],
  },
};

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

const person = Interlocutors.ENTREPRENEUR;

const ERUDITION_TEST_STEP: TestStep = {
  id: 'ERUDITION_TEST_STEP',
  person: Interlocutors.PETROV,
  type: StepType.test,
  external_id: 'test-tvoi-krugozor-junior-g3-210519',
  path: 'test-tvoi-krugozor-junior-g3-210519.json',
  name: 'Эрудиция 2/2',
  order: [
    { timeout: 60, question: 'Q1', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q2', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q3', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q4', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q5', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q6', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q7', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q9', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q11', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q19', subtest: 'T1', description: '' },
  ],
  nextStep: 'ERUDITION_TEST_FINISH/0',
};

const ERUDITION_TEST: Record<string, Step> = {
  ERUDITION_TEST_1: {
    person: Interlocutors.PETROV,
    id: 'ERUDITION_TEST_1',
    text: 'Тест состоит из 10 вопросов. Ты [[должен/должна]] выбрать 1 вариант ответа из предложенных, и у тебя будет всего 1 минута на каждый вопрос.',
    type: StepType.question,
    callToAction: 'Начнем?',
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Да, давай!',
        nextStep: 'ERUDITION_TEST_STEP',
      },
    ],
  },
  ERUDITION_TEST_STEP,
  ...splitStep({
    person: Interlocutors.PETROV,
    id: 'ERUDITION_TEST_FINISH',
    text: `Ну вот и весь тест 😊

Как он тебе? Понравился?`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Ты был прав. Тест оказался очень интересным!',
        nextStep: 'Q2',
      },
      {
        id: 'b2',
        text: 'Ох, Петров! Было сложновато, но я, кажется, [[справился/справилась]]...',
        nextStep: 'Q2',
      },
    ],
  }),
};

// const TEST_LOGIC: { [id: string]: Step } = {
//   TEST_LOGIC_1: {
//     person,
//     id: 'TEST_LOGIC_1',
//     text:
//       'Ну, начнем. Первый вопрос. У Олиной мамы пять дочек: Вика, Аня, Марта, Кира. Как зовут пятую?',
//     type: StepType.question,
//     callToAction: 'Выбери вариант',
//     maxAnswers: 1,
//     buttons: [
//       {
//         id: 'b1',
//         text: 'Соня',
//         nextStep: 'TEST_LOGIC_1_2',
//       },
//       {
//         id: 'b2',
//         text: 'Таня',
//         nextStep: 'TEST_LOGIC_1_3',
//       },
//       {
//         id: 'b3',
//         text: 'Оля',
//         nextStep: 'TEST_LOGIC_1_4',
//       },
//     ],
//   },

//   TEST_LOGIC_1_2: {
//     person,
//     id: 'TEST_LOGIC_1_2',
//     text: 'Прочти вопрос еще раз и будь внимательнее. Это не Соня.',
//     type: StepType.question,
//     callToAction: 'Выбери вариант',
//     maxAnswers: 1,
//     buttons: [
//       {
//         id: 'b2',
//         text: 'Таня',
//         nextStep: 'TEST_LOGIC_1_3',
//       },
//       {
//         id: 'b3',
//         text: 'Оля',
//         nextStep: 'TEST_LOGIC_1_4',
//       },
//     ],
//   },
//   TEST_LOGIC_1_3: {
//     person,
//     id: 'TEST_LOGIC_1_3',
//     text: 'Нет, это неверный ответ. Секрет кроется в первых трех словах вопроса.',
//     type: StepType.question,
//     callToAction: 'Выбери вариант',
//     maxAnswers: 1,
//     buttons: [
//       {
//         id: 'b1',
//         text: 'Соня',
//         nextStep: 'TEST_LOGIC_1_2',
//       },
//       {
//         id: 'b3',
//         text: 'Оля',
//         nextStep: 'TEST_LOGIC_1_4',
//       },
//     ],
//   },
//   TEST_LOGIC_1_4: {
//     person,
//     id: 'TEST_LOGIC_1_4',
//     text: 'Ну конечно же Оля! Ты очень [[догадливый/догадливая]]!',
//     type: StepType.message,
//     nextStep: 'TEST_LOGIC_2',
//   },

//   TEST_LOGIC_2: {
//     person,
//     callToAction: 'Выбери вариант',
//     id: 'TEST_LOGIC_2',
//     text:
//       'Второй вопрос. В отеле 4 этажа. Чем выше этаж, тем больше людей там живет. На какой этаж чаще всего ездит лифт?',
//     type: StepType.question,
//     maxAnswers: 1,
//     buttons: [
//       {
//         id: 'b1',
//         text: 'Первый',
//         nextStep: 'TEST_LOGIC_2_4',
//       },
//       {
//         id: 'b2',
//         text: 'Второй',
//         nextStep: 'TEST_LOGIC_2_2',
//       },
//       {
//         id: 'b3',
//         text: 'Четвертый',
//         nextStep: 'TEST_LOGIC_2_3',
//       },
//     ],
//   },
//   TEST_LOGIC_2_2: {
//     person,
//     callToAction: 'Выбери вариант',
//     id: 'TEST_LOGIC_2_2',
//     text: 'Ты [[был/была]] [[близок/близка]], но это неверный ответ.',
//     type: StepType.question,
//     maxAnswers: 1,
//     buttons: [
//       {
//         id: 'b1',
//         text: 'Первый',
//         nextStep: 'TEST_LOGIC_2_4',
//       },
//       {
//         id: 'b3',
//         text: 'Четвертый',
//         nextStep: 'TEST_LOGIC_2_3',
//       },
//     ],
//   },
//   TEST_LOGIC_2_3: {
//     person,
//     callToAction: 'Выбери вариант',
//     id: 'TEST_LOGIC_2_3',
//     text: 'Увы, но нет.',
//     type: StepType.question,
//     maxAnswers: 1,
//     buttons: [
//       {
//         id: 'b1',
//         text: 'Первый',
//         nextStep: 'TEST_LOGIC_2_4',
//       },
//       {
//         id: 'b2',
//         text: 'Второй',
//         nextStep: 'TEST_LOGIC_2_2',
//       },
//     ],
//   },
//   TEST_LOGIC_2_4: {
//     person,
//     id: 'TEST_LOGIC_2_4',
//     text: 'Тебя не проведешь! Да, это правильный ответ!',
//     type: StepType.message,
//     nextStep: 'TEST_LOGIC_3',
//   },

//   TEST_LOGIC_3: {
//     person,
//     id: 'TEST_LOGIC_3',
//     callToAction: 'Выбери вариант',
//     text: 'Следующий вопрос. Укажите недостающее число: 2, 32, 332, ?, 33332.',
//     type: StepType.question,
//     maxAnswers: 1,
//     buttons: [
//       {
//         id: 'b1',
//         text: '3322',
//         nextStep: 'TEST_LOGIC_3_2',
//       },
//       {
//         id: 'b2',
//         text: '3232',
//         nextStep: 'TEST_LOGIC_3_3',
//       },
//       {
//         id: 'b3',
//         text: '3332',
//         nextStep: 'TEST_LOGIC_3_4',
//       },
//     ],
//   },
//   TEST_LOGIC_3_2: {
//     person,
//     id: 'TEST_LOGIC_3_2',
//     callToAction: 'Выбери вариант',
//     text: 'Это неверный ответ. Все намного проще, чем кажется.',
//     type: StepType.question,
//     maxAnswers: 1,
//     buttons: [
//       {
//         id: 'b2',
//         text: '3232',
//         nextStep: 'TEST_LOGIC_3_3',
//       },
//       {
//         id: 'b3',
//         text: '3332',
//         nextStep: 'TEST_LOGIC_3_4',
//       },
//     ],
//   },
//   TEST_LOGIC_3_3: {
//     person,
//     id: 'TEST_LOGIC_3_3',
//     callToAction: 'Выбери вариант',
//     text: 'Нет, присмотрись к ряду чисел повнимательнее.',
//     type: StepType.question,
//     maxAnswers: 1,
//     buttons: [
//       {
//         id: 'b1',
//         text: '3322',
//         nextStep: 'TEST_LOGIC_3_2',
//       },
//       {
//         id: 'b3',
//         text: '3332',
//         nextStep: 'TEST_LOGIC_3_4',
//       },
//     ],
//   },
//   TEST_LOGIC_3_4: {
//     person,
//     id: 'TEST_LOGIC_3_4',
//     callToAction: 'Выбери вариант',
//     text: 'Это правильный ответ! Ты почти у цели! ',
//     type: StepType.message,
//     nextStep: 'TEST_LOGIC_4',
//   },

//   TEST_LOGIC_4: {
//     person,
//     id: 'TEST_LOGIC_4',
//     callToAction: 'Выбери вариант',
//     text:
//       'И последний вопрос. На продажу выставили десять автомобилей. Все, кроме девяти, купили. Сколько автомобилей осталось?',
//     type: StepType.question,
//     maxAnswers: 1,
//     buttons: [
//       {
//         id: 'b1',
//         text: 'Один',
//         nextStep: 'TEST_LOGIC_4_2',
//       },
//       {
//         id: 'b2',
//         text: 'Девять',
//         nextStep: 'TEST_LOGIC_4_4',
//       },
//       {
//         id: 'b3',
//         text: 'Десять',
//         nextStep: 'TEST_LOGIC_4_3',
//       },
//     ],
//   },
//   TEST_LOGIC_4_2: {
//     person,
//     id: 'TEST_LOGIC_4_2',
//     callToAction: 'Выбери вариант',
//     text: 'Хм, подумай еще немного.',
//     type: StepType.question,
//     maxAnswers: 1,
//     buttons: [
//       {
//         id: 'b2',
//         text: 'Девять',
//         nextStep: 'TEST_LOGIC_4_4',
//       },
//       {
//         id: 'b3',
//         text: 'Десять',
//         nextStep: 'TEST_LOGIC_4_3',
//       },
//     ],
//   },
//   TEST_LOGIC_4_3: {
//     person,
//     id: 'TEST_LOGIC_4_3',
//     callToAction: 'Выбери вариант',
//     text: 'Не все так просто, это неверный ответ.',
//     type: StepType.question,
//     maxAnswers: 1,
//     buttons: [
//       {
//         id: 'b1',
//         text: 'Один',
//         nextStep: 'TEST_LOGIC_4_2',
//       },
//       {
//         id: 'b2',
//         text: 'Девять',
//         nextStep: 'TEST_LOGIC_4_4',
//       },
//     ],
//   },
//   TEST_LOGIC_4_4: {
//     person,
//     id: 'TEST_LOGIC_4_4',
//     callToAction: 'Выбери вариант',
//     text: 'Абсолютно верно! Сейф открыт! Ура!',
//     type: StepType.message,
//     nextStep: 'Q2',
//   },
// };

export const scenario_2_2: Scenario = {
  scenarioId: SceneId.scenario_2_2,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Триумфальная арка',
    scene_id: 'scenario_2_2',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `«{{NAME}} вы нашли Гарина! Он смог запустить резервный блок памяти моего компьютера, я кое-что начинаю вспоминать. В этой арке образовался волновой разрыв. 
          Видите эти ячейки на потолке арки?» — Петров показывает на свод, разбитый на десятки ячеек, в центре которых находится что-то вроде бутонов цветов. 
          
          «Это кесоны — в архитектуре они используются, чтобы укрепить свод, а в акустике — для усиления вибрации. Кажется, Интеллект 2.0 изменил вибрацию в этой области таким образом, что время и пространство искривились. Мы должны найти этот разрыв, чтобы закрыть его. Сможешь помочь мне? Я буду ловить сигнал, а ты исследуй поверхность!»
          `,
        callToAction: 'Отправиться к Триумфальной арке',
        id: 'Q1',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отдать маршрутизатор Петрову',
            nextStep: 'ERUDITION_TEST_1',
          },
        ],
      },
      ...ERUDITION_TEST, // ТЕСТ: Эрудиция в гуманитарной сфере 2
      Q2: {
        text: `«{{NAME}} у нас получилось, координаты есть! Сейчас я перепишу код и отправлю его Гарину на резервный КВА. Но у нас всё ещё нет связи, нам нужна очень высокая точка, с которой можно будет передать сигнал на КВА».

          «Останкинская башня?» — догадываешься ты.  
          
          «Нет, Башня не подойдёт, там слишком много сигналов, могут быть помехи». 
          
          Вдруг ты замечаешь, что Вжух дергает тебя лапкой. «Кажется, я знаю такое место», — говорит кот. — «Пойдём».
          `,
        id: 'Q2',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться со Вжухом',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
