import React, { Fragment, FC, memo, useMemo, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import PicStep1 from '../../../assets/images/onboarding_cat.svg';
import PicStep2 from '../../../assets/images/onboarding_people.svg';
import PicStep3 from '../../../assets/images/onboarding_tower.svg';
import SpriteStar from '../../../assets/images/onboarding_sprite_star.svg';
import SpriteCloudL from '../../../assets/images/onboarding_sprite_cloud1.svg';
import SpriteCloudR from '../../../assets/images/onboarding_sprite_cloud2.svg';

import {
  OnboardingPic,
  OnboardingPic3,
  OnboardingStar,
  OnboardingStarR,
  OnboardingStarB,
  OnboardingCloud1,
  OnboardingCloud2,
  OnboardingCloud3,
  OnboardingRightStep1,
  OnboardingRightStep2,
  OnboardingRightStep3,
  OnboardingMainTitle,
  OnboardingPageLeft,
  OnboardingPageRight,
  OnboardingContent,
  OnboardingButtonWrap,
  OnboardingPaginationWrapper,
  OnboardingPaginationEmpty,
  OnboardingPaginationFilled,
} from './OnboardingPageStyled';

import { routes } from '../../../common/shared/constants/routes';
import { useDispatch } from 'react-redux';
import { setStateAsync } from '../../redux/user/userActions';

import { Text } from '../../../common/shared/ui/Text';
import { Box } from '../../../common/shared/ui/Box';
import { Button } from '../../../common/shared/ui/Button';
import { Icon } from '../../../common/shared/ui/Icon';
import { Page } from '../../../common/pages/Page';
import Logo from '../../components/Logo';

const screensContent = [
  {
    description: (
      <Text displayAs='div' $textStyle='p24'>
        Одним прекрасным утром ты получаешь сообщение от старого приятеля.
        <br />
        <br />
      </Text>
    ),
    right: (
      <OnboardingRightStep1>
        <OnboardingStar src={SpriteStar} />
        <OnboardingStarR src={SpriteStar} />
        <OnboardingStarB src={SpriteStar} />
        <OnboardingPic src={PicStep1} />
      </OnboardingRightStep1>
    ),
  },
  {
    title: <OnboardingMainTitle>Привет!</OnboardingMainTitle>,
    description: (
      <Text displayAs='div' $textStyle='p24'>
        В парке «Большая Перемена» снова творятся странные вещи, нужна твоя помощь, чтобы провести
        расследование и всё исправить.
        <br />
        <br />
        Уверен, ты идеально для этого подходишь, только другие обитатели парка стали настороженно
        относиться к гостям. Давай поможем им узнать тебя получше!
      </Text>
    ),
    right: (
      <OnboardingRightStep1>
        <OnboardingStar src={SpriteStar} />
        <OnboardingStarR src={SpriteStar} />
        <OnboardingStarB src={SpriteStar} />
        <OnboardingPic src={PicStep1} />
      </OnboardingRightStep1>
    ),
  },
  // {
  //   description: (
  //     <Text displayAs='div' $textStyle='p24'>
  //       <div style={{ overflowY: 'auto', maxHeight: '55vh' }}>
  //         Запиши видеовизитку продолжительностью 1-2 минуты.
  //         <br />
  //         <br /> В ней:
  //         <br />
  //         <br /> Расскажи самое важное и интересное о себе. Это могут быть достижения или хобби,
  //         или, возможно, ты мечтаешь реализовать собственный проект. Расскажи о том, что для тебя
  //         важно, что ценишь, и чем по-настоящему гордишься.
  //         <br />
  //         <br /> Обязательно расскажи нам чего ждешь от участия в конкурсе.
  //       </div>
  //     </Text>
  //   ),
  //   right: (
  //     <OnboardingRightStep2>
  //       <OnboardingPic src={PicStep2} />
  //     </OnboardingRightStep2>
  //   ),
  // },
];

const Pagination = (props: { currentPage: number; countPages: number }) => {
  const { currentPage, countPages } = props;

  const items = useMemo(() => {
    const arr = [];

    for (let i = 0; i < countPages; i++) {
      arr.push(
        i > currentPage ? (
          <OnboardingPaginationEmpty key={i} />
        ) : (
          <OnboardingPaginationFilled key={i} />
        ),
      );
    }

    return arr;
  }, [currentPage, countPages]);

  return <OnboardingPaginationWrapper $pageNum={currentPage}>{items}</OnboardingPaginationWrapper>;
};

export const OnboardingWrap = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const items = screensContent;
  const history = useHistory();

  const handleNext = useCallback(() => {
    if (page + 1 <= items.length - 1) {
      setPage(page + 1);
      return;
    }

    dispatch(setStateAsync({ isOnboardingCompleted: true }));
    history.push(routes.map);
  }, [history, items, page, dispatch]);

  const handlePrev = useCallback(() => {
    if (0 <= page - 1) setPage(page - 1);
  }, [page, setPage]);

  const nextBtn = useMemo(() => ({ text: 'Далее', onClick: handleNext }), [handleNext]);

  return (
    <Fragment>
      <OnboardingPageLeft $pageNum={page}>
        <Box $justify='space-between' $align='center'>
          <Pagination currentPage={page} countPages={items.length} />
          <Logo isOnboarding />
        </Box>
        <OnboardingContent $pageNum={page}>
          {items[page].title}
          {items[page].description}
        </OnboardingContent>
        <OnboardingButtonWrap>
          {!!page && (
            <Button
              $textStyle='p18'
              $fontWeight='500'
              $style='ghost'
              $minW='230px'
              onClick={handlePrev}>
              <Box $justify='space-between' $align='center'>
                <Icon isReverse icon='ico_chevron' />
                Назад
              </Box>
            </Button>
          )}
          <Button $textStyle='p18' $fontWeight='500' $minW='230px' onClick={nextBtn.onClick}>
            <Box $justify='space-between' $align='center'>
              {nextBtn.text}
              <Icon icon='ico_chevron' />
            </Box>
          </Button>
        </OnboardingButtonWrap>
      </OnboardingPageLeft>

      <OnboardingPageRight $pageNum={page}>{items[page].right}</OnboardingPageRight>
    </Fragment>
  );
};

const OnboardingPage: FC = () => (
  <Page>
    <OnboardingWrap />
  </Page>
);

export default memo(OnboardingPage);
