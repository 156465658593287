import { isDebug } from '../constants/constants';

export class Logger {
  static log(...args: any[]) {
    if (isDebug) {
      console.log(...args);
    }
  }
  static error(...args: any[]) {
    if (isDebug) {
      console.error(...args);
    }
  }
  static warn(...args: any[]) {
    if (isDebug) {
      console.warn(...args);
    }
  }
}
