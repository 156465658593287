/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const alien_1_1: Scenario = {
  scenarioId: SceneId.alien_1_1,
  type: ScenarioType.chat,
  data: {
    title: 'Знакомство с Инопланетянином ',
    scene_id: 'alien_1_1',
    startId: 'Q2',
    steps: {
      // Q1: {
      //   person: Interlocutors.SYSTEM,
      //   id: 'Q1',
      //   text: `Тут вы заметили позади Вжуха странное белое существо.`,
      //   type: StepType.message,
      //   nextStep: 'Q2',
      // },

      Q2: {
        person: Interlocutors.ALIEN,
        id: 'Q2',
        text: `Шшшшшшш…`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Привет! А ты здесь откуда, дружок?',
            nextStep: 'Q3',
          },
          {
            id: 'b2',
            text: 'Вжух! Только резко не оборачивайся…',
            nextStep: 'Q3',
          },
        ],
      },

      Q3: {
        person: Interlocutors.CAT,
        id: 'Q3',
        text: `МЯЯЯЯЯЯЯУУ!!!

        Кто это и откуда он тут взялся?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Вжух, не спугни его. Он же совсем малыш.',
            nextStep: 'Q4',
          },
        ],
      },

      Q4: {
        person: Interlocutors.ALIEN,
        id: 'Q4',
        text: `Щщщщщщщ…`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Похоже он тоже нас не понимает',
            nextStep: 'Q5',
          },
        ],
      },

      Q5: {
        person: Interlocutors.CAT,
        id: 'Q5',
        text: `А вдруг… Вдруг, он опасен?

        Похоже, эта фиолетовая слизь на поле — его рук дело. Мало ли, что он ещё может вытворить?`,
        type: StepType.question,
        nextStep: 'Q6',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Он выглядит вполне безобидно. Малыш, пойдёшь с нами?',
            nextStep: 'Q6',
          },
        ],
      },

      Q6: {
        person: Interlocutors.ALIEN,
        id: 'Q6',
        text: `Шшшшш…`,
        type: StepType.question,
        nextStep: 'Q7',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, пойдём к Сан Санычу вместе! ',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.SYSTEM,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.SYSTEM,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
