import React from 'react';
import { DividerProps } from './DividerTypes';
import { StyledDivider } from './DividerStyled';

export const Divider = (props: DividerProps) => {
  return (
    <StyledDivider
      className={props.className}
      $isMobileMenuDivider={props.isMobileMenuDivider}
      $isOnboardingDivider={props.isOnboardingDivider}
    />
  );
};

export default Divider;
