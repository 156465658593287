/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg3_innopolice_1_1_1: Scenario = {
  scenarioId: SceneId.stg3_innopolice_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Иннополис',
    text: `Когда вы зашли в мастерскую Петрова, они с Сан Санычем сидели у радиопередатчика и ели бутерброды.

    Увидев вас, Петров радостно вскочил со стула и помахал.`,
    buttons: ['Начать'],
  },
};
