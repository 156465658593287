import scenes from '../resources/scenes';
import { ChatScenario, Question, ScenarioType, SceneId, StepType } from '../resources/scenario';

import { Logger } from '../../common/shared/utils/Logger';
import { isDebug } from '../../common/shared/constants/constants';

function validate(id: SceneId) {
  const scenario: ChatScenario = scenes[id].data as ChatScenario;
  if (scenes[id].scenarioId !== id || scenario.scene_id !== id) {
    Logger.log('ID не совпадает');
    return false;
  }
  const checked: { [id: string]: boolean } = {};
  const steps = scenario.steps;
  const checkTree = (id: string): boolean => {
    if (typeof checked[id] === 'boolean') {
      return checked[id];
    }
    const check = (): boolean => {
      checked[id] = true;
      const step = steps[id];
      // проверять id
      if (!step || step.id !== id) {
        return false;
      }
      if (
        step.type === StepType.to_map_step ||
        step.type === StepType.exit ||
        step.type === StepType.to_lk_step
      ) {
        return true;
      }
      if (step.type === StepType.question) {
        const question = step as Question;
        if (question.buttons) {
          let correct = true;
          const buttons: { [id: string]: boolean } = {};
          for (let i = 0; i < question.buttons.length; i++) {
            correct = correct && !buttons[question.buttons[i].id];
            buttons[question.buttons[i].id] = true;
            checkTree(question.buttons[i].nextStep);
          }
          return correct;
        }
      }
      if (step.condition) {
        checkTree(step.condition.variants[0]);
        checkTree(step.condition.variants[1]);
        return true;
      }
      if (!step.nextStep) {
        return false;
      }
      checkTree(step.nextStep);
      return true;
    };
    checked[id] = check();
    if (!checked[id]) {
      Logger.log('ERROR - ID:', id);
    }
    return checked[id];
  };

  checkTree(scenario.startId);
  return !Object.values(checked).some((s) => !s);
}

function validateAll() {
  const res: boolean[] = [];
  for (const id in scenes) {
    const scene = scenes[id as SceneId];
    if (scene.type === ScenarioType.chat) {
      Logger.log('VALIDATE', id);
      res.push(validate(id as SceneId));
      Logger.log('RESULT', res[res.length - 1]);
    }
  }
  Logger.log(res.every((i) => i) ? 'VALID' : 'INVALID');
}

if (isDebug) {
  validateAll();
}
