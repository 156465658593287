import React from 'react';
import { useDispatch } from 'react-redux';
import { logoutAsync } from '../redux/user/userActions';

const useLogout = () => {
  const dispatch = useDispatch();
  const handleLogout = React.useCallback(() => dispatch(logoutAsync()), [dispatch]);

  return { handleLogout };
};

export default useLogout;
