import { useMemo } from 'react';
import { SceneId, StepType } from '../types/scenario';

import { useSelector } from 'react-redux';
import { selectScenarios } from '../redux/user/userGetters';
import {
  selectActiveChatHistory,
  selectActiveChatScenario,
} from '../redux/scenario/scenarioGetters';

export function useIsProfileAvailable() {
  const scenarioState = useSelector(selectScenarios);
  const scene = useSelector(selectActiveChatScenario);
  const sceneHistory = useSelector(selectActiveChatHistory);
  return useMemo(() => {
    // if (scenarioState.scenarios) {
    //   if (scenarioState.scenarios[SceneId.scenario_1_17]) {
    //     return true;
    //   }
    //   if (scenarioState.startedScenarios && scenarioState.startedScenarios[SceneId.scenario_1_17]) {
    //     const started = scenarioState.startedScenarios[SceneId.scenario_1_17];
    //     if (started?.findIndex((step) => step.type === StepType.answer) !== -1) {
    //       return true;
    //     }
    //   }
    //   if (scene && scene.scenarioId === SceneId.scenario_1_17) {
    //     return (sceneHistory || []).findIndex((step) => step.type === StepType.answer) !== -1;
    //   }
    // }
    // если поставить true - разблокирован профиль для всех, из-за завершения конкурса
    return true;
  }, [scene, sceneHistory, scenarioState]);
}
