/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  Step,
  StepType,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT_ID';

export const scenario_3_19_0: Scenario = {
  scenarioId: SceneId.scenario_3_19_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Иннополис',
    text: 'Вы с Вжухом, Малевичем и Петровым заходите в холл главного здания Иннополиса, чтобы там подождать Софию в уютном кафе.  Но вдруг Петров встревоженно вскакивает.',
    buttons: ['Продолжить'],
  },
};

const ALT: Record<string, Step> = {
  ...splitStep({
    person: Interlocutors.PETROV,
    id: 'ALT1',
    text: `От этого эксперимента во многом зависит судьба 10G вышки — будет она работать и останется в парке или нет. Сейчас началась активная фаза испытаний, поэтому отлучаться ни в коем случае нельзя.

Ох, вот дырявая голова! Я ведь недавно взял себе щенка из приюта, и уже скоро его пора будет кормить. А у меня после прохода через силовое поле внезапно разрядился и выключился телефон. Так бы я мог позвонить своему другу Ване — я помню его номер телефона. У Вани есть ключи от моей квартиры, правда он болеет и сам сходить не сможет. Но найдет того, кто покормит моего щенка. Ведь я уже никак не могу отойти оставить эксперимент без присмотра 🙁️`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Петров, может быть, тебе нужна какая-то помощь?',
        nextStep: 'ALT2',
      },
      {
        id: 'b2',
        text: 'Да уж, столько приключений нам пришлось пройти, не удивительно, что ты забыл и про эксперимент, и про щенка!',
        nextStep: 'Q4',
      },
    ],
  }),
  ALT2: {
    person: Interlocutors.PETROV,
    id: 'ALT2',
    text: 'Да, нужно что-то придумать с кормлением щенка 🙁️',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Не переживай, Петров! Собаки могут долгое время обходиться без еды, главное, чтобы в доступе была вода. (Игровое время не будет заморожено)',
        nextStep: 'ALT3/0',
      },
      {
        id: 'b2',
        text: 'Мой телефон тоже выключился, но работают стационарные. Я знаю, что у охранника как раз есть. Ты можешь записать номер Вани, я спущусь и попрошу охранника позвонить ему. (Игровое время будет заморожено на 7 минут)',
        nextStep: 'ALT4/0',
      },
      {
        id: 'b3',
        text: 'Давай я схожу покормлю твоего щенка? Думаю, что как раз успею до прихода Софии. (Игровое время будет заморожено на 30 минут)',
        nextStep: 'ALT5/0',
      },
    ],
  },
  ...splitStep({
    person: Interlocutors.PETROV,
    id: 'ALT3',
    type: StepType.message,
    text: `Спасибо, {{NAME}}! Ты меня [[успокоил/успокоила]]. Не знал, что ты так хорошо разбираешься в собаках 😊

Я останусь здесь доделывать эксперимент, а ты спускайся к Вжуху и Малевичу. София уже скоро придет.`,
    nextStep: 'Q5',
  }),
  ...splitStep({
    person: Interlocutors.PETROV,
    id: 'ALT4',
    type: StepType.message,
    text: `Спасибо огромное, {{NAME}}! Ты очень меня [[выручил/выручила]] 😊

Я останусь здесь доделывать эксперимент, а ты спускайся к Вжуху и Малевичу. София уже скоро придет.`,
    nextStep: 'Q5',
  }),
  ...splitStep({
    person: Interlocutors.PETROV,
    id: 'ALT5',
    type: StepType.message,
    text: `Спасибо тебе огромное, {{NAME}}! 😍

А я как раз успешно завершил эксперимент, благодаря твоей помощи 😊 

Кажется, София придет с минуты на минуту, пойдем к Вжуху и Малевичу.`,
    nextStep: 'Q5',
  }),
};

export const scenario_3_19: Scenario = {
  scenarioId: SceneId.scenario_3_19,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Иннополис',
    scene_id: 'scenario_3_19',
    startId: 'Q1/0',
    steps: {
      ...splitStep({
        person: Interlocutors.PETROV,
        id: 'Q1',
        text: `Со всеми этими неожиданными приключениями я совсем замотался и забыл про свой эксперимент! Мне срочно надо бежать в лабораторию! 

{{NAME}}, хочешь посмотреть? Я как раз работаю над преобразованием искр от 10G вышки в энергию для обеспечения парка.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, конечно! Мне очень интересно посмотреть',
            nextStep: 'Q2',
          },
        ],
      }),
      Q2: {
        person: Interlocutors.SYSTEM,
        id: 'Q2',
        type: StepType.message,
        text: `Вы с Петровым поднялись в его лабораторию.`,
        nextStep: 'Q3',
      },
      Q3: {
        person: Interlocutors.PETROV,
        id: 'Q3',
        text: 'Не бойся искр, они совсем не опасны. Яркие только очень, поэтому долго на них не смотри.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ладно, не буду',
            nextStep: 'ALT1/0',
          },
          {
            id: 'b2',
            text: 'Трудно оторваться — уж очень красиво искрит!',
            nextStep: 'ALT1/0',
          },
        ],
      },

      ...ALT,

      Q4: {
        person: Interlocutors.PETROV,
        id: 'Q4',
        type: StepType.message,
        text: `Да уж! Голова кругом от всех событий! Я останусь доделывать эксперимент и постараюсь что-то придумать с кормлением щенка. А ты спускайся к Вжуху и Малевичу. Обязательно дождитесь Софию, она уже скоро придет.`,
        nextStep: 'Q5',
      },
      Q5: {
        person: Interlocutors.SYSTEM,
        id: 'Q5',
        type: StepType.message,
        text: `Вы сидите в кафе холла Иннополиса, ожидая Софию. Вдруг дверь резко открывается и в помещение стремительно и грациозно входит элегантная девушка. По просиявшему взгляду Малевича вы понимаете, что это и есть София.`,
        nextStep: EXIT_ID,
      },
      [EXIT_ID]: {
        person: Interlocutors.PETROV,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};

export const scenario_3_19_1: Scenario = {
  scenarioId: SceneId.scenario_3_19_1,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Новости парка',
    text: 'Поздравляем! Вы прошли все задания. Финал игры откроется 21 мая.',
    buttons: ['Продолжить'],
  },
};

const romanToInt = function (string: []) {
  const convert = {
    1: 'I',
    5: 'V',
    10: 'X',
    50: 'L',
    100: 'C',
    500: 'D',
    1000: 'M',
  };

  const number = 0;
  let previosOne;

  for (let i = 0; i < string.length; i++) {
    const value = convert[string[i]];
    console.log(string[i]);
  }

  return number;
};
