import styled from 'styled-components';
import colors from '../../../common/shared/constants/colors';
import { ChallengesProps } from './ChallengesTypes';
import { BREAKPOINT_PHONE, BREAKPOINT_TABLET } from '../../../common/shared/constants/constants';

export const StyledChallenges = styled.div<ChallengesProps>`
  width: 100%;
  background-color: ${colors.white};

  padding: 20px;
  border-radius: 16px;
`;

export const StyledChallengesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  margin-top: 10px;

  & > * + * {
    margin-top: 16px;
  }

  /* @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    & > :nth-child(even) {
      padding-left: 15px;
    }

    & > :nth-child(odd) {
      padding-right: 15px;
    }

    & > * + * {
      margin-top: 0;
    }

    & > * {
      width: 50%;
    }
  }

  @media screen and (min-width: ${BREAKPOINT_TABLET}) {
    & > * + * {
      margin-top: 10px;
    }

    & > * {
      width: 100%;
    }

    & > :nth-child(even),
    & > :nth-child(odd) {
      padding: 0;
    }
  } */
`;
