/* eslint-disable max-len */
import {
  Action,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  Step,
  StepType,
} from '../../../scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT_ID';
const person = Interlocutors.GRANDMOTHER;

const ALT: Record<string, Step> = {
  ...splitStep({
    person,
    id: 'ALT1',
    text: `А вот и поезд!

Эх, жалко только, что мой внучок Костик сегодня не с нами. Ему бы очень понравилось расследовать это дело. Да и на ярмарку он тоже хотел посмотреть, но не смог прийти… 😔`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'А что случилось? Почему он не пришел?',
        nextStep: 'ALT2',
      },
      {
        id: 'b2',
        text: 'Ну ничего, ярмарка будет еще несколько дней, а с расследованием все-таки тянуть нельзя...',
        nextStep: 'Q1',
      },
    ],
  }),
  ALT2: {
    person,
    id: 'ALT2',
    text: 'Да никак не может освоить математику. Я не знаю, как ему объяснить, а много денег на репетиторов нет. Вот и приходится ему самому сидеть с утра до ночи… Но только толку от этого мало 🙁️ ',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Не переживайте, Бабуля, все наладится. Многие школьники через это проходят, математика — непростая наука. (Игровое время не будет заморожено)',
        nextStep: 'ALT3',
      },
      {
        id: 'b2',
        text: 'Я знаю, как Вам быть. На Ютубе есть бесплатный канал по математике, там все очень понятно объясняют и к тому же это абсолютно бесплатно. Давайте, я Вам покажу. (Игровое время будет заморожено на 5 минут)',
        nextStep: 'ALT4',
      },
      {
        id: 'b3',
        text: 'А давайте я подтяну Вашего внука по провисающим темам. (Игровое время будет заморожено на 30 минут)',
        nextStep: 'ALT5',
      },
    ],
  },
  ALT3: {
    person,
    id: 'ALT3',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Я [[рад/рада]], что [[смог/смогла]] Вас приободрить! Давайте вернемся к поиску улик',
        nextStep: 'Q1',
      },
    ],
    text: `Спасибо, {{NAME}}! [[Успокоил/Успокоила]] сердце старушки 😊`,
  },
  ALT4: {
    person,
    id: 'ALT4',
    text: `Вот здорово — до чего техника дошла! Теперь мой Костик точно освоит этот непростой предмет! 😊`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Ну вот и здорово! А теперь давайте вернемся к поиску улик',
        nextStep: 'Q1',
      },
    ],
  },
  ALT5: {
    person,
    id: 'ALT5',
    text: `[[Какой/Какая]] же ты [[умный/умная]]! Теперь Костик точно выровняет оценки в школе. Большое тебе спасибо, {{NAME}}! 😊`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: '[[Рад/Рада]], что [[смог/смогла]] помочь! А теперь давайте вернемся к поиску улик',
        nextStep: 'Q1',
      },
    ],
  },
};

export const scenario_3_2: Scenario = {
  scenarioId: SceneId.scenario_3_2,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Поезд в "Артек"',
    scene_id: 'scenario_3_2',
    startId: 'ALT1/0',
    steps: {
      ...ALT,
      Q1: {
        person,
        id: 'Q1',
        text: 'Ой, кажется, двери в вагон поезда открываются с помощью необычного замка с электронным кодом, нужно решить задачу, чтобы попасть внутрь…',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Не волнуйтесь, Бабуля! Я уже [[решал/решала]] эту задачку и помню код для замка!',
            nextStep: 'Q2',
          },
          {
            id: 'b2',
            text: 'Так, я ведь здесь уже [[бывал/бывала]], надо только вспомнить какое число было кодом...',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        person,
        id: 'Q2',
        text: 'Что набирать?',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: '36',
            nextStep: 'Q2-1',
          },
          {
            id: 'b2',
            text: '48',
            nextStep: 'Q3',
          },
          {
            id: 'b3',
            text: '144',
            nextStep: 'Q2-2',
          },
        ],
      },
      'Q2-1': {
        person,
        id: 'Q2-1',
        text: 'Нет, это не подходит 🙁',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b2',
            text: '48',
            nextStep: 'Q3',
          },
          {
            id: 'b3',
            text: '144',
            nextStep: 'Q2-2',
          },
        ],
      },
      'Q2-2': {
        person,
        id: 'Q2-2',
        text: 'Эх, неверный код 🙁',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: '36',
            nextStep: 'Q2-1',
          },
          {
            id: 'b2',
            text: '48',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        person,
        id: 'Q3',
        type: StepType.message,
        text: `Как здорово! С твоей памятью и смекалкой мы быстро докопаемся до истины 😊`,
        nextStep: 'Q4',
      },
      Q4: {
        person: Interlocutors.SYSTEM,
        id: 'Q4',
        type: StepType.message,
        text: `Вы входите в уже знакомый вагон поезда и начинаете внимательно его осматривать...`,
        nextStep: 'Q5',
      },
      Q5: {
        person,
        id: 'Q5',
        text: 'Смотри… Кажется, тут что-то есть! Это похоже на кирку. Такой инструмент обычно используют для добычи горных пород или для работы с твердым грунтом... Странно, откуда здесь взялась кирка, ума не приложу 🤔',
        type: StepType.question,
        maxAnswers: 1,
        action: Action.GivePick,
        buttons: [
          {
            id: 'b1',
            text: 'Хм… И правда, откуда в поезде могла взяться кирка?',
            nextStep: 'Q6',
          },
          {
            id: 'b2',
            text: 'Не помню, чтобы в «Большой перемене» были шахты по добыче горных пород…',
            nextStep: 'Q6',
          },
        ],
      },
      Q6: {
        person,
        id: 'Q6',
        type: StepType.message,
        text: `Я знаю, кто нам может помочь с разгадкой. Петров — парнишка с гениальным умом, он точно справится с тайной этой улики. Давай возьмем кирку с собой и отправимся к Петрову, он сейчас в Иннополисе.`,
        nextStep: EXIT_ID,
      },

      [EXIT_ID]: {
        person: Interlocutors.TEACHER,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
