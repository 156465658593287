import isDeepEqual from 'deep-equal';

import freezeSlice from './';
import { AppThunk } from '../../types';
import { FreezeState } from '../../hooks/useFrozenState';

const { setFreezeState } = freezeSlice.actions;

export const setFreezeStateAction =
  (value: FreezeState): AppThunk =>
  async (dispatch, getState) => {
    const freezeState = getState().freeze;
    const isEqual = isDeepEqual(value, freezeState);

    if (!isEqual) dispatch(setFreezeState(value));
  };
