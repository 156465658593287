/* eslint-disable max-len */
import {
  Conditions,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT';
const TO_MAP_ID = 'TO_MAP_ID';

const person = Interlocutors.TEACHER;

const TEST_TVOI_VECTOR_3: TestStep = {
  maxAnswers: 2,
  external_id: 'test-tvoi-vektor-junior-210401',
  id: 'TEST_TVOI_VECTOR_3',
  name: 'Твой вектор 3/3',
  nextStep: 'Q11',
  order: [
    {
      description: '',
      subtest: 'T1',
      question: 'Q4',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q5',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q6',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q38',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q18',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q29',
    },
  ],
  path: 'test-tvoi-vektor-junior-210401.json',
  person: Interlocutors.PETROV,
  type: StepType.test,
};

const TEST_TVOI_VIBOR_5: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'TEST_TVOI_VIBOR_5',
  name: 'Твой выбор 5/5',
  nextStep: 'Q13',
  order: [
    {
      description:
        'Хочется, чтобы труд был тебе в радость, поэтому выбери то, чем хочешь здесь заняться.',
      subtest: 'T5',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person: Interlocutors.PETROV,
  type: StepType.test,
};

export const oboutInnopolice_1_1: Scenario = {
  scenarioId: SceneId.oboutInnopolice_1_1,
  type: ScenarioType.chat,
  data: {
    title: 'Иннополис',
    scene_id: 'scenario_1_25',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Спасибо 🙂 

        {{NAME}}, а ты ведь здесь ещё толком не [[был/была]], да?
        
        Вообще, Иннополис — уникальное место, университет и город одновременно. Иннополис считается одним из наукоградов России, как Сколково или Кольцово, знаешь о таких?`,
        id: 'Q1',
        type: StepType.question,
        person: Interlocutors.PETROV,
        nextStep: 'Q2',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Я [[слышал/слышала]] о Сколково, это в подмосковье, да? ',
            nextStep: 'Q2',
          },
          {
            id: 'b2',
            text: 'Нет, я об этом не знаю...',
            nextStep: 'Q3',
          },
        ],
      },
      Q2: {
        id: 'Q2',
        type: StepType.message,
        text: 'Да, я поражен твоими знаниями! 😍',
        person: Interlocutors.PETROV,
        nextStep: 'Q4',
      },
      Q3: {
        id: 'Q3',
        type: StepType.message,
        text: 'Ну ничего, я сейчас расскажу 😊',
        person: Interlocutors.PETROV,
        nextStep: 'Q4',
      },
      Q4: {
        id: 'Q4',
        type: StepType.question,
        person: Interlocutors.PETROV,
        nextStep: 'Q5',
        text: `Иннополис построен на основе высокотехнологичных разработок, и в этом заключается его основная эксклюзивность. Экология, созданная безопасная среда, большие возможности для получения образования.

        В город приезжают много интересных лекторов, занимаются роботами и высокими технологиями, а еще тут можно познакомиться с ребятами со всего света! `,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да! Хочу там побывать!',
            nextStep: 'Q5',
          },
          {
            id: 'b2',
            text: 'Ну да, интересно в целом',
            nextStep: 'Q5',
          },
        ],
      },
      Q5: {
        id: 'Q5',
        type: StepType.message,
        text: 'Многие, отправляясь сюда, принимают решение остаться или вдохновляются на новые творения. Молодежь со всей России приезжает, чтобы познакомиться с особенностями жизненного уклада и технологическими новшествами.',
        person: Interlocutors.PETROV,
        nextStep: 'Q6',
      },
      Q6: {
        id: 'Q6',
        person: Interlocutors.PETROV,
        text: 'Иннополис был создан для развития информационных технологий, но эти странные аномалии в парке отключили нас от электричества и остановили работу ☹️',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, и что же делать?',
            nextStep: 'Q7',
          },
        ],
      },

      Q7: {
        id: 'Q7',
        person: Interlocutors.PETROV,
        text: 'А вот что! Дерни за этот рычаг.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ух ты!',
            nextStep: 'Q8',
          },
        ],
      },

      Q8: {
        id: 'Q8',
        person: Interlocutors.CAT,
        text: 'Да будет свет!',
        type: StepType.message,
        nextStep: 'Q9',
      },

      Q9: {
        id: 'Q9',
        person: Interlocutors.PETROV,
        text: `А теперь, когда к нам вернулось электричество, все идём в лабораторию к анализатору!

        Образец слизи у меня вот. Где ваша вода из Байкала? Выливай в прибор, в эту колбу.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А давайте ещё проанализируем эту шерсть? Она принадлежит странному существу, которое мы с Вжухом встретили недалеко от футбольного поля.',
            nextStep: 'Q10',
          },
        ],
      },

      Q10: {
        id: 'Q10',
        person: Interlocutors.PETROV,
        text: `Всё, анализатор запущен! Скоро мы узнаем, как связаны все эти странные события в парке. А пока работает, давай попьём чаю и ты мне расскажешь про свои увлечения?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Конечно, с удовольствием!',
            nextStep: 'TEST_TVOI_VECTOR_3',
          },
        ],
      },

      TEST_TVOI_VECTOR_3,

      Q11: {
        id: 'Q11',
        person: Interlocutors.CAT,
        text: `{{name}}! Кажется у нас проблема…`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Вжух, ну сколько можно о проблемах. Давай отдхонём немного?',
            nextStep: 'Q12',
          },
        ],
      },

      Q12: {
        id: 'Q12',
        person: Interlocutors.PETROV,
        text: `А вы, молодой человек, очень интересно рассказываете. Хочу задать ещё один вопрос.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Конечно, давайте',
            nextStep: 'TEST_TVOI_VIBOR_5',
          },
        ],
      },

      TEST_TVOI_VIBOR_5,

      Q13: {
        id: 'Q13',
        person: Interlocutors.CAT,
        text: `{{NAME}}! У нас точно проблема!`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ну что же случилось?',
            nextStep: 'Q14',
          },
        ],
      },

      Q14: {
        id: 'Q14',
        person: Interlocutors.CAT,
        text: 'Белое существо… Пропало.',
        type: StepType.message,
        nextStep: 'Q15',
      },

      Q15: {
        id: 'Q15',
        person: Interlocutors.PETROV,
        text: 'Анализатор завис. Критическая ошибка.',
        type: StepType.message,
        nextStep: EXIT_ID,
      },

      [EXIT_ID]: {
        person: Interlocutors.SYSTEM,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.SYSTEM,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
