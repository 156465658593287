/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_innopolicePetrov_1_1_0: Scenario = {
  scenarioId: SceneId.stg2_innopolicePetrov_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.CAT,
    title: 'Иннополис',
    text: `Ура! Наконец микросхема у нас!

    Теперь пойдём к Петрову, запустим анализатор и узнаем, что за существо мы встретили.`,
    buttons: ['Продолжить'],
  },
};
