/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg3_hersones_1_1_1: Scenario = {
  scenarioId: SceneId.stg3_hersones_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Херсонес',
    text: `Следуя за Женей, вы оказываетесь в Херсонесе. Его древние развалины погружают всех присутствующих в атмосферу античных мифов..`,
    buttons: ['Начать'],
  },
};
