import { Step, StepType, Interlocutors } from '../../../types/scenario';
import { splitStep } from '../../../utils/ScenarioUtils';
import { images } from '../tests/images';

export const CREATIVE_TEST_1: { [id: string]: Step } = {
  ...splitStep({
    person: Interlocutors.ALIEN,
    id: 'CREATIVE_TEST',
    callToAction: 'Напиши и отправь',
    type: StepType.question,
    maxAnswers: 1,
    text: `Перед тобой несколько картин известных художников абстракционистов. Придумай им названия.

    На обдумывание каждой картины у тебя будет 1 минута, поэтому не затягивай.

    Твои ответы должны быть приличными и цензурными 😊

    Придумай название для каждой картины, впиши в поле ниже и отправь. 

    Обращаю внимание, что нужно обязательно нажать кнопку «Отправить ответ», до окончания времени на секундомере, иначе ответ не будет засчитан.`,
    buttons: [
      {
        id: 'b1',
        text: 'Начать',
        nextStep: 'CREATIVE_TEST_1',
      },
    ],
  }),
  CREATIVE_TEST_1: {
    person: Interlocutors.ALIEN,
    id: 'CREATIVE_TEST_1',
    variable: 'CREATIVE_TEST_1',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_1, title: '' },
    nextStep: 'CREATIVE_TEST_2',
    timeout: 60,
  },
  CREATIVE_TEST_2: {
    person: Interlocutors.ALIEN,
    id: 'CREATIVE_TEST_2',
    variable: 'CREATIVE_TEST_2',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_2, title: '' },
    nextStep: 'CREATIVE_TEST_3',
    timeout: 60,
  },
  CREATIVE_TEST_3: {
    person: Interlocutors.ALIEN,
    id: 'CREATIVE_TEST_3',
    variable: 'CREATIVE_TEST_3',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_3, title: '' },
    nextStep: 'CREATIVE_TEST_4',
    timeout: 60,
  },
  CREATIVE_TEST_4: {
    person: Interlocutors.ALIEN,
    id: 'CREATIVE_TEST_4',
    variable: 'CREATIVE_TEST_4',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_4, title: '' },
    nextStep: 'CREATIVE_TEST_5',
    timeout: 60,
  },
  CREATIVE_TEST_5: {
    person: Interlocutors.ALIEN,
    id: 'CREATIVE_TEST_5',
    variable: 'CREATIVE_TEST_5',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_5, title: '' },
    nextStep: 'CREATIVE_TEST_6',
    timeout: 60,
  },
  CREATIVE_TEST_6: {
    person: Interlocutors.ALIEN,
    id: 'CREATIVE_TEST_6',
    variable: 'CREATIVE_TEST_6',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_6, title: '' },
    nextStep: 'Q6',
    timeout: 60,
  },
};
