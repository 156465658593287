/* eslint-disable max-len */
import {
  Action,
  Conditions,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

export const scenario_3_12_0: Scenario = {
  scenarioId: SceneId.scenario_3_12_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Москва-Сити',
    text: 'Вы, Бабуля, Петров и синий кот заходите в Москва-Сити. У главного здания вас встречают Сан Саныч, Светлана Васильевна и Вжух. Все удивленно устремляют взгляд на незнакомца. ',
    buttons: ['Продолжить'],
  },
};

const EXIT_ID = 'EXIT_ID';

export const scenario_3_12: Scenario = {
  scenarioId: SceneId.scenario_3_12,
  type: ScenarioType.chat,
  data: {
    title: 'Херсонес',
    scene_id: 'scenario_3_12',
    startId: 'Q1/0',
    steps: {
      ...splitStep({
        person: Interlocutors.CAT,
        id: 'Q1',
        type: StepType.message,
        text: `КТО ЭТОТ СИНИЙ ГОСТЬ? 🙀

Будто смотрю на себя в зеркало, но только нарядился на карнавал красок… А еще изрядно потрепался. Ребята, кого это вы привели?`,
        nextStep: 'Q2',
      }),
      Q2: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q2',
        type: StepType.message,
        text: `Да, Вжух, мы все очень удивлены ЕГО появлению, но давайте сначала проявим гостеприимство и накормим беднягу. Он явно давно не ел…`,
        nextStep: 'Q3',
      },
      Q3: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q3',
        type: StepType.message,
        text: `Мда… Вот это поворот! Пойдемте, рядом с моим новым офисом как раз есть отличное кафе. А потом можем все обсудить у меня в спокойной обстановке.`,
        nextStep: 'Q4',
      },
      Q4: {
        person: Interlocutors.SYSTEM,
        id: 'Q4',
        type: StepType.message,
        text: `Все плотно поели в кафе. Во время обеда обитатели «Большой перемены» представились незнакомцу. А после устроились в новом уютном офисе Сан Саныча за ароматным чаем, чтобы узнать историю синего кота....`,
        nextStep: 'Q5',
      },
      Q5: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q5',
        type: StepType.message,
        text: `Прошу прощения, а у вас есть кофе? Я, видите ли, пью только свежесваренный эспрессо.`,
        nextStep: 'Q6/0',
      },
      ...splitStep({
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q6',
        type: StepType.message,
        text: `А гость-то, кажется, непростой… 🤔

Ну, рассказывай! Кто ты, как сюда попал и главное: что ты делал рядом с генератором ледяного дыма, который я заказал несколько недель назад у Петрова?`,
        nextStep: 'Q7/0',
      }),
      ...splitStep({
        person: Interlocutors.BLUE_CAT,
        id: 'Q7',
        type: StepType.message,
        text: `Меня зовут… 

Меня зовут… 

Видите ли, я не помню своего имени. Зовите меня просто — Синий. Я попал сюда совершенно случайно. Это такая длинная история… 😿

Последнее, что я помню, это как моя дорогая хозяйка София взяла меня на утренний променад. А потом БАЦ и какая-то искрящаяся штуковина ослепила меня, я так испугался, что пустился бежать. Бежал, бежал, а когда огляделся — понял, что нахожусь в неизвестном мне месте 🙀`,
        nextStep: 'Q8/0',
      }),
      ...splitStep({
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q8',
        type: StepType.message,
        text: `Так эта искрящаяся штуковина — моя 10G вышка! Она должна обеспечить парк передовыми технологиями связи. Также она вырабатывает энергию во время раздачи сигнала. Но только эти последние технологии еще не до конца протестированы, и иногда вышка искрит... 😔

Я специально привез вышку в «Большую перемену» зная, что Петрову под силу ее доработать, чтобы выходящие икры можно было собирать и преобразовывать в энергию. Так у людей была бы не только сверхскоростная качественная связь, но и источник энергии, которой хватило бы для обеспечения электричеством всего нашего парка.`,
        nextStep: 'Q9/0',
      }),
      ...splitStep({
        person: Interlocutors.BLUE_CAT,
        id: 'Q9',
        type: StepType.message,
        text: `Так вот что это было! 😾

В общем, я долго бродил, выбился из сил и проголодался. Сел в отчаянии и тут в мой чувствительный нос проник воодушевляющий аромат свежей сметаны! Как завороженный я шел на запах и пришел в изумительно обставленную кухню…`,
        nextStep: 'Q10',
      }),
      Q10: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q10',
        type: StepType.message,
        text: `Спасибо, я старалась 😊`,
        nextStep: 'Q11',
      },
      Q11: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q11',
        type: StepType.message,
        text: `Сметана так и шептала мне: съешь меня. И я не сдержался! Хоть и понимал, что чужое брать нехорошо, но этот аромат, и я был такой голодный… 😿

В общем, открыть крышку мне так и не удалось — меня напугали чьи-то шаги. Целая банка драгоценной сметаны разбилась и растеклась по всей кухне! 🙀

Я побежал к открытому окну, но по пути зацепился поводком за торчащий из паркета гвоздь… О, мой любимый поводок! Его подарила мне дорогая София на день рождения 😿

А шаги все приближались. Мне ничего не оставалось, как снять поводок оставить лежать зацепленным за тот роковой гвоздь. Я выпрыгнул с испуга в окно и помчался куда глаза глядят...`,
        nextStep: 'Q12',
      },
      Q12: {
        person: Interlocutors.SYSTEM,
        id: 'Q12',
        type: StepType.system,
        condition: {
          type: Conditions.HAS_ROPE,
          variants: ['Q13', 'Q14'],
        },
        _meta: {},
      },
      Q13: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q13',
        text: 'Так вот что это была за таинственная веревка! И теперь понятно, почему сметанные следы на моей кухне после той ночи напоминали кошачьи 🤔',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            action: Action.LostRope,
            text: 'Ты знаешь, Синий, ведь твой любимый поводок все еще у меня. Вот, держи!',
            nextStep: 'Q13-1',
          },
        ],
      },
      'Q13-1': {
        person: Interlocutors.BLUE_CAT,
        id: 'Q13-1',
        type: StepType.message,
        text: `Ух ты! Мой поводочек! Как же я рад, благодарю! 😻`,
        nextStep: 'Q15',
      },
      Q14: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q14',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            action: Action.LostRope,
            text: 'Ой, Синий, прости! Я [[использовал/использовала]] твой поводок для того, чтобы вытащить Светлану Васильевну из ямы…',
            nextStep: 'Q14-1',
          },
        ],
        text: `Так вот что это была за таинственная веревка! И теперь понятно, почему сметанные следы на моей кухне после той ночи напоминали кошачьи 🤔`,
      },
      'Q14-1': {
        person: Interlocutors.BLUE_CAT,
        id: 'Q14-1',
        type: StepType.message,
        text: `Ради такого благородного дела даже любимого поводка не жалко! На твоем месте я бы поступил так же.`,
        nextStep: 'Q15',
      },
      Q15: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q15',
        text: 'Многоуважаемая Бабуля, простите меня! Я вовсе не хотел наводить такой беспорядок на Вашей чудесной кухне, я был действительно очень голоден и напуган 😿',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Я [[уверен/уверена]], что Бабуля совсем не обижается',
            nextStep: 'Q16',
          },
        ],
      },
      Q16: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q16',
        type: StepType.message,
        text: `Ну что ты, бедный котик! Нелегко тебе пришлось 🙁️`,
        nextStep: 'Q17',
      },
      Q17: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q17',
        type: StepType.message,
        text: `После сметанного конфуза я долго бежал, выбился из сил и решил укрыться в ближайшем здании, чтобы перевести дух. А когда осмотрелся, испугался еще больше — повсюду торчали провода, стояли какие-то механизмы, лежали непонятные чертежи и колбы… 

Пойдемте, я покажу вам это страшное место!`,
        nextStep: EXIT_ID,
      },
      [EXIT_ID]: {
        person: Interlocutors.TEACHER,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
