/* eslint-disable react/no-children-prop */
import React from 'react';

import { Text } from '../../common/shared/ui/Text';
import { useSelector } from 'react-redux';
import { BaseProps } from '../../common/shared/styles/styledBase';
import { selectUserInfo } from '../redux/user/userGetters';

export function prepareText(
  text: string,
  variables: Record<string, string>,
  gender: 'male' | 'female',
) {
  let result = text;

  for (const key in variables) {
    if (Object.prototype.hasOwnProperty.call(variables, key)) {
      const regex = new RegExp(`\\{\\{${key}\\}\\}`, 'gmi');
      result = result.replace(regex, variables[key]);
    }
  }

  result = result.replace(/(\[\[([^\]]*)\/([^\]]*)\]\])/gi, (part, _part, male, female) => {
    return gender === 'male' ? male : female;
  });

  return result;
}

interface TemplatedTextProps extends BaseProps {
  children: string;
  isHTML?: boolean;
}

export function TemplatedText(props: TemplatedTextProps) {
  const userInfo = useSelector(selectUserInfo);
  const variables = userInfo?.variables || {};

  const result = prepareText(
    props.children,
    variables,
    userInfo?.profile?.female ? 'female' : 'male',
  );

  if (props.isHTML) {
    return (
      <Text
        $wordBreak='break-word'
        $whiteSpace='pre-line'
        $textStyle={props.$textStyle}
        // @ts-ignore
        dangerouslySetInnerHTML={{ __html: result }}
      />
    );
  }

  return (
    <Text
      children={result}
      $textStyle={props.$textStyle}
      $wordBreak='break-word'
      $whiteSpace='pre-line'
    />
  );
}
