import React, { FC } from 'react';

import { TextProps } from './Types';
import { TextStyled } from './TextStyled';

export const Text: FC<TextProps> = (props) => {
  const { displayAs = 'span', isHtml, children, ...baseProps } = props;

  const childrenProps = isHtml
    ? { dangerouslySetInnerHTML: { __html: children } }
    : { children: children };

  return <TextStyled as={displayAs} {...baseProps} {...childrenProps} />;
};
