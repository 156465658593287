import { FreezeState } from '../../hooks/useFrozenState';
import { RootState } from '../../types';

export const selectFrozenState = (state: RootState): FreezeState => {
  return {
    text: state.freeze.text,
    start: state.freeze.start,
    title: state.freeze.title,
    duration: state.freeze.duration,
    isOpened: state.freeze.isOpened,
  };
};
