import styled, { css } from 'styled-components';
import { TextStyled } from '../Text';
import { BaseProps } from '../../styles/styledBase';
import colors from '../../constants/colors';
import close from '../../../../assets/images/close.svg';

import { BREAKPOINT_PHONE, BREAKPOINT_TABLET, GAP } from '../../constants/constants';

export const ModalBackground = styled.div`
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const ModalWrapperStyled = styled.div<{
  $isCenterModal: boolean;
}>`
  top: 0;
  left: 0;
  z-index: 9999999999;
  position: fixed;

  ${({ $isCenterModal }) =>
    $isCenterModal &&
    `
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}

  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  background: ${colors.base40};
`;

export const ModalContainerStyled = styled.div<{
  $isInputToolbarModal?: boolean;
}>`
  display: flex;
  position: relative;
  flex-direction: column;

  border-radius: ${GAP.m};

  padding: 20px;
  margin: 80px auto 20px;
  background: ${colors.white};

  width: 800px;
  max-width: calc(100% - 30px);

  z-index: 5;

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    padding: 36px 30px;
    width: 600px;
    margin: 80px auto 20px;

    ${({ $isInputToolbarModal }) =>
      $isInputToolbarModal
        ? css`
            width: 1200px;
          `
        : css`
            width: 600px;
          `}
  }

  @media screen and (min-width: ${BREAKPOINT_TABLET}) {
    padding: 30px 50px;
    width: 800px;

    ${({ $isInputToolbarModal }) =>
      $isInputToolbarModal
        ? css`
            width: 1200px;
          `
        : css`
            width: 800px;
          `}
  }
`;

export const ModalBodyStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 1px;
`;

export const ModalTitleStyled = styled(TextStyled)<BaseProps>`
  margin-bottom: ${GAP.ss};

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    font-size: 18px;
    line-height: 1.35;
  }
`;

export const ModalCloseStyled = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 9;
  display: block;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background: none;
  border: none;

  &:after,
  &:before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 2px;
    height: 27px;
    content: '';
    background: ${colors.grey4};
    border-radius: 5px;
    transition: 0.2s background-color ease-in-out;
  }

  &:after {
    transform: matrix(-0.71, 0.71, 0.71, 0.71, 0, 0);
  }

  &:before {
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  }

  &:hover:after,
  &:hover:before {
    //background: var(--slate-gray)80;
  }
`;

export const ModalCrossStyled = styled.div<{ $isRelative?: boolean }>`
  ${({ $isRelative }) =>
    !$isRelative &&
    css`
      position: absolute;
      z-index: 10;

      top: -20px;
      right: 0;

      transform: translateY(-40px);
    `}

  width: 40px;
  height: 40px;

  border-radius: 50%;

  background-color: ${colors.grey5};
  background-image: url(${close});
  background-position: center;
  background-repeat: no-repeat;

  cursor: pointer;
  display: none;
`;
