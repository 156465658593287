/* eslint-disable max-len */
import { Interlocutors, Step, StepType, SystemStepType } from '../../../types/scenario';
import { splitStep } from '../../../utils/ScenarioUtils';
import { images } from './images';

const person = Interlocutors.SYSTEM;

export const LOGIC_TEST_NEW: Record<string, Step> = {
  LOGIC_TEST_NEW_INTRO: {
    person,
    id: 'LOGIC_TEST_NEW_INTRO',
    type: StepType.question,
    text: `Смотри, условия достаточно простые. Тебе нужно решить 10 логических задачек за 20 минут. Какие-то из них будут проще, какие-то посложнее и, возможно, даже понадобится ручка и лист бумаги, чтобы что-то вычислять.

    Важный момент: принять участие в этой викторине можно только один раз. Повторно к нему вернуться будет нельзя. А это очень важно, понимаешь? Поучаствовать в викторине нужно обязательно!`,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Да, понимаю',
        nextStep: 'LOGIC_TEST_NEW_INTRO_2',
      },
    ],
  },
  LOGIC_TEST_NEW_INTRO_2: {
    person,
    id: 'LOGIC_TEST_NEW_INTRO_2',
    type: StepType.question,
    text: `Итак, сначала я буду говорить задание или показывать картинку, тебе нужно найти ответ на вопрос, вписать в поле ввода и нажать «Отправить ответ». Будь [[внимателен/внимательна]]! Но не переживай, ты поймешь из вопроса что и как нужно ответить.

    Не торопись, на все 10 вопросов у тебя будет 20 минут, но и не затягивай сильно с поиском решения!`,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Хорошо, все понятно!',
        nextStep: 'LOGIC_TEST_NEW_3',
      },
    ],
  },
  LOGIC_TEST_NEW_3: {
    person,
    id: 'LOGIC_TEST_NEW_3',
    type: StepType.message,
    nextStep: 'LOGIC_TEST_NEW_4',
    text: 'Ну тогда поехали! Организаторы уже запускают таймер! И удачи нам!',
  },
  LOGIC_TEST_NEW_4: {
    person,
    nextStep: 'LOGIC_TEST_NEW_Q1',
    id: 'LOGIC_TEST_NEW_4',
    type: StepType.system,
    _meta: {
      type: SystemStepType.limitedTimeGroup,
      time: 20 * 60,
      timeoutStep: 'Q2',
      exitStep: 'TEST_EXIT',
    },
  },
  LOGIC_TEST_NEW_Q1: {
    person,
    id: 'LOGIC_TEST_NEW_Q1',
    text: 'Найди зашифрованное слово',
    image: { uri: images.logic_new_1, title: '' },
    type: StepType.text_question,
    variable: 'LOGIC_TEST_NEW_Q1',
    nextStep: 'LOGIC_TEST_NEW_Q2',
  },
  LOGIC_TEST_NEW_Q2: {
    person,
    id: 'LOGIC_TEST_NEW_Q2',
    text: `Напиши истинное математическое равенство, где вместо букв стоят цифры 
      НАУКА+УЧЕБА=РАБОТА`,
    type: StepType.text_question,
    variable: 'LOGIC_TEST_NEW_Q2',
    nextStep: 'LOGIC_TEST_NEW_Q3',
  },
  LOGIC_TEST_NEW_Q3: {
    person,
    id: 'LOGIC_TEST_NEW_Q3',
    text: `Расставь коробки в порядке увеличения массы. Оранжевая коробка тяжелее синяя, оранжевая и зеленая вместе по массе равны двум синим»
      Какая коробка самая легкая? 
      выбор из вариантов ответов: оранжевая, синяя, зеленая, красная 
      Какая коробка средняя по весу?
      выбор из вариантов ответов: оранжевая, синяя, зеленая, красная
      Какая коробка самая тяжелая?
      выбор из вариантов ответов: оранжевая, синяя, зеленая, красная`,
    type: StepType.text_question,
    variable: 'LOGIC_TEST_NEW_Q3',
    nextStep: 'LOGIC_TEST_NEW_Q4',
  },
  LOGIC_TEST_NEW_Q4: {
    person,
    id: 'LOGIC_TEST_NEW_Q4',
    text: 'Выбери самую легкую коробку',
    image: { uri: images.logic_new_2, title: '' },
    type: StepType.text_question,
    variable: 'LOGIC_TEST_NEW_Q4',
    nextStep: 'LOGIC_TEST_NEW_Q5',
  },
  LOGIC_TEST_NEW_Q5: {
    person,
    id: 'LOGIC_TEST_NEW_Q5',
    text: 'Впиши числа 4,2,6,1 в фигуры так, чтобы стрелки шли от большего числа к меньшему',
    image: { uri: images.logic_new_3, title: '' },
    type: StepType.message,
    nextStep: 'LOGIC_TEST_NEW_Q5_1',
  },
  LOGIC_TEST_NEW_Q5_1: {
    person,
    id: 'LOGIC_TEST_NEW_Q5_1',
    text: 'В треугольнике должна быть цифра…',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_NEW_Q5_1',
    nextStep: 'LOGIC_TEST_NEW_Q5_2',
  },
  LOGIC_TEST_NEW_Q5_2: {
    person,
    id: 'LOGIC_TEST_NEW_Q5_2',
    text: 'В круге должна быть цифра…',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_NEW_Q5_2',
    nextStep: 'LOGIC_TEST_NEW_Q5_3',
  },
  LOGIC_TEST_NEW_Q5_3: {
    person,
    id: 'LOGIC_TEST_NEW_Q5_3',
    text: 'В квадрате должна быть цифра…',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_NEW_Q5_3',
    nextStep: 'LOGIC_TEST_NEW_Q5_4',
  },
  LOGIC_TEST_NEW_Q5_4: {
    person,
    id: 'LOGIC_TEST_NEW_Q5_4',
    text: 'В звездочке должна быть цифра…',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_NEW_Q5_4',
    nextStep: 'LOGIC_TEST_NEW_Q6',
  },

  LOGIC_TEST_NEW_Q6: {
    person,
    id: 'LOGIC_TEST_NEW_Q6',
    text: 'Даня старше всех. Марк моложе четверых. Тимур старше одного и младше троих. Сеня моложе Гоши. Напиши всех ребят слева направо по старшенству через запятую. Справа самый старший.',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_NEW_Q6',
    nextStep: 'LOGIC_TEST_NEW_Q7',
  },

  LOGIC_TEST_NEW_Q7: {
    person,
    id: 'LOGIC_TEST_NEW_Q7',
    text: 'Дети бежали из класса в буфет. Андрей бежал быстрее, чем Ваня и Боря. Ваня прибежал раньше Гоши, но позже Бори. Кто прибежал в буфет первым, а кто последним?',
    type: StepType.message,
    nextStep: 'LOGIC_TEST_NEW_Q7_1',
  },
  LOGIC_TEST_NEW_Q7_1: {
    person,
    id: 'LOGIC_TEST_NEW_Q7_1',
    text: 'Кто бежал первым?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_NEW_Q7_1',
    nextStep: 'LOGIC_TEST_NEW_Q7_2',
  },
  LOGIC_TEST_NEW_Q7_2: {
    person,
    id: 'LOGIC_TEST_NEW_Q7_2',
    text: 'Кто бежал последним?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_NEW_Q7_2',
    nextStep: 'LOGIC_TEST_NEW_Q8',
  },

  LOGIC_TEST_NEW_Q8: {
    person,
    id: 'LOGIC_TEST_NEW_Q8',
    text: `Сергей Петрович старше, чем Антон Андреевич, но моложе, чем Василий Витальевич. А Пётр Петрович - старший брат Сергея Петровича. Напиши, кто самый молодой.
      Ответ выбор одного ответа из вариантов: Сергей Петрович, Антон Андреевич, Василий Витальевич, Пётр Петрович`,
    type: StepType.text_question,
    variable: 'LOGIC_TEST_NEW_Q8',
    nextStep: 'LOGIC_TEST_NEW_Q9',
  },

  LOGIC_TEST_NEW_Q9: {
    person,
    id: 'LOGIC_TEST_NEW_Q9',
    text: 'У Сони, Маши, Антона, Кости и Юры есть домашние животные. У каждого живет или собака, или кошка, или попугай. Девочки не держат собак, а мальчики попугаев. У Сони и Маши разные питомцы, а у Маши с Антоном одинаковые. У Сони нет кошки. У Кости и Юры одинаковые животные, а у Антона с Костей разные. Какие животные живут у каждого?',
    type: StepType.message,
    nextStep: 'LOGIC_TEST_NEW_Q9_1',
  },
  LOGIC_TEST_NEW_Q9_1: {
    person,
    id: 'LOGIC_TEST_NEW_Q9_1',
    text: 'Кто живет у Сони?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_NEW_Q9_1',
    nextStep: 'LOGIC_TEST_NEW_Q9_2',
  },
  LOGIC_TEST_NEW_Q9_2: {
    person,
    id: 'LOGIC_TEST_NEW_Q9_2',
    text: 'Кто живет у Маши?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_NEW_Q9_2',
    nextStep: 'LOGIC_TEST_NEW_Q9_3',
  },
  LOGIC_TEST_NEW_Q9_3: {
    person,
    id: 'LOGIC_TEST_NEW_Q9_3',
    text: 'Кто живет у Антона?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_NEW_Q9_3',
    nextStep: 'LOGIC_TEST_NEW_Q9_4',
  },
  LOGIC_TEST_NEW_Q9_4: {
    person,
    id: 'LOGIC_TEST_NEW_Q9_4',
    text: 'Кто живет у Кости?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_NEW_Q9_4',
    nextStep: 'LOGIC_TEST_NEW_Q9_5',
  },
  LOGIC_TEST_NEW_Q9_5: {
    person,
    id: 'LOGIC_TEST_NEW_Q9_5',
    text: 'Кто живет у Юры?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_NEW_Q9_5',
    nextStep: 'LOGIC_TEST_NEW_Q10',
  },
  LOGIC_TEST_NEW_Q10: {
    person,
    id: 'LOGIC_TEST_NEW_Q10',
    text: `Среди школьников пятого класса проводилось анкетирование по любимым мультфильмам. Самыми популярными оказались три мультфильма: "Фиксики", "Маша и медведь", "Простоквашино". Всего в классе 28 человек. " Фиксики " выбрали 16 учеников, среди которых трое назвали еще "Простоквашино ", шестеро - "Маша и медведь", а один написал все три мультфильма. Мультфильм " Простоквашино " назвали 9 ребят, среди которых пятеро выбрали по два мультфильма. Сколько человек выбрали мультфильм "Маша и медведь"?`,
    type: StepType.text_question,
    variable: 'LOGIC_TEST_NEW_Q10',
    nextStep: 'Q2',
  },
};
