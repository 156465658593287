/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId, StepType } from '../../../scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';
import profileScreen from '../../../../../assets/images/profile_screen.jpg';

export const scenario_3_23_0: Scenario = {
  scenarioId: SceneId.scenario_3_23_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Новости парка',
    text: 'Вам следует загрузить или проверить свою видеовизитку. Для этого нажмите на кнопку «Загрузить видеовизитку» вверху экрана.',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_23: Scenario = {
  scenarioId: SceneId.scenario_3_23,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Парк «Большая перемена»',
    text: 'Вы и Вжух проводите экскурсию по парку «Большая перемена». София восхищена его масштабами, технологичностью и красотой и хочет очень внимательно рассмотреть все уголки «Большой перемены».',
    buttons: ['Продолжить'],
  },
};
export const scenario_3_23_01: Scenario = {
  scenarioId: SceneId.scenario_3_23_01,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Парк «Большая перемена»',
    text: 'Экскурсия займет много времени. Возвращайтесь в игру 22 мая, когда София удовлетворит свое любопытство и исследует каждую замечательную достопримечательность парка.',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_23_02: Scenario = {
  scenarioId: SceneId.scenario_3_23_02,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Парк «Большая перемена»',
    text: 'Вы возвращаетесь в парк «Большая перемена», вас встречают Вжух, Малевич и София. Экскурсия для Софии окончена, гостья осталась в полном восторге от красоты «Большой перемены».',
    buttons: ['Продолжить'],
  },
};

const EXIT_ID = 'EXIT_ID';

export const scenario_3_23_1: Scenario = {
  scenarioId: SceneId.scenario_3_23_1,
  type: ScenarioType.chat,
  data: {
    title: 'Парк «Большая перемена»',
    scene_id: 'scenario_3_23_1',
    startId: 'Q1/0',
    steps: {
      ...splitStep({
        person: Interlocutors.CAT,
        id: 'Q1',
        text: `{{NAME}},  благодаря твоей помощи, смекалке и отваге, парк «Большая перемена» полностью восстановлен и готов к приему посетителей 😻
        
        Баллы за дистанционный этап Конкурса будут размещены в твоем <a href="https://game.bolshayaperemena.online/" target="_blank">профиле</a> в игре. После авторизации в личном кабинете не забудь зайти в игру, чтобы увидеть баллы:`,
        type: StepType.question,
        maxAnswers: 1,
        image: { uri: profileScreen, title: '' },
        buttons: [
          {
            id: 'b1',
            text: 'Здорово!',
            nextStep: EXIT_ID,
          },
        ],
      }),

      [EXIT_ID]: {
        person: Interlocutors.TEACHER,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
