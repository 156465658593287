/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg2_kurganAlienFound_1_1: Scenario = {
  scenarioId: SceneId.stg2_kurganAlienFound_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Мамаев Курган',
    scene_id: 'stg2_kurganAlienFound_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Давайте разделимся, так мы найдём его быстрее!`,
        id: 'Q1',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Женя, Женя, подожди 😅 Хочу спросить тебя!',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        text: `Ой, да, что-то я поторопилась. Конечно, спрашивай!`,
        id: 'Q2',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        nextStep: 'Q3',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Для начала, ты перестала говорить на кошачьем?',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        text: `Да! Оказалось, что я не могу по-человечески общаться только в Эрмитаже. Когда я отправилась навестить Петрова, язык сразу вернулся.

        Петров, к слову, мне и рассказал, что вы ищете того милого инопланетянина. Решила вам помочь, а то ведь у меня есть идея, как его найти.`,
        id: 'Q3',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        nextStep: 'Q4',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Это отлично, рад понимать тебя без перевода! Но где же мы найдём пришельца? ',
            nextStep: 'Q4',
          },
        ],
      },

      Q4: {
        text: `А помните фотографию, которая в Эрмитаже ему понравилась?

        Посмотрите теперь на этот холм! Вот же она, статуя Родина-Мать, что на фото! 
        
        Я подумала — пришелец увидел статую издалека и точно захотел посмотреть на неё вживую.`,
        id: 'Q4',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        nextStep: 'Q5',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хм, звучит логично. А что это, ещё раз, за памятник?',
            nextStep: 'Q5',
          },
        ],
      },

      Q5: {
        text: `Мамаев курган — одна из самых памятных достопримечательностей нашей страны 😻


        Настоящий Мамаев курган находится в самом центре Волгограда, но у нас тут точная копия и, конечно же, тоже есть памятник-ансамбль «Героям Сталинградской битвы».`,
        id: 'Q5',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        nextStep: 'Q6',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А что такое памятник-ансамбль?',
            nextStep: 'Q6',
          },
          {
            id: 'b2',
            text: 'Почему памятник в Волгограде, а битва Сталинградская?',
            nextStep: 'Q7',
          },
        ],
      },

      Q6: {
        text: `Памятник-ансамбль — это когда много памятников складываются в единую композицию. В данном случае, военной тематики.`,
        id: 'Q6',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Почему памятник в Волгограде, а битва Сталинградская?',
            nextStep: 'Q7',
          },
          {
            id: 'b2',
            text: 'Понятно',
            nextStep: 'Q8',
          },
        ],
      },
      Q7: {
        text: `Волгоград раньше назывался Сталинградом, с 1925 по 1961 год. За выдающиеся заслуги перед Родиной 1 мая 1945 года Сталинград был удостоен почетного звания города-героя.`,
        id: 'Q7',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А что такое памятник-ансамбль?',
            nextStep: 'Q6',
          },
          {
            id: 'b1',
            text: 'Как интересно! ',
            nextStep: 'Q8',
          },
        ],
      },
      Q8: {
        text: `Расположение мемориала неслучайно — за высоту 102,0 (так на военных картах обозначался курган) велись жесточайшие бои. Это была стратегическая точка, ведь с вершины хорошо виден весь город. 
        
        Главным монументом Мамаева кургана является грандиозная скульптура «Родина-мать зовет!». Она признана одной из самых высоких статуй в мире, ведь ее общая высота — аж 85 метров, только представь!
        
        Не менее значимое место кургана — Зал Воинской славы. Внутри горит Вечный огонь в виде скульптурной руки с факелом, а на стене есть надпись: «Да, были простыми смертными и мало кто уцелел из нас, но мы все выполнили свой патриотический долг перед священной Родиной Матерью». На стенах высечены имена погибших солдат. 
        
        Зал Воинской славы открыт для посетителей в любое время суток, так что обязательно посети это великое место, если будешь в Волгограде.`,
        id: 'Q8',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо за целую экскурсию!',
            nextStep: 'Q9',
          },
        ],
      },

      Q9: {
        text: `Всегда рада поделиться!

        Ну что, разделимся и поищем тут нашего пришельца?`,
        id: 'Q9',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Мне с тобой так интересно пообщаться! Давай вместе пойдём искать',
            nextStep: 'Q10',
          },
          {
            id: 'b2',
            text: 'Давай вместе пойдём, чтобы не потеряться!',
            nextStep: 'Q10',
          },
        ],
      },

      Q10: {
        text: `Смотрите, кого я вижу!`,
        id: 'Q10',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Наш пришелец! Привет!',
            nextStep: 'Q11',
          },
        ],
      },

      Q11: {
        text: `Шшшшшш…

        Щщщ…`,
        id: 'Q11',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Наверное, он тоже рад нас видеть 😁',
            nextStep: 'Q12',
          },
        ],
      },

      Q12: {
        text: `О, вы нашли его! Я так и думал, что пришелец здесь.`,
        id: 'Q12',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А нам Женя подсказала, что он может быть здесь. ',
            nextStep: 'Q13',
          },
        ],
      },

      Q13: {
        text: `Кстати, вот, принес вам новую газировку. По всему парку уже продаётся. Называется «Байкал».`,
        id: 'Q13',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        callToAction: 'Получен предмет “Бутылка “Байкала””',
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо, попить — это очень кстати! Но как ты нас нашёл?',
            nextStep: 'Q14',
          },
        ],
      },

      Q14: {
        text: `Элементарно! По моим наблюдениям, только с Мамаевым Курганом ничего не случилось после вторжения пришельца. 

        Все остальные достопримечательности парка всячески от него пострадали. Вот он и пришёл туда, где ещё не навредил!`,
        id: 'Q14',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Зачем же так обвинять? Я думаю, что он специально ничему не вредил!',
            nextStep: 'Q15',
          },
        ],
      },

      Q15: {
        text: `Ребята, не ссорьтесь! Давайте жить дружно!

        Петров, ты говорил, что у тебя есть план, как всё исправить?`,
        id: 'Q15',
        person: Interlocutors.GUIDE,
        type: StepType.message,
        nextStep: 'Q16',
      },

      Q16: {
        text: `Да, мой план — вернуть пришельца сородичам. С ними нужно связаться! 

        В парке есть копия Останкинской Телебашни. С неё мы можем отправить сообщение в космос и вызвать родню пришельца сюда, чтобы они его подобрали.`,
        id: 'Q16',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Звучит так увлекательно! ',
            nextStep: 'Q17',
          },
        ],
      },

      Q17: {
        text: `Но к сожалению — это всё теоретически. Радиопередатчики с башни демонтировали еще в прошлом году и отправили в Москву. Нам ведь в парке столь мощная аппаратура ни к чему.`,
        id: 'Q17',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Что же нам тогда делать?',
            nextStep: 'Q18',
          },
        ],
      },

      Q18: {
        text: `Может Сан Саныч позволит заказать её? `,
        id: 'Q18',
        person: Interlocutors.CAT,
        type: StepType.message,
        nextStep: 'Q19',
      },

      Q19: {
        text: `Техника эта очень дорого стоит! Вряд ли сможем позволить себе.`,
        id: 'Q19',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А мы всё равно спросим Сан Саныча. Вжух, пришелец, идём!',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
