/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg2_lakeOrderFromGranny_1_1: Scenario = {
  scenarioId: SceneId.stg2_lakeOrderFromGranny_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Байкал',
    scene_id: 'stg2_lakeOrderFromGranny_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Бабуля, мы с хорошими новостями! Воду из озера можно пить!`,
        id: 'Q1',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, мы сделали анализ воды — это обычная сладкая газировка',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        text: `Это замечательно! Угощайтесь тогда, на здоровье!

        Эх, но рыбам она всё равно вредна…`,
        id: 'Q2',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        nextStep: 'Q3',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А вот по этому поводу у нас деловое предложение',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        text: `Да, мы от Сан Саныча. Он хочет прислать сюда аквариумы, чтобы переселить рыб.`,
        id: 'Q3',
        person: Interlocutors.CAT,
        type: StepType.message,
        nextStep: 'Q4',
      },

      Q4: {
        text: `Ой, как здорово! Но что же дать ему взамен? 

        И вы тогда мне поможете сообразить, какие аквариумы нам понадобятся, хорошо?`,
        id: 'Q4',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        nextStep: 'Q5',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Конечно поможем! А взамен Сан Саныч хочет продавать воду из озера, раз она теперь стала лимонадом.',
            nextStep: 'Q5',
          },
        ],
      },

      Q5: {
        text: `Конечно, пусть берёт сколько нужно. 

        И я даже вам название для напитка придумала: «Байкал»!`,
        id: 'Q5',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        nextStep: 'Q6',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Здорово, мне нравится!',
            nextStep: 'Q6',
          },
        ],
      },

      Q6: {
        text: `Ну вот и договорились! Только рыб так много и все они такие разные. Нам нужно около ста тысяч аквариумов!`,
        id: 'Q6',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо, передадим информацию Сан Санычу!',
            nextStep: 'Q7',
          },
        ],
      },
      Q7: {
        text: `Какие вы молодцы!

        Спасибо вам, ребята.`,
        id: 'Q7',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Пожалуйста! Сан Саныч аквариумы уже совсем скоро пришлёт. ',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
