/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../../types/scenario';
import interlocutors from '../../../interlocutors';
import { splitStep } from '../../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT';
const TO_MAP_STEP = 'TO_MAP_STEP';

const person = Interlocutors.ENTREPRENEUR;

const TEST_TVOI_VIBOR_2: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'TEST_TVOI_VIBOR_2',
  name: 'Твой выбор 4/5',
  nextStep: 'Q5',
  order: [
    {
      description: '',
      subtest: 'T2',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person,
  type: StepType.test,
};

export const oboutMoscow_1_1: Scenario = {
  scenarioId: SceneId.oboutMoscow_1_1,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Москва-Сити',
    scene_id: 'oboutMoscow_1_1',
    startId: 'Q0/0',
    steps: {
      ...splitStep({
        type: StepType.message,
        id: 'Q0',
        person,
        text: `А, это же наш новый товарищ! Меня зовут Сан Саныч. Я человек дела 👨‍💼

        Я всегда держу слово и считаю, что честным трудом можно добиться чего угодно! Поэтому я выбрал для себя профессию предпринимателя.
        
        Мой главный жизненный принцип: хочешь что-то получить — заработай! Я тоже когда-то был школьником как ты, а теперь создаю полезные для людей проекты и неплохо на этом зарабатываю 😊
        `,
        nextStep: 'Q1',
      }),
      Q1: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q1',
        person,
        text: 'Поэтому меня привели в парк свои интересы. Так вот, скажу тебе сразу начистоту. Та искрящаяся штука в тумане МОЯ. Я точно знаю, где ее приспособить 😏',
        buttons: [
          {
            id: 'b1',
            text: 'Приятно познакомиться! Наконец-то мы понимаем друг друга',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        type: StepType.question,
        id: 'Q2',
        nextStep: 'Q2_2',
        person,
        text: `Итсоньлетаводелсоп йонтарбо в ызарф ьтавыргиорп удуб узарс я — нофоткид адюс енм ак-йад а

        Ещорп тедуб миобо ман кат`,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Записал! Снова проигрываем обратно',
            nextStep: 'Q2_2',
          },
        ],
      },

      Q2_2: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q2_2',
        person,
        text: `А дай-ка мне сюда диктофон — я сразу буду проигрывать фразы в обратной последовательности. 

        Так нам обоим будет проще`,
        buttons: [
          {
            id: 'b1',
            text: 'Конечно, держите!',
            nextStep: 'Q3_0',
          },
        ],
      },

      Q3_0: {
        type: StepType.question,
        id: 'Q3_0',
        nextStep: 'TEST_TVOI_VIBOR_2',
        person,
        text: `Я тебе про себя рассказал, теперь хотел бы узнать и о тебе побольше. 

        Я человек практики, поэтому расскажи мне о работе. Где хотел бы работать? Может и к нам в будущем захочешь устроиться?`,
        buttons: [
          {
            id: 'b1',
            text: 'Давайте расскажу ',
            nextStep: 'TEST_TVOI_VIBOR_2',
          },
        ],
        maxAnswers: 1,
      },

      TEST_TVOI_VIBOR_2,

      Q5: {
        type: StepType.question,
        id: 'Q5',
        nextStep: 'Q6',
        person,
        text: `Сразу видно — подрастает профессионал!`,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо! А расскажите, где мы сейчас?',
            nextStep: 'Q6',
          },
          {
            id: 'b2',
            text: 'Спасибо! Мы очень торопимся, и нужна ваша помощь',
            nextStep: 'Q7',
          },
        ],
        maxAnswers: 1,
      },

      Q6: {
        type: StepType.question,
        id: 'Q6',
        nextStep: 'Q7',
        person,
        text: `Перед тобой полноразмерная копия Москва-Сити. Это развивающийся деловой район Москвы. Он состоит из башен, всего их 20 и шесть еще строятся. Высота самой высокой из них составляет 374 метра, это целых 97 этажей! С ума сойти, как высоко! 😍

        Мы как раз стоим у входа в нее, я перевожу сюда свой офис, чтобы быть в самой гуще предпринимательских событий.
        
        Но это еще не все! Москва-Сити — это пространство, в котором можно заниматься бизнесом, жить и интересно проводить время. Например, здесь есть музей. Он расположен на 56 этаже и оттуда открывается совершенно потрясающий вид! А еще это такой музей, где экспонаты можно и даже нужно трогать руками! 😊`,
        buttons: [
          {
            id: 'b1',
            text: 'Как интересно! Я бы [[хотел/хотела]] ещё поговорить про Москва-Сити, но нам очень нужна ваша помощь.',
            nextStep: 'Q7',
          },
        ],
        maxAnswers: 1,
      },

      Q7: {
        type: StepType.question,
        id: 'Q7',
        nextStep: 'Q8',
        person,
        text: `Ой, что-то я увлекся…

        Давай перейдём к делу. Какая помощь требуется?`,
        buttons: [
          {
            id: 'b1',
            text: 'Петров послал к вам за деталями. Но мы забыли, как именно они называются ',
            nextStep: 'Q8',
          },
        ],
        maxAnswers: 1,
      },

      Q8: {
        type: StepType.question,
        id: 'Q8',
        nextStep: EXIT_ID,
        person,
        text: `Детали от солнечных батарей, точно! На той неделе пришли из Китая.

        Вот же они. Забирайте, конечно!`,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо! Удачи вам!',
            nextStep: EXIT_ID,
          },
        ],
        maxAnswers: 1,
      },

      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_STEP]: {
        person,
        id: TO_MAP_STEP,
        type: StepType.to_map_step,
      },
    },
  },
};

export const scenario_1_9_1: Scenario = {
  scenarioId: SceneId.scenario_1_9_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.ENTREPRENEUR].name,
    person: Interlocutors.ENTREPRENEUR,
    text: 'Мы все еще делаем репортаж, твоя помощь пока не нужна.',
    buttons: ['Закрыть'],
  },
};

export const scenario_1_9_2: Scenario = {
  scenarioId: SceneId.scenario_1_9_2,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: '{{NAME}}, тут сейчас никого нет, все разбежались по делам. Приходи попозже.',
    buttons: ['Закрыть'],
  },
};
