/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_ostankinoAlienInvasion_1_1_0: Scenario = {
  scenarioId: SceneId.stg2_ostankinoAlienInvasion_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.CAT,
    title: 'Останкино',
    text: `Надеюсь, лифты в башне всё-таки работают. Не хочу на такую высоту ползти по лестнице!`,
    buttons: ['Начать'],
  },
};
