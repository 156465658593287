/* eslint-disable max-len */
import { Interlocutors, Step, StepType, SystemStepType } from '../../scenario';
import { images } from './images';

const person = Interlocutors.SYSTEM;

export const TEST_LOGIC_MAIN_2023: Record<string, Step> = {
  TEST_LOGIC_MAIN_START: {
    person,
    id: 'TEST_LOGIC_MAIN_START',
    type: StepType.system,
    _meta: {
      type: SystemStepType.limitedTimeGroup,
      time: 20 * 60,
      timeoutStep: 'LOGIC_TEST_PART2_TIMEOUT',
      exitStep: 'Q12',
    },
    nextStep: 'TEST_LOGIC_MAIN_Q1',
  },

  LOGIC_TEST_PART2_TIMEOUT: {
    person,
    id: 'LOGIC_TEST_PART2_TIMEOUT',
    type: StepType.message,
    text: `{{NAME}}, увы, время закончилось 😔`,
    nextStep: 'Q12',
  },

  // ЗАДАНИЕ 1.

  TEST_LOGIC_MAIN_Q1: {
    person,
    id: 'TEST_LOGIC_MAIN_Q1',
    text: 'Найди зашифрованное слово',
    image: { uri: images.logic_test_main_1, title: '' },
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_Q1_2023',
    nextStep: 'TEST_LOGIC_MAIN_Q2',
  },

  // ЗАДАНИЕ 2.

  TEST_LOGIC_MAIN_Q2: {
    person,
    id: 'TEST_LOGIC_MAIN_Q2',
    text: `Замени буквы на цифры и найди число, которое должно стоять вместо знака вопроса, если Щ=9 и все буквы – целые положительные числа.`,
    image: { uri: images.logic_test_main_2, title: '' },
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_Q2_2023',
    nextStep: 'TEST_LOGIC_MAIN_Q3',
  },

  // ЗАДАНИЕ 3.

  TEST_LOGIC_MAIN_Q3: {
    person,
    id: 'TEST_LOGIC_MAIN_Q3',
    text: `Мысленно расставь банки в порядке увеличения массы. Желтая и синяя вместе по весу равны двум зеленым. Синяя банка тяжелее зеленой. `,
    type: StepType.message,
    nextStep: 'TEST_LOGIC_MAIN_Q3_0',
  },

  TEST_LOGIC_MAIN_Q3_0: {
    person,
    id: 'TEST_LOGIC_MAIN_Q3_0',
    text: `Какая банка самая легкая?`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'желтая',
        nextStep: 'TEST_LOGIC_MAIN_Q3_1',
      },
      {
        id: 'b2',
        text: 'синяя',
        nextStep: 'TEST_LOGIC_MAIN_Q3_1',
      },
      {
        id: 'b3',
        text: 'зеленая',
        nextStep: 'TEST_LOGIC_MAIN_Q3_1',
      },
      {
        id: 'b4',
        text: 'красная',
        nextStep: 'TEST_LOGIC_MAIN_Q3_1',
      },
    ],
    nextStep: 'TEST_LOGIC_MAIN_Q3_1',
  },

  TEST_LOGIC_MAIN_Q3_1: {
    person,
    id: 'TEST_LOGIC_MAIN_Q3_1',
    text: `Какая банка средняя по весу?`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'желтая',
        nextStep: 'TEST_LOGIC_MAIN_Q3_2',
      },
      {
        id: 'b2',
        text: 'синяя',
        nextStep: 'TEST_LOGIC_MAIN_Q3_2',
      },
      {
        id: 'b3',
        text: 'зеленая',
        nextStep: 'TEST_LOGIC_MAIN_Q3_2',
      },
      {
        id: 'b4',
        text: 'красная',
        nextStep: 'TEST_LOGIC_MAIN_Q3_2',
      },
    ],
    nextStep: 'TEST_LOGIC_MAIN_Q3_2',
  },

  TEST_LOGIC_MAIN_Q3_2: {
    person,
    id: 'TEST_LOGIC_MAIN_Q3_1',
    text: `Какая банка самая тяжелая?`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'желтая',
        nextStep: 'TEST_LOGIC_MAIN_Q4',
      },
      {
        id: 'b2',
        text: 'синяя',
        nextStep: 'TEST_LOGIC_MAIN_Q4',
      },
      {
        id: 'b3',
        text: 'зеленая',
        nextStep: 'TEST_LOGIC_MAIN_Q4',
      },
      {
        id: 'b4',
        text: 'красная',
        nextStep: 'TEST_LOGIC_MAIN_Q4',
      },
    ],
    nextStep: 'TEST_LOGIC_MAIN_Q4',
  },

  // ЗАДАНИЕ 4.

  TEST_LOGIC_MAIN_Q4: {
    person,
    id: 'TEST_LOGIC_MAIN_Q4',
    text: `Выбери самую тяжёлую коробку на картинке`,
    image: { uri: images.logic_test_main_4, title: '' },
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'оранжевая',
        nextStep: 'TEST_LOGIC_MAIN_Q5',
      },
      {
        id: 'b2',
        text: 'синяя',
        nextStep: 'TEST_LOGIC_MAIN_Q5',
      },
      {
        id: 'b3',
        text: 'зеленая',
        nextStep: 'TEST_LOGIC_MAIN_Q5',
      },
      {
        id: 'b4',
        text: 'красная',
        nextStep: 'TEST_LOGIC_MAIN_Q5',
      },
    ],
    nextStep: 'TEST_LOGIC_MAIN_Q5',
  },

  // ЗАДАНИЕ 5.

  TEST_LOGIC_MAIN_Q5: {
    person,
    id: 'TEST_LOGIC_MAIN_Q5',
    text: 'Впиши числа 14, 9, 4, 19 в фигуры так, чтобы стрелки шли от большего числа к меньшему',
    image: { uri: images.logic_test_main_3, title: '' },
    type: StepType.message,
    nextStep: 'TEST_LOGIC_MAIN_Q5_1',
  },

  TEST_LOGIC_MAIN_Q5_1: {
    person,
    id: 'TEST_LOGIC_MAIN_Q5_1',
    text: 'В треугольнике должна быть цифра…',
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_Q5_1',
    nextStep: 'TEST_LOGIC_MAIN_Q5_2',
  },

  TEST_LOGIC_MAIN_Q5_2: {
    person,
    id: 'TEST_LOGIC_MAIN_Q5_2',
    text: 'В круге должна быть цифра…',
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_Q5_2',
    nextStep: 'TEST_LOGIC_MAIN_Q5_3',
  },

  TEST_LOGIC_MAIN_Q5_3: {
    person,
    id: 'TEST_LOGIC_MAIN_Q5_3',
    text: 'В квадрате должна быть цифра…',
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_Q5_3',
    nextStep: 'TEST_LOGIC_MAIN_Q5_4',
  },

  TEST_LOGIC_MAIN_Q5_4: {
    person,
    id: 'TEST_LOGIC_MAIN_Q5_4',
    text: 'В звездочке должна быть цифра…',
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_Q5_4',
    nextStep: 'TEST_LOGIC_MAIN_Q6',
  },

  // ЗАДАНИЕ 6.

  TEST_LOGIC_MAIN_Q6: {
    person,
    id: 'TEST_LOGIC_MAIN_Q6',
    text: 'Иван выше всех. Петр ниже четверых. Илья выше одного и ниже троих. Андрей ниже Саши. Напиши всех ребят, начиная с самого низкого. Писать имена нужно через запятую.',
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_Q6',
    nextStep: 'TEST_LOGIC_MAIN_Q7',
  },

  // ЗАДАНИЕ 7.

  TEST_LOGIC_MAIN_Q7: {
    person,
    id: 'TEST_LOGIC_MAIN_Q7',
    text: 'Дети выполняли задания «Другого дела». Баллы, начисленные по заданию, зависят от того, кто раньше сдаст задание.  Алена сдала задание позже, чем Кирилл и Таня. Таня сдала задание раньше Миланы, но позже Кирилла. Милана сдала задание раньше Алены.',
    type: StepType.message,
    nextStep: 'TEST_LOGIC_MAIN_Q7_1',
  },

  TEST_LOGIC_MAIN_Q7_1: {
    person,
    id: 'TEST_LOGIC_MAIN_Q7_1',
    text: `Кто получит максимальный балл?`,
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_Q7_1',
    nextStep: 'TEST_LOGIC_MAIN_Q7_2',
  },

  TEST_LOGIC_MAIN_Q7_2: {
    person,
    id: 'TEST_LOGIC_MAIN_Q7_2',
    text: `Кто получит самый низкий балл?`,
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_Q7_2',
    nextStep: 'TEST_LOGIC_MAIN_Q8',
  },

  // ЗАДАНИЕ 8.

  TEST_LOGIC_MAIN_Q8: {
    person,
    id: 'TEST_LOGIC_MAIN_Q8',
    text: `Ирина старше, чем Ксения, но моложе, чем Ольга. А Николай - младший брат Ирины. Напиши, кто самый старший.`,
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_Q8',
    nextStep: 'TEST_LOGIC_MAIN_Q9',
  },

  // ЗАДАНИЕ 9.

  TEST_LOGIC_MAIN_Q9: {
    person,
    id: 'TEST_LOGIC_MAIN_Q9',
    text: `У Александра, Андрея, Никиты, Светы, Оли есть домашние животные. У каждого живет или собака, или кошка, или попугай. Девочки не держат кошек, а мальчики собак. У Светы и Оли разные питомцы, а у Светы с Андреем одинаковые. У Оли нет попугая. У Александра и Никиты разные животные, а у Андрея с Александром одинаковые. Какие животные живут у каждого?`,
    type: StepType.message,
    nextStep: 'TEST_LOGIC_MAIN_Q9_1',
  },

  TEST_LOGIC_MAIN_Q9_1: {
    person,
    id: 'TEST_LOGIC_MAIN_Q9_1',
    text: `Какое животное живет у Александра?`,
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_Q9_1',
    nextStep: 'TEST_LOGIC_MAIN_Q9_2',
  },

  TEST_LOGIC_MAIN_Q9_2: {
    person,
    id: 'TEST_LOGIC_MAIN_Q9_2',
    text: `Какое животное живет у Андрея?`,
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_Q9_2',
    nextStep: 'TEST_LOGIC_MAIN_Q9_3',
  },

  TEST_LOGIC_MAIN_Q9_3: {
    person,
    id: 'TEST_LOGIC_MAIN_Q9_3',
    text: `Какое животное живет у Никиты?`,
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_Q9_3',
    nextStep: 'TEST_LOGIC_MAIN_Q9_4',
  },

  TEST_LOGIC_MAIN_Q9_4: {
    person,
    id: 'TEST_LOGIC_MAIN_Q9_4',
    text: `Какое животное живет у Светы?`,
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_Q9_4',
    nextStep: 'TEST_LOGIC_MAIN_Q9_5',
  },

  TEST_LOGIC_MAIN_Q9_5: {
    person,
    id: 'TEST_LOGIC_MAIN_Q9_5',
    text: `Какое животное живет у Оли?`,
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_Q9_5',
    nextStep: 'TEST_LOGIC_MAIN_Q10',
  },

  // ЗАДАНИЕ 10.

  TEST_LOGIC_MAIN_Q10: {
    person,
    id: 'TEST_LOGIC_MAIN_Q10',
    text: `Среди школьников пятого класса проводилось анкетирование по любимым мультфильмам «Союзмультфильма». Самыми популярными оказались три мультфильма: "Приключения Пети и Волка", "Простоквашино", "Чебурашка". Всего в классе 34 человека. " Приключения Пети и Волка " выбрали 24 ученика, среди которых трое назвали еще "Простоквашино ", пятеро - "Чебурашка", а семеро написали все три мультфильма. Мультфильм " Простоквашино " назвали 16 ребят, среди которых семеро выбрали по два мультфильма. Сколько человек выбрали мультфильм "Чебурашка"?`,
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_Q10',
    nextStep: 'Q12',
  },
};
