/* eslint-disable max-len */
import {
  Step,
  SceneId,
  StepType,
  Scenario,
  ScenarioType,
  Interlocutors,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';
import { TEST_TVOI_VECTOR } from '../../tests/test_tvoi_vector';
import { images } from '../../tests/images';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const hermitage_1_1: Scenario = {
  scenarioId: SceneId.hermitage_1_1,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    timeoutMessage: 'К сожалению, время на ответ истекло.',
    scene_id: 'scenario_2_4',
    startId: 'Q0',
    title: 'Эрмитаж',
    steps: {
      Q0: {
        person: Interlocutors.CAT,
        id: 'Q0',
        text: `Малевич! Как же я давно тебя не видел!

        А мы с моим новым другом {{NAME}} проводим очень важное расследование.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ты приятель Вжуха? Приятно познакомиться!',
            nextStep: 'Q1',
          },
        ],
      },
      Q1: {
        person: Interlocutors.BLUE_CAT,
        text: `
        Добрый день, друзья! 

        {{NAME}}, наслышан о вашей помощи нашему парку. Я Малевич, несу ответственную службу в этом музее.
        
        Могу чем-то вас выручить?`,
        id: 'Q1',
        nextStep: 'Q2',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Это такое красивое место! Расскажи, где мы?',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        person: Interlocutors.BLUE_CAT,
        text: `
        Конечно, с радостью!

        Мы в Зимнем дворце — это главный императорский дворец России. Его называют Зимним, потому что императоры жили здесь зимой, а лето проводили в загородных летних резиденциях. 
         
        Сейчас этот дворец является частью самого большого музея нашей страны — Эрмитажа. По площади он занимает третье место в мире и насчитывает 365 залов.
        
        В нашем парке находится точная копия дворца, а настоящий расположен в культурной столице России — Санкт-Петербурге.
        
        Мало кто знает, что работы всемирно известных великих художников Леонардо да Винчи и Микеланджело хранятся здесь.`,
        id: 'Q2',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Здорово, наверное, тут было жить!',
            nextStep: 'Q3',
          },
          {
            id: 'b2',
            text: 'Хочу теперь сюда на экскурсию!',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q3',
        text: `Рад встретить ценителя прекрасного. 

        Когда я завожу нового друга, всегда задаю ему вопросы про любимые занятия. Позвольте и про ваши узнать.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Конечно, спрашивай!',
            nextStep: 'Q4',
          },
        ],
      },
      // Тест - Твой Вектор 1/3
      Q4: TEST_TVOI_VECTOR,

      Q6: {
        person: Interlocutors.CAT,
        text: `Малевич, мы рады встрече, правда очень торопимся! Увидимся ещё!

        {{name}}, пойдём скорее внутрь, нам ещё Женю искать`,
        id: 'Q6',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, Малевич, до встречи!',
            nextStep: 'Q7',
          },
        ],
      },
      Q7: {
        person: Interlocutors.CAT,
        id: 'Q7',
        type: StepType.question,
        text: `Ох уж эти эрмитажные коты — настоящие аристократы! 

        Ты знаешь, что Эрмитаж знаменит своими котами, которые несут официальную службу по поимке крыс и мышей? Да-да, такая у них полезная работа!
        
        Они главные смотрители и настоящий символ Эрмитажа`,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А ты бы не хотел тоже работать в Эрмитаже?',
            nextStep: 'Q8',
          },
        ],
      },
      Q8: {
        person: Interlocutors.CAT,
        id: 'Q8',
        text: `Ну уж нет, мне больше нравится общаться с людьми! 

        А вот и Женя! Привет!`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Привет, Женя!',
            nextStep: 'Q9',
          },
        ],
      },
      Q9: {
        person: Interlocutors.GUIDE,
        id: 'Q9',
        text: `Мяу! Мяу мяу мяу, мяу мяу.

        Мрррррр….`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А-ха-ха-ха! Женя, я тоже люблю изображать кота! ',
            nextStep: 'Q10',
          },
        ],
      },
      Q10: {
        person: Interlocutors.GUIDE,
        id: 'Q10',
        text: `Мяяяяу. Мрр, мяу, мяу мяу.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Мяу! Нам нужна твоя помощь!',
            nextStep: 'Q11',
          },
          {
            id: 'b2',
            text: 'Эээ… М-мяу?',
            nextStep: 'Q11',
          },
        ],
      },
      Q11: {
        person: Interlocutors.CAT,
        id: 'Q11',
        text: `Не думаю, что она нас понимает…`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Как это? ',
            nextStep: 'Q12',
          },
          {
            id: 'b2',
            text: 'Женя, ну скажи ему, что ты шутишь! ',
            nextStep: 'Q12',
          },
        ],
      },
      Q12: {
        person: Interlocutors.GUIDE,
        id: 'Q12',
        text: `Мррррр.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Вжух! Что она сказала? Ты же тоже кот. ',
            nextStep: 'Q13',
          },
        ],
      },
      Q13: {
        person: Interlocutors.CAT,
        id: 'Q13',
        text: `Дело в том, что…даже не знаю как сказать… Видишь ли, я говорил, что больше люблю говорить с людьми.

        И так давно я не говорил по-кошачьи, что уже разучился понимать язык. В парке ведь все коты владеют человеческим русским.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Но что же нам теперь делать?',
            nextStep: 'Q14',
          },
        ],
      },
      Q14: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q14',
        text: `Друзья, совсем забыл вам сказать о нашем конфузе.

        Не так давно, удивительным образом, все человеческие сотрудники Эрмитажа, в том числе и Женя, забыли русский и стали говорить на кошачьем. Мы теперь даже посетителей-людей принять не можем.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Малевич, но нам очень нужно с ней поговорить! Это для нашего расследования. ',
            nextStep: 'Q15',
          },
        ],
      },
      Q15: {
        person: Interlocutors.CAT,
        id: 'Q15',
        text: `Да, Малевич!

        Ты же постоянно в Эрмитаже работаешь с котами. Спроси у Жени, где мы можем найти инженера Петрова?`,
        type: StepType.message,
        nextStep: 'Q16',
      },
      Q16: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q16',
        text: `Друзья, я на голодный желудок совсем не могу заниматься умственным трудом переводчика. 

        А у Жени, к тому же, такой сложный для понимания диалект кошачьего.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Мы принесем тебе еды. Что бы ты хотел?',
            nextStep: 'Q17',
          },
        ],
      },
      Q17: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q17',
        text: `Спасибо! 

        Я очень люблю речную рыбу. За неё готов хоть даже и книгу вам перевести на кошачий.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Мы мигом, Малевич!',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};

const CREATIVE_TEST: { [id: string]: Step } = {
  ...splitStep({
    person: Interlocutors.BLUE_CAT,
    id: 'CREATIVE_TEST',
    callToAction: 'Напиши и отправь',
    type: StepType.question,
    maxAnswers: 1,
    text: `Перед тобой несколько картин известных художников абстракционистов. Придумай им названия.

На обдумывание каждой картины у тебя будет 1 минута, поэтому не затягивай.

Напоминаю, названия не могут нарушать Положение о конкурсе, особенно пункт 1.6. Если хочешь изучить Положение, оно есть по <a href="https://bolshayaperemena.online/api/link/short/4beffe5b9d" target="_blank">ссылке</a>

В общем, твои ответы должны быть приличными и цензурными 😊

Придумай название для каждой картины, впиши в поле ниже и отправь. 

Обращаю внимание, что нужно обязательно нажать кнопку «Отправить ответ», до окончания времени на секундомере, иначе ответ не будет засчитан.`,
    buttons: [
      {
        id: 'b1',
        text: 'Начать',
        nextStep: 'CREATIVE_TEST_1',
      },
    ],
  }),
  CREATIVE_TEST_1: {
    person: Interlocutors.BLUE_CAT,
    id: 'CREATIVE_TEST_1',
    variable: 'CREATIVE_TEST_1',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_1, title: '' },
    nextStep: 'CREATIVE_TEST_2',
    timeout: 60,
  },
  CREATIVE_TEST_2: {
    person: Interlocutors.BLUE_CAT,
    id: 'CREATIVE_TEST_2',
    variable: 'CREATIVE_TEST_2',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_2, title: '' },
    nextStep: 'CREATIVE_TEST_3',
    timeout: 60,
  },
  CREATIVE_TEST_3: {
    person: Interlocutors.BLUE_CAT,
    id: 'CREATIVE_TEST_3',
    variable: 'CREATIVE_TEST_3',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_3, title: '' },
    nextStep: 'CREATIVE_TEST_4',
    timeout: 60,
  },
  CREATIVE_TEST_4: {
    person: Interlocutors.BLUE_CAT,
    id: 'CREATIVE_TEST_4',
    variable: 'CREATIVE_TEST_4',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_4, title: '' },
    nextStep: 'CREATIVE_TEST_5',
    timeout: 60,
  },
  CREATIVE_TEST_5: {
    person: Interlocutors.BLUE_CAT,
    id: 'CREATIVE_TEST_5',
    variable: 'CREATIVE_TEST_5',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_5, title: '' },
    nextStep: 'CREATIVE_TEST_6',
    timeout: 60,
  },
  CREATIVE_TEST_6: {
    person: Interlocutors.BLUE_CAT,
    id: 'CREATIVE_TEST_6',
    variable: 'CREATIVE_TEST_6',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_6, title: '' },
    nextStep: 'Q6',
    timeout: 60,
  },
};
