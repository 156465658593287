import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import {
  BREAKPOINT_LAPTOP,
  BREAKPOINT_PHONE,
  BREAKPOINT_SMALL_PHONE,
  BREAKPOINT_TABLET,
} from '../../../common/shared/constants/constants';
import { windowSizes } from '../../redux/app';
import { selectViewport } from '../../redux/app/appGetters';
import { setViewport } from '../../redux/app/appActions';

const sizes = {
  [parseInt(BREAKPOINT_SMALL_PHONE)]: windowSizes.phone,
  [parseInt(BREAKPOINT_PHONE)]: windowSizes.tablet,
  [parseInt(BREAKPOINT_TABLET)]: windowSizes.laptop,
  [parseInt(BREAKPOINT_LAPTOP)]: windowSizes.desktop,
};

let first = 0;

export const Viewport = () => {
  const size = useSelector(selectViewport);

  const dispatch = useDispatch();
  const setSize = useCallback((size) => dispatch(setViewport(size)), [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      const newSize = Object.keys(sizes).reduce(
        (_, cur) => (+cur < window.innerWidth ? sizes[+cur] : _),
        windowSizes.smallphone,
      );

      if (newSize !== size) setSize(newSize);
    };

    if (first === 0) {
      handleResize();
      first++;
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [size, setSize]);

  return null;
};
