/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';
import interlocutors from '../../../interlocutors';

export const scenario_1_22: Scenario = {
  scenarioId: SceneId.scenario_1_22,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `{{NAME}}, ты снова нас очень выручил! 😊

Бабуля в таком восторге от знакомства, что только о тебе и говорит! Гуляет на свежем воздухе и не нарадуется, как стало чисто и красиво 😻

Мы все решили к ней присоединиться и тоже пару дней отдохнуть. Возвращайся к нам 23 апреля, продолжим трудиться на благо «Большой перемены»!`,
    buttons: ['Закрыть'],
  },
};
