/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg3_lake_1_1_1: Scenario = {
  scenarioId: SceneId.stg3_lake_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Байкал',
    text: `Вы подходите к берегу озера, но Бабули вокруг не видно. Её избушка тоже пустая. На лавочке, где обычно Бабуля отдыхала, вы находите кружку с недопитым чаем. Рядом с лавочкой стоят аккуратно сложенные пустые коробки разных размеров и форм…`,
    buttons: ['Начать'],
  },
};
