/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_olympicPark_1_1_1: Scenario = {
  scenarioId: SceneId.stg2_olympicPark_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Олимпийский Парк',
    text: `Подходя к стадиону Фишт, вы слышите крики толпы и удары по мячу. На стадионе сейчас любительский матч. 

    Вы рады, что стадион теперь снова используется и вы вместе с Петровым не зря чистили поле от фиолетовой слизи.`,
    buttons: ['Начать'],
  },
};
