export enum windowSizes {
  smallphone = 'smallphone',
  phone = 'phone',
  tablet = 'tablet',
  laptop = 'laptop',
  desktop = 'desktop',
}

export interface TestResultData {}

export interface AppState {
  viewport: windowSizes;
  isShowMobileMenu: boolean;
  isShowVideoAttachmentModal: boolean;
  shared: Record<string | number, any>;
}
