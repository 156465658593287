import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QueryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { useMount } from '../../../common/shared/hooks/useMount';
import { selectToken } from '../../redux/user/userGetters';
import LocalStorage from '../../../common/shared/utils/LocalStorage';
import { routes } from '../../../common/shared/constants/routes';
import { setInfoAsync, setTokenAsync } from '../../redux/user/userActions';
import {
  CURRENT_VERSION,
  isDebug,
  STORE_VERSION,
} from '../../../common/shared/constants/constants';

const useFirstDataLoad = (setLoading: any) => {
  const history = useHistory();
  const userToken = useSelector(selectToken);

  const dispatch = useDispatch();

  return useMount(() => {
    if (history?.location?.search) {
      const query = QueryString.parse(history.location.search);
      const token = query['access_token'];

      if (!Array.isArray(token) && token) {
        LocalStorage.set(STORE_VERSION, CURRENT_VERSION);

        const goToOnboarding = () => {
          history.replace(routes.dashboard);
          setLoading(false);
        };

        dispatch(setTokenAsync(token));
        dispatch(setInfoAsync(goToOnboarding));
        return;
      }
    }

    if (userToken) {
      dispatch(
        setInfoAsync(
          () => setLoading(false),
          () => setLoading(false),
        ),
      );
      return;
    }

    setTimeout(() => setLoading(false), isDebug ? 300 : 1500);
  });
};

export default useFirstDataLoad;
