import React from 'react';
import { FogStyled } from './FogStyled';
import { FogProps } from './FogTypes';

export const Fog = (props: FogProps) => {
  let top: number;
  let left: number;

  if (props.point[1].stage1.$top > 0) {
    top = props.point[1].stage1.$top;
    left = props.point[1].stage1.$left;
  } else if (props.point[1].stage2.$top > 0) {
    top = props.point[1].stage2.$top;
    left = props.point[1].stage2.$left;
  } else {
    top = props.point[1].stage2act2.$top;
    left = props.point[1].stage2act2.$left;
  }

  return <FogStyled src={props.src} top={top} left={left} isBridge={props.point[0] === 'bridge'} />;
};
