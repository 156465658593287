import styled, { css } from 'styled-components';
import { WrapperProps } from './WrapperTypes';
import { BREAKPOINT_PHONE } from '../../constants/constants';

export const StyledWrapper = styled.div<WrapperProps>`
  width: 100%;
  margin: 0 auto;
  max-width: 1240px;

  display: flex;
  flex-direction: row;

  ${(p) => {
    if (!p.withoutPadding) {
      return css`
        padding-left: 14px;
        padding-right: 14px;

        @media screen and (min-width: ${BREAKPOINT_PHONE}) {
          padding-left: 20px;
          padding-right: 20px;
        }
      `;
    }
  }}
`;
