/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId, StepType } from '../../../scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const scenario_3_4_0: Scenario = {
  scenarioId: SceneId.scenario_3_4_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Херсонес',
    text: 'Вы находитесь в Херсонесе. Его древние развалины погружают всех присутствующих в атмосферу античных мифов...',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_4: Scenario = {
  scenarioId: SceneId.scenario_3_4,
  type: ScenarioType.chat,
  data: {
    title: 'Херсонес',
    scene_id: 'scenario_3_4',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.PETROV,
        id: 'Q1',
        text: 'Обожаю эту часть «Большой перемены»! 😍',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А где это мы? Кажется, я здесь еще не [[бывал/бывала]]',
            nextStep: 'Q2/0',
          },
        ],
      },
      ...splitStep({
        person: Interlocutors.PETROV,
        id: 'Q2',
        text: `Перед нами точная копия Херсонеса — города, основанного древними греками на юго-западном побережье Крыма в V веке до н. э. Это почти 2500 лет назад! Херсонес — единственный античный полис Северного Причерноморья, городская жизнь в котором непрерывно поддерживалась вплоть до конца XIV века. 

Полисами в Древней Греции называли города с особой формой общественного устройства. В них все граждане имели определенные права и обязанности. А еще в полисах впервые появилась концепция равенства граждан перед законом, а важнейшие решения принимались с участием всех граждан мужского пола.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Древние греки были очень продвинутыми!?',
            nextStep: 'Q3',
          },
        ],
      }),
      Q3: {
        person: Interlocutors.PETROV,
        id: 'Q3',
        text: 'Не просто продвинутыми. Многие полисы имели демократический уклад, в том числе и Херсонес. Он принимал активное участие в общегреческих праздниках, спортивных состязаниях и вел активную внешнюю политику.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А что это за странные квадратные развалины?',
            nextStep: 'Q4',
          },
        ],
      },
      Q4: {
        person: Interlocutors.PETROV,
        id: 'Q4',
        text: 'Это частично сохранившиеся руины жилых усадеб. В усадьбах велось земледелие, выращивались виноград и злаковые.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Если 2500 лет назад здесь жили древние греки, как же Херсонес стал частью нашей страны?',
            nextStep: 'Q5/0',
          },
        ],
      },
      ...splitStep({
        person: Interlocutors.PETROV,
        id: 'Q5',
        text: `Дело в том, что в конце X века киевский князь Владимир после нескольких месяцев осады захватил город, и Херсонес стал частью Киевской Руси. Здесь же Владимир принял православие.  

Видишь этот прекрасный собор? Он называется Владимирским и построен в XIX веке в память о крещении киевского князя Владимира. Вон там рядом с храмом стоит купель. По одной из версий именно в ней в 988 году крестили Владимира. Можно сказать, что с этого места началась православная история Руси.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Вот это да! Поистине историческое место!',
            nextStep: 'Q6',
          },
        ],
      }),
      Q6: {
        person: Interlocutors.PETROV,
        id: 'Q6',
        type: StepType.message,
        text: `Пусть у нас в «Большой перемене» лишь копия Херсонеса, но здесь все равно ощущается особая атмосфера 😊`,
        nextStep: 'Q7',
      },
      Q7: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q7',
        type: StepType.message,
        text: `Спасибо за такой увлекательный рассказ, Петров. Но давайте вернемся к расследованию. Ты считаешь, что кирку могли использовать археологи на раскопках в этом месте?`,
        nextStep: 'Q8',
      },
      Q8: {
        person: Interlocutors.PETROV,
        id: 'Q8',
        text: 'Именно! Посмотрите на те огороженные флажками руины — это экспозиция, показывающая археологические раскопки, которые ведутся в настоящем Херсонесе в Севастополе. Там я и видел точно такую же кирку!',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Давайте подойдем поближе к экспозиции и рассмотрим место повнимательнее…',
            nextStep: 'Q9',
          },
        ],
      },
      Q9: {
        person: Interlocutors.SYSTEM,
        id: 'Q9',
        type: StepType.message,
        text: `Вы вместе с Бабулей и Петровым подходите к экспозиции археологических раскопок и заглядываете в яму...`,
        nextStep: 'Q10',
      },
      Q10: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q10',
        type: StepType.message,
        text: `Ооо, ребята, посмотрите-ка, что это здесь? Непохоже, что эта странная штуковина — часть экспозиции! Она больше напоминает какой-то современный аппарат. Наподобие тех, что мастеришь ты, Петров...🤔`,
        nextStep: 'Q11',
      },
      Q11: {
        person: Interlocutors.PETROV,
        id: 'Q11',
        text: 'Так это же моя дым-машина! Наконец-то она нашлась! И что она здесь вообще делает? 😱',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Какая еще дым-машина?',
            nextStep: 'Q12/0',
          },
          {
            id: 'b2',
            text: 'Петров, опять твои странные изобретения!',
            nextStep: 'Q12/0',
          },
        ],
      },
      ...splitStep({
        person: Interlocutors.PETROV,
        id: 'Q12',
        type: StepType.message,
        text: `Однажды ко мне пришел Сан Саныч и рассказал, что мечтает устроить в «Большой перемене» шоу ледяных скульптур. А у нас же здесь всегда ясно, тепло и светит солнце. Вот Сан Саныч и поручил мне изобрести машину, которая могла бы поддерживать лед в замороженном состоянии. 

И я сконструировал эту дым-машину. Она генерирует ледяной дым. Вот только я не успел все как следует доделать — машина исчезла из моей лаборатории! Где я ее только ни искал! А потом на парк опустился туман и начались все наши неприятности… Тогда уже не до поисков стало…  

Так что же это получается? Все из-за моей дым-машины?!! 😱

Но постойте, из нее торчит что-то красное… Похоже на пропавшую из поезда ручку стоп-крана. Надо ее вытащить…`,
        nextStep: 'Q13/0',
      }),
      ...splitStep({
        person: Interlocutors.SYSTEM,
        id: 'Q13',
        type: StepType.message,
        text: `Петров спрыгнул в яму к машине, потянулся к красной ручке, как ВДРУГ раздался пронзительный крик: «Стой! Не делай этого!».  

Из-за пушки показалось СИНЕЕ МОХНАТОЕ СУЩЕСТВО...`,
        nextStep: 'Q14',
      }),
      Q14: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q14',
        type: StepType.message,
        text: `СТОЙ! Не делай этого ни в коем случае!!! Если вытащить ручку, парк снова заледенеет!!! 🙀`,
        nextStep: 'Q15',
      },
      Q15: {
        person: Interlocutors.PETROV,
        id: 'Q15',
        type: StepType.message,
        text: `ЧТОООООО? Кто ты такой? 😱`,
        nextStep: 'Q16',
      },
      Q16: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q16',
        text: 'Батюшки! Да что же это здесь творится?!! 🤔',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ой, кто это? Какой-то синий… Вжух???',
            nextStep: 'Q17/0',
          },
        ],
      },
      ...splitStep({
        person: Interlocutors.PETROV,
        id: 'Q17',
        text: `Это же кот! Действительно, вылитый Вжух, только синий! 😱

Что ты здесь делаешь и почему сидишь возле моей дым-машины?`,
        type: StepType.message,
        nextStep: 'Q18/0',
      }),
      ...splitStep({
        id: 'Q18',
        person: Interlocutors.BLUE_CAT,
        type: StepType.message,
        text: `Многоуважаемые, подождите! Не трогайте ручку… Она сдерживает пусковой механизм дым-машины. Если ее вытащить, то все здесь снова покроется ледяным туманом!

Видите ли… Я...`,
        nextStep: 'Q19/0',
      }),
      ...splitStep({
        person: Interlocutors.GRANDMOTHER,
        id: 'Q19',
        type: StepType.message,
        text: `Да погодите вы с расспросами, давайте сначала накормим бедолагу! Посмотрите, какой он худенький: кожа да кости, шерсть местами вылезла и выглядит он очень напуганным 😔

Котик, не бойся, пойдем с нами. Тут как раз рядом Москва-Сити, там и накормим, и согреем.

А ты, Петров, пока позвони Сан Санычу, пусть найдет Светлану Васильевну, возьмет Вжуха, и встретимся в Москва-Сити.`,
        nextStep: EXIT_ID,
      }),
      [EXIT_ID]: {
        person: Interlocutors.TEACHER,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.TEACHER,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
