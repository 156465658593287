import { createSlice } from '@reduxjs/toolkit';

export enum windowSizes {
  smallphone = 'smallphone',
  phone = 'phone',
  tablet = 'tablet',
  laptop = 'laptop',
  desktop = 'desktop',
}

export interface TestResultData {}

export interface AppState {
  viewport: windowSizes;
  isShowMobileMenu: boolean;
  isShowVideoAttachmentModal: boolean;
  shared: Record<string | number, any>;
}

const initialState: AppState = {
  shared: {},
  viewport: windowSizes.smallphone,

  isShowMobileMenu: false,
  isShowVideoAttachmentModal: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setViewport: (state, action) => {
      state.viewport = action.payload;
    },
    setShowMobileMenu: (state, action) => {
      state.isShowMobileMenu = Boolean(action.payload);
    },
    setShowVideoAttachmentModal: (state, action) => {
      state.isShowVideoAttachmentModal = Boolean(action.payload);
    },
    share: (state, action) => {
      state.shared = { ...state.shared, ...action.payload };
    },
  },
});

export default appSlice;
