/* eslint-disable @typescript-eslint/no-non-null-assertion */
// @ts-nocheck
import React from 'react';
import styled from 'styled-components';
import LibraryTooltip from 'react-tooltip';
import { ReactTooltipProps } from './ReactTooltipTypes';
import { BREAKPOINT_PHONE } from '../../constants/constants';

const StyledReactTooltip = styled(LibraryTooltip)`
  &&& {
    padding: 12px;
    max-width: 320px;

    font-size: 14px;
    line-height: 1.35;
    border-radius: 10px;

    transition: opacity 0.15s ease-out;

    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      padding: 8px;
      font-size: 12px;
      max-width: 200px;
    }
  }
`;

export const ReactTooltip = (props: ReactTooltipProps) => {
  return (
    <StyledReactTooltip
      // for debug
      // eventOff='dbclick'
      // event='click focus'
      //
      multiline
      place='top'
      effect='solid'
      delayShow={250}
      overridePosition={({ left, top }, currentEvent, currentTarget, node) => {
        const { clientWidth, clientHeight } = document.documentElement;
        left = Math.min(clientWidth - node!.clientWidth, left);
        top = Math.min(clientHeight - node!.clientHeight, top);
        left = Math.max(0, left);
        top = Math.max(0, top);
        return { top, left };
      }}
    />
  );
};
