import { AppThunk, IProtocol, TestType } from '../../types';
import { mergeProtocols, parseTests, parseTestTvoiKod } from '../../utils/testsParser';
import { SceneId } from '../../resources/scenario';
import { Optional } from '../user';
import ChoicesApi from '../../api/choices';
import { TEST_PROBLEM_KEY } from '../../../common/shared/constants/constants';
import { TestID } from '../../types';
import { ApiChoice } from '../../types/api/user';
import testsSlice from './index';
import { TestItem } from '../../hooks/useTestsResults';
import { setVariablesAsync } from '../user/userActions';
import { useAppDispatch } from '../../hooks/useAppDispatch';

/**
 * TODO: !!!!!! отрефакторить этот файл и поменять id сцен тестов на новые
 */

const { setTestResults, addTestResult, setActiveTestResult } = testsSlice.actions;

export const setActiveTestResultAsync =
  (data?: TestItem): AppThunk =>
  async (dispatch) => {
    dispatch(setActiveTestResult(data));
  };

const checkTvoiKodAsync = (): AppThunk => async (dispatch, getState) => {
  const tests = getState().tests.testResults;
  const scenarios = getState().scenario.scenarios;

  if (tests[TestID[TestType.personality]]) {
    return;
  }

  if (scenarios.scenario_1_3) {
    const protocol = parseTestTvoiKod(scenarios.scenario_1_3);
    const response = await ChoicesApi.postChoice(TestID[TestType.personality], protocol);
    dispatch(addTestResult({ [TestID[TestType.personality]]: response.data }));
  }
};

const checkTvoiVyborAsync = (): AppThunk => async (dispatch, getState) => {
  const tests = getState().tests.testResults;
  const scenarios = getState().scenario.scenarios;
  let completed = 0;
  const ids = [
    SceneId.scenario_1_8,
    SceneId.scenario_1_9,
    SceneId.scenario_1_18,
    SceneId.scenario_1_19,
    SceneId.scenario_1_23,
  ];
  let protocol: Optional<IProtocol>;

  if (tests[TestID[TestType.interests]]) {
    return;
  }

  for (let i = 0; i < ids.length; i++) {
    const definedScenario = scenarios[ids[i]];
    if (definedScenario) {
      ++completed;
      const part = parseTests(definedScenario, TestType.interests, 'json');
      protocol = protocol ? mergeProtocols(protocol, part) : part;
    }
  }

  if (completed === 5 && protocol) {
    const response = await ChoicesApi.postChoice(TestID[TestType.interests], protocol);
    dispatch(addTestResult({ [TestID[TestType.interests]]: response.data }));
  }
};

const checkTvoiVectorAsync = (): AppThunk => async (dispatch, getState) => {
  const tests = getState().tests.testResults;
  const scenarios = getState().scenario.scenarios;
  let completed = 0;
  const ids = [
    SceneId.scenario_1_7,
    SceneId.scenario_1_17,
    SceneId.scenario_1_25_1,
    SceneId.scenario_1_25_4,
  ];
  let protocol: Optional<IProtocol>;

  if (tests[TestID[TestType.thinking]]) {
    return;
  }

  for (let i = 0; i < ids.length; i++) {
    const definedScenario = scenarios[ids[i]];
    if (definedScenario && definedScenario.findIndex((i) => i.id.indexOf('TEST') !== -1)) {
      ++completed;
      const part = parseTests(definedScenario, TestType.thinking, 'json');
      protocol = protocol ? mergeProtocols(protocol, part) : part;
    }
  }

  if (completed > 3) {
    const variables = getState().user?.info?.variables || {};
    dispatch(setVariablesAsync({ ...variables, [TEST_PROBLEM_KEY]: 'true' }));
  }

  if (completed >= 3 && protocol) {
    const response = await ChoicesApi.postChoice(TestID[TestType.thinking], protocol);
    dispatch(addTestResult({ [TestID[TestType.thinking]]: response.data }));
  }
};

const checkEruditionTechAsync = (): AppThunk => async (dispatch, getState) => {
  const tests = getState().tests.testResults;
  const scenarios = getState().scenario.scenarios;
  const definedScenario = scenarios[SceneId.scenario_3_3];

  if (tests[TestID[TestType.erudition_tech]]) {
    return;
  }

  if (definedScenario) {
    const protocol = parseTests(
      definedScenario,
      TestType.erudition_tech,
      'json',
      'TEST/TVOI_KRUGOZOR_T2_STEP',
    );
    if (protocol) {
      const response = await ChoicesApi.postChoice(TestID[TestType.erudition_tech], protocol);
      dispatch(addTestResult({ [TestID[TestType.erudition_tech]]: response.data }));
    }
  }
};

const checkLogicTestAsync = (): AppThunk => async (dispatch, getState) => {
  const tests = getState().tests.testResults;
  const scenarios = getState().scenario.scenarios;
  const definedScenario = scenarios[SceneId.scenario_3_1];

  if (tests[TestID[TestType.logic]]) {
    return;
  }

  if (definedScenario) {
    const protocol = parseTests(definedScenario, TestType.logic, 'js');
    if (protocol) {
      const response = await ChoicesApi.postChoice(TestID[TestType.logic], protocol);
      dispatch(addTestResult({ [TestID[TestType.logic]]: response.data }));
    }
  }
};

const checkEruditionHumAsync = (): AppThunk => async (dispatch, getState) => {
  const tests = getState().tests.testResults;
  const scenarios = getState().scenario.scenarios;
  const definedScenario = scenarios[SceneId.scenario_3_3];

  if (tests[TestID[TestType.erudition_hum]]) {
    return;
  }

  if (definedScenario) {
    const protocol = parseTests(
      definedScenario,
      TestType.erudition_hum,
      'json',
      'TEST/TVOI_KRUGOZOR_G2_STEP',
    );
    if (protocol) {
      const response = await ChoicesApi.postChoice(TestID[TestType.erudition_hum], protocol);
      dispatch(addTestResult({ [TestID[TestType.erudition_hum]]: response.data }));
    }
  }
};

function normalizeTestResults(tests: ApiChoice[]) {
  const response: Record<string, ApiChoice> = {};

  for (let i = 0; i < tests.length; i++) {
    response[tests[i].data.test] = tests[i];
  }
  return response;
}

let isFetching = false;

export const checkTestsAsync = (): AppThunk => async (dispatch) => {
  // чтобы запросы не шли два раза
  if (isFetching) return;

  isFetching = true;
  const response = await ChoicesApi.getChoices();
  const choices = normalizeTestResults(response.data);

  dispatch(setTestResults(choices));
  dispatch(checkTvoiKodAsync());
  dispatch(checkTvoiVyborAsync());
  dispatch(checkTvoiVectorAsync());
  dispatch(checkEruditionTechAsync());
  dispatch(checkEruditionHumAsync());
  dispatch(checkLogicTestAsync());

  const timeout = setTimeout(() => {
    isFetching = false;
    clearTimeout(timeout);
  }, 2000);
};

export const sendTestNew = async (getState: any) => {
  const choices = await ChoicesApi.getChoices();
  // const data = normalizeTestResults(choices.data); // отрефакторить, не понятно зачем сделано

  await checkTvoiKodAsyncNew(getState);
};

const checkTvoiKodAsyncNew = async (getState: any) => {
  const tests = getState().tests.testResults; // отрефакторить, не понятно зачем сделано

  const scenarios = getState().scenario.scenarios;
  const protocol = parseTestTvoiKod(scenarios.scenario_1_3);
  await ChoicesApi.postChoice(TestID[TestType.personality], protocol);
};
