import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserInfo } from '../redux/user/userGetters';
import {
  StyledApperanceHero,
  StyledApperanceHeroView,
  StyledApperanceHeroViewBase,
  StyledApperanceHeroViewBody,
  StyledApperanceHeroViewFace,
  StyledApperanceHeroViewHair,
} from './Hero/HeroStyled';

import { StyledHeaderUserImg } from './HeaderUser/HeaderUserStyled';

import avatar from '../../assets/images/avatar.png';
import useHeroConfig from '../hooks/useHeroConfig';

export function Avatar(props: any) {
  const userInfo = useSelector(selectUserInfo);
  const { hair, face, body, background, config } = useHeroConfig(true);

  if (!userInfo?.profile?.bodyId) {
    return (
      <StyledHeaderUserImg
        src={avatar}
        onClick={props.onClick}
        $cursor={props.onClick ? 'pointer' : undefined}
      />
    );
  }

  return (
    <StyledApperanceHero $background={background.icon} onClick={props.onClick} $isAvatar>
      <StyledApperanceHeroView $isAvatar>
        <StyledApperanceHeroViewHair src={hair.template} />
        <StyledApperanceHeroViewFace src={face.template} />
        <StyledApperanceHeroViewBody src={body.template} />
        <StyledApperanceHeroViewBase src={config.base} />
      </StyledApperanceHeroView>
    </StyledApperanceHero>
  );
}
