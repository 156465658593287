/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg3_mgu_1_1_1: Scenario = {
  scenarioId: SceneId.stg3_mgu_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'МГУ',
    text: `У здания МГУ вы видите толпу жителей парка. Кажется, там собрались все ваши знакомые и множество незнакомцев. Перед толпой стоит Петров и что-то увлеченно рассказывает.`,
    buttons: ['Начать'],
  },
};
