import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { useDispatch } from 'react-redux';
import { runScenarioAsync } from '../redux/scenario/scenarioActions';

import scenes from '../resources/scenes';
import { SceneId } from '../types/scenario';

export function useRunScenarioAsync() {
  const dispatch = useDispatch();
  const history = useHistory();
  return useCallback(
    (sceneId: SceneId, onSubmit?: (button: string) => void) => {
      dispatch(runScenarioAsync(scenes[sceneId], onSubmit, history));
    },
    [dispatch, history],
  );
}
