/* eslint-disable @typescript-eslint/no-non-null-assertion */
import userSlice, { LegacyUserState, Optional, UserAppState, UserInfo } from '.';
import { AppThunk, RootState, UnsavedScripts } from '../../types';

import Auth from '../../../common/shared/utils/Auth';
import UserApi from '../../api/user';
import LocalStorage from '../../../common/shared/utils/LocalStorage';

import { SceneId, Step, StepType } from '../../types/scenario';
import { ProfileConfig } from '../../components/Hero/config';

import {
  CURRENT_VERSION,
  isDebug,
  RELOGIN_VERSION,
  STORE_CHAT_PAGE_PROGRESS,
  STORE_UNSAVED_SCRIPTS,
  STORE_VERSION,
  VIDEO_REWRITED_KEY,
} from '../../../common/shared/constants/constants';

import scenarioSlice from '../scenario';
import ScriptsApi from '../../api/scripts';

import { Logger } from '../../utils/Logger';
import { ApiScript } from '../../types/api/user';
import { checkTestsAsync } from '../tests/testsActions';
import { ChallengeBackendData, ChallengeKey } from '../../components/Challenges/config';
import { setActiveSceneVariablesAsync, setAllScenarios } from '../scenario/scenarioActions';
import { removeUnsavedScript, storeUnsavedScript } from '../../hooks/scenarioHelpers/utils';

const { login, logout, setInfo, setChalleng, setUserFiles } = userSlice.actions;

const getDataFromStorage: () => Partial<LegacyUserState> = () => {
  try {
    const scenariosStorageData = LocalStorage.get<string>(STORE_CHAT_PAGE_PROGRESS);
    if (scenariosStorageData) return JSON.parse(scenariosStorageData);
  } catch (_) {
    return {};
  }
};

const syncProgress = (): AppThunk => async (dispatch) => {
  const response = await ScriptsApi.getScripts();
  const scripts = normalizeScripts(response.data);
  dispatch(setAllScenarios(scripts));
};

export const getUserId = (getState: () => RootState): string | undefined => {
  const state = getState();
  return (Auth.parseToken(state.user.token || '') || {}).sub?.toString();
};

let isLoading = false;
export const saveStartedScenariosAsync =
  (startedScenario: Step[], id?: SceneId): AppThunk =>
  async (dispatch, getState) => {
    if (!isLoading) {
      isLoading = true;
      const activeScenarioId = id || getState().scenario.activeChatScenario?.scenarioId;
      if (activeScenarioId) {
        if (startedScenario.findIndex((i) => i.type === StepType.exit) !== -1) {
          return;
        }
        await dispatch(saveHistoryAsync(activeScenarioId, startedScenario, false));
      }
      isLoading = false;
    } else {
      console.log('LOADING.log');
    }
  };

export const setVariablesAsync =
  (variables: Record<string, string>): AppThunk =>
  async (dispatch, getState) => {
    const userId = getUserId(getState) || '';
    const currentProfile: ProfileConfig = getState().user.info?.profile || { name: '' };
    const profile =
      currentProfile.name !== variables.NAME
        ? { ...currentProfile, name: variables.NAME }
        : undefined;
    const currentUser = await UserApi.getUserInfo(userId);
    const currentUserVars = currentUser.data.variables || {};
    const vars = { ...currentUserVars, ...variables };
    const response = await UserApi.updateVariables(userId, vars, profile);
    dispatch(setInfo(response.data));
  };

export const setStateAsync =
  (state: Partial<UserAppState> = {}, withoutRefreshState?: boolean): AppThunk =>
  async (dispatch, getState) => {
    const userInfo = getState().user.info;
    const oldInfo = (userInfo && userInfo.state && userInfo.state) || {};

    LocalStorage.set(STORE_VERSION, CURRENT_VERSION);

    const newState: UserAppState = {
      ...(oldInfo || {}),
      ...(state || {}),
    };

    const userId = getUserId(getState) || '';
    const response = await UserApi.setUserState(userId, newState);

    if (!withoutRefreshState) dispatch(setInfo(response.data));
  };

export const setTokenAsync =
  (token: string): AppThunk =>
  async (dispatch) => {
    dispatch(login(token));
  };

export const logoutAsync = (): AppThunk => async (dispatch) => {
  const unsaved = LocalStorage.get<UnsavedScripts>(STORE_UNSAVED_SCRIPTS);
  LocalStorage.clear();
  if (unsaved) {
    LocalStorage.set(STORE_UNSAVED_SCRIPTS, unsaved);
  }
  dispatch(logout());
};

const clearHistory = (): AppThunk => async (dispatch, getState) => {
  if (isDebug) {
    const { scenarios, startedScenarios } = getState().scenario;
    for (const scenariosKey in scenarios) {
      await ScriptsApi.deleteScript(scenariosKey);
    }
    for (const scenariosKey in startedScenarios) {
      await ScriptsApi.deleteScript(scenariosKey);
    }
    await dispatch(syncProgress);
  }
};

export const clearProgressAsync =
  (body?: Partial<UserInfo>): AppThunk =>
  async (dispatch, getState) => {
    if (isDebug) {
      const userId = getUserId(getState) || '';

      try {
        await dispatch(clearHistory());
        await UserApi.resetUserState(userId, body);
      } catch (ignore) {
        Logger.log(ignore);
      }

      window.location.href = '/';
    }
  };

const migrateUserAsync = (): AppThunk => async (dispatch, getState) => {
  // local storage
  const chatProgress = getDataFromStorage();
  if (chatProgress && chatProgress.scenarios) {
    const state = getState();
    const keys = Object.keys(chatProgress.scenarios);
    if (keys.length) Logger.log(keys);
    dispatch(
      saveStartedScenariosAsync(chatProgress.scenarios[keys[0] as SceneId]!, keys[0] as SceneId),
    );
    const userState = state.user.info?.state as Optional<LegacyUserState>;
    const variables = {
      ...(userState?.variables || {}),
      ...(state.user.info?.variables || {}),
      ...state.scenario.sceneVariables,
      ...(chatProgress?.variables || {}),
    };
    await dispatch(setActiveSceneVariablesAsync(variables));
    localStorage.removeItem(STORE_CHAT_PAGE_PROGRESS);
  }

  // перенос variables из user.state
  let userState = getState().user.info?.state as Optional<LegacyUserState>;
  const userStateVariables = userState?.variables;
  const unsavedUserStateVariables = userState?.unsavedVariables;
  if (userStateVariables || unsavedUserStateVariables) {
    const v = { ...(userStateVariables || {}), ...(unsavedUserStateVariables || {}) };
    const variables = { ...v, ...(getState().user.info?.variables || {}) };
    await dispatch(setActiveSceneVariablesAsync(variables));

    const state = getState().user.info?.state;
    if (state) {
      const newState = { ...state } as LegacyUserState;
      delete newState.variables;
      delete newState.unsavedVariables;
      const userId = getUserId(getState) || '';
      const response = await UserApi.setUserState(userId, newState);
      dispatch(setInfo(response.data));
    }
  }

  // перенос profile из user.state
  userState = getState().user.info?.state as Optional<LegacyUserState>;
  if (userState?.profile) {
    const newState = { ...userState };
    delete newState.profile;
    const userId = getUserId(getState) || '';
    const response = await UserApi.setUserState(userId, newState);
    dispatch(setInfo(response.data));
  }
};

const normalizeScripts = (scripts: ApiScript[]) => {
  const res: {
    scenarios: Partial<Record<SceneId, Step[]>>;
    startedScenarios: Partial<Record<SceneId, Step[]>>;
  } = {
    scenarios: {},
    startedScenarios: {},
  };
  for (let i = 0; i < scripts.length; i++) {
    res[scripts[i].completed ? 'scenarios' : 'startedScenarios'][scripts[i].script_id] =
      scripts[i].history;
  }
  return res;
};

const checkHasUnsaved = (): AppThunk => (dispatch, getState) => {
  const user = getState().user.info;
  if (user) {
    const unsaved = LocalStorage.get<UnsavedScripts>(STORE_UNSAVED_SCRIPTS) || {};
    const hasUnsaved = Object.keys(unsaved[user.id] || {}).length > 0;
    dispatch(scenarioSlice.actions.setHasUnsaved(hasUnsaved));
  }
};

export const syncUnsavedScripts = (): AppThunk => async (dispatch, getState) => {
  dispatch(checkHasUnsaved());
  const user = getState().user.info;
  if (user) {
    const unsaved = LocalStorage.get<UnsavedScripts>(STORE_UNSAVED_SCRIPTS) || {};
    const unsavedForUser = unsaved[user.id];
    if (unsavedForUser) {
      for (const sceneId in unsavedForUser) {
        const id = sceneId as SceneId;
        const history = unsavedForUser[id]!;
        const isCompleted = history.findIndex((i) => i.type === StepType.exit) !== -1;
        await dispatch(saveHistoryAsync(id, history, isCompleted));
      }
    }
  }
};

/**
 * Вызывает обновление истории. Если обновить не удалось - создает
 * @param {SceneId} id
 * @param {Step[]} history
 * @param {boolean} completed
 * @returns {Promise<void>}
 */
async function updateScriptHistory(id: SceneId, history: Step[], completed: boolean) {
  try {
    const response = await ScriptsApi.patchScript(id, history, completed);
    if (response.status === 404) {
      await ScriptsApi.postScript(id, history, completed);
    }
  } catch (error: any) {
    if (error.response && error.response.status === 404) {
      await ScriptsApi.postScript(id, history, completed);
    } else {
      throw error;
    }
  }
}

// добавить метод для синхронизации с localStorage
export const saveHistoryAsync =
  (id: SceneId, history: Step[], completed: boolean): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const scenario = getState().scenario;
    const prev = { ...scenario.startedScenarios };
    const scen = { ...scenario.scenarios };
    const scenarios = { ...scenario.scenarios };
    const startedScenarios = { ...scenario.startedScenarios };

    // изменение истории на опережение - до ответа сервера
    if (completed) {
      scenarios[id] = history;
    } else {
      startedScenarios[id] = history;
    }
    dispatch(setAllScenarios({ scenarios, startedScenarios }));

    const userInfo = getState().user.info;
    try {
      if (prev[id]) {
        await updateScriptHistory(id, history, completed);
      } else {
        if (!scen[id]) {
          await ScriptsApi.postScript(id, history, completed);
        }
      }
      const _scenarios = { ...scenario.scenarios };
      const _startedScenarios = { ...scenario.startedScenarios };
      if (completed) {
        delete _startedScenarios[id];
        _scenarios[id] = history;
      } else {
        _startedScenarios[id] = history;
      }
      dispatch(
        setAllScenarios({
          scenarios: _scenarios,
          startedScenarios: _startedScenarios,
        }),
      );
      if (userInfo) {
        removeUnsavedScript(userInfo.id, id);
      }
    } catch (e) {
      if (userInfo) {
        storeUnsavedScript(userInfo.id, id, history);
      }
    }
    dispatch(checkHasUnsaved());
  };

const migrateScenarios = (): AppThunk => async (dispatch, getState) => {
  try {
    const userState = getState().user.info?.state as Optional<LegacyUserState>;
    const scenarios = userState?.scenarios || {};
    const startedScenarios = userState?.startedScenarios || {};
    const response = await ScriptsApi.getScripts();
    const scripts = normalizeScripts(response.data);
    dispatch(setAllScenarios(scripts));
    for (const key in scenarios) {
      const id = key as SceneId;
      await dispatch(saveHistoryAsync(id, scenarios[id]!, true));
    }
    for (const key in startedScenarios) {
      const id = key as SceneId;
      await dispatch(saveHistoryAsync(id, startedScenarios[id]!, false));
    }

    // удалить из user.state, чтобы избежать повторной миграции и уменьшить количество данных
    // if (userState?.scenarios || userState?.startedScenarios) {
    //   const newState = { ...userState };
    //   delete newState.scenarios;
    //   delete newState.startedScenarios;
    //   const userId = getUserId(getState) || '';
    //   const response = await UserApi.setUserState(userId, newState);
    //   dispatch(setInfo(response.data));
    // }
  } catch (e) {
    dispatch(logout());
    alert('Что-то пошло не так при загрузке данных пользователя');
  }
};

export const setInfoAsync =
  (successCallback?: () => void, failedCallback?: () => void): AppThunk =>
  async (dispatch, getState) => {
    try {
      const userId = getUserId(getState) || '';
      const userFiles = await UserApi.setUserFiles();

      if (!userId) throw new Error('Developer error: user id is not found');
      const v = LocalStorage.get(STORE_VERSION);
      if (v !== RELOGIN_VERSION) {
        dispatch(logout());
        if (successCallback) {
          setTimeout(successCallback);
        }
        return;
      }
      const response = await UserApi.getUserInfo(userId);

      dispatch(setUserFiles(userFiles.data));
      dispatch(setInfo(response.data));
      await dispatch(migrateScenarios());
      dispatch(syncUnsavedScripts());
      // dispatch(checkTestsAsync());
      const responseState = response.data.state as Optional<LegacyUserState>;
      if (responseState?.profile) {
        await dispatch(setHeroAsync(responseState.profile));
      }
      if (response.data.variables) {
        dispatch(scenarioSlice.actions.setSceneVariables(response.data.variables));
      }

      if (successCallback) setTimeout(successCallback);
      dispatch(migrateUserAsync());
    } catch (error) {
      dispatch(logout());
      if (failedCallback) setTimeout(failedCallback);
      alert('Что-то пошло не так при загрузке данных пользователя');
    }
  };

export const setUserVideoAsync =
  (link: string, successCallback?: () => void): AppThunk =>
  async (dispatch, getState) => {
    try {
      const userId = getUserId(getState) || '';
      if (!userId) throw new Error('Developer error: user id is not found');

      const response = await UserApi.setUserVideo(userId, link);
      dispatch(setInfo(response.data));
      const variables = getState().user.info?.variables || {};
      dispatch(setVariablesAsync({ ...variables, [VIDEO_REWRITED_KEY]: 'true' }));

      if (successCallback) setTimeout(successCallback);
    } catch (error) {
      dispatch(logout());
      alert('Что-то пошло не так! Попробуйте добавить видеовизитку снова!');
    }
  };

export const setViewedWelcomeProfileAsync = (): AppThunk => async (dispatch, getState) => {
  const userInfo = getState().user.info || { state: {} };
  const userState: UserInfo['state'] = userInfo.state || {};

  const newState: UserInfo['state'] = {
    ...userState,
    isViewedProfile: true,
  };

  dispatch(setStateAsync(newState, true));
  dispatch(
    setInfo({
      ...userInfo,
      state: newState,
    }),
  );
};

export const setChallengeState =
  (challengeName: ChallengeKey, challengeData: ChallengeBackendData): AppThunk =>
  async (dispatch, getState) => {
    const userInfo = getState().user.info || { state: {} };
    const userState: UserInfo['state'] = userInfo.state || {};

    const prevChallenges = { ...(userState.challenges || {}) };
    const prevChallenge = { ...(prevChallenges[challengeName] || {}) };

    const newState: UserInfo['state'] = {
      ...userState,
      challenges: {
        ...prevChallenges,
        [challengeName]: {
          ...prevChallenge,
          ...challengeData,
        },
      },
    };

    dispatch(setStateAsync(newState, true));
    dispatch(
      setInfo({
        ...userInfo,
        state: newState,
      }),
    );
  };

export const setRightThingHistory =
  (rightThingHistory: string): AppThunk =>
  async (dispatch, getState) => {
    const userInfo = getState().user.info || { state: {} };
    const userState: UserInfo['state'] = userInfo.state || {};

    const newState: UserInfo['state'] = {
      ...userState,
      rightThingHistory,
    };

    dispatch(setStateAsync(newState, true));
    dispatch(
      setInfo({
        ...userInfo,
        state: newState,
      }),
    );
  };

export const setHeroAsync =
  (profile: ProfileConfig): AppThunk =>
  async (dispatch, getState) => {
    const userId = getUserId(getState) || '';
    const response = await UserApi.setUserHero(userId, profile);
    dispatch(setInfo(response.data));
  };

export const setOnCallSelected =
  (challengeID: number): AppThunk =>
  async (dispatch, getState) => {
    const userId = getUserId(getState) || '';
    const response = await UserApi.setUserChallengeCall(userId, challengeID);
    if (response.status === 201) {
      dispatch(setChalleng(challengeID));
    }
  };
