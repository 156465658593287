/* eslint-disable max-len */
import {
  Conditions,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../../types/scenario';
import interlocutors from '../../../interlocutors';
import { splitStep } from '../../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT';
const TO_MAP_STEP = 'TO_MAP_STEP';

const person = Interlocutors.GRANDMOTHER;

const TEST_TVOI_KOD_2: TestStep = {
  external_id: 'test-tvoi-kod-junior-210401',
  id: 'Q2_5',
  name: 'Твой код 2',
  nextStep: 'Q7',
  order: [
    {
      description: 'Ты можешь выбрать несколько вариантов из предложенных.',
      subtest: 'T2',
      question: 'Q1',
    },
    //     {
    //       description: `Хоть на уроках я не бывал, но во время дискуссии веду себя так же.

    // А здесь уже чуть посложнее, так как вариантов восемь. Не один, а минимум два нужно отнести к «Точно да» и два к «Определенно нет».`,
    //       subtest: 'T2',
    //       question: 'Q1',
    //     },
    //     {
    //       description:
    //         'Ну все, последний вопрос. Снова восемь вариантов, так что хотя бы на два ответь «Точно да» и на два «Определенно нет».',
    //       subtest: 'T2',
    //       question: 'Q2',
    //     },
  ],
  path: 'test-tvoi-kod-junior-210401.json',
  person,
  type: StepType.test,
};

export const scenario_1_17_0: Scenario = {
  scenarioId: SceneId.scenario_1_17_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.SYSTEM].name,
    person: Interlocutors.SYSTEM,
    text: `Вы подходите к достопримечательности «Природные достояния России» и полной грудью вдыхаете прекрасный свежий воздух! 

          На берегу озера стоит задорная старушка и кормит уток. Вы начинаете с ней разговор...`,
    buttons: ['Начать'],
  },
};

export const scenario_1_17: Scenario = {
  isRequired: true,
  scenarioId: SceneId.scenario_1_17,
  type: ScenarioType.chat,
  data: {
    title: 'Природные достояния России',
    scene_id: 'scenario_1_17',
    startId: 'Q1/0',
    steps: {
      ...splitStep({
        text: `Здравствуй, {{name}}! Меня зовут Бабуля 👵

        Я уже давно живу в парке, перебралась сюда одной из первых. Здесь такой прекрасный, чистый воздух и такие замечательные достопримечательности! 
        
        Только сейчас, вот, много странного происходит, ничего не работает и посетители разъехались. И с озером моим любимым кошмар случился!
        
        Но ничего, ты ведь здесь и поможешь нам! 😊`,
        id: 'Q1',
        type: StepType.question,
        maxAnswers: 1,
        person,
        buttons: [
          {
            id: 'b1',
            text: 'Приятно познакомиться! Но что же тут у вас случилось?',
            nextStep: 'Q2',
          },
        ],
      }),
      Q2: {
        type: StepType.message,
        id: 'Q2',
        person,
        text: `Не торопись, всё по порядку расскажу.

        Я столько про тебя слышала и так ждала встречи! Но вот беда, сегодня по невнимательности надела не те очки и совсем не могу разглядеть тебя 😣
        
        Опиши себя, пожалуйста, чтобы я хоть представила, как ты выглядишь.`,
        nextStep: 'Q3',
      },
      Q3: {
        person,
        id: 'Q3',
        type: StepType.question,
        maxAnswers: 1,
        text: 'Зайди в свой профиль и создай там персонажа 😊',
        callToAction: 'Сделай и возвращайся',
        buttons: [
          {
            id: 'b1',
            text: 'Готово',
            nextStep: 'Q3/1',
          },
        ],
      },
      'Q3/1': {
        type: StepType.system,
        condition: {
          type: Conditions.IS_AVATAR_CREATED,
          variants: ['Q5', 'Q4'],
        },
        id: 'Q3/1',
        person,
        _meta: {},
      },
      Q4: {
        text: 'Что-то я не слышу, попробуй еще раз.',
        nextStep: 'Q3',
        id: 'Q4',
        person,
        type: StepType.message,
      },
      Q5: {
        person,
        id: 'Q5',
        type: StepType.question,
        maxAnswers: 1,
        text: `Ох, ну какой же ты милый ребенок! 😍
        
        Хочу теперь узнать тебя получше.`,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо!',
            nextStep: 'Q6',
          },
        ],
      },
      Q6: {
        person,
        id: 'Q6',
        type: StepType.question,
        maxAnswers: 1,
        text: `Ответь бабуле на пару вопросов, пожалуйста.

        Отвечай «Точно да», если вариант про тебя и «Определенно нет», если на тебя это не похоже. А на случай трудностей есть вариант «Не знаю»`,
        buttons: [
          {
            id: 'b1',
            text: 'Окей ',
            nextStep: 'Q2_5',
          },
        ],
      },

      TEST_TVOI_KOD_2,

      Q7: {
        person,
        id: 'Q7',
        type: StepType.question,
        maxAnswers: 1,
        text: `Какой ты молодец! А я ещё наслышана о твоей помощи другим.

        И мне, честно говоря, она тоже не помешает.`,
        buttons: [
          {
            id: 'b1',
            text: 'Конечно помогу! Что у вас случилось?',
            nextStep: 'Q8/0',
          },
        ],
        nextStep: 'Q8/0',
      },
      ...splitStep({
        id: 'Q8',
        person,
        nextStep: 'Q9',
        type: StepType.message,
        text: `Сейчас расскажу.

        Мы с тобой находимся в моем любимом месте парка. Ты наверняка знаешь, что природа нашей страны уникальна. В России находится 11 объектов Всемирного природного наследия ЮНЕСКО, а значит весь мир стремится их сохранить. 
        
        Это природные заповедники, национальные парки, горные вершины, глубочайшие озера, ледники, бескрайние степи и даже действующие вулканы.`,
      }),
      Q9: {
        id: 'Q9',
        text: `Например, в числе объектов ЮНЕСКО самые большие нетронутые леса Евразии — леса республики Коми. По размеру они даже немного больше Бельгии, только представь! 😲`,
        person,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ого!',
            nextStep: 'Q11',
          },
        ],
      },

      ...splitStep({
        id: 'Q11',
        person,
        text: `
        А ты знаешь об уникальном полуострове Камчатка? Он напоминает торт со свечками: так много там действующих вулканов!🌋
        Ну и, конечно же, нельзя не сказать об Эльбрусе — самой высокой горной вершине России и Европы. Она выше, чем десять Останкинских телебашен! Покорить Эльбрус стремятся альпинисты со всего мира, но это очень опасно. Подготовка к восхождению может занять долгие месяцы, а то и годы! 🤭
        
        Ты бы [[хотел/хотела]] забраться на Эльбрус?`,
        type: StepType.question,
        maxAnswers: 1,
        callToAction: 'Что скажешь?',
        buttons: [
          {
            id: 'b1',
            text: 'Не знаю, не [[уверен/уверена]]',
            nextStep: 'Q12',
          },
          {
            id: 'b2',
            text: 'Да, это ведь очень интересное приключение!',
            nextStep: 'Q13',
          },
        ],
      }),
      Q12: {
        id: 'Q12',
        person,
        type: StepType.message,
        nextStep: 'Q14',
        text: 'Понимаю тебя. Это действительно очень непросто 😊',
      },
      Q13: {
        id: 'Q13',
        person,
        type: StepType.message,
        nextStep: 'Q14',
        text: 'Ох, ты действительно очень [[храбрый/храбрая]]! Вжух не соврал! 😍',
      },
      Q10: {
        id: 'Q10',
        person,
        type: StepType.question,
        text: `А ещё у нас есть озеро Байкал — самое глубокое на Земле и самое большое в России. Если на дно Байкала друг на друга поставить девять зданий МГУ, то шпиль последнего все равно не покажется из воды, настолько оно глубокое! 🤯

        В Байкале живёт множество уникальных рыб и растений и содержатся огромные запасы пресной воды — одна пятая всех мировых запасов!`,
        nextStep: 'Q11/0',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Это же можно весь мир напоить водой! ',
            nextStep: 'Q15',
          },
        ],
      },
      Q14: {
        id: 'Q14',
        person,
        text: `Как раз у копии Байкала мы и находимся. Тут тоже очень прозрачная и чистая вода. Точнее, раньше была. И вот как раз с этим мне и нужна помощь.

        Вода теперь стала… Впрочем, {{name}}, Вжух, попробуйте сами! Зачерпну-ка я вам стаканчик из озера.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А-а-а это н-не опасно?',
            nextStep: 'Q15',
          },
          {
            id: 'b2',
            text: 'Выглядит вроде бы чистой, давайте! ',
            nextStep: 'Q15',
          },
        ],
      },
      Q15: {
        id: 'Q15',
        text: `Она.. она сладкая! И газированная.`,
        person,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, Вжух, мне тоже понравилась. Напоминает ту, которую мне мама недавно покупала',
            nextStep: 'Q16',
          },
        ],
      },
      Q16: {
        id: 'Q16',
        person,
        text: `Вам, ребята, может и вкусно! Но вода в озере не должна быть такой. Озерным рыбам и птицам такая вода навредит😭 

        И стала вода такой буквально недавно. Нужно разобраться, что случилось с озером, пока не поздно.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q17',
            text: 'Ужас! Но как же нам тут помочь, с целым озером?',
          },
        ],
      },
      // TEST_TVOI_VECTOR,
      ...splitStep({
        id: 'Q17',
        text: `Хмм..

        У главного инженера парка, Петрова, есть прибор для анализа воды. Отнеси, пожалуйста, эту банку с водой из озера ему. Вдруг Петров что-нибудь придумает.`,
        person,
        type: StepType.question,
        maxAnswers: 1,
        callToAction: 'Ты же поможешь мне, да?',
        buttons: [
          {
            id: 'b1',
            text: 'Конечно, кладите банку в мой рюкзак!',
            nextStep: EXIT_ID,
          },
          {
            id: 'b2',
            text: 'Нет, извините, сейчас не могу',
            nextStep: EXIT_ID,
          },
        ],
        nextStep: EXIT_ID,
      }),
      [EXIT_ID]: {
        person: Interlocutors.PETROV,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_STEP]: {
        person: Interlocutors.PETROV,
        id: TO_MAP_STEP,
        type: StepType.exit,
      },
    },
  },
};

export const scenario_1_17_1: Scenario = {
  scenarioId: SceneId.scenario_1_17_1,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `{{NAME}}, вот и обещанный сюрприз! 🎉

Мы подготовили твой личный кабинет. Ты можешь создать своего персонажа и посмотреть предметы, которые были собраны в игре! 😺`,
    buttons: ['Далее'],
  },
};

export const scenario_1_17_2: Scenario = {
  scenarioId: SceneId.scenario_1_17_2,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `Ну, чего ты ждешь? Скорее делай персонажа! 😺`,
    buttons: ['Создать персонажа'],
  },
};

export const scenario_1_17_3: Scenario = {
  scenarioId: SceneId.scenario_1_17_3,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.GRANDMOTHER].name,
    person: Interlocutors.GRANDMOTHER,
    text: `{{NAME}}, у меня тут пока все под контролем, не переживай. Помоги пока кому-нибудь еще.`,
    buttons: ['Закрыть'],
  },
};

// const TEST_TVOI_KOD_2: TestStep = {
//   external_id: 'test-tvoi-kod-junior-210401',
//   id: 'Q2_5',
//   name: 'Твой код 2',
//   nextStep: 'Q2_6',
//   order: [
//     {
//       description: 'Ты можешь выбрать несколько вариантов из предложенных.',
//       subtest: 'T2',
//       question: 'Q1',
//     },
//     //     {
//     //       description: `Хоть на уроках я не бывал, но во время дискуссии веду себя так же.

//     // А здесь уже чуть посложнее, так как вариантов восемь. Не один, а минимум два нужно отнести к «Точно да» и два к «Определенно нет».`,
//     //       subtest: 'T2',
//     //       question: 'Q1',
//     //     },
//     //     {
//     //       description:
//     //         'Ну все, последний вопрос. Снова восемь вариантов, так что хотя бы на два ответь «Точно да» и на два «Определенно нет».',
//     //       subtest: 'T2',
//     //       question: 'Q2',
//     //     },
//   ],
//   path: 'test-tvoi-kod-junior-210401.json',
//   person,
//   type: StepType.test,
// };

// const TK_TEST: TestStep = {
//   id: 'TK_TEST',
//   person: Interlocutors.SYSTEM,
//   type: StepType.test,
//   external_id: 'test-tvoi-kod-junior',
//   path: 'test-tvoi-kod-junior-210401.json',
//   name: 'Твой Код 1/1',
//   order: [
//     {
//       description: `Сейчас будет четыре варианта. Хотя бы один из них нужно отметить «Точно да» и один «Определенно нет». А вот и первый вопрос:`,
//       subtest: 'T1',
//       question: 'Q1',
//     },
//     {
//       description:
//         'Отлично! Идем дальше. В этом вопросе, как и в прошлом, нужно выбрать четыре варианта ответа. Хотя бы один надо отметить «Точно да» и один «Определенно нет».',
//       subtest: 'T1',
//       question: 'Q2',
//     },
//     {
//       description:
//         'Ничего сложного, правда? Продолжай отвечать так же: из четырех вариантов хотя бы один «Точно да» и один «Определенно нет»',
//       subtest: 'T1',
//       question: 'Q3',
//     },
//     {
//       description:
//         'Очень приятно узнать тебя поближе. Следующий вопрос и все те же условия: из четырех вариантов минимум один «Точно да» и один «Определенно нет».',
//       subtest: 'T1',
//       question: 'Q4',
//     },
//     {
//       description:
//         'О, абсолютно с тобой согласен! Я бы тоже выбрал такие же варианты. Продолжим с вопросами. Этот ничем не отличается от предыдущих: из четырех вариантов хотя бы один «Точно да» и один «Определенно нет».',
//       subtest: 'T1',
//       question: 'Q5',
//     },
//     {
//       description:
//         'Ты очень интересный человек! Здесь отвечай аналогично: из четырех минимум один вариант «Точно да» и один «Определенно нет».',
//       subtest: 'T1',
//       question: 'Q6',
//     },
//     {
//       description:
//         'А я вот никогда не бывал на физкультуре, котам не очень-то это положено. Вопросов осталось совсем немного. Сейчас тоже выбирай хотя бы один «Точно да» и один «Определенно нет». Вариантов все так же четыре.',
//       subtest: 'T1',
//       question: 'Q7',
//     },
//     {
//       description: `Хоть на уроках я не бывал, но во время дискуссии веду себя так же.

// А здесь уже чуть посложнее, так как вариантов восемь. Не один, а минимум два нужно отнести к «Точно да» и два к «Определенно нет».`,
//       subtest: 'T2',
//       question: 'Q1',
//     },
//     {
//       description:
//         'Ну все, последний вопрос. Снова восемь вариантов, так что хотя бы на два ответь «Точно да» и на два «Определенно нет».',
//       subtest: 'T2',
//       question: 'Q2',
//     },
//   ],
//   nextStep: 'Q11',
// };
