/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  Step,
  StepType,
  TestStep,
} from '../../../types/scenario';
import interlocutors from '../../interlocutors';
import { splitStep } from '../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT';
const TO_MAP_STEP = 'TO_MAP_STEP';

const person = Interlocutors.TEACHER;

/**
 * заглушка после 0 акта
 */
// export const scenario_1_7_0: Scenario = {
//   scenarioId: SceneId.scenario_1_7_0,
//   type: ScenarioType.modal,
//   data: {
//     closable: false,
//     title: 'Тишина',
//     person: Interlocutors.SYSTEM,
//     text: `В парке все спят. Возвращайся в парк 15 апреля. А пока не забывай делать уроки и побольше проводи времени на свежем воздухе.`,
//     buttons: [],
//   },
// };

// export const scenario_1_7_0: Scenario = {
//   scenarioId: SceneId.scenario_1_7_0,
//   type: ScenarioType.modal,
//   data: {
//     closable: true,
//     title: 'МГУ',
//     person: Interlocutors.SYSTEM,
//     text: `Вы подходите к макету главного здания Московского государственного университета. Несмотря на то что это копия, студенты здесь тоже постигают науки.

// У входа в университет стоит задумчивая  женщина в очках. Вы начинаете с ней разговор...`,
//     buttons: ['Начать'],
//   },
// };

export const scenario_1_7_0: Scenario = {
  scenarioId: SceneId.scenario_1_7_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Домик бабули',
    text: 'Ты находишься в домике бабули. Возможно, стоит осмотреться здесь — может быть ты найдешь какие-то подсказки.',
    buttons: ['Закрыть'],
  },
};

const ABOUT_MGU: Record<string, Step> = {
  Q2: {
    type: StepType.message,
    id: 'Q2',
    person,
    text: `Мы находимся в копии Московского государственного университета имени М. В. Ломоносова, если коротко: МГУ. Это один из старейших и крупнейших университетов России и расположен он в столице нашей родины.`,
    nextStep: 'Q2_2',
  },
  Q2_2: {
    type: StepType.message,
    id: 'Q2_2',
    person,
    text: `Впервые МГУ открыл двери для студентов в 1755 году, это было 269 лет назад, только подумать, как давно! И представь себе, в МГУ учится больше 40 000 студентов, такой огромный вуз!`,
    nextStep: 'Q2_3',
  },
  Q2_3: {
    type: StepType.message,
    id: 'Q2_3',
    person,
    text: `А сейчас здесь никого нет... 🙁`,
    nextStep: 'Q2_4',
  },
  Q2_4: {
    type: StepType.message,
    id: 'Q2_4',
    person,
    text: `Я рада, что ты нам поможешь! 😊`,
    nextStep: 'Q3_1',
  },
};

const TEST_TVOI_VECTOR: TestStep = {
  maxAnswers: 2,
  external_id: 'test-tvoi-vektor-junior-210401',
  id: 'Q6',
  name: 'Твой вектор 1/3',
  nextStep: 'Q7',
  order: [
    {
      description:
        'С помощью твоих ответов мы определим, какие направления будут главными для изучения в университете. Отвечай честно и выбирай только то, что тебе близко.',
      subtest: 'T1',
      question: 'Q39',
    },
    {
      description: 'Теперь давай выберем, что студенты будут узнавать на дополнительных занятиях.',
      subtest: 'T1',
      question: 'Q16',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q17',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q11',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q10',
    },
    {
      description: 'Ну и последний вопрос просто о тебе.',
      subtest: 'T1',
      question: 'Q21',
    },
  ],
  path: 'test-tvoi-vektor-junior-210401.json',
  person,
  type: StepType.test,
};

export const scenario_1_7: Scenario = {
  scenarioId: SceneId.scenario_1_7,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'МГУ',
    scene_id: 'scenario_1_7',
    startId: 'Q1/0',
    steps: {
      ...splitStep({
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q1',
        person,
        text: `Добрый день! А я тебя уже знаю. Ты {{NAME}}, верно? А меня зовут Светлана Васильевна, я учительница 👩‍🏫 

Как и ты я здесь недавно. Меня пригласили обучать обитателей парка наукам, но почти все куда-то делись.

Работать в «Большой перемене» — большая честь и радость для меня! Ведь я с детства мечтала быть учителем. И вот моя мечта сбылась! Я обожаю познавать мир и рассказывать ученикам про науки, способные изменить жизнь человечества к лучшему. Ведь наука — это так увлекательно! 😍

Но сейчас мне немного грустно, потому что после нашествия тумана больше некого учить 🙁

Думаю, с твоей помощью мы разберемся, что произошло, вернем учеников в парк и выясним, почему из парка исчезли идеи.`,
        callToAction: 'Выбери вариант',
        buttons: [
          {
            id: 'b1',
            text: 'Приятно познакомиться, Светлана Васильевна!',
            nextStep: 'Q2',
          },
          {
            id: 'b2',
            text: 'Приятно познакомиться, но я не сюда хотел прийти, извините…',
            nextStep: TO_MAP_STEP,
          },
        ],
      }),
      ...ABOUT_MGU,
      Q3_1: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q3_1',
        person: Interlocutors.TEACHER,
        text: 'Видишь ли, люди не могут получить образование и найти работу 😣',
        callToAction: 'Поможешь нам?',
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q4',
            text: 'Я помогу, не волнуйтесь',
          },
          {
            id: 'b2',
            nextStep: TO_MAP_STEP,
            text: 'Извините, я пока не могу',
          },
        ],
      },
      Q4: {
        type: StepType.message,
        id: 'Q4',
        person,
        text: 'Спасибо большое, ты замечательный человек! 😊',
        nextStep: 'Q5',
      },
      Q5: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q5',
        person,
        text: 'Тебе нужно выбрать не более двух вариантов ответа из предложенных.',
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо',
            nextStep: 'Q6',
          },
        ],
      },
      Q6: TEST_TVOI_VECTOR,
      ...splitStep({
        person,
        type: StepType.message,
        id: 'Q7',
        nextStep: 'Q8',
        text: `Какие замечательно получилось! Ты, наверное, даже не догадываешься, что очень помог нам! Ведь на основе твоих ответов мы сможем восстановить учебную программу в МГУ, план которой был утерян в тумане. 

А теперь в нашем университете станет еще интереснее и ученики начнут постигать науки с еще большим рвением!

За твою помощь я хочу вручить тебе эту лестницу! Она символизирует путь на вершину знаний, который проходят студенты МГУ.`,
      }),
      Q8: {
        person,
        type: StepType.message,
        id: 'Q8',
        nextStep: EXIT_ID,
        text: 'Береги ее, она пригодится тебе в самом конце. Я отдам лестницу Вжуху, пусть пока полежит у него.',
      },
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_STEP]: {
        person,
        id: TO_MAP_STEP,
        type: StepType.to_map_step,
      },
    },
  },
};

export const scenario_1_7_1: Scenario = {
  scenarioId: SceneId.scenario_1_7_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.TEACHER].name,
    person: Interlocutors.TEACHER,
    text: '{{NAME}}, я тут пока сама справлюсь, спасибо!',
    buttons: ['Закрыть'],
  },
};
