import styled from 'styled-components';

export const StyledItemsBar = styled.div``;

export const StyledItemsBarList = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  /* -26px + 20px; */
  margin-top: -6px;
  margin-left: -26px;
`;
