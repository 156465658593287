import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import QueryString from 'query-string';
import Auth from '../../shared/utils/Auth';
import UserApi from '../../../fifthGrade/api/user';
import { routes } from '../../shared/constants/routes';

export const useDefineGrade = () => {
  const history = useHistory();
  const [grade, setGrade] = useState<number | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [queryClassesChanged, setQueryClassesChanged] = useState<QueryString.ParsedQuery<string>>();

  useEffect(() => {
    const defineGradeAsync = async () => {
      const query = QueryString.parse(history.location.search);
      setQueryClassesChanged(query);
      const localToken = localStorage.getItem('STORE_USER_TOKEN');
      const token =
        query['access_token'] || (localToken && JSON.parse(localToken as string)) || undefined;

      if (!Array.isArray(token) && token) {
        try {
          const userId = Auth.parseToken(token)?.sub?.toString();
          if (!userId) return;
          const response = await UserApi.getUserInfo(userId, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const grade = response.data.grade;
          setGrade(grade);
        } catch {
          localStorage.clear();
          history.replace(routes.login);
        }
      }
      setIsLoading(false);
    };
    defineGradeAsync();
  }, []);

  return {
    queryClassesChanged,
    grade,
    isLoading,
  } as const;
};
