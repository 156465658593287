/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const oboutMoscow_1_1_0: Scenario = {
  scenarioId: SceneId.oboutMoscow_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.ENTREPRENEUR,
    title: 'Москва-Сити',
    text: `Алед кеволеч я .чынас нас тувоз янем !щиравот йывон шан еж отэ ,а

    ялетаминирпдерп юиссефорп ябес ялд ларбыв я умотэоп !ондогу огеч ясьтибод онжом модурт мынтсеч отч ,юатичс и оволс ужред адгесв я
    
    
    Юавытабараз мотэ ан охолпен и ыткеорп йедюл ялд еынзелоп юадзос ьрепет а ,ыт как мокиньлокш лыб от-адгок ежот я !йатобараз — ьтичулоп от-отч ьшечох :пицнирп йыннензиж йынвалг йом`,
    buttons: ['Записал! Теперь проигрываем обратно'],
  },
};
