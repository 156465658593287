import styled from 'styled-components';
import { ProfilePageProps } from './ProfilePageTypes';

import { BREAKPOINT_PHONE, BREAKPOINT_TABLET } from '../../../common/shared/constants/constants';

export const StyledProfilePageWrapper = styled.div<ProfilePageProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin: 10px 0 0 0;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    padding: 15px 14px;
  }

  @media screen and (min-width: ${BREAKPOINT_TABLET}) {
    flex-direction: row;
    align-items: flex-start;

    & > * + * {
      margin: 0 0 0 10px;
    }
  }
`;

export const StyledProfilePage = styled.div<ProfilePageProps>`
  width: 100%;

  & > * + * {
    margin: 10px 0 0 0;
  }
`;
