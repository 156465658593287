/* eslint-disable max-len */
import { Interlocutors, Step, StepType, SystemStepType } from '../../../types/scenario';
import { splitStep } from '../../../utils/ScenarioUtils';
import { images } from './images';

const person = Interlocutors.ENTREPRENEUR;

export const LOGIC_TEST_PART2: Record<string, Step> = {
  ...splitStep({
    person,
    id: 'LOGIC_TEST_PART2_1',
    text: `Смотри, условия достаточно простые. Тебе нужно решить 10 логических задачек за 25 минут. Какие-то из них будут проще, какие-то посложнее и, возможно, даже понадобится ручка и лист бумаги, чтобы что-то вычислять.

Важный момент: принять участие в решении этих головоломок моего друга можно только один раз. Повторно вернуться к ним будет нельзя.`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Поехали!',
        nextStep: 'LOGIC_TEST_PART2_4',
      },
    ],
  }),
  LOGIC_TEST_PART2_4: {
    person,
    nextStep: 'LOGIC_TEST_PART2_Q1',
    id: 'LOGIC_TEST_PART2_4',
    type: StepType.system,
    _meta: {
      type: SystemStepType.limitedTimeGroup,
      time: 25 * 60,
      timeoutStep: 'LOGIC_TEST_PART2_TIMEOUT',
      exitStep: 'Q6',
    },
  },

  LOGIC_TEST_PART2_TIMEOUT: {
    person,
    id: 'LOGIC_TEST_PART2_TIMEOUT',
    type: StepType.message,
    text: `{{NAME}}, увы, время закончилось 😔`,
    nextStep: 'Q6',
  },

  LOGIC_TEST_PART2_Q1: {
    person,
    id: 'LOGIC_TEST_PART2_Q1',
    text: 'Найди зашифрованное слово',
    image: { uri: images.logic_2_1, title: '' },
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q1',
    nextStep: 'LOGIC_TEST_PART2_Q2',
  },
  LOGIC_TEST_PART2_Q2: {
    person,
    id: 'LOGIC_TEST_PART2_Q2',
    text: 'Напиши истинное математическое равенство, где вместо букв стоят цифры: ОДИН + ОДИН = МНОГО',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q2',
    nextStep: 'LOGIC_TEST_PART2_Q3',
  },
  LOGIC_TEST_PART2_Q3: {
    person,
    id: 'LOGIC_TEST_PART2_Q3',
    text: 'Напиши истинное математическое равенство, где вместо букв стоят цифры: ЧАЙ : АЙ = АЙ',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q3',
    nextStep: 'LOGIC_TEST_PART2_Q4',
  },
  LOGIC_TEST_PART2_Q4: {
    person,
    id: 'LOGIC_TEST_PART2_Q4',
    text: 'Расположи числа 5, 7, 2, 9 на месте фигур (квадрата, звездочки, треугольника и круга) так, чтобы стрелки шли от большего числа к меньшему:',
    image: { uri: images.logic_2_2, title: '' },
    type: StepType.message,
    nextStep: 'LOGIC_TEST_PART2_Q4_1',
  },
  LOGIC_TEST_PART2_Q4_1: {
    person,
    id: 'LOGIC_TEST_PART2_Q4_1',
    text: 'Что на месте квадрата?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q4_1',
    nextStep: 'LOGIC_TEST_PART2_Q4_2',
  },
  LOGIC_TEST_PART2_Q4_2: {
    person,
    id: 'LOGIC_TEST_PART2_Q4_2',
    text: 'На месте звездочки?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q4_2',
    nextStep: 'LOGIC_TEST_PART2_Q4_3',
  },
  LOGIC_TEST_PART2_Q4_3: {
    person,
    id: 'LOGIC_TEST_PART2_Q4_3',
    text: 'На месте треугольника?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q4_3',
    nextStep: 'LOGIC_TEST_PART2_Q4_4',
  },
  LOGIC_TEST_PART2_Q4_4: {
    person,
    id: 'LOGIC_TEST_PART2_Q4_4',
    text: 'На месте круга?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q4_4',
    nextStep: 'LOGIC_TEST_PART2_Q5',
  },
  LOGIC_TEST_PART2_Q5: {
    person,
    id: 'LOGIC_TEST_PART2_Q5',
    variable: 'LOGIC_TEST_PART2_Q5',
    type: StepType.text_question,
    image: { uri: images.logic_2_3, title: '' },
    text: `На одной чашке весов лежат 6 одинаковых яблок и 3 одинаковые груши, на другой чашке 3 таких же яблока и 5 таких же груш. Весы находятся в равновесии. Что легче: яблоко или груша?`,
    nextStep: 'LOGIC_TEST_PART2_Q6',
  },

  LOGIC_TEST_PART2_Q6: {
    person,
    id: 'LOGIC_TEST_PART2_Q6',
    text: 'Дети бежали из класса в буфет. Андрей бежал быстрее, чем Ваня и Боря. Ваня прибежал раньше Гоши, но позже Бори.',
    type: StepType.message,
    nextStep: 'LOGIC_TEST_PART2_Q6_1',
  },
  LOGIC_TEST_PART2_Q6_1: {
    person,
    id: 'LOGIC_TEST_PART2_Q6_1',
    text: 'Напиши, кто прибежал в буфет первым?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q6_1',
    nextStep: 'LOGIC_TEST_PART2_Q6_2',
  },
  LOGIC_TEST_PART2_Q6_2: {
    person,
    id: 'LOGIC_TEST_PART2_Q6_2',
    text: 'Напиши, кто прибежал последним?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q6_2',
    nextStep: 'LOGIC_TEST_PART2_Q7',
  },

  LOGIC_TEST_PART2_Q7: {
    person,
    id: 'LOGIC_TEST_PART2_Q7',
    text: 'В бассейне семеро пловцов с разноцветными шапочками выясняли, кто старше. Они узнали, что пловец в белой шапочке — старше всех, в серой — старше пятерых, в зеленой — старше четверых, пловец в красной — старше двоих, но младше пловца в оранжевой, пловец в фиолетовой — старше, чем в желтой. Напиши всех слева направо по старшинству (справа самый старший).',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q7',
    nextStep: 'LOGIC_TEST_PART2_Q8',
  },

  LOGIC_TEST_PART2_Q8: {
    person,
    id: 'LOGIC_TEST_PART2_Q8',
    text: 'Бабушка испекла для троих внуков рогалики и оставила их на столе. Коля забежал перекусить первым. Сосчитал все рогалики, взял свою долю и убежал. Аня зашла в дом позже. Она не знала, что Коля уже взял рогалики, сосчитала их и, разделив на троих, взяла свою долю. Третьим пришел Гена, который тоже разделил остаток выпечки на троих и взял свою долю. На столе осталось 8 рогаликов. Сколько рогаликов из восьми оставшихся должен съесть каждый, чтобы в результате все съели поровну?',
    type: StepType.message,
    nextStep: 'LOGIC_TEST_PART2_Q8_1',
  },
  LOGIC_TEST_PART2_Q8_1: {
    person,
    id: 'LOGIC_TEST_PART2_Q8_1',
    text: 'Сколько рогаликов должен съесть Гена?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q8_1',
    nextStep: 'LOGIC_TEST_PART2_Q8_2',
  },

  LOGIC_TEST_PART2_Q8_2: {
    person,
    id: 'LOGIC_TEST_PART2_Q8_2',
    text: 'Сколько рогаликов должна съесть Аня?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q8_2',
    nextStep: 'LOGIC_TEST_PART2_Q8_3',
  },
  LOGIC_TEST_PART2_Q8_3: {
    person,
    id: 'LOGIC_TEST_PART2_Q8_3',
    text: 'Сколько рогаликов должен съесть Коля?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q8_3',
    nextStep: 'LOGIC_TEST_PART2_Q9',
  },

  LOGIC_TEST_PART2_Q9: {
    person,
    id: 'LOGIC_TEST_PART2_Q9',
    text: 'В семье пять человек: муж, жена, их сын, сестра мужа и отец жены. Их профессии: инженер, юрист, слесарь, учитель и экономист. Известно, что юрист и учитель — не кровные родственники. Слесарь младше экономиста, и оба играют в футбол за сборную своего завода. Инженер моложе учителя, но старше жены своего брата. Назовите профессии каждого.',
    type: StepType.message,
    nextStep: 'LOGIC_TEST_PART2_Q9_1',
  },
  LOGIC_TEST_PART2_Q9_1: {
    person,
    id: 'LOGIC_TEST_PART2_Q9_1',
    text: 'Какая профессия у мужа?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q9_1',
    nextStep: 'LOGIC_TEST_PART2_Q9_2',
  },
  LOGIC_TEST_PART2_Q9_2: {
    person,
    id: 'LOGIC_TEST_PART2_Q9_2',
    text: 'Какая профессия у жены?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q9_2',
    nextStep: 'LOGIC_TEST_PART2_Q9_3',
  },
  LOGIC_TEST_PART2_Q9_3: {
    person,
    id: 'LOGIC_TEST_PART2_Q9_3',
    text: 'Какая профессия у сына?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q9_3',
    nextStep: 'LOGIC_TEST_PART2_Q9_4',
  },
  LOGIC_TEST_PART2_Q9_4: {
    person,
    id: 'LOGIC_TEST_PART2_Q9_4',
    text: 'Какая профессия у сестры мужа?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q9_4',
    nextStep: 'LOGIC_TEST_PART2_Q9_5',
  },
  LOGIC_TEST_PART2_Q9_5: {
    person,
    id: 'LOGIC_TEST_PART2_Q9_5',
    text: 'Какая профессия у отца жены?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q9_5',
    nextStep: 'LOGIC_TEST_PART2_Q10',
  },

  LOGIC_TEST_PART2_Q10: {
    person,
    id: 'LOGIC_TEST_PART2_Q10',
    text: 'В классе 35 учеников. Каждый из них пользуется хотя бы одним из видов городского транспорта: метро, автобусом и троллейбусом. Всеми тремя видами транспорта пользуются 6 учеников, метро и автобусом — 15 учеников, метро и троллейбусом — 13 учеников, троллейбусом и автобусом — 9 учеников. Сколько учеников пользуются только одним видом транспорта?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART2_Q10',
    nextStep: 'Q6',
  },
};
