/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const scenario_3_7_i: Scenario = {
  scenarioId: SceneId.scenario_3_7_i,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Херсонес',
    text: `Вот ты и в домике у бабули. Ты открываешь ноутбук и получаешь сообщение от Гарина: «{{NAME}}, всё готово, мы всё сделали: я полностью стёр «Интеллект 2.0» и восстановил резервные системы парка, сигнал, который вы с Вжухом перенаправили с ракеты, теперь обеспечивает связь. Вы молодцы, все неприятности позади. Я готов запустить резерв, но есть одно но…».`,
    buttons: ['Спросить у Гарина, в чем проблема.'],
  },
};

export const scenario_3_7: Scenario = {
  scenarioId: SceneId.scenario_3_7,
  type: ScenarioType.chat,
  data: {
    title: 'Херсонес',
    scene_id: 'scenario_3_7',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.SYSTEM,
        id: 'Q1',
        text: `«Видишь ли, «Интеллект 2.0» нарушил всю логику работы парка, но благодаря этим багам мы стали понимать речь животных. Если сейчас я включу бэкап, боюсь, ты перестанешь понимать Вжуха», — отвечает Гарин.

        «Гарин, дай нам час», — подумав, отвечаешь ты.
        «Конечно!» — с пониманием отвечает Гарин.
        `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Поговорить со Вжухом',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        // ТЕСТ НА АЛЬТРУИЗМ 18
        person: Interlocutors.CAT,
        id: 'Q2',
        text: `«Я всё слышал. Ну что ж, может ты и перестанешь меня понимать, но мы, коты, всегда понимаем человека. 
        Единственное, пока ты меня понимаешь, у меня есть одна просьба. Может быть, ты согласишься её исполнить?».
        `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Конечно, Вжух!',
            nextStep: 'Q3',
          },
          {
            id: 'b2',
            text: 'Вжух, давай поскорее восстановим парк, не до просьб сейчас.',
            nextStep: 'Q5',
          },
        ],
      },
      Q3: {
        // ТЕСТ НА АЛЬТРУИЗМ 19
        person: Interlocutors.CAT,
        id: 'Q3',
        text: `«{{NAME}}, спасибо тебе, ты настоящий человек! Дело в том, что, пока мы с тобой спасали парк, я изрядно проголодался — сможешь поймать мне вкусную рыбу на ужин?»`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Поймать Вжуху большую ставриду.',
            nextStep: 'Q3_1',
          },
          {
            id: 'b2',
            text: 'Поймать Вжуху маленьких барабулек.',
            nextStep: 'Q3_1',
          },
          {
            id: 'b3',
            text: 'Попросить рыбаков поймать Вжуху что-нибудь.',
            nextStep: 'Q4',
          },
        ],
      },
      Q3_1: {
        person: Interlocutors.CAT,
        id: 'Q3_1',
        text: `«{{NAME}}, ммм, спасибо! Как же я люблю эту рыбу!», -  Вжух жадно уплетает пойманную тобой и поджаренную на костре рыбу. 

        «В благодарность я расскажу тебе тайну понтийской зубастой рыбы. На мысу Херсонес, на одном из каменных пляжей, полого уходящих в воду, есть таинственные рисунки. Один из них — огромная каменная зубастая рыба. Если встать там на закате и загадать желание, то рыба исполнит его, абсолютно любое!».
        `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Поблагодарить Вжуха и отправиться домой',
            nextStep: 'Q4',
          },
        ],
      },
      Q4: {
        person: Interlocutors.SYSTEM,
        id: 'Q4',
        text: `Ты пишешь Гарину, что вы готовы. С небольшой грустью смотришь ты на сопящего наевшегося Вжуха, свернувшегося клубочком, и тоже ложишься спать.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А что же было дальше?',
            nextStep: 'Q5',
          },
        ],
      },
      Q5: {
        person: Interlocutors.SYSTEM,
        id: 'Q5',
        text: `Ты просыпаешься от аромата свежеиспеченных блинчиков — бабуля зовет завтракать. Рядом облизывает лапки на утреннем солнце Вжух.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Поговорить с Вжухом',
            nextStep: 'Q6',
          },
        ],
      },
      Q6: {
        person: Interlocutors.CAT,
        id: 'Q6',
        text: `Мяяяяу`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Закончить игру',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.SYSTEM,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.SYSTEM,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
