/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_moscowCity_1_1_1: Scenario = {
  scenarioId: SceneId.stg2_moscowCity_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Москва-сити',
    text: `Приближаясь к Москва-Сити, вы увидели, что рядом с башнями никого нет, хотя должен быть разгар рабочего дня.

    Подойдя ближе, вы заметили Сан Саныча у входа в главный небоскреб. Он поглядывал на часы и держал в правой руке диктофон.`,
    buttons: ['Начать'],
  },
};
