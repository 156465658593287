/* eslint-disable max-len */
import {
  Conditions,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../scenario';
import interlocutors from '../../interlocutors';
import { splitStep } from '../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT';
const TO_MAP_ID = 'TO_MAP_ID';

const person = Interlocutors.TEACHER;
const TEST_TVOI_VECTOR_3: TestStep = {
  maxAnswers: 2,
  external_id: 'test-tvoi-vektor-junior-210401',
  id: 'TEST_TVOI_VECTOR_3',
  name: 'Твой вектор 3/3',
  nextStep: 'Q9',
  order: [
    {
      description: '',
      subtest: 'T1',
      question: 'Q4',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q5',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q6',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q38',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q18',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q29',
    },
  ],
  path: 'test-tvoi-vektor-junior-210401.json',
  person,
  type: StepType.test,
};

export const scenario_1_25_0: Scenario = {
  scenarioId: SceneId.scenario_1_25_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: interlocutors[Interlocutors.PETROV].name,
    person: Interlocutors.PETROV,
    text: `{{NAME}}, наконец-то я тебя нашел! Это снова происходит, опять такой же ТУМАН, как и в прошлый раз. Скорее, нам нужно где-то укрыться 😱`,
    buttons: ['Далее'],
  },
};

export const scenario_1_25_popup: Scenario = {
  scenarioId: SceneId.scenario_1_25_popup,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: interlocutors[Interlocutors.SYSTEM].name,
    person: Interlocutors.PETROV,
    text: `Вы с Петровым находите укрытие и прячетесь от тумана.`,
    buttons: ['Начать'],
  },
};

export const scenario_1_25: Scenario = {
  scenarioId: SceneId.scenario_1_25,
  type: ScenarioType.chat,
  data: {
    title: 'Парк «Большая перемена»',
    scene_id: 'scenario_1_25',
    startId: 'Q1',
    steps: {
      Q1: {
        text: 'Фуф, вроде в этот раз пронесло 😓 {{NAME}}, смотри, а вот и Сан Саныч!',
        id: 'Q1',
        type: StepType.message,
        person: Interlocutors.PETROV,
        nextStep: 'Q2',
      },
      Q2: {
        id: 'Q2',
        type: StepType.message,
        text: 'Вот вы где, у нас беда! Все в порядке, но я нигде не могу найти Светлану Васильевну 😱',
        person: Interlocutors.ENTREPRENEUR,
        nextStep: 'Q3',
      },
      Q3: {
        id: 'Q3',
        type: StepType.message,
        text: 'Как же так, нам обязательно нужно ее найти! Где ее видели в последний раз? 😨',
        person: Interlocutors.PETROV,
        nextStep: 'Q4',
      },
      Q4: {
        id: 'Q4',
        type: StepType.message,
        person: Interlocutors.ENTREPRENEUR,
        nextStep: 'Q5',
        text: 'У библиотеки МГУ 😣',
      },
      Q5: {
        id: 'Q5',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Конечно, побежали скорее!',
            nextStep: EXIT_ID,
          },
          {
            id: 'b2',
            text: 'Ох, что-то мне страшно',
            nextStep: 'Q6',
          },
        ],
        person: Interlocutors.PETROV,
        nextStep: 'Q6',
        text: 'Отлично, оттуда и начнем. Пойдем по ее следу, да, {{NAME}}? 🙁',
      },
      Q6: {
        id: 'Q6',
        person: Interlocutors.PETROV,
        text: 'Не переживай, мы вместе и ничего не случится 😉',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо, побежали',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.PETROV,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};

export const scenario_1_25_1_popup: Scenario = {
  scenarioId: SceneId.scenario_1_25_popup,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: interlocutors[Interlocutors.SYSTEM].name,
    person: Interlocutors.SYSTEM,
    text: `Ты заходишь в МГУ и слышишь, как из ямы в полу доносится знакомый голос Светланы Васильевны…`,
    buttons: ['Продолжить'],
  },
};

export const scenario_1_25_1: Scenario = {
  scenarioId: SceneId.scenario_1_25_1,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'МГУ',
    scene_id: 'scenario_1_25_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: '{{NAME}}? Ах, я сама не понимаю, как могла попасть в эту ужасную мокрую яму! {{NAME}}, ты можешь позвать кого-нибудь на помощь?',
        id: 'Q1',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.TEACHER,
        buttons: [
          {
            text: 'Зачем кого-то звать, когда у меня как раз есть веревка! Я [[сам/сама]] Вас вытащу!',
            id: 'b1',
            nextStep: 'Q3',
          },
          {
            text: 'Хочу приберечь веревку на будущее, мы с Петровым и голыми руками сможем Вас вытащить! Хоть и придется постараться...',
            id: 'b2',
            nextStep: 'Q2/0',
          },
        ],
      },
      ...splitStep({
        id: 'Q2',
        type: StepType.question,
        maxAnswers: 1,
        text: `Кажется, без веревочной лестницы здесь не обойтись... 

Я как раз видел подходящую у Сан Саныча в Олимпийском парке! Скорее беги к нему и раздобудь лестницу, а я пока сбегаю за теплым пледом для Светланы Васильевны. В этой яме совсем сыро и холодно!`,
        person: Interlocutors.PETROV,
        buttons: [
          {
            id: 'b1',
            text: 'Я пулей!',
            nextStep: 'COND',
          },
          {
            id: 'b2',
            text: 'Кажется, это займет время. Лучше все-таки воспользуемся веревкой…',
            nextStep: 'Q4',
          },
        ],
      }),
      COND: {
        id: 'COND',
        person: Interlocutors.SYSTEM,
        type: StepType.system,
        condition: {
          type: Conditions.IS_HELP_WITH_ENTREPRENEUR,
          variants: ['Q3-2', TO_MAP_ID],
        },
        _meta: {},
      },
      Q3: {
        id: 'Q3',
        person: Interlocutors.SYSTEM,
        type: StepType.system,
        nextStep: 'Q5/0',
        text: 'Вы спустили Светлане Васильевне веревку. Замерзшая учительница выбралась из ямы.',
        _meta: {},
      },
      'Q3-1': {
        id: 'Q3-1',
        person: Interlocutors.SYSTEM,
        type: StepType.system,
        nextStep: 'Q5/0',
        _meta: {},
        text: 'Вы с Петровым спустили Светлане Васильевне веревку. Замерзшая учительница выбралась из ямы.',
      },
      'Q3-2': {
        id: 'Q3-2',
        person: Interlocutors.SYSTEM,
        type: StepType.system,
        nextStep: 'Q5/0',
        _meta: {},
        text: `Вы с Петровым и Сан Санычем спустили Светлане Васильевне веревочную лестницу. Замерзшая учительница выбралась из ямы и закуталась в одеяло и плед.

Сан Саныч попрощался со всеми и поспешил по своим делам.`,
      },
      Q4: {
        id: 'Q4',
        text: 'Так у тебя есть веревка? Она ведь тоже подойдет!',
        person: Interlocutors.PETROV,
        type: StepType.message,
        nextStep: 'Q3-1',
      },
      ...splitStep({
        text: `Вот спасибо, мои вы дорогие! 🥰

Без вас я бы просидела здесь еще очень долго и даже не представляю, как выбиралась бы!

Предлагаю отметить чудесное спасение чаем с домашним вареньем 😊`,
        id: 'Q5',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.TEACHER,
        buttons: [
          {
            id: 'b1',
            text: 'Да, чай сейчас будет очень кстати',
            nextStep: 'Q6',
          },
        ],
      }),
      Q6: {
        id: 'Q6',
        type: StepType.question,
        maxAnswers: 1,
        text: '{{NAME}}, знаешь, а ведь у меня еще остались для тебя вопросы от Сан Саныча. Ты не против, если я их задам по пути?',
        person: Interlocutors.TEACHER,
        buttons: [
          {
            id: 'b1',
            text: 'Да, спрашивайте',
            nextStep: 'Q7',
          },
        ],
      },
      Q7: {
        id: 'Q7',
        person: Interlocutors.TEACHER,
        text: 'Отлично! Выбирай не более двух вариантов ответа из предложенных.',
        type: StepType.message,
        nextStep: TEST_TVOI_VECTOR_3.id,
      },
      TEST_TVOI_VECTOR_3,

      ...splitStep({
        id: 'Q9',
        person: Interlocutors.TEACHER,
        type: StepType.message,
        nextStep: EXIT_ID,
        text: `Спасибо за твоё терпение! 😊 Отдыхай сегодня от таких непростых заданий, а завтра нажимай на кнопку “Перейти в парк” и возвращайся - мы все будем тебя ждать!`,
      }),
      [EXIT_ID]: {
        person: Interlocutors.PETROV,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.PETROV,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};

export const scenario_1_25_2: Scenario = {
  scenarioId: SceneId.scenario_1_25_2,
  type: ScenarioType.chat,
  data: {
    title: 'Олимпийский парк в Сочи',
    scene_id: 'scenario_1_25_2',
    startId: 'Q1',
    steps: {
      Q1: {
        text: '{{NAME}}, слышал тебе нужна веревочная лестница для спасения Светланы Васильевны? Да, у меня как раз есть подходящая. Я уже ее взял, побежали скорее вызволять нашу учительницу!',
        id: 'Q1',
        type: StepType.message,
        person: Interlocutors.ENTREPRENEUR,
        nextStep: 'Q2/0',
      },
      ...splitStep({
        id: 'Q2',
        type: StepType.question,
        maxAnswers: 1,
        callToAction: 'Попробуем?',
        text: `Пока идем, помоги мне, пожалуйста, с одним делом. Я узнал о конкурсе, победа в котором позволит быстрее привести в порядок «Большую перемену». Там деньги дают за первое место и они бы нам очень пригодились. 

Дело в том, что я уже выполнил все задания, осталось ответить только на один вопрос.`,
        person: Interlocutors.ENTREPRENEUR,
        buttons: [
          {
            id: 'b1',
            text: 'Да, давайте. Все равно до МГУ путь неблизкий',
            nextStep: 'Q3/0',
          },
        ],
      }),
      ...splitStep({
        id: 'Q3',
        person: Interlocutors.ENTREPRENEUR,
        type: StepType.question,
        maxAnswers: 1,
        text: `Я знал, что на тебя можно положиться! 

Как называется полуостров на северо-востоке России, который похож на торт со свечами из-за обилия действующих вулканов?`,
        buttons: [
          {
            id: 'b1',
            text: 'Ямал',
            nextStep: 'Q3-1',
          },
          {
            id: 'b2',
            text: 'Кольский',
            nextStep: 'Q3-2',
          },
          {
            id: 'b3',
            text: 'Камчатка',
            nextStep: 'Q4/0',
          },
        ],
      }),
      'Q3-1': {
        id: 'Q3-1',
        text: 'Нет, Ямал расположен тоже на севере России, но западнее и там совсем нет вулканов. Подумай еще.',
        callToAction: 'Выбери вариант',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.ENTREPRENEUR,
        buttons: [
          {
            id: 'b1',
            text: 'Кольский',
            nextStep: 'Q3-2',
          },
          {
            id: 'b2',
            text: 'Камчатка',
            nextStep: 'Q4/0',
          },
        ],
      },
      'Q3-2': {
        id: 'Q3-2',
        text: 'От Кольского полуострова до нужного нам пришлось бы проплыть почти все побережье Северного Ледовитого океана. К сожалению, на Кольском не обнаружено ни одного вулкана.',
        callToAction: 'Выбери вариант',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.ENTREPRENEUR,
        buttons: [
          {
            id: 'b1',
            text: 'Ямал',
            nextStep: 'Q3-1',
          },
          {
            id: 'b2',
            text: 'Камчатка',
            nextStep: 'Q4/0',
          },
        ],
      },
      ...splitStep({
        id: 'Q4',
        text: `Ну точно же! Камчатка! Мне ведь Бабуля об этом рассказывала когда-то! Теперь призовые деньги будут наши и «Большая перемена» станет даже лучше, чем прежде!`,
        type: StepType.question,
        person: Interlocutors.ENTREPRENEUR,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Это отлично! Я [[рад/рада]]!',
            nextStep: EXIT_ID,
          },
          {
            id: 'b2',
            text: 'Ну все, бежим скорее!',
            nextStep: EXIT_ID,
          },
        ],
      }),
      [EXIT_ID]: {
        person: Interlocutors.PETROV,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};

export const scenario_1_25_4: Scenario = {
  scenarioId: SceneId.scenario_1_25_4,
  type: ScenarioType.chat,
  data: {
    title: 'МГУ',
    scene_id: 'scenario_1_25_4',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Приступим к вопросам? 😊`,
        id: 'Q1',
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.TEACHER,
        buttons: [
          {
            id: 'b1',
            text: 'Да',
            nextStep: 'Q7',
          },
        ],
      },
      Q7: {
        id: 'Q7',
        person: Interlocutors.TEACHER,
        text: 'Отлично! Выбирай не более двух вариантов ответа из предложенных.',
        type: StepType.message,
        nextStep: TEST_TVOI_VECTOR_3.id,
      },
      TEST_TVOI_VECTOR_3,
      ...splitStep({
        id: 'Q9',
        person: Interlocutors.TEACHER,
        type: StepType.message,
        nextStep: EXIT_ID,
        text: `Спасибо за твою терпеливость! 😊

Что-то холодает, тебе не кажется? Пойдем же скорее домой греться ароматным чаем с вареньем.`,
      }),
      [EXIT_ID]: {
        person: Interlocutors.TEACHER,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
