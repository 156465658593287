/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../scenario';
import interlocutors from '../../interlocutors';

export const scenario_1_26_1: Scenario = {
  scenarioId: SceneId.scenario_1_26_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.ENTREPRENEUR].name,
    person: Interlocutors.ENTREPRENEUR,
    text: 'А, {{NAME}}! Я решил повнимательнее осмотреть объекты и убедиться, что в парке больше ничего не случилось из-за этого нового тумана. Твоя помощь пока не нужна.',
    buttons: ['Закрыть'],
  },
};
export const scenario_1_26_2: Scenario = {
  scenarioId: SceneId.scenario_1_26_2,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.GRANDMOTHER].name,
    person: Interlocutors.GRANDMOTHER,
    text: `{{NAME}}, я слышала, как ты [[помог/помогла]] Светлане Васильевне! Ты очень [[чуткий/чуткая]] и [[отзывчивый/отзывчивая]]! Нам с тобой очень повезло!

Я помогаю Сан Санычу осматривать парк после нашествия тумана и сама тут справлюсь, спасибо!`,
    buttons: ['Закрыть'],
  },
};
export const scenario_1_26_3: Scenario = {
  scenarioId: SceneId.scenario_1_26_3,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.PETROV].name,
    person: Interlocutors.PETROV,
    text: `{{NAME}}, ну ты представляешь, я все еще вожусь с этим электронным замком! Ни в какую не поддается! Не хочу тебя задерживать, ты и так здорово всем нам [[помог/помогла]]`,
    buttons: ['Закрыть'],
  },
};
export const scenario_1_26_4: Scenario = {
  scenarioId: SceneId.scenario_1_26_4,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `Ах, это ты, {{NAME}}! Я испугался тумана и спрятался на стадионе. 

Но сейчас уже вроде все закончилось, так что осмотрю-ка я Олимпийский парк. Проверю, все ли в порядке.`,
    buttons: ['Закрыть'],
  },
};
