import Api from '../utils/Api';

import { AxiosPromise } from 'axios';
import { ApiScript, ApiScriptsResponse } from '../types/api/user';
import { Step } from '../resources/scenario';
import { isDebug } from '../../common/shared/constants/constants';

class ScriptsApi {
  static getScripts(): AxiosPromise<ApiScriptsResponse> {
    return Api.call(`/scripts`);
  }
  static postScript(id: string, history: Step[], completed: boolean): AxiosPromise<ApiScript> {
    const data = {
      completed,
      history,
      script_id: id,
    };
    return Api.call(`/scripts`, {
      data,
      method: 'post',
    });
  }
  static patchScript(id: string, history: Step[], completed: boolean): AxiosPromise<ApiScript> {
    const data = {
      completed,
      history,
      script_id: id,
    };
    return Api.call(`/scripts/${id}`, {
      data,
      method: 'patch',
    });
  }

  static deleteScript(id: string) {
    if (isDebug) {
      return Api.call(`/scripts/${id}`, {
        data: {},
        method: 'delete',
      });
    }
  }
}

export default ScriptsApi;
