/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../../types/scenario';
import interlocutors from '../../../interlocutors';

const EXIT_ID = 'EXIT';
const TO_MAP_STEP = 'TO_MAP_STEP';
const TO_MAP_STEP_SAVE = 'TO_MAP_STEP_SAVE';

const person = Interlocutors.ENTREPRENEUR;

export const scenario_1_18_0: Scenario = {
  scenarioId: SceneId.scenario_1_18_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Олимпийский парк в Сочи',
    person: Interlocutors.SYSTEM,
    text: `Зажимаешь уши и отправляешься исследовать парк.`,
    buttons: ['Начать'],
  },
};

const TEST_TVOI_VIBOR_1: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'Q6',
  name: 'Твой выбор 1/5',
  nextStep: 'Q7',
  order: [
    {
      description: '',
      subtest: 'T3',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person: Interlocutors.SYSTEM,
  type: StepType.test,
};

export const scenario_1_18: Scenario = {
  scenarioId: SceneId.scenario_1_18,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Олимпийский парк в Сочи',
    scene_id: 'scenario_1_18',
    startId: 'Q1',
    steps: {
      Q1: {
        id: 'Q1',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        text: 'Нам нужно найти источник звука — надо осмотреть все объекты. Давай начнём с Поющего фонтана? Думаю, он вполне может издавать звук.',
        buttons: [
          {
            id: 'b1',
            text: 'Осмотреть Поющий фонтан',
            nextStep: 'Q1_1',
          },
        ],
      },
      Q1_1: {
        id: 'Q1_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: 'Вы подходите к поющим фонтанам — кажется, страшный визг доносится именно отсюда. Находиться здесь невыносимо.',
        buttons: [
          {
            id: 'b1',
            text: 'Обойти фонтан вокруг',
            nextStep: 'Q1_1_1',
          },
        ],
      },
      Q1_1_1: {
        id: 'Q1_1_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: 'Вы обходите фонтан, но не находите ничего: ни кнопок, ни рычагов. Но вот, что странно: в фонтане нет воды. Ну что ж, может быть теперь в Планетарий?',
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться в планетарий «Сириус»',
            nextStep: 'Q1_2',
          },
        ],
      },
      Q1_2: {
        id: 'Q1_2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: 'Вы заходите в планетарий. Странно, кажется, что потолка совсем нет. Звезды застыли на небе. Ты находишь пульт управления. На нем множество рычагов и кнопок. «А что, если его включить?» — думаешь ты. Ты нажимаешь зелёную кнопку и слышишь шум процессора. Кажется, пульт заработал, но что я ещё могу сделать?',
        callToAction: 'Планетарий Сириус',
        buttons: [
          {
            id: 'b1',
            text: 'Выбрать созвездие «Большой пёс»',
            nextStep: 'Q1_2_1',
          },
          {
            id: 'b2',
            text: 'Выбрать созвездие «Орион»',
            nextStep: 'Q1_2_2',
          },
        ],
      },
      Q1_2_1: {
        id: 'Q1_2_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: `Планеты на потолке начинают вращаться, карта звёздного неба сдвигается. 
          Слышен голос диктора «Большой Пёс — созвездие южного полушария неба, самая яркая звезда — Сириус. Расположено к юго-востоку от Ориона; частично лежит в Млечном Пути. На территории России наблюдается полностью в южных и центральных районах и частично — в северных».
          `,
        callToAction: 'Выбрать созвездие «Большой пёс»',
        buttons: [
          {
            id: 'b1',
            text: 'Узнать больше про Сириус',
            nextStep: 'Q1_2_1_1',
          },
        ],
      },
      Q1_2_1_1: {
        id: 'Q1_2_1_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: `Ты слышишь голос диктора: «Сириус — звезда созвездия Большого Пса. Ярчайшая звезда ночного неба; её светимость в 25 раз превышает светимость Солнца. Многие древние культуры придавали особое значение звезде Сириус. Во времена Раннего царства жители долины реки Нил поклонялись ей, как богине Сопдет. Сириус часто изображали как Исиду, стоящую в небесной лодке». 

          На карте звёздного неба появляется лодка, изображенная в водоёме, напоминающем поющий фонтан. На пульте открывается небольшой люк — внутри него кнопка, а над ней изображена лодка. 
          
          «Вжух»,кажется, мы что-то нашли»!
          `,
        callToAction: 'Узнать больше про Сириус',
        buttons: [
          {
            id: 'b1',
            text: 'Нажать кнопку',
            nextStep: 'Q2',
          },
        ],
      },
      Q1_2_2: {
        id: 'Q1_2_2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: `Планеты на потолке начинают вращаться, карта звёздного неба сдвигается. 
          Слышен голос диктора «Созвездие Орион — это одно из самых заметных и узнаваемых созвездий на ночном небе. Его самые яркие звезды — красная Бетельгейзе и бело-голубой Ригель».
          `,
        callToAction: 'Выбрать созвездие Орион',
        buttons: [
          {
            id: 'b1',
            text: 'Выбрать созвездие «Большой пёс»',
            nextStep: 'Q1_2_1',
          },
        ],
      },
      Q2: {
        id: 'Q2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: `Ты нажимаешь кнопку, лодка на изображении начинает плыть. 
          «Мне кажется, мы открыли какой-то механизм. Если он на воде, то думаю, нам стоит осмотреть фонтан».
          `,
        callToAction: 'Нажать кнопку',
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться к Поющему фонтану',
            nextStep: 'Q2_1',
          },
        ],
      },
      Q2_1: {
        id: 'Q2_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: `Ты подходишь к фонтану и видишь, что он наполнился водой, а посередине плавает лодка с кем-то на борту. Вода закручивается в воронку и лодка не может выплыть. Человек машет руками и что-то говорит, но из-за пронзительного визга фонтанов ничего не слышно. 
          «Думаю, нам нужно понять, как отключить этот звук. А почему бы нам не отправиться к колесу обозрения — наверняка с него видно весь парк»?.
          `,
        callToAction: 'Поющий фонтан',
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться на Колесо обозрения',
            nextStep: 'Q1_5',
          },
        ],
      },
      Q1_3: {
        id: 'Q1_3',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: `Проход на стадион закрыт. Тут пока делать нечего.`,
        callToAction: 'Стадион «Фишт»',
        buttons: [
          {
            id: 'b1',
            text: 'Поющий фонтан',
            nextStep: 'Q2_1',
          },
          {
            id: 'b2',
            text: 'Инновационный центр «Сириус»',
            nextStep: 'Q1_4',
          },
          {
            id: 'b3',
            text: 'Колесо обозрения',
            nextStep: 'Q1_5',
          },
        ],
      },
      Q1_4: {
        id: 'Q1_4',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: `Проход в центр закрыт. Тут пока делать нечего.`,
        callToAction: 'Инновационный центр «Сириус»',
        buttons: [
          {
            id: 'b1',
            text: 'Поющий фонтан',
            nextStep: 'Q2_1',
          },
          {
            id: 'b2',
            text: 'Колесо обозрения',
            nextStep: 'Q1_5',
          },
        ],
      },
      Q1_5: {
        id: 'Q1_5',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: `Вы подходите к колесу. Оно идет против часовой стрелки с огромной скоростью. 
          «Нам нужно это исправить», — говорит Вжух.
          `,
        callToAction: 'Колесо обозрения',
        buttons: [
          {
            id: 'b1',
            text: 'Осмотреть щиток управления колесом.',
            nextStep: 'Q1_5_1',
          },
        ],
      },
      Q1_5_1: {
        id: 'Q1_5_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: `На щитке написано «Колесо Времени». 
          Под надписью расположены часы со стрелками. Стрелки вращаются в обратную сторону. 
          
          Внизу есть два рычага: 
          «Стоп» 
          «Завод»
          `,
        callToAction: 'Осмотреть щиток управления колесом',
        buttons: [
          {
            id: 'b1',
            text: 'Нажать рычаг «Стоп»',
            nextStep: 'Q1_5_1_1',
          },
          {
            id: 'b2',
            text: 'Прокрутить механизм «Завод»',
            nextStep: 'Q1_5_1_2',
          },
        ],
      },
      Q1_5_1_1: {
        id: 'Q1_5_1_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: `Ты нажимаешь на рычаг «Стоп». Колесо останавливается. Но останавливается не только оно: птицы замерли в полете, все звуки стихли, Вжух застыл, смахивая муху с носа. 
          «Кажется, я остановил/а время»
          `,
        callToAction: 'Нажать рычаг «Стоп»',
        buttons: [
          {
            id: 'b1',
            text: 'Прокрутить механизм «Завод»',
            nextStep: 'Q1_5_1_2',
          },
        ],
      },
      Q1_5_1_2: {
        id: 'Q1_5_1_2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: `С большим усилием, навалившись всем телом, ты прокручиваешь тяжелую ручку механизма завода 12 раз до упора. Слышится щелчок и вдруг все вновь оживает: птицы полетели дальше, Вжух смешно плюхнулся на зад, и в воздухе разлилась приятная мелодия. 
          «Вжух, ужасный вой — его больше нет! Кажется, эта музыка раздаётся от фонтана».
          `,
        callToAction: 'Прокрутить механизм «Завод»',
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться к Поющему фонтану',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        id: 'Q3',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: `Да, это действительно фонтаны. Вокруг разносится нежная мелодия. Водная гладь ровная и спокойная. У кромки фонтана в лодке сидит растерянный человек и машет тебе рукой.`,
        callToAction: 'Отправиться к Поющему фонтану',
        buttons: [
          {
            id: 'b1',
            text: 'Поговорить с человеком из лодки',
            nextStep: 'Q3_1',
          },
        ],
      },
      Q3_1: {
        id: 'Q3_1',
        person: Interlocutors.ENTREPRENEUR,
        type: StepType.question,
        maxAnswers: 1,
        text: `Здравствуйте! Спасибо, что помогли мне выбраться. Совсем не понимаю, что произошло и как я здесь оказался. 
          На нём халат с инициалами А.А. Иванов. Под именем располагается круглый логотип, состоящий из точек, и надпись Sirius. Кажется, он работал в научном центре.
          `,
        callToAction: 'Поговорить с человеком из лодки',
        buttons: [
          {
            id: 'b1',
            text: 'Давайте,я отведу вас в научный центр «Сириус», возможно, мы сможем что-то узнать',
            nextStep: 'Q4',
          },
        ],
      },
      Q4: {
        id: 'Q4',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: `Вы подходите к научному центру. Человек из лодки достаёт пропуск и прикладывает к считывателю двери. 
          «Добро пожаловать в Инновационный научно-технологический центр «Сириус», — говорит приятный женский голос. 
          «Для того, чтобы попасть в кампус, пройдите авторизацию».
          `,
        callToAction: 'Поговорить с человеком из лодки',
        buttons: [
          {
            id: 'b1',
            text: 'Пройти авторизацию',
            nextStep: 'Q5',
          },
        ],
      },
      Q5: {
        id: 'Q5',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: `Приветствую в системе авторизации «Сириус». В нашем центре трудятся сотни специалистов разных специализаций: кто-то изучает технологии, а кто-то жизнь. 

          Сейчас мы определим, какое направление подходит именно тебе. Начнём?
          `,
        callToAction: 'Поговорить с человеком из лодки',
        buttons: [
          {
            id: 'b1',
            text: 'Ок',
            nextStep: 'Q6',
          },
        ],
      },
      Q6: TEST_TVOI_VIBOR_1, // ТЕСТ: Твой выбор (на интересы) 1
      Q7: {
        id: 'Q7',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: `{{NAME}} поздравляю с прохождением теста. Возьмите ваш личный пароль. Вы можете использовать его для входа в инновационный центр «Сириус», а также для пользования ноутбуком.`,
        buttons: [
          {
            id: 'b1',
            text: 'Узнать, кто же такой человек из лодки',
            nextStep: 'Q8',
          },
        ],
      },
      Q8: {
        id: 'Q8',
        person: Interlocutors.ENTREPRENEUR,
        type: StepType.question,
        maxAnswers: 1,
        text: `{{NAME}}, спасибо! Судя по досье в системе центра, меня зовут Александр Александрович Иванов и я предприниматель. Здесь занимался стартапом под названием «Интеллект 2.0». Думаю, я пойду в кабинет и разберусь в своих файлах. А ты иди, ещё раз спасибо!`,
        buttons: [
          {
            id: 'b1',
            text: 'Использовать пароль для ноутбука',
            nextStep: 'Q9',
          },
        ],
      },
      Q9: {
        id: 'Q9',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: `Ты вводишь пароль в ноутбук. Бинго! Он разблокирован.`,
        buttons: [
          {
            id: 'b1',
            text: 'Прочитать сообщение',
            nextStep: 'Q11',
          },
        ],
      },
      Q11: {
        id: 'Q11',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        text: `В твоём мессенджере всплывает сообщение:
          Петров: «Найди Гарина, теряю память». Пользователь был в сети 24 часа назад.
          `,
        buttons: [
          {
            id: 'b1',
            text: 'Выйти на карту',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_STEP]: {
        person,
        id: TO_MAP_STEP,
        type: StepType.to_map_step,
      },
      [TO_MAP_STEP_SAVE]: {
        person,
        id: TO_MAP_STEP_SAVE,
        type: StepType.to_map_step,
      },
    },
  },
};

// export const scenario_1_18: Scenario = {
//   scenarioId: SceneId.scenario_1_18,
//   type: ScenarioType.chat,
//   isRequired: true,
//   data: {
//     title: 'Олимпийский парк в Сочи',
//     scene_id: 'scenario_1_18',
//     startId: 'Q1/0',
//     steps: {
//       ...splitStep({
//         text: `О, здравствуй, {{NAME}}! С башней все так же отлично. Да и первый репортаж мы наконец-то подготовили. Уже пустили в эфир, всем нравится 😊

// А теперь перед нами стоит НОВАЯ ЗАДАЧА — нужно навести здесь порядок после этого странного тумана. Нам же совсем негде заниматься спортом, а спорт — это здоровье 😔`,
//         id: 'Q1',
//         type: StepType.question,
//         maxAnswers: 1,
//         person,
//         buttons: [
//           {
//             id: 'b1',
//             nextStep: 'Q2',
//             text: 'Да, конечно',
//           },
//           {
//             id: 'b2',
//             nextStep: TO_MAP_STEP,
//             text: 'Нет, извините, сейчас не могу',
//           },
//         ],
//       }),
//       Q2: {
//         id: 'Q2',
//         type: StepType.message,
//         nextStep: 'Q3',
//         text:
//           'Мы сейчас в копии Олимпийского парка. Настоящий находится на берегу Черного моря в городе Сочи. ',
//         person,
//       },
//       Q3: {
//         id: 'Q3',
//         person,
//         type: StepType.question,
//         maxAnswers: 1,
//         text:
//           'Это очень особенный город, ведь там есть и море, и горы! Летом можно загорать и купаться, а зимой хоть и светит яркое солнце, но снег в горах не тает, и можно кататься на лыжах и сноуборде! 😍',
//         buttons: [
//           {
//             id: 'b1',
//             text: 'А я там [[был/была]]',
//             nextStep: 'Q4',
//           },
//           {
//             id: 'b2',
//             text: 'Наверное, там очень круто отдыхать!',
//             nextStep: 'Q7',
//           },
//         ],
//       },
//       Q4: {
//         id: 'Q4',
//         text: 'Как здорово! Тебе там понравилось?',
//         person,
//         type: StepType.question,
//         maxAnswers: 1,
//         buttons: [
//           {
//             id: 'b1',
//             nextStep: 'Q5',
//             text: 'Да, было классно!',
//           },
//           {
//             id: 'b2',
//             nextStep: 'Q6',
//             text: 'Да как-то не очень',
//           },
//         ],
//       },
//       Q5: {
//         id: 'Q5',
//         person,
//         nextStep: 'Q8',
//         text: 'Там действительно здорово отдыхается 😊',
//         type: StepType.message,
//       },
//       Q6: {
//         id: 'Q6',
//         person,
//         type: StepType.message,
//         nextStep: 'Q8',
//         text: 'Ну что ж, и такое бывает 🤷‍️',
//       },
//       Q7: {
//         id: 'Q7',
//         person,
//         type: StepType.message,
//         nextStep: 'Q8',
//         text: 'Да, там определенно стоит побывать! 😊',
//       },
//       Q8: {
//         id: 'Q8',
//         text:
//           'В общем, именно из-за такого классного климата Сочи был выбран главным городом зимних Олимпийских игр 2014 года.',
//         nextStep: 'Q9',
//         person,
//         type: StepType.message,
//       },
//       Q9: {
//         id: 'Q9',
//         text:
//           'Самое знаменитое сооружение парка — стадион «Фишт», он назван в честь горной вершины Кавказского хребта и построен в форме ракушки. Здесь были проведены потрясающие церемонии, посвященные началу и окончанию игр. Стадион настолько большой, что может вместить в себя население небольшого города!',
//         nextStep: 'Q10',
//         type: StepType.message,
//         person,
//       },
//       Q10: {
//         id: 'Q10',
//         text:
//           'Еще в сочинском олимпийском парке есть арены для соревнований по фигурному катанию, конькобежному спорту, хоккею, керлингу и многим другим видам спорта.',
//         type: StepType.question,
//         person,
//         maxAnswers: 1,
//         buttons: [
//           {
//             id: 'b1',
//             nextStep: 'Q11/0',
//             text: 'А где и когда появились Олимпийские игры?',
//           },
//           {
//             id: 'b2',
//             nextStep: 'Q15',
//             text: 'Спасибо за интересную информацию, но с чем же надо помочь?',
//           },
//         ],
//       },
//       ...splitStep({
//         id: 'Q11',
//         text: `Первые Олимпийские игры прошли много веков назад в Древней Греции, а участвовать в них могли только мужчины.

// Сейчас же игры стали главным спортивным состязанием мира! Множество мальчиков и девочек с детства тренируются по много часов в день, чтобы в будущем стать олимпийскими чемпионами.`,
//         type: StepType.question,
//         maxAnswers: 1,
//         person,
//         buttons: [
//           {
//             id: 'b1',
//             text: 'Я тоже занимаюсь спортом!',
//             nextStep: 'Q12',
//           },
//           {
//             id: 'b2',
//             text: 'Ничего себе сколько этим играм уже лет!',
//             nextStep: 'Q14',
//           },
//         ],
//       }),
//       Q12: {
//         id: 'Q12',
//         text: 'А я сразу понял, что ты [[спортсмен/спортсменка]]! 😍 Какой вид спорта?',
//         type: StepType.text_question,
//         variable: 'SPORT',
//         nextStep: 'Q13',
//         person,
//       },
//       Q13: {
//         id: 'Q13',
//         text:
//           'Уважаю спортивных людей! Ты [[большой/большая]] молодец! Как я уже сказал, спорт — это здоровье! 💪',
//         person,
//         type: StepType.message,
//         nextStep: 'Q15',
//       },
//       Q14: {
//         id: 'Q14',
//         nextStep: 'Q15',
//         person,
//         type: StepType.message,
//         text:
//           'Да! Знаешь, спорт не зря всегда был в почете, ведь как я уже сказал, спорт — это здоровье! 💪',
//       },
//       Q15: {
//         person,
//         id: 'Q15',
//         type: StepType.question,
//         maxAnswers: 1,
//         text: 'Ну, вернемся к нашей задаче?',
//         buttons: [
//           {
//             id: 'b1',
//             text: 'Да, давайте',
//             nextStep: 'Q16',
//           },
//           {
//             id: 'b2',
//             text: 'Мне уже нужно идти, давайте попозже',
//             nextStep: TO_MAP_STEP_SAVE,
//           },
//         ],
//       },
//       ...splitStep({
//         text: `Понимаешь, туман и тут наделал дел. Раньше мы проводили различные турниры, состязания, а теперь не то что соревнования не устроить, даже утреннюю пробежку не совершить 😔

// Нужно тут все прибрать и отстроить. Я выделю тебе помощников.`,
//         id: 'Q16',
//         person,
//         type: StepType.message,
//         nextStep: 'Q17',
//       }),
//       Q17: {
//         id: 'Q17',
//         text:
//           'Выбери, чем бы ты хотел заняться, но не больше пяти вариантов, а все остальное я поручу другим.',
//         type: StepType.question,
//         maxAnswers: 1,
//         person,
//         buttons: [
//           {
//             id: 'b1',
//             text: 'Хорошо',
//             nextStep: TEST_TVOI_VIBOR_3.id,
//           },
//         ],
//       },
//       TEST_TVOI_VIBOR_3,
//       Q19: {
//         id: 'Q19',
//         text:
//           'Отлично! Спасибо! Благодаря этим ответам, мы подберем занятие, которое придется тебе по вкусу. Ведь делать то, что действительно нравится вдвойне приятно 😊',
//         person,
//         nextStep: EXIT_ID,
//         type: StepType.question,
//         maxAnswers: 1,
//         buttons: [
//           {
//             id: 'b1',
//             text: 'И вам спасибо! С радостью займусь!',
//             nextStep: 'Q20/0',
//           },
//         ],
//       },
//       ...splitStep({
//         id: 'Q20',
//         text: `О! Я вижу, ты закончил и потрясающе справился с поставленной задачей!

// Теперь обитатели «Большой Перемены» снова смогут вести активный образ жизни. Мы все тебе очень благодарны! 😊`,
//         nextStep: 'Q21/0',
//         person,
//         type: StepType.message,
//       }),
//       ...splitStep({
//         id: 'Q21',
//         text: `А что это за блестящая штука у тебя в руках? Это же ЛУПА, которую я выронил здесь еще до тумана во время утренней пробежки!

// За твою помощь я ее тебе ДАРЮ! Она может пригодиться тебе в будущем 😉`,
//         nextStep: EXIT_ID,
//         person,
//         type: StepType.message,
//       }),
//       [EXIT_ID]: {
//         person,
//         id: EXIT_ID,
//         type: StepType.exit,
//       },
//       [TO_MAP_STEP]: {
//         person,
//         id: TO_MAP_STEP,
//         type: StepType.to_map_step,
//       },
//       [TO_MAP_STEP_SAVE]: {
//         person,
//         id: TO_MAP_STEP_SAVE,
//         type: StepType.to_map_step,
//       },
//     },
//   },
// };

export const scenario_1_18_1: Scenario = {
  scenarioId: SceneId.scenario_1_18_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.ENTREPRENEUR].name,
    person: Interlocutors.ENTREPRENEUR,
    text: `{{NAME}}, у нас перерыв на зарядку. Если хочешь, тоже разомнись пока.`,
    buttons: ['Закрыть'],
  },
};
