/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_hermitage_1_1_1: Scenario = {
  scenarioId: SceneId.stg2_hermitage_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Эрмитаж',
    text: `У ворот Эрмитажа никого нет. 

    Зайдя внутрь, вы видите Женю, которая неспешно беседует с кошкой Мартой и ещё несколькими котами. Ни одного их «Мяу» вы до сих пор не можете понять. Малевича, переводчика с кошачьего, не видно.
    
    Вы приветливо машете Жене.`,
    buttons: ['Начать'],
  },
};
