import { useAppUpdate } from '@r-youth/app-update';
import React, { useState } from 'react';
import useViewport from 'src/sixthGrade/hooks/useViewport';
import styled from 'styled-components';
import * as moment from 'moment-timezone';

const SOCKET_URL =
  process.env.NODE_ENV === 'development'
    ? 'wss://test.bolshajaperemena.ru'
    : 'wss://bolshayaperemena.online';

const StyledRoot = styled('div')({
  position: 'sticky',
  background: '#FFE074',
  width: '100%',
  padding: '8px',
  textAlign: 'center',
  zIndex: 1,
  height: '36px',
});

export const UpdatePlanned = () => {
  const { isMobile } = useViewport();
  const [date, setDate] = useState('');

  useAppUpdate({
    appType: 'game',
    socket: {
      endPoint: `${SOCKET_URL}:4010/notification`,
    },
    onUpdatePlanned: (date) => {
      setDate(date);
    },
    onUpdateStarted: () => {
      setTimeout(() => {
        window.location.href = window.location.origin;
      }, 1000);
    },
  });

  if (!date) {
    return null;
  }

  const formattedDate = moment.utc(date).tz('Europe/Moscow').format('HH:mm');

  return (
    <StyledRoot>
      {!isMobile
        ? `Внимание! Сегодня с ${formattedDate} на сайте будут
        проводиться технические работы. Мы работаем, чтобы наш сайт стал лучше.
        Спасибо за понимание.`
        : `В ${formattedDate} планируется обновление сайта`}
    </StyledRoot>
  );
};
