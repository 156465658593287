import { PointsOfInterest } from '../../types';
import * as checks from '../../hooks/scenarioHelpers/stageChecks';
import { ScenarioState } from '../../redux/scenario';
import { ID, to } from '../../hooks/scenarioHelpers/ID';

type PlaceOpened = Partial<Record<string, (scenarioState: ScenarioState) => boolean>>;

const placeOpened: PlaceOpened = {
  [PointsOfInterest.mgu]: (scenarioState) => checks.notWelcomeTest(scenarioState), //  работает

  [PointsOfInterest.lake]: (scenarioState) => Boolean(scenarioState.scenarios?.[to[ID.lake]]), // работает

  [PointsOfInterest.innopolice]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.innopolice]]), // работает

  [PointsOfInterest.hermitage]: (scenarioState) =>
    Boolean(scenarioState.scenarios[to[ID.hermitage]]), // работает

  [PointsOfInterest.hersones]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.baggage]]), // работает

  [PointsOfInterest.sochi]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.olympicPark]]),

  [PointsOfInterest.moscowCity]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.moscowCityDialogue]]),

  [PointsOfInterest.patriot]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.moscowCityThirdAct]]),

  [PointsOfInterest.arch]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.stage2_act1_arch]]),

  [PointsOfInterest.kurgan]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.stage2_act1_arch]]),

  [PointsOfInterest.space]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.stage2_act2_cosmodrom]]),

  [PointsOfInterest.ostankino]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.stage2_act2_ostankino]]),

  [PointsOfInterest.bridge]: (scenarioState) =>
    Boolean(scenarioState.scenarios?.[to[ID.stage2_act3_bridge]]),
};

export const checkPlaceOpened = (placeName: string, scenarioState: ScenarioState) => {
  return placeOpened[placeName]?.(scenarioState);
};
