import styled from 'styled-components';

import bg from '../../../assets/images/pageloader_bg.jpg';
import { BREAKPOINT_PHONE } from '../../../common/shared/constants/constants';

export const PageLoaderStyled = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: auto;
  min-height: 100%;
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  color: white;
`;

export const LoaderInner = styled.div`
  display: flex;
  position: relative;
`;

export const LoaderLogo = styled.img`
  display: block;
  width: 450px;
  max-width: 450px;
  height: 100%;

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    max-width: 130px;
  }
`;
