/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg3_spaceAlienShip_1_1: Scenario = {
  scenarioId: SceneId.stg3_spaceAlienShip_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Космодром',
    scene_id: 'stg3_spaceAlienShip_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Чтобы связаться с нашим кораблём — поверни ручку вот так. Учти, что когда ты будешь говорить с ними, ты не сможешь слышать меня.`,
        id: 'Q1',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо, Квилл. Мы готовы. ',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        text: `Когда вы повернули ручку, радиоприёмник зашипел. Вы поприветствовали пришельцев в микрофон, но ответа не последовало.

        Вы повторили, громче. Шипение из приёмника прекратилось, наступила тишина. Через несколько секунд вы услышали низкий голос из динамика.`,
        id: 'Q2',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q3',
      },

      Q3: {
        text: `Приветствуем тебя, землянин. Благодарим за помощь представителю нашего вида. Он сообщил, что желает попасть обратно на наш корабль. Для этого рекомендуем использовать вашу примитивную ракету. `,
        id: 'Q3',
        person: Interlocutors.ALIENSHIP,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Здравствуйте! Мы не можем запустить ракету из-за ветра. ',
            nextStep: 'Q4',
          },
        ],
      },

      Q4: {
        text: `Между прочим, из-за ветра, который устроили вы! И Квилл не будет к вам возвращаться до тех пор, пока вы не исправите всё, что натворили в нашем парке!`,
        id: 'Q4',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Вжух, вдруг ты их обидишь! ',
            nextStep: 'Q5',
          },
        ],
      },

      Q5: {
        text: `Насколько нам известно, трансформации, случившиеся с вашим парком, стали результатом неосторожного обращения Квилла с нашим лучом-преобразователем. Более того, с нашего корабля вместе с Квиллом пропал элемент питания этого луча.

        Поэтому любые ваши требования возмутительны и не будут выполнены. Мы лишь ослабим ветра для обеспечения взлёта вашей ракеты.`,
        id: 'Q5',
        person: Interlocutors.ALIENSHIP,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Квилл, ты же говорил, что не трогал эти лучи!',
            nextStep: 'Q6',
          },
        ],
      },

      Q6: {
        text: `Сильное зеленое свечение пробилось сквозь облака и осветило весь космодром. Вдруг вы почувствовали, что ветер ослабевает. Через минуту на космодроме наступил полный штиль.

        Вы переключаете волну на радиоприёмнике, чтобы снова слышать Квилла.`,
        id: 'Q6',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q7',
      },

      Q7: {
        text: `Простите меня, пожалуйста! Мне было стыдно признаться, что я испортил ваш замечательный парк, зная, что мои сородичи откажутся возвращать всё назад.

        Я хотел перенестись на Землю, но перепутал луч трансформации и телепортации. Прежде чем разобраться, как они работают, я трансформировал множество достопримечательностей парка.`,
        id: 'Q7',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А мы понадеялись, что получится всё исправить! И ты теперь просто улетишь?',
            nextStep: 'Q8',
          },
        ],
      },

      Q8: {
        text: `Вжух протянул лапу к радиоприёмнику и переключил волну на корабль пришельцев.`,
        id: 'Q8',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q9',
      },

      Q9: {
        text: `У нас замечательный парк, и вы обязаны исправить всё, что натворили вашим лучом. Даже если это сделал Квилл, он не со зла!`,
        id: 'Q9',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, мы не согласны с вашим решением!',
            nextStep: 'Q10',
          },
        ],
      },

      Q10: {
        text: `Ваш парк не представляет интереса и важности для нашего вида, и мы не будем тратить энергию луча трансформации на подобные м… `,
        id: 'Q10',
        person: Interlocutors.ALIENSHIP,
        type: StepType.message,
        nextStep: 'Q11',
      },

      Q11: {
        text: `Не дожидаясь конца ответа пришельцев, Квилл переключил радиоприёмник на свою волну.`,
        id: 'Q11',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q12',
      },

      Q12: {
        text: `Друзья, я очень перед вами виноват и сочту своим долгом убедить мой вид в том, что парк прекрасен и стоит вернуть всё как было. 

        До тех пор, я никуда не полечу!
        `,
        id: 'Q12',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ура! Мы теперь команда по спасению «Большой Перемены»! ',
            nextStep: 'Q13',
          },
        ],
      },

      Q13: {
        text: `Да, команда 😺

        Идём скорее думать, как переубедить пришельцев!`,
        id: 'Q13',
        person: Interlocutors.CAT,
        type: StepType.message,
        nextStep: EXIT_ID,
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
