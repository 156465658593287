import React from 'react';
import { StyledScores } from './ScoresStyled';

import colors from '../../shared/constants/colors';
import { Box } from '../../../common/shared/ui/Box';
import { Text } from '../../../common/shared/ui/Text';

import { ScoreItem } from './ScoresTypes';

export const Scores = ({ state }: any) => {
  const sumValues = (obj: ScoreItem | undefined, keys: (keyof ScoreItem)[]) => {
    return keys.reduce((acc, key) => {
      const value = obj?.[key];
      if (typeof value === 'string') {
        return acc;
      }
      return acc + (value || 0);
    }, 0);
  };

  const totalPresentation = sumValues(state.manualCheckedScore, [
    'presentation1',
    'presentation2',
    'presentation3',
  ]);

  const totalChallenge = sumValues(state.manualCheckedScore, [
    'challenge1_1',
    'challenge1_2',
    'challenge2_1',
    'challenge2_2',
  ]);

  const totalInterview = sumValues(state.manualCheckedScore, [
    'interview_communication',
    'interview_logic',
    'interview_altruism',
    'interview_creative',
  ]);

  const personalCompetencies = sumValues(state.manualCheckedScore, [
    'final_logical_thinking',
    'final_creativity',
    'final_communication',
    'final_cooperation',
    'final_teamwork',
  ]);

  const groupCompetencies = sumValues(state.manualCheckedScore, [
    'final_consistency',
    'final_elaboration',
    'final_originality',
  ]);

  const finalCompetencies = personalCompetencies + groupCompetencies;

  const calculateNumericValue = (value: number | string | null | undefined) =>
    typeof value === 'number' ? value : 0;

  const totalScore =
    totalPresentation +
    totalChallenge +
    totalInterview +
    personalCompetencies +
    groupCompetencies +
    calculateNumericValue(state.manualCheckedScore?.logic) +
    calculateNumericValue(state.manualCheckedScore?.erudition);

  const hasValidScore = (score: number | string | null | undefined, maxScore: number) => {
    if (score === undefined || score === null) {
      return 'нет оценки';
    } else if (typeof score === 'string') {
      return score;
    }
    return `${score} из ${maxScore}`;
  };

  return (
    <StyledScores>
      <Box $align='center' $isWrap={false} $direction={'row'} $justify={'space-between'}>
        <Text $textStyle='p18' $color={colors.title} $fontWeight='800'>
          Дистанционный этап конкурса завершен
        </Text>

        <Text $textStyle='p14' $fontWeight={'600'} $color={colors.blueButton} $mR='5%'>
          Общий балл: {totalScore}
        </Text>
      </Box>
      {/* {state?.manualCheckedScore?.interview_next_stage ? (
        <Text $textStyle='p14' $color={colors.blueButton} $fontWeight='600'>
          Поздравляем! Ты в финале.
        </Text>
      ) : (
        <Text $textStyle='p14' $color={colors.blueButton} $fontWeight='600'>
          К сожалению, тебе не доступен следующий этап
        </Text>
      )} */}
      {/* {state?.manualCheckedScore?.next_stage ? (
        <Text $textStyle='p14' $color={colors.blueButton} $fontWeight='600'>
          Поздравляем! Ты участник этапа «Онлайн-собеседование»
        </Text>
      ) : (
        <Text $textStyle='p14' $color={colors.blueButton} $fontWeight='600'>
          К сожалению, тебе не доступен следующий этап
        </Text>
      )} */}
      {state?.manualCheckedScore?.winner ? (
        <Text $textStyle='p14' $color={colors.blueButton} $fontWeight='600'>
          Поздравляем! Ты победил!
        </Text>
      ) : (
        <Text $textStyle='p14' $color={colors.blueButton} $fontWeight='600'>
          Возвращайся в следующем году, у тебя все получится!
        </Text>
      )}
      <Text $textStyle='p14' $color={colors.title} $fontWeight='800'>
        Видеовизитка: {totalPresentation} из 60
      </Text>
      <Text $textStyle='p14' $color={colors.grey2} $minW='35%'>
        Критерий Самопрезентация: {hasValidScore(state?.manualCheckedScore?.presentation1, 20)}
      </Text>
      <Text $textStyle='p14' $color={colors.grey2} $minW='35%'>
        Критерий Речь: {hasValidScore(state?.manualCheckedScore?.presentation2, 20)}
      </Text>
      <Text $textStyle='p14' $color={colors.grey2} $minW='35%'>
        Критерий Связанность рассказа: {hasValidScore(state?.manualCheckedScore?.presentation3, 20)}
      </Text>
      <Text $textStyle='p14' $color={colors.title} $fontWeight='800'>
        Задание по вызову: {totalChallenge} из 160
      </Text>
      <Text $textStyle='p14' $color={colors.grey2} $minW='35%'>
        Критерий Полнота выполнения задания 1 :{' '}
        {hasValidScore(state?.manualCheckedScore?.challenge1_1, 60)}
      </Text>
      <Text $textStyle='p14' $color={colors.grey2} $minW='35%'>
        Критерий Креативность 1: {hasValidScore(state?.manualCheckedScore?.challenge1_2, 20)}
      </Text>
      <Text $textStyle='p14' $color={colors.grey2} $minW='35%'>
        Критерий Полнота выполнения задания 2:{' '}
        {hasValidScore(state?.manualCheckedScore?.challenge2_1, 60)}
      </Text>
      <Text $textStyle='p14' $color={colors.grey2} $minW='35%'>
        Критерий Креативность 2: {hasValidScore(state?.manualCheckedScore?.challenge2_2, 20)}
      </Text>
      <Text $textStyle='p14' $color={colors.title} $fontWeight='800'>
        Тест Логика: {hasValidScore(state?.manualCheckedScore?.logic, 60)}
      </Text>
      <Text $textStyle='p14' $color={colors.title} $fontWeight='800'>
        Тест Эрудиция: {hasValidScore(state?.manualCheckedScore?.erudition, 60)}
      </Text>

      {!!totalInterview && (
        <>
          <Text $textStyle='p14' $color={colors.title} $fontWeight='800'>
            Собеседование: {totalInterview} из 160
          </Text>
          <Text $textStyle='p14' $color={colors.grey2} $minW='35%'>
            Коммуникация: {hasValidScore(state?.manualCheckedScore?.interview_communication, 40)}
          </Text>
          <Text $textStyle='p14' $color={colors.grey2} $minW='35%'>
            Логическое мышление: {hasValidScore(state?.manualCheckedScore?.interview_logic, 40)}
          </Text>
          <Text $textStyle='p14' $color={colors.grey2} $minW='35%'>
            Альтруизм: {hasValidScore(state?.manualCheckedScore?.interview_altruism, 40)}
          </Text>
          <Text $textStyle='p14' $color={colors.grey2} $minW='35%' $mB='20px'>
            Креативность: {hasValidScore(state?.manualCheckedScore?.interview_creative, 40)}
          </Text>
        </>
      )}

      {/* ФИНАЛ КОНКУРСА */}

      <Text $textStyle='p16' $color={colors.title} $fontWeight='800'>
        Финал конкурса: {finalCompetencies} из 500
      </Text>

      {/* ПЕРСОНАЛЬНЫЕ КОМПЕТЕНЦИИ */}

      <Text $textStyle='p16' $color={colors.title} $fontWeight='800'>
        Персональные компетенции: {personalCompetencies} из 400
      </Text>
      <Text $textStyle='p14' $color={colors.grey2} $minW='35%'>
        Логическое мышление: {hasValidScore(state?.manualCheckedScore?.final_logical_thinking, 80)}
      </Text>
      <Text $textStyle='p14' $color={colors.grey2} $minW='35%'>
        Креативность: {hasValidScore(state?.manualCheckedScore?.final_creativity, 80)}
      </Text>
      <Text $textStyle='p14' $color={colors.grey2} $minW='35%'>
        Коммуникация: {hasValidScore(state?.manualCheckedScore?.final_communication, 80)}
      </Text>
      <Text $textStyle='p14' $color={colors.grey2} $minW='35%'>
        Сотрудничество: {hasValidScore(state?.manualCheckedScore?.final_cooperation, 80)}
      </Text>
      <Text $textStyle='p14' $color={colors.grey2} $minW='35%' $mB='20px'>
        Командная работа: {hasValidScore(state?.manualCheckedScore?.final_teamwork, 80)}
      </Text>

      {/* ГРУППОВОЕ РЕШЕНИЕ КЕЙСА */}

      <Text $textStyle='p16' $color={colors.title} $fontWeight='800'>
        Групповое решение кейса: {groupCompetencies} из 100
      </Text>
      <Text $textStyle='p14' $color={colors.grey2} $minW='35%'>
        Системность: {hasValidScore(state?.manualCheckedScore?.final_consistency, 40)}
      </Text>
      <Text $textStyle='p14' $color={colors.grey2} $minW='35%'>
        Проработанность: {hasValidScore(state?.manualCheckedScore?.final_elaboration, 40)}
      </Text>
      <Text $textStyle='p14' $color={colors.grey2} $minW='35%'>
        Оригинальность: {hasValidScore(state?.manualCheckedScore?.final_originality, 20)}
      </Text>
    </StyledScores>
  );
};
