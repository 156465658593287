import { BREAKPOINT_PHONE } from '../../../common/shared/constants/constants';
import styled, { css } from 'styled-components';

const smallSizeSvg = '50px';
const sizeSvg = '70px';

const smallSize = '62px';
const size = '90px';

export const StyledItemsBarItem = styled.div<{
  $isPseudo?: boolean;
  $isActive?: boolean;
  $hasEvent?: boolean;
}>`
  ${(p) => {
    if (p.$isPseudo) {
      return css`
        width: ${smallSize};
        height: 0px;
      `;
    } else {
      return css`
        width: ${smallSize};
        height: ${smallSize};

        margin-top: 26px;
      `;
    }
  }}

  ${(p) => {
    if (p.$isActive) {
      return css`
        border: 2px solid #3e68ff;
      `;
    }
  }}

  ${(p) => p.$hasEvent && `cursor: pointer;`}

  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 26px;
  border-radius: 16px;
  background: rgba(228, 228, 228, 0.25);

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    ${(p) => {
      if (p.$isPseudo) {
        return css`
          width: ${size};
          height: 0px;
        `;
      } else {
        return css`
          width: ${size};
          height: ${size};

          margin-top: 26px;
        `;
      }
    }}
  }
`;

export const StyledItemsBarItemIcon = styled.img`
  width: ${smallSizeSvg};
  height: ${smallSizeSvg};

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    width: ${sizeSvg};
    height: ${sizeSvg};
  }
`;

export const StyledItemsBarItemColor = styled.div<{ $color: string }>`
  border-radius: 50%;
  width: ${smallSizeSvg};
  height: ${smallSizeSvg};

  ${({ $color }) => $color && `background: ${$color};`}

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    width: ${sizeSvg};
    height: ${sizeSvg};
  }
`;
