import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';
import interlocutors from '../../../interlocutors';

export const mguDictaphone_1_1_0: Scenario = {
  scenarioId: SceneId.mguDictaphone_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `У Светланы Васильевны в МГУ точно был диктофон. Как-то раз ребята из кружка журналистики брали на него интервью. У меня, между прочим!`,
    buttons: ['Продолжить'],
  },
};

export const mguDictaphone_1_1_1: Scenario = {
  scenarioId: SceneId.mguDictaphone_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'МГУ',
    text: `В библиотеке книги всё также летали под потолком, но к многим из них теперь привязали веревочки, чтобы было легче доставать нужные.`,
    buttons: ['Продолжить'],
  },
};
