/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg2_innopolicePetrov_1_1: Scenario = {
  scenarioId: SceneId.stg2_innopolicePetrov_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    timeoutMessage: 'К сожалению, время для ответа истекло 🙁',
    title: 'Иннополис',
    scene_id: 'stg2_innopolicePetrov_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.PETROV,
        text: `Привет! Я уже переживать начал за вас. Но вижу, что вы в порядке.

        Получилось раздобыть нам микросхему?`,
        id: 'Q1',
        type: StepType.question,
        nextStep: 'Q2',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да! Вот она, держи. ',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        text: `Петров, представляешь, ради неё мы обошли весь парк — готовились к викторине.`,
        id: 'Q2',
        person: Interlocutors.CAT,
        type: StepType.message,
        nextStep: 'Q3',
      },

      Q3: {
        text: `Вы, ребята, очень ответственные, не сомневался в вас.

        Так, подключил схему в компьютер. Запускаем.`,
        id: 'Q3',
        person: Interlocutors.PETROV,
        type: StepType.question,
        nextStep: 'Q4',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, скорее-скорее! ',
            nextStep: 'Q4',
          },
        ],
      },

      Q4: {
        id: 'Q4',
        person: Interlocutors.PETROV,
        text: `Отлично, всё работает!

        Вот результаты анализа.
        
        Хмм… Странно`,
        type: StepType.question,
        nextStep: 'Q5',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Что? Что там?',
            nextStep: 'Q5',
          },
        ],
      },

      Q5: {
        id: 'Q5',
        person: Interlocutors.PETROV,
        text: `А вы весь парк обошли ведь, да? Не нашли того чудного зверька?

        Странно… Очень странно!`,
        type: StepType.question,
        nextStep: 'Q6',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Нет, его и след простыл. А что сказал анализатор?',
            nextStep: 'Q6',
          },
        ],
      },

      Q6: {
        id: 'Q6',
        person: Interlocutors.PETROV,
        text: `Ну смотрите, братцы.

        Анализ шерсти и слизи дали пустые результаты. Это значит, ничего подобного в базе прибора нет. А в него загружена информация обо всех живых существах на нашей планете.`,
        type: StepType.question,
        nextStep: 'Q7',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ну значит это какая-то ошибка? Откуда же он тогда взялся?',
            nextStep: 'Q7',
          },
        ],
      },

      Q7: {
        id: 'Q7',
        person: Interlocutors.CAT,
        text: `Не с нашей планеты! А-ха-ха-ха-ха!`,
        type: StepType.question,
        nextStep: 'Q8',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Очень смешно, Вжух 😬',
            nextStep: 'Q8',
          },
        ],
      },

      Q8: {
        id: 'Q8',
        person: Interlocutors.PETROV,
        text: `А я, между прочим, склоняюсь к той же версии.

        Смотрите, даже вода из Байкала содержит следы неизвестных анализатору веществ. А значит, и наше существо, и странные события в парке связаны!
        
        И, бьюсь об заклад, связаны с чем-то инопланетным.
        `,
        type: StepType.question,
        nextStep: 'Q9',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А я не верю в инопланетян! Вода хотя бы не опасна? Мы обещали бабуле узнать.',
            nextStep: 'Q9',
          },
          {
            id: 'b2',
            text: 'То есть к нам прилетели пришельцы? Как круто! Но раз вода инопланетная, её не опасно пить? ',
            nextStep: 'Q9',
          },
        ],
      },

      Q9: {
        id: 'Q9',
        person: Interlocutors.PETROV,
        text: `Пить её не опаснее, чем любую земную сладкую газировку, не переживайте.`,
        type: StepType.question,
        nextStep: 'Q10',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Здорово, а то мы с Вжухом уже её пили 😅',
            nextStep: 'Q10',
          },
        ],
      },

      Q10: {
        id: 'Q10',
        person: Interlocutors.CAT,
        text: `Это всё, конечно, хорошо! Но что мы будем делать с инопланетянином? И со всеми странностями в парке?

        Мы ведь его даже нигде найти не смогли! А парк надо спасать!`,
        type: StepType.message,
        nextStep: 'Q11',
      },

      Q11: {
        id: 'Q11',
        person: Interlocutors.PETROV,
        text: `Сложный вопрос! Пока не знаю, мне нужно подумать. Но найти пришельца однозначно нужно. Вы поищите его, а я составлю план действий по спасению парка.`,
        type: StepType.question,
        nextStep: EXIT_ID,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хороший план! Мы найдём нашего пришельца!',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
