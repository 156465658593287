import React, { useCallback } from 'react';
import { ChatTextAreaStyled } from '../../chat/ChatAnswer/ChatAnswerStyled';

interface Props {
  value: string;
  setValue: (value: string) => void;
}

export const Textarea = ({ value, setValue }: Props) => {
  const onChange = useCallback((e) => setValue(e.target.value), [setValue]);
  return <ChatTextAreaStyled placeholder='Напиши ответ тут' value={value} onChange={onChange} />;
};

export default Textarea;
