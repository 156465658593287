import { useCallback, useEffect, useRef } from 'react';
import { Step } from '../resources/scenario';

import { useDispatch, useSelector } from 'react-redux';
import { selectTimer, selectActiveChatHistory } from '../redux/scenario/scenarioGetters';
import { setScenarioTimer } from '../redux/scenario/scenarioActions';

const useTimerMessage = (step: Step, isLast: boolean, isTyping: boolean, onTimeout: () => void) => {
  const dispatch = useDispatch();
  const timer = useSelector(selectTimer);
  const counterTime = useRef<number>(0);
  const counter = useRef<NodeJS.Timeout>();
  const sceneHistory = useSelector(selectActiveChatHistory); // история активного сценария

  const setTime = useCallback(
    (time: number) => {
      if (timer === time) return;
      dispatch(setScenarioTimer(time));
    },
    [dispatch, timer],
  );

  const stopCounter = useCallback(() => {
    setTime(0);
    if (counter.current) clearInterval(counter.current);
  }, [setTime]);

  const startCounter = useCallback(() => {
    const func = () => {
      counterTime.current = counterTime.current - 1;
      setTime(counterTime.current);

      if (counterTime.current === 0) {
        onTimeout();
        stopCounter();
      }
    };

    // исправление бага - если пользователь вышел из теста без ответа на вопрос и вернулся, отсчёт начинается заново
    const savedDate =
      sceneHistory[sceneHistory.length - 2].type === 'system'
        ? sceneHistory[sceneHistory.length - 3].date
        : sceneHistory[sceneHistory.length - 2].date;
    const now = new Date().valueOf();
    const mDate = new Date(savedDate!).valueOf();
    const seconds = 60;
    const t = seconds - Math.round((now - mDate) / 1000);
    const newTimer = t > 0 ? (t >= 58 ? 60 : t) : 1;

    counterTime.current = newTimer;
    counter.current = setInterval(func, 1000);

    setTime(newTimer);
  }, [onTimeout, setTime, stopCounter, step.timeout]);

  // Обнуление таймера при unMount!
  useEffect(() => {
    return () => {
      if (counter.current) {
        dispatch(setScenarioTimer(0));
        clearInterval(counter.current);
      }
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isLast) {
      if (counter.current) {
        clearInterval(counter.current);
      }
    }
  }, [isLast]);
  useEffect(() => {
    if (!isLast) return;

    // на случай, если последний элемент обновился
    // но эффект предыдущего успел запустить таймер
    if (!step.timeout) {
      stopCounter();
      return;
    }

    if (isTyping) return;

    // если таймер пустой - запустить
    if (timer === 0) startCounter();
  }, [setTime, onTimeout, stopCounter, startCounter, timer, isLast, isTyping, step.timeout]);
};

export default useTimerMessage;
