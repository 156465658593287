import {
  Conditions,
  Interlocutors,
  Message,
  Question,
  QuestionButton,
  Step,
  StepType,
  SystemStep,
  TestJSON,
  TestStep,
  TextQuestion,
} from '../resources/scenario';
import { tests } from '../resources/scenarios/tests';
import { images } from '../resources/scenarios/tests/images';

interface IDData {
  index: number;
  testId: string;
  subtestId?: string;
  questionId?: string;
  answerId?: string;
}

function getId(data: IDData) {
  const { testId, index, subtestId, questionId, answerId } = data;
  let id = `TEST/${testId}/`;
  if (subtestId) {
    id += subtestId + '/';
  }
  if (questionId) {
    id += questionId + '/';
  }
  if (answerId) {
    id += answerId + '/';
  }
  id += String(index);
  return id;
}

export function getFullName(firstName = '', lastName = '') {
  return `${firstName ?? ''} ${lastName ?? ''}`.trim();
}

function makeTextMessage(text: string, person: Interlocutors, idData: IDData): Message {
  return {
    id: getId(idData),
    nextStep: '',
    person: person,
    text,
    type: StepType.message,
  };
}

const POSITIVE = 'Точно да';
const NEGATIVE = 'Определенно нет';
const NEUTRAL = 'Не знаю';
function getButtons(): QuestionButton[] {
  const nextStep = '';
  return [
    {
      id: '1',
      text: POSITIVE,
      nextStep,
    },
    {
      id: '2',
      text: NEUTRAL,
      nextStep,
    },
    {
      id: '3',
      text: NEGATIVE,
      nextStep,
    },
  ];
}

function fixText(text: string) {
  const data = text.split('::');
  return data.length > 1 ? data[1].trim() : text;
}

function makeQuestionMessage(text: string, idData: IDData, person: Interlocutors): Question {
  return {
    isHorizontal: true,
    buttons: getButtons(),
    condition: undefined,
    id: getId(idData),
    nextStep: '',
    person,
    maxAnswers: 1,
    text: fixText(text),
    type: StepType.question,
  };
}

function normalize(steps: Step[]): Record<string, Step> {
  const res: Record<string, Step> = {};
  for (let i = 0; i < steps.length; i++) {
    res[steps[i].id] = steps[i];
  }
  return res;
}

function makeMetaMessage(person: Interlocutors, idData: IDData): SystemStep {
  const min = idData.subtestId === 'T1' ? 1 : 2;
  return {
    id: getId(idData),
    _meta: idData.subtestId
      ? {
          [POSITIVE]: min,
          [NEGATIVE]: min,
        }
      : {},
    person,
    type: StepType.system,
    nextStep: '',
  };
}

function makeCheckMessage(idData: IDData, person: Interlocutors): SystemStep {
  const min = idData.subtestId === 'T1' ? 1 : 2;
  return {
    condition: {
      type: Conditions.IS_LAST_ANSWERS_VALID,
      variants: [
        getId({ ...idData, index: idData.index + 1 }),
        getId({ ...idData, index: idData.index + 2 }),
      ],
    },
    id: getId(idData),
    _meta: idData.subtestId
      ? {
          [POSITIVE]: min,
          [NEGATIVE]: min,
        }
      : {},
    person,
    type: StepType.system,
  };
}

export function testToSteps(test: TestStep): {
  steps: Record<string, Step>;
  first: string;
  last: string;
} {
  const testJson: TestJSON = tests[test.path];
  const start = makeMetaMessage(test.person, { testId: test.id, index: 0 });
  start.id = test.id;
  const steps: Step[] = [start];
  const stepToLink: { step?: Step } = { step: start };
  const linkSteps = (step: Step) => {
    if (stepToLink.step) {
      stepToLink.step.nextStep = step.id;
    }
    stepToLink.step = step;
  };
  for (let i = 0; i < test.order.length; i++) {
    const item = test.order[i];
    const getIDDataTemplate = () => ({
      index: steps.length + 1,
      subtestId: item.subtest,
      questionId: item.question,
      testId: test.id,
    });
    // Если есть, то добавляется описание
    if (item.description) {
      const description = makeTextMessage(item.description, test.person, getIDDataTemplate());
      linkSteps(description);
      steps.push(description);
    }

    const json = testJson.subtests.items[item.subtest].questions.items[item.question];

    if (json.type === 'multiple-slider') {
      const text = makeTextMessage(json.text, test.person, getIDDataTemplate());
      steps.push(text);
      linkSteps(text);

      // если есть описание, то начать повторно тест нужно с него
      const questionStart = steps[steps.length - 1].id;

      // Шаг, по которому выполняется валидация
      const validationStep = makeMetaMessage(test.person, getIDDataTemplate());
      steps.push(validationStep);
      linkSteps(validationStep);

      for (const answerId in json.answers) {
        const step = makeQuestionMessage(
          json.answers[answerId].text,
          { ...getIDDataTemplate(), answerId },
          test.person,
        );
        steps.push(step);
        linkSteps(step);
      }
      const checkStep = makeCheckMessage(
        { ...getIDDataTemplate(), subtestId: item.subtest },
        test.person,
      );
      steps.push(checkStep);
      const _text =
        checkStep._meta[NEGATIVE] === 1
          ? `{{NAME}}, нужно из четырех вариантов минимум один ответить «Точно да» и один «Определенно нет».
          
        Давай попробуем еще раз. Ответь, пожалуйста, на вопросы, согласно условию.`
          : `{{NAME}}, нужно из восьми вариантов минимум два ответить «Точно да» и два «Определенно нет».

      Давай попробуем еще раз. Ответь, пожалуйста, на вопросы, согласно условию.`;
      const restartStep = makeTextMessage(_text, test.person, getIDDataTemplate());
      restartStep.nextStep = questionStart;
      if (checkStep.condition) {
        checkStep.condition.variants = ['', restartStep.id];
      }
      linkSteps(checkStep);
      steps.push(restartStep);
    } else {
      const question: Question = {
        image: json.image ? { uri: images[json.image], title: '' } : undefined,
        buttons: [],
        id: getId(getIDDataTemplate()),
        maxAnswers: test.maxAnswers || 5,
        nextStep: '',
        person: test.person,
        text: json.text,
        type: StepType.question,
        timeout: item.timeout,
      };
      for (const answerId in json.answers) {
        const variant = json.answers[answerId];
        question.buttons!.push({
          id: answerId,
          nextStep: '',
          text: variant.text,
        });
      }
      if (json.type === 'single') {
        const randomSort = () => 0.5 - Math.random();
        question.maxAnswers = 1;
        question.buttons?.sort(randomSort);
      }
      steps.push(question);
      linkSteps(question);
    }
  }
  const last: SystemStep = {
    id: getId({ index: steps.length + 1, testId: test.id }),
    _meta: {},
    person: test.person,
    type: StepType.system,
    nextStep: test.nextStep,
  };
  steps.push(last);
  linkSteps(last);
  steps.forEach((step) => {
    if (step.condition) {
      step.condition.variants[0] = step.nextStep || '';
    }
    if ((step as Question).buttons) {
      (step as Question).buttons = (step as Question).buttons!.map((btn) => ({
        ...btn,
        nextStep: step.nextStep || '',
      }));
    }
  });
  return {
    steps: normalize(steps),
    first: steps[0].id,
    last: steps[steps.length - 1].id,
  };
}

/**
 * Разбивает шаг на несколько сообщений, если встречается двойной перенос строки.
 * ID последнего шага равен id исходного шага
 * ID первого шага равен ${ID исходного}/0
 * В случае, если шаг состоит из одного сообщения - шаги дублируются с разными ID для универсальности
 * @param step
 */
export function splitStep(step: Question | Message | TextQuestion): Record<string, Step> {
  const parts = step.text.split('\n\n');
  if (parts.length === 1) {
    const first = `${step.id}/0`;
    return {
      [step.id]: step,
      [first]: { ...step, id: first },
    };
  }
  const messages: Step[] = parts.map((part, index) => ({
    person: step.person,
    delay: step.delay,
    text: part,
    type: StepType.message,
    nextStep: `${step.id}/${index + 1}`,
    id: `${step.id}/${index}`,
  }));
  messages[messages.length - 2].nextStep = step.id;
  messages[messages.length - 1] = {
    ...step,
    text: parts[parts.length - 1],
  };
  const res: Record<string, Step> = {};
  messages.forEach((m) => (res[m.id] = m));
  return res;
}
