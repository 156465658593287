/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  Step,
  StepType,
  SystemStepType,
  TestStep,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';
import { images } from '../../tests/images';
import mentorMobile from '../../../../../assets/images/mentor_mobile.jpg';
import mentorDesktop from '../../../../../assets/images/mentor_desktop.jpg';

const TEST_TVOI_VYBOR: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'TEST_TVOI_VYBOR',
  name: 'Твой выбор остаток',
  nextStep: 'Q3',
  order: [
    {
      description: '',
      subtest: 'T2',
      question: 'Q1',
    },
    {
      description: '',
      subtest: 'T4',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person: Interlocutors.SYSTEM,
  type: StepType.test,
};

const LOGIC: Record<string, Step> = {
  LOGIC_TEST: {
    person: Interlocutors.PETROV,
    nextStep: 'LOGIC_TEST_PART1_Q5',
    id: 'LOGIC_TEST',
    type: StepType.system,
    _meta: {
      type: SystemStepType.limitedTimeGroup,
      time: 2 * 60,
      timeoutStep: 'LOGIC_TEST_TIMEOUT',
      exitStep: 'Q20',
    },
  },

  LOGIC_TEST_PART1_Q5: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5',
    text: 'Расположи числа 4, 2, 6, 1 на месте фигур (квадрата, звездочки, треугольника и круга) так, чтобы стрелки шли от большего числа к меньшему:',
    image: { uri: images.logic_fix, title: '' },
    type: StepType.message,
    nextStep: 'LOGIC_TEST_PART1_Q5_1',
  },
  LOGIC_TEST_PART1_Q5_1: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5_1',
    text: 'Что на месте квадрата?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_1',
    nextStep: 'LOGIC_TEST_PART1_Q5_2',
  },
  LOGIC_TEST_PART1_Q5_2: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5_2',
    text: 'На месте звездочки?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_2',
    nextStep: 'LOGIC_TEST_PART1_Q5_3',
  },
  LOGIC_TEST_PART1_Q5_3: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5_3',
    text: 'На месте треугольника?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_3',
    nextStep: 'LOGIC_TEST_PART1_Q5_4',
  },
  LOGIC_TEST_PART1_Q5_4: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5_4',
    text: 'На месте круга?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_4',
    nextStep: 'Q20',
  },
  LOGIC_TEST_TIMEOUT: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_TIMEOUT',
    text: '{{NAME}}, увы, время закончилось 🙁',
    type: StepType.message,
    nextStep: 'Q21',
  },
};

const EXIT_ID = 'EXIT_ID';

export const scenario_3_1_i: Scenario = {
  scenarioId: SceneId.scenario_3_1_i,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Иннополис',
    text: `Взяв с собой ничего не понимающую Софию, вы отправляетесь в Иннополис: вам нужно срочно передать накопитель с «Интеллектом 2.0» Гарину.`,
    buttons: ['Отправиться к КВА'],
  },
};

export const scenario_3_1: Scenario = {
  scenarioId: SceneId.scenario_3_1,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Иннополис',
    scene_id: 'scenario_3_1',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.SYSTEM,
        id: 'Q1',
        type: StepType.question,
        maxAnswers: 1,
        text: `Вы приближаетесь к гигантской жабе, София вскрикивает и прячется за Вжухом. 
        Голограмма Гарина радостно машет вам:
        “{{NAME}}, вы с Вжухом справились, молодцы. Давайте сюда накопитель.”`,
        buttons: [
          {
            id: 'b1',
            text: 'Отдать накопитель Гарину',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        person: Interlocutors.SYSTEM,
        id: 'Q2',
        type: StepType.question,
        maxAnswers: 1,
        text: `Ты достаёшь накопитель и жаба мгновенно слизывает его своим длинным языком с твоей руки. 
        Глаза жабы начинают вращаться — она будто гипнотизирует вас. 
        
        «Не бойтесь, всё в порядке» — слышится голос Гарина. «Процесс стирания «Интеллекта 2.0» запущен, я запущу бэкап версию парка «Большая перемена». 
        
        Но пока жители не пришли в себя и не испугались, вам нужно всех найти и вернуть их туда, где они потерялись. Иначе будет хаос. 
        
        Кстати, ты так много взаимодействовал с «Интеллектом 2.0», что это могло тебя повредить, давай-ка проверим твою память и знания о себе.
        `,
        buttons: [
          {
            id: 'b1',
            text: 'Ответить на вопросы Гарина',
            nextStep: 'TEST_TVOI_VYBOR',
          },
        ],
      },

      TEST_TVOI_VYBOR: TEST_TVOI_VYBOR, // твой выбор джуниор  остаток

      Q3: {
        person: Interlocutors.CAT,
        id: 'Q3',
        type: StepType.question,
        maxAnswers: 1,
        text: `Ну, вроде всё в порядке. Так что, поможешь жителям парка?`,
        buttons: [
          {
            id: 'b1',
            text: 'Согласиться',
            nextStep: 'Q4',
          },
        ],
      },
      Q4: {
        person: Interlocutors.SYSTEM,
        id: 'Q4',
        type: StepType.question,
        maxAnswers: 1,
        text: `«Я в тебе не сомневался. Начните с Софии — доставьте её в Эрмитаж, кажется, её там кто-то ждёт», — говорит Гарин и остаётся разбираться с жабой.`,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться в Эрмитаж',
            nextStep: 'Q5/0',
          },
        ],
      },
      ...splitStep({
        person: Interlocutors.TEACHER,
        delay: 5000,
        id: 'Q5',
        text: `{{NAME}}, наступил момент напомнить тебе, что ты можешь пригласить реального помощника. Им может стать педагог-наставник. Он сможет, например, подсказать, где искать нужную для выполнения заданий информацию.
        
        Если ты уже пригласил наставника, то можешь смело пролистывать это сообщение дальше.
        
        В педагоги-наставники подойдет учитель, тренер, инструктор или другой взрослый, обучающий детей. Подробнее информацию о наставнике ты можешь посмотреть в <a target="_blank" href="https://bolshayaperemena.online/api/link/short/4beffe5b9d">Положении о конкурсе</a>.
        
        Хорошо подумай, кто это может быть. Ведь после выбора наставника ты не сможешь его поменять. На выбор у тебя есть время, поэтому не торопись. Присоединить наставника в <a target="_blank" href="https://bolshayaperemena.online">личном кабинете</a> ты можешь до 13.06.2024г.
        
        Если ты точно знаешь, кого хочешь видеть своим наставником, предложи ему поучаствовать вместе с тобой. Для этого тебе нужно отправить приглашение педагогу из своего <a target="_blank" href="https://bolshayaperemena.online">личного кабинета</a> на платформе “Большая перемена” стать наставником:`,
        type: StepType.message,
        nextStep: 'Q6',
      }),
      Q6: {
        person: Interlocutors.TEACHER,
        id: 'Q6',
        type: StepType.message,
        text: ``,
        image: { uri: mentorMobile, title: '' },
        nextStep: 'Q7',
      },
      Q7: {
        person: Interlocutors.TEACHER,
        id: 'Q7',
        type: StepType.message,
        text: `Если педагог согласен, то должен зарегистрироваться на сайте и сообщить тебе свой id из личного кабинета на платформе “Большая перемена”. В своем личном кабинете он может либо принять, либо отклонить твое приглашение:`,
        nextStep: 'Q8',
      },
      Q8: {
        person: Interlocutors.TEACHER,
        id: 'Q8',
        type: StepType.message,
        text: ``,
        image: { uri: mentorDesktop, title: '' },
        nextStep: 'Q9',
      },
      Q9: {
        person: Interlocutors.TEACHER,
        id: 'Q9',
        type: StepType.question,
        text: `Важно: педагог будет считаться наставником только в том случае, если примет твое приглашение в своем личном кабинете.`,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ок, я [[понял/поняла]]',
            nextStep: EXIT_ID,
          },
        ],
      },

      //       ...splitStep({
      //         person: Interlocutors.PETROV,
      //         id: 'Q19',
      //         text: `Тогда поехали!

      // У тебя будет ровно 2 минуты на решение. Нужно будет дать 4 ответа, вписывай их по очереди — по одному в поле ввода и затем жми «Отправить ответ».`,
      //         type: StepType.question,
      //         maxAnswers: 1,
      //         buttons: [
      //           {
      //             id: 'b1',
      //             text: 'Хорошо',
      //             nextStep: 'LOGIC_TEST',
      //           },
      //         ],
      //       }),

      //       ...LOGIC,

      [EXIT_ID]: {
        person: Interlocutors.SYSTEM,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
