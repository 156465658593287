/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';
import { LOGIC_TEST_NEW } from '../../../scenarios/tests/logicTestNew';
import { TVOI_KRUGOZOR_T2 } from '../../tests/tvoi_krugozor_t2';

export const scenario_2_1: Scenario = {
  scenarioId: SceneId.scenario_2_1,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Иннополис',
    text: `Перед вами точная копия Иннополиса — города высоких технологий, расположенного в Республике Татарстан. Вас встречает Петров.`,
    buttons: ['Закрыть'],
  },
};

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';
const person = Interlocutors.PETROV;

export const scenario_2_1_1: Scenario = {
  scenarioId: SceneId.scenario_2_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Иннополис',
    timeoutMessage: 'К сожалению, время для ответа истекло ️',
    scene_id: 'scenario_2_1_1',
    startId: 'Q1/0',
    steps: {
      ...splitStep({
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q1',
        person,
        text: `Привет, {{NAME}}! Эх, у нас тут просто кошмар! 😱

Пока тебя не было, все заледенело! Все здания в Иннополисе стали похожи на большие сосульки! Ах да, ты же еще не [[бывал/бывала]] в Иннополисе, верно?`,
        buttons: [
          {
            id: 'b1',
            text: 'Нет, а что это?',
            nextStep: 'Q2/0',
          },
        ],
      }),
      ...splitStep({
        id: 'Q2',
        person,
        text: `Ну да, рановато тебе пока в Иннополис 🙂 

Это университет и город одновременно. Иннополис считается одним из наукоградов России, как Сколково или Кольцово, знаешь о таких?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Я [[слышал/слышала]] о Сколково, это в Подмосковье, да?',
            nextStep: 'Q3',
          },
          {
            id: 'b2',
            text: 'Нет, я об этом не знаю...',
            nextStep: 'Q4',
          },
        ],
      }),
      Q3: {
        person,
        id: 'Q3',
        type: StepType.message,
        text: `Да, я поражен твоими знаниями! 😍`,
        nextStep: 'Q5/0',
      },
      Q4: {
        person,
        id: 'Q4',
        type: StepType.message,
        text: `Ну ничего, я сейчас расскажу 😊`,
        nextStep: 'Q5/0',
      },
      ...splitStep({
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q5',
        person,
        text: `Иннополис построен на основе высокотехнологичных разработок, и в этом заключается его основная эксклюзивность. Экология, созданная безопасная среда, большие возможности для получения образования.

В город приезжают много интересных лекторов, занимаются роботами и высокими технологиями, а еще тут можно познакомиться с ребятами со всего света!`,
        buttons: [
          {
            id: 'b1',
            text: 'Хочу там побывать!',
            nextStep: 'Q6',
          },
          {
            id: 'b2',
            text: 'Ну да, интересно в целом',
            nextStep: 'Q6',
          },
        ],
      }),
      Q6: {
        person,
        id: 'Q6',
        type: StepType.message,
        text: `Многие, отправляясь сюда, принимают решение остаться или вдохновляются на новые творения. Молодежь со всей России приезжает, чтобы познакомиться с особенностями жизненного уклада и технологическими новшествами.`,
        nextStep: 'Q7',
      },
      Q7: {
        text: 'Иннополис был создан для развития информационных технологий, но ледяной туман заморозил все лаборатории. А ведь без новых открытий сейчас никуда, [[сам/сама]] понимаешь… 🙁 ',
        id: 'Q7',
        person,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, и что же делать?',
            nextStep: 'Q8',
          },
        ],
      },
      Q8: {
        person,
        id: 'Q8',
        text: 'Я уже начал проектировать новую тепловую пушку. Ее мощности должно хватить, чтобы разморозить парк. И я очень рассчитываю на твою помощь 😔',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Конечно, я помогу!',
            nextStep: 'Q9',
          },
          {
            id: 'b2',
            text: 'Ну да, куда же вы без меня..',
            nextStep: 'Q9',
          },
        ],
      },
      Q9: {
        person,
        id: 'Q9',
        text: `Я хочу узнать, могу ли рассчитывать на твои знания в процессе создания пушки, поэтому задам 10 вопросов.
        Ты [[должен/должна]] выбрать 1 вариант ответа из предложенных, и у тебя будет всего 1 минута на каждый вопрос.`,
        type: StepType.message,
        nextStep: 'Q9_1',
      },

      Q9_1: {
        person: Interlocutors.SYSTEM,
        id: 'Q9_1',
        text: `Тебе нужно понимать, что это тренировочный тест и его результаты не повлияют на твои результаты в конкурсе`,
        type: StepType.message,
        nextStep: 'Q9_1_0',
      },
      Q9_1_0: {
        person: Interlocutors.SYSTEM,
        id: 'Q9_1_0',
        text: `Предлагаем тебе пройти тест на эрудицию в технической сфере. Он оценит уровень твоей любознательности и широту кругозора и займет не более 10 минут. Тебе будет предъявлено 10 заданий, в каждом из которых по 4 варианта ответа, и только один из них верный. Время на каждый вопрос ограничено (60 секунд). Обращай внимание на счетчики времени на экране! Не рекомендуем долго думать над вариантами, лучше ориентироваться на свои знания и предположения и давать оперативные ответы. И старайся не пользоваться никакими справочниками и дополнительными ресурсами, ведь тест оценивает именно твой уровень эрудиции. Также имей в виду, что ты можешь вернуться назад только на один вопрос, чтобы исправить свой ответ. А теперь вперед, и желаем успехов!`,
        type: StepType.question,
        nextStep: 'TVOI_KRUGOZOR_T2_STEP',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: '[[Понял/Поняла]].',
            nextStep: 'TVOI_KRUGOZOR_T2_STEP',
          },
        ],
      },

      // Тест Твой кругозор 2\2

      ...TVOI_KRUGOZOR_T2,

      Q10: {
        person,
        id: 'Q10',
        type: StepType.message,
        text: `Вопросы были непростыми, но ты молодец! 👍 `,
        nextStep: 'Q11/0',
      },
      ...splitStep({
        person,
        id: 'Q11',
        text: `Тепловая пушка — штука сложная, и нам нужна особенная микросхема. К сожалению, в нашей лаборатории ее нет, но я знаю, что такая точно есть у Сан Саныча.

Он сейчас как раз недалеко — на Космодроме! Беги к нему и раздобудь микросхему. Скорее, иначе уже к вечеру мы все превратимся в снеговиков! ⛄️`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b2',
            text: 'Не успеешь моргнуть, как я уже вернусь с микросхемой!',
            nextStep: EXIT_ID,
          },
        ],
      }),
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};

// const ERUDITION_TEST: TestStep = {
//   id: 'ERUDITION_TEST',
//   person: Interlocutors.PETROV,
//   type: StepType.test,
//   external_id: '20210425-004355-7293',
//   path: 'erudition-20210425-004355-7293.json',
//   name: 'Эрудиция 1/2',
//   order: [
//     { timeout: 60, question: 'Q1', subtest: 'T2', description: '' },
//     { timeout: 60, question: 'Q2', subtest: 'T2', description: '' },
//     { timeout: 60, question: 'Q4', subtest: 'T2', description: '' },
//     { timeout: 60, question: 'Q5', subtest: 'T2', description: '' },
//     { timeout: 60, question: 'Q6', subtest: 'T2', description: '' },
//     { timeout: 60, question: 'Q10', subtest: 'T2', description: '' },
//     { timeout: 60, question: 'Q11', subtest: 'T2', description: '' },
//     { timeout: 60, question: 'Q13', subtest: 'T2', description: '' },
//     { timeout: 60, question: 'Q14', subtest: 'T2', description: '' },
//     { timeout: 60, question: 'Q20', subtest: 'T2', description: '' },
//   ],
//   nextStep: 'Q10',
// };
