/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg3_lake_1_1_0: Scenario = {
  scenarioId: SceneId.stg3_lake_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.CAT,
    title: 'Байкал',
    text: `Если нам нужно удивить пришельцев чем-то дельным, стоит поспрашивать взрослых — всё-таки они и на работу ходят и другие взрослые штуки делают.

    А кто у нас самый взрослый в парке? Правильно — Бабуля! Давайте с ней и поговорим.`,
    buttons: ['Начать'],
  },
};
