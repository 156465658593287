import styled from 'styled-components';
import { colors } from '../../../common/shared/constants/colors';
import {
  BREAKPOINT_PHONE,
  BREAKPOINT_SMALL_PHONE,
} from '../../../common/shared/constants/constants';

import { BaseProps } from '../../../common/shared/styles/styledBase';
import { TextStyled } from '../../../common/shared/ui/Text';

export const StyledHeaderUser = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledHeaderUserImg = styled.img<{ $cursor?: 'pointer' }>`
  cursor: ${(props) => props.$cursor};
  width: 40px;
  height: 40px;
  border-radius: 50%;

  @media screen and (min-width: ${BREAKPOINT_SMALL_PHONE}) {
    width: 60px;
    height: 60px;
  }

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    width: 40px;
    height: 40px;
  }
`;

export const StyledHeaderUserName = styled(TextStyled)<BaseProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
  margin-left: 10px;
  color: ${colors.grey2};

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    font-size: 18px;
    font-weight: 800;
    line-height: 1.5;

    color: ${colors.text};
    max-width: fit-content;
  }
`;
