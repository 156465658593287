import buks from './image-210425-005955-8302.png';
import creative_1 from './creative_1.jpg';
import creative_2 from './creative_2.jpg';
import creative_3 from './creative_3.jpg';
import creative_4 from './creative_4.jpg';
import creative_5 from './creative_5.jpg';
import creative_6 from './creative_6.jpg';
import creative_7 from './creative_7.jpg';
import creative_8 from './creative_8.jpg';
import creative_9 from './creative_9.jpg';
import creative_10 from './creative_10.jpg';

import creative_arch_1 from './creative_arch_1.svg';
import creative_arch_2 from './creative_arch_2.svg';
import creative_arch_3 from './creative_arch_3.svg';
import creative_arch_4 from './creative_arch_4.svg';
import creative_arch_5 from './creative_arch_5.svg';
import creative_arch_6 from './creative_arch_6.svg';
import creative_arch_7 from './creative_arch_7.svg';
import creative_arch_8 from './creative_arch_8.svg';
import creative_arch_9 from './creative_arch_9.svg';
import creative_arch_10 from './creative_arch_10.svg';

import logic_1 from './logic_1.jpg';
import logic_2 from './logic_2.jpg';
import logic_3 from './logic_3.jpg';
import logic_fix from './logic_fix.jpg';
import elephant from './elephant.jpg';
import cube from './cube.jpg';
import sculpt from './image-210425-003849-4321.jpeg';
import sign from './image-210425-003900-3962.png';
import logic_2_1 from './logic_2_1.jpg';
import logic_2_2 from './logic_2_2.jpg';
import logic_2_3 from './logic_2_3.jpg';
import logic_new_1 from './logic_new_1.jpeg';
import logic_new_2 from './logic_new_2.jpeg';
import logic_new_3 from './logic_new_3.jpeg';

import logic_test_main_1 from './logic_test_main_1.jpg';
import logic_test_main_2 from './logic_test_main_2.jpg';
import logic_test_main_3 from './logic_test_main_3.jpg';
import logic_test_main_4 from './logic_test_main_4.jpg';

import image_210426_235209_8328 from '../../../../../assets/tests/Images-g2/image-210426-235209-8328_g2.png';
import image_210426_235319_3475 from '../../../../../assets/tests/Images-g2/image-210426-235319-3475_g2.png';
import image_210426_235344_5278 from '../../../../../assets/tests/Images-g2/image-210426-235344-5278_g2.png';
import image_240528_151546_2732 from './image-240528-151546-2732.png';

import image_210427_000024_6489 from '../../../../../assets/tests/Images-t2/image-210427-000024-6489_t2.png';
import image_240528_150956_1721 from './image-240528-150956-1721.png';

import logic_test_2024_1 from './logic_test_2024_1.png';
import logic_test_2024_2 from './logic_test_2024_2.png';
import logic_test_2024_3 from './logic_test_2024_3.png';

export const images: { [id: string]: string } = {
  'image-210425-005955-8302.png': buks,
  'image-210425-003849-4321.jpeg': sculpt,
  'image-210425-003900-3962.png': sign,
  creative_1,
  creative_2,
  creative_3,
  creative_4,
  creative_5,
  creative_6,
  creative_7,
  creative_8,
  creative_9,
  creative_10,

  creative_arch_1,
  creative_arch_2,
  creative_arch_3,
  creative_arch_4,
  creative_arch_5,
  creative_arch_6,
  creative_arch_7,
  creative_arch_8,
  creative_arch_9,
  creative_arch_10,

  logic_1,
  logic_2,
  logic_3,
  logic_fix,
  elephant,
  cube,
  logic_2_1,
  logic_2_2,
  logic_2_3,
  logic_new_1,
  logic_new_2,
  logic_new_3,
  logic_test_main_1,
  logic_test_main_2,
  logic_test_main_3,
  logic_test_main_4,
  'image-210426-235209-8328.png': image_210426_235209_8328,
  'image-210426-235319-3475.png': image_210426_235319_3475,
  'image-210426-235344-5278.png': image_210426_235344_5278,
  'image-210427-000024-6489.png': image_210427_000024_6489,
  'image-240528-151546-2732.png': image_240528_151546_2732,
  'image-240528-150956-1721.png': image_240528_150956_1721,

  logic_test_2024_1,
  logic_test_2024_2,
  logic_test_2024_3,
};
