/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const hermitageFeeding_1_1_0: Scenario = {
  scenarioId: SceneId.hermitageFeeding_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.CAT,
    title: 'ЭРМИТАЖ',
    text: `Малевич, привет! 

    Мы принесли тебе поесть.`,
    buttons: ['Начать'],
  },
};
