/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId, StepType } from '../../../scenario';
import interlocutors from '../../../interlocutors';
import { splitStep } from '../../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT';

const person = Interlocutors.PETROV;

export const scenario_2_9: Scenario = {
  scenarioId: SceneId.scenario_2_9,
  type: ScenarioType.chat,
  data: {
    title: 'Иннополис',
    scene_id: 'scenario_2_9',
    startId: 'Q0/0',
    steps: {
      ...splitStep({
        id: 'Q0',
        person,
        text: `Слушай, я уже почти доделал тепловую пушку, но обнаружил, что не хватает важных деталей: золотого болта, нагревательного элемента, зеркала-отражателя и вентилятора.

Тебе предстоит отыскать их в парке «Большой перемены» и принести мне в Иннополис.

Выручай! Без этих деталей никак не обойтись! 🙁`,
        nextStep: 'Q1',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ого! Что ни день, то новые приключения!',
            nextStep: EXIT_ID,
          },
          {
            id: 'b2',
            text: '[[Побежал/Побежала]] на поиски!',
            nextStep: EXIT_ID,
          },
        ],
      }),
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};

export const scenario_2_9_1: Scenario = {
  scenarioId: SceneId.scenario_2_9_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `Кажется, золотой болт видели в районе моста на остров Русский. Можешь начать свои поиски оттуда.`,
    buttons: ['Закрыть'],
  },
};

export const scenario_2_9_2: Scenario = {
  scenarioId: SceneId.scenario_2_9_2,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.PETROV].name,
    person: Interlocutors.PETROV,
    text: `Не хватает еще деталей! Здесь их нет, возвращайся на карту.`,
    buttons: ['Закрыть'],
  },
};
