/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg3_spaceAlienShip_1_1_1: Scenario = {
  scenarioId: SceneId.stg3_spaceAlienShip_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.CAT,
    title: 'Космодром',
    text: `Даже не верится, что мы вот-вот спасём парк и поможем Квиллу вернуться домой.`,
    buttons: ['Начать'],
  },
};
