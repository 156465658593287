/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import { TEST_LOGIC_MAIN_2024 } from '../../tests/test_logic_main_2024';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg3_ostankino_1_1: Scenario = {
  scenarioId: SceneId.stg3_ostankino_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Останкино',
    scene_id: 'stg3_ostankino_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `О-хо-хо! Неужели, сработало? Чудеса, да и только!

        Радиопередатчику, только, кранты, ребята…`,
        id: 'Q1',
        person: Interlocutors.PETROV,
        type: StepType.message,
        nextStep: 'Q2',
      },

      Q2: {
        text: `Шшшшш… щщщщ…`,
        id: 'Q2',
        person: Interlocutors.ALIEN,
        type: StepType.message,
        nextStep: 'Q3',
      },
      Q3: {
        text: `Да, вот об этом я и говорю.`,
        id: 'Q3',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Но мы хотя бы узнали его имя. Да, Квилл?',
            nextStep: 'Q4',
          },
          {
            id: 'b2',
            text: 'Неужели не получится передатчик починить? ',
            nextStep: 'Q4',
          },
        ],
      },

      Q4: {
        text: `Ну что же, будем чинить! Только его нужно в Иннополис донести, у меня там весь инструмент.`,
        id: 'Q4',
        person: Interlocutors.PETROV,
        type: StepType.message,
        nextStep: 'Q5',
      },

      Q5: {
        text: `А лифт-то не работает! Электричество пропало во всей башне. Вы же не понесете его по лестнице вниз. Бросьте радио тут.`,
        id: 'Q5',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Вжух, мы не можем! Нам нужно вернуть Квилла домой, а без связи с пришельцами ничего не получится!',
            nextStep: 'Q6',
          },
        ],
      },

      Q6: {
        text: `Правильно.

        Саныч, раз, два — взяли!`,
        id: 'Q6',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Я помогу!',
            nextStep: 'Q7',
          },
          {
            id: 'b2',
            text: 'У вас всё получится!',
            nextStep: 'Q8',
          },
        ],
      },

      Q7: {
        text: `Пыхтя и краснея от напряжения, вы втроем спускаете по лестнице сломанный радиопередатчик. 

        Внизу башни вы, к своему удивлению, встречаете Бабулю.`,
        id: 'Q7',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q9',
      },

      Q8: {
        text: `Пыхтя и краснея от напряжения, Петров и Сан Саныч спускают по лестнице сломанный радиопередатчик. 

        Внизу башни вы, к своему удивлению, встречаете Бабулю.`,
        id: 'Q8',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q9',
      },

      Q9: {
        text: `Как хорошо, что это всего лишь копия настоящей Останкинской башни, не такая высокая!`,
        id: 'Q9',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, но тут аж 365 ступеней!',
            nextStep: 'Q10',
          },
        ],
      },

      Q10: {
        text: `Привет, ребята! Что у вас тут происходит? Что это над нами в небе?`,
        id: 'Q10',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Мы связались с пришельцами, чтобы вернуть Квилла домой!',
            nextStep: 'Q11',
          },
        ],
      },

      Q11: {
        text: `Щщщщщщ…`,
        id: 'Q11',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, он не может с нами общаться без радио. А оно сломалось, и неизвестно, когда починим.',
            nextStep: 'Q11_1',
          },
        ],
      },

      Q11_1: {
        text: `Хм.. Кажется, у меня есть идея, как можно вам помочь! Дело в том, что мой старый приятель Аркадий оставил мне на хранение свой карманный радиоприёмник, но он лежит в сейфе. 
        
        А чтобы открыть сейф, нужно решить тест на логику, который будет посложнее предыдущих. Я-то уже старенькая, не смогу помочь вам его решить…`,
        id: 'Q11_1',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        maxAnswers: 1,
        callToAction: 'Ну что, {{NAME}}, справишься?',
        buttons: [
          {
            id: 'b1',
            text: 'Конечно, бабуля! Других вариантов-то у нас все равно нет.',
            nextStep: 'Q11_2',
          },
        ],
      },

      Q11_2: {
        text: `У тебя будет 20 минут. Возможно, тебе понадобится ручка и лист бумаги, чтобы что-то вычислять. Очень внимательно читай вопросы.
        
        Важный момент: пройти тест можно только один раз. Повторно к нему вернуться будет нельзя. А это очень важно, понимаешь?
        
        Обрати внимание: так как ты проходишь тест после 16:00 7 июня, то баллы за этот тест тебе не будут начисляться.
        
        Выходить из теста во время его прохождения нельзя. Но если ты все-таки выйдешь из игры после запуска теста, независимо от причин, почему это произошло, таймер будет продолжать работать, а время, отведенное на тест, истекать. 
        
        Вопросы нельзя проматывать и возвращаться к предыдущим вопросам, поэтому прежде, чем начать тест, проверь, что у тебя:
           -   стабильно работает Интернет
           -   заряжено устройство, с которого ты проходишь тест
           -   и никто тебе не помешает в ближайшие 20 минут`,
        id: 'Q11_2',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Я [[готов/готова]]!',
            nextStep: 'TEST_LOGIC_MAIN_2024_START',
          },
        ],
      },

      ...TEST_LOGIC_MAIN_2024,

      Q12: {
        text: `Ой.. Оказывается, сейф всё это время был открыт! Но в любом случае здорово, что ты так [[старался/старалась]].
        
        За твое желание выручить друга я дарю тебе карманный радиоприёмник! Надеюсь, он вам пригодится!
        
        Вот, посмотрите, подойдёт ли?`,
        id: 'Q12',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        maxAnswers: 1,
        callToAction: 'Получен предмет “Карманный радиоприёмник”.',
        buttons: [
          {
            id: 'b1',
            text: 'Ух-ты, радио! Спасибо! Квилл, сможешь настроить его на свою волну?',
            nextStep: 'Q13',
          },
        ],
      },

      Q13: {
        text: `Шшшш… щщщ.. чччччч… кккк… Ка… жется работает. Мы снова можем коммуницировать. Спасибо, человек!`,
        id: 'Q13',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Квилл, у меня столько к тебе вопросов! ',
            nextStep: 'Q14',
          },
        ],
      },

      Q14: {
        text: `Погоди, давай сначала определимся с планом возвращения Квилла домой.

        Квилл, как нам пригласить твоих сородичей приземлиться и подобрать тебя? Это же их корабль парит за облаками?`,
        id: 'Q14',
        person: Interlocutors.PETROV,
        type: StepType.message,
        nextStep: 'Q15',
      },

      Q15: {
        text: `Мой вид не сможет приземлиться ни при каких обстоятельствах. Для них эта планета слишком неизведанна и может таить опасности.`,
        id: 'Q15',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Но ты же тут с нами? В полной безопасности!',
            nextStep: 'Q16',
          },
        ],
      },

      Q16: {
        text: `Да, я самый молодой из нас, а потому самый безрассудный. На ваши земные годы мне всего лишь 13 лет и 4 месяца`,
        id: 'Q16',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ой, мы почти ровесники 😁 Но как же нам тогда вернуть тебя домой?',
            nextStep: 'Q17',
          },
        ],
      },

      Q17: {
        text: `У вас есть средства телепортации? Или средства сверхсветового перемещения?`,
        id: 'Q17',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Э-э… У Петрова, может, найдутся такие?',
            nextStep: 'Q18',
          },
        ],
      },

      Q18: {
        text: `Нет, таких продвинутых технологий мы ещё не разработали. Но есть старенькая ракета на космодроме. Я её, правда, давно не проверял. А тут ещё эту радиостанцию чинить придётся.`,
        id: 'Q18',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Давайте мы с Вжухом и Квиллом сходим, проверим ракету?',
            nextStep: 'Q19',
          },
        ],
      },

      Q19: {
        text: `Разумно. Если захотите найти нас с Сан Санычем — мы будем в моей мастерской в Иннополисе. `,
        id: 'Q19',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо! Удачи вам!',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
