import Api from '../utils/Api';

import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { UserAppState, UserInfo } from '../redux/user';
import { isDebug } from '../../common/shared/constants/constants';
import { ProfileConfig } from '../components/Hero/config';
import { ApiUserGetInfoGetResponse } from '../types/api/user';

class UserApi {
  static getUserInfo(
    id: string,
    config?: AxiosRequestConfig,
  ): AxiosPromise<ApiUserGetInfoGetResponse> {
    return Api.call(`/users/${id}`, config);
  }

  // добавляет id добавленного видео к юзеру
  static setUserVideo(id: string, link: string): AxiosPromise<ApiUserGetInfoGetResponse> {
    const data = JSON.stringify({ type: 'presentation' });
    return Api.call(`/files/${link}/confirm`, { data, method: 'POST' });
  }

  static setUserChallengeCall(
    userID: string,
    challangeID: number,
  ): AxiosPromise<ApiUserGetInfoGetResponse> {
    const data = JSON.stringify({ type: 'challenge' });
    return Api.call(`/users/${userID}/set-challenge?challenge=${challangeID}`, {
      data,
      method: 'POST',
    });
  }

  static setUserState(id: string, state: UserAppState): AxiosPromise<ApiUserGetInfoGetResponse> {
    const data = JSON.stringify({ state });
    return Api.call(`/users/${id}`, { data, method: 'PATCH' });
  }

  static updateVariables(
    id: string,
    variables: UserInfo['variables'],
    profile?: UserInfo['profile'],
  ): AxiosPromise<ApiUserGetInfoGetResponse> {
    const body: { variables: UserInfo['variables']; profile?: UserInfo['profile'] } = { variables };
    if (profile) {
      body.profile = profile;
    }
    const data = JSON.stringify(body);
    return Api.call(`/users/${id}`, { data, method: 'PATCH' });
  }

  static setUserHero(id: string, profile: ProfileConfig): AxiosPromise<ApiUserGetInfoGetResponse> {
    const data = JSON.stringify({ profile });
    return Api.call(`/users/${id}`, { data, method: 'PATCH' });
  }

  static setUserFiles(): AxiosPromise<ApiUserGetInfoGetResponse> {
    const data = JSON.stringify({
      params: {
        type: 'presentation|challenge',
        confirmed: true,
      },
    });
    return Api.call(`/files/`, { data, method: 'GET' });
  }

  static setUserChallenge(
    id: string,
    profile: ProfileConfig,
  ): AxiosPromise<ApiUserGetInfoGetResponse> {
    const data = JSON.stringify({ profile });
    return Api.call(`/users/${id}`, { data, method: 'PATCH' });
  }

  static resetUserState(id: string, body: Partial<UserInfo> = {}): Promise<any> {
    if (isDebug) {
      const data = JSON.stringify({
        state: null,
        info: '',
        profile: null,
        variables: null,
        score: 0,
        ...body,
      });

      return Api.call(`/users/${id}`, {
        data,
        method: 'PATCH',
      });
    }

    return Promise.resolve('');
  }
}

export default UserApi;
