import styled from 'styled-components';
import colors from '../../../../common/shared/constants/colors';
import { TextProps } from '../../../../common/shared/ui/Text/Types';
import { TextStyled } from '../../../../common/shared/ui/Text';
import {
  BREAKPOINT_PHONE,
  BREAKPOINT_TABLET,
  GAP,
} from '../../../../common/shared/constants/constants';

interface IChatMessageStyled {
  $isMe?: boolean;
}

export const ChatMessageStyled = styled.div<IChatMessageStyled>`
  display: flex;
  align-items: flex-start;

  margin-top: 10px;

  &:first-child {
    margin-top: 30px;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    &:first-child {
      margin-top: 20px;
    }
  }

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    width: 85%;
    margin-top: 15px;
    ${({ $isMe }) => $isMe && 'margin-left: 15%;'};
  }

  @media screen and (min-width: ${BREAKPOINT_TABLET}) {
    width: 75%;
    margin-top: 10px;
    ${({ $isMe }) => $isMe && 'margin-left: 25%;'};
  }
`;

export const ChatMessageBubbleStyled = styled.div<IChatMessageStyled>`
  overflow: hidden;

  margin-left: ${GAP.s};
  background: ${colors.grey3};
  flex-grow: 1;
  border-radius: ${GAP.m};
  padding: ${GAP.s} ${GAP.ss};

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    padding: ${GAP.m} ${GAP.xl};
    margin-left: ${GAP.m};
  }

  ${({ $isMe }) =>
    $isMe &&
    `
    background: ${colors.blueMessage};
    color: ${colors.white};
  `}
`;

export const StyledChatMessageImage = styled.img`
  width: 100%;
  border-radius: 4px;
  margin-bottom: 10px;

  max-height: 380px;
  object-fit: contain;
  object-position: top left;

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    max-height: 280px;
    margin-bottom: 6px;
  }
`;

export const ChatMessageNameStyled = styled.div``;

export const ChatMessageHeaderStyled = styled(TextStyled)<TextProps>`
  width: 100%;
  margin-bottom: ${GAP.s};
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  margin-top: -${GAP.xxxs};
  margin-left: -${GAP.s};

  & > * {
    margin-top: ${GAP.xxxs};
    margin-left: ${GAP.s};
  }
`;

export const ChatMessageTimeStyled = styled.div<IChatMessageStyled>`
  font-size: 12px;
  color: ${colors.grey2};

  ${({ $isMe }) =>
    $isMe &&
    `
    color: ${colors.white};
  `}
`;

export const ChatUserImgStyled = styled.img`
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    width: 56px;
    min-width: 56px;
    height: 56px;
  }
`;
