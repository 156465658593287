/* eslint-disable max-len */
import {
  Action,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  Step,
  StepType,
  TestStep,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT_ID';

const TEST_TVOI_VECTOR: TestStep = {
  maxAnswers: 2,
  external_id: 'test-tvoi-vektor-junior-210401',
  id: 'TEST_TVOI_VECTOR',
  name: 'Твой вектор остаток',
  nextStep: 'Q6',
  order: [
    {
      description: '',
      subtest: 'T1',
      question: 'Q4',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q5',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q6',
    },
    {
      description:
        'Знаешь, {{NAME}}, у тебя ведь сейчас очень интересный возраст. Тебе уже достаточно лет, чтобы начать задумываться о своем будущем, о том, как ты хочешь прожить свою жизнь. И при этом, у тебя впереди много времени, чтобы осуществить все свои задумки и желания.',
      subtest: 'T1',
      question: 'Q9',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q18',
    },
    {
      description: 'Хорошо, пойдем дальше.',
      subtest: 'T1',
      question: 'Q20',
    },
    {
      description: '{{NAME}}, а как у тебя дела со школой?',
      subtest: 'T1',
      question: 'Q27',
    },
    {
      description: 'Ты знаешь, что важно не только хорошо трудиться, но и хорошо отдыхать?',
      subtest: 'T1',
      question: 'Q28',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q29',
    },
    {
      description: '',
      subtest: 'T1',
      question: 'Q36',
    },
    {
      description: '{{NAME}}, последний вопрос и закончим.',
      subtest: 'T1',
      question: 'Q38',
    },
  ],
  path: 'test-tvoi-vektor-junior-210401.json',
  person: Interlocutors.SYSTEM,
  type: StepType.test,
};

const ALT: Record<string, Step> = {
  ALT1: {
    person: Interlocutors.GRANDMOTHER,
    id: 'ALT1',
    text: `Это же моя подруга Евдокия! Кажется, она не может войти 😔`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Наверное, нужно подойти и спросить, что у нее случилось',
        nextStep: 'ALT2',
      },
      {
        id: 'b2',
        text: 'Я уже [[приложил/приложила]] пропуск, нет времени ждать 10 минут',
        nextStep: 'Q1',
      },
    ],
  },
  ALT2: {
    person: Interlocutors.GRANDMOTHER,
    id: 'ALT2',
    text: 'Наверняка она не поняла, как правильно оформить пропуск в терминале Иннополиса, ввела не все данные, и теперь ее пропуск недействителен 🙁',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Давайте, я помогу Вашей подруге правильно ввести данные и оформить работающий пропуск. (Игровое время будет заморожено на 15 минут)',
        nextStep: 'ALT3/0',
      },
      {
        id: 'b2',
        text: 'Давайте позвоним по телефону администратору и попросим помочь Евдокии с терминалом для оформления пропусков. А сами пойдем искать Петрова. (Игровое время будет заморожено на 7 минут)',
        nextStep: 'ALT4/0',
      },
      {
        id: 'b3',
        text: 'Давайте посоветуем Евдокии позвонить администратору. Он обязательно ей поможет. А сами пойдем искать Петрова. (Игровое время не будет заморожено)',
        nextStep: 'ALT5/0',
      },
    ],
  },
  ...splitStep({
    person: Interlocutors.GRANDMOTHER,
    id: 'ALT3',
    type: StepType.message,
    text: `Спасибо тебе огромное! Даже не знаю, сколько бы Евдокия еще здесь топталась, если бы не ты! 😍

А теперь пойдем искать Петрова.
`,
    nextStep: 'Q1',
  }),
  ...splitStep({
    person: Interlocutors.GRANDMOTHER,
    id: 'ALT4',
    type: StepType.message,
    text: `Как хорошо ты [[придумал/придумала]] с администратором! Евдокия в надежных руках 😊

А нам пора искать Петрова.`,
    nextStep: 'Q1',
  }),
  ...splitStep({
    person: Interlocutors.GRANDMOTHER,
    id: 'ALT5',
    type: StepType.message,
    text: `О, это хорошая идея, давай!

А нам пора искать Петрова.`,
    nextStep: 'Q1',
  }),
};

const ALT_PETROV: Record<string, Step> = {
  ALT_PETROV1: {
    person: Interlocutors.PETROV,
    id: 'ALT_PETROV1',
    text: `Надеюсь, Светлана Васильевна скоро вернется… А то дел в лаборатории накопилась целая куча 😔`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Светлана Васильевна надолго ушла? А то ведь ты нам очень нужен',
        nextStep: 'ALT_PETROV2',
      },
      {
        id: 'b2',
        text: 'Да, надеюсь она скоро вернется, ведь у нас к тебе тоже есть дело… ',
        nextStep: 'Q3',
      },
    ],
  },
  ALT_PETROV2: {
    person: Interlocutors.PETROV,
    id: 'ALT_PETROV2',
    text: 'Светлане Васильевне для следующего занятия нужна 3D ручка. У нее есть картриджи, но ручки нет. Вот она и побежала в МГУ, чтобы там поискать, вдруг найдется.',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Так у меня же как раз есть 3D ручка. Скорее звони Светлане Васильевне и скажи, чтобы возвращалась. Я дам ей свою ручку для урока. (Игровое время будет заморожено на 10 минут и вы лишитесь 3D ручки)',
        nextStep: 'ALT_PETROV3/0',
        action: Action.Lost3dPen,
      },
      {
        id: 'b2',
        text: 'Надо позвонить Сан Санычу, он любитель современных технологичных вещей. Скорее всего, у него есть 3D ручка. (Игровое время будет заморожено на 5 минут)',
        nextStep: 'ALT_PETROV4/0',
      },
      {
        id: 'b3',
        text: 'Да уж, должно быть Светлана Васильевна переживает, что не может найти 3D ручку. (Игровое время не будет заморожено)',
        nextStep: 'Q3',
      },
    ],
  },
  ...splitStep({
    person: Interlocutors.TEACHER,
    id: 'ALT_PETROV3',
    type: StepType.message,
    text: `Спасибо тебе огромное, {{NAME}}! Теперь занятие пройдет по плану 😊 

Я верну тебе ручку после урока.`,
    nextStep: 'Q3',
  }),
  ...splitStep({
    person: Interlocutors.PETROV,
    id: 'ALT_PETROV4',
    type: StepType.message,
    text: `Как здорово ты [[придумал/придумала]] с Сан Санычем! И чего я сам не догадался! Позвоню Светлане Васильевне и скажу, что ручку скоро доставят в Иннополис.`,
    nextStep: 'Q3',
  }),
};

export const scenario_3_3_0: Scenario = {
  scenarioId: SceneId.scenario_3_3_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Иннополис',
    text: 'Вы с Бабулей заходите в главное здание Иннополиса и прикладываете свои пропуска к турникетам на входе. Пропуском можно воспользоваться один раз в 10 минут. Бабуля проходит первой, оборачивается и удивленно восклицает. Вы замираете у турникета...',
    buttons: ['Закрыть'],
  },
};

const ERUDITION_TEST_STEP: TestStep = {
  id: 'ERUDITION_TEST_STEP',
  person: Interlocutors.PETROV,
  type: StepType.test,
  external_id: 'test-tvoi-krugozor-junior-t3-210519',
  path: 'test-tvoi-krugozor-junior-t3-210519.json',
  name: 'Эрудиция 2/2',
  order: [
    { timeout: 60, question: 'Q1', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q2', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q3', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q4', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q5', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q6', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q7', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q9', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q11', subtest: 'T1', description: '' },
    { timeout: 60, question: 'Q19', subtest: 'T1', description: '' },
  ],
  nextStep: 'ERUDITION_TEST_FINISH/0',
};

const ERUDITION_TEST: Record<string, Step> = {
  ERUDITION_TEST_1: {
    person: Interlocutors.PETROV,
    id: 'ERUDITION_TEST_1',
    text: 'Тест состоит из 10 вопросов. Ты [[должен/должна]] выбрать 1 вариант ответа из предложенных, и у тебя будет всего 1 минута на каждый вопрос.',
    type: StepType.question,
    callToAction: 'Начнем?',
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Да, давай!',
        nextStep: 'ERUDITION_TEST_STEP',
      },
    ],
  },
  ERUDITION_TEST_STEP,
  ...splitStep({
    person: Interlocutors.PETROV,
    id: 'ERUDITION_TEST_FINISH',
    text: `Ну вот и весь тест 😊

Как он тебе? Понравился?`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Ты был прав. Тест оказался очень интересным!',
        nextStep: 'ALT_PETROV1',
      },
      {
        id: 'b2',
        text: 'Ох, Петров! Было сложновато, но я, кажется, [[справился/справилась]]...',
        nextStep: 'ALT_PETROV1',
      },
    ],
  }),
};

export const scenario_3_3_i: Scenario = {
  scenarioId: SceneId.scenario_3_3_i,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Москва-Сити',
    text: '«Москва-Сити» такая же, какой вы её оставили. Башню «Эволюция» захватила дикая природа, а в башне «Меркурий» порвалась ткань Вселенной. Сейчас самое время навести порядок. С чего начнём?',
    buttons: ['Отправиться в башню «Эволюция»'],
  },
};

export const scenario_3_3: Scenario = {
  scenarioId: SceneId.scenario_3_3,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Иннополис',
    scene_id: 'scenario_3_3',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.SYSTEM,
        id: 'Q1',
        type: StepType.question,
        maxAnswers: 1,
        text: `В закручивающейся, словно ДНК-решётка, башне бушует дикая растительность: здесь словно находятся все растения мира. По гигантским тропическим деревьям перескакивают приматы; столы, которые некогда занимали офисные клерки, обросли мхом. На мониторе спит питон. Надо будет вернуть животных в места их обитания, а растения передать Ботаническому саду МГУ.

        «{{NAME}}, я тут», — слышится чей-то голос.
        `,
        buttons: [
          {
            id: 'b1',
            text: 'Пойти на голос',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        // ТЕСТ НА АЛЬТРУИЗМ 12
        person: Interlocutors.PETROV,
        id: 'Q2',
        type: StepType.question,
        maxAnswers: 1,
        text: `Спотыкаясь о стулья, заросшие мхом, и раздвигая ветви растений, вы приближаетесь к источнику звука. 
        Вы видите, что в ветвях высоко на дереве запутался Петров — он улыбается и машет вам. 
        
        «Я хотел тут сам разобраться, но, кажется, не выйдет. Удивительно, но, похоже тут был воссоздан процесс эволюции на тысячелетия. Я проверил: в чьей-то чашке из-под кофе образовался мукор — белая плесень, которая, соединившись с комнатными растениями и водорослями из аквариума, разнесла флору по всему зданию».
        
        Поможете мне спуститься?
        `,
        buttons: [
          {
            id: 'b1',
            text: 'Помочь Петрову',
            nextStep: 'Q3',
          },
          {
            id: 'b2',
            text: 'Простите, Петров, мы торопимся',
            nextStep: 'Q4',
          },
        ],
      },
      Q3: {
        // ТЕСТ НА АЛЬТРУИЗМ 13
        person: Interlocutors.PETROV,
        id: 'Q3',
        type: StepType.question,
        maxAnswers: 1,
        text: `«Спасибо, я знал, что ты не бросишь в беде. Возьми внизу секатор и разрежь ветви слоновой лианы, которые связали меня», - говорит Петров.
        Секатор лежит у корней гигантской пальмы, Петров очень высоко.
        `,
        buttons: [
          {
            id: 'b1',
            text: 'Вскарабкаться по пальме самостоятельно',
            nextStep: 'Q3_1',
          },
          {
            id: 'b2',
            text: '«Вжух, у тебя же цепкие когти?» - отправить Вжуха',
            nextStep: 'Q4',
          },
          {
            id: 'b3',
            text: 'Подкинуть секатор в надежде, что Петров поймает его',
            nextStep: 'Q3_2',
          },
        ],
      },
      Q3_1: {
        person: Interlocutors.SYSTEM,
        id: 'Q3_1',
        type: StepType.question,
        maxAnswers: 1,
        text: `Ты ползешь вверх по пальме, цепляясь за извивающиеся ветви слоновой лианы, как за канаты. Вдруг ты замечаешь нечто интересное — черный как смоль гриб переливается всеми цветами радуги. Ты срываешь гриб и ползёшь дальше.`,
        buttons: [
          {
            id: 'b1',
            text: 'Разрезать Лиану',
            nextStep: 'Q4',
          },
        ],
      },
      Q3_2: {
        // ТЕСТ НА АЛЬТРУИЗМ
        person: Interlocutors.SYSTEM,
        id: 'Q3_2',
        type: StepType.question,
        maxAnswers: 1,
        text: `Секатор приземляется на мягкий мох, едва не зацепив Вжуха. Кот стремительно отпрыгивает. Не лучший способ обращения с острыми предметами — попробуй действовать по-другому.`,
        buttons: [
          {
            id: 'b1',
            text: 'Попробовать другой способ',
            nextStep: 'Q3',
          },
        ],
      },
      Q4: {
        person: Interlocutors.PETROV,
        id: 'Q4',
        type: StepType.question,
        maxAnswers: 1,
        text: `«Фух, спасибо. Сейчас я вызову команду биологов и ботаников из МГУ — они организуют транспортировку растений и животных, а нам надо отправится в башню «Меркурий».`,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться в башню «Меркурий»',
            nextStep: 'Q5',
          },
        ],
      },
      Q5: {
        person: Interlocutors.SYSTEM,
        id: 'Q5',
        type: StepType.question,
        maxAnswers: 1,
        text: `Ты проходишь внутрь башни «Меркурий» и словно попадаешь в космос. Здесь будто нет стен - только безграничное чёрное пространство-материя. В центре висит огромная планета, переливающаяся золотыми, голубыми и серыми огнями — Меркурий.

        Кажется, «Интеллект 2.0» и здесь нарушил ткань пространства. Нужно подумать, как мы можем все исправить. Правильно решение придет к тебе, когда ты ответишь на все вопросы, которые крутятся у тебя в голове.
        `,
        buttons: [
          {
            id: 'b1',
            text: 'Пройти тест',
            nextStep: 'TEST_TVOI_VECTOR',
          },
        ],
      },

      TEST_TVOI_VECTOR: TEST_TVOI_VECTOR, // твой векто джуниор  остаток

      Q6: {
        person: Interlocutors.PETROV,
        id: 'Q6',
        type: StepType.question,
        maxAnswers: 1,
        text: `«Нам нужно собрать все подсказки и понять, что здесь произошло и как это исправить».`,
        buttons: [
          {
            id: 'b1',
            text: 'Исследовать планету',
            nextStep: 'Q6_1',
          },
        ],
      },
      Q6_1: {
        person: Interlocutors.SYSTEM,
        id: 'Q6_1',
        type: StepType.question,
        maxAnswers: 1,
        text: `В центре башни крутится точная копия планеты Меркурий, но уменьшенная в миллионы раз. Размером она где-то в 10 этажей. Интересно, как планета могла уменьшиться?`,
        buttons: [
          {
            id: 'b1',
            text: 'Исследовать башню «Меркурий»',
            nextStep: 'Q6_2',
          },
        ],
      },
      Q6_2: {
        person: Interlocutors.SYSTEM,
        id: 'Q6_2',
        type: StepType.question,
        maxAnswers: 1,
        text: `Стены башни «пропадают» и поглощаются тьмой. Вы словно выходите в открытый космос. На границах пересечения темноты и теней образовалось очень яркое свечение. 

        «Не подходите туда!» — кричит Петров. — «Кажется, это горизонт событий».
        `,
        buttons: [
          {
            id: 'b1',
            text: 'Поговорить с Петровым',
            nextStep: 'Q7',
          },
        ],
      },
      Q7: {
        // ТЕСТ НА АЛЬТРУИЗМ 14
        person: Interlocutors.PETROV,
        id: 'Q7',
        type: StepType.question,
        maxAnswers: 1,
        text: `«Так, кажется, я понял: «Интеллект 2.0» сдвинул системы координат нашей планеты и границы физического мира. То, что мы здесь видим — это кротовая нора, которая, исказив масштабы планеты Меркурий, благодаря квантовой запутанности, перенесла сюда её маленькую копию. 
        Нам нужно отправить двойника планеты обратно на орбиту и закрыть кротовую нору, но для этого понадобится невероятная по мощности волна.
        Мне понадобится ваша помощь!».
        `,
        buttons: [
          {
            id: 'b1',
            text: 'Конечно! Для этого мы здесь.',
            nextStep: 'Q8',
          },
          {
            id: 'b2',
            text: 'Кажется, это слишком сложно, мы же не учёные!',
            nextStep: EXIT_ID,
          },
        ],
      },
      Q8: {
        // ТЕСТ НА АЛЬТРУИЗМ 15
        person: Interlocutors.PETROV,
        id: 'Q8',
        type: StepType.question,
        maxAnswers: 1,
        text: `Петров нажимает кнопку в стене, оттуда выезжает огромный аппарат, похожий на бублик. 
        Это Терменвокс X — волновой аппарат, способный создать сильнейшие волны, которые не слышны уху человека. Раньше такие аппараты работали на газу, но для нашего — нужно нечто гораздо более мощное. 
        
        С его помощью мы сможем создать достаточно мощную волну, чтобы откинуть мини-Меркурий обратно и закрыть кротовую нору.
        
        Есть только три варианта топлива для такого аппарата:
        `,
        buttons: [
          {
            id: 'b1',
            text: 'Энергия от усердной учёбы ученика 5-7 классов. Нужно учиться 8 часов подряд.',
            nextStep: 'Q9',
          },
          {
            id: 'b2',
            text: 'Энергия статического электричества, которое появляется при поглаживании магического кота. Гладить Вжуха 5 часов подряд.',
            nextStep: 'Q9',
          },
          {
            id: 'b3',
            text: 'Энергия топливного гриба.',
            nextStep: 'Q8_1',
          },
        ],
      },
      Q8_1: {
        person: Interlocutors.SYSTEM,
        id: 'Q8_1',
        type: StepType.question,
        maxAnswers: 1,
        text: `Ты достаёшь из кармана смолянистый чёрный гриб и протягиваешь Петрову. 
        «Топливный гриб!» — восклицает поражённый Петров. «Мало кто встречал их в жизни, говорили даже, что их не существует. Давай скорее, он точно даст достаточное количество энергии».
        `,
        buttons: [
          {
            id: 'b1',
            text: 'Запустить гриб в Терменвокс',
            nextStep: 'Q9',
          },
        ],
      },
      Q9: {
        person: Interlocutors.SYSTEM,
        id: 'Q9',
        type: StepType.question,
        maxAnswers: 1,
        text: `Терменвокс начинает работать: его длинная антенна начинает вращаться и, в конце концов, образовывает гигантскую воронку, которая отпускает волновые круги в воздухе, словно на воде. 
        Мини-Меркурий начинает вращаться и стремительно удаляется от вас. С коротким пронзительным звуком кротовая нора закрывается. Свет пропадает совсем, а затем включаются лампы и освещают кабинеты башни. 
        
        Невероятно! Кажется, работа сделана!
        `,
        buttons: [
          {
            id: 'b1',
            text: 'Поговорить с Петровым',
            nextStep: 'Q10',
          },
        ],
      },
      Q10: {
        person: Interlocutors.PETROV,
        id: 'Q10',
        type: StepType.question,
        maxAnswers: 1,
        text: `Теперь нужно убедится, что Меркурий оказался на своём месте в Млечном Пути, и восстановить связь по всему парку. Пожалуйста, отправляйтесь на космодром.`,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться на Космодром',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.TEACHER,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};

// export const scenario_3_3: Scenario = {
//   scenarioId: SceneId.scenario_3_3,
//   type: ScenarioType.chat,
//   isRequired: true,
//   data: {
//     timeoutMessage: 'К сожалению, время для ответа истекло 🙁',
//     title: 'Иннополис',
//     scene_id: 'scenario_3_3',
//     startId: 'ALT1',
//     steps: {
//       ...ALT,

//       Q1: {
//         person: Interlocutors.SYSTEM,
//         id: 'Q1',
//         text: 'Вы находите Петрова в лектории Иннополиса и видите перед ним класс учеников…',
//         type: StepType.message,
//         nextStep: 'Q2/0',
//       },
//       ...splitStep({
//         person: Interlocutors.PETROV,
//         id: 'Q2',
//         text: `О, привет, {{NAME}}! Здравствуйте, Бабуля! А я сегодня подменяю Светлану Васильевну. Она поручила мне провести для ребят очень интересный тест.

// {{NAME}}, хочешь с нами? Тебе наверняка понравится 😉`,
//         type: StepType.question,
//         maxAnswers: 1,
//         buttons: [
//           {
//             id: 'b1',
//             text:
//               'Спасибо, что предложил! Я как раз [[хотел/хотела]] размять мозги, поэтому с удовольствием пройду твой тест!',
//             nextStep: 'ERUDITION_TEST_1',
//           },
//           {
//             id: 'b2',
//             text: 'Ну раз ты говоришь, что тест очень интересный, я в деле!',
//             nextStep: 'ERUDITION_TEST_1',
//           },
//         ],
//       }),

//       ...ERUDITION_TEST,

//       ...ALT_PETROV,

//       Q3: {
//         person: Interlocutors.PETROV,
//         id: 'Q3',
//         type: StepType.message,
//         text: `А кстати, зачем вы меня искали?`,
//         nextStep: 'Q4',
//       },
//       Q4: {
//         person: Interlocutors.GRANDMOTHER,
//         id: 'Q4',
//         type: StepType.message,
//         text: `Мы тебе кирку принесли!`,
//         nextStep: 'Q5',
//       },
//       Q5: {
//         person: Interlocutors.PETROV,
//         id: 'Q5',
//         type: StepType.message,
//         text: `Какую такую кирку? Зачем?`,
//         nextStep: 'Q6/0',
//       },

//       ...splitStep({
//         person: Interlocutors.GRANDMOTHER,
//         id: 'Q6',
//         type: StepType.message,
//         text: `Помнишь, мы приступили к расследованию дела Вжуха и решили начать распутывать этот клубок с поезда в «Артек»? В вагоне, где НЕКТО (предположительно Вжух) нажал стоп-кран, мы нашли эту кирку.

// Ты очень умный, вот мы и подумали, что ты можешь знать, откуда эта вещь взялась...
// `,
//         nextStep: 'Q7',
//       }),
//       Q7: {
//         person: Interlocutors.PETROV,
//         id: 'Q7',
//         type: StepType.question,
//         maxAnswers: 1,
//         buttons: [
//           {
//             action: Action.LostPick,
//             id: 'b1',
//             text: 'Отдать кирку',
//             nextStep: 'Q8',
//           },
//         ],
//         text: `Вот оно что! Ну, показывайте свою кирку. Надо ее осмотреть.`,
//       },
//       Q8: {
//         person: Interlocutors.SYSTEM,
//         id: 'Q8',
//         type: StepType.message,
//         text: `Вы передаете кирку Петрову.`,
//         nextStep: 'Q9/0',
//       },
//       ...splitStep({
//         person: Interlocutors.PETROV,
//         id: 'Q9',
//         type: StepType.message,
//         text: `Хм… Кажется, я такую уже где-то видел, но не припомню где... 🤔

// Значит, воспользуемся любимым методом Шерлока Холмса — дедукцией! Эту кирку явно активно использовали в работе, она совсем неновая. К тому же на ней куски глины… Значит, ее использовали для работы с твердой породой земли… 🤔`,
//         nextStep: 'Q10',
//       }),
//       Q10: {
//         person: Interlocutors.GRANDMOTHER,
//         id: 'Q10',
//         type: StepType.message,
//         text: `Но у нас в «Большой перемене» нет ни шахт, ни горнодобывающих предприятий… А в зоне Природных достояний вообще нельзя ничего копать.`,
//         nextStep: 'Q11/0',
//       },
//       ...splitStep({
//         person: Interlocutors.PETROV,
//         id: 'Q11',
//         type: StepType.message,
//         text: `Точно! Копать! Я вспомнил, где видел подобные инструменты — на археологических раскопках в ХЕРСОНЕСЕ. Следующую улику надо искать там 🧐

// Пойдемте все вместе. Теперь расследование и меня заинтересовало.`,
//         nextStep: EXIT_ID,
//       }),

//       [EXIT_ID]: {
//         person: Interlocutors.TEACHER,
//         id: EXIT_ID,
//         type: StepType.exit,
//       },
//     },
//   },
// };
