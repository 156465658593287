/* eslint-disable max-len */
import {
  Conditions,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  Step,
  StepType,
  SystemStepType,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';
import { images } from '../../tests/images';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

const LOGIC: Record<string, Step> = {
  ...splitStep({
    person: Interlocutors.PETROV,
    callToAction: 'Ты [[готов/готова]]?',
    id: 'LOGIC',
    text: `А по пути, чтобы ты не [[скучал/скучала]], я задам задачку.
Возможно, понадобятся ручка и лист бумаги для расчетов.

У тебя будет ровно 2 минуты на решение. Нужно будет дать 4 ответа, вписывай их по очереди — по одному в поле ввода и затем жми «Отправить ответ».`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Погнали!',
        nextStep: 'LOGIC_TEST',
      },
    ],
  }),

  LOGIC_TEST: {
    person: Interlocutors.PETROV,
    nextStep: 'LOGIC_TEST_PART1_Q5',
    id: 'LOGIC_TEST_PART1_4',
    type: StepType.system,
    _meta: {
      type: SystemStepType.limitedTimeGroup,
      time: 2 * 60,
      timeoutStep: 'LOGIC_TEST_TIMEOUT',
      exitStep: 'LOGIC_TEST_FINISH',
    },
  },

  LOGIC_TEST_PART1_Q5: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5',
    text: 'Расположи числа 4, 2, 6, 1 на месте фигур (квадрата, звездочки, треугольника и круга) так, чтобы стрелки шли от большего числа к меньшему:',
    image: { uri: images.logic_fix, title: '' },
    type: StepType.message,
    nextStep: 'LOGIC_TEST_PART1_Q5_1',
  },
  LOGIC_TEST_PART1_Q5_1: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5_1',
    text: 'Что на месте квадрата?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_1',
    nextStep: 'LOGIC_TEST_PART1_Q5_2',
  },
  LOGIC_TEST_PART1_Q5_2: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5_2',
    text: 'На месте звездочки?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_2',
    nextStep: 'LOGIC_TEST_PART1_Q5_3',
  },
  LOGIC_TEST_PART1_Q5_3: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5_3',
    text: 'На месте треугольника?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_3',
    nextStep: 'LOGIC_TEST_PART1_Q5_4',
  },
  LOGIC_TEST_PART1_Q5_4: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5_4',
    text: 'На месте круга?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_4',
    nextStep: 'LOGIC_TEST_FINISH',
  },
  LOGIC_TEST_FINISH: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_FINISH',
    text: 'Я не сомневался, что ты справишься! И мы как раз дошли до холла 😊',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Это было просто!',
        nextStep: 'Q3',
      },
      {
        id: 'b2',
        text: 'Пришлось подумать, но я [[смог/смогла]]!',
        nextStep: 'Q3',
      },
    ],
  },
  LOGIC_TEST_TIMEOUT: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_TIMEOUT',
    text: '{{NAME}}, увы, время закончилось 🙁',
    type: StepType.message,
    nextStep: 'Q3',
  },
};

const ALT_TEXT = `У нас сейчас проходит акция по наполнению библиотеки научного центра Иннополиса редкими книгами. Каждый желающий может пожертвовать библиотеке свою книгу или купить что-то на этой ярмарке и подарить. 

Вот, кстати, смотри, там продают одну редкую книгу… Я всегда мечтал, чтобы у нас в Иннополисе такая была — это книга Стивена Хокинга. Но только, кажется, она стоит целое состояние! 😱`;

const ALT_BUTTONS = [
  {
    id: 'b2',
    text: 'Да, действительно дорого! Но я знаю, как быть. У меня как раз есть точно такая же книга, мне ее Сан Саныч подарил! И она даже еще уникальнее, чем книга с ярмарки, потому что ее лично подписал сам Стивен Хокинг! Вот держи, я тоже хочу поучаствовать в акции и подарить вашей библиотеке эту книгу. (Игровое время будет заморожено на 15 минут и вы лишитесь книги с автографом Стивена Хокинга)',
    nextStep: 'ALT4/0',
  },
  {
    id: 'b3',
    text: 'А давай попросим Сан Саныча купить эту книгу для библиотеки! Уверен, что он согласится! (Игровое время не будет заморожено)',
    nextStep: 'ALT5',
  },
];

const ALT: Record<string, Step> = {
  ALT_CONDITION: {
    id: 'ALT_CONDITION',
    type: StepType.system,
    condition: {
      type: Conditions.HAS_COINS,
      variants: ['ALT1/0', 'ALT2/0'],
    },
    _meta: {},
    person: Interlocutors.PETROV,
  },
  ...splitStep({
    person: Interlocutors.PETROV,
    id: 'ALT1',
    text: ALT_TEXT,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Не переживай, Петров, я как раз [[нашел/нашла]] сегодня в кустах монеты! Мне они не особо нужны. Может быть кто-то еще добавит и хватит на книгу. (Игровое время будет заморожено на 3 минуты и вы лишитесь монет)',
        nextStep: 'ALT3',
      },
      ...ALT_BUTTONS,
    ],
  }),
  ...splitStep({
    person: Interlocutors.PETROV,
    id: 'ALT2',
    text: ALT_TEXT,
    type: StepType.question,
    maxAnswers: 1,
    buttons: ALT_BUTTONS,
  }),
  ALT3: {
    person: Interlocutors.PETROV,
    id: 'ALT3',
    type: StepType.message,
    text: `Как здорово! Неужели моей мечте суждено сбыться?! Пойду искать оставшиеся монеты! Спасибо тебе большое! 😍`,
    nextStep: 'Q4',
  },
  ...splitStep({
    person: Interlocutors.PETROV,
    id: 'ALT4',
    type: StepType.message,
    text: `Как здорово! Моя мечта сбылась! Неужели в нашей библиотеке появится такая удивительная книга да еще и с личным автографом самого Хокинга! 😍

Это очень щедрый подарок! Спасибо! 😍`,
    nextStep: 'Q4',
  }),
  ALT5: {
    person: Interlocutors.PETROV,
    id: 'ALT5',
    type: StepType.message,
    text: `Да, думаю Сан Саныч не откажет 😊`,
    nextStep: 'Q4',
  },
};

export const scenario_2_23: Scenario = {
  scenarioId: SceneId.scenario_2_23,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Иннополис',
    scene_id: 'scenario_2_23',
    startId: 'Q0/0',
    steps: {
      ...splitStep({
        id: 'Q0',
        person: Interlocutors.PETROV,
        text: `Привет, {{NAME}}! Рад снова тебя видеть! [[Видел/Видела]], как классно наша тепловая пушка здесь все растопила? Наконец-то «Большая перемена» приобретает прежний прекрасный облик! 😍

Кстати, нам в лабораторию привезли кое-что необычное! Мне не терпится тебе показать эту штуковину… 😉`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Не томи, показывай, что там у тебя?',
            nextStep: 'Q1/0',
          },
          {
            id: 'b2',
            text: 'Давай, я посмотрю на твою чудо-машину в другой раз',
            nextStep: TO_MAP_ID,
          },
        ],
      }),
      ...splitStep({
        person: Interlocutors.PETROV,
        id: 'Q1',
        text: `Вот смотри! Это машина по преобразованию и улучшению вещей. С ее помощью улучшить можно все, что пожелаешь! Ну, почти все… Главное, чтобы предмет влез по размеру 😁

Я ждал тебя, чтобы провести первый эксперимент! Подготовил для этого один предмет, давай, для начала преобразуем его? 
Надо будет положить предмет внутрь и записывать параметры улучшения.

У тебя будет ровно 10 минут.`,
        type: StepType.question,
        callToAction: 'Справишься?',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Конечно! Запускай свою машину!',
            nextStep: 'CREATIVE/0',
          },
          {
            id: 'b2',
            text: 'Ну давай попробуем, как твоя машина работает...',
            nextStep: 'CREATIVE/0',
          },
        ],
      }),

      ...splitStep({
        id: 'CREATIVE',
        person: Interlocutors.PETROV,
        delay: 7000,
        type: StepType.message,
        text: `Перед тобой игрушечный слон. Как можно его изменить, чтобы детям стало интереснее играть? Придумай самые удачные, оригинальные и необычные способы изменения игрушечного слона. 

Не беспокойся о том, насколько сложно будет осуществить твои изменения. Думай только, как можно улучшить эту игрушку. Перечисли все возможные изменения слона и запиши ниже. Напоминаю, у тебя на это есть 10 минут.

Записывай по одному варианту в поле ввода и обязательно жми кнопку «Отправить ответ». Для следующей твоей идеи появится новое поле ввода. То есть нужно писать и отправлять по одной идее за раз. Всего ты можешь предложить 15 вариантов улучшений, так что чем больше — тем лучше.

И не забудь, твои варианты должны быть приличными и осмысленными.`,
        nextStep: 'CREATIVE1',
      }),
      CREATIVE1: {
        person: Interlocutors.PETROV,
        id: 'CREATIVE1',
        type: StepType.message,
        image: { uri: images.elephant, title: '' },
        text: `Ну все, время пошло! А вот и слон, которого ты будешь улучшать. Пиши свои варианты!`,
        nextStep: 'CREATIVE2',
      },

      CREATIVE2: {
        id: 'CREATIVE2',
        person: Interlocutors.PETROV,
        type: StepType.system,
        _meta: {
          type: SystemStepType.limitedTimeInput,
          time: 10 * 60,
          maxAnswers: 15,
          exitStep: 'CREATIVE-EXIT/0',
        },
        nextStep: 'CREATIVE3',
      },
      CREATIVE3: {
        id: 'CREATIVE3',
        person: Interlocutors.PETROV,
        text: '',
        type: StepType.text_question,
        variable: 'CREATIVE_ELEPHANT',
        nextStep: 'CREATIVE3',
      },
      ...splitStep({
        id: 'CREATIVE-EXIT',
        person: Interlocutors.PETROV,
        type: StepType.message,
        text: `Вот это да! Вот это крутые улучшения! Уверен, что с такими слонами детям будет играть намного веселее! 😍

Ах да, на этой новой машине новости Иннополиса не кончаются! Пойдем в холл научного центра, там сейчас проходит интересная акция.`,
        nextStep: 'LOGIC/0',
      }),

      ...LOGIC,

      Q3: {
        person: Interlocutors.SYSTEM,
        id: 'Q3',
        type: StepType.message,
        text: `Вы проходите в холл научного центра Иннополиса и видите перед собой книжную ярмарку.`,
        nextStep: 'ALT_CONDITION',
      },

      ...ALT,

      Q4: {
        person: Interlocutors.SYSTEM,
        id: 'Q4',
        type: StepType.message,
        text: `Многие обитатели «Большой перемены» решили поучаствовать в акции Иннополиса и тоже поспешили на ярмарку. Вы встречаете Бабулю, Светлану Васильевну и Сан Саныча.`,
        nextStep: 'Q5/0',
      },

      ...splitStep({
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q5',
        type: StepType.message,
        text: `Хорошо, что все в сборе. У меня есть для вас сногсшибательные новости. 

Помните, я проводил расследование, чтобы узнать кто украл ракету с Космодрома?

ПОХИТИТЕЛЬ НАЙДЕН! 😑`,
        nextStep: 'Q6',
      }),
      Q6: {
        person: Interlocutors.PETROV,
        id: 'Q6',
        type: StepType.message,
        text: `Оу! И кто это? 😱`,
        nextStep: 'Q7',
      },
      Q7: {
        person: Interlocutors.TEACHER,
        id: 'Q7',
        type: StepType.message,
        text: `Вот так новости! 😱`,
        nextStep: 'Q8',
      },
      Q8: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q8',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Сан Саныч, Вы закончили расследование?! Говорите же скорее!',
            nextStep: 'Q9/0',
          },
        ],
        text: `Это же тот самый ночной расхититель моей чудесной кухни! 😱`,
      },

      ...splitStep({
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q9/0',
        type: StepType.message,
        text: `Во всем виноват… 

Вы только присядьте, ради всего святого… Это вас может шокировать! 😔`,
        nextStep: 'Q10',
      }),
      Q10: {
        person: Interlocutors.TEACHER,
        id: 'Q10',
        type: StepType.message,
        text: `Да говорите же уже! 😑`,
        nextStep: 'Q11',
      },
      Q11: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q11',
        type: StepType.message,
        text: `Во всем виноват ОН!!! 😱`,
        nextStep: 'Q12',
      },
      Q12: {
        person: Interlocutors.SYSTEM,
        id: 'Q12',
        type: StepType.message,
        text: `Сан Саныч показывает на вход. На ярмарку медленно входит довольный ВЖУХ...`,
        nextStep: EXIT_ID,
      },
      [EXIT_ID]: {
        person: Interlocutors.PETROV,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.PETROV,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
