/* eslint-disable max-len */
import { useCallback, useEffect } from 'react';

import { ID, to } from './scenarioHelpers/ID';
import { isDebug } from '../../common/shared/constants/constants';
import { setFreezeStateAction } from '../redux/freeze/freezeActions';
import { AnswerStep, QuestionButton, SceneId, Step, StepType } from '../types/scenario';

import { initialState as initialFrozenState } from '../redux/freeze';
import { useDispatch, useSelector } from 'react-redux';
import { selectScenarios } from '../redux/user/userGetters';
import { selectFrozenState } from '../redux/freeze/freezeGetters';
import { ScenarioState } from '../redux/scenario';

const checkIsFreezing = (duration: number, startDate: string) => {
  const now = new Date().valueOf();
  const start = new Date(startDate).valueOf();

  return now - duration * 1000 * 60 < start;
};

export interface FreezeState {
  text: string;
  start: string;
  title: string;
  duration: number;
  isOpened: boolean;
}

function checkHermitageAltruism(hermitageAlt1History?: Step[]): FreezeState | undefined {
  if (hermitageAlt1History) {
    const altQuestionIndex = hermitageAlt1History.findIndex((step) => step.id === 'ALT_Q2');
    if (altQuestionIndex !== -1) {
      const answer = hermitageAlt1History[altQuestionIndex + 1] as AnswerStep | undefined;
      if (answer) {
        const button = (answer.data.value as QuestionButton[])[0];

        const DURATION_B1 = isDebug ? 0.5 : 6 * 60;
        const DURATION_B2 = isDebug ? 0.5 : 3 * 60;

        if (button) {
          switch (button.id) {
            case 'b1':
              return {
                isOpened: checkIsFreezing(DURATION_B1, answer.date),
                text: 'Вы пытаетесь добраться до нее, но это оказалось не так просто и процесс затянулся...',
                title: 'Спасение кошки',
                start: answer.date,
                duration: DURATION_B1,
              };
            case 'b2':
              return {
                isOpened: checkIsFreezing(DURATION_B2, answer.date),
                start: answer.date,
                duration: DURATION_B2,
                text: 'Найти охранника в запутанных залах Эрмитажа оказалось непросто. Вы все еще ищете его, чтобы позвать на помощь.',
                title: 'Спасение кошки',
              };
            case 'b3':
              return undefined;
          }
        }
      }
    }
  }
  return undefined;
}

function checkBridgeAltruism(bridgeHistory?: Step[]): FreezeState | undefined {
  if (bridgeHistory) {
    const firstAnswer = bridgeHistory.find((s) => s.type === StepType.answer) as
      | AnswerStep
      | undefined;
    if (firstAnswer) {
      const value: QuestionButton = (firstAnswer.data.value as QuestionButton[])[0];

      const DURATION_B2 = isDebug ? 0.5 : 5;
      const DURATION_B3 = isDebug ? 0.5 : 15;

      if (value.id === 'b2') {
        return {
          isOpened: checkIsFreezing(DURATION_B2, firstAnswer.date),
          start: firstAnswer.date,
          duration: DURATION_B2,
          text: 'Вы ждете, пока Вжух подойдет, чтобы объяснить, какая помощь нужна Бабуле.',
          title: 'Мост на остров Русский',
        };
      }

      if (value.id === 'b3') {
        return {
          isOpened: checkIsFreezing(DURATION_B3, firstAnswer.date),
          start: firstAnswer.date,
          duration: DURATION_B3,
          text: 'Вы с Бабулей медленно идете к пешеходному переходу.',
          title: 'Мост на остров Русский',
        };
      }
    }
  }
  return undefined;
}

function checkMoscowCityLift(liftHistory?: Step[]): FreezeState | undefined {
  if (liftHistory) {
    // идти с конца?

    // увидели человека
    const altQ2 = liftHistory.findIndex((step) => step.id === 'ALT2');
    if (altQ2 !== -1) {
      const answerQ2 = liftHistory[altQ2 + 1] as AnswerStep | undefined;
      if (answerQ2) {
        const value: QuestionButton = (answerQ2.data.value as QuestionButton[])[0];

        const DURATION_B2 = isDebug ? 0.5 : 2;
        const DURATION_B3 = isDebug ? 0.5 : 5;

        if (value.id === 'b2') {
          return {
            isOpened: checkIsFreezing(DURATION_B2, answerQ2.date),
            start: answerQ2.date,
            duration: DURATION_B2,
            text: 'Вы договариваетесь с охранником, стоящим рядом с лифтом, это занимает у вас 2 минуты.',
            title: 'Москва-Сити',
          };
        }
        if (value.id === 'b3') {
          return {
            isOpened: checkIsFreezing(DURATION_B3, answerQ2.date),
            start: answerQ2.date,
            duration: DURATION_B3,
            text: 'Вы наблюдаете, как незнакомец заканчивает собирать листы с пола и внимательно укладывает их в папки, чтобы ничего не перепутать. Ожидание затягивается...',
            title: 'Москва-Сити',
          };
        }
      }
    }

    // увидели человека
    const altQ1 = liftHistory.findIndex((step) => step.id === 'ALT1');
    if (altQ1 !== -1) {
      const answerQ1 = liftHistory[altQ1 + 1] as AnswerStep | undefined;
      if (answerQ1) {
        const value: QuestionButton = (answerQ1.data.value as QuestionButton[])[0];

        const DURATION_B2 = isDebug ? 0.5 : 1;

        if (value.id === 'b2') {
          return {
            isOpened: checkIsFreezing(DURATION_B2, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B2,
            text: 'Листы из папок разлетелись по всему полу. Незнакомец старается быстрее их собрать, но листов слишком много... Вам приходится подождать.',
            title: 'Москва-Сити',
          };
        }
      }
    }
  }
  return undefined;
}

function checkMoscowCityCleaning(cleaningHistory?: Step[]): FreezeState | undefined {
  if (cleaningHistory) {
    const qIndex = cleaningHistory.findIndex((step) => step.id === 'Q18_1');
    if (qIndex !== -1) {
      const answerQ1 = cleaningHistory[qIndex + 1] as AnswerStep | undefined;
      if (answerQ1) {
        const value: QuestionButton = (answerQ1.data.value as QuestionButton[])[0];

        const DURATION_B1 = isDebug ? 0.5 : 60;
        const DURATION_B2 = isDebug ? 0.5 : 5;

        if (value.id === 'b1') {
          return {
            isOpened: checkIsFreezing(DURATION_B1, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B1,
            text: 'Вы начинаете наводить порядок в новом офисе Сан Саныча. Работы оказалось немало. Придется повозиться...',
            title: 'Москва-Сити',
          };
        }
        if (value.id === 'b2') {
          return {
            isOpened: checkIsFreezing(DURATION_B2, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B2,
            text: 'Вы вспоминаете, что как раз видели Вжуха, гуляющего по 45 этажу. Выходите из офиса и через 5 минут находите кота у окна, смотрящего вдаль... Вжух соглашается помочь Сан Санычу.',
            title: 'Москва-Сити',
          };
        }
      }
    }
  }
  return undefined;
}

function checkGrandmotherFriendAltruism(hist?: Step[]): FreezeState | undefined {
  if (hist) {
    const qIndex = hist.findIndex((step) => step.id === 'ALT');
    if (qIndex !== -1) {
      const answerQ1 = hist[qIndex + 1] as AnswerStep | undefined;
      if (answerQ1) {
        const value: QuestionButton = (answerQ1.data.value as QuestionButton[])[0];

        const DURATION_B2 = isDebug ? 0.5 : 10;
        const DURATION_B3 = isDebug ? 0.5 : 5;

        if (value.id === 'b2') {
          return {
            isOpened: checkIsFreezing(DURATION_B2, answerQ1.date),

            start: answerQ1.date,
            duration: DURATION_B2,
            text: 'Вы начинаете искать визитку благотворительной организации. Куда же она запропастилась?.. Через 10 минут вдруг вспоминаете, что положили ее в маленький внутренний отдел рюкзака. Вы отдаете визитку Аркадию.',
            title: 'Космодром',
          };
        }
        if (value.id === 'b3') {
          return {
            isOpened: checkIsFreezing(DURATION_B3, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B3,
            text: 'Вы достаете из рюкзака толстовку и передаете ее Аркадию. Замерзший мужчина долго благодарит вас и сразу же надевает теплую вещь. Толстовка пришлась Аркадию впору.',
            title: 'Космодром',
          };
        }
      }
    }
  }
  return undefined;
}

function checkHelpClimbUpGrandmotherFriendAltruismHistory(hist?: Step[]): FreezeState | undefined {
  if (hist) {
    const qIndex = hist.findIndex((step) => step.id === 'ALT1');
    if (qIndex !== -1) {
      const answerQ1 = hist[qIndex + 1] as AnswerStep | undefined;
      if (answerQ1) {
        const value: QuestionButton = (answerQ1.data.value as QuestionButton[])[0];

        const DURATION_B1 = isDebug ? 0.5 : 15;
        const DURATION_B2 = isDebug ? 0.5 : 5;

        if (value.id === 'b1') {
          return {
            isOpened: checkIsFreezing(DURATION_B1, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B1,
            text: 'Вы спустились по длинной лестнице вниз и начали помогать приятелю Бабули. Для этого вам понадобилось 15 минут.',
            title: 'Мамаев курган',
          };
        }
        if (value.id === 'b2') {
          return {
            isOpened: checkIsFreezing(DURATION_B2, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B2,
            text: 'Поиски полицейского занимают у вас 5 минут.',
            title: 'Мамаев курган',
          };
        }
      }
    }
  }
  return undefined;
}

function checkCinemaHelpAltruismHistory(hist?: Step[]): FreezeState | undefined {
  if (hist) {
    const qIndex = hist.findIndex((step) => step.id === 'ALT1');
    if (qIndex !== -1) {
      const answerQ1 = hist[qIndex + 1] as AnswerStep | undefined;
      if (answerQ1) {
        const value: QuestionButton = (answerQ1.data.value as QuestionButton[])[0];

        const DURATION_B2 = isDebug ? 0.5 : 20;
        const DURATION_B3 = isDebug ? 0.5 : 2 * 60;

        if (value.id === 'b2') {
          return {
            isOpened: checkIsFreezing(DURATION_B2, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B2,
            text: 'Вы отправляетесь в центр бизнеса «Большой перемены» — главное здание Москва-Сити на поиски мастера по видеосъемке. Это занимает у вас 20 минут.',
            title: 'Москва-Сити',
          };
        }
        if (value.id === 'b3') {
          return {
            isOpened: checkIsFreezing(DURATION_B3, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B3,
            text: 'На обучение помощника Сан Саныча искусству видеосъемки на телефон у вас уходит 2 часа.',
            title: 'Москва-Сити',
          };
        }
      }
    }
  }
  return undefined;
}

function checkBookHelpAltruismHistory(hist?: Step[]): FreezeState | undefined {
  if (hist) {
    const qIndex = hist.findIndex((step) => step.id === 'ALT1' || step.id === 'ALT2');

    if (qIndex !== -1) {
      const answerQ1 = hist[qIndex + 1] as AnswerStep | undefined;
      if (answerQ1) {
        const value: QuestionButton = (answerQ1.data.value as QuestionButton[])[0];

        const DURATION_B1 = isDebug ? 0.5 : 3;
        const DURATION_B2 = isDebug ? 0.5 : 15;

        if (value.id === 'b1') {
          return {
            isOpened: checkIsFreezing(DURATION_B1, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B1,
            text: 'Монеты рассыпались по рюкзаку и поиск всех занимает у вас 3 минуты.',
            title: 'Иннополис',
          };
        }
        if (value.id === 'b2') {
          return {
            isOpened: checkIsFreezing(DURATION_B2, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B2,
            text: 'Вы достаете из рюкзака книгу Стивена Хокинга с автографом. Взбудораженный Петров горячо вас благодарит. Это занимает 15 минут.',
            title: 'Иннополис',
          };
        }
      }
    }
  }
  return undefined;
}

function checkHelpGrandsonOfGrandmotherAltruismHistory(hist?: Step[]): FreezeState | undefined {
  if (hist) {
    const qIndex = hist.findIndex((step) => step.id === 'ALT2');

    if (qIndex !== -1) {
      const answerQ1 = hist[qIndex + 1] as AnswerStep | undefined;
      if (answerQ1) {
        const value: QuestionButton = (answerQ1.data.value as QuestionButton[])[0];

        const DURATION_B2 = isDebug ? 0.5 : 5;
        const DURATION_B3 = isDebug ? 0.5 : 30;

        if (value.id === 'b2') {
          return {
            isOpened: checkIsFreezing(DURATION_B2, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B2,
            text: 'Вы берете телефон Бабули, поиск Ютуб-канала занимает 5 минут.',
            title: 'Поезд в «Артек»',
          };
        }
        if (value.id === 'b3') {
          return {
            isOpened: checkIsFreezing(DURATION_B3, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B3,
            text: 'Вы созваниваетесь с внуком Бабули и начинаете объяснять непонятные ему темы. Это занимает 30 минут.',
            title: 'Поезд в «Артек»',
          };
        }
      }
    }
  }
  return undefined;
}

function checkHelpInnopolisTerminalAltruismHistory(hist?: Step[]): FreezeState | undefined {
  if (hist) {
    const qIndex = hist.findIndex((step) => step.id === 'ALT2');

    if (qIndex !== -1) {
      const answerQ1 = hist[qIndex + 1] as AnswerStep | undefined;
      if (answerQ1) {
        const value: QuestionButton = (answerQ1.data.value as QuestionButton[])[0];

        const DURATION_B1 = isDebug ? 0.5 : 15;
        const DURATION_B2 = isDebug ? 0.5 : 7;

        if (value.id === 'b1') {
          return {
            isOpened: checkIsFreezing(DURATION_B1, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B1,
            text: 'Вы отходите от турникета и идете с Евдокией к терминалу, чтобы оформить пропуск. Это занимает у вас 15 минут.',
            title: 'Иннополис',
          };
        }
        if (value.id === 'b2') {
          return {
            isOpened: checkIsFreezing(DURATION_B2, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B2,
            text: 'Вы звоните администратору и объясняете в чем дело. Это занимает у вас 7 минут.',
            title: 'Иннополис',
          };
        }
      }
    }
  }
  return undefined;
}
function checkHelpTeacherToSearch3DPenAltruismHistory(hist?: Step[]): FreezeState | undefined {
  if (hist) {
    const qIndex = hist.findIndex((step) => step?.id === 'ALT_PETROV2');

    if (qIndex !== -1) {
      const answerQ1 = hist[qIndex + 1] as AnswerStep | undefined;
      if (answerQ1) {
        const value: QuestionButton = (answerQ1.data.value as QuestionButton[])[0];

        const DURATION_B1 = isDebug ? 0.5 : 10;
        const DURATION_B2 = isDebug ? 0.5 : 5;

        if (value.id === 'b1') {
          return {
            isOpened: checkIsFreezing(DURATION_B1, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B1,
            text: 'Вы ждете Светлану Васильевну, которая поспешила из МГУ обратно в Иннополис. Дорога занимает у нее 10 минут.',
            title: 'Иннополис',
          };
        }
        if (value.id === 'b2') {
          return {
            isOpened: checkIsFreezing(DURATION_B2, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B2,
            text: 'Вы звоните Сан Санычу и договариваетесь, чтобы он послал помощника с 3D ручкой в Иннополис. Разговор занимаем у вас 5 минут.',
            title: 'Иннополис',
          };
        }
      }
    }
  }
  return undefined;
}

function checkDogWalkingAltruismHistory(hist?: Step[]): FreezeState | undefined {
  if (hist) {
    const qIndex = hist.findIndex((step) => step.id === 'ALT2');

    if (qIndex !== -1) {
      const answerQ1 = hist[qIndex + 1] as AnswerStep | undefined;
      if (answerQ1) {
        const value: QuestionButton = (answerQ1.data.value as QuestionButton[])[0];

        const DURATION_B2 = isDebug ? 0.5 : 30;
        const DURATION_B3 = isDebug ? 0.5 : 180;

        if (value.id === 'b2') {
          return {
            isOpened: checkIsFreezing(DURATION_B2, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B2,
            text: 'Вы начинаете искать того, кто бы согласился погулять со щенками, и на это уходит 30 минут...',
            title: 'Иннополис',
          };
        }
        if (value.id === 'b3') {
          return {
            isOpened: checkIsFreezing(DURATION_B3, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B3,
            text: 'Щенки оказались очень активными: они много бегали за мячиком, приносили палку и весело гоняли голубей. В итоге выгул занял у вас целых 3 часа.',
            title: 'Иннополис',
          };
        }
      }
    }
  }
  return undefined;
}

function checkHelpStudentTraineeAltruismHistory(hist?: Step[]): FreezeState | undefined {
  if (hist) {
    const qIndex = hist.findIndex((step) => step.id === 'ALT1');

    if (qIndex !== -1) {
      const answerQ1 = hist[qIndex + 1] as AnswerStep | undefined;
      if (answerQ1) {
        const value: QuestionButton = (answerQ1.data.value as QuestionButton[])[0];

        const DURATION_B2 = isDebug ? 0.5 : 20;
        const DURATION_B3 = isDebug ? 0.5 : 120;

        if (value.id === 'b2') {
          return {
            isOpened: checkIsFreezing(DURATION_B2, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B2,
            text: 'Вы начинаете искать номер телефона репетитора, но контактов в вашем телефоне оказывается слишком много. Поиск затягивается на 20 минут.',
            title: 'Поезд в «Артек»',
          };
        }
        if (value.id === 'b3') {
          return {
            isOpened: checkIsFreezing(DURATION_B3, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B3,
            text: 'Вы созваниваетесь со стажером Петрова и объясняете все непонятные моменты. На это у вас уходит 2 часа.',
            title: 'Поезд в «Артек»',
          };
        }
      }
    }
  }
  return undefined;
}

function checkFeedingPuppyAltruismHistory(hist?: Step[]): FreezeState | undefined {
  if (hist) {
    const qIndex = hist.findIndex((step) => step.id === 'ALT2');

    if (qIndex !== -1) {
      const answerQ1 = hist[qIndex + 1] as AnswerStep | undefined;
      if (answerQ1) {
        const value: QuestionButton = (answerQ1.data.value as QuestionButton[])[0];

        const DURATION_B2 = isDebug ? 0.5 : 7;
        const DURATION_B3 = isDebug ? 0.5 : 30;

        if (value.id === 'b2') {
          return {
            isOpened: checkIsFreezing(DURATION_B2, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B2,
            text: 'Вы берете записанный Петровым номер телефона Вани, спускаетесь вниз и договариваетесь с охранником о звонке. На это у вас уходит 7 минут.',
            title: 'Иннополис',
          };
        }
        if (value.id === 'b3') {
          return {
            isOpened: checkIsFreezing(DURATION_B3, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B3,
            text: 'Вы отправляетесь домой к Петрову, чтобы покормить щенка, и возвращаетесь обратно в Иннополис. Это занимает у вас 30 минут.',
            title: 'Иннополис',
          };
        }
      }
    }
  }
  return undefined;
}

function checkFeedingDucksAltruismHistory(hist?: Step[]): FreezeState | undefined {
  if (hist) {
    const qIndex = hist.findIndex((step) => step.id === 'ALT2');

    if (qIndex !== -1) {
      const answerQ1 = hist[qIndex + 1] as AnswerStep | undefined;
      if (answerQ1) {
        const value: QuestionButton = (answerQ1.data.value as QuestionButton[])[0];

        const DURATION_B2 = isDebug ? 0.5 : 10;
        const DURATION_B3 = isDebug ? 0.5 : 60;

        if (value.id === 'b2') {
          return {
            isOpened: checkIsFreezing(DURATION_B2, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B2,
            text: 'Вы звоните стажеру Петрова и объясняете, что надо делать. Созвон занимает у вас 10 минут.',
            title: 'Парк «Большая перемена»',
          };
        }
        if (value.id === 'b3') {
          return {
            isOpened: checkIsFreezing(DURATION_B3, answerQ1.date),
            start: answerQ1.date,
            duration: DURATION_B3,
            text: 'Вы отправляетесь на поиски утят, чтобы покормить. Это занимает 1 час.',
            title: 'Парк «Большая перемена»',
          };
        }
      }
    }
  }
  return undefined;
}

interface HookValue extends FreezeState {
  resetFreezeState: () => void;
}

export const getFrozenState = (_scenarioState: ScenarioState) => {
  if (!_scenarioState || !_scenarioState.startedScenarios || !_scenarioState.scenarios) {
    return undefined;
  }

  const getAltruism = (sceneId: SceneId) => {
    if (_scenarioState.activeChatScenario?.scenarioId === sceneId) {
      return _scenarioState.activeSceneHistory;
    }

    return _scenarioState.startedScenarios[sceneId];
  };

  /**
   * Размещать в обратном порядке!!!
   */

  // помощь в кормлении уток Саныча
  const feedingDucksAltruismHistory = _scenarioState.startedScenarios[to[ID.entrepreneurDucks]];
  const alt15 = checkFeedingDucksAltruismHistory(feedingDucksAltruismHistory);
  if (alt15) return alt15;

  // помощь в кормлении щенка
  const feedingPuppyAltruismHistory = getAltruism(to[ID.innopoliceStage3Act2Final]);
  const alt14 = checkFeedingPuppyAltruismHistory(feedingPuppyAltruismHistory);
  if (alt14) return alt14;

  // помощь школьнику-стажеру
  const helpStudentTraineeAltruismHistory = getAltruism(to[ID.trainRepairHandle]);
  const alt13 = checkHelpStudentTraineeAltruismHistory(helpStudentTraineeAltruismHistory);
  if (alt13) return alt13;

  // помощь в выгуле собак
  const dogWalkingAltruismHistory = getAltruism(to[ID.innopoliceContinueStory]);
  const alt12 = checkDogWalkingAltruismHistory(dogWalkingAltruismHistory);
  if (alt12) return alt12;

  const helpInnopolisTerminalAnd3DPenTeacherAltruismHistory = getAltruism(
    to[ID.innopolicePickCheck],
  );

  // помощь учителю в поисках 3D ручки
  const alt11 = checkHelpTeacherToSearch3DPenAltruismHistory(
    helpInnopolisTerminalAnd3DPenTeacherAltruismHistory,
  );
  if (alt11) return alt11;

  // помощь Евдокие с терминалом, чтобы оформить пропуск.
  const alt10 = checkHelpInnopolisTerminalAltruismHistory(
    helpInnopolisTerminalAnd3DPenTeacherAltruismHistory,
  );
  if (alt10) return alt10;

  // помощь внуку Бабули
  // const helpGrandsonOfGrandmotherAltruismHistory = getAltruism(to[ID.stage3_hermitage]);
  // const alt9 = checkHelpGrandsonOfGrandmotherAltruismHistory(
  //   helpGrandsonOfGrandmotherAltruismHistory,
  // );
  // if (alt9) return alt9;

  // наполнению библиотеки научного центра Иннополиса редкими книгами
  const bookHelpAltruismHistory = getAltruism(to[ID.innopoliceAct3Finish]);
  const alt8 = checkBookHelpAltruismHistory(bookHelpAltruismHistory);
  if (alt8) return alt8;

  // помощь помощнику Сан Саныча разобраться со съемкой видео
  const cinemaHelpAltruismHistory = getAltruism(to[ID.moscowCityCinemaHelp]);
  const alt7 = checkCinemaHelpAltruismHistory(cinemaHelpAltruismHistory);
  if (alt7) return alt7;

  // помощь Иннокентию подняться
  const helpClimbUpGrandmotherFriendAltruismHistory = getAltruism(to[ID.kurganQuest]);
  const alt6 = checkHelpClimbUpGrandmotherFriendAltruismHistory(
    helpClimbUpGrandmotherFriendAltruismHistory,
  );
  if (alt6) return alt6;

  // Бабуля - теплая одежда
  const grandmotherFriendAltruismHistory = getAltruism(to[ID.grandmotherFriendAltruism]);
  const alt5 = checkGrandmotherFriendAltruism(grandmotherFriendAltruismHistory);
  if (alt5) return alt5;

  // уборка у Саныча
  const moscowCityAlt1History = getAltruism(to[ID.moscowCityTest]);
  const alt4 = checkMoscowCityCleaning(moscowCityAlt1History);
  if (alt4) return alt4;

  // Лифт в Москва Сити
  const alt3 = checkMoscowCityLift(moscowCityAlt1History);
  if (alt3) return alt3;

  // Бабуля на мосту
  const bridgeAlt1History = getAltruism(to[ID.altruism2GrandmotherRoad]);
  const alt2 = checkBridgeAltruism(bridgeAlt1History);
  if (alt2) return alt2;

  // Спасение кошки в Эрмитаже
  // const hermitageAlt1History = getAltruism(to[ID.hermitagePictureSave]);
  // const alt1 = checkHermitageAltruism(hermitageAlt1History);
  // if (alt1) return alt1;
};

export function useFrozenState(withEffects = false): HookValue {
  const dispatch = useDispatch();
  const scenarioState = useSelector(selectScenarios);
  const frozenState = useSelector(selectFrozenState);

  const setFrozenState = useCallback(
    (_scenarioState: ScenarioState) => {
      const result = getFrozenState(_scenarioState);
      if (result) dispatch(setFreezeStateAction(result));
    },
    [dispatch],
  );

  const resetFreezeState = useCallback(() => {
    dispatch(setFreezeStateAction({ ...initialFrozenState }));
  }, [dispatch]);

  // эффект нужен в целом только для первого открытия
  // оставлен в качестве подстраховки от непредвиденных ситуаций
  useEffect(() => {
    if (withEffects) setFrozenState(scenarioState);
  }, [scenarioState, withEffects, setFrozenState]);

  return { ...frozenState, resetFreezeState };
}
