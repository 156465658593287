import React from 'react';

import { PrivateRoute } from './PrivateRoute';
import { Redirect, Route, Switch } from 'react-router-dom';

import LoginPage from '../../../../../pages/LoginPage';
import { ErrorPage } from '../../../../../pages/ErrorPage';
import OnboardingPage from '../../../../..//pages/OnboardingPage';

import { routes } from '../../../../../../common/shared/constants/routes';
import { LayoutAuth } from '../../../../../components/Layout/LayoutAuth';
import { ChatPageContainer as ChatPage } from '../../../../..//pages/ChatPage/ChatPage';
import { MapPage } from '../../../../..//pages/MapPage/MapPage';
import { useSelector } from 'react-redux';
import {
  selectScenarios,
  selectToken,
  selectUserInfo,
  selectUserState,
} from '../../../../..//redux/user/userGetters';
import {
  selectActiveChatScenario,
  selectHasUnsaved,
} from '../../../../..//redux/scenario/scenarioGetters';

import { FreezeModal } from '../../../../..//components/FreezeModal';
import { HasUnsavedPage } from '../../../../..//pages/HasUnsavedPage';
import ProfilePage from '../../../../../pages/ProfilePage';
import DebugPage from '../../../../../pages/DebugPage';
import { isDebug } from '../../../../../../common/shared/constants/constants';
import { GameModal } from '../../../../../components/GameModal';

export const Router = () => {
  const userState = useSelector(selectUserState);
  const token = useSelector(selectToken);
  const userScenarios = useSelector(selectScenarios).scenarios;

  const scene = useSelector(selectActiveChatScenario);
  const hasUnsaved = useSelector(selectHasUnsaved);

  const user = useSelector(selectUserInfo);

  return (
    <>
      <Switch>
        <Route path={routes.login}>{token ? <Redirect to={routes.map} /> : <LoginPage />}</Route>

        <PrivateRoute exact path={routes.profile}>
          {/* {!videoIsAttached ? (
            <Redirect to={routes.map} />
          ) : ( */}
          <LayoutAuth withGrid withMentor>
            <ProfilePage />
          </LayoutAuth>
          {/* )} */}
        </PrivateRoute>

        <Route path={routes.loginDenied}>
          {token ? (
            <ErrorPage
              title='К сожалению, Вы не можете принять участие в игре'
              btnText='Вернуться на главную'
              description=''
              isLoginDeniedPage={true}
              hideButton={false}
            />
          ) : (
            <Redirect to={routes.map} />
          )}
        </Route>

        <Route path={routes.error}>
          {token ? (
            <ErrorPage
              title='500 упс...'
              btnText='Вернуться в профиль'
              description='Упс. Что-то пошло не так, но мы скоро все починим'
            />
          ) : (
            <Redirect to={routes.login} />
          )}
        </Route>

        <PrivateRoute path={routes.onboarding}>
          {userState && userState.isOnboardingCompleted ? (
            <Redirect to={routes.map} />
          ) : (
            <OnboardingPage />
          )}
        </PrivateRoute>

        <PrivateRoute exact path={routes.dashboard}>
          {userState && !userState.isOnboardingCompleted ? (
            <Redirect to={routes.onboarding} />
          ) : (
            <LayoutAuth>
              <MapPage />
            </LayoutAuth>
          )}
        </PrivateRoute>

        <PrivateRoute exact path={routes.map}>
          <LayoutAuth>
            <MapPage />
          </LayoutAuth>
        </PrivateRoute>
        <PrivateRoute exact path={routes.sync}>
          {hasUnsaved ? (
            <LayoutAuth>
              <HasUnsavedPage />
            </LayoutAuth>
          ) : (
            <Redirect to={routes.dashboard} />
          )}
        </PrivateRoute>

        <PrivateRoute exact path={routes.chat}>
          {!scene || Object.keys(userScenarios).length === 0 ? (
            <Redirect to={routes.map} />
          ) : (
            <LayoutAuth withGrid isFixed>
              <ChatPage />
            </LayoutAuth>
          )}
        </PrivateRoute>

        {isDebug && (
          <Route exact path={routes.debug}>
            <DebugPage />
          </Route>
        )}

        <Route>
          <ErrorPage />
        </Route>
      </Switch>
      <FreezeModal />
    </>
  );
};
