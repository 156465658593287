// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

import { MapModalButtonsStyled } from '../../pages/MapPage/MapPageStyled';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo } from '../../redux/user/userGetters';
import { setUserVideoAsync } from '../../redux/user/userActions';
import { checkLinkValid } from '../../redux/scenario/scenarioActions';
import useMobileMenuShow from '../../hooks/useMobileMenuShow';

import { Box } from '../../../common/shared/ui/Box';
import { Button } from '../../../common/shared/ui/Button';
import { Icon } from '../../../common/shared/ui/Icon';
import { VideoType } from '../../../common/shared/types/modalVideoType';

import UploadFiles from '../../components/UploadFiles';
import FilesApi from '../../api/files';

import {
  IntroductionText,
  СonclusionText,
  VideoIsAttached,
  HeaderModalVideoText,
  StyledModalAttachmentVideoStyled,
} from '../../../common/shared/ui/ModalAttachmentVideo';
import { StyledVideoBoxStyled } from '../../../common/shared/ui/ModalAttachmentVideo/ModalAttachmentVideoStyled';

export const ModalAttachmentVideo = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserInfo);

  /**
   * возвращает true, если кнопка продолжить заблокирована,
   * иначе false
   */

  const getDisableStatus = () => {
    // текущее время
    const curDateInMs = Date.now();
    // дата запуска игры
    const startVideoUploadingDateInMs = new Date(2022, 1, 12).getTime(); // the month is 0-indexed

    return startVideoUploadingDateInMs < curDateInMs ? false : true;
  };

  const [mime, setMime] = useState('');
  const [isAttached, setIsAttached] = useState(Boolean(user?.files?.presentation));
  const [link, setLink] = useState(user?.info || '');
  const [isValid, setIsValid] = useState(false);
  const [fileResponse, setFileResponse] = useState<any>(null); // ответ бека с fileId и пр
  const [isDisabled] = useState(getDisableStatus()); // кнопка загрузки видео
  const [videoWasSuccessfullyUploaded, setVideoWasSuccessfullyUploaded] = useState(false); // маркер того - было ли видео успешно загружено

  const { isOpenAttachmentVideoModal, closeAttachmentVideoModal } = useMobileMenuShow();
  const [type] = useState<string>(VideoType.link);

  const onChangeLink = useCallback((value: string) => {
    setLink(value);
    setIsValid(checkLinkValid(value));
  }, []);

  // отрабатывается когда пользователь загружает видео
  const onChangeFile = useCallback(
    (_link: string, _mime: string) => {
      setMime(mime);
      onChangeLink(_link);
    },
    [mime, onChangeLink],
  );

  const setUserVideo = useCallback(async () => {
    if (type === VideoType.file) {
      try {
        await FilesApi.uploadFileToUser(link, mime);
      } catch (e) {
        alert('Ошибка сохранения файла');
        return;
      }
    }
    dispatch(setUserVideoAsync(fileResponse.id, closeAttachmentVideoModal));
  }, [type, dispatch, link, closeAttachmentVideoModal, mime, fileResponse]);

  const onBackendResponse = (file: any): void => {
    setFileResponse(file);
    if (file) {
      setVideoWasSuccessfullyUploaded(true);
    } else {
      setVideoWasSuccessfullyUploaded(false);
    }
  };

  useEffect(() => onChangeLink(''), [onChangeLink, type]);

  useEffect(() => {
    setIsAttached(Boolean(user?.files?.presentation));
  }, [user?.files?.presentation]);

  useEffect(() => {
    if (isOpenAttachmentVideoModal) {
      setLink(user?.info || '');
    }
  }, [isOpenAttachmentVideoModal, user?.info]);

  if (isAttached) {
    return <VideoIsAttached />;
  }

  return (
    <StyledModalAttachmentVideoStyled>
      {/* Текст вступление до визитки */}
      <IntroductionText />

      {/* <StyledVideoBoxStyled>
        {fileResponse && (
          <ReactPlayer url={fileResponse?.url} controls={true} key={fileResponse.id} />
        )}
      </StyledVideoBoxStyled>

      <Box $mB={'32px'} $mT={'32px'}>
        <UploadFiles
          description='Формат: mр4, размер: до 50 Мб, продолжительность: до 2х мин.'
          uploadLink='/files'
          accept='video/mp4,audio/mp4'
          hideSaveButton
          withoutTitle
          $wrapperPadding={'0px'}
          onLoadFile={onChangeFile}
          onBackendResponse={onBackendResponse}
          isDisabled={isDisabled}
          fileType='presentation'
        />
      </Box> */}

      {/* Текст после визитки */}
      {/* <СonclusionText /> */}

      <HeaderModalVideoText $textStyle='p18' $color='red'></HeaderModalVideoText>
      {/* <MapModalButtonsStyled> */}
      {/* <Button
          // вернуть после запуска игры !isValid || !videoWasSuccessfullyUploaded
          disabled={!isValid || !videoWasSuccessfullyUploaded}
          $textStyle='p18'
          $fontWeight='500'
          onClick={setUserVideo}>
          <Box $justify='space-between' $align='center'>
            Отправить
            <Icon $mL='12px' icon='ico_chevron' />
          </Box>
        </Button> */}
      {/* <Button $textStyle='p18' $fontWeight='500' onClick={handleLogout}>
          <Box $justify='space-between' $align='center'>
            Выйти из профиля
            <Icon $mL='12px' icon='ico_chevron' />
          </Box>
        </Button> */}
      {/* </MapModalButtonsStyled> */}
    </StyledModalAttachmentVideoStyled>
  );
};
