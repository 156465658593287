import React, { useEffect, useMemo, useState } from 'react';

import { useMount } from '../../../../common/shared/hooks/useMount';

import Typing from '../../Typing';
import ChatMessageButtons from '../../ChatMessageButtons';
import { IChatMessageContainerProps } from './ChatMessageTypes';
import {
  ChatUserImgStyled,
  ChatMessageStyled,
  ChatMessageTimeStyled,
  ChatMessageNameStyled,
  ChatMessageBubbleStyled,
  ChatMessageHeaderStyled,
  StyledChatMessageImage,
} from './ChatMessageStyled';

import useTimerMessage from '../../../hooks/useTimerMessage';

import { prepareText, TemplatedText } from '../../TemplatedText';
import { CallToAction } from '../CallToAction';

import { interlocutors } from '../../../resources/interlocutors';
import { isDebug } from '../../../../common/shared/constants/constants';
import { Interlocutors, QuestionButton, StepType } from '../../../resources/scenario';

import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../../redux/user/userGetters';

const ChatMessage = ({
  isLast,
  step,
  nextStep,
  lastStep,
  previousStep,
  variables,
  onAnswer,
  onTimeout,
  onMessageSent,
  renameMalevich,
}: IChatMessageContainerProps) => {
  const userInfo = useSelector(selectUserInfo);

  const messageText = useMemo(() => {
    let result = '';

    if (step.image) {
      result = '';
    }

    if (step.type === StepType.answer) {
      if (step.answerType === 'button') {
        result = '';
        const buttonsValue = step.data.value as QuestionButton[];
        buttonsValue.forEach(
          (button) => (result += `${result.length ? '<br/>' : ''}${button.text}`),
        );
      } else {
        result = step.data.value as string;
      }
    }

    if ((step as any).text) result = (step as any).text as string;

    return prepareText(result, variables, userInfo?.profile?.female ? 'female' : 'male');
  }, [step, userInfo?.profile, variables]);

  const delay = useMemo(() => {
    if (isDebug) return 500;
    if (step.delay) return step.delay;

    if (messageText.length > 200) return 3500;
    if (messageText.length > 100) return 2500;
    return 1500;
  }, [step, messageText]);

  const isMe = step.person === Interlocutors.ME;
  const interlocutor = interlocutors[step.person];

  const [isTyping, setTyping] = useState(!isMe && step.id === lastStep.id);
  const [messageTime, setMessageTime] = useState(step.date || new Date().toUTCString());

  useTimerMessage(step, isLast, isTyping, onTimeout);

  useMount(() => {
    // onMessageSent должно вызывать
    // только последнее сообщение!!!!

    if (!isMe && isLast) {
      setTimeout(() => {
        onMessageSent(step.id);

        setTyping(false);
        setMessageTime(new Date().toUTCString());
      }, delay);
    }
  });

  useEffect(() => {
    if (!isLast) {
      setTyping(false);
    }
  }, [isLast]);

  const content = useMemo(() => {
    if (isTyping) return <Typing />;

    const time = new Date(messageTime).toLocaleString(undefined, {
      day: 'numeric',
      hour: 'numeric',
      month: 'numeric',
      minute: 'numeric',
    });
    const name =
      renameMalevich && interlocutor.name === interlocutors[Interlocutors.BLUE_CAT].name
        ? 'Малевич'
        : interlocutor.name;
    return (
      <React.Fragment>
        <ChatMessageHeaderStyled $textStyle='p14'>
          {name && <ChatMessageNameStyled>{name}</ChatMessageNameStyled>}
          <ChatMessageTimeStyled $isMe={isMe}>{time}</ChatMessageTimeStyled>
        </ChatMessageHeaderStyled>
        {step.image && step.image.uri && (
          <StyledChatMessageImage alt={step.image.title} src={step.image.uri} />
        )}
        <TemplatedText $textStyle='p16' isHTML>
          {messageText}
        </TemplatedText>
        <CallToAction step={step} />
        <ChatMessageButtons step={step} nextStep={nextStep} onAnswer={onAnswer} />
      </React.Fragment>
    );
  }, [
    isTyping,
    messageTime,
    renameMalevich,
    interlocutor.name,
    isMe,
    step,
    messageText,
    nextStep,
    onAnswer,
  ]);

  if (
    step.type === StepType.exit ||
    step.type === StepType.to_map_step ||
    step.type === StepType.to_lk_step
  )
    return null;
  if (previousStep && previousStep.type === StepType.question && previousStep.isHorizontal)
    return null;

  if (!messageText && !step.image?.uri) {
    return null;
  }
  return (
    <ChatMessageStyled $isMe={isMe}>
      {interlocutor.img && !isMe && <ChatUserImgStyled src={interlocutor.img} />}
      <ChatMessageBubbleStyled $isMe={isMe}>{content}</ChatMessageBubbleStyled>
    </ChatMessageStyled>
  );
};

export default ChatMessage;
