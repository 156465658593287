import styled from 'styled-components';
import { TextStyled } from '../Text';

import SpriteStar from '../../../../assets/images/onboarding_sprite_star.svg';

import { BREAKPOINT_LAPTOP, BREAKPOINT_PHONE, BREAKPOINT_TABLET } from '../../constants/constants';
import colors from '../../constants/colors';
import { BoxStyled } from '../Box';

export const HeaderModalListStyled = styled.ul`
  display: block;
  margin-top: 20px;
  color: #808191;

  li {
    display: flex;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      display: block;
      position: relative;
      content: '';
      top: 3px;
      width: 30px;
      height: 30px;
      min-width: 30px;
      background: url('${SpriteStar}') no-repeat center;
      background-size: contain;
      margin-right: 20px;
    }
  }

  @media screen and (max-width: ${BREAKPOINT_LAPTOP}) {
    margin-top: 30px;

    li {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 16px;

      &:before {
        width: 24px;
        height: 24px;
      }
    }
  }

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    li {
      font-size: 15px;
      line-height: 1.5;
      margin-bottom: 24px;
      text-align: left;

      &:before {
        top: 0;
      }
    }
  }
`;

export const HeaderModalVideoText = styled(TextStyled)`
  margin-bottom: 20px;
  color: #808191;
`;

export const StyledModalAttachmentVideoStyled = styled.div`
  padding: 20px;
  border-radius: 16px;
  background: ${colors.white};

  & > * + * {
    margin-top: 10px;
  }

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    padding: 20px;

    & > * + * {
      margin-top: 15px;
    }
  }
`;

export const ModalButtonsStyled = styled(BoxStyled)`
  margin-top: 8px;
  margin-left: -12px;

  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-top: 12px;
    margin-left: 12px;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    & > * > div {
      max-width: 240px;
    }
  }

  @media screen and (min-width: ${BREAKPOINT_TABLET}) {
    margin-top: 18px;
  }
`;

export const StyledVideoBoxStyled = styled.div`
  & > div {
    width: 100% !important;
    /* width: fit-content !important; */
  }
`;
