/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../scenario';

export const scenario_3_5: Scenario = {
  scenarioId: SceneId.scenario_3_5,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Новости парка',
    text: 'А чтобы узнать, что же это за синее мохнатое существо, возвращайся в «Большую перемену» 15 мая.',
    buttons: ['Закрыть'],
  },
};
