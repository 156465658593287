import { useCallback, useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { selectUserInfo } from '../redux/user/userGetters';

import { Item } from '../components/ItemsBar/ItemsBarTypes';
import { AppearanceConfig, femaleConfig, maleConfig, getItem } from '../components/Hero/config';

interface HookValue {
  hair: Item;
  face: Item;
  body: Item;
  background: Item;

  config: AppearanceConfig<true>;

  setHair: (item: Item) => void;
  setFace: (item: Item) => void;
  setBody: (item: Item) => void;
  setBackground: (item: Item) => void;

  setMaleConfig: () => void;
  setFemaleConfig: () => void;
}

export const useHeroConfig = (withRefreshEffects?: boolean): HookValue => {
  const userInfo = useSelector(selectUserInfo);

  const [config, setConfig] = useState<AppearanceConfig<true>>(() => {
    const isMale = !userInfo?.profile?.female;
    return isMale ? maleConfig : femaleConfig;
  });

  const [hair, setHair] = useState(getItem(config, 'hair', userInfo?.profile?.hairId));
  const [face, setFace] = useState(getItem(config, 'face', userInfo?.profile?.faceId));
  const [body, setBody] = useState(getItem(config, 'body', userInfo?.profile?.bodyId));
  const [background, setBackground] = useState(
    getItem(config, 'background', userInfo?.profile?.backgroundId),
  );

  useEffect(() => {
    if (withRefreshEffects) {
      const isMale = !userInfo?.profile?.female;
      const newConfig = isMale ? maleConfig : femaleConfig;

      const newHair = getItem(config, 'hair', userInfo?.profile?.hairId);
      const newFace = getItem(config, 'face', userInfo?.profile?.faceId);
      const newBody = getItem(config, 'body', userInfo?.profile?.bodyId);
      const newBackground = getItem(config, 'background', userInfo?.profile?.backgroundId);

      setHair(newHair);
      setFace(newFace);
      setBody(newBody);
      setBackground(newBackground);
      if (newConfig.id !== config.id) setConfig(newConfig);
    }
  }, [withRefreshEffects, config, userInfo?.profile]);

  const setMaleConfig = useCallback(async () => {
    setConfig(maleConfig);
    setHair(maleConfig.hair[0]);
    setFace(maleConfig.face[0]);
    setBody(maleConfig.body[0]);
    setBackground(maleConfig.background[0]);
  }, []);

  const setFemaleConfig = useCallback(async () => {
    setConfig(femaleConfig);
    setHair(femaleConfig.hair[0]);
    setFace(femaleConfig.face[0]);
    setBody(femaleConfig.body[0]);
    setBackground(femaleConfig.background[0]);
  }, []);

  return {
    hair,
    face,
    body,
    config,
    background,

    setHair,
    setFace,
    setBody,
    setBackground,
    setMaleConfig,
    setFemaleConfig,
  };
};

export default useHeroConfig;
