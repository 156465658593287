import { to, ID } from './ID';
import { ScenarioState } from '../../redux/scenario';

export function notStarted(userState?: ScenarioState) {
  return !userState || !userState.scenarios || !userState.scenarios[to[ID.start]];
}

export function notCatWelcome(userState: ScenarioState) {
  return !userState.scenarios[to[ID.catWelcome]];
}

export function notCityPresented(userState: ScenarioState) {
  return !userState.scenarios[to[ID.cityPresentation]];
}

export function notWelcomeTest(userState: ScenarioState) {
  return !userState.scenarios[to[ID.welcomeTest]];
}

export function notFirstActCompleted(userState: ScenarioState) {
  return !userState.scenarios[to[ID.baggage]] || !userState.scenarios[to[ID.welcomeGrandmother]];
}

export function notCatFlaskHelpIntro(userState: ScenarioState) {
  return !userState.scenarios[to[ID.catFlaskHelpIntro]];
}

export function notCatFlaskHelp(userState: ScenarioState) {
  return !userState.scenarios[to[ID.catFlaskHelp]];
}

export function notGrandMomWelcome(userState: ScenarioState) {
  return !userState.scenarios[to[ID.welcomeGrandMom]];
}

export function hersoneBaggageWasCompleted(userState: ScenarioState) {
  return userState.scenarios[to[ID.baggage]];
}

export function notGrandmotherWelcome(userState: ScenarioState) {
  return !userState.scenarios[to[ID.welcomeGrandmother]];
}

export function notSecondActSochiCompleted(userState: ScenarioState) {
  return !userState.scenarios[to[ID.sochiInfo]];
}

export function notSecondActMguCompleted(userState: ScenarioState) {
  return !userState.scenarios[to[ID.mguSecondAct]];
}

export function notFogStarted(userState: ScenarioState) {
  return !userState.scenarios[to[ID.grandMomThirdAct]];
}

export function notFogIntro(userState: ScenarioState) {
  return !userState.scenarios[to[ID.fogIntro]];
}

export function notStartTeacherHelp(userState: ScenarioState) {
  return !userState.scenarios[to[ID.teacherLost]];
}

export function notChosenHelpVariant(userState: ScenarioState) {
  if (userState.scenarios[to[ID.helpTeacher]]) {
    return false;
  }
  const sceneHistory = userState.startedScenarios?.[to[ID.helpTeacher]];
  if (sceneHistory) {
    return sceneHistory.findIndex((i) => i.nextStep === 'COND') === -1;
  }
  return true;
}

export function notGotRequiredRopeStair(userState: ScenarioState) {
  const sceneHistory = userState.startedScenarios?.[to[ID.helpTeacher]];
  if (!sceneHistory) {
    return false;
  }
  return (
    sceneHistory.findIndex((i) => i.nextStep === 'COND') !== -1 &&
    !userState.scenarios[to[ID.getRopeStair]]
  );
}

export function notTeacherSaved(userState: ScenarioState) {
  return !userState.scenarios[to[ID.helpTeacher]];
}

export function notFirstStageCompleted(userState: ScenarioState) {
  const history = userState?.scenarios?.[to[ID.helpTeacher]];

  if (history) {
    if (history.findIndex((i) => i.id.match(/TEST/)) !== -1) {
      return false;
    }

    return !userState.scenarios[to[ID.teacherLastTest]];
  }

  return true;
}

// export function notSecondStageIntro(userState: ScenarioState) {
//   return !userState.scenarios[to[ID.secondStageIntro]];
// }

// export function notSecondStageIntroCompleted(userState: ScenarioState) {
//   return !userState?.scenarios?.[to[ID.catIntroSecondStage]];
// }

// export function notSchemaRequested(userState: ScenarioState) {
//   return !userState.scenarios[to[ID.petrovSchemaRequest]];
// }

// export function notSafeOpened(userState: ScenarioState) {
//   return !userState.scenarios[to[ID.spaceSafeOpening]];
// }

// export function notSchemaGiven(userState: ScenarioState) {
//   return !userState.scenarios[to[ID.giveSchema]];
// }

// export function notHermitagePictureSaved(userState: ScenarioState) {
//   return !userState.scenarios[to[ID.hermitagePictureSave]];
// }

export function notStage2Act1CompleteShown(userState: ScenarioState) {
  return !userState.scenarios[to[ID.stage2_act1Completed]];
}

export function notStage2Act2Announced(userState: ScenarioState) {
  return !userState?.scenarios?.[to[ID.stage2Act2Announce]];
}

export function notStage2Act2InnopoliceVisited(userState: ScenarioState) {
  return !userState.scenarios[to[ID.stage2Act2AnnounceInnopolice]];
}

export function notStage2Act2AnnounceCatToIsland(userState: ScenarioState) {
  return !userState.scenarios[to[ID.stage2Act2AnnounceCatToIsland]];
}

export function notAltruism2CompletedOrSkipped(userState: ScenarioState) {
  return !userState.scenarios[to[ID.altruism2GrandmotherRoad]];
}

export function notBridgeBoxHelp(userState: ScenarioState) {
  return !userState.scenarios[to[ID.bridgeBoxHelp]];
}

export function notMoscowCity(userState: ScenarioState) {
  return !userState.scenarios[to[ID.moscowCityTest]];
}

export function notMoscowCityTestResult(userState: ScenarioState) {
  return !userState.scenarios[to[ID.moscowCityTestResult]];
}

export function notTwoDetailsReceivedShown(userState: ScenarioState) {
  return !userState.scenarios[to[ID.twoDetailsReceived]];
}

export function notTrainEggDetailReceived(userState: ScenarioState) {
  return !userState.scenarios[to[ID.trainEgg]];
}

export function notInnopoliceEggDetailReceived(userState: ScenarioState) {
  return !userState.scenarios[to[ID.innopoliceDetailEgg]];
}

export function notAllDetailsReceivedShown(userState: ScenarioState) {
  return !userState.scenarios[to[ID.allDetailsReceived]];
}

export function notDetailsGiven(userState: ScenarioState) {
  return !userState.scenarios[to[ID.innopoliceGiveDetails]];
}

export function notGrandmotherFriendHelp(userState: ScenarioState) {
  return !userState.scenarios[to[ID.grandmotherFriendAltruism]];
}

export function notRocketStarted(userState: ScenarioState) {
  return !userState.scenarios[to[ID.newRocketStart]];
}

export function notStage2act2CompletedShown(userState: ScenarioState) {
  return !userState.scenarios[to[ID.stage2act2Completed]];
}

export function notStage2act3Started(userState: ScenarioState) {
  return !userState.scenarios[to[ID.stage2act3Announce]];
}

export function notCatParadeInfo(userState: ScenarioState) {
  return !userState.scenarios[to[ID.catParadeInfo]];
}

export function notKurganInfo(userState: ScenarioState) {
  return !userState.scenarios[to[ID.kurganInfo]];
}

export function notKurganQuest(userState: ScenarioState) {
  return !userState.scenarios[to[ID.kurganQuest]];
}

export function notMoscowCityCinemaHelpAnnounce(userState: ScenarioState) {
  return !userState.scenarios[to[ID.moscowCityCinemaHelpAnnounce]];
}

export function notMoscowCityCinemaHelp(userState: ScenarioState) {
  return !userState.scenarios[to[ID.moscowCityCinemaHelp]];
}

export function notHermitageEgg(userState: ScenarioState) {
  return !userState.scenarios[to[ID.hermitageEgg]];
}

export function notOstamnkinoEgg(userState: ScenarioState) {
  return !userState.scenarios[to[ID.ostamnkinoEgg]];
}

export function notOstamnkinoEgg_1(userState: ScenarioState) {
  return !userState.scenarios[to[ID.ostamnkinoEgg_1]];
}

export function notLakeEgg(userState: ScenarioState) {
  return !userState.scenarios[to[ID.lakeEgg]];
}

export function notMguEgg(userState: ScenarioState) {
  return !userState.scenarios[to[ID.mguEgg]];
}

export function notMguEgg_1(userState: ScenarioState) {
  return !userState.scenarios[to[ID.mguEgg_1]];
}

export function notInnopoliceAct3Finish(userState: ScenarioState) {
  return !userState.scenarios[to[ID.innopoliceAct3Finish]];
}

export function notSecondStageCompleted(userState: ScenarioState) {
  return !userState.scenarios[to[ID.fictive_SecondStageCompleted]];
}

export function notThirdStageStarted(userState: ScenarioState) {
  return !userState.scenarios[to[ID.stage3Start]];
}

// export function notStage3InnopoliceFinished(userState: ScenarioState) {
//   return !userState.scenarios[to[ID.stage3StartInnopolice]];
// }

// export function notTrainStage3Finished(userState: ScenarioState) {
//   return !userState.scenarios[to[ID.trainStage3Quest]];
// }

export function notInnopolicePickChecked(userState: ScenarioState) {
  return !userState.scenarios[to[ID.innopolicePickCheck]];
}

export function notBridgeBlueSteps(userState: ScenarioState) {
  return !userState.scenarios[to[ID.bridgeBlueSteps]];
}

export function notHersonesStepsViewed(userState: ScenarioState) {
  return !userState.scenarios[to[ID.hersonesBlueSteps]];
}

export function notHersonesCompleted(userState: ScenarioState) {
  return !userState.scenarios[to[ID.hersonesQuest]];
}

export function notStage3Act1CompleteShown(userState: ScenarioState) {
  return !userState.scenarios[to[ID.stage3Act1Complete]];
}

export function notStage3Act2StartShown(userState: ScenarioState) {
  return !userState.scenarios[to[ID.stage3Act2Start]];
}

export function notHersonesStoryFinished(userState: ScenarioState) {
  return !userState.scenarios[to[ID.hersonesContinue]];
}

export function notMoscowCityCafeFinished(userState: ScenarioState) {
  return !userState.scenarios[to[ID.moscowCityCafe]];
}

export function notInnopoliceStoryFinished(userState: ScenarioState) {
  return !userState.scenarios[to[ID.innopoliceContinueStory]];
}

export function notSpaceStoryFinished(userState: ScenarioState) {
  return !userState.scenarios[to[ID.spaceContinueStory]];
}

export function notTrainStoryFinished(userState: ScenarioState) {
  return !userState.scenarios[to[ID.trainContinueStory]];
}

export function notPatriotStopHandleCompleted(userState: ScenarioState) {
  return !userState.scenarios[to[ID.patriotStopHandle]];
}

export function notTrainHandleRepaired(userState: ScenarioState) {
  return !userState.scenarios[to[ID.trainRepairHandle]];
}

export function notArchCompleted(userState: ScenarioState) {
  return !userState.scenarios[to[ID.archQuest]];
}

export function notInnopoliceStage3Act2FinalCompleted(userState: ScenarioState) {
  return !userState.scenarios[to[ID.innopoliceStage3Act2Final]];
}

export function notStage3Act2FinalShown(userState: ScenarioState) {
  return !userState.scenarios[to[ID.stage3Act2Final]];
}

export function notStartStage3Act3(userState: ScenarioState) {
  return !userState.scenarios[to[ID.startStage3Act3]];
}

export function notWelcomeSofia(userState: ScenarioState) {
  return !userState.scenarios[to[ID.welcomeSofia]];
}

export function notEntrepreneurDucksInfo(userState: ScenarioState) {
  return !userState.scenarios[to[ID.entrepreneurDucksInfo]];
}

export function notEntrepreneurDucks(userState: ScenarioState) {
  return !userState.scenarios[to[ID.entrepreneurDucks]];
}

export function notAboutInfo(userState: ScenarioState) {
  return !userState.scenarios[to[ID.aboutInfo]];
}

export function notWaitShown(userState: ScenarioState) {
  return !userState.scenarios[to[ID.waitTo21may]];
}

export function notChallengesAnnounce(userState: ScenarioState) {
  return !userState.scenarios[to[ID.challengesAnnounce]];
}

export function notAboutChallenges(userState: ScenarioState) {
  return !userState.scenarios[to[ID.aboutChallenges]];
}

export function notFinish(userState: ScenarioState) {
  return !userState.scenarios[to[ID.finish]];
}
