/* eslint-disable max-len */
import React, { ReactNode, useState, useCallback } from 'react';
import { ChallengesProps } from './ChallengesTypes';
import { StyledChallenges, StyledChallengesList } from './ChallengesStyled';
import {
  StyledChallengeTopContainer,
  StyledMore,
  StyledChallengeBottomContainer,
} from '../Challenge/ChallengeStyled';
import config from './config';

import { Text } from '../../../common/shared/ui/Text';
import Challenge from '../Challenge';
import GeneralChallenge from '../Challenge/GeneralChallenge';
import colors from '../../../common/shared/constants/colors';
import IconArrowDown from '../../../assets/icons/IconArrowDown';

import { useSelector } from 'react-redux';
import {
  selectChallengesData,
  selectUserFiles,
  selectUserInfo,
} from '../../redux/user/userGetters';
import { TemplatedText } from '../TemplatedText';
import useViewport from '../../hooks/useViewport';

const Challenges = (props: ChallengesProps) => {
  const [isOpened, setIsOpened] = useState(true);
  const userInfo = useSelector(selectUserInfo);
  const challengesData = useSelector(selectChallengesData);
  const { isMobile } = useViewport();

  const toggleOpened = useCallback(() => {
    setIsOpened((value) => !value);
  }, []);

  const index = userInfo?.challenge;
  let selectedChallenge: any;

  if (index) {
    selectedChallenge = Object.values(config)[index];
  }

  // функция для отоброжения одного челленджа
  const challenge: React.ReactNode = React.useMemo(() => {
    return (
      <Challenge key={selectedChallenge?.title} userData={undefined} data={selectedChallenge} />
    );
  }, [selectedChallenge]);

  // функция для отображение всех челленджов
  const challenges = React.useMemo(() => {
    const result: ReactNode[] = [];

    for (const key in config) {
      if (Object.prototype.hasOwnProperty.call(config, key) && key !== 'general') {
        const data = config[key];
        result.push(<Challenge key={key} userData={challengesData[data.value]} data={data} />);
      }
    }

    return result;
  }, [challengesData]);

  /**
   * новый общий челендж
   */

  const generalChallenge = React.useMemo(() => {
    const result: ReactNode[] = [];

    for (const key in config) {
      if (Object.prototype.hasOwnProperty.call(config, key) && key === 'general') {
        const data = config[key];
        result.push(
          <GeneralChallenge key={key} userData={challengesData[data.value]} data={data} />,
        );
      }
    }

    return result;
  }, [challengesData]);

  if (!userInfo?.files) {
    return null;
  }

  return (
    <StyledChallenges>
      <StyledChallengeTopContainer onClick={toggleOpened}>
        <Text $textStyle='p14' $color={colors.title} $fontWeight='800'>
          Вызовы
        </Text>
        <StyledMore $isOpened={isOpened}>
          {!isMobile && (
            <Text $textStyle='p13' $color={colors.blueButton}>
              {isOpened ? 'Скрыть' : 'Раскрыть'}
            </Text>
          )}
          <div />
          <IconArrowDown />
        </StyledMore>
      </StyledChallengeTopContainer>
      <StyledChallengeBottomContainer $isOpened={isOpened}>
        <>
          <br />
          <Text $textStyle='p14' $color={colors.text50}>
            {userInfo?.files.challenge ? (
              <Text $color='red'>
                Задание выполнено. <br /> Напоминаем, что пройти последнюю часть игры и отправить на
                проверку оценочные тесты ты можешь до 7 июня 16:00 (МСК). Желаем тебе удачи!
              </Text>
            ) : (
              <>
                <Text $color='red'>Дорогой друг! Прием работ по вызову завершен.</Text>
                <br />
                <Text $color='red'>
                  Напоминаем, что пройти последнюю часть игры и отправить на проверку оценочные
                  тесты ты можешь до 7 июня 16:00 (МСК).
                </Text>
                <br />
                <Text $color='red'>Желаем тебе удачи!</Text>
              </>
            )}
          </Text>
          <StyledChallengesList>
            {userInfo?.challenge ? challenge : challenges}
            {generalChallenge}
          </StyledChallengesList>
        </>
      </StyledChallengeBottomContainer>
    </StyledChallenges>
  );
};

export default Challenges;
