/* eslint-disable max-len */
import {
  Action,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  Step,
  StepType,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT_ID';

export const scenario_3_17_0: Scenario = {
  scenarioId: SceneId.scenario_3_17_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Поезд в «Артек»',
    text: 'Вы вновь попадаете в знакомый вагон поезда в «Артек», чтобы починить стоп-кран…',
    buttons: ['Продолжить'],
  },
};

const ALT: Record<string, Step> = {
  ALT1: {
    person: Interlocutors.PETROV,
    id: 'ALT1',
    text: 'Мой школьник-стажер очень инициативный, но, похоже, никак не может осилить некоторые правила русского языка. Вот, например, написание «не» с глаголами 😔',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Я [[видел/видела]] в библиотеке МГУ отличную книгу с правилами русского языка. Думаю, твоему стажеру будет полезно ее прочесть. (Игровое время не будет заморожено) ',
        nextStep: 'ALT3',
      },
      {
        id: 'b2',
        text: 'Я знаю отличного репетитора по русскому языку, давай я поищу его контакты. (Игровое время будет заморожено на 20 минут)',
        nextStep: 'ALT4',
      },
      {
        id: 'b3',
        text: 'Не расстраивайся, Петров, давай я помогу твоему стажеру разобраться в нужных правилах русского языка. (Игровое время будет заморожено на 2 часа)',
        nextStep: 'ALT5',
      },
    ],
  },
  ALT3: {
    person: Interlocutors.PETROV,
    id: 'ALT3',
    text: `Спасибо большое за совет! Обязательно передам своему стажеру.`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Да не за что',
        nextStep: 'Q4/0',
      },
    ],
  },
  ALT4: {
    person: Interlocutors.PETROV,
    id: 'ALT4',
    text: `Спасибо тебе, {{NAME}}! Хорошего репетитора сейчас нелегко найти. Сегодня же с ним свяжусь.`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: '[[Рад/Рада]] помочь',
        nextStep: 'Q4/0',
      },
    ],
  },
  ALT5: {
    person: Interlocutors.PETROV,
    id: 'ALT5',
    text: `Какой ты [[умный/умная]], {{NAME}}! Быстро [[объяснил/объяснила]] стажеру то, в чем он не мог так долго разобраться.`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Это было не так сложно, как казалось твоему стажеру',
        nextStep: 'Q4/0',
      },
    ],
  },
};

const MENTOR: Record<string, Step> = {
  MENTOR1: {
    person: Interlocutors.TEACHER,
    id: 'MENTOR1',
    text: '{{NAME}}, помнишь, мы обсуждали, что ты можешь позвать на помощь реального наставника?',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Да, конечно помню!',
        nextStep: 'MENTOR3/0',
      },
      {
        id: 'b2',
        text: 'Хм… Что-то не помню...',
        nextStep: 'MENTOR2/0',
      },
    ],
  },
  ...splitStep({
    person: Interlocutors.TEACHER,
    id: 'MENTOR2',
    type: StepType.message,
    delay: 5,
    text: `Хорошо, я напомню 🙂

Впереди тебя ждут очень интересные задания по одному из направлений конкурса.

Наступил момент пригласить реального помощника. Им может стать педагог-наставник. Он сможет, например, подсказать, где искать нужную для выполнения заданий информацию.

В педагоги-наставники подойдет учитель, тренер, инструктор или другой взрослый, обучающий детей.

Он должен работать в образовательной организации Российской Федерации в 2020/2021 учебном году.

И должен быть зарегистрирован на сайте <a target="_blank" href="//bolshayaperemena.online">bolshayaperemena.online</a>
Подробнее, кто может быть наставником, почитай, пожалуйста, в <a target="_blank" href="//bolshayaperemena.online/polojenie_o_konkurse.pdf">Положении о конкурсе</a>.
Выбирай в наставники человека, которого ты хорошо знаешь, и который сможет поддержать тебя в конкурсе. Скорее всего, на эту роль подойдет твой любимый учитель, спортивный тренер или руководитель кружка, в котором ты занимаешься.

Хорошо подумай, кто это может быть. Ведь после выбора наставника, ты не сможешь его поменять. У тебя есть время до 29 мая.

Если ты точно знаешь, кого хочешь видеть своим наставником, предложи ему это в реальности. Если он согласен, то попроси его зарегистрироваться на сайте.`,
    nextStep: 'MENTOR3/0',
  }),
  ...splitStep({
    person: Interlocutors.TEACHER,
    id: 'MENTOR3',
    text: `Ну вот и пришло время добавить наставника 😊

Зайди в свой профиль, введи ФИО, email регистрации и ID своего наставника на сайте <a target="_blank" href="//bolshayaperemena.online">bolshayaperemena.online</a>`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'А где найти ID?',
        nextStep: 'MENTOR4',
      },
      {
        id: 'b2',
        text: 'Хорошо',
        nextStep: 'MENTOR5',
      },
    ],
  }),
  MENTOR4: {
    person: Interlocutors.TEACHER,
    id: 'MENTOR4',
    type: StepType.message,
    text: `ID твой наставник должен посмотреть в своем личном кабинете на bolshayaperemena.online`,
    nextStep: 'MENTOR5',
  },
  MENTOR5: {
    person: Interlocutors.TEACHER,
    id: 'MENTOR5',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Хорошо',
        nextStep: 'Q0',
      },
    ],
    text: `На добавление наставника у тебя есть еще время до 29 мая. Когда будешь [[готов/готова]], внеси данные в своем профиле.`,
  },
};

export const scenario_3_17: Scenario = {
  scenarioId: SceneId.scenario_3_17,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Поезд в «Артек»',
    scene_id: 'scenario_3_17',
    startId: 'MENTOR1',
    steps: {
      ...MENTOR,
      Q0: {
        person: Interlocutors.SYSTEM,
        id: 'Q0',
        type: StepType.message,
        text: `Вы стоите в знакомом вагоне поезда в «Артек», чтобы починить стоп-кран…`,
        nextStep: 'Q1',
      },
      Q1: {
        person: Interlocutors.PETROV,
        id: 'Q1',
        text: `{{NAME}}, давай сюда скорее военный стоп-кран, я заменю старый сломанный на новый..`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отдать стоп-кран Петрову',
            action: Action.LostStop,
            nextStep: 'Q2/0',
          },
        ],
      },
      ...splitStep({
        person: Interlocutors.PETROV,
        id: 'Q2',
        text: `ГОТОВО! Идеально подошел! Теперь ребята смогут отправиться в «Артек»! 😍

Но что это? Почему в инструкции к стоп-крану столько ошибок на правило написания «не» с глаголами? Что это за безобразие такое! После потепления по просьбе начальника депо я специально послал сюда школьника-стажера, чтобы тот освежил все инструкции к новому летнему сезону. Что же это получается? Теперь все инструкции с ошибками? 😱`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Жаль, что у него не получилось с первого раза. Может быть ему нужна помощь?',
            nextStep: 'ALT1',
          },
          {
            id: 'b2',
            text: 'Ну что ж. Это неприятно, но бывает. Давайте пойдем дальше.',
            nextStep: 'Q3',
          },
        ],
      }),

      ...ALT,

      Q3: {
        person: Interlocutors.PETROV,
        id: 'Q3',
        type: StepType.message,
        text: `Да, пойдем. А школьнику-стажеру придется все переделать 😑`,
        nextStep: 'Q4/0',
      },

      ...splitStep({
        person: Interlocutors.GRANDMOTHER,
        id: 'Q4',
        type: StepType.message,
        text: `Какое загляденье! Стоп-кран на месте, теперь поезд готов к работе! И совсем скоро ребятишки отправятся на нем в «Артек», а кто-то, как в старые добрые времена, приедет к нам в парк! 😍

Спасибо тебе, {{NAME}}, ты здорово [[потрудился/потрудилась]] для этого!

Но давайте не будем забывать, что у нас есть еще одна важная задача — вернуть Синего домой.`,
        nextStep: 'Q5',
      }),
      Q5: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q5',
        type: StepType.message,
        text: `Спасибо вам, друзья! Я очень надеюсь на вашу помощь.`,
        nextStep: 'Q6',
      },
      Q6: {
        person: Interlocutors.TEACHER,
        id: 'Q6',
        type: StepType.message,
        text: `Синий, ты упоминал, что был на прогулке с хозяйкой, как вдруг испугался искрящейся вспышки, так? Значит, дело было недалеко от 10G вышки Сан Саныча.`,
        nextStep: 'Q7',
      },
      Q7: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q7',
        type: StepType.message,
        text: `Да, Вы совершенно верно запомнили, Светлана Васильевна.`,
        nextStep: 'Q8/0',
      },
      ...splitStep({
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q8',
        type: StepType.message,
        text: `Значит, это произошло за пределами нашего парка. Самая ближайшая точка к вышке — это главный вход в парк «Большая перемена», расположенный недалеко от достопримечательности «Останкинская телебашня». 

Думаю, стоит прогуляться туда, может быть Синему удастся вспомнить оттуда дорогу домой.`,
        nextStep: EXIT_ID,
      }),
      [EXIT_ID]: {
        person: Interlocutors.PETROV,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
