import React, { useEffect, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';

import { BackpackProps } from './BackpackTypes';
import { StyledBackpack } from './BackpackStyled';

import ItemsBar from '../ItemsBar';

import { BACKPACK_CONFIG, Items } from '../../../common/shared/constants/backpackConfig';
import { Action, AnswerStep, QuestionButton, SceneId } from '../../resources/scenario';

import { useSelector } from 'react-redux';
import { selectScenarios } from '../../redux/user/userGetters';
import { ID, to } from '../../hooks/scenarioHelpers/ID';
import { ScenarioState } from '../../redux/scenario';

function checkBackpackItem(
  scenarioState: ScenarioState,
  pushScenarioId: SceneId,
  popScenarioId: SceneId,
  pushAction: Action,
  popAction: Action,
  pushIfCompleted = false,
  popIfCompleted = false,
) {
  if (scenarioState.scenarios) {
    const pushHistory =
      scenarioState.scenarios[pushScenarioId] || scenarioState.startedScenarios?.[pushScenarioId];
    const popHistory =
      scenarioState.scenarios[popScenarioId] || scenarioState.startedScenarios?.[popScenarioId];
    if (
      pushHistory &&
      (pushHistory.findIndex((i) => i.action === pushAction) !== -1 ||
        (scenarioState.scenarios[pushScenarioId] && pushIfCompleted))
    ) {
      return !(
        popHistory &&
        (popHistory.findIndex((i) => i.action === popAction) !== -1 ||
          (scenarioState.scenarios[popScenarioId] && popIfCompleted))
      );
    }
  }
  return false;
}

function useAvailableItems() {
  const userState = useSelector(selectScenarios);

  return useMemo(() => {
    const res: Items[] = [];

    if (userState.scenarios) {
      if (userState.scenarios[to[ID.welcomeTeacher]]) {
        res.push(Items.stairs);
        const sceneHist =
          userState.scenarios[to[ID.hermitagePictureSave]] ||
          userState.startedScenarios?.[to[ID.hermitagePictureSave]];
        if (sceneHist) {
          const altQuestionIndex = sceneHist.findIndex((step) => step.id === 'ALT_Q2');
          if (altQuestionIndex !== -1) {
            const answer = sceneHist[altQuestionIndex + 1] as AnswerStep | undefined;
            if (answer) {
              const button = (answer.data.value as QuestionButton[])[0];
              if (button && button.id === 'b1') {
                res.pop();
              }
            }
          }
        }
      }

      if (userState.scenarios[to[ID.sochiInfo]]) {
        res.push(Items.loupe);
        if (userState.scenarios[to[ID.trainEgg]]) {
          res.pop();
        }
      }

      if (userState.scenarios[to[ID.grandMomThirdAct]]) {
        let pushTwile = true;

        const s25_1 =
          userState.scenarios[to[ID.helpTeacher]] ||
          userState.startedScenarios?.[to[ID.helpTeacher]];

        if (s25_1) {
          const twineStep = s25_1.findIndex((step) => step.id === 'Q3' || step.id === 'Q4');
          if (twineStep !== -1) {
            pushTwile = false;
          }
        }
        const s =
          userState.scenarios[to[ID.moscowCityCafe]] ||
          userState.startedScenarios?.[to[ID.moscowCityCafe]];
        if (s && s.findIndex((i) => i.action === Action.LostRope)) {
          pushTwile = false;
        }
        if (pushTwile) {
          res.push(Items.twine);
        }
      }
      if (userState.scenarios[to[ID.spaceSafeOpening]]) {
        if (!userState.scenarios[to[ID.giveSchema]]) {
          res.push(Items.schema);
        }
      }
      if (
        checkBackpackItem(
          userState,
          to[ID.giveSchema],
          to[ID.innopolicePickCheck],
          Action.Give3dPen,
          Action.Lost3dPen,
          true,
        )
      ) {
        res.push(Items.pen3D);
      }
      // todo: здесь в середине сценария дают болт
      if (userState.scenarios[to[ID.bridgeBoxHelp]]) {
        if (!userState.scenarios[to[ID.innopoliceGiveDetails]]) {
          res.push(Items.bolt);
        }
        res.push(Items.hoody);
        const s = userState.scenarios[to[ID.grandmotherFriendAltruism]];
        if (s) {
          const qIndex = s.findIndex((step) => step.id === 'ALT');
          if (qIndex !== -1) {
            const answer = s[qIndex + 1] as AnswerStep | undefined;
            if (answer && (answer.data.value as QuestionButton[])[0].id === 'b3') {
              res.pop();
            }
          }
        }
      }
      if (userState.scenarios[to[ID.moscowCityTest]]) {
        if (!userState.scenarios[to[ID.innopoliceGiveDetails]]) {
          res.push(Items.mirror);
        }
      }
      if (userState.scenarios[to[ID.trainEgg]]) {
        if (!userState.scenarios[to[ID.innopoliceGiveDetails]]) {
          res.push(Items.radiator);
        }
      }
      if (userState.scenarios[to[ID.innopoliceDetailEgg]]) {
        if (!userState.scenarios[to[ID.innopoliceGiveDetails]]) {
          res.push(Items.blower);
        }
      }

      if (userState.scenarios[to[ID.hermitageEgg]]) {
        res.push(Items.coin);
        const h =
          userState.scenarios[to[ID.innopoliceAct3Finish]] ||
          userState.startedScenarios?.[to[ID.innopoliceAct3Finish]];
        if (h) {
          const alt = h.findIndex((item) => item.id === 'ALT3');
          if (alt !== -1) {
            res.pop();
          }
        }
      }
      if (
        userState.scenarios[to[ID.moscowCityCinemaHelp]] ||
        (userState.startedScenarios?.[to[ID.moscowCityCinemaHelp]] &&
          userState.startedScenarios?.[to[ID.moscowCityCinemaHelp]]?.findIndex(
            (step) => step.action === Action.GiveBook,
          ))
      ) {
        res.push(Items.book);
        const h =
          userState.scenarios[to[ID.innopoliceAct3Finish]] ||
          userState.startedScenarios?.[to[ID.innopoliceAct3Finish]];
        if (h) {
          const alt = h.findIndex((item) => item.id === 'ALT4');
          if (alt !== -1) {
            res.pop();
          }
        }
      }
      if (
        userState.scenarios[to[ID.kurganQuest]] ||
        (userState.startedScenarios?.[to[ID.kurganQuest]] &&
          userState.startedScenarios?.[to[ID.kurganQuest]]?.findIndex(
            (step) => step.action === Action.GiveBand,
          ))
      ) {
        res.push(Items.band);
      }
      if (
        checkBackpackItem(
          userState,
          to[ID.trainStage3Quest],
          to[ID.innopolicePickCheck],
          Action.GivePick,
          Action.LostPick,
          true,
          true,
        )
      ) {
        res.push(Items.pick);
      }
      if (
        checkBackpackItem(
          userState,
          to[ID.patriotStopHandle],
          to[ID.trainRepairHandle],
          Action.GiveStop,
          Action.LostStop,
          true,
          true,
        )
      ) {
        res.push(Items.stopHandle);
      }
      if (userState.scenarios[to[ID.finish]]) {
        res.push(Items.medal);
      }
    }

    // Тестовый блок для отображения элементов в рюкзаке
    // if (process.env.NODE_ENV === 'development') {
    //   // @ts-ignore
    //   return Object.keys(BACKPACK_CONFIG).map((id) => BACKPACK_CONFIG[id]);
    // }

    return res.map((id) => BACKPACK_CONFIG[id]);
  }, [userState]);
}

const Backpack = (props: BackpackProps) => {
  const handleSelectBackpackItem = React.useCallback(() => {}, []);
  const items = useAvailableItems();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <StyledBackpack>
      <ItemsBar title='Рюкзак' isBlockTitle items={items} onSelect={handleSelectBackpackItem} />
    </StyledBackpack>
  );
};

export default Backpack;
