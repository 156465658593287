/* eslint-disable max-len */
import {
  Conditions,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../../types/scenario';

import { TEST_LOGIC_MAIN_2023 } from '../../tests/test_logic_main_2023';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg2_mgu_1_1: Scenario = {
  scenarioId: SceneId.stg2_mgu_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'МГУ',
    scene_id: 'stg2_mgu_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Нам нужно убедиться, что вы люди рассудительные. Пройдите наш тест. 

        Возможно, вам понадобится ручка и лист бумаги, чтобы что-то вычислять.
        
        Важный момент: пройти его можно только один раз. Повторно к нему вернуться будет нельзя. А это очень важно, понимаете?`,
        id: 'Q1',
        person: Interlocutors.ENTREPRENEUR,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Мы готовы!',
            nextStep: 'Q1_0',
          },
        ],
      },

      Q1_0: {
        text: `Данный тест является тренировочным и не приносит баллов на конкурсе!`,
        id: 'Q1_0',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'TEST_LOGIC_MAIN_1/0',
      },

      ...TEST_LOGIC_MAIN_2023, // КОНТРОЛЬНЫЙ ТЕСТ НА ЛОГИКУ

      Q2: {
        text: `Ну что же, здорово у вас получилось!`,
        id: 'Q2',
        person: Interlocutors.ENTREPRENEUR,
        type: StepType.question,
        nextStep: 'Q3',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Супер!',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        text: `Очень скоро в нашей библиотеке состоится викторина о чудесных достопримечательностях нашего парка.
        
        Вам будет необходимо ответить на вопросы об озере Байкал, Государственном Эрмитаже, Олимпийском Парке, Москва-Сити и, конечно же, МГУ.
        
        Перед тем, как поучаствовать, обязательно повторите свои знания об этих местах. 
        
        Ну что же, удачи вам с подготовкой!`,
        id: 'Q3',
        person: Interlocutors.TEACHER,
        type: StepType.message,
        nextStep: 'Q4',
      },

      Q4: {
        text: `Ученики расходятся. Вы ждёте, пока Светлана Васильевна освободится.`,
        id: 'Q4',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q5',
      },

      Q5: {
        text: `Здравствуй, {{name}}! Ты тоже участвуешь в викторине?`,
        id: 'Q5',
        person: Interlocutors.TEACHER,
        type: StepType.question,
        nextStep: 'Q6',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Здравствуйте, да, нам очень нужно выиграть!',
            nextStep: 'Q6',
          },
        ],
      },

      Q6: {
        text: `Ну что же, это отличное стремление. Буду рада помочь тебе с подготовкой.

        Рассказать тебе ещё раз про МГУ?`,
        id: 'Q6',
        person: Interlocutors.TEACHER,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Расскажите',
            nextStep: 'Q7',
          },
          {
            id: 'b2',
            text: 'Спасибо, но чуть позже. А вы не видели то белое существо? Которое причесывали',
            nextStep: 'Q9',
          },
        ],
      },
      Q7: {
        text: `Мы находимся в копии Московского государственного университета имени М. В. Ломоносова, если коротко: МГУ. Несмотря на то, что это копия, студенты здесь тоже постигают науки. МГУ — один из старейших и крупнейших университетов России и расположен он в столице нашей родины.

        Впервые МГУ открыл двери для студентов в 1755 году, это было 269 лет назад, только подумать, как давно! И представь себе, в МГУ учится больше 40 000 студентов, такой огромный вуз!`,
        id: 'Q7',
        person: Interlocutors.TEACHER,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Очень познавательно, спасибо! А вы не видели то белое существо? Которое причесывали',
            nextStep: 'Q8',
          },
        ],
      },
      Q8: {
        text: `Ой, да, был ваш зверёк здесь недавно. Ребята увидели его у радиоприёмника. Крутил ручки, пытаясь настроить волну. Я ему помогла, настроила на Чайковского — прямое включение из Московской консерватории. Он послушал пару минут и убежал. Куда — даже и не знаю.`,
        id: 'Q8',
        person: Interlocutors.TEACHER,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо вам за помощь! ',
            nextStep: 'Q11',
          },
        ],
      },
      Q9: {
        text: `Ой, да, был ваш зверёк здесь недавно. Ребята увидели его у радиоприёмника. Крутил ручки, пытаясь настроить волну. Я ему помогла, настроила на Чайковского — прямое включение из Московской консерватории. Он послушал пару минут и убежал. Куда — даже и не знаю.`,
        id: 'Q9',
        person: Interlocutors.TEACHER,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо вам за помощь! А то мы уже и не надеялись поймать его след здесь.',
            nextStep: 'Q11',
          },
        ],
      },
      Q11: {
        id: 'Q11',
        person: Interlocutors.TEACHER,
        type: StepType.question,
        text: `Пожалуйста, друзья! 

        Успехов вам в поисках и в подготовке к викторине!`,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'До свидания!',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
