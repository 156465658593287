import React from 'react';

import { StyledModalAttachmentVideoStyled } from '../ModalAttachmentVideoStyled';
import { useHistory } from 'react-router-dom';
import { routes } from '../../../constants/routes';
import { Button } from '../../Button';
import { Box } from '../../Box';
import { Text } from '../../Text';
import { Icon } from '../../Icon';

export const VideoIsAttached = () => {
  const history = useHistory();

  return (
    <>
      <StyledModalAttachmentVideoStyled>
        <div>
          <Text $textStyle='p18' $fontWeight='800'>
            Видеовизитка загружена! 🎉
          </Text>
          <div style={{ paddingTop: '20px' }}>
            <Text $textStyle='p18'>
              Ваша видеовизитка уже улетела на изучение обитателям парка 🎯
            </Text>
          </div>
        </div>
        <Button $textStyle='p18' $fontWeight='500' onClick={() => history?.push(routes.chat)}>
          <Box $justify='space-between' $align='center'>
            Вернуться в игру
            <Icon $mL='32px' icon='ico_chevron' />
          </Box>
        </Button>
      </StyledModalAttachmentVideoStyled>
    </>
  );
};
