// @ts-nocheck
import React, { useCallback, useEffect } from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

import { useSelector } from 'react-redux';
import { selectToken } from '../../../../../redux/user/userGetters';

import { routes } from '../../../../../../common/shared/constants/routes';
import { selectHasUnsaved } from '../../../../../redux/scenario/scenarioGetters';

export const PrivateRoute = ({ children, ...props }: RouteProps) => {
  const token = useSelector(selectToken);
  const hasUnsaved = useSelector(selectHasUnsaved);

  const renderContent = useCallback(
    (child: React.ReactNode) => {
      if (!token) {
        return <Redirect to={routes.login} />;
      }

      if (hasUnsaved && props.path !== routes.chat && props.path !== routes.sync) {
        return <Redirect to={routes.sync} />;
      }
      return child;
    },
    [hasUnsaved, props.path, token],
  );
  return <Route {...props}>{renderContent(children)}</Route>;
};
