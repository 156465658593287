/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId, StepType } from '../../../scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT';

const person = Interlocutors.GRANDMOTHER;

export const scenario_2_14_0: Scenario = {
  scenarioId: SceneId.scenario_2_14_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: 'Новости парка',
    person: Interlocutors.SYSTEM,
    text: `Вы направляетесь с Петровым к Космодрому. По пути встречаете Бабулю с ее знакомым. Вы замечаете, что этот знакомый как будто замерз. Бабуля начинает с вами разговор...`,
    buttons: ['Начать'],
  },
};

export const scenario_2_14: Scenario = {
  scenarioId: SceneId.scenario_2_14,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Космодром',
    scene_id: 'scenario_2_14',
    startId: 'Q0/0',
    steps: {
      ...splitStep({
        id: 'Q0',
        person,
        text: `Ребятки! Здравствуйте! Слухи о том, что ваша чудесная тепловая пушка готова и начала работать уже разнеслись по округе! Спасибо вам, дорогие! Что бы мы без вас делали! 😊

А, кстати, забыла представить, это мой знакомый, Аркадий. Представляете, он приехал в парк, когда еще было тепло, а потом резко похолодало и Аркадий оказался к этому совсем не готов! И вот, ходит, мерзнет. 🙁`,
        nextStep: 'Q1',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Понимаю, это очень неприятно! И что же он собирается делать?',
            nextStep: 'ALT',
          },
          {
            id: 'b2',
            text: 'Да уж, вот это ситуация, конечно... Желаю Вам поскорее справиться с ней. А нам пора, у нас срочное дело на Космодроме!',
            nextStep: EXIT_ID,
          },
        ],
      }),
      ALT: {
        person,
        id: 'ALT',
        text: 'А делать и нечего. У Аркадия здесь никого нет и он не понимает, где же ему найти теплые вещи.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Мне очень жаль, что с Вами такое приключилось! Надеюсь, Вы быстро найдете теплую одежду... А нам надо скорее отправиться на Космодром. (Игровое время не будет заморожено)',
            nextStep: EXIT_ID,
          },
          {
            id: 'b2',
            text: 'Я как раз знаю контакты благотворительной организации, она поможет Аркадию с одеждой! Сейчас я их поищу... (Игровое время будет заморожено на 10 минут)',
            nextStep: 'Q2/0',
          },
          {
            id: 'b3',
            text: 'У меня как раз есть чистая и очень теплая толстовка! Вот, Аркадий, возьмите. Она согреет Вас, к тому же очень стильно выглядит! (Игровое время будет заморожено на 5 минут, вы лишитесь толстовки)',
            nextStep: 'Q2/0',
          },
        ],
      },
      ...splitStep({
        id: 'Q2',
        person,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'До свидания!',
            nextStep: EXIT_ID,
          },
        ],
        text: `Спасибо большое за твою помощь! Как же здорово, что мы вас встретили! 😍

А теперь бегите на Космодром. У вас же там важные дела!`,
      }),
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
