import styled from 'styled-components';

import { TextProps } from './Types';
import { BaseStyled } from '../../styles/styledBase';

export const TextStyled = styled(BaseStyled)<TextProps>`
  display: block;

  strong {
    font-weight: 900;
  }
  b {
    font-weight: bold;
  }
`;

export const TemplatedTextDescribed = styled(BaseStyled)<TextProps>`
  display: block;
  font-style: italic;
  margin-top: 30px;
  font-size: 14px;

  strong {
    font-weight: 600;
  }
  b {
    font-weight: bold;
  }
`;
