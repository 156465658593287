/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId, StepType, TestStep } from '../../scenario';
import { splitStep } from '../../../utils/ScenarioUtils';
import interlocutors from '../../interlocutors';

const EXIT_ID = 'EXIT';
const TO_MAP_STEP = 'TO_MAP_STEP';

const person = Interlocutors.TEACHER;

const TEST_TVOI_VIBOR_4: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'TEST_TVOI_VIBOR_4',
  name: 'Твой выбор 3/5',
  nextStep: 'Q3/0',
  order: [
    {
      description: '',
      subtest: 'T1',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person,
  type: StepType.test,
};

export const scenario_1_19: Scenario = {
  scenarioId: SceneId.scenario_1_19,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'МГУ',
    scene_id: 'scenario_1_19',
    startId: 'Q1/0',
    steps: {
      ...splitStep({
        text: `Привет, {{NAME}}! Ты как раз вовремя. Из-за тумана все книги в библиотеке МГУ отсырели, и теперь ими совершенно невозможно пользоваться! Все буквы поплыли, а страницы размокли! 😱

        Необходимо скорее наполнить библиотеку новыми книгами. Вот только библиотекарь уехал из парка из-за тумана ☹️ Поэтому вся надежда на тебя!`,
        id: 'Q1',
        callToAction: 'Ведь ты же поможешь?',
        type: StepType.question,
        maxAnswers: 1,
        person,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q2',
            text: 'Да, именно поэтому я здесь',
          },
        ],
      }),
      Q2: {
        id: 'Q2',
        type: StepType.question,
        maxAnswers: 1,
        text: 'Нам нужно определиться, с каких книг мы начнем наполнение библиотеки. Выбери предметы, которые тебе нравятся, и учебники  этих тематик станут первыми.',
        person,
        buttons: [
          {
            id: 'b1',
            nextStep: TEST_TVOI_VIBOR_4.id,
            text: 'Да, понятно',
          },
        ],
      },
      TEST_TVOI_VIBOR_4,
      ...splitStep({
        id: 'Q3',
        person,
        type: StepType.message,
        text: `Я не могу высказать всю свою благодарность тебе, {{NAME}}! 😊

Теперь каждый сможет посетить библиотеку и найти что-то себе по вкусу! До свидания!`,
        nextStep: EXIT_ID,
      }),
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_STEP]: {
        person,
        id: TO_MAP_STEP,
        type: StepType.to_map_step,
      },
    },
  },
};

export const scenario_1_19_1: Scenario = {
  scenarioId: SceneId.scenario_1_19_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.TEACHER].name,
    person: Interlocutors.TEACHER,
    text: `{{NAME}}, книги меня совсем увлекли, так соскучилась по библиотеке! Приходи попозже, я скоро закончу.`,
    buttons: ['Закрыть'],
  },
};
