// @ts-nocheck
import React, { FC, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { LinkProps } from './LinkTypes';
import { LinkStyled } from './LinkStyled';
import { preventLeaveTestOnClick } from '../../hooks/scenarioHelpers/utils';

const Link: FC<LinkProps> = (props) => {
  const { displayAs = 'link', target = '_blank', to, children, ...baseStyles } = props;

  const Component = useCallback(() => {
    if (displayAs === 'a') {
      return (
        <a href={to} target={target} rel='noreferrer' onClick={preventLeaveTestOnClick}>
          {children}
        </a>
      );
    } else {
      return (
        <RouterLink to={to} onClick={preventLeaveTestOnClick}>
          {children}
        </RouterLink>
      );
    }
  }, [displayAs, to, target, children]);

  return (
    <LinkStyled as='span' {...baseStyles}>
      <Component />
    </LinkStyled>
  );
};

export default Link;
