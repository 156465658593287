import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

import { QuestionButton } from '../../../resources/scenario';

import { Text } from '../../../../common/shared/ui/Text';
import { ChatRadioStyled } from '../../chat/ChatAnswer/ChatAnswerStyled';

interface Props {
  options: { value: string; text: string }[];
  value: QuestionButton[];
  isHorizontal?: boolean;
  withoutMaxHeight?: boolean;
  onToggle: (id: string) => void;
}

const ChatAnswerRadio = ({ options, value, onToggle, isHorizontal, withoutMaxHeight }: Props) => {
  useEffect(() => {
    if (options.length === 1 && !value.length) {
      onToggle(options[0].value);
    }
  }, [onToggle, options, value]);

  return (
    <ChatAnswerRadioStyled $isHorizontal={isHorizontal} $withoutMaxHeight={withoutMaxHeight}>
      {options.map(({ value: v, text }) => (
        <ChatRadioStyled
          key={value + text}
          $current={value.findIndex((item) => item.id === v) !== -1}
          onClick={() => onToggle(v)}>
          <Text $textStyle='p16'>{text}</Text>
        </ChatRadioStyled>
      ))}
    </ChatAnswerRadioStyled>
  );
};

export const ChatAnswerRadioStyled = styled.div<{
  $isHorizontal?: boolean;
  $withoutMaxHeight?: boolean;
}>`
  display: flex;
  overflow-y: scroll;
  overflow-x: hidden;

  & > * {
    background: ${(props) => (!props.$withoutMaxHeight ? '#ffffff' : '#f9f9f9')};
  }

  max-height: ${(props) => (!props.$withoutMaxHeight ? '192px' : 'none')};
  flex-direction: ${(props) => (props.$isHorizontal ? 'row' : 'column')};
  flex-wrap: ${(props) => (props.$isHorizontal ? 'wrap' : 'nowrap')};

  margin-left: -10px;
  margin-top: -10px;

  ${(props) =>
    props.$isHorizontal
      ? css`
          > div {
            margin-left: 10px;
            margin-top: 10px;
            flex: 1;
            white-space: nowrap;
          }
        `
      : ''}
`;

export default ChatAnswerRadio;
