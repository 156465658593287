/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

export const scenario_3_14_0: Scenario = {
  scenarioId: SceneId.scenario_3_14_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Космодром',
    text: 'Вы стоите в рубке управления Космодрома. Синий продолжает свой рассказ...',
    buttons: ['Продолжить'],
  },
};

const EXIT_ID = 'EXIT_ID';

export const scenario_3_14: Scenario = {
  scenarioId: SceneId.scenario_3_14,
  type: ScenarioType.chat,
  data: {
    title: 'Космодром',
    scene_id: 'scenario_3_14',
    startId: 'Q1/0',
    steps: {
      ...splitStep({
        person: Interlocutors.BLUE_CAT,
        id: 'Q1',
        type: StepType.message,
        text: `Я подумал, что здесь наверняка есть много разных переключателей и подходящий для машины точно найдется.

Я залез в какую-то просторную комнату, увидел красивый красный выключатель. Решил, что он подойдет! Дотронулся до выключателя, и вдруг раздался громкий голос: «Десять, девять, восемь, семь…»`,
        nextStep: 'Q2',
      }),
      Q2: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q2',
        type: StepType.message,
        text: `А потом сильный грохот, гул и в небо взметнулась ракета?`,
        nextStep: 'Q3',
      },
      Q3: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q3',
        type: StepType.message,
        text: `Да, совершенно верно, а как Вы догадались?`,
        nextStep: 'Q4',
      },
      Q4: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q4',
        type: StepType.message,
        text: `Потому что тот идеальный выключатель на самом деле был кнопкой пуска в рубке управления Космодрома. Именно там я нашел кошачью шерсть и с этого началось мое расследование похищения ракеты...`,
        nextStep: 'Q5',
      },
      Q5: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q5',
        type: StepType.message,
        text: `Сан Саныч, я случайно! Я вовсе не хотел запускать никакие ракеты! Как же мне теперь возместить Вам такой непомерный ущерб? 😿`,
        nextStep: 'Q6',
      },
      Q6: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q6',
        type: StepType.message,
        text: `Какой ущерб, дорогой?! Ракета-то у нас была самовозвращающаяся — новейшая разработка Роскосмоса! Она уже даже вернулась на Космодром 😁`,
        nextStep: 'Q7',
      },
      Q7: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q7',
        text: `Какое облегчение! 😻`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да уж, вот это неожиданные новости! Но здорово, что сейчас все проясняется!',
            nextStep: 'Q8',
          },
        ],
      },
      Q8: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q8',
        type: StepType.message,
        text: `А после Космодрома я вспомнил про поезд и побежал к нему. Пойдемте, я покажу вам как все было. `,
        nextStep: EXIT_ID,
      },

      [EXIT_ID]: {
        person: Interlocutors.TEACHER,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
