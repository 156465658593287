import React from 'react';
import { Provider } from 'react-redux';
import { useDefineGrade } from './common/app/hooks/useDefinedGrade';
import { getCurrentGradeConfig } from './common/shared/utils/getCurrentGradeConfig';
import { GameModal as SeventhGameModal } from './seventhGrade/components/GameModal';
import { ModalProvider } from './common/shared/ui/NewModal';
import { UpdatePlanned } from './common/services/update-planned';
import styled from 'styled-components';

const StyledRoot = styled('div')`
  display: flex;
  max-width: 100vw;
  min-height: 100%;
  height: 100%;
  flex: 1;
`;

const Main = () => {
  const { queryClassesChanged, grade, isLoading } = useDefineGrade();

  if (isLoading) {
    return null;
  }

  const { Viewport, App, Store } = getCurrentGradeConfig(grade as number);

  // при смене класса должна появляться модалка
  const isQueryClassesChanged =
    queryClassesChanged && 'class-changed' in queryClassesChanged ? true : false;

  return (
    <Provider store={Store}>
      <UpdatePlanned />
      <Viewport />
      <StyledRoot id='content'>
        <App isQueryClassesChanged={isQueryClassesChanged} />
        <ModalProvider />
      </StyledRoot>

      <SeventhGameModal />
    </Provider>
  );
};

export default Main;
