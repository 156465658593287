/* eslint-disable max-len */
import {
  Step,
  SceneId,
  StepType,
  Scenario,
  ScenarioType,
  Interlocutors,
} from '../../../../types/scenario';
import { LOGIC_TEST_NEW } from '../../tests/logicTestNew';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const scenario_2_9_i: Scenario = {
  scenarioId: SceneId.scenario_2_9_i,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'остров Русский / Таинственный лес',
    text: `Отшельник высаживает вас в красивой бухте Чернышёва. Темно, лунная дорожка уходит в море. На берегу возвышаются башенки из камней, словно кто-то древний построил их здесь. 

    Нужно найти дорогу вглубь острова.
    `,
    buttons: ['Отправиться к Великокняжеской батарее'],
  },
};

export const scenario_2_9: Scenario = {
  scenarioId: SceneId.scenario_2_9,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    scene_id: 'scenario_2_9',
    startId: 'Q1',
    title: 'остров Русский / Таинственный лес',
    steps: {
      Q1: {
        text: `Вы со Вжухом поднимаетесь к Великокняжеской крепости, здесь расположены орудийные дворики, подбрустверная галерея, казематы и погребки. 

          А что, если легенды острова не врут и где-то здесь спрятано пиратское золото. Поищем?
          `,
        id: 'Q1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Искать пиратское золото',
            nextStep: 'LOGIC_TEST_NEW_1/0',
          },
        ],
      },

      ...LOGIC_TEST_NEW, // ТЕСТ НА ЛОГИКУ (новый)

      Q2: {
        text: `Ура, золотые монеты твои! Время отправляться дальше, в лес.`,
        id: 'Q2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться в глубь леса',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        text: `Вы пробираетесь в чащу: лес становится всё гуще. Деревья шелестят листьями, луна освещает тоненькие тропинки, то тут, то там светятся во тьме мухоморы, слышно уханье совы. 

          «Кто здесь?» — вдруг раздаётся голос. 
          «В заповедный лес нельзя посторонним. Пройди проверку леса, чтобы двигаться дальше, или уходи».
          `,
        id: 'Q3',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Пройти проверку леса',
            nextStep: 'Q4',
          },
        ],
      },
      // ТЕСТ НА ЛОГИКУ 3(ЕДИНИЧНЫЙ ВОПРОС)
      Q4: {
        text: `Лес расступается и ты замираешь: в чаще в небе зависла искрящаяся светом девушка, от неё исходят электрические молнии, над ней парит облако кодов. 
          В лесу то появляются, то пропадают странные предметы: летающие тарелки, необычные животные. 
          Кажется, она создаёт баги! Вот мы и нашли тебя, Интеллект 2.0.
          `,
        id: 'Q4',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Поговорить с Софьей',
            nextStep: 'Q5',
          },
        ],
      },
      Q5: {
        text: `«Теперь я контролирую жизнь парка, вы всё делали неправильно. Не мешайте мне, я усовершенствую ваши технологии и жизнь», — голос не похож на голос Софьи — он похож на голос машины. 

          Вдруг Софья выпускает на вас армию боевых гномов — кажется, это тоже баги. Вжух дергает тебя за рукав.
          `,
        id: 'Q5',
        person: Interlocutors.SOFIA,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ответить Вжуху',
            nextStep: 'Q6',
          },
        ],
      },
      Q6: {
        // ТЕСТ НА АЛЬТРУИЗМ 9
        text: `Слушай, насколько я знаю, гномы любят золото, отдай им монеты из леса!`,
        id: 'Q6',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отдать монеты гномам',
            nextStep: 'Q6_1',
          },
          {
            id: 'b2',
            text: 'Ещё чего, я разберусь с ними, а монеты оставлю себе!',
            nextStep: 'Q7',
          },
        ],
      },
      Q7: {
        text: `Ты не отдаешь монеты гномам и вступаешь в схватку с ними. Видимо, искаженная реальность в таинственном лесу прокачала и твой скин: откуда ни возьмись на тебе появляются латы, ты быстро перемещаешься и уворачиваешься от ударов гномьих молотков. Где-то слышится шипение Вжуха. Кажется, он вцепился в красный колпак одного из гномов.`,
        id: 'Q7',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Продолжить битву',
            nextStep: 'Q8',
          },
        ],
      },
      Q6_1: {
        text: `Ты кидаешь гномам золотые монеты: маленькие существа бросаются их собирать и уже совсем забыли про вас с котом. Золото — известная слабость гномов, оно их полностью дезориентирует. 

          Интеллект 2.0 дезориентирован и подбирает новый код к багу. «Врубай накопитель!» — кричит Вжух.
          `,
        id: 'Q6_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Включить накопитель',
            nextStep: 'Q8',
          },
        ],
      },
      Q8: {
        text: `Ты включаешь накопитель и его луч мгновенно направляется на Софью. Гномы замирают, пейзаж замирает, время останавливается. Все окружающие предметы распадаются на двоичный код: вместо деревьев ты видишь нули и единицы, слышишь страшный гул. 

          «Система будет отключена через 3,2,1 секунду», — слышишь ты. Вспышка, лампочка накопителя с желтой сменяется на зелёную и Софья падает на мягкий мох. Кажется, тебе удалось! Вы загрузили Интеллект на накопитель и вам больше ничего не угрожает.
          `,
        id: 'Q8',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Подбежать к Софье',
            nextStep: 'Q9',
          },
        ],
      },
      Q9: {
        text: `«Кто вы? Где я?».`,
        id: 'Q9',
        person: Interlocutors.SOFIA,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ответить Софье',
            nextStep: 'Q10',
          },
        ],
      },
      Q10: {
        text: `Я Вжух, а это {{NAME}}. Мы пришли сюда спасти вас.
          Сейчас нам нужно вернуться в Иннополис, а потом мы поможем вам всё вспомнить. Сейчас вам лучше отправиться с нами.
          `,
        id: 'Q10',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться в Иннополис',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
