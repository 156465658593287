import LocalStorage from '../../../common/shared/utils/LocalStorage';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RATING_PAGINATION, RATING_LIST } from '../../../common/shared/constants/constants';
import { UserRatingData } from '../../types/api/user';

export interface Pagination {
  page: number;
  limit: number;
  total: number;
}

interface RatingsSliceState {
  isLoading: boolean;
  list: UserRatingData[];
  pagination: Pagination;
}

const initialState: RatingsSliceState = {
  isLoading: false,
  list: LocalStorage.get<UserRatingData[]>(RATING_LIST) || [],
  pagination: LocalStorage.get<Pagination>(RATING_PAGINATION) || {
    page: 1,
    limit: 10,
    total: 70000,
  },
};

const ratingsSlice = createSlice({
  name: 'ratings',
  initialState,
  reducers: {
    setList: (state, action) => {
      if (action.payload && action.payload.length) {
        state.list = action.payload;
        LocalStorage.set(RATING_LIST, action.payload);
      }
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setPagination: (state, action: PayloadAction<Pagination>) => {
      if (
        typeof action.payload.page !== 'undefined' &&
        typeof action.payload.limit !== 'undefined' &&
        typeof action.payload.total !== 'undefined'
      ) {
        state.pagination = action.payload;
        LocalStorage.set(RATING_PAGINATION, action.payload);
      }
    },
  },
});

export default ratingsSlice;
