import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { routes } from '../../common/shared/constants/routes';
import { useIsProfileAvailable } from '../hooks/useIsProfileAvailable';

export function useNavigateToProfile() {
  const isProfileAvailable = useIsProfileAvailable();
  const history = useHistory();

  return useCallback(() => {
    if (isProfileAvailable) {
      history.push(routes.profile);
    }
  }, [history, isProfileAvailable]);
}
