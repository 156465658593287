import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';
import interlocutors from '../../../interlocutors';

export const hermitage_1_1_0: Scenario = {
  scenarioId: SceneId.hermitage_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `Подруга Петрова, экскурсовод Женя, работает в Эрмитаже. Она точно поможет нам его найти.`,
    buttons: ['Начать'],
  },
};

export const hermitage_1_1_1: Scenario = {
  scenarioId: SceneId.hermitage_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Эрмитаж',
    text: `Вы находитесь у точной копии Зимнего Дворца. Его пышная настенная лепнина поражает воображение! У главных ворот сидит большой синий кот.`,
    buttons: ['Начать'],
  },
};
