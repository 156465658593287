/* eslint-disable max-len */
import React from 'react';

export const IconRatings: React.FC = () => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.3 0H2.7C1.20884 0 0 1.20884 0 2.7V15.3C0 16.7912 1.20884 18 2.7 18H15.3C16.7912 18 18 16.7912 18 15.3V2.7C18 1.20884 16.7912 0 15.3 0ZM1.79824 2.69989C1.79824 2.20284 2.20119 1.79989 2.69824 1.79989H15.2982C15.7953 1.79989 16.1982 2.20284 16.1982 2.69989V15.2999C16.1982 15.797 15.7953 16.1999 15.2982 16.1999H2.69824C2.20119 16.1999 1.79824 15.797 1.79824 15.2999V2.69989ZM12.7009 5.17836C12.6034 4.79457 12.2666 4.51956 11.871 4.5009C11.4755 4.48223 11.1142 4.72429 10.981 5.09719L9.13824 10.2569L7.49826 6.81296C7.35426 6.51048 7.05412 6.31304 6.71934 6.30053C6.38455 6.28801 6.07052 6.46249 5.9043 6.75337L4.62056 8.9999H3.6C3.10295 8.9999 2.7 9.40283 2.7 9.8999C2.7 10.397 3.10295 10.7999 3.6 10.7999H5.14286C5.46583 10.7999 5.76404 10.6268 5.92428 10.3464L6.61315 9.14084L8.44461 12.9868C8.60094 13.3152 8.93952 13.5172 9.30276 13.4987C9.666 13.4803 9.98235 13.2451 10.1048 12.9026L11.6831 8.48321L11.9848 9.67148C12.0862 10.0705 12.4454 10.3499 12.8571 10.3499H14.4C14.8971 10.3499 15.3 9.94697 15.3 9.4499C15.3 8.95283 14.8971 8.5499 14.4 8.5499H13.5572L12.7009 5.17836Z'
      fill='#808191'
    />
  </svg>
);
