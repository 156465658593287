/* eslint-disable max-len */

import bolt from '../../../assets/images/backpack/bolt.svg';
import book from '../../../assets/images/backpack/book.svg';
import coin from '../../../assets/images/backpack/coin.svg';
import band from '../../../assets/images/backpack/band.svg';
import pick from '../../../assets/images/backpack/pick.svg';
import twine from '../../../assets/images/backpack/twine.svg';
import loupe from '../../../assets/images/backpack/loupe.svg';
import pen3D from '../../../assets/images/backpack/pen3D.svg';
import hoody from '../../../assets/images/backpack/hoody.svg';
import stairs from '../../../assets/images/backpack/stairs.svg';
import schema from '../../../assets/images/backpack/schema.svg';
import mirror from '../../../assets/images/backpack/mirror.svg';
import blower from '../../../assets/images/backpack/blower.svg';
import radiator from '../../../assets/images/backpack/radiator.svg';
import stopHandle from '../../../assets/images/backpack/stopHandle.svg';
import medal from '../../../assets/images/backpack/medal.svg';
import jar from '../../../assets/images/backpack/jar.svg';
import fish from '../../../assets/images/backpack/fish.png';
import bottle from '../../../assets/images/backpack/bottle.png';
import radio from '../../../assets/images/backpack/radio.png';
import dictaphone from '../../../assets/images/backpack/dictaphone.svg';
import fishInBucket from '../../../assets/images/backpack/fishInBucket.svg';
import fur from '../../../assets/images/backpack/fur.svg';
import crowbar from '../../../assets/images/backpack/crowbar.svg';
import notebook from '../../../assets/images/backpack/notebook.svg';
import pass from '../../../assets/images/backpack/pass.svg';
import carpet from '../../../assets/images/backpack/carpet.svg';
import mushroom from '../../../assets/images/backpack/mushroom.svg';
import key from '../../../assets/images/backpack/key.svg';

export interface Item {
  description?: string;
  template?: string;
  icon: string;
  id: string;
  isHidden?: boolean;
}

export enum Items {
  key = 'key',
  bolt = 'bolt',
  book = 'book',
  coin = 'coin',
  band = 'band',
  pick = 'pick',
  twine = 'twine',
  loupe = 'loupe',
  pen3D = 'pen3D',
  hoody = 'hoody',
  mirror = 'mirror',
  blower = 'blower',
  stairs = 'stairs',
  schema = 'schema',
  radiator = 'radiator',
  stopHandle = 'stopHandle',
  medal = 'medal',
  jar = 'jar',
  bottle = 'bottle',
  radio = 'radio',
  fish = 'fish',
  dictaphone = 'dictaphone',
  fishInBucket = 'fishInBucket',
  fur = 'fur',
  notebook = 'notebook',
  crowbar = 'crowbar',
  pass = 'pass',
  carpet = 'carpet',
  mushroom = 'mushroom',
}

export const BACKPACK_CONFIG: { [key in Items]: Item } = {
  [Items.jar]: {
    icon: jar,
    id: Items.jar,
    description: `Эту банку [[главного/главной]] [[спасителя/спасительницы]] ты [[получил/получила]] для Петрова, чтобы провести анализ воды`,
  },
  [Items.medal]: {
    icon: medal,
    id: Items.medal,
    description: `Эту медаль [[главного/главной]] [[спасителя/спасительницы]] ты [[получил/получила]] за то, что [[помог/помогла]] восстановить «Большую перемену».`,
  },
  [Items.pen3D]: {
    icon: pen3D,
    id: Items.pen3D,
    description: `Это 3D ручка. Ты [[получил/получила]] ее от Петрова за добытую микросхему для тепловой пушки. С ее помощью ты можешь создать любой предмет.`,
  },
  [Items.stopHandle]: {
    icon: stopHandle,
    id: Items.stopHandle,
    description: `Этот стоп-кран ты [[получил/получила]] за разгадывание головоломок начальника парка «Патриот».`,
  },
  [Items.book]: {
    icon: book,
    id: Items.book,
    description: `Это очень редкая книга Стивена Хокинга с его личным автографом. Ее раздобыл Сан Саныч на научной конференции.`,
  },
  [Items.coin]: {
    icon: coin,
    id: Items.coin,
    description: `Эти монеты ты [[нашел/нашла]] под кустом. Наверное, их кто-то обронил.`,
  },
  [Items.band]: {
    icon: band,
    id: Items.band,
    description: `Это Георгиевская лента — символ праздника победы в Великой Отечественной Войне. Ее подарила тебе Светлана Васильевна за помощь в подготовке патриотической викторины.`,
  },
  [Items.twine]: {
    icon: twine,
    id: Items.twine,
    description: `Это веревка — самый загадочный предмет «Большой Перемены», который однажды ночью у себя на кухне нашла Бабуля. С этой веревки начали происходить все странности. Возможно, именно в этой веревке кроется разгадка всех тайн.`,
  },
  [Items.loupe]: {
    icon: loupe,
    id: Items.loupe,
    description: `Это лупа. Сан Саныч подарил ее тебе в благодарность за помощь на стадионе. Она досталась предпринимателю от деда и заряжена на успех в любых начинаниях!`,
  },
  [Items.stairs]: {
    icon: stairs,
    id: Items.stairs,
    description: `Это лестница. Она символизирует путь на вершину знаний, который проходят студенты МГУ. Она досталась тебе от Светланы Васильевны за помощь в восстановлении учебного плана.`,
  },
  [Items.bolt]: {
    icon: bolt,
    id: Items.bolt,
    description: `Это золотой болт для тепловой пушки, которую собирает Петров. Ты [[нашел/нашла]] его в коробках, помогая рабочим на мосту на остров Русский.`,
  },
  [Items.mirror]: {
    icon: mirror,
    id: Items.mirror,
    description: `Это зеркало-отражатель — деталь, необходимая для тепловой пушки. Ты [[нашел/нашла]] ее в офисе у Сан Саныча.`,
  },
  [Items.blower]: {
    icon: blower,
    id: Items.blower,
    description: `Это лопасть вентилятора — деталь для тепловой пушки Петрова. Ты [[нашел/нашла]] ее в кустах рядом с Иннополисом.`,
  },
  [Items.radiator]: {
    icon: radiator,
    id: Items.radiator,
    description: `Это нагревательный элемент. Одна из деталей для пушки Петрова. Ты [[нашел/нашла]] его в одном из вагонов поезда «Артек».`,
  },
  [Items.schema]: {
    icon: schema,
    id: Items.schema,
    description:
      'Это микросхема — это недостающая деталь, необходимая для тепловой пушки. Она хранилась в японском сейфе Сан Саныча, открыть который можно было только после решения головоломки.',
  },
  [Items.hoody]: {
    icon: hoody,
    id: Items.hoody,
    description:
      'Эту толстовку тебе подарила Светлана Васильевна за помощь на мосту. Она очень мягкая, теплая и стильная.',
  },
  [Items.pick]: {
    icon: pick,
    id: Items.pick,
    description:
      'Это кирка. Ты [[нашел/нашла]] ее в поезде, когда [[искал/искала]] улики по делу Вжуха.',
  },
  [Items.bottle]: {
    icon: bottle,
    id: Items.bottle,
    description: 'Эту бутылку с газировкой ты получил от Петрова',
  },
  [Items.radio]: {
    icon: radio,
    id: Items.radio,
    description: 'Этот радиоприёмник тебе подарила Бабуля',
  },
  [Items.fish]: {
    icon: fish,
    id: Items.fish,
    description: 'Эту Рыбу ты поймал на рыбалке',
  },
  [Items.fishInBucket]: {
    icon: fishInBucket,
    id: Items.fishInBucket,
    description: 'Эту рыбу вы поймали с Бабулей на рыбалке.',
  },
  [Items.dictaphone]: {
    icon: dictaphone,
    id: Items.dictaphone,
    description: 'Этот диктофон тебе подарила Светлана Васильевна за прохождение теста.',
  },
  [Items.fur]: {
    icon: fur,
    id: Items.fur,
    description:
      'Этот кусок шерсти инопланетянина остался у тебя, как доказательство контакта с внеземной цивилизацией. Возможно, она обладает особыми свойствами. Вот бы её изучить!',
  },
  [Items.crowbar]: {
    icon: crowbar,
    id: Items.crowbar,
    description: 'Этот предмет был найден тобой внутри старого маяка на берегу моря',
  },
  [Items.notebook]: {
    icon: notebook,
    id: Items.notebook,
    description: 'Этот ноутбук был найден тобой в домике бабули в Херсонесе',
  },
  [Items.pass]: {
    icon: pass,
    id: Items.pass,
    description:
      'Этот пропуск был найден тобой в ботаническом саду МГУ, он принадлежит Ивану Петрову',
  },
  [Items.carpet]: {
    icon: carpet,
    id: Items.carpet,
    description:
      'Этот ковёр тебе подарила бабуля, кажется он не так прост, как может показаться на первый взгляд',
  },
  [Items.mushroom]: {
    icon: mushroom,
    id: Items.mushroom,
    description: 'Этот гриб был найден тобой во время спасения Петрова на дереве',
  },
  [Items.key]: {
    icon: key,
    id: Items.key,
    description: 'Этот ключ остался у тебя после встречи с богиней Сехмет',
  },
};
