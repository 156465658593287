import { TestJSON } from '../../scenario';

import TK from './test-tvoi-kod-junior-210401.json';
import TVOI_VIBOR from './test-tvoi-vybor-junior-210401.json';
import TVOI_VEKTOR from './test-tvoi-vektor-junior-210401.json';
import ERUDITION_HUM from './bp23_-_Твой_кругозор_Junior_(g2)_-_20230530.json';
import ERUDITION_TECH from './bp23_-_Твой_кругозор_Junior_(t2)_-_20230530.json';
import ERUDITION_HUM_ON_CHECK from './Тест_Твой_кругозор_Junior_g2024_2024_05_28.json';
import ERUDITION_TECH_ON_CHECK from './Тест_Твой_кругозор_Junior_t2024_2024_05_28.json';

export const tests: Record<string, TestJSON> = {
  'test-tvoi-kod-junior-210401.json': TK as TestJSON,
  'test-tvoi-vybor-junior-210401.json': TVOI_VIBOR as TestJSON,
  'test-tvoi-vektor-junior-210401.json': TVOI_VEKTOR as TestJSON,
  'bp23_-_Твой_кругозор_Junior_(g2)_-_20230530.json': ERUDITION_HUM as TestJSON,
  'bp23_-_Твой_кругозор_Junior_(t2)_-_20230530.json': ERUDITION_TECH as TestJSON,
  'Тест_Твой_кругозор_Junior_g2024_2024_05_28.json': ERUDITION_HUM_ON_CHECK as TestJSON,
  'Тест_Твой_кругозор_Junior_t2024_2024_05_28.json': ERUDITION_TECH_ON_CHECK as TestJSON,
};
