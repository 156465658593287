/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_kurganAlienFound_1_1_0: Scenario = {
  scenarioId: SceneId.stg2_kurganAlienFound_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.GUIDE,
    title: 'Мамаев Курган',
    text: `Ребята, подождите! 
    
    Фух! Еле… Еле догнала вас! В общем, Петров сказал, что вы ищете то странное существо. Я знаю, где его искать! Ну, уверена, что знаю. 
    
    Идите за мной!`,
    buttons: ['Начать'],
  },
};
