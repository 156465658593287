/* eslint-disable max-len */
import React from 'react';

export const IconEmail: React.FC = () => (
  <svg width='20' height='18' viewBox='0 0 20 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.9178 6.37598L11.2151 9.38684C10.5155 9.94184 9.53122 9.94184 8.83164 9.38684L5.09766 6.37598'
      stroke='#808191'
      strokeOpacity='0.6'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.0901 16.5C16.6245 16.507 18.3327 14.4246 18.3327 11.8653V6.14168C18.3327 3.58235 16.6245 1.5 14.0901 1.5H5.90863C3.37417 1.5 1.66602 3.58235 1.66602 6.14168V11.8653C1.66602 14.4246 3.37417 16.507 5.90863 16.5H14.0901Z'
      stroke='#808191'
      strokeOpacity='0.6'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
