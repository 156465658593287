/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_innopolicePetrov_1_1_1: Scenario = {
  scenarioId: SceneId.stg2_innopolicePetrov_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Иннополис',
    text: `Вы застали Петрова сидящим за грудой неисправных компьютеров. Увидев вас, Петров вскочил с места и приветственно помахал.`,
    buttons: ['Начать'],
  },
};
