import React from 'react';
import colors from '../../../common/shared/constants/colors';

import logout from '../../../assets/images/logout.svg';

import useLogout from '../../hooks/useLogout';
import useViewport from '../../hooks/useViewport';
import useMobileMenuShow from '../../hooks/useMobileMenuShow';

import { Box } from '../../../common/shared/ui/Box';
import { Button } from '../../../common/shared/ui/Button';
import { Divider } from '../../../common/shared/ui/Divider';
import HeaderUser from '../../components/HeaderUser';
import { MobileMenuProps } from './MobileMenuTypes';
import { StyledMobileMenu, StyledSidebar } from './MobileMenuStyled';

import { useLocation } from 'react-router';
import { isDebug } from '../../../common/shared/constants/constants';

import { useDispatch } from 'react-redux';
import { clearProgressAsync } from '../../redux/user/userActions';

const MobileMenu = (props: MobileMenuProps) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { isMobile } = useViewport();
  const { handleLogout } = useLogout();
  const { isOpenMobileMenu, closeMobileMenu } = useMobileMenuShow();

  const onClearProgress = React.useCallback(() => dispatch(clearProgressAsync()), [dispatch]);

  React.useEffect(() => {
    closeMobileMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <StyledMobileMenu $isActive={isOpenMobileMenu && isMobile}>
      <HeaderUser />
      <Box
        $color={colors.red}
        $direction='row'
        $isWrap={false}
        $justify='space-between'
        $align='center'>
        <Button $size='s' $mL='10px' $style='grey' onClick={handleLogout}>
          <Box $color={colors.red} $isWrap={false} $justify='space-between' $align='center'>
            Выйти
            <img style={{ marginLeft: 12 }} width={16} src={logout} alt='icon' />
          </Box>
        </Button>
      </Box>
      {isDebug && (
        <Button $size='s' $style='grey' onClick={onClearProgress} $mT='16px'>
          <Box $color={colors.red} $justify='space-between' $align='center'>
            Сбросить прогресс
          </Box>
        </Button>
      )}
      <Divider isMobileMenuDivider />
      <StyledSidebar isMobileMenuSidebar />
    </StyledMobileMenu>
  );
};

export default MobileMenu;
