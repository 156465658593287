import { SceneId, Scenario } from '../types/scenario';
import { scenario_0 } from './scenarios/scenario_0';
import { scenario_1_1 } from './scenarios/stage1/scenario_1_1';
import { scenario_1_2 } from './scenarios/stage1/scenario_1_2';
import { scenario_1_3, scenario_1_3_1 } from './scenarios/stage1/scenario_1_3';
import { mgu, ostankino, space } from './scenarios/stage1/scenario_1_5';
import { scenario_unsaved } from './scenarios/scenario_unsaved';
import { scenario_1_7, scenario_1_7_0, scenario_1_7_1 } from './scenarios/stage1/scenario_1_7';
import {
  scenario_1_8,
  scenario_1_8_0,
  scenario_1_8_1,
  scenario_1_8_4,
  scenario_1_8_5,
  scenario_1_8_6,
} from './scenarios/stage1/scenario_1_8';
import {
  scenario_1_9,
  scenario_1_9_0,
  scenario_1_9_1,
  scenario_1_9_2,
} from './scenarios/stage1/scenario_1_9';
import { scenario_1_10 } from './scenarios/stage1/scenario_1_10';
import { scenario_1_4 } from './scenarios/stage1/scenario_1_4';
import { scenario_1_14, scenario_1_14_0, scenario_1_14_1 } from './scenarios/stage1/scenario_1_14';
import {
  scenario_1_17,
  scenario_1_17_0,
  scenario_1_17_1,
  scenario_1_17_2,
  scenario_1_17_3,
} from './scenarios/stage1/scenario_1_17';
import { scenario_1_18, scenario_1_18_0, scenario_1_18_1 } from './scenarios/stage1/scenario_1_18';
import {
  scenario_1_19,
  scenario_1_19_1,
  scenario_1_19_4,
  scenario_1_19_5,
  scenario_1_19_6,
  scenario_1_19_7,
} from './scenarios/stage1/scenario_1_19';
import { scenario_1_22 } from './scenarios/stage1/scenario_1_22';
import { scenario_1_23, scenario_1_24 } from './scenarios/stage1/scenario_1_23';
import {
  scenario_1_24_3,
  scenario_1_24_4,
  scenario_1_24_5,
  scenario_1_24_6,
} from './scenarios/stage1/scenario_1_23';

import {
  scenario_1_25,
  scenario_1_25_0,
  scenario_1_25_1,
  scenario_1_25_2,
  scenario_1_25_4,
} from './scenarios/stage1/scenario_1_25';
import {
  scenario_1_26_1,
  scenario_1_26_2,
  scenario_1_26_3,
  scenario_1_26_4,
} from './scenarios/stage1/scenario_1_26';

import { scenario_2_1, scenario_2_1_i } from './scenarios/stage2/act1/scenario_2_1';
import { scenario_2_2, scenario_2_2_i } from './scenarios/stage2/act1/scenario_2_2';
import { scenario_2_3, scenario_2_3_i } from './scenarios/stage2/act1/scenario_2_3';

import { scenario_2_4_0, scenario_2_4_i, scenario_2_4 } from './scenarios/stage2/act2/scenario_2_4';

import { scenario_2_5_i, scenario_2_5 } from './scenarios/stage2/act2/scenario_2_5';

import { scenario_2_6 } from './scenarios/stage2/act2/scenario_2_6';

import { scenario_2_7_i, scenario_2_7 } from './scenarios/stage2/act3/scenario_2_7';

import { scenario_2_8_i, scenario_2_8 } from './scenarios/stage2/act3/scenario_2_8';

import { scenario_2_9_i, scenario_2_9 } from './scenarios/stage2/act3/scenario_2_9';

import {
  scenario_2_10,
  scenario_2_10_0,
  scenario_2_10_1,
  scenario_2_10_1_0,
} from './scenarios/stage2/act2/scenario_2_10';
import {
  scenario_2_11,
  scenario_2_11_0,
  scenario_2_11_4,
  scenario_2_11_5,
} from './scenarios/stage2/act2/scenario_2_11';
import { scenario_egg1, scenario_egg1_0 } from './scenarios/stage2/act2/scenario_egg1';
import { scenario_egg2_0 } from './scenarios/stage2/act2/scenario_egg2';
import { scenario_2_12 } from './scenarios/stage2/act2/scenario_2_12';
import { scenario_2_13 } from './scenarios/stage2/act2/scenario_2_13';
import { scenario_2_14, scenario_2_14_0 } from './scenarios/stage2/act2/scenario_2_14';
import { scenario_2_15, scenario_2_15_0 } from './scenarios/stage2/act2/scenario_2_15';
import {
  scenario_2_16,
  scenario_2_16_0_5,
  scenario_2_16_1,
  scenario_2_16_1_3,
  scenario_2_16_1_5,
  scenario_2_16_2,
  scenario_2_16_3,
  scenario_2_16_4,
  scenario_2_16_5,
} from './scenarios/stage2/act2/scenario_2_16';
import { scenario_2_20, scenario_2_20_1 } from './scenarios/stage2/act3/scenario_2_20';
import { scenario_2_21, scenario_2_21_1 } from './scenarios/stage2/act3/scenario_2_21';
import { scenario_2_22, scenario_2_22_1 } from './scenarios/stage2/act3/scenario_2_22';
import { scenario_2_23 } from './scenarios/stage2/act3/scenario_2_23';
import { scenario_egg3_0 } from './scenarios/stage2/act3/scenario_egg3';
import { scenario_egg4_0, scenario_egg4_1 } from './scenarios/stage2/act3/scenario_egg4';
import { scenario_egg5_0 } from './scenarios/stage2/act3/scenario_egg5';
import { scenario_egg6_0, scenario_egg6_1 } from './scenarios/stage2/act3/scenario_egg6';
import {
  scenario_3_0,
  scenario_3_0_1,
  scenario_3_0_10,
  scenario_3_0_11,
  scenario_3_0_12,
  scenario_3_0_13,
  scenario_3_0_14,
  scenario_3_0_2,
  scenario_3_0_3,
  scenario_3_0_4,
  scenario_3_0_5,
  scenario_3_0_6,
  scenario_3_0_7,
  scenario_3_0_8,
  scenario_3_0_9,
} from './scenarios/stage3/act1/scenario_3_0';
import { scenario_3_1_i, scenario_3_1 } from './scenarios/stage3/act1/scenario_3_1';
import { scenario_3_2_i, scenario_3_2 } from './scenarios/stage3/act1/scenario_3_2';
import { scenario_3_3_i, scenario_3_3, scenario_3_3_0 } from './scenarios/stage3/act1/scenario_3_3';
import { scenario_3_4, scenario_3_4_i } from './scenarios/stage3/act2/scenario_3_4';
import { scenario_3_5, scenario_3_5_i } from './scenarios/stage3/act2/scenario_3_5';
import { scenario_3_6, scenario_3_6_i } from './scenarios/stage3/act2/scenario_3_6';
import { scenario_3_7, scenario_3_7_i } from './scenarios/stage3/act3/scenario_3_7';

import {
  scenario_3_19,
  scenario_3_19_0,
  scenario_3_19_1,
} from './scenarios/stage3/act2/scenario_3_19';
import { scenario_3_10 } from './scenarios/stage3/act2/scenario_3_10';
import { scenario_3_11 } from './scenarios/stage3/act2/scenario_3_11';
import { scenario_3_12, scenario_3_12_0 } from './scenarios/stage3/act2/scenario_3_12';
import { scenario_3_13, scenario_3_13_0 } from './scenarios/stage3/act2/scenario_3_13';
import { scenario_3_14, scenario_3_14_0 } from './scenarios/stage3/act2/scenario_3_14';
import { scenario_3_15, scenario_3_15_0 } from './scenarios/stage3/act2/scenario_3_15';
import { scenario_3_16, scenario_3_16_0 } from './scenarios/stage3/act2/scenario_3_16';
import { scenario_3_17, scenario_3_17_0 } from './scenarios/stage3/act2/scenario_3_17';
import { scenario_3_18, scenario_3_18_0 } from './scenarios/stage3/act2/scenario_3_18';
import {
  scenario_3_23,
  scenario_3_23_0,
  scenario_3_23_01,
  scenario_3_23_02,
  scenario_3_23_1,
} from './scenarios/stage3/act3/scenario_3_23';
import { scenario_3_22, scenario_3_22_1 } from './scenarios/stage3/act3/scenario_3_22';
import { scenario_3_21 } from './scenarios/stage3/act3/scenario_3_21';
import { scenario_3_20 } from './scenarios/stage3/act3/scenario_3_20';
import { scenario_3_24 } from './scenarios/stage3/act3/scenario_3_24';
import { scenario_blanks } from './scenarios/scenario_blanks';
import { scenario_stop_1 } from './scenarios/stage1/scenario_stops';

const scenes: Record<any, Scenario> = {
  [SceneId.scenario_stop_1]: scenario_stop_1,
  [SceneId.scenario_blanks]: scenario_blanks,
  [SceneId.scenario_unsaved]: scenario_unsaved,
  [SceneId.scenario_0]: scenario_0,
  [SceneId.scenario_1_1]: scenario_1_1,
  [SceneId.scenario_1_2]: scenario_1_2,
  [SceneId.scenario_1_3]: scenario_1_3,
  [SceneId.scenario_1_4]: scenario_1_4,
  [SceneId.scenario_1_3_1]: scenario_1_3_1,
  [SceneId.scenario_1_5_1]: ostankino,
  [SceneId.scenario_1_5_2]: space,
  [SceneId.scenario_1_5_3]: mgu,
  [SceneId.scenario_1_7_0]: scenario_1_7_0,
  [SceneId.scenario_1_7]: scenario_1_7,
  [SceneId.scenario_1_7_1]: scenario_1_7_1,
  [SceneId.scenario_1_8_0]: scenario_1_8_0,
  [SceneId.scenario_1_8]: scenario_1_8,
  [SceneId.scenario_1_8_1]: scenario_1_8_1,
  [SceneId.scenario_1_8_4]: scenario_1_8_4,
  [SceneId.scenario_1_8_5]: scenario_1_8_5,
  [SceneId.scenario_1_8_6]: scenario_1_8_6,
  [SceneId.scenario_1_9]: scenario_1_9,
  [SceneId.scenario_1_9_0]: scenario_1_9_0,
  [SceneId.scenario_1_9_1]: scenario_1_9_1,
  [SceneId.scenario_1_9_2]: scenario_1_9_2,
  [SceneId.scenario_1_10]: scenario_1_10,
  [SceneId.scenario_1_14_0]: scenario_1_14_0,
  [SceneId.scenario_1_14]: scenario_1_14,
  [SceneId.scenario_1_14_1]: scenario_1_14_1,
  [SceneId.scenario_1_17_0]: scenario_1_17_0,
  [SceneId.scenario_1_17]: scenario_1_17,
  [SceneId.scenario_1_17_1]: scenario_1_17_1,
  [SceneId.scenario_1_17_2]: scenario_1_17_2,
  [SceneId.scenario_1_17_3]: scenario_1_17_3,
  [SceneId.scenario_1_18_0]: scenario_1_18_0,
  [SceneId.scenario_1_18]: scenario_1_18,
  [SceneId.scenario_1_18_1]: scenario_1_18_1,
  [SceneId.scenario_1_19]: scenario_1_19,
  [SceneId.scenario_1_19_1]: scenario_1_19_1,
  [SceneId.scenario_1_19_4]: scenario_1_19_4,
  [SceneId.scenario_1_19_5]: scenario_1_19_5,
  [SceneId.scenario_1_19_6]: scenario_1_19_6,
  [SceneId.scenario_1_19_7]: scenario_1_19_7,
  [SceneId.scenario_1_22]: scenario_1_22,
  [SceneId.scenario_1_23]: scenario_1_23,
  [SceneId.scenario_1_24]: scenario_1_24,
  [SceneId.scenario_1_24_3]: scenario_1_24_3,
  [SceneId.scenario_1_24_4]: scenario_1_24_4,
  [SceneId.scenario_1_24_5]: scenario_1_24_5,
  [SceneId.scenario_1_24_6]: scenario_1_24_6,
  [SceneId.scenario_1_25_0]: scenario_1_25_0,
  [SceneId.scenario_1_25]: scenario_1_25,
  [SceneId.scenario_1_25_1]: scenario_1_25_1,
  [SceneId.scenario_1_25_2]: scenario_1_25_2,
  [SceneId.scenario_1_25_4]: scenario_1_25_4,
  [SceneId.scenario_1_26_1]: scenario_1_26_1,
  [SceneId.scenario_1_26_2]: scenario_1_26_2,
  [SceneId.scenario_1_26_3]: scenario_1_26_3,
  [SceneId.scenario_1_26_4]: scenario_1_26_4,
  // фиктивный, чтобы отметить прохождение этапа
  [SceneId.scenario_1_30]: scenario_1_26_4,

  [SceneId.scenario_2_1_i]: scenario_2_1_i,
  [SceneId.scenario_2_1]: scenario_2_1,

  [SceneId.scenario_2_2_i]: scenario_2_2_i,
  [SceneId.scenario_2_2]: scenario_2_2,

  [SceneId.scenario_2_3_i]: scenario_2_3_i,
  [SceneId.scenario_2_3]: scenario_2_3,

  [SceneId.scenario_2_4_0]: scenario_2_4_0,
  [SceneId.scenario_2_4_i]: scenario_2_4_i,
  [SceneId.scenario_2_4]: scenario_2_4,

  [SceneId.scenario_2_5_i]: scenario_2_5_i,
  [SceneId.scenario_2_5]: scenario_2_5,

  [SceneId.scenario_2_6]: scenario_2_6,

  [SceneId.scenario_2_7_i]: scenario_2_7_i,
  [SceneId.scenario_2_7]: scenario_2_7,

  [SceneId.scenario_2_8_i]: scenario_2_8_i,
  [SceneId.scenario_2_8]: scenario_2_8,

  [SceneId.scenario_2_9_i]: scenario_2_9_i,
  [SceneId.scenario_2_9]: scenario_2_9,

  // [SceneId.scenario_2_0]: scenario_2_0,
  // [SceneId.scenario_2_0_1]: scenario_2_0_1,
  // [SceneId.scenario_2_1]: scenario_2_1,
  // [SceneId.scenario_2_1_1]: scenario_2_1_1,
  // [SceneId.scenario_2_2]: scenario_2_2,
  // [SceneId.scenario_2_2_1]: scenario_2_2_1,
  // [SceneId.scenario_2_2_4]: scenario_2_2_4,
  // [SceneId.scenario_2_4_0]: scenario_2_4_0,
  // [SceneId.scenario_2_4]: scenario_2_4,

  // [SceneId.scenario_2_5_1]: scenario_2_5_1,
  // [SceneId.scenario_2_5_2]: scenario_2_5_2,
  // [SceneId.scenario_2_5_3]: scenario_2_5_3,
  // [SceneId.scenario_2_5_4]: scenario_2_5_4,
  // [SceneId.scenario_2_5_5]: scenario_2_5_5,
  // [SceneId.scenario_2_5_6]: scenario_2_5_6,
  // [SceneId.scenario_2_5_7]: scenario_2_5_7,

  //[SceneId.scenario_2_9]: scenario_2_9,
  //[SceneId.scenario_2_9_1]: scenario_2_9_1,
  //[SceneId.scenario_2_9_2]: scenario_2_9_2,
  [SceneId.scenario_2_10_0]: scenario_2_10_0,
  [SceneId.scenario_2_10]: scenario_2_10,
  [SceneId.scenario_2_10_1]: scenario_2_10_1,
  [SceneId.scenario_2_10_1_0]: scenario_2_10_1_0,

  [SceneId.scenario_2_11]: scenario_2_11,
  [SceneId.scenario_2_11_0]: scenario_2_11_0,
  [SceneId.scenario_2_11_4]: scenario_2_11_4,
  [SceneId.scenario_2_11_5]: scenario_2_11_5,
  [SceneId.scenario_egg1_0]: scenario_egg1_0,
  [SceneId.scenario_egg1]: scenario_egg1,
  [SceneId.scenario_egg2_0]: scenario_egg2_0,

  [SceneId.scenario_2_12]: scenario_2_12,
  [SceneId.scenario_2_13]: scenario_2_13,
  [SceneId.scenario_2_14_0]: scenario_2_14_0,
  [SceneId.scenario_2_14]: scenario_2_14,

  [SceneId.scenario_2_15]: scenario_2_15,
  [SceneId.scenario_2_15_0]: scenario_2_15_0,
  [SceneId.scenario_2_16]: scenario_2_16,
  [SceneId.scenario_2_16_1]: scenario_2_16_1,
  [SceneId.scenario_2_16_2]: scenario_2_16_2,
  [SceneId.scenario_2_16_3]: scenario_2_16_3,
  [SceneId.scenario_2_16_4]: scenario_2_16_4,
  [SceneId.scenario_2_16_5]: scenario_2_16_5,
  [SceneId.scenario_2_16_0_5]: scenario_2_16_0_5,
  [SceneId.scenario_2_16_1_5]: scenario_2_16_1_5,
  [SceneId.scenario_2_16_1_3]: scenario_2_16_1_3,

  [SceneId.scenario_2_20]: scenario_2_20,
  [SceneId.scenario_2_20_1]: scenario_2_20_1,
  [SceneId.scenario_2_21]: scenario_2_21,
  [SceneId.scenario_2_21_1]: scenario_2_21_1,
  [SceneId.scenario_2_22]: scenario_2_22,
  [SceneId.scenario_2_22_1]: scenario_2_22_1,
  [SceneId.scenario_2_23]: scenario_2_23,
  [SceneId.scenario_egg3_0]: scenario_egg3_0,
  [SceneId.scenario_egg4_0]: scenario_egg4_0,
  [SceneId.scenario_egg4_1]: scenario_egg4_1,
  [SceneId.scenario_egg5_0]: scenario_egg5_0,
  [SceneId.scenario_egg6_0]: scenario_egg6_0,
  [SceneId.scenario_egg6_1]: scenario_egg6_1,
  // фиктивный, чтобы отметить прохождение этапа
  [SceneId.scenario_2_30]: scenario_egg3_0,

  [SceneId.scenario_3_0]: scenario_3_0,
  [SceneId.scenario_3_0_1]: scenario_3_0_1,
  [SceneId.scenario_3_0_2]: scenario_3_0_2,
  [SceneId.scenario_3_0_3]: scenario_3_0_3,
  [SceneId.scenario_3_0_4]: scenario_3_0_4,
  [SceneId.scenario_3_0_5]: scenario_3_0_5,
  [SceneId.scenario_3_0_6]: scenario_3_0_6,
  [SceneId.scenario_3_0_7]: scenario_3_0_7,
  [SceneId.scenario_3_0_8]: scenario_3_0_8,
  [SceneId.scenario_3_0_9]: scenario_3_0_9,
  [SceneId.scenario_3_0_10]: scenario_3_0_10,
  [SceneId.scenario_3_0_11]: scenario_3_0_11,
  [SceneId.scenario_3_0_12]: scenario_3_0_12,
  [SceneId.scenario_3_0_13]: scenario_3_0_13,
  [SceneId.scenario_3_0_14]: scenario_3_0_14,

  [SceneId.scenario_3_1_i]: scenario_3_1_i,
  [SceneId.scenario_3_1]: scenario_3_1,
  [SceneId.scenario_3_2_i]: scenario_3_2_i,
  [SceneId.scenario_3_2]: scenario_3_2,
  [SceneId.scenario_3_3_i]: scenario_3_3_i,
  [SceneId.scenario_3_3]: scenario_3_3,

  [SceneId.scenario_3_3_0]: scenario_3_3_0,
  [SceneId.scenario_3_4_i]: scenario_3_4_i,
  [SceneId.scenario_3_4]: scenario_3_4,
  [SceneId.scenario_3_5_i]: scenario_3_5_i,
  [SceneId.scenario_3_5]: scenario_3_5,
  [SceneId.scenario_3_6_i]: scenario_3_6_i,
  [SceneId.scenario_3_6]: scenario_3_6,

  [SceneId.scenario_3_7_i]: scenario_3_7_i,
  [SceneId.scenario_3_7]: scenario_3_7,

  [SceneId.scenario_3_10]: scenario_3_10,
  [SceneId.scenario_3_11]: scenario_3_11,
  [SceneId.scenario_3_12]: scenario_3_12,
  [SceneId.scenario_3_12_0]: scenario_3_12_0,
  [SceneId.scenario_3_13]: scenario_3_13,
  [SceneId.scenario_3_13_0]: scenario_3_13_0,
  [SceneId.scenario_3_14]: scenario_3_14,
  [SceneId.scenario_3_14_0]: scenario_3_14_0,
  [SceneId.scenario_3_15]: scenario_3_15,
  [SceneId.scenario_3_15_0]: scenario_3_15_0,
  [SceneId.scenario_3_16]: scenario_3_16,
  [SceneId.scenario_3_16_0]: scenario_3_16_0,
  [SceneId.scenario_3_17]: scenario_3_17,
  [SceneId.scenario_3_17_0]: scenario_3_17_0,
  [SceneId.scenario_3_18]: scenario_3_18,
  [SceneId.scenario_3_18_0]: scenario_3_18_0,
  [SceneId.scenario_3_19]: scenario_3_19,
  [SceneId.scenario_3_19_0]: scenario_3_19_0,
  [SceneId.scenario_3_19_1]: scenario_3_19_1,

  [SceneId.scenario_3_20]: scenario_3_20,
  [SceneId.scenario_3_21]: scenario_3_21,
  [SceneId.scenario_3_22]: scenario_3_22,
  [SceneId.scenario_3_22_1]: scenario_3_22_1,
  [SceneId.scenario_3_23_0]: scenario_3_23_0,
  [SceneId.scenario_3_23]: scenario_3_23,
  [SceneId.scenario_3_23_01]: scenario_3_23_01,
  [SceneId.scenario_3_23_02]: scenario_3_23_02,
  [SceneId.scenario_3_23_1]: scenario_3_23_1,
  [SceneId.scenario_3_24]: scenario_3_24,
};

export default scenes;
