import { SceneId, Scenario } from '../types/scenario';
import { scenario_0 } from './scenarios/scenario_0';
import { scenario_1_1 } from './scenarios/stage1/mgu/scenario_1_1';
import { scenario_1_2 } from './scenarios/stage1/mgu/scenario_1_2';
import { scenario_1_3, scenario_1_3_1 } from './scenarios/stage1/mgu/scenario_1_3';
import { mgu, ostankino, space } from './scenarios/stage1/mgu/scenario_1_5';
import { scenario_unsaved } from './scenarios/scenario_unsaved';
import { scenario_1_7, scenario_1_7_0, scenario_1_7_1 } from './scenarios/stage1/mgu/scenario_1_7';
import {
  scenario_1_8,
  scenario_1_8_0,
  scenario_1_8_1,
  scenario_1_8_4,
  scenario_1_8_5,
  scenario_1_8_6,
} from './scenarios/stage1/mgu/scenario_1_8';
import {
  scenario_1_9,
  scenario_1_9_0,
  scenario_1_9_1,
  scenario_1_9_2,
} from './scenarios/stage1/mgu/scenario_1_9';
import { scenario_1_10 } from './scenarios/stage1/mgu/scenario_1_10';
import { scenario_1_4 } from './scenarios/stage1/mgu/scenario_1_4';
import {
  scenario_1_14,
  scenario_1_14_0,
  scenario_1_14_1,
} from './scenarios/stage1/mgu/scenario_1_14';
import {
  scenario_1_17,
  scenario_1_17_0,
  scenario_1_17_1,
  scenario_1_17_2,
  scenario_1_17_3,
} from './scenarios/stage1/mgu/scenario_1_17';
import {
  scenario_1_18,
  scenario_1_18_0,
  scenario_1_18_1,
} from './scenarios/stage1/mgu/scenario_1_18';
import {
  scenario_1_19,
  scenario_1_19_1,
  scenario_1_19_4,
  scenario_1_19_5,
  scenario_1_19_6,
  scenario_1_19_7,
} from './scenarios/stage1/mgu/scenario_1_19';
import { scenario_1_22 } from './scenarios/stage1/mgu/scenario_1_22';
import { scenario_1_23, scenario_1_24 } from './scenarios/stage1/mgu/scenario_1_23';
import {
  scenario_1_24_3,
  scenario_1_24_4,
  scenario_1_24_5,
  scenario_1_24_6,
} from './scenarios/stage1/mgu/scenario_1_23';

import {
  scenario_1_25,
  scenario_1_25_0,
  scenario_1_25_1,
  scenario_1_25_2,
  scenario_1_25_4,
} from './scenarios/stage1/mgu/scenario_1_25';
import {
  scenario_1_26_1,
  scenario_1_26_2,
  scenario_1_26_3,
  scenario_1_26_4,
} from './scenarios/stage1/mgu/scenario_1_26';

import {
  scenario_2_1,
  scenario_2_1_Second,
  scenario_2_1_i,
} from './scenarios/stage2/act1/scenario_2_1';
import { scenario_2_2, scenario_2_2_i } from './scenarios/stage2/act1/scenario_2_2';
import { scenario_2_3, scenario_2_3_i } from './scenarios/stage2/act1/scenario_2_3';

import { scenario_2_4_0, scenario_2_4_i, scenario_2_4 } from './scenarios/stage2/act2/scenario_2_4';

import { scenario_2_5_i, scenario_2_5 } from './scenarios/stage2/act2/scenario_2_5';

import { scenario_2_6 } from './scenarios/stage2/act2/scenario_2_6';

import { scenario_2_7_i, scenario_2_7 } from './scenarios/stage2/act3/scenario_2_7';

import { scenario_2_8_i, scenario_2_8 } from './scenarios/stage2/act3/scenario_2_8';

import { scenario_2_9_i, scenario_2_9 } from './scenarios/stage2/act3/scenario_2_9';

import {
  scenario_2_10,
  scenario_2_10_0,
  scenario_2_10_1,
  scenario_2_10_1_0,
} from './scenarios/stage2/act2/scenario_2_10';
import {
  scenario_2_11,
  scenario_2_11_0,
  scenario_2_11_4,
  scenario_2_11_5,
} from './scenarios/stage2/act2/scenario_2_11';
import { scenario_egg1, scenario_egg1_0 } from './scenarios/stage2/act2/scenario_egg1';
import { scenario_egg2_0 } from './scenarios/stage2/act2/scenario_egg2';
import { scenario_2_12 } from './scenarios/stage2/act2/scenario_2_12';
import { scenario_2_13 } from './scenarios/stage2/act2/scenario_2_13';
import { scenario_2_14, scenario_2_14_0 } from './scenarios/stage2/act2/scenario_2_14';
import { scenario_2_15, scenario_2_15_0 } from './scenarios/stage2/act2/scenario_2_15';
import {
  scenario_2_16,
  scenario_2_16_0_5,
  scenario_2_16_1,
  scenario_2_16_1_3,
  scenario_2_16_1_5,
  scenario_2_16_2,
  scenario_2_16_3,
  scenario_2_16_4,
  scenario_2_16_5,
} from './scenarios/stage2/act2/scenario_2_16';
import { scenario_2_20, scenario_2_20_1 } from './scenarios/stage2/act3/scenario_2_20';
import { scenario_2_21, scenario_2_21_1 } from './scenarios/stage2/act3/scenario_2_21';
import { scenario_2_22, scenario_2_22_1 } from './scenarios/stage2/act3/scenario_2_22';
import { scenario_2_23 } from './scenarios/stage2/act3/scenario_2_23';
import { scenario_egg3_0 } from './scenarios/stage2/act3/scenario_egg3';
import { scenario_egg4_0, scenario_egg4_1 } from './scenarios/stage2/act3/scenario_egg4';
import { scenario_egg5_0 } from './scenarios/stage2/act3/scenario_egg5';
import { scenario_egg6_0, scenario_egg6_1 } from './scenarios/stage2/act3/scenario_egg6';
import {
  scenario_3_0,
  scenario_3_0_1,
  scenario_3_0_10,
  scenario_3_0_11,
  scenario_3_0_12,
  scenario_3_0_13,
  scenario_3_0_14,
  scenario_3_0_2,
  scenario_3_0_3,
  scenario_3_0_4,
  scenario_3_0_5,
  scenario_3_0_6,
  scenario_3_0_7,
  scenario_3_0_8,
  scenario_3_0_9,
} from './scenarios/stage3/act1/scenario_3_0';
import { scenario_3_1_i, scenario_3_1 } from './scenarios/stage3/act1/scenario_3_1';
import { scenario_3_2_i, scenario_3_2 } from './scenarios/stage3/act1/scenario_3_2';
import { scenario_3_3_i, scenario_3_3, scenario_3_3_0 } from './scenarios/stage3/act1/scenario_3_3';
import { scenario_3_4, scenario_3_4_i } from './scenarios/stage3/act2/scenario_3_4';
import { scenario_3_5, scenario_3_5_i } from './scenarios/stage3/act2/scenario_3_5';
import { scenario_3_6, scenario_3_6_i } from './scenarios/stage3/act2/scenario_3_6';
import { scenario_3_7, scenario_3_7_i } from './scenarios/stage3/act3/scenario_3_7';

import {
  scenario_3_19,
  scenario_3_19_0,
  scenario_3_19_1,
} from './scenarios/stage3/act2/scenario_3_19';
import { scenario_3_10 } from './scenarios/stage3/act2/scenario_3_10';
import { scenario_3_11 } from './scenarios/stage3/act2/scenario_3_11';
import { scenario_3_12, scenario_3_12_0 } from './scenarios/stage3/act2/scenario_3_12';
import { scenario_3_13, scenario_3_13_0 } from './scenarios/stage3/act2/scenario_3_13';
import { scenario_3_14, scenario_3_14_0 } from './scenarios/stage3/act2/scenario_3_14';
import { scenario_3_15, scenario_3_15_0 } from './scenarios/stage3/act2/scenario_3_15';
import { scenario_3_16, scenario_3_16_0 } from './scenarios/stage3/act2/scenario_3_16';
import { scenario_3_17, scenario_3_17_0 } from './scenarios/stage3/act2/scenario_3_17';
import { scenario_3_18, scenario_3_18_0 } from './scenarios/stage3/act2/scenario_3_18';
import {
  scenario_3_23,
  scenario_3_23_0,
  scenario_3_23_01,
  scenario_3_23_02,
  scenario_3_23_1,
} from './scenarios/stage3/act3/scenario_3_23';
import { scenario_3_22, scenario_3_22_1 } from './scenarios/stage3/act3/scenario_3_22';
import { scenario_3_21 } from './scenarios/stage3/act3/scenario_3_21';
import { scenario_3_20 } from './scenarios/stage3/act3/scenario_3_20';
import { scenario_3_24 } from './scenarios/stage3/act3/scenario_3_24';
import { scenario_blanks } from './scenarios/scenario_blanks';
import { scenario_stop_1 } from './scenarios/stage1/mgu/scenario_stops';
import { lake_1_1_0, lake_1_1_1 } from './scenarios/stage1//lake/lake_1_1_0';
import { lake_1_1 } from './scenarios/stage1/lake/lake_1_1';
import { lake_1_2 } from './scenarios/stage1/lake/lake_1_2';
import { lake_1_3 } from './scenarios/stage1/lake/lake_1_3';
import { lake_1_4 } from './scenarios/stage1/lake/lake_1_4';
import { innopolice_1_1_0, innopolice_1_1_1 } from './scenarios/stage1/innopolice/innopolice_1_1_0';
import { innopolice_1_1 } from './scenarios/stage1/innopolice/innopolice_1_1';
import { hermitage_1_1_0, hermitage_1_1_1 } from './scenarios/stage1/hermitage/hermitage_1_1_0';
import { hermitage_1_1 } from './scenarios/stage1/hermitage/hermitage_1_1';
import {
  lakeComeback_1_1_0,
  lakeComeback_1_1_1,
} from './scenarios/stage1/lakeComeback/lakeComeback_1_1_0';
import { lakeComeback_1_1 } from './scenarios/stage1/lakeComeback/lakeComeback_1_1';
import { hermitageFeeding_1_1_0 } from './scenarios/stage1/hermitageFeeding/hermitageFeeding_1_1_0';
import { hermitageFeeding_1_1 } from './scenarios/stage1/hermitageFeeding/hermitageFeeding_1_1';
import { olympicPark_1_1_0 } from './scenarios/stage1/olympicPark/olympicPark_1_1_0';
import { olympicPark_1_1_1 } from './scenarios/stage1/olympicPark/olympicPark_1_1_1';
import { olympicPark_1_1 } from './scenarios/stage1/olympicPark/olympicPark_1_1';

import { moscowCity_1_1_0 } from './scenarios/stage1/moscow/moscowCity_1_1_0';
import { moscowCity_1_1 } from './scenarios/stage1/moscow/moscowCity_1_1';
import { moscowCity_1_1_1 } from './scenarios/stage1/moscow/moscowCity_1_1_1';

import { alien_1_1_1 } from './scenarios/stage1/alien/alien_1_1_1';
import { alien_1_1_0 } from './scenarios/stage1/alien/alien_1_1_0';
import { alien_1_1 } from './scenarios/stage1/alien/alien_1_1';

import {
  mguDictaphone_1_1_0,
  mguDictaphone_1_1_1,
} from './scenarios/stage1/mguDictaphone/mguDictaphone_1_1_0';
import { mguDictaphone_1_1 } from './scenarios/stage1/mguDictaphone/mguDictaphone_1_1';

import { oboutMoscow_1_1_0 } from './scenarios/stage1/oboutMoscow/oboutMoscow_1_1_0';
import { oboutMoscow_1_1 } from './scenarios/stage1/oboutMoscow/oboutMoscow_1_1';
import {
  oboutInnopolice_1_1_0,
  oboutInnopolice_1_1_1,
} from './scenarios/stage1/oboutInnopolice/oboutInnopolice_1_1_0';
import { oboutInnopolice_1_1 } from './scenarios/stage1/oboutInnopolice/oboutInnopolice_1_1';
import { stg2_innopolice_1_1_0 } from './scenarios/stage2/innopolice/stg2_innopolice_1_1_0';
import { stg2_innopolice_1_1 } from './scenarios/stage2/innopolice/stg2_innopolice_1_1';
import { stg2_mgu_1_1_0 } from './scenarios/stage2/mgu/stg2_mgu_1_1_0';
import { stg2_mgu_1_1_1 } from './scenarios/stage2/mgu/stg2_mgu_1_1_1';
import { stg2_mgu_1_1 } from './scenarios/stage2/mgu/stg2_mgu_1_1';
import { stg2_intro_1_1_0 } from './scenarios/stage2/intro/stg2_intro_1_1_0';
import { stg2_intro_1_1 } from './scenarios/stage2/intro/stg2_intro_1_1';
import { stg2_mgu_1_2 } from './scenarios/stage2/mgu/stg2_mgu_1_2';
import { stg2_lake_1_1_1 } from './scenarios/stage2/lake/stg2_lake_1_1_1';
import { stg2_lake_1_1 } from './scenarios/stage2/lake/stg2_lake_1_1';
import { stg2_hermitage_1_1_1 } from './scenarios/stage2/hermitage/stg2_hermitage_1_1_1';
import { stg2_hermitage_1_1 } from './scenarios/stage2/hermitage/stg2_hermitage_1_1';
import { stg2_olympicPark_1_1_1 } from './scenarios/stage2/olympicPark/stg2_olympicPark_1_1_1';
import { stg2_olympicPark_1_1 } from './scenarios/stage2/olympicPark/stg2_olympicPark_1_1';
import { stg2_moscowCity_1_1_1 } from './scenarios/stage2/moscowCity/stg2_moscowCity_1_1_1';
import { stg2_moscowCity_1_1 } from './scenarios/stage2/moscowCity/stg2_moscowCity_1_1';
import { stg2_mguQuiz_1_1_0 } from './scenarios/stage2/mguQuiz/stg2_mguQuiz_1_1_0';
import { stg2_mguQuiz_1_1_1 } from './scenarios/stage2/mguQuiz/stg2_mguQuiz_1_1_1';
import { stg2_mguQuiz_1_1 } from './scenarios/stage2/mguQuiz/stg2_mguQuiz_1_1';
import { stg2_innopolicePetrov_1_1_0 } from './scenarios/stage2/innopolicePetrov/stg2_innopolicePetrov_1_1_0';
import { stg2_innopolicePetrov_1_1_1 } from './scenarios/stage2/innopolicePetrov/stg2_innopolicePetrov_1_1_1';
import { stg2_innopolicePetrov_1_1 } from './scenarios/stage2/innopolicePetrov/stg2_innopolicePetrov_1_1';
import { stg2_lakeOrderFromGranny_1_1_0 } from './scenarios/stage2/lakeOrderFromGranny/stg2_lakeOrderFromGranny_1_1_0';
import { stg2_lakeOrderFromGranny_1_1_1 } from './scenarios/stage2/lakeOrderFromGranny/stg2_lakeOrderFromGranny_1_1_1';
import { stg2_lakeOrderFromGranny_1_1 } from './scenarios/stage2/lakeOrderFromGranny/stg2_lakeOrderFromGranny_1_1';
import { stg2_kurganAlienFound_1_1_0 } from './scenarios/stage2/kurganAlienFound/stg2_kurganAlienFound_1_1_0';
import { stg2_kurganAlienFound_1_1_1 } from './scenarios/stage2/kurganAlienFound/stg2_kurganAlienFound_1_1_1';
import { stg2_kurganAlienFound_1_1 } from './scenarios/stage2/kurganAlienFound/stg2_kurganAlienFound_1_1';
import { stg2_moscowCityMeetingGranny_1_1_1 } from './scenarios/stage2/moscowCityMeetingGranny/stg2_moscowCityMeetingGranny_1_1_1';
import { stg2_moscowCityMeetingGranny_1_1 } from './scenarios/stage2/moscowCityMeetingGranny/stg2_moscowCityMeetingGranny_1_1';
import { stg2_ostankinoAlienInvasion_1_1_0 } from './scenarios/stage2/ostankinoAlienInvasion/stg2_ostankinoAlienInvasion_1_1_0';
import { stg2_ostankinoAlienInvasion_1_1_1 } from './scenarios/stage2/ostankinoAlienInvasion/stg2_ostankinoAlienInvasion_1_1_1';
import { stg2_ostankinoAlienInvasion_1_1 } from './scenarios/stage2/ostankinoAlienInvasion/stg2_ostankinoAlienInvasion_1_1';
import { stg3_ostankino_1_1_1 } from './scenarios/stage3/ostankino/stg3_ostankino_1_1_1';
import { stg3_ostankino_1_1 } from './scenarios/stage3/ostankino/stg3_ostankino_1_1';
import { stg3_space_1_1_0 } from './scenarios/stage3/space/stg3_space_1_1_0';
import { stg3_space_1_1_0_i } from './scenarios/stage3/space/stg3_space_1_1_0_i';
import { stg3_space_1_1_1 } from './scenarios/stage3/space/stg3_space_1_1_1';
import { stg3_space_1_1 } from './scenarios/stage3/space/stg3_space_1_1';
import { stg3_innopolice_1_1_1 } from './scenarios/stage3/innopolice/stg3_innopolice_1_1_1';
import { stg3_innopolice_1_1 } from './scenarios/stage3/innopolice/stg3_innopolice_1_1';
import { stg3_spaceAlienShip_1_1_1 } from './scenarios/stage3/spaceAlienShip/stg3_spaceAlienShip_1_1_1';
import { stg3_spaceAlienShip_1_1 } from './scenarios/stage3/spaceAlienShip/stg3_spaceAlienShip_1_1';
import { stg3_hermitage_1_1_1 } from './scenarios/stage3/hermitage/stg3_hermitage_1_1_1';
import { stg3_hermitage_1_1 } from './scenarios/stage3/hermitage/stg3_hermitage_1_1';
import { stg3_meetingWithZhenya_1_1_0 } from './scenarios/stage3/meetingWithZhenya/stg3_meetingWithZhenya_1_1_0';
import { stg3_meetingWithZhenya_1_1 } from './scenarios/stage3/meetingWithZhenya/stg3_meetingWithZhenya_1_1';
import { stg3_hersones_1_1_1 } from './scenarios/stage3/hersones/stg3_hersones_1_1_1';
import { stg3_hersones_1_1 } from './scenarios/stage3/hersones/stg3_hersones_1_1';
import { stg3_lake_1_1_0 } from './scenarios/stage3/lake/stg3_lake_1_1_0';
import { stg3_lake_1_1_1 } from './scenarios/stage3/lake/stg3_lake_1_1_1';
import { stg3_lake_1_1 } from './scenarios/stage3/lake/stg3_lake_1_1';
import { stg3_mgu_1_1_1 } from './scenarios/stage3/mgu/stg3_mgu_1_1_1';
import { stg3_mgu_1_1 } from './scenarios/stage3/mgu/stg3_mgu_1_1';
import { stg3_spaceMeetingWithAliens_1_1_0 } from './scenarios/stage3/spaceMeetingWithAliens/stg3_spaceMeetingWithAliens_1_1_0';
import { stg3_spaceMeetingWithAliens_1_1_1 } from './scenarios/stage3/spaceMeetingWithAliens/stg3_spaceMeetingWithAliens_1_1_1';
import { stg3_spaceMeetingWithAliens_1_1 } from './scenarios/stage3/spaceMeetingWithAliens/stg3_spaceMeetingWithAliens_1_1';
import { stg3_bridgeRussianIsland_1_1_0 } from './scenarios/stage3/bridgeRussianIsland/stg3_bridgeRussianIsland_1_1_0';
import { stg3_bridgeRussianIsland_1_1_1 } from './scenarios/stage3/bridgeRussianIsland/stg3_bridgeRussianIsland_1_1_1';
import { stg3_bridgeRussianIsland_1_1 } from './scenarios/stage3/bridgeRussianIsland/stg3_bridgeRussianIsland_1_1';

const scenes: Record<any, Scenario> = {
  // Остановки
  [SceneId.scenario_stop_1]: scenario_stop_1,

  // НЕЗАВЕРШЕННЫЕ ЭТАПЫ ИГРЫ
  [SceneId.scenario_blanks]: scenario_blanks,

  // НЕСОХРАНЕННЫЕ
  [SceneId.scenario_unsaved]: scenario_unsaved,

  // МГУ    2 ЧАСТЬ
  [SceneId.scenario_0]: scenario_0,
  [SceneId.scenario_1_1]: scenario_1_1,
  [SceneId.scenario_1_2]: scenario_1_2,
  [SceneId.scenario_1_3]: scenario_1_3,
  [SceneId.scenario_1_3_1]: scenario_1_3_1,
  [SceneId.scenario_1_4]: scenario_1_4,

  // БАЙКАЛ  3 ЧАСТЬ

  [SceneId.lake_1_1_0]: lake_1_1_0,
  [SceneId.lake_1_1_1]: lake_1_1_1,
  [SceneId.lake_1_1]: lake_1_1,
  [SceneId.lake_1_2]: lake_1_2,
  [SceneId.lake_1_3]: lake_1_3,
  [SceneId.lake_1_4]: lake_1_4,

  // ИННОПОЛИС   4 ЧАСТЬ

  [SceneId.innopolice_1_1_0]: innopolice_1_1_0,
  [SceneId.innopolice_1_1_1]: innopolice_1_1_1,
  [SceneId.innopolice_1_1]: innopolice_1_1,

  // ЭРМИТАЖ    5 ЧАСТЬ

  [SceneId.hermitage_1_1_0]: hermitage_1_1_0,
  [SceneId.hermitage_1_1_1]: hermitage_1_1_1,
  [SceneId.hermitage_1_1]: hermitage_1_1,

  // БАЙКАЛ - ВОЗВРАЩЕНИЕ     6 ЧАСТЬ

  [SceneId.lakeComeback_1_1_0]: lakeComeback_1_1_0,
  [SceneId.lakeComeback_1_1_1]: lakeComeback_1_1_1,
  [SceneId.lakeComeback_1_1]: lakeComeback_1_1,

  // ЭРМИТАЖ - КОРМЛЕНИЕ МАЛЕВИЧА    7 ЧАСТЬ

  [SceneId.hermitageFeeding_1_1_0]: hermitageFeeding_1_1_0,
  [SceneId.hermitageFeeding_1_1]: hermitageFeeding_1_1,

  // ОЛИМПИЙСКИЙ ПАРК    8 ЧАСТЬ

  [SceneId.olympicPark_1_1_0]: olympicPark_1_1_0,
  [SceneId.olympicPark_1_1_1]: olympicPark_1_1_1,
  [SceneId.olympicPark_1_1]: olympicPark_1_1,

  // ЗНАКОМСТВО С ИНОПЛАНЕТЯНИНОМ    9 ЧАСТЬ

  [SceneId.alien_1_1_0]: alien_1_1_0,
  [SceneId.alien_1_1_1]: alien_1_1_1,
  [SceneId.alien_1_1]: alien_1_1,

  // МОСКВА СИТИ    10 ЧАСТЬ

  [SceneId.moscowCity_1_1_0]: moscowCity_1_1_0,
  [SceneId.moscowCity_1_1_1]: moscowCity_1_1_1,
  [SceneId.moscowCity_1_1]: moscowCity_1_1,

  // // МГУ - ДИКТОФОН      11 ЧАТСТЬ

  [SceneId.mguDictaphone_1_1_0]: mguDictaphone_1_1_0,
  [SceneId.mguDictaphone_1_1_1]: mguDictaphone_1_1_1,
  [SceneId.mguDictaphone_1_1]: mguDictaphone_1_1,

  // // МОСКВА СИТИ ЗНАКОМСТВО С САН САНЫЧОМ    12 ЧАСТЬ

  [SceneId.oboutMoscow_1_1_0]: oboutMoscow_1_1_0,
  [SceneId.oboutMoscow_1_1]: oboutMoscow_1_1,

  // // ИННОПОЛОЛИС - ПРО ИННОПОЛИС     13 ЧАСТЬ

  [SceneId.oboutInnopolice_1_1_0]: oboutInnopolice_1_1_0,
  [SceneId.oboutInnopolice_1_1_1]: oboutInnopolice_1_1_1,
  [SceneId.oboutInnopolice_1_1]: oboutInnopolice_1_1,

  // ВТОРОЙ ЭТАП
  // ИНТРО

  [SceneId.stg2_intro_1_1_0]: stg2_intro_1_1_0,
  [SceneId.stg2_intro_1_1]: stg2_intro_1_1,

  // ИННОПОЛИС          1 ЧАСТЬ

  [SceneId.stg2_innopolice_1_1_0]: stg2_innopolice_1_1_0,
  [SceneId.stg2_innopolice_1_1]: stg2_innopolice_1_1,

  // МГУ                 2 ЧАСТь

  [SceneId.stg2_mgu_1_1_0]: stg2_mgu_1_1_0,
  [SceneId.stg2_mgu_1_1_1]: stg2_mgu_1_1_1,
  [SceneId.stg2_mgu_1_1]: stg2_mgu_1_1,
  [SceneId.stg2_mgu_1_2]: stg2_mgu_1_2,

  // БАЙКАЛ                3 ЧАСТь

  [SceneId.stg2_lake_1_1_1]: stg2_lake_1_1_1,
  [SceneId.stg2_lake_1_1]: stg2_lake_1_1,

  // ЭРМИТАЖ                4 ЧАСТь

  [SceneId.stg2_hermitage_1_1_1]: stg2_hermitage_1_1_1,
  [SceneId.stg2_hermitage_1_1]: stg2_hermitage_1_1,

  // Олимпийский парк                5 ЧАСТь

  [SceneId.stg2_olympicPark_1_1_1]: stg2_olympicPark_1_1_1,
  [SceneId.stg2_olympicPark_1_1]: stg2_olympicPark_1_1,

  // Москва Сити                6 ЧАСТь

  [SceneId.stg2_moscowCity_1_1_1]: stg2_moscowCity_1_1_1,
  [SceneId.stg2_moscowCity_1_1]: stg2_moscowCity_1_1,

  // МГУ

  [SceneId.stg2_mguQuiz_1_1_0]: stg2_mguQuiz_1_1_0,
  [SceneId.stg2_mguQuiz_1_1_1]: stg2_mguQuiz_1_1_1,
  [SceneId.stg2_mguQuiz_1_1]: stg2_mguQuiz_1_1,

  // Иннополис Петров

  [SceneId.stg2_innopolicePetrov_1_1_0]: stg2_innopolicePetrov_1_1_0,
  [SceneId.stg2_innopolicePetrov_1_1_1]: stg2_innopolicePetrov_1_1_1,
  [SceneId.stg2_innopolicePetrov_1_1]: stg2_innopolicePetrov_1_1,

  // БАЙКАЛ ЗАКАЗ ОТ БАБУЛИ

  [SceneId.stg2_lakeOrderFromGranny_1_1_0]: stg2_lakeOrderFromGranny_1_1_0,
  [SceneId.stg2_lakeOrderFromGranny_1_1_1]: stg2_lakeOrderFromGranny_1_1_1,
  [SceneId.stg2_lakeOrderFromGranny_1_1]: stg2_lakeOrderFromGranny_1_1,

  // Мамаев Курган Инопланетянин найден

  [SceneId.stg2_kurganAlienFound_1_1_0]: stg2_kurganAlienFound_1_1_0,
  [SceneId.stg2_kurganAlienFound_1_1_1]: stg2_kurganAlienFound_1_1_1,
  [SceneId.stg2_kurganAlienFound_1_1]: stg2_kurganAlienFound_1_1,

  // МОСКВА СИТИ

  [SceneId.stg2_moscowCityMeetingGranny_1_1_1]: stg2_moscowCityMeetingGranny_1_1_1,
  [SceneId.stg2_moscowCityMeetingGranny_1_1]: stg2_moscowCityMeetingGranny_1_1,

  /// ОСТАНКИНСКАЯ БАШНЯ

  [SceneId.stg2_ostankinoAlienInvasion_1_1_0]: stg2_ostankinoAlienInvasion_1_1_0,
  [SceneId.stg2_ostankinoAlienInvasion_1_1_1]: stg2_ostankinoAlienInvasion_1_1_1,
  [SceneId.stg2_ostankinoAlienInvasion_1_1]: stg2_ostankinoAlienInvasion_1_1,

  // 3 ЭТАП

  /// ОСТАНКИНСКАЯ БАШНЯ

  [SceneId.stg3_ostankino_1_1_1]: stg3_ostankino_1_1_1,
  [SceneId.stg3_ostankino_1_1]: stg3_ostankino_1_1,

  /// КОСМОДРОМ

  [SceneId.stg3_space_1_1_0]: stg3_space_1_1_0,
  [SceneId.stg3_space_1_1_0_i]: stg3_space_1_1_0_i,
  [SceneId.stg3_space_1_1_1]: stg3_space_1_1_1,
  [SceneId.stg3_space_1_1]: stg3_space_1_1,

  /// ИННОПОЛИС

  [SceneId.stg3_innopolice_1_1_1]: stg3_innopolice_1_1_1,
  [SceneId.stg3_innopolice_1_1]: stg3_innopolice_1_1,

  /// КОСМОДРОМ КОРАБЛЬ ПРИШЕЛЬЦЕВ

  [SceneId.stg3_spaceAlienShip_1_1_1]: stg3_spaceAlienShip_1_1_1,
  [SceneId.stg3_spaceAlienShip_1_1]: stg3_spaceAlienShip_1_1,

  // ЭРМИТАЖ

  [SceneId.stg3_hermitage_1_1_1]: stg3_hermitage_1_1_1,
  [SceneId.stg3_hermitage_1_1]: stg3_hermitage_1_1,

  // ВСТРЕЧА С ЖЕНЕЙ

  [SceneId.stg3_meetingWithZhenya_1_1_0]: stg3_meetingWithZhenya_1_1_0,
  [SceneId.stg3_meetingWithZhenya_1_1]: stg3_meetingWithZhenya_1_1,

  // ХЕРСОНЕС

  [SceneId.stg3_hersones_1_1_1]: stg3_hersones_1_1_1,
  [SceneId.stg3_hersones_1_1]: stg3_hersones_1_1,

  // БАЙКАЛ

  [SceneId.stg3_lake_1_1_0]: stg3_lake_1_1_0,
  [SceneId.stg3_lake_1_1_1]: stg3_lake_1_1_1,
  [SceneId.stg3_lake_1_1]: stg3_lake_1_1,

  // МГУ

  [SceneId.stg3_mgu_1_1_1]: stg3_mgu_1_1_1,
  [SceneId.stg3_mgu_1_1]: stg3_mgu_1_1,

  // КОСМОДРОМ

  [SceneId.stg3_spaceMeetingWithAliens_1_1_0]: stg3_spaceMeetingWithAliens_1_1_0,
  [SceneId.stg3_spaceMeetingWithAliens_1_1_1]: stg3_spaceMeetingWithAliens_1_1_1,
  [SceneId.stg3_spaceMeetingWithAliens_1_1]: stg3_spaceMeetingWithAliens_1_1,

  // МОСТ НА РУССКИЙ ОСТРОВ

  [SceneId.stg3_bridgeRussianIsland_1_1_0]: stg3_bridgeRussianIsland_1_1_0,
  [SceneId.stg3_bridgeRussianIsland_1_1_1]: stg3_bridgeRussianIsland_1_1_1,
  [SceneId.stg3_bridgeRussianIsland_1_1]: stg3_bridgeRussianIsland_1_1,

  // Старый код - отредактировать !
  [SceneId.scenario_1_5_1]: ostankino,
  [SceneId.scenario_1_5_2]: space,
  [SceneId.scenario_1_5_3]: mgu,
  [SceneId.scenario_1_7_0]: scenario_1_7_0,
  [SceneId.scenario_1_7]: scenario_1_7,
  [SceneId.scenario_1_7_1]: scenario_1_7_1,
  [SceneId.scenario_1_8_0]: scenario_1_8_0,
  [SceneId.scenario_1_8]: scenario_1_8,
  [SceneId.scenario_1_8_1]: scenario_1_8_1,
  [SceneId.scenario_1_8_4]: scenario_1_8_4,
  [SceneId.scenario_1_8_5]: scenario_1_8_5,
  [SceneId.scenario_1_8_6]: scenario_1_8_6,
  [SceneId.scenario_1_9]: scenario_1_9,
  [SceneId.scenario_1_9_0]: scenario_1_9_0,
  [SceneId.scenario_1_9_1]: scenario_1_9_1,
  [SceneId.scenario_1_9_2]: scenario_1_9_2,
  [SceneId.scenario_1_10]: scenario_1_10,
  [SceneId.scenario_1_14_0]: scenario_1_14_0,
  [SceneId.scenario_1_14]: scenario_1_14,
  [SceneId.scenario_1_14_1]: scenario_1_14_1,
  [SceneId.scenario_1_17_0]: scenario_1_17_0,
  [SceneId.scenario_1_17]: scenario_1_17,
  [SceneId.scenario_1_17_1]: scenario_1_17_1,
  [SceneId.scenario_1_17_2]: scenario_1_17_2,
  [SceneId.scenario_1_17_3]: scenario_1_17_3,
  [SceneId.scenario_1_18_0]: scenario_1_18_0,
  [SceneId.scenario_1_18]: scenario_1_18,
  [SceneId.scenario_1_18_1]: scenario_1_18_1,
  [SceneId.scenario_1_19]: scenario_1_19,
  [SceneId.scenario_1_19_1]: scenario_1_19_1,
  [SceneId.scenario_1_19_4]: scenario_1_19_4,
  [SceneId.scenario_1_19_5]: scenario_1_19_5,
  [SceneId.scenario_1_19_6]: scenario_1_19_6,
  [SceneId.scenario_1_19_7]: scenario_1_19_7,
  [SceneId.scenario_1_22]: scenario_1_22,
  [SceneId.scenario_1_23]: scenario_1_23,
  [SceneId.scenario_1_24]: scenario_1_24,
  [SceneId.scenario_1_24_3]: scenario_1_24_3,
  [SceneId.scenario_1_24_4]: scenario_1_24_4,
  [SceneId.scenario_1_24_5]: scenario_1_24_5,
  [SceneId.scenario_1_24_6]: scenario_1_24_6,
  [SceneId.scenario_1_25_0]: scenario_1_25_0,
  [SceneId.scenario_1_25]: scenario_1_25,
  [SceneId.scenario_1_25_1]: scenario_1_25_1,
  [SceneId.scenario_1_25_2]: scenario_1_25_2,
  [SceneId.scenario_1_25_4]: scenario_1_25_4,
  [SceneId.scenario_1_26_1]: scenario_1_26_1,
  [SceneId.scenario_1_26_2]: scenario_1_26_2,
  [SceneId.scenario_1_26_3]: scenario_1_26_3,
  [SceneId.scenario_1_26_4]: scenario_1_26_4,
  // фиктивный, чтобы отметить прохождение этапа
  [SceneId.scenario_1_30]: scenario_1_26_4,

  [SceneId.scenario_2_1_i]: scenario_2_1_i,
  [SceneId.scenario_2_1_Second]: scenario_2_1_Second,
  [SceneId.scenario_2_1]: scenario_2_1,

  [SceneId.scenario_2_2_i]: scenario_2_2_i,
  [SceneId.scenario_2_2]: scenario_2_2,

  [SceneId.scenario_2_3_i]: scenario_2_3_i,
  [SceneId.scenario_2_3]: scenario_2_3,

  [SceneId.scenario_2_4_0]: scenario_2_4_0,
  [SceneId.scenario_2_4_i]: scenario_2_4_i,
  [SceneId.scenario_2_4]: scenario_2_4,

  [SceneId.scenario_2_5_i]: scenario_2_5_i,
  [SceneId.scenario_2_5]: scenario_2_5,

  [SceneId.scenario_2_6]: scenario_2_6,

  [SceneId.scenario_2_7_i]: scenario_2_7_i,
  [SceneId.scenario_2_7]: scenario_2_7,

  [SceneId.scenario_2_8_i]: scenario_2_8_i,
  [SceneId.scenario_2_8]: scenario_2_8,

  [SceneId.scenario_2_9_i]: scenario_2_9_i,
  [SceneId.scenario_2_9]: scenario_2_9,

  // [SceneId.scenario_2_0]: scenario_2_0,
  // [SceneId.scenario_2_0_1]: scenario_2_0_1,
  // [SceneId.scenario_2_1]: scenario_2_1,
  // [SceneId.scenario_2_1_1]: scenario_2_1_1,
  // [SceneId.scenario_2_2]: scenario_2_2,
  // [SceneId.scenario_2_2_1]: scenario_2_2_1,
  // [SceneId.scenario_2_2_4]: scenario_2_2_4,
  // [SceneId.scenario_2_4_0]: scenario_2_4_0,
  // [SceneId.scenario_2_4]: scenario_2_4,

  // [SceneId.scenario_2_5_1]: scenario_2_5_1,
  // [SceneId.scenario_2_5_2]: scenario_2_5_2,
  // [SceneId.scenario_2_5_3]: scenario_2_5_3,
  // [SceneId.scenario_2_5_4]: scenario_2_5_4,
  // [SceneId.scenario_2_5_5]: scenario_2_5_5,
  // [SceneId.scenario_2_5_6]: scenario_2_5_6,
  // [SceneId.scenario_2_5_7]: scenario_2_5_7,

  //[SceneId.scenario_2_9]: scenario_2_9,
  //[SceneId.scenario_2_9_1]: scenario_2_9_1,
  //[SceneId.scenario_2_9_2]: scenario_2_9_2,
  [SceneId.scenario_2_10_0]: scenario_2_10_0,
  [SceneId.scenario_2_10]: scenario_2_10,
  [SceneId.scenario_2_10_1]: scenario_2_10_1,
  [SceneId.scenario_2_10_1_0]: scenario_2_10_1_0,

  [SceneId.scenario_2_11]: scenario_2_11,
  [SceneId.scenario_2_11_0]: scenario_2_11_0,
  [SceneId.scenario_2_11_4]: scenario_2_11_4,
  [SceneId.scenario_2_11_5]: scenario_2_11_5,
  [SceneId.scenario_egg1_0]: scenario_egg1_0,
  [SceneId.scenario_egg1]: scenario_egg1,
  [SceneId.scenario_egg2_0]: scenario_egg2_0,

  [SceneId.scenario_2_12]: scenario_2_12,
  [SceneId.scenario_2_13]: scenario_2_13,
  [SceneId.scenario_2_14_0]: scenario_2_14_0,
  [SceneId.scenario_2_14]: scenario_2_14,

  [SceneId.scenario_2_15]: scenario_2_15,
  [SceneId.scenario_2_15_0]: scenario_2_15_0,
  [SceneId.scenario_2_16]: scenario_2_16,
  [SceneId.scenario_2_16_1]: scenario_2_16_1,
  [SceneId.scenario_2_16_2]: scenario_2_16_2,
  [SceneId.scenario_2_16_3]: scenario_2_16_3,
  [SceneId.scenario_2_16_4]: scenario_2_16_4,
  [SceneId.scenario_2_16_5]: scenario_2_16_5,
  [SceneId.scenario_2_16_0_5]: scenario_2_16_0_5,
  [SceneId.scenario_2_16_1_5]: scenario_2_16_1_5,
  [SceneId.scenario_2_16_1_3]: scenario_2_16_1_3,

  [SceneId.scenario_2_20]: scenario_2_20,
  [SceneId.scenario_2_20_1]: scenario_2_20_1,
  [SceneId.scenario_2_21]: scenario_2_21,
  [SceneId.scenario_2_21_1]: scenario_2_21_1,
  [SceneId.scenario_2_22]: scenario_2_22,
  [SceneId.scenario_2_22_1]: scenario_2_22_1,
  [SceneId.scenario_2_23]: scenario_2_23,
  [SceneId.scenario_egg3_0]: scenario_egg3_0,
  [SceneId.scenario_egg4_0]: scenario_egg4_0,
  [SceneId.scenario_egg4_1]: scenario_egg4_1,
  [SceneId.scenario_egg5_0]: scenario_egg5_0,
  [SceneId.scenario_egg6_0]: scenario_egg6_0,
  [SceneId.scenario_egg6_1]: scenario_egg6_1,
  // фиктивный, чтобы отметить прохождение этапа
  [SceneId.scenario_2_30]: scenario_egg3_0,

  [SceneId.scenario_3_0]: scenario_3_0,
  [SceneId.scenario_3_0_1]: scenario_3_0_1,
  [SceneId.scenario_3_0_2]: scenario_3_0_2,
  [SceneId.scenario_3_0_3]: scenario_3_0_3,
  [SceneId.scenario_3_0_4]: scenario_3_0_4,
  [SceneId.scenario_3_0_5]: scenario_3_0_5,
  [SceneId.scenario_3_0_6]: scenario_3_0_6,
  [SceneId.scenario_3_0_7]: scenario_3_0_7,
  [SceneId.scenario_3_0_8]: scenario_3_0_8,
  [SceneId.scenario_3_0_9]: scenario_3_0_9,
  [SceneId.scenario_3_0_10]: scenario_3_0_10,
  [SceneId.scenario_3_0_11]: scenario_3_0_11,
  [SceneId.scenario_3_0_12]: scenario_3_0_12,
  [SceneId.scenario_3_0_13]: scenario_3_0_13,
  [SceneId.scenario_3_0_14]: scenario_3_0_14,

  [SceneId.scenario_3_1_i]: scenario_3_1_i,
  [SceneId.scenario_3_1]: scenario_3_1,
  [SceneId.scenario_3_2_i]: scenario_3_2_i,
  [SceneId.scenario_3_2]: scenario_3_2,
  [SceneId.scenario_3_3_i]: scenario_3_3_i,
  [SceneId.scenario_3_3]: scenario_3_3,

  [SceneId.scenario_3_3_0]: scenario_3_3_0,
  [SceneId.scenario_3_4_i]: scenario_3_4_i,
  [SceneId.scenario_3_4]: scenario_3_4,
  [SceneId.scenario_3_5_i]: scenario_3_5_i,
  [SceneId.scenario_3_5]: scenario_3_5,
  [SceneId.scenario_3_6_i]: scenario_3_6_i,
  [SceneId.scenario_3_6]: scenario_3_6,

  [SceneId.scenario_3_7_i]: scenario_3_7_i,
  [SceneId.scenario_3_7]: scenario_3_7,

  [SceneId.scenario_3_10]: scenario_3_10,
  [SceneId.scenario_3_11]: scenario_3_11,
  [SceneId.scenario_3_12]: scenario_3_12,
  [SceneId.scenario_3_12_0]: scenario_3_12_0,
  [SceneId.scenario_3_13]: scenario_3_13,
  [SceneId.scenario_3_13_0]: scenario_3_13_0,
  [SceneId.scenario_3_14]: scenario_3_14,
  [SceneId.scenario_3_14_0]: scenario_3_14_0,
  [SceneId.scenario_3_15]: scenario_3_15,
  [SceneId.scenario_3_15_0]: scenario_3_15_0,
  [SceneId.scenario_3_16]: scenario_3_16,
  [SceneId.scenario_3_16_0]: scenario_3_16_0,
  [SceneId.scenario_3_17]: scenario_3_17,
  [SceneId.scenario_3_17_0]: scenario_3_17_0,
  [SceneId.scenario_3_18]: scenario_3_18,
  [SceneId.scenario_3_18_0]: scenario_3_18_0,
  [SceneId.scenario_3_19]: scenario_3_19,
  [SceneId.scenario_3_19_0]: scenario_3_19_0,
  [SceneId.scenario_3_19_1]: scenario_3_19_1,

  [SceneId.scenario_3_20]: scenario_3_20,
  [SceneId.scenario_3_21]: scenario_3_21,
  [SceneId.scenario_3_22]: scenario_3_22,
  [SceneId.scenario_3_22_1]: scenario_3_22_1,
  [SceneId.scenario_3_23_0]: scenario_3_23_0,
  [SceneId.scenario_3_23]: scenario_3_23,
  [SceneId.scenario_3_23_01]: scenario_3_23_01,
  [SceneId.scenario_3_23_02]: scenario_3_23_02,
  [SceneId.scenario_3_23_1]: scenario_3_23_1,
  [SceneId.scenario_3_24]: scenario_3_24,
};

export default scenes;
