/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../scenario';
import interlocutors from '../../interlocutors';

export const scenario_1_2: Scenario = {
  scenarioId: SceneId.scenario_1_2,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `Сейчас парк совсем грустно выглядит, правда? Чтобы помочь восстановить какую-то часть, нажми на красный указатель на достопримечательности.

    А если тебе понадобится подсказка куда идти и что делать дальше, ты всегда можешь обратиться ко мне, нажав на кнопку с моим фото внизу экрана. И я приду на помощь.`,
    buttons: ['Закрыть'],
  },
};
