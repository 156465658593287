import styled from 'styled-components';

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  cursor: grab;
  user-select: none;

  &:active {
    cursor: grabbing;
  }
`;
