import { AnswerStep, Question, QuestionButton, Step, StepType } from '../resources/scenario';
import { Optional } from '../redux/user';
import { IProtocol, TestType } from '../types';

function fixDuration(protocol: IProtocol) {
  for (const subtest in protocol.subtests) {
    protocol.subtests[subtest].duration = Object.values(
      protocol.subtests[subtest].questions,
    ).reduce((v, i) => v + i.duration, 0);
  }
  protocol.duration = Object.values(protocol.subtests).reduce((v, i) => v + i.duration, 0);
}

function safeInsertTvoiKod(
  protocol: IProtocol,
  question: Question,
  button: QuestionButton,
  duration: number,
) {
  const [, , subtest, questionId, subQuestion] = question.id.split('/');
  if (!protocol.subtests[subtest]) {
    protocol.subtests[subtest] = { duration: 0, questions: {} };
  }
  if (!protocol.subtests[subtest].questions[questionId]) {
    protocol.subtests[subtest].questions[questionId] = {
      duration: 0,
      answers: {},
    };
  }
  protocol.subtests[subtest].questions[questionId].answers[subQuestion] = Number(button.id) || 1;
  protocol.subtests[subtest].questions[questionId].duration += duration;
}

/**
 * Парсит тест Твой код - сценарий 1.3
 * @param hist
 */
export function parseTestTvoiKod(hist: Step[]): IProtocol {
  let index = hist.findIndex((step) => step.id.indexOf('TEST') !== -1);
  const protocol: IProtocol = {
    duration: 0,
    subtests: {},
  };
  let lastTime = 0;
  while (hist[index] && hist[index].id.indexOf('TEST') !== -1) {
    let question: Optional<Question> =
      hist[index].type === StepType.question ? (hist[index] as Question) : undefined;
    for (; index < hist.length; ++index) {
      if (hist[index].type === StepType.answer) {
        break;
      }
      if (hist[index].type === StepType.question) {
        question = hist[index] as Question;
      }
    }
    const answer = hist[index] as Optional<AnswerStep>;
    if (question && question.id.indexOf('TEST') !== -1 && answer) {
      const button = answer.data.value[0] as QuestionButton;
      const curTime = new Date(answer.date).valueOf();
      // костыль - первый вопрос - 10 секунд. Остальные корректно
      safeInsertTvoiKod(
        protocol,
        question,
        button,
        lastTime ? Math.round((curTime - lastTime) / 1000) : 10,
      );
      lastTime = curTime;
    }
    ++index;
  }
  fixDuration(protocol);
  return protocol;
}

function safeInsert(
  protocol: IProtocol,
  question: Question,
  buttons: QuestionButton[],
  duration: number,
  testType: TestType,
  testFormat: 'json' | 'js',
) {
  let subtest;
  let questionId;

  if (testFormat === 'json') {
    const [, , sub_test, question_id] = question.id.split('/');
    subtest = sub_test;
    questionId = question_id;
  } else if (testFormat === 'js') {
    if (testType === TestType.logic) {
      const [, sub_test, , , id_1, id_2] = question.id.split('_');
      subtest = sub_test;
      questionId = id_2 ? `${id_1}_${id_2}` : id_1;
    }
    if (testType === TestType.creativity) {
      const [sub_test, ...question_id] = question.id.split('_');
      subtest = sub_test;
      questionId = question_id.join('_');
    }
  }

  if (!subtest || !questionId) return;

  if (!protocol.subtests[subtest]) {
    protocol.subtests[subtest] = { duration: 0, questions: {} };
  }

  if (!protocol.subtests[subtest].questions[questionId]) {
    protocol.subtests[subtest].questions[questionId] = {
      duration: 0,
      answers: {},
    };
    const { answers } = protocol.subtests[subtest].questions[questionId];
    if (question.buttons) {
      const qButtons = question.buttons;
      for (let i = 0; i < qButtons.length; i++) {
        answers[qButtons[i].id] = 1;
      }
    }
  }

  for (let i = 0; i < buttons.length; i++) {
    const button = buttons[i];
    if (button.id) {
      protocol.subtests[subtest].questions[questionId].answers[button.id] = 2;
    } else if (typeof buttons === 'string') {
      protocol.subtests[subtest].questions[questionId].answers['b1'] = buttons;
    }
  }

  protocol.subtests[subtest].questions[questionId].duration += duration;
}

export function parseTests(
  hist: Step[],
  testType: TestType,
  testFormat: 'json' | 'js',
  testStartStep: string = 'TEST',
): IProtocol {
  let index = hist.findIndex((step) => step.id.indexOf(testStartStep) !== -1);
  const protocol: IProtocol = {
    duration: 0,
    subtests: {},
  };
  let lastTime = 0;
  while (hist[index] && hist[index].id.indexOf(testStartStep) !== -1) {
    let question: Optional<Question> =
      hist[index].type === StepType.question || hist[index].type === StepType.text_question
        ? (hist[index] as Question)
        : undefined;
    let questionsCount = 0;
    for (; index < hist.length; ++index) {
      if (hist[index].type === StepType.answer) {
        break;
      }
      if (hist[index].type === StepType.question || hist[index].type === StepType.text_question) {
        if (questionsCount > 0) {
          index--;
          break;
        }
        question = hist[index] as Question;
        ++questionsCount;
      }
    }
    const next = hist[index];
    if (
      question &&
      question.id.indexOf(testFormat === 'js' ? `${testStartStep}_` : `${testStartStep}/`) !== -1 &&
      next
    ) {
      const buttons = next.type === StepType.answer ? (next.data.value as QuestionButton[]) : [];
      const curTime = new Date(next.date || lastTime).valueOf();

      safeInsert(
        protocol,
        question,
        buttons,
        buttons.length === 0 ? 60 : lastTime ? Math.round((curTime - lastTime) / 1000) : 10,
        testType,
        testFormat,
      );

      lastTime = curTime;
    }
    ++index;
  }
  fixDuration(protocol);
  return protocol;
}

export function mergeProtocols(p1: IProtocol, p2: IProtocol): IProtocol {
  const res: IProtocol = {
    duration: 0,
    subtests: {},
  };
  for (const sub in p1.subtests) {
    res.subtests[sub] = {
      duration: 0,
      questions: {},
    };
    res.subtests[sub].questions = {
      ...p1.subtests[sub].questions,
      ...(p2.subtests[sub]?.questions || {}),
    };
  }
  for (const sub in p2.subtests) {
    res.subtests[sub] = {
      duration: 0,
      questions: {},
    };
    res.subtests[sub].questions = {
      ...p2.subtests[sub].questions,
      ...(p1.subtests[sub]?.questions || {}),
    };
  }
  fixDuration(res);
  return res;
}
