import { RootState } from '../../types';
import { ChallengeBackendData, ChallengeKey } from '../../components/Challenges/config';

export const selectToken = (state: RootState) => state.user.token;

export const selectUserInfo = (state: RootState) => state.user.info;

export const selectUserGrade = ({ user }: RootState) => user.info?.grade;

export const selectScenarios = (state: RootState) => state.scenario;

export const selectCompletedScenarios = (state: RootState) => state.scenario.scenarios;

// export const selectAllSteps = (state: RootState) => state.scenario.sequenceOfAllSteps;

export const selectUserState = (state: RootState) => state.user.info?.state || {};

export const selectUserFiles = (state: RootState) => state.user.userFiles;

export const selectUserRightThingHistory = (state: RootState) =>
  (state.user.info?.state || {}).rightThingHistory;

export const selectChallengesData = (state: RootState) =>
  (state.user.info?.state || {}).challenges || ({} as Record<ChallengeKey, ChallengeBackendData>);
