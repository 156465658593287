/* eslint-disable max-len */
import { Interlocutors, Step, StepType, SystemStepType } from '../../../types/scenario';
import { images } from './images';

const person = Interlocutors.GRANDMOTHER;

export const TEST_LOGIC_MAIN_2024: Record<string, Step> = {
  TEST_LOGIC_MAIN_2024_START: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_START',
    type: StepType.system,
    _meta: {
      type: SystemStepType.limitedTimeGroup,
      time: 20 * 60,
      timeoutStep: 'TEST_LOGIC_MAIN_2024_TIMEOUT',
      exitStep: 'Q12',
    },
    nextStep: 'TEST_LOGIC_MAIN_2024_Q1',
  },

  TEST_LOGIC_MAIN_2024_TIMEOUT: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_TIMEOUT',
    type: StepType.message,
    text: `{{NAME}}, увы, время закончилось...`,
    nextStep: 'Q12',
  },

  // ЗАДАНИЕ 1.

  TEST_LOGIC_MAIN_2024_Q1: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q1',
    text: 'Найди зашифрованное слово',
    image: { uri: images.logic_test_2024_1, title: '' },
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_2024_Q1',
    nextStep: 'TEST_LOGIC_MAIN_2024_Q2',
  },

  // ЗАДАНИЕ 2.

  TEST_LOGIC_MAIN_2024_Q2: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q2',
    text: `Определи, какая фигура соответствует имени кота, который сопровождает тебя по парку в игре. Подсказка: слову «кот» соответствует третья фигура.`,
    image: { uri: images.logic_test_2024_2, title: '' },
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_2024_Q2',
    nextStep: 'TEST_LOGIC_MAIN_2024_Q3',
  },

  // ЗАДАНИЕ 3.

  TEST_LOGIC_MAIN_2024_Q3: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q3',
    text: `Дыня и гранат весят столько же, сколько арбуз. Две дыни весят столько же, сколько арбуз и гранат. Сколько нужно гранатов, чтобы уравнять вес дыни?`,
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_2024_Q3',
    nextStep: 'TEST_LOGIC_MAIN_2024_Q4',
  },

  // ЗАДАНИЕ 4.

  TEST_LOGIC_MAIN_2024_Q4: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q4',
    text: `Дети выполняли задания марафона «Время твоих перемен». Место в рейтинге зависит от того, кто раньше сдаст задание.  Света сдала задание позже, чем Кирилл и Соня, но раньше, чем Милана. Кирилл сдал задание раньше Миланы, но позже Сони. Милана сдала задание раньше Алёны.`,
    type: StepType.message,
    nextStep: 'TEST_LOGIC_MAIN_2024_Q4_1',
  },

  TEST_LOGIC_MAIN_2024_Q4_1: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q4_1',
    text: `Кто окажется в рейтинге первым?`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Света',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q4_2',
      },
      {
        id: 'b2',
        text: 'Кирилл',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q4_2',
      },
      {
        id: 'b3',
        text: 'Соня',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q4_2',
      },
      {
        id: 'b4',
        text: 'Милана',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q4_2',
      },
      {
        id: 'b5',
        text: 'Алёна',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q4_2',
      },
    ],
    nextStep: 'TEST_LOGIC_MAIN_2024_Q4_2',
  },

  TEST_LOGIC_MAIN_2024_Q4_2: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q4_2',
    text: `Кто окажется в рейтинге последним?`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Света',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q5',
      },
      {
        id: 'b2',
        text: 'Кирилл',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q5',
      },
      {
        id: 'b3',
        text: 'Соня',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q5',
      },
      {
        id: 'b4',
        text: 'Милана',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q5',
      },
      {
        id: 'b5',
        text: 'Алёна',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q5',
      },
    ],
    nextStep: 'TEST_LOGIC_MAIN_2024_Q5',
  },

  // ЗАДАНИЕ 5.

  TEST_LOGIC_MAIN_2024_Q5: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q5',
    text: 'Впиши числа 34, 9, 27, 13 в фигуры так, чтобы стрелки шли от большего числа к меньшему',
    image: { uri: images.logic_test_2024_3, title: '' },
    type: StepType.message,
    nextStep: 'TEST_LOGIC_MAIN_2024_Q5_1',
  },

  TEST_LOGIC_MAIN_2024_Q5_1: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q5_1',
    text: 'В треугольнике должна быть цифра…',
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_2024_Q5_1',
    nextStep: 'TEST_LOGIC_MAIN_2024_Q5_2',
  },

  TEST_LOGIC_MAIN_2024_Q5_2: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q5_2',
    text: 'В квадрате должна быть цифра…',
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_2024_Q5_2',
    nextStep: 'TEST_LOGIC_MAIN_2024_Q5_3',
  },

  TEST_LOGIC_MAIN_2024_Q5_3: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q5_3',
    text: 'В сердечке должна быть цифра…',
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_2024_Q5_3',
    nextStep: 'TEST_LOGIC_MAIN_2024_Q5_4',
  },

  TEST_LOGIC_MAIN_2024_Q5_4: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q5_4',
    text: 'В круге должна быть цифра…',
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_2024_Q5_4',
    nextStep: 'TEST_LOGIC_MAIN_2024_Q6',
  },

  // ЗАДАНИЕ 6.

  TEST_LOGIC_MAIN_2024_Q6: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q6',
    text: `Расставь буквы в словах правильно и найди лишнее слово:
    ЯСОИТРИ    ИРАЛТТРЕУА    ИИТВЗАК    ИФКИЗА`,
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_2024_Q6',
    nextStep: 'TEST_LOGIC_MAIN_2024_Q7',
  },

  // ЗАДАНИЕ 7.

  TEST_LOGIC_MAIN_2024_Q7: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q7',
    text: 'Вы повстречали кота Малевича. Кот Малевич никогда не говорит правду по понедельникам, вторникам и средам и говорит только правду во все остальные дни недели.  При встрече он сказал: «Я лгал вчера и после завтрашнего дня я буду лгать два дня подряд». В какой день недели произошла ваша встреча?',
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_2024_Q7',
    nextStep: 'TEST_LOGIC_MAIN_2024_Q8',
  },

  // ЗАДАНИЕ 8.

  TEST_LOGIC_MAIN_2024_Q8: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q8',
    text: `Два экскаватора выкапывают 2 метра канавы за 2 часа. Сколько нужно экскаваторов чтобы за 8 часов выкопать 8 метров канавы?`,
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_2024_Q8',
    nextStep: 'TEST_LOGIC_MAIN_2024_Q9',
  },

  // ЗАДАНИЕ 9.

  TEST_LOGIC_MAIN_2024_Q9: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q9',
    text: `Оля, Марианна, Катя, Ибрагим, Сергей и Олег ходят в музыкальную школу.  В школе есть занятия по игре на барабанной установке, гитаре, скрипке, домбре, аккордеоне. Никто из детей не играет на цифровом инструменте. У девочек и мальчиков разные инструменты. У всех мальчиков разные инструменты. Девочки не играют на струнных инструментах. Олег хочет быть таким же умелым исполнителем, как Паганини, ведь он играет на таком же инструменте. Марианна единственная из девочек, кто может унести свой инструмент домой. В инструменте Ибрагима больше струн, чем у Сергея.`,
    type: StepType.message,
    nextStep: 'TEST_LOGIC_MAIN_2024_Q9_1',
  },

  TEST_LOGIC_MAIN_2024_Q9_1: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q9_1',
    text: `Какой инструмент у Оли?`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'барабанная установка',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_2',
      },
      {
        id: 'b2',
        text: 'гитара',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_2',
      },
      {
        id: 'b3',
        text: 'скрипка',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_2',
      },
      {
        id: 'b4',
        text: 'домбра',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_2',
      },
      {
        id: 'b5',
        text: 'аккордеон',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_2',
      },
    ],
    nextStep: 'TEST_LOGIC_MAIN_2024_Q9_2',
  },

  TEST_LOGIC_MAIN_2024_Q9_2: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q9_2',
    text: `Какой инструмент у Марианны?`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'барабанная установка',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_3',
      },
      {
        id: 'b2',
        text: 'гитара',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_3',
      },
      {
        id: 'b3',
        text: 'скрипка',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_3',
      },
      {
        id: 'b4',
        text: 'домбра',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_3',
      },
      {
        id: 'b5',
        text: 'аккордеон',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_3',
      },
    ],
    nextStep: 'TEST_LOGIC_MAIN_2024_Q9_3',
  },

  TEST_LOGIC_MAIN_2024_Q9_3: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q9_3',
    text: `Какой инструмент у Кати?`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'барабанная установка',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_4',
      },
      {
        id: 'b2',
        text: 'гитара',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_4',
      },
      {
        id: 'b3',
        text: 'скрипка',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_4',
      },
      {
        id: 'b4',
        text: 'домбра',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_4',
      },
      {
        id: 'b5',
        text: 'аккордеон',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_4',
      },
    ],
    nextStep: 'TEST_LOGIC_MAIN_2024_Q9_4',
  },

  TEST_LOGIC_MAIN_2024_Q9_4: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q9_4',
    text: `Какой инструмент у Ибрагима?`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'барабанная установка',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_5',
      },
      {
        id: 'b2',
        text: 'гитара',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_5',
      },
      {
        id: 'b3',
        text: 'скрипка',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_5',
      },
      {
        id: 'b4',
        text: 'домбра',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_5',
      },
      {
        id: 'b5',
        text: 'аккордеон',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_5',
      },
    ],
    nextStep: 'TEST_LOGIC_MAIN_2024_Q9_5',
  },

  TEST_LOGIC_MAIN_2024_Q9_5: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q9_5',
    text: `Какой инструмент у Сергея?`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'барабанная установка',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_6',
      },
      {
        id: 'b2',
        text: 'гитара',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_6',
      },
      {
        id: 'b3',
        text: 'скрипка',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_6',
      },
      {
        id: 'b4',
        text: 'домбра',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_6',
      },
      {
        id: 'b5',
        text: 'аккордеон',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q9_6',
      },
    ],
    nextStep: 'TEST_LOGIC_MAIN_2024_Q9_6',
  },

  TEST_LOGIC_MAIN_2024_Q9_6: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q9_6',
    text: `Какой инструмент у Олега?`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'барабанная установка',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q10',
      },
      {
        id: 'b2',
        text: 'гитара',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q10',
      },
      {
        id: 'b3',
        text: 'скрипка',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q10',
      },
      {
        id: 'b4',
        text: 'домбра',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q10',
      },
      {
        id: 'b5',
        text: 'аккордеон',
        nextStep: 'TEST_LOGIC_MAIN_2024_Q10',
      },
    ],
    nextStep: 'TEST_LOGIC_MAIN_2024_Q10',
  },

  // ЗАДАНИЕ 10.

  TEST_LOGIC_MAIN_2024_Q10: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q10',
    text: `Среди школьников начальной школы проводилось анкетирование по любимым мультфильмам «Союзмультфильма». Самыми популярными оказались три мультфильма: «Умка», «Простоквашино», «Ну, погоди! ». Всего в опросе приняли участие 140 школьников. «Ну, погоди» выбрали 72 ученика, среди которых 25 учеников назвали еще «Простоквашино», 13 - «Умка», а 15 учеников написали все три мультфильма. Мультфильм «Простоквашино» назвали 84 ребенка, среди которых 36 выбрали по два мультфильма.`,
    type: StepType.message,
    nextStep: 'TEST_LOGIC_MAIN_2024_Q10_1',
  },

  TEST_LOGIC_MAIN_2024_Q10_1: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q10_1',
    text: `Сколько человек выбрали мультфильм «Умка»?`,
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_2024_Q10_1',
    nextStep: 'TEST_LOGIC_MAIN_2024_Q10_2',
  },

  TEST_LOGIC_MAIN_2024_Q10_2: {
    person,
    id: 'TEST_LOGIC_MAIN_2024_Q10_2',
    text: `Сколько человек выбрали всего один мультфильм в качестве любимого?`,
    type: StepType.text_question,
    variable: 'TEST_LOGIC_MAIN_2024_Q10_2',
    nextStep: 'Q12',
  },
};
