import React from 'react';

import { Box } from '../../../common/shared/ui/Box';
import { Text } from '../../../common/shared/ui/Text';

import { Icon } from '../../../common/shared/ui/Icon';
import { Button } from '../../../common/shared/ui/Button';

import {
  ErrorCloud1,
  ErrorCloud2,
  ErrorCloud3,
  ErrorTextStyled,
  LoginDeniedErrorTextStyled,
  ErrorLogoStyled,
  ErrorTowerStyled,
  ErrorBlockStyled,
  ErrorBottomStyled,
  ErrorBottomRelativeStyled,
} from './ErrorPageStyled';

import { useHistory } from 'react-router';
import { routes } from '../../../common/shared/constants/routes';
import { ErrorPageProps } from './ErrorPageTypes';
import { TemplatedText } from '../../components/TemplatedText';
import { Page } from '../../../common/pages/Page';
import { useDispatch } from 'react-redux';
import userSlice from '../../redux/user';

const SpriteCloudL = '/onboarding_sprite_cloud1.svg';
const SpriteCloudR = '/onboarding_sprite_cloud2.svg';
const PicStep3 = '/onboarding_tower.svg';

export const ErrorPage = (props: ErrorPageProps) => {
  const history = useHistory();
  const { logout } = userSlice.actions;
  const dispatch = useDispatch();

  const {
    isErrorBoundary,
    title = '404 Упс...',
    description = 'Упс. Похоже, что такой страницы нет',
    btnText = 'Вернуться на главную',
    // eslint-disable-next-line no-sequences
    onClick = () => (dispatch(logout()), history.push(routes.login)),
    isLoginDeniedPage = false,
  } = props;

  let reasonText = null;
  if (isLoginDeniedPage) {
    const queryParams = new URLSearchParams(window.location.search.substring(1));
    const reason = parseInt(queryParams.get('reason') as string);
    if (reason === 2) {
      reasonText = `Дорогой друг, к сожалению, игра уже закончилась. 
      Следующая игра начнется в конце марта 2024 года.          
      До скорой встречи в новом сезоне!`;
    }
    if (reason === 3) {
      reasonText = `Попробуйте позже`;
    }
  }

  return (
    <Page>
      <Box
        $maxW={isErrorBoundary ? '500px' : '460px'}
        $justify='center'
        $textAlign='center'
        $isCenter>
        <ErrorBlockStyled>
          <ErrorLogoStyled />
          <h1>
            {isLoginDeniedPage ? (
              <LoginDeniedErrorTextStyled $textStyle='h2'>
                {reasonText !== null ? reasonText : title}
              </LoginDeniedErrorTextStyled> // кастомные стили спец для стр login-denied
            ) : (
              <ErrorTextStyled $textStyle='h2'>{title}</ErrorTextStyled>
            )}
          </h1>
          <Text displayAs='div' $textStyle='p18' $mB='30px' $pL='15px' $pR='15px'>
            <TemplatedText>{description}</TemplatedText>
          </Text>
          {!props.hideButton && (
            <Button $noborder $textStyle='p18' $fontWeight='500' onClick={onClick}>
              <Box $justify='space-between' $align='center'>
                {btnText}
                <Icon icon='ico_chevron' />
              </Box>
            </Button>
          )}
        </ErrorBlockStyled>

        <ErrorBottomStyled>
          <ErrorBottomRelativeStyled>
            <ErrorCloud1 src={SpriteCloudL} />
            <ErrorCloud2 src={SpriteCloudL} />
            <ErrorCloud3 src={SpriteCloudR} />
            <ErrorTowerStyled src={PicStep3} />
          </ErrorBottomRelativeStyled>
        </ErrorBottomStyled>
      </Box>
    </Page>
  );
};
