/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  Step,
  StepType,
  SystemStepType,
  TestStep,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';
import { images } from '../../tests/images';

const TEST_TVOI_VYBOR: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'TEST_TVOI_VYBOR',
  name: 'Твой выбор остаток',
  nextStep: 'Q3',
  order: [
    {
      description:
        'Ты можешь выбрать несколько вариантов из предложенных. Не один, а минимум два нужно отнести к «Точно да» и два к «Определенно нет».',
      subtest: 'T2',
      question: 'Q1',
    },
    {
      description:
        'Ты можешь выбрать несколько вариантов из предложенных. Не один, а минимум два нужно отнести к «Точно да» и два к «Определенно нет».',
      subtest: 'T4',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person: Interlocutors.SYSTEM,
  type: StepType.test,
};

const LOGIC: Record<string, Step> = {
  LOGIC_TEST: {
    person: Interlocutors.PETROV,
    nextStep: 'LOGIC_TEST_PART1_Q5',
    id: 'LOGIC_TEST',
    type: StepType.system,
    _meta: {
      type: SystemStepType.limitedTimeGroup,
      time: 2 * 60,
      timeoutStep: 'LOGIC_TEST_TIMEOUT',
      exitStep: 'Q20',
    },
  },

  LOGIC_TEST_PART1_Q5: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5',
    text: 'Расположи числа 4, 2, 6, 1 на месте фигур (квадрата, звездочки, треугольника и круга) так, чтобы стрелки шли от большего числа к меньшему:',
    image: { uri: images.logic_fix, title: '' },
    type: StepType.message,
    nextStep: 'LOGIC_TEST_PART1_Q5_1',
  },
  LOGIC_TEST_PART1_Q5_1: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5_1',
    text: 'Что на месте квадрата?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_1',
    nextStep: 'LOGIC_TEST_PART1_Q5_2',
  },
  LOGIC_TEST_PART1_Q5_2: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5_2',
    text: 'На месте звездочки?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_2',
    nextStep: 'LOGIC_TEST_PART1_Q5_3',
  },
  LOGIC_TEST_PART1_Q5_3: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5_3',
    text: 'На месте треугольника?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_3',
    nextStep: 'LOGIC_TEST_PART1_Q5_4',
  },
  LOGIC_TEST_PART1_Q5_4: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_PART1_Q5_4',
    text: 'На месте круга?',
    type: StepType.text_question,
    variable: 'LOGIC_TEST_PART1_Q5_4',
    nextStep: 'Q20',
  },
  LOGIC_TEST_TIMEOUT: {
    person: Interlocutors.PETROV,
    id: 'LOGIC_TEST_TIMEOUT',
    text: '{{NAME}}, увы, время закончилось 🙁',
    type: StepType.message,
    nextStep: 'Q21',
  },
};

const EXIT_ID = 'EXIT_ID';

export const scenario_3_1_i: Scenario = {
  scenarioId: SceneId.scenario_3_1_i,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Иннополис',
    text: `Взяв с собой ничего не понимающую Софью, вы отправляетесь в Иннополис: вам нужно срочно передать накопитель с «Интеллектом 2.0» Гарину.`,
    buttons: ['Отправиться к КВА'],
  },
};

export const scenario_3_1: Scenario = {
  scenarioId: SceneId.scenario_3_1,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Иннополис',
    scene_id: 'scenario_3_1',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.SYSTEM,
        id: 'Q1',
        type: StepType.question,
        maxAnswers: 1,
        text: `Вы приближаетесь к гигантской жабе, Софья вскрикивает и прячется за Вжухом. 
        Голограмма Гарина радостно машет вам. 
        {{NAME}}, вы с Вжухом справились, молодцы. Давайте сюда накопитель.
        `,
        buttons: [
          {
            id: 'b1',
            text: 'Отдать накопитель Гарину',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        person: Interlocutors.SYSTEM,
        id: 'Q2',
        type: StepType.question,
        maxAnswers: 1,
        text: `Ты достаёшь накопитель и жаба мгновенно слизывает его своим длинным языком с твоей руки. 
        Глаза жабы начинают вращаться — она будто гипнотизирует вас. 
        
        «Не бойтесь, всё в порядке» — слышится голос Гарина. «Процесс стирания «Интеллекта 2.0» запущен, я запущу бэкап версию парка «Большая перемена». 
        
        Но пока жители не пришли в себя и не испугались, вам нужно всех найти и вернуть их туда, где они потерялись. Иначе будет хаос. 
        
        Кстати, ты так много взаимодействовал с «Интеллектом 2.0», что это могло тебя повредить, давай-ка проверим твою память и знания о себе.
        `,
        buttons: [
          {
            id: 'b1',
            text: 'Ответить на вопрос Гарина',
            nextStep: 'TEST_TVOI_VYBOR',
          },
        ],
      },

      TEST_TVOI_VYBOR: TEST_TVOI_VYBOR, // твой выбор джуниор  остаток

      Q3: {
        person: Interlocutors.SYSTEM,
        id: 'Q3',
        type: StepType.question,
        maxAnswers: 1,
        text: `Ну, вроде всё в порядке. Так что, поможешь жителям парка?`,
        buttons: [
          {
            id: 'b1',
            text: 'Согласиться',
            nextStep: 'Q4',
          },
        ],
      },
      Q4: {
        person: Interlocutors.SYSTEM,
        id: 'Q4',
        type: StepType.question,
        maxAnswers: 1,
        text: `«Я в тебе не сомневался. Начните с Софьи — доставьте её в Эрмитаж, кажется, её там кто-то ждёт», — говорит Гарин и остаётся разбираться с жабой.`,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться в Эрмитаж',
            nextStep: EXIT_ID,
          },
        ],
      },

      //       ...splitStep({
      //         person: Interlocutors.PETROV,
      //         id: 'Q19',
      //         text: `Тогда поехали!

      // У тебя будет ровно 2 минуты на решение. Нужно будет дать 4 ответа, вписывай их по очереди — по одному в поле ввода и затем жми «Отправить ответ».`,
      //         type: StepType.question,
      //         maxAnswers: 1,
      //         buttons: [
      //           {
      //             id: 'b1',
      //             text: 'Хорошо',
      //             nextStep: 'LOGIC_TEST',
      //           },
      //         ],
      //       }),

      //       ...LOGIC,

      [EXIT_ID]: {
        person: Interlocutors.SYSTEM,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
