/* eslint-disable max-len */
import {
  Action,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import { splitStep } from 'src/sixthGrade/utils/ScenarioUtils';
import mentorMobile from '../../../../../assets/images/mentor_mobile.jpg';
import mentorDesktop from '../../../../../assets/images/mentor_desktop.jpg';

import { TVOI_KRUGOZOR_G2 } from '../tvoi_krugozor_g2';
import { TVOI_KRUGOZOR_T2 } from '../tvoi_krugozor_t2';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg2_moscowCity_1_1: Scenario = {
  scenarioId: SceneId.stg2_moscowCity_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    timeoutMessage: 'К сожалению, время для ответа истекло 🙁',
    title: 'Москва сити',
    scene_id: 'stg2_moscowCity_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.ENTREPRENEUR,
        text: `Привет, ребята! Сколько зим!

        Как у вас дела? У нас вот в делах страшный бардак.`,
        id: 'Q1',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Привет, Сан Саныч! Нам, на самом деле, нужна помощь, но и готовы помочь вам.',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        text: `Спасибо, это очень ценно. Но у нас тут дела сложные, что у вас?`,
        id: 'Q2',
        person: Interlocutors.ENTREPRENEUR,
        type: StepType.message,
        nextStep: 'Q3',
      },
      Q3: {
        text: `Мы готовимся к викторине и нам нужно вспомнить то, что ты рассказывал про Москва-Сити.`,
        id: 'Q3',
        person: Interlocutors.CAT,
        type: StepType.question,
        nextStep: 'Q4',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, и ещё, помните нашего друга, маленького, белого, пушистого? Не видели его в последнее время?',
            nextStep: 'Q4',
          },
        ],
      },

      Q4: {
        id: 'Q4',
        person: Interlocutors.ENTREPRENEUR,
        text: `Зверька помню, да. Но не видел его с тех пор. Да и времени нет у меня обращать внимание на такие мелочи.

        А насчёт рассказа про Сити — я недавно как раз всё пересказал по телевизору. Вот-вот запись будет транслироваться на экран на том небоскрёбе.
        
        Посмотрите пока, а я отлучусь по делам.`,
        type: StepType.question,
        nextStep: 'Q5',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо, спасибо! Кстати, а где все люди? Рабочий день же.',
            nextStep: 'Q5',
          },
        ],
      },

      Q5: {
        text: `С нашим временем задом-наперёд всё запуталось. Смотрите, во всём остальном парке полдень, а на наших часах сейчас полночь! Получается, я работаю и по ночам тоже.

        Ладно, смотрите репортаж.`,
        id: 'Q5',
        person: Interlocutors.ENTREPRENEUR,
        type: StepType.question,
        nextStep: 'Q6',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Эх, тяжело же вам…',
            nextStep: 'Q6',
          },
        ],
      },

      Q6: {
        text: `Экран на одной из башен загорается. Начинается трансляция вечерних новостей. Выходит Сан Саныч и начинает рассказ.`,
        id: 'Q6',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q7',
      },
      Q7: {
        text: `«…Москва-Сити — это развивающийся деловой район Москвы. Он состоит из башен, всего их 20 и шесть еще строятся. Высота самой высокой из них составляет 374 метра, это целых 97 этажей! 

        Я перевожу сюда свой офис, чтобы быть в самой гуще предпринимательских событий.
        
        Но это еще не все! Москва-Сити — это пространство, в котором можно заниматься бизнесом, жить и интересно проводить время. Например, здесь есть музей небоскребов и высотного строительства. Он расположен на 56 этаже и оттуда открывается совершенно потрясающий вид!...»`,
        id: 'Q7',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q8',
      },
      Q8: {
        person: Interlocutors.ENTREPRENEUR,
        text: `Ну как, понравилось интервью?

        Извините, друзья, что я с вами без должного внимания. Сейчас запускаем новое предприятие и с ним возникли большие сложности.`,
        id: 'Q8',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ничего страшного! А какие сложности?',
            nextStep: 'Q9',
          },
        ],
        nextStep: 'Q9',
      },

      Q9: {
        person: Interlocutors.ENTREPRENEUR,
        text: `Организовываем стекольное производство. Но я не могу всем друзьям рассказывать деловые секреты.`,
        id: 'Q9',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'На нас со Вжухом можно положиться! К тому же, мы правда хотим помочь',
            nextStep: 'Q10',
          },
        ],
        nextStep: 'Q10',
      },

      Q10: {
        person: Interlocutors.ENTREPRENEUR,
        text: `Хорошо, я могу вас привлечь, как партнёров, но мне нужно, чтобы вы доказали, что достаточно компетентны. Это очень важно! Готовы пройти тест на эрудицию? Всего будет 20 вопросов, разделенных на 2 части.`,
        id: 'Q10',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Было бы здорово! Я [[готов/готова]]!',
            nextStep: 'Q10_1',
          },
        ],
      },

      Q10_1: {
        person: Interlocutors.SYSTEM,
        text: `Данный тест является тренировочным и не приносит баллов на конкурсе!`,
        id: 'Q10_1',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Понятно',
            nextStep: 'Q11',
          },
        ],
      },

      Q11: {
        person: Interlocutors.SYSTEM,
        text: `Это первая часть теста. Предлагаем тебе пройти тест на эрудицию в гуманитарной сфере. Он оценит уровень твоей любознательности и широту кругозора и займет не более 10 минут.
        
        Тебе будет предъявлено 10 заданий, в каждом из которых по 4 варианта ответа, и только один из них верный.Время на каждый вопрос ограничено (60 секунд). Обращай внимание на счетчики времени на экране! 
        
        Не рекомендуем долго думать над вариантами, лучше ориентироваться на свои знания и предположения и давать оперативные ответы. И старайся не пользоваться никакими справочниками и дополнительными ресурсами, ведь тест оценивает именно твой уровень эрудиции.
        
        Также имей в виду, что ты можешь вернуться назад только на один вопрос, чтобы исправить свой ответ.
        А теперь вперед, и желаем успехов! 
        Начинаем?`,
        id: 'Q11',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            action: Action.LostPick,
            id: 'b1',
            text: 'Да, давай начнем!',
            nextStep: 'TVOI_KRUGOZOR_G2_STEP',
          },
        ],
        nextStep: 'TVOI_KRUGOZOR_G2_STEP',
      },

      // Первая часть теста Твой кругозор 2\1
      ...TVOI_KRUGOZOR_G2,

      Q12: {
        person: Interlocutors.SYSTEM,
        id: 'Q12',
        text: `А теперь приступаем ко второй части нашего теста. 
        
        Предлагаем тебе пройти тест на эрудицию в технической сфере. Он оценит уровень твоей любознательности и широту кругозора и займет не более 10 минут. 
        
        Тебе будет предъявлено 10 заданий, в каждом из которых по 4 варианта ответа, и только один из них верный.
        
        Время на каждый вопрос ограничено (60 секунд). Обращай внимание на счетчики времени на экране! 
        
        Не рекомендуем долго думать над вариантами, лучше ориентироваться на свои знания и предположения и давать оперативные ответы. И старайся не пользоваться никакими справочниками и дополнительными ресурсами, ведь тест оценивает именно твой уровень эрудиции.
        
        Также имей в виду, что ты можешь вернуться назад только на один вопрос, чтобы исправить свой ответ.
        
        А теперь вперед, и желаем успехов!
        Начинаем?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, давай!',
            nextStep: 'TVOI_KRUGOZOR_T2_STEP',
          },
        ],
      },

      // Вторая часть теста Твой кругозор 2\2
      ...TVOI_KRUGOZOR_T2,

      Q13: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q13',
        text: `Так вот, у меня есть предприятие по изготовлению стеклянных бутылок. Я хотел продавать их производителю лимонадов из Москвы, но в последний момент они передумали и перешли на алюминиевые банки.

        Дескать, банки не бьются и меньше весят! Но бутылки-то мы уже производим и необходимо с ними что-то делать.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хм… А что если вам самим еще и лимонад делать? ',
            nextStep: 'Q14',
          },
        ],
      },

      Q14: {
        text: `Это отличная мысль, но второй завод строить я не могу себе позволить.`,
        id: 'Q14',
        person: Interlocutors.ENTREPRENEUR,
        type: StepType.question,
        nextStep: 'Q15',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ох, действительно, что же делать?',
            nextStep: 'Q15',
          },
        ],
      },

      Q15: {
        text: `А я всё придумал! Сан Саныч, мы найдём тебе целое море, то есть озеро, лимонада.`,
        id: 'Q15',
        person: Interlocutors.CAT,
        type: StepType.question,
        nextStep: 'Q16',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Вжух, я, кажется, понял, о чём ты. Но мы не можем просто брать эту сладкую воду из Байкала. Бабуля будет против, она же заботится о рыбе в озере!',
            nextStep: 'Q16',
          },
        ],
      },

      Q16: {
        text: `Точно! Я слышал, что вода в Байкале превратилась в лимонад. Вжух, молодец!

        А с Бабулей мы ведь сможем договориться. Помочь сохранению рыб как-нибудь. Есть идеи?`,
        id: 'Q16',
        person: Interlocutors.ENTREPRENEUR,
        type: StepType.question,
        nextStep: 'Q17',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Сан Саныч, а ваше предприятие может сделать аквариумы?',
            nextStep: 'Q17',
          },
        ],
      },

      Q17: {
        text: `Не без труда, но, думаю, получится. А что?`,
        id: 'Q17',
        person: Interlocutors.ENTREPRENEUR,
        type: StepType.question,
        nextStep: 'Q18',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Бабуля упоминала, что рыб нужно поместить в аквариумы поскорее. Может, вы поможете?',
            nextStep: 'Q18',
          },
        ],
      },

      Q18: {
        text: `Конечно! Я сейчас же начну изучать технологию, а вы, пожалуйста, расскажите Бабуле о нашей идее. Идёт, партнёры?`,
        id: 'Q18',
        person: Interlocutors.ENTREPRENEUR,
        type: StepType.question,
        nextStep: EXIT_ID,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Идёт, Сан Саныч. Мы мигом!',
            nextStep: 'Q19/0',
          },
        ],
      },
      ...splitStep({
        person: Interlocutors.TEACHER,
        delay: 5000,
        id: 'Q19',
        text: `{{NAME}}, наступил момент напомнить тебе, что ты можешь пригласить реального помощника. Им может стать педагог-наставник. Он сможет, например, подсказать, где искать нужную для выполнения заданий информацию.
        
        Если ты уже пригласил наставника, то можешь смело пролистывать это сообщение дальше.
        
        В педагоги-наставники подойдет учитель, тренер, инструктор или другой взрослый, обучающий детей. Подробнее информацию о наставнике ты можешь посмотреть в <a target="_blank" href="https://bolshayaperemena.online/api/link/short/4beffe5b9d">Положении о конкурсе</a>.
        
        Хорошо подумай, кто это может быть. Ведь после выбора наставника ты не сможешь его поменять. На выбор у тебя есть время, поэтому не торопись. Присоединить наставника в <a target="_blank" href="https://bolshayaperemena.online">личном кабинете</a> ты можешь до 13.06.2024г.
        
        Если ты точно знаешь, кого хочешь видеть своим наставником, предложи ему поучаствовать вместе с тобой. Для этого тебе нужно отправить приглашение педагогу из своего <a target="_blank" href="https://bolshayaperemena.online">личного кабинета</a> на платформе “Большая перемена” стать наставником:`,
        type: StepType.message,
        nextStep: 'Q20',
      }),
      Q20: {
        person: Interlocutors.TEACHER,
        id: 'Q20',
        type: StepType.message,
        text: ``,
        image: { uri: mentorMobile, title: '' },
        nextStep: 'Q21',
      },
      Q21: {
        person: Interlocutors.TEACHER,
        id: 'Q21',
        type: StepType.message,
        text: `Если педагог согласен, то должен зарегистрироваться на сайте и сообщить тебе свой id из личного кабинета на платформе “Большая перемена”. В своем личном кабинете он может либо принять, либо отклонить твое приглашение:`,
        nextStep: 'Q22',
      },
      Q22: {
        person: Interlocutors.TEACHER,
        id: 'Q22',
        type: StepType.message,
        text: ``,
        image: { uri: mentorDesktop, title: '' },
        nextStep: 'Q23',
      },
      Q23: {
        person: Interlocutors.TEACHER,
        id: 'Q23',
        type: StepType.question,
        text: `Важно: педагог будет считаться наставником только в том случае, если примет твое приглашение в своем личном кабинете.`,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ок, я [[понял/поняла]]',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
