import React from 'react';

export const IconProfile: React.FC = () => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      /* eslint-disable-next-line max-len */
      d='M9 18C4.02943 18 0 13.9705 0 9C0 4.02943 4.02943 0 9 0C13.9705 0 18 4.02943 18 9C18 13.9705 13.9705 18 9 18ZM8.9971 1.79917C5.02065 1.79917 1.7971 5.02272 1.7971 8.99917C1.7971 11.0595 2.66251 12.9177 4.04964 14.2302C4.77999 12.7317 6.31798 11.6992 8.0971 11.6992H10.3471C12.0049 11.6992 13.4301 12.6953 14.0568 14.1216C15.3782 12.8163 16.1971 11.0034 16.1971 8.99917C16.1971 5.02272 12.9736 1.79917 8.9971 1.79917ZM12.5441 15.2666C12.3229 14.2563 11.4228 13.5 10.3461 13.5H8.09612C6.91327 13.5 5.90808 14.2606 5.54297 15.3194C6.56815 15.8809 7.74485 16.2 8.99612 16.2C10.2861 16.2 11.4969 15.8608 12.5441 15.2666ZM8.99453 10.8003C7.00631 10.8003 5.39453 9.18853 5.39453 7.20034C5.39453 5.21212 7.00631 3.60034 8.99453 3.60034C10.9827 3.60034 12.5945 5.21212 12.5945 7.20034C12.5945 9.18853 10.9827 10.8003 8.99453 10.8003ZM8.99876 5.39985C8.00462 5.39985 7.19876 6.20573 7.19876 7.19985C7.19876 8.19399 8.00462 8.99985 8.99876 8.99985C9.9929 8.99985 10.7988 8.19399 10.7988 7.19985C10.7988 6.20573 9.9929 5.39985 8.99876 5.39985Z'
      fill='currentColor'
    />
  </svg>
);
