/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const innopolice_1_1_0: Scenario = {
  scenarioId: SceneId.innopolice_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.CAT,
    title: 'Иннополис',
    text: `Удивительные вещи! 

    Но Петров точно нам поможет со всем разобраться — он очень умный, даже умнее меня 😸
    
    Он обычно всегда работает в Иннополисе — туда и отправимся`,
    buttons: ['Продолжить'],
  },
};

export const innopolice_1_1_1: Scenario = {
  scenarioId: SceneId.innopolice_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Иннополис',
    text: `Перед вами точная копия Иннополиса — города высоких технологий, расположенного в Республике Татарстан.

    Подойдя ближе, вы замечаете, что в зданиях не горит свет. В городе, будто бы, никого нет.`,
    buttons: ['Продолжить'],
  },
};
