import { MapSourceStage } from '../MapSource/MapSourceTypes';
import { PointsOfInterest } from '../../types';

interface PointConfigValue {
  $top: number;
  $left: number;
}

const POINTS_CONFIG: Record<PointsOfInterest, Record<MapSourceStage, PointConfigValue>> = {
  [PointsOfInterest.mgu]: {
    stage1: {
      // $top: 29,
      // $left: 36.5,
      $top: 28.5,
      $left: 15.25,
    },
    stage2: {
      $top: 28.5,
      $left: 15.25,
    },
    stage2act2: {
      $top: 28.5,
      $left: 15.25,
    },
    stage2act3: {
      $top: 28.5,
      $left: 15.25,
    },
    stage3act1: {
      $top: 28.5,
      $left: 15.25,
    },
    stage3act2: {
      $top: 28.5,
      $left: 15.25,
    },
  },
  [PointsOfInterest.hersones]: {
    stage1: {
      // $left: -100,
      // $top: -100,
      $top: 28,
      $left: 79.24,
    },
    stage2: {
      $left: -100,
      $top: -100,
    },
    stage2act2: {
      $left: -100,
      $top: -100,
    },
    stage2act3: {
      $top: -100,
      $left: -100,
    },
    stage3act1: {
      $top: 28,
      $left: 79.24,
    },
    stage3act2: {
      $top: 28,
      $left: 79.24,
    },
  },
  [PointsOfInterest.lake]: {
    stage1: {
      // $top: 26,
      // $left: 92,
      $top: 25,
      $left: 37.75,
    },
    stage2: {
      $top: 25,
      $left: 37.75,
    },
    stage2act2: {
      $top: 25,
      $left: 37.75,
    },
    stage2act3: {
      $top: 25,
      $left: 37.75,
    },
    stage3act1: {
      $top: 25,
      $left: 37.75,
    },
    stage3act2: {
      $top: 25,
      $left: 37.75,
    },
  },
  [PointsOfInterest.sochi]: {
    stage1: {
      // $top: 75,
      // $left: 79.4,
      $top: 75,
      $left: 32.65,
    },
    stage2: {
      $top: 75,
      $left: 32.65,
    },
    stage2act2: {
      $top: 75,
      $left: 32.65,
    },
    stage2act3: {
      $top: 75,
      $left: 32.65,
    },
    stage3act1: {
      $top: 75,
      $left: 32.65,
    },
    stage3act2: {
      $top: 75,
      $left: 32.65,
    },
  },
  [PointsOfInterest.innopolice]: {
    stage1: {
      $top: 78.5,
      $left: 50.5,
    },
    stage2: {
      $top: 78.5,
      $left: 50.5,
    },
    stage2act2: {
      $top: 78.5,
      $left: 50.5,
    },
    stage2act3: {
      $top: 78.5,
      $left: 50.5,
    },
    stage3act1: {
      $top: 78.5,
      $left: 50.5,
    },
    stage3act2: {
      $top: 78.5,
      $left: 50.5,
    },
  },
  innopoliceBush: {
    stage1: {
      $left: -100,
      $top: -100,
    },
    stage2: {
      $top: -100,
      $left: -100,
    },
    stage2act2: {
      $top: 74,
      $left: 53.5,
    },
    stage2act3: {
      $top: 74,
      $left: 53.5,
    },
    stage3act1: {
      $top: 74,
      $left: 53.5,
    },
    stage3act2: {
      $top: 74,
      $left: 53.5,
    },
  },
  bridge: {
    stage1: {
      $top: -100,
      $left: -100,
    },
    stage2: {
      $top: 48,
      $left: 68,
    },
    stage2act2: {
      $top: 48,
      $left: 68,
    },
    stage2act3: {
      $top: 48,
      $left: 68,
    },
    stage3act1: {
      $top: 48,
      $left: 68,
    },
    stage3act2: {
      $top: 48,
      $left: 68,
    },
  },
  moscowCity: {
    stage1: {
      $top: 35,
      $left: 91,
    },
    stage2: {
      $top: -100,
      $left: -100,
    },
    stage2act2: {
      $top: 35,
      $left: 91,
    },
    stage2act3: {
      $top: 35,
      $left: 91,
    },
    stage3act1: {
      $top: 35,
      $left: 91,
    },
    stage3act2: {
      $top: 35,
      $left: 91,
    },
  },
  train: {
    stage1: {
      $top: -100,
      $left: -100,
    },
    stage2: {
      $top: -100,
      $left: -100,
    },
    stage2act2: {
      $top: 84,
      $left: 91.5,
    },
    stage2act3: {
      $top: 84,
      $left: 91.5,
    },
    stage3act1: {
      $top: 84,
      $left: 91.5,
    },
    stage3act2: {
      $top: 84,
      $left: 91.5,
    },
  },
  train2: {
    stage1: {
      $top: -100,
      $left: -100,
    },
    stage2: {
      $top: -100,
      $left: -100,
    },
    stage2act2: {
      $top: 84,
      $left: 91.5,
    },
    stage2act3: {
      $top: 84,
      $left: 91.5,
    },
    stage3act1: {
      $top: 72,
      $left: 95,
    },
    stage3act2: {
      $top: 72,
      $left: 95,
    },
  },
  kurgan: {
    stage1: {
      $top: -100,
      $left: -100,
    },
    stage2: {
      $top: 80,
      $left: 73,
    },
    stage2act2: {
      $top: -100,
      $left: -100,
    },
    stage2act3: {
      $top: 80,
      $left: 73,
    },
    stage3act1: {
      $top: 80,
      $left: 73,
    },
    stage3act2: {
      $top: 80,
      $left: 73,
    },
  },
  [PointsOfInterest.patriot]: {
    stage1: {
      $top: 71,
      $left: 87.75,
    },
    stage2: {
      $left: -100,
      $top: -100,
    },
    stage2act2: {
      $left: -100,
      $top: -100,
    },
    stage2act3: {
      $top: -100,
      $left: -100,
    },
    stage3act1: {
      $top: 70,
      $left: 85,
    },
    stage3act2: {
      $top: 71,
      $left: 87.75,
    },
  },
  [PointsOfInterest.arch]: {
    stage1: {
      $top: -100,
      $left: -100,
    },
    stage2: {
      $top: 25.15,
      $left: 2.45,
    },
    stage2act2: {
      $top: -100,
      $left: -100,
    },
    stage2act3: {
      $top: -100,
      $left: -100,
    },
    stage3act1: {
      $top: -100,
      $left: -100,
    },
    stage3act2: {
      $top: 25.15,
      $left: 2.45,
    },
  },
  [PointsOfInterest.space]: {
    stage1: {
      // $top: 80,
      // $left: 38.5,
      $top: 80.75,
      $left: 16.5,
    },
    stage2: {
      $top: 80.75,
      $left: 16.5,
    },
    stage2act2: {
      $top: 80.75,
      $left: 16.5,
    },
    stage2act3: {
      $top: 80.75,
      $left: 16.5,
    },
    stage3act1: {
      $top: 80.75,
      $left: 16.5,
    },
    stage3act2: {
      $top: 80.75,
      $left: 16.5,
    },
  },
  [PointsOfInterest.ostankino]: {
    stage1: {
      // $top: 82.5,
      // $left: 13,
      $top: 82,
      $left: 5.75,
    },
    stage2: {
      $top: 82,
      $left: 5.75,
    },
    stage2act2: {
      $top: 82,
      $left: 5.75,
    },
    stage2act3: {
      $top: 82,
      $left: 5.75,
    },
    stage3act1: {
      $top: 82,
      $left: 5.75,
    },
    stage3act2: {
      $top: 82,
      $left: 5.75,
    },
  },
  [PointsOfInterest.hersonesSteps]: {
    stage1: {
      // $left: -100,
      // $top: -100,
      $top: 32,
      $left: 78,
    },
    stage2: {
      $left: -100,
      $top: -100,
    },
    stage2act2: {
      $left: -100,
      $top: -100,
    },
    stage2act3: {
      $top: -100,
      $left: -100,
    },
    stage3act1: {
      $top: 32,
      $left: 78,
    },
    stage3act2: {
      $top: 32,
      $left: 78,
    },
  },
  [PointsOfInterest.hermitageBush]: {
    stage1: {
      $left: -100,
      $top: -100,
    },
    stage2: {
      $left: -100,
      $top: -100,
    },
    stage2act2: {
      $left: -100,
      $top: -100,
    },
    stage2act3: {
      $top: 17,
      $left: 59.5,
    },
    stage3act1: {
      $top: 17,
      $left: 59.5,
    },
    stage3act2: {
      $top: 17,
      $left: 59.5,
    },
  },
  [PointsOfInterest.mguBush]: {
    stage1: {
      $left: -100,
      $top: -100,
    },
    stage2: {
      $left: -100,
      $top: -100,
    },
    stage2act2: {
      $left: -100,
      $top: -100,
    },
    stage2act3: {
      $top: 26,
      $left: 22,
    },
    stage3act1: {
      $top: 26,
      $left: 22,
    },
    stage3act2: {
      $top: 26,
      $left: 22,
    },
  },
  [PointsOfInterest.ostankinoBush]: {
    stage1: {
      $left: -100,
      $top: -100,
    },
    stage2: {
      $left: -100,
      $top: -100,
    },
    stage2act2: {
      $left: -100,
      $top: -100,
    },
    stage2act3: {
      $top: 67,
      $left: 6.5,
    },
    stage3act1: {
      $top: 67,
      $left: 6.5,
    },
    stage3act2: {
      $top: 67,
      $left: 6.5,
    },
  },
  [PointsOfInterest.lakeBush]: {
    stage1: {
      // $top: 26,
      // $left: 92,
      $top: 25,
      $left: 37.75,
    },
    stage2: {
      $top: 25,
      $left: 37.75,
    },
    stage2act2: {
      $top: 25,
      $left: 37.75,
    },
    stage2act3: {
      $top: 31.5,
      $left: 36.75,
    },
    stage3act1: {
      $top: 31.5,
      $left: 36.75,
    },
    stage3act2: {
      $top: 31.5,
      $left: 36.75,
    },
  },
  [PointsOfInterest.hermitage]: {
    stage1: {
      $top: 30,
      $left: 56.2,
    },
    stage2: {
      $top: 30,
      $left: 56.2,
    },
    stage2act2: {
      $top: 30,
      $left: 56.2,
    },
    stage2act3: {
      $top: 30,
      $left: 56.2,
    },
    stage3act1: {
      $top: 30,
      $left: 56.2,
    },
    stage3act2: {
      $top: 30,
      $left: 56.2,
    },
  },
};

export default POINTS_CONFIG;
