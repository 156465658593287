/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg3_space_1_1: Scenario = {
  scenarioId: SceneId.stg3_space_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Космодром',
    scene_id: 'stg3_space_1_1',
    startId: 'Q0',
    steps: {
      Q0: {
        text: `Я часто смотрел на планеты через свой квантовый телескоп. И ваша, Земля, показалась мне такой красивой, что я смотрел на неё каждый день и мечтал попасть к вам на экскурсию. 

        Потом однажды я воспользовался нашим лучом телепортации, чтобы перенестись на Землю. Прицелился лучом в большую прямоугольную площадку, покрытую травой, и так попал на Землю.`,
        id: 'Q0',
        person: Interlocutors.ALIEN,
        type: StepType.message,
        nextStep: 'Q0_1',
      },

      Q0_1: {
        text: `Вы подходите к макету космодрома «Восточный» в натуральную величину. Даже удивительно как он поместился в этом парке.

        На платформе стоит величественная ракета, но рядом не видно ни души.
        
        Подойдя ближе, вы чувствуете, как сильный ветер дует вам в лицо. Ветер с каждым шагом усиливается. Когда Вжуха уже начинает сдувать, вы берёте его на руки.`,
        id: 'Q0_1',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q1',
      },
      Q1: {
        text: `Да тут целый ураган! Сколько же можно трудностей? Как бы у нас от всей этой инопланетной мистики крыши не поехали!`,
        id: 'Q1',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Вжух, держись! Я тебя держу 😅',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        text: `А вам известно, что «Поехали!» — слово, которое произнес первый космонавт во время старта первого пилотируемого космического корабля? 👨‍🚀`,
        id: 'Q2',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ух ты! Квилл, ты знаешь про космонавтов?',
            nextStep: 'Q3',
          },
        ],
      },

      Q3: {
        text: `Да, мы в школе изучали историю покорения космоса разными видами, в том числе и людьми.`,
        id: 'Q3',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Здорово, а расскажи ещё, пожалуйста!',
            nextStep: 'Q4',
          },
        ],
      },

      Q4: {
        text: `Этим первым космонавтом был ваш соотечественник Юрий Гагарин и произошло это 12 апреля 1961 года по земному календарю, то есть целых 63 года назад.

        Юрий Гагарин на космическом корабле «Восток-1» стартовал с космодрома Байконур. Мы сейчас находимся на похожем космодроме. Гагарин впервые в мире совершил орбитальный облет планеты Земля. Время его нахождения в космосе составило почти 2 часа 🚀`,
        id: 'Q4',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Как интересно! Скоро и ты так же полетишь на ракете!',
            nextStep: 'Q5',
          },
        ],
      },

      Q5: {
        text: `К сожалению, не получится. При таком ветре ракеты старых земных моделей не способны безопасно взлететь.`,
        id: 'Q5',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Как же так?!',
            nextStep: 'Q6',
          },
        ],
      },

      Q6: {
        text: `Квилл, а что же, всё-таки, произошло с нашим парком? Все эти странные превращения — твоих рук дело?`,
        id: 'Q6',
        person: Interlocutors.CAT,
        type: StepType.message,
        nextStep: 'Q7',
      },

      Q7: {
        text: `Э-э… Я не знаю… Что именно произошло с парком я не знаю. Но на нашем корабле есть лучи преобразования материи, которые способны вызвать похожие изменения. `,
        id: 'Q7',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ну то есть твои сородичи могут всё исправить?',
            nextStep: 'Q8',
          },
        ],
      },

      Q8: {
        text: `В теории, этим лучом можно провести обратную трансформацию. Но я не знаю, получится ли. Это не точно и требует уточнения.`,
        id: 'Q8',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да брось, ты вернёшься к ним и просто попросишь всё исправить. Ведь так?',
            nextStep: 'Q9',
          },
        ],
      },

      Q9: {
        text: `Я… Я попробую.`,
        id: 'Q9',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо, спасибо тебе за помощь!',
            nextStep: 'Q10',
          },
        ],
      },

      Q10: {
        text: `Хорошо, теперь идём к Петрову — посмотрим, что там с починкой радиопередатчика. Свяжемся с пришельцами и потребуем всё вернуть как было! Они в конце концов всё сами натворили, пусть и исправляют.`,
        id: 'Q10',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо, идём! Вжух, только не ругайся так на них. Может, они не виноваты? ',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
