/* eslint-disable max-len */
import React, { Fragment, useCallback, useState } from 'react';
import { ChallengeProps } from './ChallengeTypes';
import * as S from './ChallengeStyled';

import colors from '../../../common/shared/constants/colors';
import IconArrowDown from '../../../assets/icons/IconArrowDown';

import { useDispatch } from 'react-redux';
import { setChallengeState } from '../../redux/user/userActions';
import { checkLinkValid } from '../../redux/scenario/scenarioActions';

import { Box } from '../../../common/shared/ui/Box';
import { Text } from '../../../common/shared/ui/Text';
import Link from '../Link';
import { Input } from '../../../common/shared/ui/Input';
import { Button } from '../../../common/shared/ui/Button';
import UploadFiles from '../UploadFiles';
import useViewport from '../../hooks/useViewport';
import { GameModal } from '../GameModal';
import { Interlocutors } from '../../types/scenario';

const GeneralChallenge = (props: ChallengeProps) => {
  const dispatch = useDispatch();
  const [isOpened, setIsOpened] = useState(false);
  const [postLink, setPostLink] = useState('');

  const { isMobile } = useViewport();

  const toggleOpened = useCallback(() => {
    setIsOpened((value) => !value);
  }, []);

  const onSaveDoc = useCallback(
    (urlDoc: string) => {
      return new Promise<boolean>((resolve) => {
        GameModal.open(
          'Система',
          'После нажатия кнопки «Отправить на проверку» ты не сможешь внести изменения. И твои ответы отправятся на проверку. Ты [[уверен/уверена]], что выполнил все задания до конца?',
          Interlocutors.SYSTEM,
          ['Отправить на проверку', 'Отмена'],
          true,
          (btn) => {
            if (btn === 'Отправить на проверку') {
              dispatch(setChallengeState(props.data.value, { urlDoc }));
              resolve(false);
              return;
            }
            resolve(true);
          },
        );
      });
    },
    [props, dispatch],
  );

  const onSavePost = useCallback(() => {
    if (!postLink || !checkLinkValid(postLink))
      return alert('Ссылка не корректна! Проверь правильность ссылки и попробуй сохранить снова!');

    GameModal.open(
      'Система',
      'После нажатия кнопки «Отправить» ты не сможешь внести изменения. И твои ответы отправятся на проверку. Ты [[уверен/уверена]], что выполнил все задания до конца?',
      Interlocutors.SYSTEM,
      ['Отправить', 'Отмена'],
      true,
      (btn) => {
        if (btn === 'Отправить') {
          dispatch(setChallengeState(props.data.value, { urlPost: postLink }));
          return;
        }
      },
    );
  }, [props, postLink, dispatch]);

  return (
    <S.StyledChallenge>
      <S.StyledChallengeTopContainer onClick={toggleOpened}>
        <Box $align='center'>
          {/* <S.StyledChallengeIcon src={props.data.icon} alt='Отправить ссылку' /> */}
          <Text $textStyle='p15'>Доброе дело</Text>

          {props.userData?.urlDoc && props.userData?.urlPost && (
            <svg
              style={{ marginLeft: 12 }}
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M4.49951 12.4545L9.65576 17L19.4995 7'
                stroke='#808191'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          )}
        </Box>
        <S.StyledMore $isOpened={isOpened}>
          {!isMobile && (
            <Text $textStyle='p13' $color={colors.blueButton}>
              {isOpened ? 'Скрыть' : 'Раскрыть'}
            </Text>
          )}
          <div />
          <IconArrowDown />
        </S.StyledMore>
      </S.StyledChallengeTopContainer>
      <S.StyledChallengeBottomContainer $isOpened={isOpened}>
        {props.userData?.urlDoc ? (
          <Fragment>
            <Text $textStyle={'p14'} $fontWeight={'800'} $color={colors.title}>
              Загруженный результат
            </Text>
            <Text $textStyle={'p14'} $color={colors.title}>
              Загруженный вами результат можно посмотреть
              <Link
                displayAs='a'
                $textStyle={'p14'}
                $color={colors.blue}
                to={props.userData?.urlDoc}>
                {' '}
                по ссылке
              </Link>
            </Text>
          </Fragment>
        ) : (
          <></>
          // <S.StyledChallengeUploadContainer>
          //   <UploadFiles
          //     description='Один файл в формате doc, docx или pdf. Объемом до 10 МБайт'
          //     uploadLink='/documents'
          //     accept='.docx,.txt,.odt,.pdf'
          //     $size={!isMobile ? 'small' : 'large'}
          //     type='progress'
          //     onSaveHandler={onSaveDoc}
          //   />
          // </S.StyledChallengeUploadContainer>
        )}

        {props.userData?.urlPost ? (
          <Fragment>
            <Text $textStyle={'p14'} $fontWeight={'800'} $color={colors.title}>
              Загруженная ссылка на пост
            </Text>
            <Text $textStyle={'p14'} $color={colors.title}>
              Созданный вами пост можно посмотреть
              <Link
                displayAs='a'
                $textStyle={'p14'}
                $color={colors.blue}
                to={props.userData?.urlPost}>
                {' '}
                по ссылке.
              </Link>{' '}
              Напоминаем, что результаты участия в дистанционном этапе конкурса станут известны
              после 11 июня и будут размещены в твоем профиле в игре. Желаем тебе удачи!
            </Text>
          </Fragment>
        ) : (
          <S.StyledChallengeUploadContainer>
            {/* <Text $textStyle={'p14'} $color={colors.title}>
              Отправить ссылку
            </Text>
            <Text $mT={'8px'} $textStyle={'p13'} $color={colors.title} isHtml>
              {props.data.offlineQuest}
            </Text> */}
            <Text $color='red' $textStyle={'p14'}>
              {/* <br /> */}
              {/* Отправь ссылку на рассказ о своих добрый делах до 7 июня 16:00 (МСК). <br /> */}
              Дорогой друг! Прием работ по заданию «Доброе дело» завершен. Напоминаем, что
              результаты участия в дистанционном этапе конкурса станут известны после 11 июня и
              будут размещены в твоем профиле в игре. Желаем тебе удачи!
              <br />
            </Text>
            {/* <Box $mT={'8px'}>
              <Input isGrey placeholder='...' value={postLink} onChange={setPostLink} />
            </Box>
            <Text $mT={'8px'} $textStyle={'p13'} $color={colors.text50} isHtml>
              Перепроверь корректность ссылки перед нажатием на кнопку «Отправить». Изменить ссылку
              после отправки нельзя.
            </Text>
            <Button
              disabled={!postLink}
              $maxW='120px'
              $minW='120px'
              $mT={'12px'}
              $style='blue'
              onClick={onSavePost}>
              <Box $justify='center' $isWrap={false} $direction='row'>
                Отправить
              </Box>
            </Button> */}
          </S.StyledChallengeUploadContainer>
        )}
      </S.StyledChallengeBottomContainer>
    </S.StyledChallenge>
  );
};

export default GeneralChallenge;
