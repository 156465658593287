import React, { useState } from 'react';
import { LayoutAuth } from '../../components/Layout/LayoutAuth';

import styled from 'styled-components';
import colors from '../../../common/shared/constants/colors';

import { Text } from '../../../common/shared/ui/Text';
import { Button } from '../../../common/shared/ui/Button';

import { useDispatch } from 'react-redux';
import { clearProgressAsync } from '../../redux/user/userActions';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 24px;
  border-radius: 16px;
  background: white;

  & > * + * {
    margin-top: 16px;
  }
`;

const bodyStage2act1 = {
  state: {
    isOnboardingCompleted: true,
    scenarios: {
      scenario_0: [],
      scenario_1_1: [],
      scenario_1_2: [],
      scenario_1_3: [],
      scenario_1_3_1: [],
      scenario_1_7: [],
      scenario_1_7_0: [],
      scenario_1_8: [],
      scenario_1_8_0: [],
      scenario_1_9: [],
      scenario_1_9_0: [],

      scenario_1_14: [],
      scenario_1_14_0: [],
      scenario_1_17: [],
      scenario_1_17_0: [],
      scenario_1_18: [],
      scenario_1_18_0: [],
      scenario_1_19: [],

      scenario_1_23: [],
      scenario_1_25: [],
      scenario_1_25_0: [],
      scenario_1_25_1: [],
      scenario_1_25_2: [],
      scenario_1_25_4: [],
    },
  },
};

const bodyStage2act2 = {
  state: {
    isOnboardingCompleted: true,
    scenarios: {
      ...bodyStage2act1.state.scenarios,
      scenario_1_30: [],

      scenario_2_0: [],
      scenario_2_0_1: [],
      scenario_2_1: [],
      scenario_2_1_1: [],
      scenario_2_2: [],
      scenario_2_2_1: [],
      scenario_2_2_4: [],
      scenario_2_4: [],
      scenario_2_4_0: [],
      scenario_2_5: [],
    },
  },
};
const bodyStage2act3 = {
  state: {
    isOnboardingCompleted: true,
    scenarios: {
      ...bodyStage2act2.state.scenarios,
      scenario_2_8: [],
      scenario_2_9: [],
      scenario_2_9_1: [],
      scenario_2_10: [],
      scenario_2_10_0: [],
      scenario_2_10_1: [],
      scenario_2_10_1_0: [],
      scenario_2_11: [],
      scenario_2_11_0: [],
      scenario_2_11_4: [],
      scenario_2_11_5: [],
      scenario_2_12: [],
      scenario_2_13: [],
      scenario_2_14: [],
      scenario_2_14_0: [],
      scenario_2_15: [],
      scenario_2_15_0: [],
      scenario_2_16: [],
      scenario_2_16_0_5: [],
      scenario_2_16_1: [],

      scenario_egg1: [],
      scenario_egg1_0: [],
      scenario_egg2_0: [],
    },
  },
};

const bodyStage3act1 = {
  state: {
    isOnboardingCompleted: true,
    scenarios: {
      ...bodyStage2act3.state.scenarios,
      scenario_2_20: [],
      scenario_2_20_1: [],
      scenario_2_21: [],
      scenario_2_21_1: [],
      scenario_2_22: [],
      scenario_2_22_1: [],
      scenario_2_23: [],

      scenario_egg3_0: [],
      scenario_egg4_0: [],
      scenario_egg5_0: [],
      scenario_egg6_0: [],
    },
  },
};
const bodyStage3act2 = {
  state: {
    isOnboardingCompleted: true,
    scenarios: {
      ...bodyStage3act1.state.scenarios,
      scenario_2_30: [],

      scenario_3_0: [],
      scenario_3_0_1: [],
      scenario_3_0_2: [],
      scenario_3_0_3: [],
      scenario_3_0_4: [],
      scenario_3_0_5: [],
      scenario_3_0_6: [],
      scenario_3_0_7: [],
      scenario_3_0_8: [],
      scenario_3_0_9: [],
      scenario_3_0_10: [],
      scenario_3_0_11: [],
      scenario_3_0_13: [],
      scenario_3_0_12: [],
      scenario_3_0_14: [],
      scenario_3_1: [],
      scenario_3_2: [],
      scenario_3_3_0: [],
      scenario_3_3: [],
      scenario_3_4_0: [],
      scenario_3_4: [],
      scenario_3_5: [],
    },
  },
};

const bodyStage3act3 = {
  state: {
    isOnboardingCompleted: true,
    scenarios: {
      ...bodyStage3act2.state.scenarios,

      scenario_3_10: [],
      scenario_3_11: [],
      scenario_3_12: [],
      scenario_3_13: [],
      scenario_3_14: [],
      scenario_3_15: [],
      scenario_3_16: [],
      scenario_3_17: [],
      scenario_3_18: [],
      scenario_3_19: [],
    },
  },
};

const DebugPage = () => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onClearProgress = React.useCallback(async () => {
    setLoading(true);
    await dispatch(clearProgressAsync());
    setLoading(false);
  }, [dispatch]);

  const goToStage2Act1 = React.useCallback(async () => {
    setLoading(true);
    await dispatch(clearProgressAsync(bodyStage2act1));
    setLoading(false);
  }, [dispatch]);

  const goToStage2Act2 = React.useCallback(async () => {
    setLoading(true);
    await dispatch(clearProgressAsync(bodyStage2act2));
    setLoading(false);
  }, [dispatch]);

  const goToStage2Act3 = React.useCallback(async () => {
    setLoading(true);
    await dispatch(clearProgressAsync(bodyStage2act3));
    setLoading(false);
  }, [dispatch]);

  const goToStage3Act1 = React.useCallback(async () => {
    setLoading(true);
    await dispatch(clearProgressAsync(bodyStage3act1));
    setLoading(false);
  }, [dispatch]);

  const goToStage3Act2 = React.useCallback(async () => {
    setLoading(true);
    await dispatch(clearProgressAsync(bodyStage3act2));
    setLoading(false);
  }, [dispatch]);

  const goToStage3Act3 = React.useCallback(async () => {
    setLoading(true);
    await dispatch(clearProgressAsync(bodyStage3act3));
    setLoading(false);
  }, [dispatch]);

  return (
    <LayoutAuth withGrid>
      <StyledWrapper>
        <Text $textStyle='h3' $color={colors.title} $fontWeight='800'>
          Действия
        </Text>
        <Button disabled={isLoading} onClick={onClearProgress}>
          Сбросить прогресс
        </Button>
        <Button disabled={isLoading} onClick={goToStage2Act1}>
          Перейти к этапу 2 акт 1
        </Button>
        <Button disabled={isLoading} onClick={goToStage2Act2}>
          Перейти к этапу 2 акт 2
        </Button>
        <Button disabled={isLoading} onClick={goToStage2Act3}>
          Перейти к этапу 2 акт 3
        </Button>
        <Button disabled={isLoading} onClick={goToStage3Act1}>
          Перейти к этапу 3
        </Button>
        <Button disabled={isLoading} onClick={goToStage3Act2}>
          Перейти к этапу 3 акт 2
        </Button>
        <Button disabled={isLoading} onClick={goToStage3Act3}>
          Перейти к этапу 3 акт 3
        </Button>
      </StyledWrapper>
    </LayoutAuth>
  );
};

export default DebugPage;
