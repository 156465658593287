import React from 'react';

import {
  SidebarSpacer,
  SupportInfoWrapper,
  SidebarWrapperStyled,
  StyledSidebarMenuItem,
  SidebarSupportItemStyled,
  SidebarMenuItemIconStyled,
  SidebarSupportWrapperStyled,
  StyledSidebarMenuItemContent,
} from './SidebarStyled';

import { useLocation } from 'react-router';
import { IconProfile } from '../../../assets/icons/IconProfile';
import { IconMap } from '../../../assets/icons/IconMap';
import { IconInfo } from '../../../assets/icons/IconInfo';
import { IconSupport } from '../../../assets/icons/IconSupport';
import { Link } from 'react-router-dom';
import { IconEmail } from '../../../assets/icons/IconEmail';
import { IconPhone } from '../../../assets/icons/IconPhone';
import { IconRatings } from '../../../assets/icons/IconRatings';

import { Text } from '../../../common/shared/ui/Text';
import { Divider } from '../../../common/shared/ui/Divider';

import { routes } from '../../../common/shared/constants/routes';
import { SidebarProps } from './SidebarTypes';
import { useIsProfileAvailable } from '../../hooks/useIsProfileAvailable';
import { preventLeaveTestOnClick } from '../../hooks/scenarioHelpers/utils';

const config: Record<string, any> = {
  profile: {
    icon: IconProfile,
    title: 'Профиль',
    to: routes.profile,
    // disabled: true,
  },
  map: {
    icon: IconMap,
    title: 'Карта',
    to: routes.map,
  },
  ratings: {
    icon: IconRatings,
    title: 'Рейтинги',
    to: routes.ratings,
    disabled: true,
  },
  about: {
    icon: IconInfo,
    title: 'О конкурсе',
    to: 'https://bolshayaperemena.online/',
    target: '_blank',
  },
};

const order = ['profile', 'map', 'about'];
// const order = ['profile', 'map', 'ratings', 'about'];

const PHONE = '+7 800 350 63 83';
const UNMASKED_HONE = `8${PHONE.replace(/[^\d]/g, '').slice(1)}`;
const EMAIL = 'help@bolshayaperemena.online';

export const SupportInfo = ({
  isMobileMenuSidebar,
  isOnboarding,
}: {
  isMobileMenuSidebar?: boolean;
  isOnboarding?: boolean;
}) => {
  return (
    <SidebarSupportWrapperStyled
      $isMobileMenuSidebar={isMobileMenuSidebar}
      $isOnboarding={isOnboarding}>
      <SidebarSupportItemStyled>
        <SidebarMenuItemIconStyled>
          <IconSupport />
        </SidebarMenuItemIconStyled>
        <Text $textStyle='p14' $fontWeight='600'>
          Поддержка
        </Text>
      </SidebarSupportItemStyled>

      <Divider isOnboardingDivider />

      <SupportInfoWrapper $isOnboarding={isOnboarding}>
        <SidebarSupportItemStyled href={`tel:${UNMASKED_HONE}`}>
          <SidebarMenuItemIconStyled>
            <IconPhone />
          </SidebarMenuItemIconStyled>
          <Text $textStyle='p12' $fontWeight='600'>
            {PHONE}
          </Text>
        </SidebarSupportItemStyled>
        <SidebarSupportItemStyled href={`mailto:${EMAIL}`}>
          <SidebarMenuItemIconStyled>
            <IconEmail />
          </SidebarMenuItemIconStyled>
          <Text $textStyle='p12' $fontWeight='600'>
            Написать письмо
          </Text>
        </SidebarSupportItemStyled>
      </SupportInfoWrapper>
    </SidebarSupportWrapperStyled>
  );
};

export const Sidebar = (props: SidebarProps) => {
  const { pathname } = useLocation();

  const isProfileAvailable = useIsProfileAvailable();
  return (
    <SidebarWrapperStyled
      className={props.className}
      $isMobileMenuSidebar={props.isMobileMenuSidebar}>
      {order.map((key) => {
        const { icon: Icon, title, to, target } = config[key];
        let { disabled } = config[key];
        if (to === routes.profile) {
          disabled = !isProfileAvailable;
        }

        const toAttr = target === '_blank' ? { pathname: to } : to;
        const Tag: any = disabled ? 'div' : Link;

        return (
          <StyledSidebarMenuItem
            as={Tag}
            to={toAttr}
            key={to}
            target={target}
            onClick={preventLeaveTestOnClick}>
            <StyledSidebarMenuItemContent
              $disabled={disabled}
              $textStyle='p14'
              $fontWeight='600'
              $current={pathname === to}>
              <SidebarMenuItemIconStyled>
                <Icon />
              </SidebarMenuItemIconStyled>
              {title}
            </StyledSidebarMenuItemContent>
          </StyledSidebarMenuItem>
        );
      })}
      <SidebarSpacer />
      <SupportInfo isMobileMenuSidebar={props.isMobileMenuSidebar} />
    </SidebarWrapperStyled>
  );
};
