import { TestJSON } from '../../../types/scenario';

import TK from './test-tvoi-kod-junior-210401.json';
import TVOI_VIBOR from './test-tvoi-vybor-junior-210401.json';
import TVOI_VEKTOR from './test-tvoi-vektor-junior-210401.json';
import ERUDITION_HUM from './test-tvoi-krugozor-junior-g3-210519.json';
import ERUDITION_TECH from './test-tvoi-krugozor-junior-t3-210519.json';
import ERUDITION_HUM_ON_CHECK from './Тест_Твой_кругозор_Junior_g2024_2024_05_28.json';
import ERUDITION_TECH_ON_CHECK from './Тест_Твой_кругозор_Junior_t2024_2024_05_28.json';

/**
 * Это тесты МГУ (прям Тесты -тесты) :
'personality' = 'Твой код (тип личности)',
'interests' = 'Твой выбор (интересы)' - разбит на части,
'thinking' = 'Твой вектор (типы мышления)' - разбит на части,
'erudition_tech' = 'Эрудиция в технической сфере',
'erudition_hum' = 'Эрудиция в гуманитарной сфере',

  'communicativeness' = 'Коммуникативность',  Это сбор видео визитки и ответ на вопросы в трех предложениях. Нужно проверить точно ли мы их задавали - в сценарии не нашла, возможно мы их удалили из сценария. Если задавали, то нужно проверить, сохранились ли ответы на вопрос. сможем ли мы их получить в формате таблицы: айди/ ответ на первый вопрос/ответ на второй вопрос.

  'altruism' = 'Альтруизм'   
Это "развилка действий во время игры", где участник выбирает один из вариантов поведения (описывает Кира). Их несколько на протяжении всей игры.  В идеале необходимо запоминать действия игроков на этой развилке, чтобы можно было за это начислить баллы. Если нет -надо думать.

  'creativity' = 'Креативность',
это не тест МГУ - это задания с полями свободного ввода впиленные в сценарий игры. Нужно запоминать ответы детей, чтобы потом им начислить баллы

 'logic' = 'Логика',
это не тесты МГУ, это тесты заложенные внутрь игры. тесты с вариантами ответов, где балл начисляется за правильный ответ.

 */
export const tests: Record<string, TestJSON> = {
  'test-tvoi-kod-junior-210401.json': TK as TestJSON, // Тест на Тип личности
  'test-tvoi-vybor-junior-210401.json': TVOI_VIBOR as TestJSON, // Тест на Интересы     (разбит на части)
  'test-tvoi-vektor-junior-210401.json': TVOI_VEKTOR as TestJSON, // Тест на Тип мышления
  'test-tvoi-krugozor-junior-g3-210519.json': ERUDITION_HUM as TestJSON,
  'test-tvoi-krugozor-junior-t3-210519.json': ERUDITION_TECH as TestJSON,
  'Тест_Твой_кругозор_Junior_g2024_2024_05_28.json': ERUDITION_HUM_ON_CHECK as TestJSON,
  'Тест_Твой_кругозор_Junior_t2024_2024_05_28.json': ERUDITION_TECH_ON_CHECK as TestJSON,
};
