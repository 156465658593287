import QueryString from 'query-string';
import axios, { AxiosError } from 'axios';
import LocalStorage from '../utils/LocalStorage';
import { STORE_USER_TOKEN } from '../constants/constants';

const getApiBaseUrl = () => {
  const isProduction = process.env.NODE_ENV === 'production';
  // see .env file
  //if (!isProduction) return '';
  return process.env.BASE_URL || process.env.REACT_APP_API_BASE_URL;
};

const paramsSerializer = (params: Record<string, any>) => {
  return QueryString.stringify(params, {
    arrayFormat: 'bracket',
    skipEmptyString: true,
  });
};

class Api {
  static setToken(token: string) {
    Api.call.defaults.headers.Authorization = `Bearer ${token}`;
  }

  static call = axios.create({
    paramsSerializer,
    responseType: 'json',
    baseURL: getApiBaseUrl(),
    headers: { 'Content-Type': 'application/json' },
  });

  static handleDefaultError = (
    error: AxiosError & { isCancel?: boolean },
    handleCustomErrors?: (error: AxiosError) => void,
  ) => {
    const { response } = error;
    if (response) {
      const { status } = response;
      const message = response.data?.message || response.data?.error;

      switch (status) {
        case 401:
          return;

        case 500:
          alert(message || 'Server Error');
          return;

        default:
          if (handleCustomErrors) {
            handleCustomErrors(error);
            return;
          }
          alert(message);
          return;
      }
    }
    alert(error);
    console.error(error);
  };
}

Api.setToken(LocalStorage.get(STORE_USER_TOKEN) || '');

export default Api;
