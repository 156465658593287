import styled from 'styled-components';
import colors from '../../constants/colors';

import { BaseStyled } from '../../styles/styledBase';
import { BREAKPOINT_SMALL_PHONE } from '../../constants/constants';
import { ButtonProps } from './Button';

const configSizes: Record<string, string> = {
  s: `
    padding: 8px 12px;
    font-size: 18px;
    border-radius: 6px;
  `,
  md: `
    padding: 18.5px 30px;
  `,
  lg: `
    padding: 30px;
    
    @media screen and (max-width: ${BREAKPOINT_SMALL_PHONE}) {
      padding: 23px 15px;
    }
  `,
};

export const ButtonStyled = styled(BaseStyled)<ButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 16px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:active {
    transform: translateY(1px);
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${({ $isRound }) => $isRound && `border-radius: 50% !important;`}
  ${({ $isRoundMobileButton }) => $isRoundMobileButton && ` padding: 10px !important;`}

  ${({ $full }) => $full && `width: 100%;`}
  ${({ $size }) => configSizes[$size as string]}
  ${({ $center }) => $center && `justify-content: center;`}
  ${({ $fontWeight }) => $fontWeight && `font-weight: ${$fontWeight} !important;`}
`;

export const ButtonBlueStyled = styled(ButtonStyled)<ButtonProps>`
  cursor: ${({ $isActive }) => ($isActive ? `default` : `pointer`)};
  background: ${({ $isActive }) => ($isActive ? `${colors.blueShadow};` : `${colors.blueButton}`)};

  color: white;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 23px;
  font-weight: 700;

  &:disabled {
    opacity: 0.8;
  }

  &:hover {
    background: ${colors.blueShadow};
  }
`;

export const ButtonNewGreyStyled = styled(ButtonStyled)`
  color: ${colors.grey2};
  background: ${colors.grey};
  font-size: 14px;

  line-height: 20px;
  padding: 10px 23px;

  &:hover {
    box-shadow:
      0 4px 24px rgba(255, 255, 255, 0.3),
      0 4px 4px rgba(255, 255, 255, 0.5);
  }

  &:disabled {
    opacity: 0.8;
  }
`;

export const ButtonPurpleStyled = styled(ButtonStyled)`
  background: ${colors.blue};
  color: white;

  &:hover {
    box-shadow:
      0 4px 24px rgba(255, 255, 255, 0.3),
      0 4px 4px ${colors.blueShadow};
  }
`;

export const ButtonPrimaryStyled = styled(ButtonStyled)<ButtonProps>`
  background: ${colors.primary};
  color: ${colors.text};

  ${(props) =>
    !props.$noborder &&
    `
    border: 1px solid ${colors.text};
  `}

  &:hover {
    box-shadow:
      0 4px 24px rgba(255, 255, 255, 0.3),
      0 4px 4px rgba(255, 242, 129, 0.5);
  }

  &:disabled {
    color: ${colors.error};
  }
`;

export const ButtonGhostStyled = styled(ButtonStyled)`
  color: ${colors.text};
  border: 1px solid ${colors.text};
  background: white;

  &:hover {
    box-shadow:
      0 4px 24px rgba(255, 255, 255, 0.3),
      0 4px 4px rgba(255, 255, 255, 0.5);
  }
`;

export const ButtonDarkGreyStyled = styled(ButtonStyled)`
  font-size: 15px;
  font-weight: 500;
  line-height: 150%;

  padding: 10px 12px;
  border-radius: 12px;
  color: ${colors.text};

  background: #eeeeee;

  &:hover {
    background: #dddddd;
  }
`;

export const ButtonGreyStyled = styled(ButtonStyled)`
  color: ${colors.grey2};
  background: ${colors.grey};
  font-size: 14px;

  &:hover {
    box-shadow:
      0 4px 24px rgba(255, 255, 255, 0.3),
      0 4px 4px rgba(255, 255, 255, 0.5);
  }
`;
