import React from 'react';

import Hero from '../../components/Hero';
import NickName from '../../components/NickName';
import Backpack from '../../components/Backpack';
import Challenges from '../../components/Challenges';

import useActiveTestResult from '../../hooks/useActiveTestResult';

import { ProfilePageProps } from './ProfilePageTypes';
import { StyledProfilePage, StyledProfilePageWrapper } from './ProfilePageStyled';
import { useIsProfileAvailable } from '../../hooks/useIsProfileAvailable';
import { Redirect } from 'react-router';
import { routes } from '../../../common/shared/constants/routes';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../redux/user/userGetters';

import { ModalAttachmentVideo } from '../../widgets/ModalAttachmentVideo/index';
import { GameModal } from '../../components/GameModal';
import { Scores } from 'src/common/widgets/Scores/Scores';

const ProfilePage = (props: ProfilePageProps) => {
  const { activeTestResult } = useActiveTestResult();
  const state = useSelector(selectUserState);

  return (
    <StyledProfilePageWrapper>
      <StyledProfilePage>
        {activeTestResult ? (
          {
            /*<TestResult />*/
          }
        ) : (
          <>
            <GameModal />
            {/*<WelcomeProfile />*/}
            <Scores state={state} />
            <NickName />
            <Hero />
            <Backpack />
            <ModalAttachmentVideo />
            {/* <TestsResults /> */}
            <Challenges />
          </>
        )}
      </StyledProfilePage>
    </StyledProfilePageWrapper>
  );
};

function ProfilePageWrapper(props: ProfilePageProps) {
  const isProfileAvailable = useIsProfileAvailable();
  return isProfileAvailable ? <ProfilePage {...props} /> : <Redirect to={routes.dashboard} />;
}
export default ProfilePageWrapper;
