import styled from 'styled-components';
import colors from '../../shared/constants/colors';
import { BREAKPOINT_PHONE } from '../../shared/constants/constants';

export const SystemPage = styled.div`
  display: flex;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  background: ${colors.background};
  color: ${colors.text};

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    padding-left: 23px;
    padding-right: 23px;
  }
`;
