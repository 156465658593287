import styled, { css } from 'styled-components';
import { MapPinStyledProps } from './MapPinTypes';

import pin_base from './pin_base.svg';
import pin_shadow from './pin_shadow.svg';

import pin_steps from './pin_steps.svg';
import pin_blue_steps from './pin_blue_steps.svg';

import { MapPinType } from '../../hooks/scenarioHelpers/types';

export const MapPinStyled = styled.div<MapPinStyledProps>`
  z-index: 10;
  height: 0px;

  display: block;
  cursor: pointer;
  position: absolute;

  width: 60px;

  @media screen and (min-width: 2000px) {
    width: 104px;
  }

  @media screen and (min-width: 4000px) {
    width: 156px;
  }

  & > div {
    position: relative;

    transform: translate(-50%, -85.71428571428571%);

    ${({ type }) =>
      type === MapPinType.steps || type === MapPinType.blueSteps
        ? css`
            display: block;
            position: absolute;

            width: 100%;
            height: 100%;

            top: 50%;
            left: 50%;

            padding-bottom: 110%;
            transition: transform 0.3s ease;
            transform: translateX(-50%) translateY(-50%);

            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(${type === MapPinType.blueSteps ? pin_blue_steps : pin_steps});

            &:hover {
              transform: translateX(-50%) translateY(-50%) scale(1.1);
            }
          `
        : css`
            padding-bottom: 116.66666666666667%;

            &::after,
            &::before {
              content: '';
              display: block;
              position: absolute;

              left: 50%;
              transform: translateX(-50%);
              transition: transform 0.3s ease;

              background-size: cover;
              background-position: center;
              background-repeat: no-repeat;
            }

            &:before {
              z-index: 2;
              width: 78.33333333333333%;
              height: 85.71428571428571%;

              top: 0;
              background-image: url(${pin_base});
            }

            &:after {
              z-index: 1;
              width: 66%;
              height: 28.57142857142857%;

              bottom: 0;
              background-image: url(${pin_shadow});
            }

            &:hover {
              &:before {
                transform: translate(-50%, -10%);
              }

              &:after {
                transform: translateX(-50%) scale(1.3);
              }
            }
          `}
  }

  ${(props) => props.$top && `top: ${props.$top}%;`}
  ${(props) => props.$bottom && `bottom: ${props.$bottom}%;`}
  ${(props) => props.$left && `left: ${props.$left}%;`}
  ${(props) => props.$right && `right: ${props.$right}%;`}
  ${(props) =>
    props.isDisabled &&
    `
      opacity: .7;
      cursor: not-allowed;
      pointer-events: none;
    `}
`;
