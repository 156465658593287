import React from 'react';
import { LoaderProps } from './LoaderTypes';
import { StyledLoader } from './LoaderStyled';

export const Loader = (props: LoaderProps) => {
  return (
    <StyledLoader className={props.className}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </StyledLoader>
  );
};
