/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg2_moscowCityMeetingGranny_1_1: Scenario = {
  scenarioId: SceneId.stg2_moscowCityMeetingGranny_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Москва сити',
    scene_id: 'stg2_moscowCityMeetingGranny_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.GRANDMOTHER,
        text: `Ребята, как у вас дела?

        У нас так хорошо! Всех рыб спасли, по аквариумам рассадили.`,
        id: 'Q1',
        type: StepType.message,
        nextStep: 'Q2',
      },
      Q2: {
        text: `А газировку «Байкал» пробовали уже? Торговля идёт невероятными темпами!`,
        id: 'Q2',
        person: Interlocutors.ENTREPRENEUR,
        type: StepType.question,
        nextStep: 'Q3',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Эх, здорово всё у вас. ',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        text: `Что же вы такие грустные! Что случилось?`,
        id: 'Q3',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        nextStep: 'Q4',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Мы хотим вернуть пришельца домой. Послать сигнал его родне с Останкинской Башни. А без радиопередатчика ничего не выйдет. Но он очень дорого стоит! ',
            nextStep: 'Q4',
          },
        ],
      },
      Q4: {
        text: `Как это, дорого? Мы же с вами партнёры, вместо дело построили! А прибыль от продажи газировки у нас очень хорошая.

        Закажем вам этот передатчик из Москвы, если нужно.`,
        id: 'Q4',
        person: Interlocutors.ENTREPRENEUR,
        type: StepType.question,
        nextStep: 'Q5',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Правда? Спасибо!',
            nextStep: 'Q5',
          },
        ],
      },
      Q5: {
        text: `Конечно, я лично всё проконтролирую!

        Только, вы учтите, что на Останкинской Башне, говорят, лифты ездить перестали, как пришелец к нам прилетел. `,
        id: 'Q5',
        person: Interlocutors.ENTREPRENEUR,
        type: StepType.question,
        nextStep: EXIT_ID,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо, что предупредили. Мы пойдём тогда, разбираться с лифтом. Удачи вам с делами! ',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
