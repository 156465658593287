/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId, Step, StepType } from '../../../scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';
import { images } from '../../tests/images';

const EXIT_ID = 'EXIT_ID';

export const scenario_3_18_0: Scenario = {
  scenarioId: SceneId.scenario_3_18_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Триумфальная арка «Большой перемены»',
    text: 'Вы вместе с обитателями «Большой перемены» находитесь у главного входа в парк. Перед вами возвышается величественная триумфальная арка. Вы пытаетесь пройти через ворота, чтобы хорошенько исследовать локацию, но будто какое-то невидимое поле не дает вам этого сделать...',
    buttons: ['Продолжить'],
  },
};

function getCreative(_: number, index: number): Step {
  return {
    timeout: 60,
    person: Interlocutors.AI,
    id: `CREATIVE_${index + 1}`,
    type: StepType.text_question,
    variable: `CREATIVE_${index + 1}`,
    text: ``,
    image: { uri: images[`creative_arch_${index + 1}`], title: '' },
    nextStep: `CREATIVE_${index + 2}`,
  };
}

function arrayToRecord(array: Step[]): Record<string, Step> {
  const obj: Record<string, Step> = {};
  for (let i = 0; i < array.length; i++) {
    obj[array[i].id] = array[i];
  }
  return obj;
}

const CREATIVE: Record<string, Step> = {
  ...splitStep({
    person: Interlocutors.AI,
    id: 'CREATIVE0',
    callToAction: 'Вам все понятно?',
    text: `Здравствуйте, {{NAME}}. Чтобы покинуть парк «Большая перемена», необходимо выполнить задание.

Я покажу 10 незаконченных фигур. Вам нужно мысленно добавить к ним дополнительные линии, чтобы получились интересные предметы или сюжетные картинки.

Постарайтесь придумать такую картинку, которую никто другой не сможет придумать. Придумайте на что это будет похоже в итоге и напишите. На выполнение у вас будет всего 10 минут: по 1 минуте на 1 картинку.`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Да, можно начинать',
        nextStep: 'NOTE',
      },
    ],
  }),
  NOTE: {
    person: Interlocutors.SYSTEM,
    id: 'Q1',
    text: `Данный тест является тренировочным и не приносит баллов в конкурсе!`,
    type: StepType.message,
    nextStep: 'CREATIVE_1',
  },
  ...arrayToRecord(new Array(10).fill(1).map(getCreative)),
  CREATIVE_11: {
    person: Interlocutors.AI,
    id: 'CREATIVE_11',
    type: StepType.message,
    text: `Ваши ответы приняты. Силовой барьер открыт, вы можете пройти сквозь триумфальную арку «Большой перемены».`,
    nextStep: 'Q7/0',
  },
};

export const scenario_3_18: Scenario = {
  scenarioId: SceneId.scenario_3_18,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Триумфальная арка «Большой перемены»',
    scene_id: 'scenario_3_18',
    startId: 'Q1',
    timeoutMessage: 'Время на этот ответ истекло.',
    steps: {
      Q1: {
        person: Interlocutors.TEACHER,
        id: 'Q1',
        text: `Это что-то новенькое! Почему мы не можем пройти сквозь арку? Будто какая-то неведомая сила не дает нам выйти из парка!`,
        type: StepType.message,
        nextStep: 'Q2/0',
      },
      ...splitStep({
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q2',
        type: StepType.message,
        text: `Эта неведомая сила, Светлана Васильевна, невидимый силовой барьер, который не дает случайным прохожим зайти в «Большую перемену».


        Помните, я хотел сделать необычный технологичный вход в «Большую перемену», чтобы посетители с первых шагов понимали, что попали в парк с передовыми технологиями?\
        
        
        Так вот, наш вход сделан по секретной космической технологии. Барьер пропускает только самых смекалистых и креативных ребят, и чтобы зайти, надо решить вопросы, которые генерирует искусственный интеллект, вживленный в Арку.
        
        
        Вот только после нашествия ледяного тумана система начала барахлить и стала работать не только на вход, но и на выход. Это значит, что для того, чтобы выйти из парка и осмотреть местность, нам сначала надо ответить на вопросы искусственного интеллекта.`,
        nextStep: 'Q3',
      }),
      Q3: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q3',
        type: StepType.message,
        text: `Странно, когда я убегал от вспышки, не помню, чтобы сталкивался с подобным невидимым барьером...`,
        nextStep: 'Q4',
      },
      Q4: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q4',
        text: `Скорее всего из-за вспышек 10G вышки магнитное поле ослабло. Так ты, Синий, пробежал, не заметив барьер.
        
        В любом случае, если мы хотим попасть за пределы «Большой перемены» и найти путь к дому Синего, нам нужно ответить на вопросы.`,
        nextStep: 'Q6',
        type: StepType.question,
        maxAnswers: 1,
        callToAction: '{{NAME}}, ты [[готов/готова]]?',
        buttons: [
          {
            id: 'b1',
            text: 'Конечно! Мы же не можем оставить Синего на произвол судьбы!',
            nextStep: 'Q6',
          },
        ],
      },

      Q6: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q6',
        type: StepType.message,
        text: `Тогда начнем!`,
        nextStep: 'CREATIVE0/0',
      },

      ...CREATIVE,

      ...splitStep({
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q7',
        type: StepType.message,
        text: `Кажется, ты успешно [[справился/справилась]] с заданием искусственного интеллекта. 

Давайте теперь осмотримся. Возможно, мы найдем тропинку, по которой Синий попал к нам.`,
        nextStep: 'Q8/0',
      }),
      ...splitStep({
        person: Interlocutors.TEACHER,
        id: 'Q8',
        type: StepType.message,
        text: `Скорее все сюда! Смотрите, что здесь! Это объявление о пропаже кота: 

Потерялся кот по имени Малевич. Окрас синий, кончик хвоста светло-голубой. Дружелюбный, образованный, немного неуклюжий. Любит кофе и разгадывать логические задачки. Нашедшему — щедрое вознаграждение. Любящая хозяйка София. 

И номер телефона…`,
        nextStep: 'Q9',
      }),
      Q9: {
        person: Interlocutors.PETROV,
        id: 'Q9',
        type: StepType.message,
        text: `Так вот, Синий, как тебя на самом деле зовут! Малевич! 😍`,
        nextStep: 'Q10',
      },
      Q10: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q10',
        type: StepType.message,
        text: `УРА! Хозяйка нашлась! Синенький, Малевич, родненький! Ты скоро воссоединишься со своей дорогой Софией! 😍`,
        nextStep: 'Q11',
      },
      Q11: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q11',
        text: 'Дорогие мои! Теперь я тоже вспомнил свое имя. Оказывается, все это время меня искали! Ох, бедная моя София, наверное места себе не находит 😿',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Давайте же скорее позвоним Софии!',
            nextStep: 'Q12',
          },
        ],
      },
      ...splitStep({
        person: Interlocutors.SYSTEM,
        id: 'Q12',
        type: StepType.message,
        text: `Сан Саныч, Светлана Васильевна и Бабуля расходятся по своим делам, а вы с Вжухом, Малевичем и Петровым отправляетесь в Иннополис дожидаться Софию.`,
        nextStep: EXIT_ID,
      }),
      [EXIT_ID]: {
        person: Interlocutors.PETROV,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
