import styled from 'styled-components';
import { IconProps } from './IconTypes';
import { BaseStyled } from '../../styles/styledBase';

export const IconStyled = styled(BaseStyled)<IconProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    max-width: 34px;
    max-height: 34px;
  }

  @keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  ${(p) => p.isRotating && 'animation: rotating 1s linear infinite;;'}
  ${(p) => p.isReverse && 'transform: rotate(-180deg);'}
`;
