/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_mgu_1_2: Scenario = {
  scenarioId: SceneId.stg2_mgu_1_2,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.CAT,
    title: 'Система',
    text: `Получается, теперь придётся искать его по всему парку! Но, хотя бы, тебя к викторине подготовим…`,
    buttons: ['Продолжить'],
  },
};
