import { SceneId } from '../../resources/scenario';
import { PointsOfInterest } from '../../types';

export enum MapPinType {
  'steps' = 'steps',
  'point' = 'point',
  'blueSteps' = 'blueSteps',
}

export type ScenarioRunnerConfig = {
  id: SceneId;
  unsaved?: boolean;
  runOnSubmit?: SceneId;
};

export type PointsClickHandlers = {
  point: PointsOfInterest;
  handler: () => void;

  type?: MapPinType; // point по умолчанию
}[];

export type RunModalScenario = (
  id: SceneId,
  onSubmitId: SceneId,
  onCompleteId?: SceneId,
  skipIfExist?: Partial<Record<SceneId, any>>,
) => void;

export interface ScenarioHelperResponse {
  pointsHandlers: PointsClickHandlers;
  autorunScenario?: ScenarioRunnerConfig;
  helpMessage?: string;
  delay?: number;
}
