import styled, { keyframes } from 'styled-components';
import { BREAKPOINT_PHONE } from '../../../common/shared/constants/constants';

export const StyledSuccessPage = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin: 90px 100px;
  text-align: center;

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    margin: 30px 0;
    max-width: 100%;
  }
`;

export const StyledSuccessPageContent = styled.div`
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SuccessPageInner = styled.div``;

export const starAnimation = keyframes`
  0% {
    transform: translateX(0px) translateY(0px) rotate(0deg) scale(1,1);
  }
  50% {
    transform: translateX(0px) translateY(0px) rotate(0deg) scale(1,1);
  }
  51% {
    transform: translateX(0px) translateY(0px) rotate(0deg) scale(1.1,1.1);
  }
  99% {
    transform: translateX(0px) translateY(0px) rotate(0deg) scale(1.1,1.1);
  }
  100% {
    transform: translateX(0px) translateY(0px) rotate(0deg) scale(1,1);
  }
`;

export const SuccessPagePicWrap = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 510px;
  height: 50%;
  display: flex;
  justify-content: center;
  transform: translateX(-50%);

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    width: 194px;
  }
`;
export const SuccessPagePic = styled.img``;

const OnboardingStar = styled.img`
  display: block;
  position: absolute;
  animation: ${starAnimation} 2s infinite linear;
  backface-visibility: hidden;
`;
export const SuccessStar1 = styled(OnboardingStar)`
  width: 50px;
  height: 50px;
  left: 0;
  top: 60px;

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    left: -60px;
  }
`;
export const SuccessStar2 = styled(OnboardingStar)`
  width: 70px;
  height: 70px;
  right: 0;
  top: 0;

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    right: -60px;
  }
`;
export const SuccessStar3 = styled(OnboardingStar)`
  width: 32px;
  height: 32px;
  top: 60%;
  left: 60px;

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    left: -40px;
    top: 45%;
  }
`;
export const SuccessStar4 = styled(OnboardingStar)`
  width: 27px;
  height: 27px;
  top: 40%;
  right: -60px;
`;
