import React, { useMemo } from 'react';
import {
  StyledItemsBarItem,
  StyledItemsBarItemIcon,
  StyledItemsBarItemColor,
} from './ItemsBarItemStyled';

import { prepareText } from '../TemplatedText';
import { ItemsBarItemProps } from './ItemsBarItemTypes';

import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../redux/user/userGetters';

const ItemsBarItem = (props: ItemsBarItemProps) => {
  const userInfo = useSelector(selectUserInfo);
  const variables = useMemo(() => userInfo?.variables || {}, [userInfo]);

  const handleSelect = React.useCallback(() => {
    if (props.onSelect) props.onSelect(props.data);
  }, [props]);

  const tooltipProps = useMemo(() => {
    const result: Record<string, string> = {};

    if (props.data.description) {
      const gender = userInfo?.profile?.female ? 'female' : 'male';

      result['data-tip'] = prepareText(props.data.description, variables, gender);
    }

    return result;
  }, [props.data.description, userInfo?.profile?.female, variables]);

  return (
    <StyledItemsBarItem
      {...tooltipProps}
      onClick={handleSelect}
      $isPseudo={!props.data.icon}
      $hasEvent={Boolean(props.onSelect && props.active)}
      $isActive={props.active && props.active.id === props.data.id}>
      {!props.data.icon ? null : props.isBackground ? (
        <StyledItemsBarItemColor $color={props.data.icon} />
      ) : (
        <StyledItemsBarItemIcon src={props.data.icon} alt={props.data.icon} />
      )}
    </StyledItemsBarItem>
  );
};

export default ItemsBarItem;
