/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg3_ostankino_1_1_1: Scenario = {
  scenarioId: SceneId.stg3_ostankino_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Останкино',
    text: `Радиопередатчик сломался от перегрузки, но, кажется, Квилл успел отправить послание в космос: сквозь облака вокруг башни пробивается зеленый свет, и вы можете рассмотреть расплывчатые очертания корабля в небе.
    
    Вжух, Сан Саныч, Петров и Квилл ошарашенно смотрят в окна.`,
    buttons: ['Начать'],
  },
};
