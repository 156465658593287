/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg3_spaceMeetingWithAliens_1_1_0: Scenario = {
  scenarioId: SceneId.stg3_spaceMeetingWithAliens_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.CAT,
    title: 'Космодром',
    text: `Вот мы и готовы показать пришельцам, на что способны!

    Отправляемся на космодром.`,
    buttons: ['Начать'],
  },
};
