import { Interlocutors } from '../types/scenario';

import PetrovChat from '../../assets/images/persons/petrovChat.svg';
import PetrovModal from '../../assets/images/persons/petrov.svg';

import GrandMotherChat from '../../assets/images/persons/grandmotherChat.svg';
import GrandMotherModal from '../../assets/images/persons/grandmother.svg';

import CatChat from '../../assets/images/persons/catChat.svg';
import CatModal from '../../assets/images/persons/cat.svg';

import BlueCatChat from '../../assets/images/persons/blueCatChat.svg';
import BlueCatModal from '../../assets/images/persons/blueCat.svg';

import TeacherChat from '../../assets/images/persons/teacherChat.svg';
import TeacherModal from '../../assets/images/persons/teacher.svg';

import SystemChat from '../../assets/images/persons/systemChat.svg';
import SystemModal from '../../assets/images/persons/system.svg';

import EntrepreneurChat from '../../assets/images/persons/entrepreneurChat.svg';
import EntrepreneurModal from '../../assets/images/persons/entrepreneur.svg';

import AIChat from '../../assets/images/persons/AIChat.svg';
import AIModal from '../../assets/images/persons/AIModal.svg';

import SophiaChat from '../../assets/images/persons/SophiaChat.svg';
import SophiaModal from '../../assets/images/persons/SophiaModal.svg';

import Guide from '../../assets/images/persons/guide.png';
import GuideChat from '../../assets/images/persons/GuideChat.png';

import Alien from '../../assets/images/persons/Alien.png';
import AlienChat from '../../assets/images/persons/AlienChat.png';

import Lift from '../../assets/images/persons/lift.png';
import LiftChat from '../../assets/images/persons/liftChat.png';

import Ufo from '../../assets/images/persons/ufo.png';
import UfoChat from '../../assets/images/persons/ufoChat.png';

export interface Interlocutor {
  img: string;
  imgModal: string;
  name: string;
}

const interlocutors: Record<Interlocutors, Interlocutor> = {
  [Interlocutors.CAT]: {
    name: 'Кот Вжух',
    img: CatChat,
    imgModal: CatModal,
  },
  [Interlocutors.BLUE_CAT]: {
    name: 'Синий', // в будущем станет Малевич?
    img: BlueCatChat,
    imgModal: BlueCatModal,
  },
  [Interlocutors.GRANDMOTHER]: {
    name: 'Бабуля',
    img: GrandMotherChat,
    imgModal: GrandMotherModal,
  },
  [Interlocutors.TEACHER]: {
    name: 'Светлана Васильевна',
    img: TeacherChat,
    imgModal: TeacherModal,
  },
  [Interlocutors.SOFIA]: {
    name: 'София',
    img: SophiaChat,
    imgModal: SophiaModal,
  },
  [Interlocutors.PETROV]: {
    name: 'Петров',
    img: PetrovChat,
    imgModal: PetrovModal,
  },
  [Interlocutors.ENTREPRENEUR]: {
    name: 'Сан Саныч',
    img: EntrepreneurChat,
    imgModal: EntrepreneurModal,
  },
  [Interlocutors.SYSTEM]: {
    name: 'Система',
    img: SystemChat,
    imgModal: SystemModal,
  },
  [Interlocutors.AI]: {
    name: 'Искусственный интеллект',
    img: AIChat,
    imgModal: AIModal,
  },

  [Interlocutors.ME]: {
    name: 'Я',
    img: '',
    imgModal: '',
  },
  [Interlocutors.GUIDE]: {
    name: 'Экскурсовод',
    img: GuideChat,
    imgModal: Guide,
  },
  [Interlocutors.ALIEN]: {
    name: 'Инопланетянин',
    img: AlienChat,
    imgModal: Alien,
  },
  [Interlocutors.LIFT]: {
    name: 'ЛИФТ',
    img: LiftChat,
    imgModal: Lift,
  },
  [Interlocutors.ALIENSHIP]: {
    name: 'Корабль пришельцев',
    img: Ufo,
    imgModal: UfoChat,
  },
};

export default interlocutors;
