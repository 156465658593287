/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const scenario_3_4_i: Scenario = {
  scenarioId: SceneId.scenario_3_4_i,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Космодром',
    text: `Итак, вы на космодроме. Для начала нужно проверить, появился ли Меркурий на своём месте в галактике.
      Где это можно сделать?
      `,
    buttons: ['Закрыть'],
  },
};

export const scenario_3_4: Scenario = {
  scenarioId: SceneId.scenario_3_4,
  type: ScenarioType.chat,
  data: {
    title: 'Космодром',
    scene_id: 'scenario_3_4',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.SYSTEM,
        id: 'Q1',
        text: `Итак, вы на космодроме. Для начала нужно проверить, появился ли Меркурий на своём месте в галактике.
        Где это можно сделать?
        `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'В обсерватории «Спектр-РГ»',
            nextStep: 'Q1_1',
          },
          {
            id: 'b2',
            text: 'В стартовом комплексе',
            nextStep: 'Q1_2',
          },
          {
            id: 'b3',
            text: 'На аэродроме',
            nextStep: 'Q1_3',
          },
        ],
      },
      Q1_2: {
        person: Interlocutors.SYSTEM,
        id: 'Q1_2',
        text: `Стартовый комплекс предназначен для запуска ракет, посмотреть на положение планет здесь не получится. Подумай ещё.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Выбрать другую локацию',
            nextStep: 'Q1',
          },
        ],
      },
      Q1_3: {
        person: Interlocutors.SYSTEM,
        id: 'Q1_3',
        text: `На аэродроме стоят самолёты, слышен шум турбин — здесь вряд ли получится проверить положение планет. Подумай ещё!`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Выбрать другую локацию',
            nextStep: 'Q1',
          },
        ],
      },
      Q1_1: {
        person: Interlocutors.SYSTEM,
        id: 'Q1_1',
        text: `Ну конечно, обсерватория! Где ещё, если не здесь посмотреть на звезды и планеты. 

        Теперь нужно выбрать верные координаты.
        `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Млечный путь, Солнечная система, прямое восхождение 4ч 6м 55с, Склонение +23‪° 28′ 45″',
            nextStep: 'Q1_1_1',
          },
          {
            id: 'b2',
            text: 'Туманность Андромеды, Сферическая подсистема, NGC 7662, C 22',
            nextStep: 'Q1_1_2',
          },
          {
            id: 'b3',
            text: 'Млечный путь, Солнечная система, прямое восхождение 21ч 45м 26с,склонение -14‪° 34′ 52″',
            nextStep: 'Q1_1_3',
          },
        ],
      },
      Q1_1_2: {
        person: Interlocutors.SYSTEM,
        id: 'Q1_1_2',
        text: `Твоему вниманию предстаёт планетарная туманность Голубой Снежок. Кажется, нужно выбрать другие координаты.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Попробовать другие координаты',
            nextStep: 'Q1_1',
          },
        ],
      },
      Q1_1_3: {
        person: Interlocutors.SYSTEM,
        id: 'Q1_1_3',
        text: `Ты в Солнечной системе, но перед тобой планета с большими кольцами — Сатурн. Тебе почти удалось, попробуй немного скорректировать координаты.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Попробовать другие координаты',
            nextStep: 'Q1_1',
          },
        ],
      },
      Q1_1_1: {
        person: Interlocutors.SYSTEM,
        id: 'Q1_1_1',
        text: `Ура, координаты верны! Ты видишь, как огромный Меркурий переливается красно-золотыми прожилками. Планета заняла своё место рядом с Солнцем. 

        Теперь нужно восстановить связь со спутником.
        `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Перейти в стартовый комплекс',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        person: Interlocutors.SYSTEM,
        id: 'Q2',
        text: `Ты попадаешь к уже знакомому тебе пульту управления полётами. Ракета запущена на орбиту. Выйди на связь с космонавтом и восстанови сообщение со спутником. Выбери верную линию связи.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Союз, Прогресс МС-19',
            nextStep: 'Q2_1',
          },
          {
            id: 'b2',
            text: 'Ангара, Космодром - ОБ - Иннополис',
            nextStep: 'Q2_2',
          },
        ],
      },
      Q2_1: {
        person: Interlocutors.SYSTEM,
        id: 'Q2_1',
        text: `Это линия связи с ракетой, доставляющей грузы на МКС. Здесь одежда, еда для космонавтов, аппаратура для научно-прикладных исследований и экспериментов.
        Попробуй другую линию связи.
        `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Выбрать другую линию связи',
            nextStep: 'Q2',
          },
        ],
      },
      Q2_2: {
        person: Interlocutors.SYSTEM,
        id: 'Q2_2',
        text: `«Земля, Ангара на связи», — слышишь ты. 
        Ура, связь установлена.
        «Земля, жду точку доступа с ОБ, переключите линию контроллера на частоту: Космодром - ОБ - Иннополис».
        
        Время отправиться в Останкинскую башню.
        `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться в Останкинскую башню',
            nextStep: EXIT_ID,
          },
        ],
      },

      //       Q1: {
      //         person: Interlocutors.PETROV,
      //         id: 'Q1',
      //         text: 'Обожаю эту часть «Большой перемены»! 😍',
      //         type: StepType.question,
      //         maxAnswers: 1,
      //         buttons: [
      //           {
      //             id: 'b1',
      //             text: 'А где это мы? Кажется, я здесь еще не [[бывал/бывала]]',
      //             nextStep: 'Q2/0',
      //           },
      //         ],
      //       },
      //       ...splitStep({
      //         person: Interlocutors.PETROV,
      //         id: 'Q2',
      //         text: `Перед нами точная копия Херсонеса — города, основанного древними греками на юго-западном побережье Крыма в V веке до н. э. Это почти 2500 лет назад! Херсонес — единственный античный полис Северного Причерноморья, городская жизнь в котором непрерывно поддерживалась вплоть до конца XIV века.

      // Полисами в Древней Греции называли города с особой формой общественного устройства. В них все граждане имели определенные права и обязанности. А еще в полисах впервые появилась концепция равенства граждан перед законом, а важнейшие решения принимались с участием всех граждан мужского пола.`,
      //         type: StepType.question,
      //         maxAnswers: 1,
      //         buttons: [
      //           {
      //             id: 'b1',
      //             text: 'Древние греки были очень продвинутыми!?',
      //             nextStep: 'Q3',
      //           },
      //         ],
      //       }),
      //       Q3: {
      //         person: Interlocutors.PETROV,
      //         id: 'Q3',
      //         text:
      //           'Непросто продвинутыми. Многие полисы имели демократический уклад, в том числе и Херсонес. Он принимал активное участие в общегреческих праздниках, спортивных состязаниях и вел активную внешнюю политику. ',
      //         type: StepType.question,
      //         maxAnswers: 1,
      //         buttons: [
      //           {
      //             id: 'b1',
      //             text: 'А что это за странные квадратные развалины?',
      //             nextStep: 'Q4',
      //           },
      //         ],
      //       },
      //       Q4: {
      //         person: Interlocutors.PETROV,
      //         id: 'Q4',
      //         text:
      //           'Это частично сохранившиеся руины жилых усадеб. В усадьбах велось земледелие, выращивались виноград и злаковые.',
      //         type: StepType.question,
      //         maxAnswers: 1,
      //         buttons: [
      //           {
      //             id: 'b1',
      //             text:
      //               'Если 2500 лет назад здесь жили древние греки, как же Херсонес стал частью нашей страны?',
      //             nextStep: 'Q5/0',
      //           },
      //         ],
      //       },
      //       ...splitStep({
      //         person: Interlocutors.PETROV,
      //         id: 'Q5',
      //         text: `Дело в том, что в конце X века киевский князь Владимир после нескольких месяцев осады захватил город, и Херсонес стал частью Киевской Руси. Здесь же Владимир принял православие.

      // Видишь этот прекрасный собор? Он называется Владимирским и построен в XIX веке в память о крещении киевского князя Владимира. Вон там рядом с храмом стоит купель. По одной из версий именно в ней в 988 году крестили Владимира. Можно сказать, что с этого места началась православная история Руси.`,
      //         type: StepType.question,
      //         maxAnswers: 1,
      //         buttons: [
      //           {
      //             id: 'b1',
      //             text: 'Вот это да! Поистине историческое место!',
      //             nextStep: 'Q6',
      //           },
      //         ],
      //       }),
      //       Q6: {
      //         person: Interlocutors.PETROV,
      //         id: 'Q6',
      //         type: StepType.message,
      //         text: `Пусть у нас в «Большой перемене» лишь копия Херсонеса, но здесь все равно ощущается особая атмосфера 😊`,
      //         nextStep: 'Q7',
      //       },
      //       Q7: {
      //         person: Interlocutors.GRANDMOTHER,
      //         id: 'Q7',
      //         type: StepType.message,
      //         text: `Спасибо за такой увлекательный рассказ, Петров. Но давайте вернемся к расследованию. Ты считаешь, что кирку могли использовать археологи на раскопках в этом месте?`,
      //         nextStep: 'Q8',
      //       },
      //       Q8: {
      //         person: Interlocutors.PETROV,
      //         id: 'Q8',
      //         text:
      //           'Именно! Посмотрите на те огороженные флажками руины — это экспозиция, показывающая археологические раскопки, которые ведутся в настоящем Херсонесе в Севастополе. Там я и видел точно такую же кирку!',
      //         type: StepType.question,
      //         maxAnswers: 1,
      //         buttons: [
      //           {
      //             id: 'b1',
      //             text: 'Давайте подойдем поближе к экспозиции и рассмотрим место повнимательнее…',
      //             nextStep: 'Q9',
      //           },
      //         ],
      //       },
      //       Q9: {
      //         person: Interlocutors.SYSTEM,
      //         id: 'Q9',
      //         type: StepType.message,
      //         text: `Вы вместе с Бабулей и Петровым подходите к экспозиции археологических раскопок и заглядываете в яму...`,
      //         nextStep: 'Q10',
      //       },
      //       Q10: {
      //         person: Interlocutors.GRANDMOTHER,
      //         id: 'Q10',
      //         type: StepType.message,
      //         text: `Ооо, ребята, посмотрите-ка, что это здесь? Непохоже, что эта странная штуковина — часть экспозиции! Она больше напоминает какой-то современный аппарат. Наподобие тех, что мастеришь ты, Петров...🤔`,
      //         nextStep: 'Q11',
      //       },
      //       Q11: {
      //         person: Interlocutors.PETROV,
      //         id: 'Q11',
      //         text:
      //           'Так это же моя дым-машина! Наконец-то она нашлась! И что она здесь вообще делает? 😱',
      //         type: StepType.question,
      //         maxAnswers: 1,
      //         buttons: [
      //           {
      //             id: 'b1',
      //             text: 'Какая еще дым-машина?',
      //             nextStep: 'Q12/0',
      //           },
      //           {
      //             id: 'b2',
      //             text: 'Петров, опять твои странные изобретения!',
      //             nextStep: 'Q12/0',
      //           },
      //         ],
      //       },
      //       ...splitStep({
      //         person: Interlocutors.PETROV,
      //         id: 'Q12',
      //         type: StepType.message,
      //         text: `Однажды ко мне пришел Сан Саныч и рассказал, что мечтает устроить в «Большой перемене» шоу ледяных скульптур. А у нас же здесь всегда ясно, тепло и светит солнце. Вот Сан Саныч и поручил мне изобрести машину, которая могла бы поддерживать лед в замороженном состоянии.

      // И я сконструировал эту дым-машину. Она генерирует ледяной дым. Вот только я не успел все как следует доделать — машина исчезла из моей лаборатории! Где я ее только ни искал! А потом на парк опустился туман и начались все наши неприятности… Тогда уже не до поисков стало…

      // Так что же это получается? Все из-за моей дым-машины?!! 😱

      // Но постойте, из нее торчит что-то красное… Похоже на пропавшую из поезда ручку стоп-крана. Надо ее вытащить…`,
      //         nextStep: 'Q13/0',
      //       }),
      //       ...splitStep({
      //         person: Interlocutors.SYSTEM,
      //         id: 'Q13',
      //         type: StepType.message,
      //         text: `Петров спрыгнул в яму к машине, потянулся к красной ручке, как ВДРУГ раздался пронзительный крик: «Стой! Не делай этого!».

      // Из-за пушки показалось СИНЕЕ МОХНАТОЕ СУЩЕСТВО...`,
      //         nextStep: EXIT_ID,
      //       }),
      [EXIT_ID]: {
        person: Interlocutors.SYSTEM,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.SYSTEM,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
