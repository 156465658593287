import styled from 'styled-components';
import { BaseStyled } from '../../styles/styledBase';
import { BoxProps } from './Types';

export const BoxStyled = styled(BaseStyled)<BoxProps>`
  display: flex;
  width: ${(props) => (props.$isFullWidth ? '100%' : 'auto')};
  flex-direction: ${(props) => props.$direction};
  flex-wrap: ${(props) => (props.$isWrap ? 'wrap' : 'no-wrap')};
  justify-content: ${(props) => props.$justify};
  align-items: ${(props) => props.$align};
  height: ${(props) => props.$height};
`;
