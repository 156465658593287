/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg2_intro_1_1: Scenario = {
  scenarioId: SceneId.stg2_intro_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: '',
    scene_id: 'stg2_intro_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Как хорошо, что мы вовремя вернулись в парк! У нас столько дел! Помнишь, что у Петрова завис анализатор? Так вот, Петров, кажется, его починил и результаты анализов готовы!

        А ещё это странное существо пропало. Нужно найти его и понять, наконец, что происходит в парке!`,
        id: 'Q1',
        person: Interlocutors.CAT,
        type: StepType.message,
        nextStep: 'Q2',
      },

      Q2: {
        text: `Петров уже начал ломать голову над тем, как бы все здесь разморозить и очень надеется на твою помощь!`,
        id: 'Q2',
        person: Interlocutors.CAT,
        type: StepType.question,
        nextStep: 'Q3',
        maxAnswers: 1,
        callToAction: '[[Готов/Готова]] к новым приключениям?',
        buttons: [
          {
            id: 'b1',
            text: 'Да, мне уже не терпится начать!',
            nextStep: 'Q3',
          },
          {
            id: 'b2',
            text: 'А что без меня Петров не справится?',
            nextStep: 'Q3',
          },
        ],
      },

      Q3: {
        text: `Без твоей помощи никак не обойтись! Беги скорее к Петрову в Иннополис.`,
        id: 'Q1',
        person: Interlocutors.CAT,
        type: StepType.message,
        nextStep: EXIT_ID,
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
