/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import profileScreen from '../../../../../assets/images/profile_screen.jpg';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg3_bridgeRussianIsland_1_1: Scenario = {
  scenarioId: SceneId.stg3_bridgeRussianIsland_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Мост',
    scene_id: 'stg3_bridgeRussianIsland_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Ну как вам? Я делал некоторые расчёты для этого моста! Он, конечно, лишь макет, гораздо меньше оригинала, но мы старались 😬`,
        id: 'Q1',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Чудо инженерной мысли! А расскажи нам про мост, раз ракета пока не взлетает.',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        text: `Это точная копия моста на остров Русский. Оригинал моста находится в самой восточной точке России — городе Владивосток. Он соединяет материк с островом Русский, чтобы живущие на острове люди могли удобно добираться до большой земли. 

        Мост на остров Русский внесен в Книгу рекордов Гиннеса, как самый длинный вантовый мост в мире. Длина пролёта оригинального моста составляет 1104 метра! А ещё он второй по высоте мост в мире — более 320 метров. И ты даже [[мог/могла]] видеть его на российской купюре номиналом 2000 рублей.
        
        Наш мост старается не уступать оригиналу и соединяет «Большую перемену» с окружающим миром.`,
        id: 'Q2',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ого! Действительно великолепный мост.',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        text: `Ой-ой! Смотрите! Взлетает!`,
        id: 'Q3',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ух-ты-ы!',
            nextStep: 'Q4',
          },
        ],
      },

      Q4: {
        text: `Вдалеке на космодроме из сопел ракеты вылетают струи пламени, и она отрывается от земли. 

        Ракета быстро набирает высоту и скрывается за облаками. Через некоторое время зелёное свечение корабля пришельцев затухает — Квилл и его сородичи улетели домой.
        
        `,
        id: 'Q4',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q5',
      },

      Q5: {
        text: `Ну вот и закончилось наше инопланетное расследование, ребята.

        {{name}}, Вжух, вы большие молодцы — умудрились парк спасти и не растеряться при встрече с пришельцами, не бросили Квилла в беде! И главное, поняли, что отзывчивость и доброта — языки межпланетные и всем видам понятные.
        
        Всегда теперь, когда взгляну на звёзды, буду вспоминать это приключение и улыбаться. А оттуда, издалека, нам будет улыбаться Квилл.`,
        id: 'Q5',
        person: Interlocutors.PETROV,
        type: StepType.message,
        nextStep: 'Q5_1',
      },
      Q5_1: {
        text: `{{NAME}},  благодаря твоей помощи, смекалке и отваге, парк «Большая перемена» полностью восстановлен и готов к приему посетителей 😻

        Баллы за дистанционный этап Конкурса будут размещены в твоем <a href="https://game.bolshayaperemena.online/" target="_blank">профиле</a> в игре. После авторизации в личном кабинете не забудь зайти в игру, чтобы увидеть баллы:`,
        id: 'Q5_1',
        person: Interlocutors.CAT,
        type: StepType.message,
        nextStep: 'Q5_2',
      },
      Q5_2: {
        text: '',
        id: 'Q5_2',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        image: { uri: profileScreen, title: '' },
        buttons: [
          {
            id: 'b1',
            text: 'Здорово!',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
