import { SceneId } from '../../types/scenario';

export enum ID {
  // Остановки
  stop_1,

  // Знакомство
  start,
  catWelcome,
  cityPresentation,
  welcomeTestIntro,
  welcomeTest,

  /**
   * Первый этап
   */
  // Первый акт
  welcomeTeacherIntro,
  welcomeTeacher,
  welcomeTeacherComplete,
  welcomePetrovIntro,
  welcomePetrov,
  welcomePetrovComplete,
  welcomeEntrepreneurIntro,
  welcomeEntrepreneur,
  welcomeEntrepreneurComplete,

  HersonesGrandmotherWelcome,
  baggageIntro,
  baggage,
  baggageComplete,
  welcomeGrandmotherIntro,
  welcomeGrandmotherComplete,
  welcomeGrandmother,

  // Второй акт
  catFlaskHelpIntro,
  catFlaskHelp,
  welcomeGrandMomIntro,
  welcomeGrandMom,
  welcomeGrandMomComplete,
  sochiIntro,
  sochiInfo,
  sochiInfoComplete,
  mguSecondAct,
  mguSecondActComplete,
  mguGardenSecondAct,
  mguGardenSecondActComplete,
  ostankinoNobody,
  hermitageSecondAct,
  hermitageSecondActComplete,

  // Третий акт
  mguTeacherThirdAct,
  mguTeacherThirdActComplete,
  moscowCityThirdAct,
  moscowCityThirdActComplete,
  patriotThirdAct,
  patriotThirdActComplete,

  grandMomThirdAct,
  fogIntro,
  teacherLost,
  helpTeacher,
  getRopeStair,
  teacherLastTest,
  ostankinoFirstStageFinal,
  spaceFirstStageFinal,
  lakeFirstStageFinal,
  sochiFirstStageFinal,

  fictive_StageCompleted,

  /**
   * Второй этап
   */
  // Первый акт
  stage2_act1_innopoliceIntro,
  stage2_act1_innopolice,
  stage2_act1_archIntro,
  stage2_act1_arch,
  stage2_act1_motherIntro,
  stage2_act1_mother,

  // Второй акт
  stage2_act2_presentation,
  stage2_act2_cosmodromIntro,
  stage2_act2_cosmodrom,

  stage2_act2_ostankinoIntro,
  stage2_act2_ostankino,

  stage2_act2_innopolice,

  // Третий акт
  stage2_act3_baikalIntro,
  stage2_act3_baikal,
  stage2_act3_bridgeIntro,
  stage2_act3_bridge,
  stage2_act3_forestIntro,
  stage2_act3_forest,

  // secondStageIntro,
  // catIntroSecondStage,
  // innopoliceIntro,
  // petrovSchemaRequest,
  // rocketLost,
  // spaceSafeOpening,
  // giveSchema,
  // hermitageIntro,
  // hermitagePictureSave,

  mguEmpty2_act1,
  spaceEmpty2_act1,
  lakeEmpty2_act1,
  ostankinoEmpty2_act1,
  sochiEmpty2_act1,
  hermitageEmpty2_act1,
  innopoliceEmpty2_act1,
  stage2_act1Completed,

  // Второй акт
  stage2Act2Announce,
  stage2Act2AnnounceInnopolice,
  stage2Act2AnnounceCatToIsland,
  altruism2Announce,
  altruism2GrandmotherRoad,
  bridgeBoxHelpAnnounce,
  bridgeBoxHelp,
  moscowCityAnnounce,
  moscowCityTest,
  moscowCityTestResult,
  twoDetailsReceived,
  innopoliceNeedMoreDetails,
  allDetailsReceived,
  trainEggAnnounce,
  trainEgg,
  innopoliceDetailEgg,
  innopoliceGiveDetails,
  grandmotherFriendAltruismAnnounce,
  grandmotherFriendAltruism,
  newRocketAnnounce,
  newRocketStart,
  stage2act2Completed,

  bridgeComplete2act,
  trainComplete2act,
  innopoliceComplete2act,
  moscowCityComplete2act,
  spaceComplete2act,
  space2act,

  // Третий акт
  stage2act3Announce,
  catParadeInfo,
  kurganInfo,
  kurganQuest,
  moscowCityCinemaHelpAnnounce,
  moscowCityCinemaHelp,

  hermitageEgg,
  ostamnkinoEgg,
  ostamnkinoEgg_1,
  lakeEgg,
  mguEgg,
  mguEgg_1,
  innopoliceAct3Finish,
  spaceTermoGunWorking,
  innopoliceNeedCoins,
  fictive_SecondStageCompleted,

  // Третий этап
  mguStage3,
  ostankinoStage3,
  spaceStage3,
  lakeStage3,
  sochiStage3,
  innopoliceStage3,
  hermitageStage3,
  bridgeStage3,
  moscowCityStage3,
  trainStage3,
  kurganStage3,
  bridgeBlueSteps,
  hersonesStage3,
  hersonesBlueSteps,

  stage3_act1_innopoliceIntro,
  stage3_act1_innopolice,
  stage3_act1_hermitageIntro,
  stage3_act1_hermitage,
  stage3_act1_moscowIntro,
  stage3_act1_moscow,

  stage3_act2_cosmodromIntro,
  stage3_act2_cosmodrom,
  stage3_act2_ostankinoIntro,
  stage3_act2_ostankino,
  stage3_act2_mguIntro,
  stage3_act2_mgu,

  stage3_act3_hersonesIntro,
  stage3_act3_hersones,

  stage3Start,
  //stage3StartInnopolice,
  //stage3_hermitageIntro,
  //stage3_hermitage,

  innopolicePickCheckInfo,
  innopolicePickCheck,
  hersonesInfo,
  hersonesQuest,

  stage3Act1Complete,

  // акт 2
  stage3Act2Start,
  hersonesContinue,
  moscowCityCafeInfo,
  moscowCityCafe,
  innopoliceContinueStoryInfo,
  innopoliceContinueStory,
  spaceContinueStoryInfo,
  spaceContinueStory,
  trainContinueStoryInfo,
  trainContinueStory,
  patriotInfo,
  patriotStopHandle,
  trainRepairHandleInfo,
  trainRepairHandle,
  archInfo,
  archQuest,
  innopoliceStage3Act2FinalInfo,
  innopoliceStage3Act2Final,
  stage3Act2Final,

  // акт 3
  startStage3Act3,
  welcomeSofia,
  entrepreneurDucksInfo,
  entrepreneurDucks,
  aboutInfo,
  waitTo21may,
  challengesAnnounce,
  aboutChallenges,
  finish,
}
export const to: Record<any, SceneId> = {
  [ID.stop_1]: SceneId.scenario_stop_1,

  [ID.start]: SceneId.scenario_0,
  [ID.catWelcome]: SceneId.scenario_1_1,
  [ID.cityPresentation]: SceneId.scenario_1_2,
  [ID.welcomeTestIntro]: SceneId.scenario_1_3_1,
  [ID.welcomeTest]: SceneId.scenario_1_3,

  //[ID.welcomeTeacherIntro]: SceneId.scenario_1_7_0,
  [ID.HersonesGrandmotherWelcome]: SceneId.scenario_1_7_0,

  [ID.welcomeTeacher]: SceneId.scenario_1_7,
  [ID.welcomeTeacherComplete]: SceneId.scenario_1_7_1,
  [ID.baggageIntro]: SceneId.scenario_1_8_0,
  [ID.baggage]: SceneId.scenario_1_8,
  [ID.baggageComplete]: SceneId.scenario_1_8_1,

  [ID.welcomeGrandmotherIntro]: SceneId.scenario_1_8_4,
  [ID.welcomeGrandmother]: SceneId.scenario_1_8_5,
  [ID.welcomeGrandmotherComplete]: SceneId.scenario_1_8_6,

  [ID.welcomeEntrepreneurIntro]: SceneId.scenario_1_9_0,
  [ID.welcomeEntrepreneur]: SceneId.scenario_1_9,
  [ID.welcomeEntrepreneurComplete]: SceneId.scenario_1_9_1,

  [ID.catFlaskHelpIntro]: SceneId.scenario_1_14_0,
  [ID.catFlaskHelp]: SceneId.scenario_1_14,
  [ID.welcomeGrandMomIntro]: SceneId.scenario_1_17_0,
  [ID.welcomeGrandMom]: SceneId.scenario_1_17,
  [ID.welcomeGrandMomComplete]: SceneId.scenario_1_17_3,

  [ID.sochiIntro]: SceneId.scenario_1_18_0,
  [ID.sochiInfo]: SceneId.scenario_1_18,
  [ID.sochiInfoComplete]: SceneId.scenario_1_18_1,
  [ID.mguSecondAct]: SceneId.scenario_1_19,
  [ID.mguSecondActComplete]: SceneId.scenario_1_19_1,
  [ID.hermitageSecondAct]: SceneId.scenario_1_19_4,
  [ID.hermitageSecondActComplete]: SceneId.scenario_1_19_5,
  [ID.mguGardenSecondAct]: SceneId.scenario_1_19_6,
  [ID.mguGardenSecondActComplete]: SceneId.scenario_1_19_7,
  [ID.ostankinoNobody]: SceneId.scenario_1_9_2,

  [ID.mguTeacherThirdAct]: SceneId.scenario_1_23,
  [ID.mguTeacherThirdActComplete]: SceneId.scenario_1_24,

  [ID.moscowCityThirdAct]: SceneId.scenario_1_24_3,
  [ID.moscowCityThirdActComplete]: SceneId.scenario_1_24_4,

  [ID.patriotThirdAct]: SceneId.scenario_1_24_5,
  [ID.patriotThirdActComplete]: SceneId.scenario_1_24_6,

  //[ID.grandMomThirdAct]: SceneId.scenario_1_23,
  [ID.fogIntro]: SceneId.scenario_1_25_0,
  [ID.teacherLost]: SceneId.scenario_1_25,
  [ID.helpTeacher]: SceneId.scenario_1_25_1,
  [ID.getRopeStair]: SceneId.scenario_1_25_2,
  [ID.teacherLastTest]: SceneId.scenario_1_25_4,
  [ID.ostankinoFirstStageFinal]: SceneId.scenario_1_26_1,
  [ID.lakeFirstStageFinal]: SceneId.scenario_1_26_2,
  [ID.spaceFirstStageFinal]: SceneId.scenario_1_26_3,
  [ID.sochiFirstStageFinal]: SceneId.scenario_1_26_4,

  [ID.fictive_StageCompleted]: SceneId.scenario_1_30,

  [ID.stage2_act1_innopoliceIntro]: SceneId.scenario_2_1_i,
  [ID.stage2_act1_innopolice]: SceneId.scenario_2_1,
  [ID.stage2_act1_archIntro]: SceneId.scenario_2_2_i,
  [ID.stage2_act1_arch]: SceneId.scenario_2_2,
  [ID.stage2_act1_motherIntro]: SceneId.scenario_2_3_i,
  [ID.stage2_act1_mother]: SceneId.scenario_2_3,

  [ID.stage2_act2_presentation]: SceneId.scenario_2_4_0,
  [ID.stage2_act2_cosmodromIntro]: SceneId.scenario_2_4_i,
  [ID.stage2_act2_cosmodrom]: SceneId.scenario_2_4,
  [ID.stage2_act2_ostankinoIntro]: SceneId.scenario_2_5_i,
  [ID.stage2_act2_ostankino]: SceneId.scenario_2_5,
  [ID.stage2_act2_innopolice]: SceneId.scenario_2_6,

  [ID.stage2_act3_baikalIntro]: SceneId.scenario_2_7_i,
  [ID.stage2_act3_baikal]: SceneId.scenario_2_7,
  [ID.stage2_act3_bridgeIntro]: SceneId.scenario_2_8_i,
  [ID.stage2_act3_bridge]: SceneId.scenario_2_8,
  [ID.stage2_act3_forestIntro]: SceneId.scenario_2_9_i,
  [ID.stage2_act3_forest]: SceneId.scenario_2_9,

  // [ID.secondStageIntro]: SceneId.scenario_2_0,
  // [ID.catIntroSecondStage]: SceneId.scenario_2_0_1,
  // [ID.innopoliceIntro]: SceneId.scenario_2_1,
  // [ID.petrovSchemaRequest]: SceneId.scenario_2_1_1,
  // [ID.rocketLost]: SceneId.scenario_2_2,
  // [ID.spaceSafeOpening]: SceneId.scenario_2_2_1,
  // [ID.giveSchema]: SceneId.scenario_2_2_4,

  // [ID.hermitageIntro]: SceneId.scenario_2_4_0,
  // [ID.hermitagePictureSave]: SceneId.scenario_2_4,

  [ID.innopoliceEmpty2_act1]: SceneId.scenario_2_5_1,
  [ID.spaceEmpty2_act1]: SceneId.scenario_2_5_2,
  [ID.hermitageEmpty2_act1]: SceneId.scenario_2_5_3,
  [ID.mguEmpty2_act1]: SceneId.scenario_2_5_4,
  [ID.lakeEmpty2_act1]: SceneId.scenario_2_5_5,
  [ID.sochiEmpty2_act1]: SceneId.scenario_2_5_6,
  [ID.ostankinoEmpty2_act1]: SceneId.scenario_2_5_7,
  [ID.stage2_act1Completed]: SceneId.scenario_2_5,

  [ID.stage2Act2Announce]: SceneId.scenario_2_8,
  [ID.stage2Act2AnnounceInnopolice]: SceneId.scenario_2_9,
  //[ID.stage2Act2AnnounceCatToIsland]: SceneId.scenario_2_9_1,
  [ID.altruism2Announce]: SceneId.scenario_2_10_0,
  [ID.altruism2GrandmotherRoad]: SceneId.scenario_2_10,
  [ID.bridgeBoxHelpAnnounce]: SceneId.scenario_2_10_1_0,
  [ID.bridgeBoxHelp]: SceneId.scenario_2_10_1,
  [ID.moscowCityAnnounce]: SceneId.scenario_2_11_0,
  [ID.moscowCityTest]: SceneId.scenario_2_11,
  [ID.moscowCityTestResult]: SceneId.scenario_2_11_4,
  [ID.twoDetailsReceived]: SceneId.scenario_2_11_5,
  [ID.allDetailsReceived]: SceneId.scenario_2_12,
  [ID.trainEggAnnounce]: SceneId.scenario_egg1_0,
  [ID.trainEgg]: SceneId.scenario_egg1,
  [ID.innopoliceDetailEgg]: SceneId.scenario_egg2_0,
  [ID.innopoliceGiveDetails]: SceneId.scenario_2_13,
  [ID.grandmotherFriendAltruismAnnounce]: SceneId.scenario_2_14_0,
  [ID.grandmotherFriendAltruism]: SceneId.scenario_2_14,
  [ID.newRocketAnnounce]: SceneId.scenario_2_15_0,
  [ID.newRocketStart]: SceneId.scenario_2_15,
  [ID.stage2act2Completed]: SceneId.scenario_2_16,
  [ID.innopoliceNeedMoreDetails]: SceneId.scenario_2_9_2,
  [ID.bridgeComplete2act]: SceneId.scenario_2_16_1,
  [ID.trainComplete2act]: SceneId.scenario_2_16_2,
  [ID.innopoliceComplete2act]: SceneId.scenario_2_16_3,
  [ID.moscowCityComplete2act]: SceneId.scenario_2_16_4,
  [ID.spaceComplete2act]: SceneId.scenario_2_16_5,
  [ID.space2act]: SceneId.scenario_2_16_0_5,

  [ID.stage2act3Announce]: SceneId.scenario_2_20,
  [ID.catParadeInfo]: SceneId.scenario_2_20_1,
  [ID.kurganInfo]: SceneId.scenario_2_21,
  [ID.kurganQuest]: SceneId.scenario_2_21_1,
  [ID.moscowCityCinemaHelpAnnounce]: SceneId.scenario_2_22,
  [ID.moscowCityCinemaHelp]: SceneId.scenario_2_22_1,
  [ID.hermitageEgg]: SceneId.scenario_egg3_0,
  [ID.ostamnkinoEgg]: SceneId.scenario_egg4_0,
  [ID.ostamnkinoEgg_1]: SceneId.scenario_egg4_1,
  [ID.lakeEgg]: SceneId.scenario_egg5_0,
  [ID.mguEgg]: SceneId.scenario_egg6_0,
  [ID.mguEgg_1]: SceneId.scenario_egg6_1,
  [ID.innopoliceAct3Finish]: SceneId.scenario_2_23,
  [ID.spaceTermoGunWorking]: SceneId.scenario_2_16_1_5,
  [ID.innopoliceNeedCoins]: SceneId.scenario_2_16_1_3,
  [ID.fictive_SecondStageCompleted]: SceneId.scenario_2_30,

  [ID.mguStage3]: SceneId.scenario_3_0_1,
  [ID.ostankinoStage3]: SceneId.scenario_3_0_2,
  [ID.spaceStage3]: SceneId.scenario_3_0_3,
  [ID.lakeStage3]: SceneId.scenario_3_0_4,
  [ID.sochiStage3]: SceneId.scenario_3_0_5,
  [ID.hermitageStage3]: SceneId.scenario_3_0_6,
  [ID.bridgeStage3]: SceneId.scenario_3_0_7,
  [ID.moscowCityStage3]: SceneId.scenario_3_0_9,
  [ID.trainStage3]: SceneId.scenario_3_0_10,
  [ID.kurganStage3]: SceneId.scenario_3_0_8,
  [ID.innopoliceStage3]: SceneId.scenario_3_0_12,
  [ID.bridgeBlueSteps]: SceneId.scenario_3_0_11,
  [ID.hersonesBlueSteps]: SceneId.scenario_3_0_13,
  [ID.hersonesStage3]: SceneId.scenario_3_0_14,

  [ID.stage3_act1_innopoliceIntro]: SceneId.scenario_3_1_i,
  [ID.stage3_act1_innopolice]: SceneId.scenario_3_1,
  [ID.stage3_act1_hermitageIntro]: SceneId.scenario_3_2_i,
  [ID.stage3_act1_hermitage]: SceneId.scenario_3_2,
  [ID.stage3_act1_moscowIntro]: SceneId.scenario_3_3_i,
  [ID.stage3_act1_moscow]: SceneId.scenario_3_3,

  [ID.stage3_act2_cosmodromIntro]: SceneId.scenario_3_4_i,
  [ID.stage3_act2_cosmodrom]: SceneId.scenario_3_4,
  [ID.stage3_act2_ostankinoIntro]: SceneId.scenario_3_5_i,
  [ID.stage3_act2_ostankino]: SceneId.scenario_3_5,
  [ID.stage3_act2_mguIntro]: SceneId.scenario_3_6_i,
  [ID.stage3_act2_mgu]: SceneId.scenario_3_6,

  [ID.stage3_act3_hersonesIntro]: SceneId.scenario_3_7_i,
  [ID.stage3_act3_hersones]: SceneId.scenario_3_7,

  [ID.stage3Start]: SceneId.scenario_3_0,
  //[ID.stage3StartInnopolice]: SceneId.scenario_3_1,

  [ID.innopolicePickCheckInfo]: SceneId.scenario_3_3_0,
  [ID.hersonesInfo]: SceneId.scenario_3_4_0,
  [ID.hersonesQuest]: SceneId.scenario_3_4,

  [ID.stage3Act1Complete]: SceneId.scenario_3_5,

  [ID.stage3Act2Start]: SceneId.scenario_3_10,
  [ID.hersonesContinue]: SceneId.scenario_3_11,
  [ID.moscowCityCafeInfo]: SceneId.scenario_3_12_0,
  [ID.moscowCityCafe]: SceneId.scenario_3_12,
  [ID.innopoliceContinueStoryInfo]: SceneId.scenario_3_13_0,
  [ID.innopoliceContinueStory]: SceneId.scenario_3_13,
  [ID.spaceContinueStoryInfo]: SceneId.scenario_3_14_0,
  [ID.spaceContinueStory]: SceneId.scenario_3_14,
  [ID.trainContinueStoryInfo]: SceneId.scenario_3_15_0,
  [ID.trainContinueStory]: SceneId.scenario_3_15,
  [ID.patriotInfo]: SceneId.scenario_3_16_0,
  [ID.patriotStopHandle]: SceneId.scenario_3_16,
  [ID.trainRepairHandleInfo]: SceneId.scenario_3_17_0,
  [ID.trainRepairHandle]: SceneId.scenario_3_17,
  [ID.archInfo]: SceneId.scenario_3_18_0,
  [ID.archQuest]: SceneId.scenario_3_18,
  [ID.innopoliceStage3Act2FinalInfo]: SceneId.scenario_3_19_0,
  [ID.innopoliceStage3Act2Final]: SceneId.scenario_3_19,
  [ID.stage3Act2Final]: SceneId.scenario_3_19_1,

  [ID.startStage3Act3]: SceneId.scenario_3_20,
  [ID.welcomeSofia]: SceneId.scenario_3_21,
  [ID.entrepreneurDucksInfo]: SceneId.scenario_3_22,
  [ID.entrepreneurDucks]: SceneId.scenario_3_22_1,
  [ID.aboutInfo]: SceneId.scenario_3_23_0,
  [ID.waitTo21may]: SceneId.scenario_3_23_01,
  [ID.challengesAnnounce]: SceneId.scenario_3_23,
  [ID.aboutChallenges]: SceneId.scenario_3_23_1,
  [ID.finish]: SceneId.scenario_3_24,
};
