/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../scenario';
import interlocutors from '../../../interlocutors';

export const scenario_egg4_0: Scenario = {
  scenarioId: SceneId.scenario_egg4_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: 'Башня Останкино',
    person: Interlocutors.SYSTEM,
    text: `Вы натыкаетесь на Вжуха, играющего с бабочками..`,
    buttons: ['Закрыть'],
  },
};

export const scenario_egg4_1: Scenario = {
  scenarioId: SceneId.scenario_egg4_1,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `Только посмотри, сколько здесь разноцветных бабочек! Обожаю трясти ветки, чтобы бабочки разлетались в разные стороны и повсюду порхали 😻`,
    buttons: ['Закрыть'],
  },
};
