/* eslint-disable max-len */
import {
  Conditions,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../types/scenario';
import interlocutors from '../../interlocutors';
import { splitStep } from '../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT_ID';

const TK_TEST_1: TestStep = {
  id: 'TK',
  person: Interlocutors.CAT,
  type: StepType.test,
  external_id: 'test-tvoi-kod-junior',
  path: 'test-tvoi-kod-junior-210401.json',
  name: 'Твой Код 1',
  order: [
    {
      description: `Сейчас будет четыре варианта. Хотя бы один из них нужно отметить «Точно да» и один «Определенно нет». А вот и первый вопрос:`,
      subtest: 'T1',
      question: 'Q1',
    },
    {
      description:
        'Отлично! Идем дальше. В этом вопросе, как и в прошлом, нужно выбрать четыре варианта ответа. Хотя бы один надо отметить «Точно да» и один «Определенно нет».',
      subtest: 'T1',
      question: 'Q2',
    },
    {
      description:
        'Ничего сложного, правда? Продолжай отвечать так же: из четырех вариантов хотя бы один «Точно да» и один «Определенно нет»',
      subtest: 'T1',
      question: 'Q3',
    },
    {
      description:
        'Очень приятно узнать тебя поближе. Следующий вопрос и все те же условия: из четырех вариантов минимум один «Точно да» и один «Определенно нет».',
      subtest: 'T1',
      question: 'Q4',
    },
    {
      description:
        'О, абсолютно с тобой согласен! Я бы тоже выбрал такие же варианты. Продолжим с вопросами. Этот ничем не отличается от предыдущих: из четырех вариантов хотя бы один «Точно да» и один «Определенно нет».',
      subtest: 'T1',
      question: 'Q5',
    },
    {
      description:
        'Ты очень интересный человек! Здесь отвечай аналогично: из четырех минимум один вариант «Точно да» и один «Определенно нет».',
      subtest: 'T1',
      question: 'Q6',
    },
    {
      description:
        'А я вот никогда не бывал на физкультуре, котам не очень-то это положено. Вопросов осталось совсем немного. Сейчас тоже выбирай хотя бы один «Точно да» и один «Определенно нет». Вариантов все так же четыре.',
      subtest: 'T1',
      question: 'Q7',
    },
  ],
  nextStep: 'Q4',
};

export const scenario_1_3_1: Scenario = {
  scenarioId: SceneId.scenario_1_3_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `{{NAME}}, давай продолжим наше знакомство 😺

    Знаешь, я опрашиваю жителей парка и гостей, чтобы помочь всем восстановить память и понять, что произошло. Я бы хотел узнать больше и о тебе. Я подготовил несколько вопросов и ответов к ним. Выбери, пожалуйста, насколько тот или иной вариант тебе подходит.`,
    buttons: ['Пойдем'],
  },
};

export const scenario_1_3: Scenario = {
  scenarioId: SceneId.scenario_1_3,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'МГУ',
    scene_id: 'scenario_1_3',
    startId: 'Q2',
    steps: {
      Q2: {
        type: StepType.question,
        id: 'Q2',
        person: Interlocutors.CAT,
        maxAnswers: 1,
        text: `Отвечай «Точно да», если вариант про тебя и «Определенно нет», если на тебя это не похоже. А на случай трудностей есть вариант: «Не знаю». Не переживай, все просто😺`,
        buttons: [
          {
            id: 'b1',
            nextStep: 'TK',
            text: 'Хорошо',
          },
        ],
      },
      TK: TK_TEST_1, // ТЕСТ: Твой код (тип личности) 1
      Q4: {
        id: 'Q4',
        text: 'Как правильно я тебя выбрал! Именно ты сможешь нам помочь! О да, в парке ходили легенды, что однажды появится герой, который поможет парку, все считали это сказкой, но кажется это правда 😻',
        person: Interlocutors.CAT,
        condition: {
          type: Conditions.IS_VIDEO_ATTACHED,
          // чтобы убрать шаги с видеовизиткой
          variants: ['Q8/0', 'Q8/0'],
          // variants: ['Q8/0', 'Q5/0'],
        },
        nextStep: '',
        type: StepType.message,
      },
      // Тест на коммуникативность 1/3 - ссылка
      ...splitStep({
        person: Interlocutors.CAT,
        text: `Твоё лицо кажется мне знакомым, но я никак не могу вспомнить, где мы могли встречаться. Я и все жители парка потеряли память и не можем вспомнить, кто мы и чем мы занимались раньше. Кажется, в парке произошло что-то странное, и с этим нам предстоит разобраться. 
        А пока добавь файл или ссылку на видеовизитку, размещённую в социальной сети…        

В ней:
Расскажи о своих сильных сторонах.
Поведай о том, в каком направлении тебе хотелось бы совершенствоваться и почему.
Поделись своими самыми значимыми, на твой взгляд, достижениями (тем, чем ты гордишься).

Выложи видеовизитку, включи доступ по ссылке, проверь, что все работает.`,
        id: 'Q5',
        type: StepType.text_question,
        variable: 'LINK',
        callToAction: 'Отправь ссылку мне в чат',
        nextStep: 'Q6',
      }),
      Q6: {
        id: 'Q6',
        person: Interlocutors.CAT,
        condition: {
          type: Conditions.IS_LINK_VALID,
          variants: ['Q8/0', 'Q7'],
        },
        type: StepType.system,
        _meta: {},
      },
      Q7: {
        person: Interlocutors.CAT,
        callToAction: 'Отправь ссылку мне в чат',
        text: `Извини, не совсем тебя понял, попробуй передать мне ссылку еще раз.`,
        id: 'Q7',
        type: StepType.text_question,
        variable: 'LINK',
        nextStep: 'Q6',
      },
      ...splitStep({
        id: 'Q8',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        text: `Знаешь, из-за всех этих странностей, которые творятся в последнее время, местные обитатели стали настороженно относиться к новеньким… Мы решили быть более бдительными и разузнать о тебе побольше.

Расскажи, пожалуйста, о себе еще немного.`,
        buttons: [
          {
            id: 'b1',
            text: 'Без проблем',
            nextStep: 'Q9',
          },
        ],
      }),
      // Тест на коммуникативность 2/3 - Почему принял решение участвовать
      Q9: {
        id: 'Q9',
        person: Interlocutors.CAT,
        type: StepType.message,
        text: 'Ответь, пожалуйста, в 2-4 предложениях. Вариант «не знаю» не принимается 😺',
        nextStep: 'Q10',
      },
      Q10: {
        id: 'Q10',
        type: StepType.text_question,
        variable: 'TEST_COMMUNICATIVE_GOAL',
        person: Interlocutors.CAT,
        nextStep: 'Q10_1',
        text: 'Почему ты принял решение участвовать в конкурсе?',
      },
      Q10_1: {
        id: 'Q10_1',
        type: StepType.system,
        condition: {
          type: Conditions.IS_ABOUT_MYSELF_VALID,
          variants: ['Q11/0', 'Q10_2'],
        },
        _meta: {},
        person: Interlocutors.CAT,
        nextStep: 'Q11/0',
      },
      Q10_2: {
        id: 'Q10_2',
        type: StepType.message,
        person: Interlocutors.CAT,
        nextStep: 'Q9',
        text: 'Так не пойдет 😿. Попробуй снова',
      },
      ...splitStep({
        id: 'Q11',
        person: Interlocutors.CAT,
        type: StepType.message,
        text: `{{NAME}}, спасибо! Весь парк только о тебе и говорит! 😻
        
        После того, как я изучу и обдумаю твои ответы, расскажу о результатах в твоем профиле, который откроется чуть позже.`,
        nextStep: EXIT_ID,
      }),
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
