export const colors = {
  background: '#fff',
  white: '#ffffff',

  red: '#FF7271',
  blue: '#7358FF',
  orange: '#FF754C',

  primary: '#FFE074',
  accent: '#61dafb',

  black: '#000000',

  text: '#1B1B1B',
  text50: 'rgba(27, 27, 27, 0.5)',
  title: '#11142d',

  base: '#1B1B1B',
  base40: 'rgba(27, 27, 27, 0.4)',
  base50: 'rgba(27, 27, 27, 0.5)',

  placeholder: 'rgba(27, 27, 27, 0.2)',

  grey: '#FAFAFA',
  grey2: '#808191',
  grey220: 'rgba(128, 129, 145, 0.2)',
  grey210: 'rgba(128, 129, 145, 0.2)',
  grey3: '#F2F2F2',
  grey4: '#E4E4E4',
  grey5: 'rgba(250, 250, 250, 0.5)',

  blueShadow: '#3A98EF',
  blueButton: '#3E68FF',
  blueMessage: '#3F8CFF',

  green: '#27ae60',

  error: '#8d7e48',
};

export default colors;
