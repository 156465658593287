import Api from '../../common/shared/api/Api';
import { AxiosPromise, CancelToken } from 'axios';

class FilesApi {
  static getUploadLink(filename: string): AxiosPromise<any> {
    return Api.call(`/files/pre-signed-put?filename=${filename}`);
  }

  // отправка видео к пользователю
  static uploadFileToUser(link: string, mimetype: string): AxiosPromise<any> {
    return Api.call(`files/`, {
      method: 'POST',
      data: {
        mimetype,
        url: link,
      },
    });
  }

  static uploadFile(
    userID: any,
    file: File,
    link: string,
    cancelToken: CancelToken,
    onUploadProgress: (progressEvent: any) => void,
    fileType?: string | undefined,
  ) {
    const formData = new FormData();
    formData.append('file', file);

    if (fileType === 'challenge') {
      formData.append('type', fileType);

      return Api.call(`/files/`, {
        cancelToken,
        data: formData,
        method: 'PUT',
        onUploadProgress: onUploadProgress,
        headers: { 'Content-Type': file.type },
      });
    }

    fileType && formData.append('type', fileType);
    return Api.call(link, {
      cancelToken,
      data: formData,
      method: 'PUT',
      onUploadProgress: onUploadProgress,
      headers: { 'Content-Type': file.type },
    });
  }

  static deleteFile(fileId: string): AxiosPromise<any> {
    return Api.call(`files/${fileId}`, {
      method: 'DELETE',
    });
  }

  static postChallangeToUser(fileId: string): AxiosPromise<any> {
    const data = JSON.stringify({ type: 'challenge' });
    return Api.call(`/files/${fileId}/confirm`, { data, method: 'POST' });
  }
}

export default FilesApi;

// if (process.env.NODE_ENV === 'development') {
//   link = link.replace('https://api-alpha.storage.yandexcloud.net/', '');
//   return Api.call(`yandexcloud/${link}`, {
//     cancelToken,
//     data: file,
//     method: 'PUT',
//     onUploadProgress: onUploadProgress,
//     headers: { 'Content-Type': file.type },
//   });
// }
