export enum NetworkStatus {
  online = 'online',
  offline = 'offline',
}

type Listener = (status: NetworkStatus) => void;
type Subscription = {
  remove: () => void;
};

class NetworkStatusUtilClass {
  private listeners: Listener[] = [];

  private lastStatus = navigator.onLine ? NetworkStatus.online : NetworkStatus.offline;

  constructor() {
    this.checkConnection();

    window.addEventListener('offline', (event) => {
      this.setConnection(NetworkStatus.offline);
    });
    window.addEventListener('online', (event) => {
      this.setConnection(NetworkStatus.online);
    });
  }

  private setConnection(state: NetworkStatus) {
    this.lastStatus = state;
    this.listeners.forEach((l) => l(state));
  }

  private async checkConnection() {
    let response: Response | undefined;
    try {
      response = await new Promise((resolve, reject) => {
        let status = 'pending';
        fetch('/1pixel.jpg', {
          method: 'get',
          headers: {
            pragma: 'no-cache',
            'cache-control': 'no-cache',
          },
        }).then((r) => {
          if (status === 'pending') {
            status = 'resolved';
            resolve(r);
          }
        });
        setTimeout(() => {
          status = 'rejected';
          reject();
        }, 5000);
      });
      // eslint-disable-next-line no-empty
    } catch (e) {}
    const isOnline = Boolean(response && response.status >= 200 && response.status < 300);
    const status = isOnline ? NetworkStatus.online : NetworkStatus.offline;
    if (this.lastStatus !== status) {
      this.setConnection(status);
    }
    setTimeout(
      () => {
        this.checkConnection();
      },
      isOnline ? 15000 : 5000,
    );
  }

  addListener(listener: Listener): Subscription {
    this.listeners.push(listener);
    listener(this.lastStatus);
    return {
      remove: () => {
        this.listeners = this.listeners.filter((i) => i !== listener);
      },
    };
  }
}

export const NetworkStatusUtil = new NetworkStatusUtilClass();
