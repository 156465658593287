/* eslint-disable max-len */
import {
  Step,
  SceneId,
  StepType,
  Scenario,
  ScenarioType,
  Interlocutors,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const scenario_2_5_i: Scenario = {
  scenarioId: SceneId.scenario_2_5_i,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Останкинская телебашня',
    text: `Вы попадаете в Останкинскую башню - вам нужно поймать сигнал со спутника. И теперь вы должны найти место, где вы будете ловить этот сигнал.`,
    buttons: ['Осмотреть музей'],
  },
};

export const scenario_2_5: Scenario = {
  scenarioId: SceneId.scenario_2_5,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    scene_id: 'scenario_2_5',
    startId: 'Q1',
    title: 'Останкинская телебашня',
    steps: {
      Q1: {
        text: `Вы в музее Останкинской башни.На стенах висят чертежи и фотографии строительства. В центре круглого зала вы замечаете большой портрет.`,
        id: 'Q1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Осмотреть портрет',
            nextStep: 'Q1_1',
          },
        ],
      },
      Q1_1: {
        text: `На табличке под портретом написано: «Николай Васильевич Никитин, архитектор». 

          Автор проектов Останкинской башни, здания МГУ на Ленинских горах, монумента «Родина-мать» в Волгограде, стадиона «Лужники» в Москве.
          
          За портретом вы замечаете рычажок.
          `,
        id: 'Q1_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отодвинуть портрет',
            nextStep: 'Q1_2',
          },
        ],
      },
      Q1_2: {
        text: `Вы тянете рычажок на себя и портрет отодвигается. За ним вы видите секретную схему линии передач: «Останкино - Родина-мать» и рубильник.

        Кажется, если поднять этот рубильник, мы сможем передать сигнал со спутника на меч монумента, а оттуда, через уже установленный там маршрутизатор, в Иннополис — Гарину.`,
        id: 'Q1_2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Поднять рубильник',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        text: `Вы поднимаете рубильник и лампочки между объектами на схеме загораются. Сработало! Теперь нужно поймать сигнал со спутника!

          В лифте Останкинской башни нет этажей, зато здесь есть высоты — выберите на какую высоту вы отправитесь.
          `,
        id: 'Q2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Подняться на высоту 85 метров',
            nextStep: 'Q2_1',
          },
          {
            id: 'b2',
            text: 'Подняться на высоту 337 метров',
            nextStep: 'Q2_2',
          },
          {
            id: 'b3',
            text: 'Подняться на высоту 340 метров',
            nextStep: 'Q2_3',
          },
        ],
      },
      Q2_1: {
        text: `Вы поднимаетесь на высоту 85 метров. Здесь Останкинская башня предстаёт изнутри: железные тросы, как вены, расходятся по башне, на стенах — манекены рабочих. Тут интересно, но, кажется, отсюда сигнал не поймать.`,
        id: 'Q2_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Подняться на высоту 337 метров',
            nextStep: 'Q2_2',
          },
          {
            id: 'b2',
            text: 'Подняться на высоту 340 метров',
            nextStep: 'Q2_3',
          },
        ],
      },
      Q2_2: {
        text: `Вы поднимаетесь на высоту 337 метров и видите знаменитую смотровую площадку со стеклянным полом. Вжух рассматривает машины внизу и пытается их поймать, как мух. Всё-таки кот остаётся котом. Ты достаешь контроллер и пытаешься поймать сигнал — тишина, кажется, высота недостаточна.`,
        id: 'Q2_2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Подняться на высоту 340 метров',
            nextStep: 'Q2_3',
          },
        ],
      },
      Q2_3: {
        text: `Высота 340 метров. На самой высокой открытой площадке Останкинской башни завывает ветер: весь парк видно как на ладони. Ты достаёшь контроллер: «Это Гарин, есть сигнал!» — слышишь ты. 
          «{{NAME}} отправляйтесь в Иннополис, теперь мы можем установить источник помех и найти Интеллект 2.0»
          `,
        id: 'Q2_3',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться в Иннополис',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
