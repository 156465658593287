import styled from 'styled-components';

import colors from '../../../common/shared/constants/colors';
import { BREAKPOINT_PHONE } from '../../../common/shared/constants/constants';

export const StyledNickName = styled.div`
  padding: 20px;
  border-radius: 16px;
  background: ${colors.white};

  & > * + * {
    margin-top: 10px;
  }

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    padding: 28px;

    & > * + * {
      margin-top: 15px;
    }
  }
`;
