import React, { FC } from 'react';
import { MapPinProps } from './MapPinTypes';
import { MapPinStyled } from './MapPinStyled';

const MapPin: FC<MapPinProps> = (props) => {
  const { $top, $bottom, $left, $right, onClick, isDisabled = false, type } = props;

  return (
    <MapPinStyled
      $top={$top}
      $left={$left}
      $right={$right}
      $bottom={$bottom}
      isDisabled={isDisabled}
      onClick={onClick}
      type={type}>
      <div></div>
    </MapPinStyled>
  );
};

export default MapPin;
