import { MapSourceConfig } from './MapSourceTypes';

//  Этап 1

import fogStage1 from './resource/stage1/fog.png';
import fogStage1x2 from './resource/stage1/fog@2x.png';
import fogStage1x3 from './resource/stage1/fog@3x.png';

// import mapStage1 from './resource/stage1/map.jpg';
// import mapStage1x2 from './resource/stage1/map@2x.jpg';
// import mapStage1x3 from './resource/stage1/map@3x.jpg';

//  Этап 2 (Акт 1)

// import mapStage2 from './resource/stage2/map.jpg';
// import mapStage2x2 from './resource/stage2/map@2x.jpg';
// import mapStage2x3 from './resource/stage2/map@3x.jpg';

//  Этап 2 (Акт 2)

// import mapStage2a2 from './resource/stage2act2/map.jpeg';
// import mapStage2a2x2 from './resource/stage2act2/map@2x.jpeg';
// import mapStage2a2x3 from './resource/stage2act2/map@3x.jpeg';

//  Этап 2 (Акт 3)

// import mapStage2a3 from './resource/stage2act3/map.jpg';
// import mapStage2a3x2 from './resource/stage2act3/map@2x.jpg';
// import mapStage2a3x3 from './resource/stage2act3/map@3x.jpg';

//  Этап 3 (Акт 1)

// import mapStage3a1 from './resource/stage3act1/map.jpg';
// import mapStage3a1x2 from './resource/stage3act1/map@2x.jpg';
// import mapStage3a1x3 from './resource/stage3act1/map@3x.jpg';

//  Этап 3 (Акт 2)

import mapStage3a2 from './resource/stage3act2/map.jpg';
import mapStage3a2x2 from './resource/stage3act2/map@2x.jpg';
import mapStage3a2x3 from './resource/stage3act2/map@3x.jpg';

const gradeFifth: MapSourceConfig = {
  // map: {
  //   stage1: {
  //     mobile: mapStage1,
  //     laptop: mapStage1x2,
  //     desktop: mapStage1x3,
  //   },
  //   stage2: {
  //     mobile: mapStage2,
  //     laptop: mapStage2x2,
  //     desktop: mapStage2x3,
  //   },
  //   stage2act2: {
  //     mobile: mapStage2a2,
  //     laptop: mapStage2a2x2,
  //     desktop: mapStage2a2x3,
  //   },
  //   stage2act3: {
  //     mobile: mapStage2a3,
  //     laptop: mapStage2a3x2,
  //     desktop: mapStage2a3x3,
  //   },
  //   stage3act1: {
  //     mobile: mapStage3a1,
  //     laptop: mapStage3a1x2,
  //     desktop: mapStage3a1x3,
  //   },
  //   stage3act2: {
  //     mobile: mapStage3a2,
  //     laptop: mapStage3a2x2,
  //     desktop: mapStage3a2x3,
  //   },
  // },
  map: {
    stage1: {
      mobile: mapStage3a2,
      laptop: mapStage3a2x2,
      desktop: mapStage3a2x3,
    },
    stage2: {
      mobile: mapStage3a2,
      laptop: mapStage3a2x2,
      desktop: mapStage3a2x3,
    },
    stage2act2: {
      mobile: mapStage3a2,
      laptop: mapStage3a2x2,
      desktop: mapStage3a2x3,
    },
    stage2act3: {
      mobile: mapStage3a2,
      laptop: mapStage3a2x2,
      desktop: mapStage3a2x3,
    },
    stage3act1: {
      mobile: mapStage3a2,
      laptop: mapStage3a2x2,
      desktop: mapStage3a2x3,
    },
    stage3act2: {
      mobile: mapStage3a2,
      laptop: mapStage3a2x2,
      desktop: mapStage3a2x3,
    },
  },
  fog: {
    stage1: {
      mobile: fogStage1,
      laptop: fogStage1x2,
      desktop: fogStage1x3,
    },
    stage2: {
      mobile: fogStage1,
      laptop: fogStage1x2,
      desktop: fogStage1x3,
    },
    stage2act2: {
      mobile: fogStage1,
      laptop: fogStage1x2,
      desktop: fogStage1x3,
    },
    stage2act3: {
      mobile: fogStage1,
      laptop: fogStage1x2,
      desktop: fogStage1x3,
    },
    stage3act1: {
      mobile: fogStage1,
      laptop: fogStage1x2,
      desktop: fogStage1x3,
    },
    stage3act2: {
      mobile: fogStage1,
      laptop: fogStage1x2,
      desktop: fogStage1x3,
    },
  },
};

export default gradeFifth;
