import React from 'react';

import { BurgerProps } from './BurgerTypes';
import { StyledBurger } from './BurgerStyled';

import { Button } from '../../../common/shared/ui/Button';
import useMobileMenuShow from '../../hooks/useMobileMenuShow';

import close from '../../../assets/images/close.svg';
import burger from '../../../assets/images/burger.svg';

const Burger = (props: BurgerProps) => {
  const { isOpenMobileMenu, toggleMobileMenu } = useMobileMenuShow();
  const icon = React.useMemo(() => (isOpenMobileMenu ? close : burger), [isOpenMobileMenu]);

  return (
    <Button $size='s' $style='grey' onClick={toggleMobileMenu}>
      <StyledBurger src={icon} />
    </Button>
  );
};

export default Burger;
