/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

const TEST_TVOI_VIBOR_3: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'TEST_TVOI_VIBOR_3',
  name: 'Твой выбор 3/5',
  nextStep: 'Q3',
  order: [
    {
      description: '',
      subtest: 'T3',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person: Interlocutors.BLUE_CAT,
  type: StepType.test,
};

export const hermitageFeeding_1_1: Scenario = {
  scenarioId: SceneId.hermitageFeeding_1_1,
  type: ScenarioType.chat,
  data: {
    title: 'ЭРМИТАЖ',
    scene_id: 'hermitageFeeding_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q1',
        text: `Привет, друзья!

        Спасибо, какая аппетитная рыба. Кладите на тарелку, пожалуйста`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Приятного аппетита',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q2',
        text: `Спасибо!

        {{NAME}}, [[какой/какая]] ты [[активный/активная]] — помогаешь всем нам! 
        
        Пока я обедаю, расскажи, к чему у тебя лежит душа. Чем займёшься в будущем?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Я…',
            nextStep: 'TEST_TVOI_VIBOR_3',
          },
        ],
      },

      TEST_TVOI_VIBOR_3,

      Q3: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q3',
        text: `Интересно, интересно. Я спокоен за твое будущее.

        Так, давайте теперь поговорим с Женей.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо, Малевич ',
            nextStep: 'Q4',
          },
        ],
      },

      Q4: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q4',
        text: `А вот и Женя! 

        Что будем спрашивать?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Что случилось с ней и сотрудниками Эрмитажа?',
            nextStep: 'Q5',
          },
          {
            id: 'b2',
            text: 'Где мы можем найти Петрова? ',
            nextStep: 'Q9',
          },
        ],
      },

      Q5: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q5',
        text: `Мяу мяу-мяу мрр мяяяу Мррррр?`,
        type: StepType.message,
        nextStep: 'Q6',
      },

      Q6: {
        person: Interlocutors.GUIDE,
        id: 'Q6',
        text: `Мяу мяяяу мррр мяу, мяу мяу мяу. Мрррр мяяяяу мяу мяу — мяу мяу`,
        type: StepType.message,
        nextStep: 'Q7',
      },

      Q7: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q7',
        text: `Она сказала, что несколько дней назад пришла сюда на работу и не смогла выдавить из себя ни слова кроме «мяу» и «мрр». Хотя, проводить экскурсии для котов ей нравится.

        Что-нибудь ещё спросим?
        `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Где мы можем найти Петрова?',
            nextStep: 'Q8',
          },
        ],
      },

      Q8: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q8',
        text: `Мяяяу мрр мяу-мяу, мяу мяу мяу?`,
        type: StepType.message,
        nextStep: 'Q9',
      },

      Q9: {
        person: Interlocutors.GUIDE,
        id: 'Q9',
        text: `Мяу мяяяу мрр мяу-мяу-мяу. Мяяяяяу, мррррррр… мяу мяу мяу.

        Мяу мяу мррр-мяяяяу.`,
        type: StepType.message,
        nextStep: 'Q10',
      },

      Q10: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q10',
        text: `Женя сказала, что Петров сегодня утром ушёл играть в футбол в Олимпийский Парк. Или в баскетбол… На кошачьем эти слова не отличаются.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо тебе, Малевич! И передай спасибо Жене',
            nextStep: 'Q11',
          },
        ],
      },

      Q11: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q11',
        text: `Мррр-мяу`,
        type: StepType.message,
        nextStep: EXIT_ID,
      },

      [EXIT_ID]: {
        person: Interlocutors.SYSTEM,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.SYSTEM,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
