import { createLogger } from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';

import appSlice from './app';
import userSlice from './user';
import freezeSlice from './freeze';
import ratingsSlice from './ratings';
import scenarioSlice from './scenario';

import { isDebug } from '../../common/shared/constants/constants';
import testsSlice from './tests';

const logger: any = createLogger({
  predicate: () => Boolean(isDebug),
  collapsed: true,
  timestamp: true,
});

const store = configureStore({
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(logger);
  },
  reducer: {
    app: appSlice.reducer,
    user: userSlice.reducer,
    freeze: freezeSlice.reducer,
    ratings: ratingsSlice.reducer,
    scenario: scenarioSlice.reducer,
    tests: testsSlice.reducer,
  },
});

export default store;
