/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../scenario';

export const scenario_3_10: Scenario = {
  scenarioId: SceneId.scenario_3_10,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Херсонес',
    text: 'Вы находитесь в Херсонесе парка «Большая перемена». В одной из ям вы обнаружили дым-машину Петрова с торчащей из нее ручкой стоп-крана поезда в «Артек». Петров потянулся к ручке, чтобы вытащить ее, как вдруг раздался крик...',
    buttons: ['Продолжить'],
  },
};
