/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import { TVOI_KRUGOZOR_G2_ON_CHECK } from '../../tests/tvoi_krugozor_g2_on_check';
import { TVOI_KRUGOZOR_T2_ON_CHECK } from '../../tests/tvoi_krugozor_t2_on_check';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const scenario_3_5_i: Scenario = {
  scenarioId: SceneId.scenario_3_5_i,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Останкинская телебашня',
    text: `Итак, вы на космодроме. Для начала нужно проверить, появился ли Меркурий на своём месте в галактике.
      Где это можно сделать?
      `,
    buttons: ['Закрыть'],
  },
};

export const scenario_3_5: Scenario = {
  scenarioId: SceneId.scenario_3_5,
  type: ScenarioType.chat,
  data: {
    title: 'Останкинская телебашня',
    timeoutMessage: 'К сожалению, время для ответа истекло 🙁',
    scene_id: 'scenario_3_5',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.SYSTEM,
        id: 'Q1',
        text: `Ты попадаешь в Останкинскую башню. Нужно поймать сигнал со спутника — вспомни, на какой высоте установлен контроллер.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Подняться на высоту 85 метров',
            nextStep: 'Q1_1',
          },
          {
            id: 'b2',
            text: 'Подняться на высоту 337 метров',
            nextStep: 'Q1_2',
          },
          {
            id: 'b3',
            text: 'Подняться на высоту 340 метров',
            nextStep: 'Q1_3',
          },
        ],
      },
      Q1_1: {
        person: Interlocutors.SYSTEM,
        id: 'Q1_1',
        text: `Вы поднимаетесь на высоту 85 метров. Здесь Останкинская башня предстаёт изнутри: железные тросы как сосуды расходятся по башне, на стенах манекены рабочих. Контроллера тут нет.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Подняться на высоту 337 метров',
            nextStep: 'Q1_2',
          },
          {
            id: 'b2',
            text: 'Подняться на высоту 340 метров',
            nextStep: 'Q1_3',
          },
        ],
      },
      Q1_2: {
        person: Interlocutors.SYSTEM,
        id: 'Q1_2',
        text: `Вы поднимаетесь на высоту 337 метров — знаменитая смотровая площадка со стеклянным полом. Контроллера тут нет.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Подняться на высоту 340 метров',
            nextStep: 'Q1_3',
          },
        ],
      },
      Q1_3: {
        person: Interlocutors.SYSTEM,
        id: 'Q1_3',
        text: `Высота 340 метров. На самой высокой открытой площадке Останкинской башни завывает ветер: а вот и контроллер!
        
        Чтобы контроллер снова заработал, тебе нужно будет ответить на тест.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отлично! Я как раз [[хотел/хотела]] размять мозги, поэтому с удовольствием пройду тест!',
            nextStep: 'Q1_3_0',
          },
          {
            id: 'b2',
            text: 'Главное, чтобы контроллер заработал! Я в деле!',
            nextStep: 'Q1_3_0',
          },
        ],
      },
      Q1_3_0: {
        person: Interlocutors.SYSTEM,
        id: 'Q1_3_0',
        text: `Тест состоит из 2х частей по 10 вопросов. На прохождение каждой части будет отведено ровно 10 минут. Ты [[должен/должна]] выбрать 1 вариант ответа из предложенных, и у тебя будет всего 1 минута на каждый вопрос. Очень внимательно читай вопросы.
        
        Важный момент: пройти тест можно только один раз. Повторно к нему вернуться будет нельзя. 
        
        Обрати внимание: так как ты проходишь тест после 16:00 7 июня, то баллы за этот тест тебе не будут начисляться.
        
        Выходить из теста во время его прохождения нельзя. Но если ты все-таки выйдешь из игры после запуска теста, независимо от причин, почему это произошло, таймер будет продолжать работать, а время, отведенное на тест, истекать. 
        
        Вопросы нельзя проматывать и возвращаться к предыдущим вопросам, поэтому прежде, чем начать тест, проверь, что у тебя:
           -   стабильно работает Интернет
           -   заряжено устройство, с которого ты проходишь тест
           -   и никто тебе не помешает в ближайшие 20 минут`,
        type: StepType.question,
        maxAnswers: 1,
        callToAction: 'Начнем?',
        buttons: [
          {
            id: 'b1',
            text: 'Да, давай!',
            nextStep: 'TVOI_KRUGOZOR_G2_STEP',
          },
        ],
      },

      ...TVOI_KRUGOZOR_G2_ON_CHECK,

      Q1_3_0_0: {
        person: Interlocutors.SYSTEM,
        id: 'Q1_3_0_0',
        text: `И еще 10 вопросов. [[Готов/Готова]]?`,
        type: StepType.question,
        maxAnswers: 1,
        callToAction: 'Начнем?',
        buttons: [
          {
            id: 'b1',
            text: 'Да, давай!',
            nextStep: 'TVOI_KRUGOZOR_T2_STEP',
          },
        ],
      },

      ...TVOI_KRUGOZOR_T2_ON_CHECK,

      Q1_3_1: {
        person: Interlocutors.SYSTEM,
        id: 'Q1_3_1',
        text: `Связи нет, выбери другую линию.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ангара, МГУ — Херсонес - ЮНЕСКО',
            nextStep: 'Q1_3_1',
          },
          {
            id: 'b2',
            text: 'Ангара, Космодром - ОБ - Иннополис',
            nextStep: 'Q1_3_2',
          },
          {
            id: 'b3',
            text: 'Союз, Космодром - ОБ - Иннополис',
            nextStep: 'Q1_3_1',
          },
        ],
      },
      Q1_3_2: {
        // ТЕСТ НА АЛЬТРУИЗМ 16
        person: Interlocutors.SYSTEM,
        id: 'Q1_3_2',
        text: `Есть сигнал — связь установлена! Теперь можно отправляться дальше. 

        Вдруг ты видишь на площадке птицу с жёлто-серым клювом и большими круглыми глазами. Птица сидит, не улетая, кажется, у неё что-то с крылом. 
        
        Может быть, ей нужна помощь?
        `,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Помочь птице',
            nextStep: 'Q2',
          },
          {
            id: 'b2',
            text: 'Пойти дальше',
            nextStep: EXIT_ID,
          },
        ],
      },
      Q2: {
        // ТЕСТ НА АЛЬТРУИЗМ 17
        person: Interlocutors.SYSTEM,
        id: 'Q2',
        text: `Отлично, есть несколько способов помочь птице, какой выберешь ты?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отнести птицу к орнитологу (Игровое время будет заморожено на 30 минут)',
            nextStep: 'Q2_1',
          },
          {
            id: 'b2',
            text: 'Позвонить в службу спасения дикой природы (Игровое время будет заморожено на 15 минут)',
            nextStep: 'Q3',
          },
          {
            id: 'b3',
            text: 'Попросить сотрудников Останкино присмотреть за птицей (Игровое время будет заморожено на 5 минут)',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        person: Interlocutors.SYSTEM,
        id: 'Q3',
        text: `Хорошо, о птице позаботятся. Время двигаться дальше. Растения из башни «Эволюция» уже должны были доставить в Ботанический сад МГУ — может стоит помочь их посадить?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправится в МГУ',
            nextStep: EXIT_ID,
          },
        ],
      },
      Q2_1: {
        // ТЕСТ НА АЛЬТРУИЗМ
        person: Interlocutors.SYSTEM,
        id: 'Q2_1',
        text: `Ты относишь птицу к орнитологу и встречаешь там учительницу.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Поговорить с учительницей',
            nextStep: 'Q2_1_1',
          },
        ],
      },
      Q2_1_1: {
        person: Interlocutors.TEACHER,
        id: 'Q2_1_1',
        text: `{{NAME}}, это же сокол сапсан! Эти соколы гнездятся у нас в МГУ. Это благородные и красивые птицы. Спасибо тебе. 
        Теперь о соколе позаботятся, а пока сможешь мне помочь в МГУ?.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться с учительницей',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.SYSTEM,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.SYSTEM,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
