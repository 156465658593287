/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../scenario';

export const scenario_3_24: Scenario = {
  scenarioId: SceneId.scenario_3_24,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.CAT,
    title: 'Новости парка',
    text: `Мы с обитателями парка поздравляем тебя! 

Ты очень [[помог/помогла]] нам всем и [[прошел/прошла]] игру до самого конца. За отвагу мы вручаем тебе медаль [[главного/главной]] [[спасителя/спасительницы]] «Большой перемены»!

Мы записали все твои ответы, все добрые дела и передали их организаторам игры. Они свяжутся с самыми сообразительными и находчивыми и выберут сильнейших — тех, кто поедет в «Артек».

Жди информации!`,
    buttons: ['Закрыть'],
  },
};
