import { useDispatch, useSelector } from 'react-redux';
import { selectScenarios, selectUserInfo, selectUserState } from '../../redux/user/userGetters';
import * as checks from './stageChecks';
import { ID, to } from './ID';
import { ScenarioHelperResponse } from './types';
import { useRunScenarioAsync } from '../useRunScenarioAsync';
import { useRunScenarioSequence } from './utils';
import { useIsFirstStageCompleted } from '../stageProgressChecks/useIsFirstStageCompleted';
import { stage1 } from './stage1';
import { stage2 } from './stage2';
import { useIsSecondStageCompleted } from '../stageProgressChecks/useIsSecondStageCompleted';
import { stage3 } from './stage3';
import { hasBlanks } from '../stageProgressChecks/requiredScenarios';
import { SceneId } from '../../resources/scenario';

export function useScenarioHelper(): ScenarioHelperResponse {
  const scenarioState = useSelector(selectScenarios);
  const userState = useSelector(selectUserState);

  const dispatch = useDispatch();

  const runScenario = useRunScenarioAsync();
  const runModalScenario = useRunScenarioSequence();

  const isFirstStageCompleted = useIsFirstStageCompleted();
  // const isFirstStageCompleted = false;
  const isSecondStageCompleted = useIsSecondStageCompleted();
  // const isSecondStageCompleted = false;

  // Начало игры
  if (checks.notStarted(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: {
        id: to[ID.start],
        unsaved: false,
      },
    };
  }

  // const user = useSelector(selectUserInfo);
  // const videoIsAttached = user?.files.presentation;

  // Начало игры

  // if (videoIsAttached)
  //   return {
  //     pointsHandlers: [],
  //     autorunScenario: {
  //       id: to[ID.stop_1],
  //       unsaved: true,
  //     },
  //   };

  const blanks = hasBlanks(scenarioState.scenarios);
  if (blanks && !scenarioState.scenarios[SceneId.scenario_blanks]) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: SceneId.scenario_blanks },
    };
  }

  if (!isFirstStageCompleted) {
    return stage1(scenarioState, runScenario, runModalScenario);
  }

  if (!isSecondStageCompleted) {
    return stage2(scenarioState, runScenario, runModalScenario, dispatch);
  }

  // заглушка

  return stage3(scenarioState, runScenario, runModalScenario, dispatch, userState);
}
