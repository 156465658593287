import { Item } from '../ItemsBar/ItemsBarTypes';

import HoodyBP from '../../../assets/images/hero/bp-hoody.svg';
import HoodyIcon from '../../../assets/images/backpack/hoody.svg';

import BoyBase from '../../../assets/images/hero/Boy base.svg';
import BoyPreview from '../../../assets/images/hero/Boy preview.svg';
import BoyBody01 from "../../../assets/images/hero/Boy's body 01.svg";
import BoyBody02 from "../../../assets/images/hero/Boy's body 02.svg";
import BoyBody03 from "../../../assets/images/hero/Boy's body 03.svg";
import BoyBody04 from "../../../assets/images/hero/Boy's body 04.svg";
import BoyBody05 from "../../../assets/images/hero/Boy's body 05.svg";
import BoyFace01 from "../../../assets/images/hero/Boy's face 01.svg";
import BoyFace02 from "../../../assets/images/hero/Boy's face 02.svg";
import BoyFace03 from "../../../assets/images/hero/Boy's face 03.svg";
import BoyFace04 from "../../../assets/images/hero/Boy's face 04.svg";
import BoyFace05 from "../../../assets/images/hero/Boy's face 05.svg";
import BoyHair01 from "../../../assets/images/hero/Boy's hair 01.svg";
import BoyHair02 from "../../../assets/images/hero/Boy's hair 02.svg";
import BoyHair03 from "../../../assets/images/hero/Boy's hair 03.svg";
import BoyHair04 from "../../../assets/images/hero/Boy's hair 04.svg";
import BoyHair05 from "../../../assets/images/hero/Boy's hair 05.svg";
import SmallBoyFace01 from "../../../assets/images/hero/Small Boy's face 01.svg";
import SmallBoyFace02 from "../../../assets/images/hero/Small Boy's face 02.svg";
import SmallBoyFace03 from "../../../assets/images/hero/Small Boy's face 03.svg";
import SmallBoyFace04 from "../../../assets/images/hero/Small Boy's face 04.svg";
import SmallBoyFace05 from "../../../assets/images/hero/Small Boy's face 05.svg";
import SmallBoyBody01 from "../../../assets/images/hero/Small Boy's body 01.svg";
import SmallBoyBody02 from "../../../assets/images/hero/Small Boy's body 02.svg";
import SmallBoyBody03 from "../../../assets/images/hero/Small Boy's body 03.svg";
import SmallBoyBody04 from "../../../assets/images/hero/Small Boy's body 04.svg";
import SmallBoyBody05 from "../../../assets/images/hero/Small Boy's body 05.svg";
import SmallBoyHair01 from "../../../assets/images/hero/Small Boy's hair 01.svg";
import SmallBoyHair02 from "../../../assets/images/hero/Small Boy's hair 02.svg";
import SmallBoyHair03 from "../../../assets/images/hero/Small Boy's hair 03.svg";
import SmallBoyHair04 from "../../../assets/images/hero/Small Boy's hair 04.svg";
import SmallBoyHair05 from "../../../assets/images/hero/Small Boy's hair 05.svg";

import GirlBase from '../../../assets/images/hero/Girl base.svg';
import GirlPreview from '../../../assets/images/hero/Girl preview.svg';

import GirlBody01 from "../../../assets/images/hero/Girl's body 01.svg";
import GirlBody02 from "../../../assets/images/hero/Girl's body 02.svg";
import GirlBody03 from "../../../assets/images/hero/Girl's body 03.svg";
import GirlBody04 from "../../../assets/images/hero/Girl's body 04.svg";
import GirlBody05 from "../../../assets/images/hero/Girl's body 05.svg";
import GirlFace01 from "../../../assets/images/hero/Girl's face 01.svg";
import GirlFace02 from "../../../assets/images/hero/Girl's face 02.svg";
import GirlFace03 from "../../../assets/images/hero/Girl's face 03.svg";
import GirlFace04 from "../../../assets/images/hero/Girl's face 04.svg";
import GirlFace05 from "../../../assets/images/hero/Girl's face 05.svg";
import GirlHair01 from "../../../assets/images/hero/Girl's hair 01.svg";
import GirlHair02 from "../../../assets/images/hero/Girl's hair 02.svg";
import GirlHair03 from "../../../assets/images/hero/Girl's hair 03.svg";
import GirlHair04 from "../../../assets/images/hero/Girl's hair 04.svg";
import GirlHair05 from "../../../assets/images/hero/Girl's hair 05.svg";
import SmallGirlFace01 from "../../../assets/images/hero/Small Girl's face 01.svg";
import SmallGirlFace02 from "../../../assets/images/hero/Small Girl's face 02.svg";
import SmallGirlFace03 from "../../../assets/images/hero/Small Girl's face 03.svg";
import SmallGirlFace04 from "../../../assets/images/hero/Small Girl's face 04.svg";
import SmallGirlFace05 from "../../../assets/images/hero/Small Girl's face 05.svg";
import SmallGirlBody01 from "../../../assets/images/hero/Small Girl's body 01.svg";
import SmallGirlBody02 from "../../../assets/images/hero/Small Girl's body 02.svg";
import SmallGirlBody03 from "../../../assets/images/hero/Small Girl's body 03.svg";
import SmallGirlBody04 from "../../../assets/images/hero/Small Girl's body 04.svg";
import SmallGirlBody05 from "../../../assets/images/hero/Small Girl's body 05.svg";
import SmallGirlHair01 from "../../../assets/images/hero/Small Girl's hair 01.svg";
import SmallGirlHair02 from "../../../assets/images/hero/Small Girl's hair 02.svg";
import SmallGirlHair03 from "../../../assets/images/hero/Small Girl's hair 03.svg";
import SmallGirlHair04 from "../../../assets/images/hero/Small Girl's hair 04.svg";
import SmallGirlHair05 from "../../../assets/images/hero/Small Girl's hair 05.svg";

export interface ProfileConfig {
  name: string;

  bodyId?: string;
  hairId?: string;
  faceId?: string;
  backgroundId?: string;

  male?: boolean;
  female?: boolean;
}

export type AppearanceConfig<ForRender extends boolean = false> = ForRender extends true
  ? {
      id: string;
      preview: string;
      base: string;
      body: Item[];
      face: Item[];
      hair: Item[];
      background: Item[];
    }
  : {
      id: string;
      preview: string;
      base: string;
      body: Item;
      face: Item;
      hair: Item;
      background: Item;
    };

export const maleConfig: AppearanceConfig<true> = {
  id: 'male',
  base: BoyBase,
  preview: BoyPreview,
  body: [
    { id: 'BoyBody01', icon: SmallBoyBody01, template: BoyBody01 },
    { id: 'BoyBody02', icon: SmallBoyBody02, template: BoyBody02 },
    { id: 'BoyBody03', icon: SmallBoyBody03, template: BoyBody03 },
    { id: 'BoyBody04', icon: SmallBoyBody04, template: BoyBody04 },
    { id: 'BoyBody05', icon: SmallBoyBody05, template: BoyBody05 },
    { id: 'BoyBody06', template: HoodyBP, icon: HoodyIcon, isHidden: true },
  ],
  hair: [
    { id: 'BoyHair01', icon: SmallBoyHair01, template: BoyHair01 },
    { id: 'BoyHair02', icon: SmallBoyHair02, template: BoyHair02 },
    { id: 'BoyHair03', icon: SmallBoyHair03, template: BoyHair03 },
    { id: 'BoyHair04', icon: SmallBoyHair04, template: BoyHair04 },
    { id: 'BoyHair05', icon: SmallBoyHair05, template: BoyHair05 },
  ],
  face: [
    { id: 'BoyFace01', icon: SmallBoyFace01, template: BoyFace01 },
    { id: 'BoyFace02', icon: SmallBoyFace02, template: BoyFace02 },
    { id: 'BoyFace03', icon: SmallBoyFace03, template: BoyFace03 },
    { id: 'BoyFace04', icon: SmallBoyFace04, template: BoyFace04 },
    { id: 'BoyFace05', icon: SmallBoyFace05, template: BoyFace05 },
  ],
  background: [
    { id: 'BoyBackground02', icon: '#71F4A6' },
    { id: 'BoyBackground01', icon: '#162C4E' },
    { id: 'BoyBackground03', icon: '#3E68FF' },
    { id: 'BoyBackground04', icon: '#FFF281' },
    { id: 'BoyBackground05', icon: '#F68A4D' },
    { id: 'BoyBackground06', icon: '#FF7271' },
  ],
};

// export const femaleConfig = { ...maleConfig, id: 'female', base: GirlBase, preview: GirlPreview };

export const femaleConfig: AppearanceConfig<true> = {
  id: 'female',
  base: GirlBase,
  preview: GirlPreview,
  body: [
    { id: 'GirlBody01', template: GirlBody01, icon: SmallGirlBody01 },
    { id: 'GirlBody02', template: GirlBody02, icon: SmallGirlBody02 },
    { id: 'GirlBody03', template: GirlBody03, icon: SmallGirlBody03 },
    { id: 'GirlBody04', template: GirlBody04, icon: SmallGirlBody04 },
    { id: 'GirlBody05', template: GirlBody05, icon: SmallGirlBody05 },
    { id: 'GirlBody06', template: HoodyBP, icon: HoodyIcon, isHidden: true },
  ],
  hair: [
    { id: 'GirlHair01', template: GirlHair01, icon: SmallGirlHair01 },
    { id: 'GirlHair02', template: GirlHair02, icon: SmallGirlHair02 },
    { id: 'GirlHair03', template: GirlHair03, icon: SmallGirlHair03 },
    { id: 'GirlHair04', template: GirlHair04, icon: SmallGirlHair04 },
    { id: 'GirlHair05', template: GirlHair05, icon: SmallGirlHair05 },
  ],
  face: [
    { id: 'GirlFace01', template: GirlFace01, icon: SmallGirlFace01 },
    { id: 'GirlFace02', template: GirlFace02, icon: SmallGirlFace02 },
    { id: 'GirlFace03', template: GirlFace03, icon: SmallGirlFace03 },
    { id: 'GirlFace04', template: GirlFace04, icon: SmallGirlFace04 },
    { id: 'GirlFace05', template: GirlFace05, icon: SmallGirlFace05 },
  ],
  background: [
    { id: 'GirlBackground02', icon: '#71F4A6' },
    { id: 'GirlBackground01', icon: '#162C4E' },
    { id: 'GirlBackground03', icon: '#3E68FF' },
    { id: 'GirlBackground04', icon: '#FFF281' },
    { id: 'GirlBackground05', icon: '#F68A4D' },
    { id: 'GirlBackground06', icon: '#FF7271' },
  ],
};

export function getItem(
  config: AppearanceConfig<true>,
  field: 'body' | 'face' | 'hair' | 'background',
  id?: string,
) {
  const index = config[field].findIndex((item) => item.id === id);
  return index !== -1 ? config[field][index] : config[field][0];
}
