/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg3_bridgeRussianIsland_1_1_1: Scenario = {
  scenarioId: SceneId.stg3_bridgeRussianIsland_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Мост',
    text: `Перед вами грандиозное сооружение — мост на остров Русский. 

    С воды отлично просматривается космодром неподалёку. Вы идёте по мосту и останавливаетесь на середине.
    `,
    buttons: ['Начать'],
  },
};
