/* eslint-disable max-len */
import {
  Conditions,
  Interlocutors,
  Message,
  Scenario,
  ScenarioType,
  SceneId,
  Step,
  StepType,
  SystemStep,
} from '../../../types/scenario';
import { splitStep } from '../../../utils/ScenarioUtils';
import interlocutors from '../../interlocutors';

const EXIT_ID = 'EXIT';

const person = Interlocutors.CAT;

function getCondition(
  id: string,
  success: string,
  error: string,
  type: Conditions,
): { [id: string]: SystemStep } {
  return {
    [id]: {
      id,
      type: StepType.system,
      condition: {
        type,
        variants: [success, error],
      },
      _meta: {},
      person,
    },
  };
}
function getError(id: string, nextStep: string): { [id: string]: Message } {
  return {
    [id]: {
      id,
      text: '{{NAME}}, мы ведь договаривались насчет «не знаю» 😿 Попробуй еще раз.',
      type: StepType.message,
      callToAction: 'Напиши 2-4 предложения и отправь',
      person,
      nextStep,
    },
  };
}

const VICT: Record<string, Step> = {
  VICT1: {
    id: 'VICT1',
    person,
    text: 'Викторина состоит из трех вопросов. Ты готов?',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        nextStep: 'VICT2',
        text: 'Да, давай',
      },
    ],
  },
  VICT2: {
    id: 'VICT2',
    text: 'Сколько лет назад был построен МГУ?',
    type: StepType.question,
    person,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: '269',
        nextStep: 'VICT2/1',
      },
      {
        id: 'b2',
        text: '500',
        nextStep: 'VICT2/2/0',
      },
      {
        id: 'b3',
        text: '35',
        nextStep: 'VICT2/2/0',
      },
    ],
  },
  'VICT2/1': {
    id: 'VICT2/1',
    person,
    text: 'Да! Абсолютно верно! Порадую Светлану Васильевну, что ты внимательно ее слушал.',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Круто!',
        nextStep: 'VICT3',
      },
    ],
  },
  ...splitStep({
    person,
    text: `Почти, но не совсем 🙈 Правильный ответ: 269 лет назад.

Так как ты зарабатываешь колбы для меня, я буду подсказывать 😉`,
    id: 'VICT2/2',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Ладно',
        nextStep: 'VICT3',
      },
    ],
  }),
  VICT3: {
    id: 'VICT3',
    text: 'Следующий вопрос: сколько продлился полет Юрия Гагарина?',
    type: StepType.question,
    person,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'меньше часа',
        nextStep: 'VICT3/2/0',
      },
      {
        id: 'b2',
        text: 'около 30 минут',
        nextStep: 'VICT3/2/0',
      },
      {
        id: 'b3',
        nextStep: 'VICT3/1',
        text: 'почти два часа',
      },
    ],
  },
  'VICT3/1': {
    text: 'Да, правильно! Вижу, Петров не зря тебе об этом рассказывал 😻',
    id: 'VICT3/1',
    nextStep: 'VICT4',
    person,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Ага, я помню, мы говорили об этом',
        nextStep: 'VICT4',
      },
    ],
  },
  ...splitStep({
    id: 'VICT3/2',
    person,
    text: `Ну ты что, Петров ведь рассказывал об этом, когда вы были на Космодроме 🙀 

Гагарин находился в космосе почти два часа.`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Да, припоминаю',
        nextStep: 'VICT4',
      },
    ],
  }),
  VICT4: {
    text: 'Остался последний вопрос и колбы наши! В каком городе находится Останкинская телебашня?',
    person,
    id: 'VICT4',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Москва',
        nextStep: 'VICT4/1',
      },
      {
        id: 'b2',
        text: 'Пекин',
        nextStep: 'VICT4/2/0',
      },
      {
        id: 'b3',
        text: 'Лондон',
        nextStep: 'VICT4/2/0',
      },
    ],
  },
  'VICT4/1': {
    id: 'VICT4/1',
    text: 'Да тебе надо на программу «Самый умный»! 😻 Это был верный ответ.',
    person,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Ура!',
        nextStep: 'Q6',
      },
    ],
  },
  ...splitStep({
    id: 'VICT4/2',
    text: `Ну ты что, конечно же в Москве! 🙀

Сан Саныч наверняка рассказывал об этом.`,
    person,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Ой, как-то забылось просто',
        nextStep: 'Q6',
      },
    ],
  }),
};

export const scenario_1_14_0: Scenario = {
  scenarioId: SceneId.scenario_1_14_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: interlocutors.CAT.name,
    person: Interlocutors.CAT,
    text: `{{NAME}}! Как хорошо, что ты здесь! Спасай, я пропал 😭😭😭`,
    buttons: ['Что случилось?'],
  },
};

export const scenario_1_14: Scenario = {
  scenarioId: SceneId.scenario_1_14,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    scene_id: 'scenario_1_14',
    startId: 'Q1/0',
    steps: {
      ...splitStep({
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q1',
        person,
        text: `Я ночью гулял в лаборатории Петрова, ведь я люблю везде гулять. Так вот, там увидел мышку, ясное дело, погнался за ней и разбил колбы 😭😭😭

Петров очень расстроится 🙁`,
        buttons: [
          {
            id: 'b1',
            text: 'Бедняжка!',
            nextStep: 'Q2',
          },
          {
            id: 'b2',
            text: 'Но чем же я могу помочь?',
            nextStep: 'Q3',
          },
        ],
      }),
      Q2: {
        id: 'Q2',
        person,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Так и чем я могу помочь?',
            nextStep: 'Q3',
          },
        ],
        text: 'Спасибо за сочувствие, но тут оно не поможет 😿',
      },
      Q3: {
        id: 'Q3',
        type: StepType.question,
        maxAnswers: 1,
        person,
        text: 'Понимаешь, тут как раз проходит викторина, в которой эти самые колбы можно выиграть. Но я же кот, мне участвовать нельзя 😣',
        buttons: [
          {
            id: 'b1',
            text: 'Извини, я никак не могу',
            nextStep: 'Q4/0',
          },
          {
            id: 'b2',
            text: 'Ну давай, я попробую',
            nextStep: 'Q5',
          },
        ],
      },
      ...splitStep({
        text: `Ой, горе мне, горе! 😣

Эти колбы нужны Петрову для какого-то очень важного эксперимента. Вроде как, это даже должно помочь узнать, что все-таки у нас произошло 😿`,
        id: 'Q4',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b2',
            text: 'Ну ладно, я приму участие в викторине',
            nextStep: 'Q5',
          },
        ],
        person,
      }),
      Q5: {
        id: 'Q5',
        person,
        text: '{{NAME}}, ты настоящий друг! Ура! Огрооомное спасибо! 😻',
        type: StepType.message,
        nextStep: 'VICT1',
      },
      ...VICT,
      ...splitStep({
        id: 'Q6',
        text: `{{NAME}}, ты мой герой! 😻 Я знал, что ты не оставишь меня в беде!

Колбы наши и Петров даже не заметит, что тут что-то произошло.`,
        person,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Мне было в радость тебе помочь',
            nextStep: 'Q7',
          },
          {
            id: 'b2',
            text: 'Да ладно, делов-то на две минуты',
            nextStep: 'Q7',
          },
        ],
      }),
      Q7: {
        id: 'Q7',
        text: 'Кстати, я виделся с Сан Санычем, он сказал, что договорился с тобой насчет вопросов. Мне ведь тоже есть о чем спросить 😺',
        type: StepType.question,
        person,
        maxAnswers: 1,
        buttons: [
          {
            text: 'Ок, спрашивай',
            nextStep: 'Q8/0',
            id: 'b1',
          },
        ],
      },
      ...splitStep({
        id: 'Q8',
        person,
        text: `Хорошо, спасибо! 😻

Я по очереди задам два вопроса. Напиши ответы в поле для ввода и нажми «Отправить».

Каждый ответ должен состоять из 2-4 предложений, вариант «не знаю» не принимается 😺`,
        type: StepType.question,
        maxAnswers: 1,
        callToAction: 'Напиши ответ и отправь',
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо',
            nextStep: 'COM_TEST_3',
          },
        ],
      }),
      COM_TEST_3: {
        id: 'COM_TEST_3',
        person,
        type: StepType.text_question,
        text: 'Что тебе было бы интересно сделать в течение конкурса «Большая Перемена»?',
        variable: 'COM_TEST_3',
        nextStep: 'Q9',
      },
      ...getCondition('Q9', 'COM_TEST_4', 'Q10', Conditions.IS_COM_TEST_STEP3_VALID),
      ...getError('Q10', 'COM_TEST_3'),
      COM_TEST_4: {
        id: 'COM_TEST_4',
        person,
        type: StepType.text_question,
        text: 'Что бы ты хотел делать вместе с другими ребятами в рамках проекта «Большая перемена»?',
        variable: 'COM_TEST_4',
        nextStep: 'Q11',
      },
      ...getCondition('Q11', 'Q13/0', 'Q12', Conditions.IS_COM_TEST_STEP4_VALID),
      ...getError('Q12', 'COM_TEST_4'),
      ...splitStep({
        id: 'Q13',
        text: `Большое спасибо! Нам всем очень приятно получше узнавать тебя, нашего спасителя. 

А я вот люблю речную рыбу и, конечно же, ловить мышей 😻`,
        nextStep: EXIT_ID,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            text: 'Успехов в охоте, но давай все-таки поосторожнее',
            id: 'b1',
            nextStep: EXIT_ID,
          },
        ],
        person,
      }),
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};

export const scenario_1_14_1: Scenario = {
  scenarioId: SceneId.scenario_1_14_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors.CAT.name,
    person: Interlocutors.CAT,
    text: `{{NAME}}, ты пока отдохни от наших дел. Обитатели парка хотят почитать твои ответы, а чтобы найти всех на такой большой площади мне нужно время.

Кстати, мы для тебя готовим сюрприз 😊 Возвращайся 21 апреля, будем ждать с нетерпением 😺`,
    buttons: ['Закрыть'],
  },
};
