import React, { useCallback, useState } from 'react';

import { Box } from '../../../common/shared/ui/Box';
import { Text } from '../../../common/shared/ui/Text';
import { Button } from '../../../common/shared/ui/Button';
import { Page } from '../../../common/pages/Page';
import Link from '../../components/Link';

import logo from '../../../assets/images/logoShort.svg';

import { BoxBottom, ButtonLogo } from './LoginPageStyled';
import { Problems } from '../../../common/shared/ui/Problems';

import { GameModal } from '../../components/GameModal';
import { Interlocutors } from '../../types/scenario';

const { REACT_APP_API_LOGIN } = process.env;

const LoginPage = () => {
  const AUTH_LINK = `${REACT_APP_API_LOGIN}?redirect_url=${window.location.origin}`;
  window.location.href = AUTH_LINK;

  // TODO: 55639: Реализация бесшовной авторизации, после 01.06.2024 удалить код ниже
  return <></>;

  // const [isAgree, setIsAgree] = useState(false);
  // const onAuthClick = useCallback(
  //   (e: any) => {
  //     if (!isAgree) {
  //       e.preventDefault();
  //       GameModal.open(
  //         '',
  //         'Для участия в конкурсе необходимо согласиться с Положением о конкурсе',
  //         Interlocutors.SYSTEM,
  //         [],
  //         true,
  //       );
  //     }
  //   },
  //   [isAgree],
  // );
  // return (
  //   <Page>
  //     <Box
  //       $maxW='460px'
  //       $direction='column'
  //       $justify='center'
  //       $align='center'
  //       $textAlign='center'
  //       $isCenter>
  //       <Text displayAs='h1' $textStyle='h1'>
  //         Авторизация
  //       </Text>
  //       <Text displayAs='div' $textStyle='p18' $mB='30px'>
  //         Для того чтобы принять участие в конкурсе и&nbsp;начать игру нужно авторизоваться через
  //         аккаунт “Большой&nbsp;перемены”
  //       </Text>
  //       {/*@ts-ignore*/}
  //       <Link displayAs='a' $isFullWidth to={AUTH_LINK} onClick={onAuthClick} target={'_self'}>
  //         <Button $full $textStyle='p18' $fontWeight='700' $style='purple' $size='lg'>
  //           <Box $justify='space-between' $align='center'>
  //             Войти с помощью
  //             <ButtonLogo src={logo} alt='Большая перемена' />
  //           </Box>
  //         </Button>
  //       </Link>
  //       <div style={{ marginTop: '16px' }}>
  //         {/*@ts-ignore*/}
  //         <Text $textStyle={'p16'} onClick={() => setIsAgree(!isAgree)}>
  //           <input type={'checkbox'} checked={isAgree} onClick={() => setIsAgree(!isAgree)} />
  //           {'Соглашаюсь с условиями '}
  //           <a
  //             href='https://bolshayaperemena.online/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5_%D0%BF%D0%BE_%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D1%83_2022.pdf'
  //             target='_blank'
  //             rel='noreferrer'>
  //             Положения о конкурсе
  //           </a>
  //         </Text>
  //       </div>
  //     </Box>
  //     <BoxBottom>
  //       <Problems />
  //     </BoxBottom>
  //   </Page>
  // );
};

export default LoginPage;
