import styled from 'styled-components';

export const TypingStyled = styled.div`
  @keyframes dots {
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        0.25em 0 0 rgba(0, 0, 0, 0),
        0.5em 0 0 rgba(0, 0, 0, 0);
    }
    40% {
      color: #333;
      text-shadow:
        0.25em 0 0 rgba(0, 0, 0, 0),
        0.5em 0 0 rgba(0, 0, 0, 0);
    }
    60% {
      text-shadow:
        0.25em 0 0 #333,
        0.5em 0 0 rgba(0, 0, 0, 0);
    }
    80%,
    100% {
      text-shadow:
        0.25em 0 0 #333,
        0.5em 0 0 #333;
    }
  }

  &:after {
    width: 12px;
    content: ' .';
    display: block;
    animation: dots 1.5s steps(5, end) infinite;
  }
`;
