/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg3_spaceMeetingWithAliens_1_1: Scenario = {
  scenarioId: SceneId.stg3_spaceMeetingWithAliens_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Космодром',
    scene_id: 'stg3_spaceMeetingWithAliens_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Уважаемые пришельцы, мы кое-что подготовили, чтобы убедить вас, какая наша планета замечательная!

        А {{name}} нами руководил и вам сейчас всё покажет!`,
        id: 'Q1',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Представляю вам наш проект!',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        text: `Вы воодушевленно и в деталях описываете пришельцам в радиопередатчик проект, который просто обязан перевернуть их пренебрежительное мнение о парке и Земле.

        Все собравшиеся: Бабуля, Петров, Сан Саныч, Малевич, Светлана Васильевна, Женя, Вжух и Квилл воодушевлены вашим рассказом и шумно поддерживают. 
        
        Вы заканчиваете рассказ и замираете в ожидании ответа пришельцев, но из радиопередатчика не доносится ни звука.`,
        id: 'Q2',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q3',
      },
      Q3: {
        text: `Земляне, ваша упорная деятельность всё же показалась нам занимательной, однако, принимая во внимание наши предыдущие обсуждения, хотелось бы повторить …`,
        id: 'Q3',
        person: Interlocutors.ALIENSHIP,
        type: StepType.message,
        nextStep: 'Q4',
      },

      Q4: {
        text: `Ну уж нет, сородичи, подождите! Я должен вам возразить!`,
        id: 'Q4',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Квилл, ты чего?',
            nextStep: 'Q5',
          },
        ],
      },

      Q5: {
        text: `{{name}} и все остальные так старались удивить вас! Старались убедить, что этот парк замечательный, чтобы вы помогли вернуть ему прежний вид. 

        Но я знаю наc, мы рациональный вид. И вас, уверен, снова не впечатлили старания землян.`,
        id: 'Q5',
        person: Interlocutors.ALIEN,
        type: StepType.message,
        nextStep: 'Q6',
      },

      Q6: {
        text: `Но проведя с ними много времени и обретя среди них друзей, я понял, что нам стоит у них поучиться!

        Поначалу никто из них даже не мог понять моих слов, но меня бескорыстно кормили, развлекали и помогали вернуться домой. Друзья работали, как одна команда, помогая друг другу в этом расследовании. И главное, {{name}} и Вжух смогли преодолеть все трудности, которые вызвал наш преображающий луч в парке. 
        
        Если вы считаете, что такая находчивость, упорство и командная работа недостойны ответной помощи, и если вы не вернёте прежний вид достопримечательностям парка, тогда я… я…
        
        Я никуда не с вами не полечу!`,
        id: 'Q6',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Квилл, ты настоящий друг! Спасибо! ',
            nextStep: 'Q7',
          },
        ],
      },

      Q7: {
        text: `Да, Квилл, мы все очень ценим твою поддержку. `,
        id: 'Q7',
        person: Interlocutors.PETROV,
        type: StepType.message,
        nextStep: 'Q8',
      },

      Q8: {
        text: `Из радиопередатчика доносилось нарастающее шипение. 

        Вдруг из-за облаков показались несколько ярких лучей, направленных в разные стороны.`,
        id: 'Q8',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Что происходит?😱 Квилл, ты разозлил их?',
            nextStep: 'Q9',
          },
        ],
      },

      Q9: {
        text: `Мы пересмотрели своё решение.

        Действительно, мы недооценили находчивость и бескорыстность землян, а также отзывчивость и открытость в межпланетных контактах. 
        
        Благодарим вас за помощь Квиллу. В благодарность, вашему парку возвращен первоначальный вид.`,
        id: 'Q9',
        person: Interlocutors.ALIENSHIP,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: `Фух! Правда? Всё получилось?`,
            nextStep: 'Q10',
          },
        ],
      },

      Q10: {
        text: `Кажется, да!

        А мы с тобой, {{name}}, отличная команда! 
        
        И с остальными тоже 😸`,
        id: 'Q10',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: `Ребята, ура! А Квилл? Ты улетаешь теперь? `,
            nextStep: 'Q11',
          },
        ],
      },

      Q11: {
        text: `Да, {{name}}, мне нужно возвращаться домой. Я обещал родным.

        Но это было хорошее приключение! И я исполнил свою мечту — побывать на Земле.
        `,
        id: 'Q11',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: `А я исполнил мечту — завести друга с другой планеты!`,
            nextStep: 'Q12',
          },
        ],
      },

      Q12: {
        text: `А-ха-ха-ха-ха! Я рад, что мы друзья.

        Сохрани этот радиопередатчик, и ты всегда сможешь пообщаться со мной! Как переключишься на нашу волну —только попроси позвать Квилла.`,
        id: 'Q12',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: `Конечно, Квилл.`,
            nextStep: 'Q13',
          },
        ],
      },

      Q13: {
        text: `Мне пора, друзья! Спасибо вам за помощь. Ещё обязательно увидимся!`,
        id: 'Q13',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: `Счастливой дороги!`,
            nextStep: 'Q14',
          },
        ],
      },

      Q14: {
        text: `Квилл по очереди обнялся и попрощался со всеми, после чего зашагал к ракете.`,
        id: 'Q14',
        person: Interlocutors.SYSTEM,
        type: StepType.message,
        nextStep: 'Q15',
      },

      Q15: {
        text: `Псс… Ребята, пойдемте скорее за мной, пока Квилл не взлетел. Покажу вам место, откуда лучший вид на полёт ракеты!`,
        id: 'Q15',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: `Идём, конечно! Вжух, не отставай!`,
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
