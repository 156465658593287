/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg3_space_1_1_1: Scenario = {
  scenarioId: SceneId.stg3_space_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.CAT,
    title: 'Космодром',
    text: `Квилл, а как ты, всё-таки, попал к нам на планету?`,
    buttons: ['Начать'],
  },
};
