import styled from 'styled-components';

export const StyledChatMessageButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -6px;

  // TODO
  // Новые стили от дизайнера
  // Не стал заводить в компонент
  & > * {
    font-size: 13px;
    font-weight: 500;
    padding: 4px 14px;
    border-radius: 8px;

    margin-top: 6px;
    margin-left: 6px;
  }
`;
