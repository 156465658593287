import styled, { css } from 'styled-components';

import colors from '../../../common/shared/constants/colors';
import {
  BREAKPOINT_PHONE,
  BREAKPOINT_SMALL_PHONE,
  BREAKPOINT_TABLET,
} from '../../../common/shared/constants/constants';

import { BaseProps } from '../../../common/shared/styles/styledBase';
import { TextStyled } from '../../../common/shared/ui/Text';
import { ISidebarMenuItemStyled } from './SidebarTypes';

export const StyledSidebarMenuItem = styled.div`
  display: flex;
`;

export const StyledSidebarMenuItemContent = styled(TextStyled)<BaseProps & ISidebarMenuItemStyled>`
  width: 100%;
  display: flex;
  align-items: center;

  border-radius: 12px;

  color: ${colors.grey2};
  text-decoration: none;

  height: 40px;

  @media screen and (min-width: ${BREAKPOINT_SMALL_PHONE}) {
    height: 50px;
  }

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    height: 40px;
  }

  ${({ $current }) => $current && `background: ${colors.primary}; color: ${colors.text};`}
  ${({ $disabled }) => $disabled && `opacity: 0.5;`}
`;

export const SidebarMenuItemIconStyled = styled.div`
  display: flex;
  align-items: center;
  margin: 0 16px;
`;

export const SidebarSupportItemStyled = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;

  height: 23px;
  border-radius: 12px;
  color: ${colors.grey2} !important;

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    height: auto;
  }
`;

export const SupportInfoWrapper = styled.div<{
  $isOnboarding?: boolean;
}>`
  display: flex !important;
  justify-content: space-between;

  ${({ $isOnboarding }) => {
    if ($isOnboarding) {
      return css`
        flex-direction: row;
        width: 340px;

        @media screen and (max-width: ${BREAKPOINT_TABLET}) {
          width: 180px;

          flex-direction: column;
        }
      `;
    }

    return css`
      flex-direction: column;
    `;
  }}

  margin-top: -8px !important;

  @media screen and (min-width: ${BREAKPOINT_SMALL_PHONE}) {
    margin-top: -15px !important;
  }

  & > * {
    margin-top: 8px !important;
    margin-bottom: 0 !important;

    @media screen and (min-width: ${BREAKPOINT_SMALL_PHONE}) {
      margin-top: 15px !important;
    }
  }
`;

export const SidebarSupportWrapperStyled = styled.div<{
  $isMobileMenuSidebar?: boolean;
  $isOnboarding?: boolean;
}>`
  border-radius: 16px;

  padding-top: 16px;
  padding-bottom: 16px;

  ${({ $isOnboarding }) => {
    if (!$isOnboarding) {
      return css`
        background: ${colors.grey};
      `;
    }

    return css`
      display: inline-block;
      margin-left: -15px;
    `;
  }}

  ${(p) => {
    if (!p.$isMobileMenuSidebar && !p.$isOnboarding) {
      return css`
        margin-top: 24px;
      `;
    }

    return css`
      margin-top: auto;
    `;
  }}

  & > * + * {
    margin-top: 8px;

    @media screen and (min-width: ${BREAKPOINT_SMALL_PHONE}) {
      margin-top: 15px;
    }
  }
`;

export const SidebarSpacer = styled.div`
  height: 16px;
`;

export const SidebarWrapperStyled = styled.div<{ $isMobileMenuSidebar?: boolean }>`
  width: 100%;
  background: ${colors.white};

  ${StyledSidebarMenuItem} + ${StyledSidebarMenuItem} {
    ${(p) => {
      if (!p.$isMobileMenuSidebar) {
        return css`
          margin-top: 20px;
        `;
      }

      return css`
        margin-top: 8px;

        @media screen and (min-width: ${BREAKPOINT_SMALL_PHONE}) {
          margin-top: 16px;
        }
      `;
    }}
  }

  ${(p) => {
    if (!p.$isMobileMenuSidebar) {
      return css`
        padding: 10px;
        border-radius: 16px;
      `;
    }
  }}
`;
