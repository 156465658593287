/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_moscowCityMeetingGranny_1_1_1: Scenario = {
  scenarioId: SceneId.stg2_moscowCityMeetingGranny_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Москва сити',
    text: `Удивительно, но в Москва-Сити вы видите довольную Бабулю, сидящую у одного из небоскрёбов.`,
    buttons: ['Начать'],
  },
};
