import React, { MouseEventHandler, TouchEventHandler, useCallback } from 'react';
import { PointsOfInterest } from '../../types';

import POINTS_CONFIG from './config';
import LocalStorage from '../../../common/shared/utils/LocalStorage';

import MapPin from '../MapPin';
import MapSource from '../MapSource';
import CatSuggestions from '../CatSuggestions';

import { MapWrapper } from './MapStyled';
import { MapSourceStage } from '../MapSource/MapSourceTypes';
import { Coords, MapHandler, MapHandlerEvent, MapProps, MouseState } from './MapProps';

import { useMount } from '../../../common/shared/hooks/useMount';
import { useIsFirstStageCompleted } from '../../hooks/stageProgressChecks/useIsFirstStageCompleted';

import { Logger } from '../../../common/shared/utils/Logger';
import { LAST_USER_COORDINATES } from '../../../common/shared/constants/constants';

import { useIsSecondStageCompleted } from '../../hooks/stageProgressChecks/useIsSecondStageCompleted';
import { useIsSecondStageFirstActCompleted } from '../../hooks/stageProgressChecks/useIsSecondStageFirstActCompleted';
import { useIsSecondStageSecondActCompleted } from '../../hooks/stageProgressChecks/useIsSecondStageSecondActCompleted';

import { MapPinType } from '../../hooks/scenarioHelpers/types';
import { useIsThirdStageFirstActCompleted } from '../../hooks/stageProgressChecks/useIsThirdStageFirstActCompleted';
import { GameModal } from '../GameModal';

const navigatePointDellay = 500;

interface StorageCoords {
  top: number;
  left: number;
}

interface RenderPointProps {
  type?: MapPinType;
  stage: MapSourceStage;
  point: PointsOfInterest;

  onClick: () => void;
}

const getTouchHandlerCoords = (e: MapHandlerEvent): Coords | undefined => {
  if (e.touches && e.touches.length > 1) return;

  try {
    if (e && e.changedTouches && e.changedTouches[0]) e = e.changedTouches[0];
    const coords = { x: e.pageX, y: e.pageY };

    return coords;
  } catch (error) {
    Logger.log('err');
  }
};

function Point({ point, onClick, stage, type }: RenderPointProps) {
  return (
    <MapPin
      $id={point}
      type={type}
      $top={POINTS_CONFIG[point][stage].$top}
      $left={POINTS_CONFIG[point][stage].$left}
      onClick={onClick}
    />
  );
}

export const Map = (props: MapProps) => {
  const firstCompleted = useIsFirstStageCompleted();
  const s2a1Completed = useIsSecondStageFirstActCompleted();
  const s2a2Completed = useIsSecondStageSecondActCompleted();
  const s2Completed = useIsSecondStageCompleted();
  const s3a1Completed = useIsThirdStageFirstActCompleted();

  let currentStage = s3a1Completed ? MapSourceStage.stage3act2 : MapSourceStage.stage3act1;

  if (!s2Completed) {
    currentStage = MapSourceStage.stage2act3;
  }
  if (!s2a2Completed) {
    currentStage = MapSourceStage.stage2act2;
  }
  if (!s2a1Completed) {
    currentStage = MapSourceStage.stage2;
  }
  if (!firstCompleted) {
    currentStage = MapSourceStage.stage1;
  }

  const mapWrapper = React.useRef<HTMLDivElement>(null);
  const mapContainer = React.useRef<HTMLDivElement>(null);
  const mouseState = React.useRef<MouseState>({
    isPressedMouse: false,
    lastPressedCoords: { x: 0, y: 0 },
  });

  const shiftMap = React.useCallback(
    (shiftX: number, shiftY: number, coords?: Coords, forceLeftTop?: StorageCoords) => {
      if (mapContainer.current && mapWrapper.current) {
        const maxWidth = mapWrapper.current.clientWidth;
        const maxHeight = mapWrapper.current.clientHeight;

        const width = mapContainer.current.clientWidth;
        const height = mapContainer.current.clientHeight;

        let top = mapContainer.current.offsetTop + shiftY;
        let left = mapContainer.current.offsetLeft + shiftX;

        if (forceLeftTop) {
          top = forceLeftTop.top;
          left = forceLeftTop.left;
        }

        if (width + left < maxWidth) left = maxWidth - width;
        if (height + top < maxHeight) top = maxHeight - height;
        if (top > 0) top = 0;
        if (left > 0) left = 0;

        mapContainer.current.style.top = `${top}px`;
        mapContainer.current.style.left = `${left}px`;
        LocalStorage.set(LAST_USER_COORDINATES, { top, left });

        if (coords) mouseState.current.lastPressedCoords = coords;
      }
    },
    [],
  );

  const handleResize = React.useCallback(() => {
    shiftMap(0, 0);
  }, [shiftMap]);

  const navigateToPoint = useCallback(
    (coords: { $left: number; $top: number }) => {
      const currentMap = mapContainer.current;
      const currentMapWrapper = mapWrapper.current;

      if (currentMap && currentMapWrapper) {
        const mapData = currentMap.getBoundingClientRect();
        const mapWrapperData = currentMapWrapper.getBoundingClientRect();

        const left = mapData.width * (coords.$left / 100) - mapWrapperData.width / 2;
        const top = mapData.height * (coords.$top / 100) - mapWrapperData.height / 2;

        currentMap.style.transition = `all ${navigatePointDellay}ms`;
        shiftMap(0, 0, undefined, { left: -left, top: -top });
        setTimeout(() => (currentMap.style.transition = ''), navigatePointDellay);

        // TODO: нужно ли блочить повторные вызовы?

        GameModal.close();
      }
    },
    [shiftMap],
  );

  const onClickToNavigatePoint = useCallback(
    (event: MouseEvent) => {
      const element = event.target as HTMLDivElement | undefined;

      if (element && element.className && element.dataset) {
        const isNavigatePoint = element.className === 'point-anchor';

        if (isNavigatePoint) {
          const type = element.dataset.point;

          if (!type) return;

          try {
            const coords = POINTS_CONFIG[type as PointsOfInterest][currentStage];
            navigateToPoint(coords);
          } catch (ignore) {
            console.log(ignore);
          }
        }
      }
    },
    [currentStage, navigateToPoint],
  );

  useMount(
    () => {
      window.addEventListener('resize', handleResize);
      window.addEventListener('click', onClickToNavigatePoint);
      shiftMap(0, 0, undefined, LocalStorage.get<StorageCoords>(LAST_USER_COORDINATES));
    },
    () => {
      window.removeEventListener('click', onClickToNavigatePoint);
      window.removeEventListener('resize', handleResize);
    },
  );

  const handleMouseUpOrLeave: MapHandler = React.useCallback(() => {
    mouseState.current.isPressedMouse = false;
  }, []);

  const handleMouseDown: MapHandler = React.useCallback((e: MapHandlerEvent) => {
    const coords = getTouchHandlerCoords(e);

    if (coords) {
      mouseState.current.isPressedMouse = true;
      mouseState.current.lastPressedCoords = coords;
    }
  }, []);

  const handleMouseMove: MapHandler = React.useCallback(
    (e: MapHandlerEvent) => {
      if (mouseState.current.isPressedMouse) {
        const coords = getTouchHandlerCoords(e);

        if (coords) {
          const shiftX = coords.x - mouseState.current.lastPressedCoords.x;
          const shiftY = coords.y - mouseState.current.lastPressedCoords.y;

          shiftMap(shiftX, shiftY, coords);
        }
      }
    },
    [shiftMap],
  );

  const pins = React.useMemo(() => {
    return (props.clickHandlers || []).map((a) => (
      <Point type={a.type} stage={currentStage} key={a.point} point={a.point} onClick={a.handler} />
    ));
  }, [props.clickHandlers, currentStage]);

  const { onCatSuggestionClick } = props;

  return (
    <>
      <GameModal />
      <MapWrapper
        ref={mapWrapper}
        onTouchMove={handleMouseMove as TouchEventHandler<HTMLDivElement>}
        onTouchStart={handleMouseDown as TouchEventHandler<HTMLDivElement>}
        onTouchEnd={handleMouseUpOrLeave as TouchEventHandler<HTMLDivElement>}
        onMouseMove={handleMouseMove as MouseEventHandler<HTMLDivElement>}
        onMouseDown={handleMouseDown as MouseEventHandler<HTMLDivElement>}
        onMouseUp={handleMouseUpOrLeave as MouseEventHandler<HTMLDivElement>}
        onMouseLeave={handleMouseUpOrLeave as MouseEventHandler<HTMLDivElement>}>
        {onCatSuggestionClick ? <CatSuggestions onClick={onCatSuggestionClick} /> : null}
        <MapSource
          pins={pins}
          innerRef={mapContainer}
          stage={currentStage}
          currentStageName={props.clickHandlers[0]}
        />
      </MapWrapper>
    </>
  );
};
