/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../types/scenario';

export const scenario_unsaved: Scenario = {
  scenarioId: SceneId.scenario_unsaved,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Внимание',
    text: `Вы не завершили выполнение задания`,
    buttons: ['Продолжить'],
  },
};

// scenarioId: SceneId.scenario_unsaved,
// type: ScenarioType.modal,
// data: {
//   closable: false,
//   person: Interlocutors.SYSTEM,
//   title: 'Вжух',
//   text: `У тебя талант! Покажу видео всем, пусть знают кто приедет нам помогать!
//   P.S. Я уже купил тебе билет в парк. Встретимся там через 3 дня!`,
//   buttons: [],
// },
