import React from 'react';

import Link from '../../components/Link';
import { LogoProps } from './LogoTypes';
import { StyledLogo } from './LogoStyled';
import { routes } from '../../../common/shared/constants/routes';

import useViewport from '../../hooks/useViewport';

const mobileLogo = '/mobile_logo.svg';
const desktopLogo = '/desktop_logo.svg';

const Logo = (props: LogoProps) => {
  const { isMobile } = useViewport();
  const icon = React.useMemo(() => (isMobile ? mobileLogo : desktopLogo), [isMobile]);

  if (props.withLink) {
    return (
      <Link to={routes.dashboard}>
        <StyledLogo $isOnboarding={props.isOnboarding} className={props.className} src={icon} />
      </Link>
    );
  }

  return <StyledLogo $isOnboarding={props.isOnboarding} className={props.className} src={icon} />;
};

export default React.memo(Logo);
