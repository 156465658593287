/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId, StepType } from '../../../scenario';

export const scenario_2_0: Scenario = {
  scenarioId: SceneId.scenario_2_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Новости парка',
    text: `Вы возвращаетесь в «Большую перемену» и видите замороженный парк. Местами туман рассеялся, показались новые достопримечательности.

Вас встречает Вжух.`,
    buttons: ['Перейти в парк'],
  },
};

const EXIT_ID = 'EXIT_ID';
const person = Interlocutors.CAT;
export const scenario_2_0_1: Scenario = {
  scenarioId: SceneId.scenario_2_0_1,
  type: ScenarioType.chat,
  data: {
    scene_id: 'scenario_2_0_1',
    startId: 'Q1',
    steps: {
      Q1: {
        type: StepType.message,
        id: 'Q1',
        person,
        text: 'Пока тебя не было, тут столько всего произошло! Помнишь, в конце первого этапа холодало? Так вот, на город опустился ледяной туман и заморозил «Большую перемену»! Бррр… 🙀',
        nextStep: 'Q2',
      },
      Q2: {
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, мне уже не терпится начать!',
            nextStep: 'Q3',
          },
          {
            id: 'b2',
            text: 'А что, без меня Петров не справится?',
            nextStep: 'Q3',
          },
        ],
        id: 'Q2',
        person,
        text: `Петров уже начал ломать голову над тем, как бы все здесь разморозить, и очень надеется на твою помощь!`,
        callToAction: '[[Готов/Готова]] к новым приключениям?',
      },
      Q3: {
        id: 'Q3',
        type: StepType.message,
        nextStep: EXIT_ID,
        person,
        text: 'Без твоей помощи никак не обойтись! Беги скорее к Петрову в Иннополис.',
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
