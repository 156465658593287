/* eslint-disable max-len */
import React from 'react';
import { FinishPageProps, FinishStepType } from './FinishPageTypes';
import {
  StyledFinishPage,
  StyledFinishPageWrapper,
  StyledFinishPageWrapperText,
  StyledPersons,
  StyledPersonsImage,
  StyledPersonsWrapper,
  StyledStar1,
  StyledStar2,
  StyledStar3,
  StyledStar4,
  StyledStar5,
  StyledNextBtn,
} from './FinishPageStyled';

import useViewport from '../../hooks/useViewport';

import { Text } from '../../../common/shared/ui/Text';

import game_finish from '../../../assets/images/onboarding_cat.svg';
import SpriteStar from '../../../assets/images/onboarding_sprite_star.svg';
import { TemplatedText } from '../../components/TemplatedText';
import { FAIL_TEXT, RESERVE_TEXT, TOP600_TEXT } from '../../../common/shared/constants/constants';
import { SupportInfo } from '../../widgets/Sidebar/Sidebar';

const FinishPage = (props: FinishPageProps) => {
  const { isMobile } = useViewport();

  const Image = React.useMemo(() => {
    return (
      <StyledPersonsWrapper>
        <StyledPersonsImage>
          <div style={{ position: 'relative', margin: '0 auto' }}>
            <StyledStar1 src={SpriteStar} />
            <StyledStar2 src={SpriteStar} />
            <StyledStar3 src={SpriteStar} />
            <StyledStar4 src={SpriteStar} />
            <StyledStar5 src={SpriteStar} />
            <StyledPersons src={game_finish} />
          </div>
        </StyledPersonsImage>
      </StyledPersonsWrapper>
    );
  }, []);

  let title = 'Игра пройдена!'; // 'Онлайн-игра завершена';
  let description = '';
  switch (props.finishStep) {
    case FinishStepType.stage1:
      if (props.nextStageAvailable) {
        // доступен след этап
        title = 'Первый этап пройден!';
        description =
          'Обитатели «Большой перемены» тебе очень благодарны! Новые приключения уже ждут. Скорее заходи в игру, чтобы разгадать все тайны.';
      } else {
        // след этап еще не доступен
        title = 'Ура! Ты прошел/прошла первый этап!';
        description =
          'Обитатели «Большой перемены» тебе очень благодарны и ждут новой встречи после 25 апреля. Возвращайся, будет интересно!';
      }
      break;
    case FinishStepType.stage2:
      if (props.nextStageAvailable) {
        // доступен след этап
        title = 'Поздравляем!';
        description = `Обитатели «Большой перемены» тебе очень благодарны! Новые приключения уже ждут. Скорее заходи в игру, чтобы разгадать все тайны.`;
      } else {
        // след этап еще не доступен
        title = 'Поздравляем!';
        description = `Сейчас начинается самый важный этап игры.  Мы будем начислять баллы за выполненные заданий для общего рейтинга, по которому будет происходить отбор участников в следующий этап Конкурса.<br>
        В твоем  профиле (вход в него в игре находится в верхнем правом углу экрана) уже появились задания по вызову.<br>
        Тебе нужно внимательно прочитать все задания и выбрать для себя один вызов, задания которого ты будешь выполнять. Выполнить нужно будет оба задания вызова. Файл с решением необходимо загрузить и отправить не позднее 16:00 по московскому времени 29 мая.<br>
        Приходи после 31 мая, здесь появится продолжение игры и еще несколько обязательных заданий, выполнение которых тоже будет оцениваться.<br>
        Желаем удачи!
        `;
      }
      break;
    case FinishStepType.finish:
      title = 'Игра пройдена до конца!';
      description = `Результаты участия в дистанционном этапе Конкурса станут известны после 11 июня. Напоминаем, что их ты сможешь посмотреть в своем профиле в игре.

      Пока ты ждешь результатов, ты можешь изучить другие мероприятия, которые постоянно появляются на нашем сайте <a href="https://большаяперемена.онлайн/" target="_blank">большаяперемена.онлайн</a>`;
      break;
    case FinishStepType.top600:
      description = TOP600_TEXT;
      break;
    case FinishStepType.reserve:
      description = RESERVE_TEXT;
      break;
    case FinishStepType.fail:
      description = FAIL_TEXT;
      break;
    case FinishStepType.competition_finished:
      description = `Прием работ в рамках онлайн-игры окончен. Сейчас команда «Большой перемены» проверяет ваши тесты и работы.

Финальные баллы за онлайн-игру появятся в вашем профиле. Вы можете следить за обновлениями конкурса в <a href="https://vk.com/bpcontest">группе ВК</a>.`;
      break;
    case FinishStepType.end_main_game:
      description = `Можешь пока отдохнуть, но возвращайся к нам 3 июня, ведь твои приключения еще не закончились. Теперь ты сможешь просто играть, не беспокоясь о времени и баллах. 
 
      Если ты не успел выполнить все задания, не переживай, впереди еще много разных конкурсов на нашей платформе. 
       
      Если ты отправил на проверку все задания, то наберись терпения – баллы будут доступны в профиле участника после 8 июня. 
       
      Ну, а пока ты можешь указать в своем профиле данные наставника, если он у тебя был, и отправить ссылку на рассказ о «Добром деле». Добавить ссылку и данные наставника нужно до 5 июня.  
       
      Ждем тебя 3 июня!`;
      break;
    case FinishStepType.moreThreshold:
      title = 'Поздравляем!';
      description = `Ты набрал больше 341 балла и прошел в следующий этап – онлайн-собеседование. 
      <br/>
      В ближайшие три дня с тобой свяжутся наши коллеги, чтобы записать тебя на него. Собеседование состоится в период с 11 по 15 июня. 
      Обязательно ознакомься с правилами его проведения в <a href="https://bolshayaperemena.online/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5_%D0%BF%D0%BE_%D0%BA%D0%BE%D0%BDhttps:/bolshayaperemena.online/%D0%9F%D0%BE%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5_%D0%BF%D0%BE_%D0%BA%D0%BE%D0%BD%D0%BA%D1%83%D1%80%D1%81%D1%83_2022.pdf%D0%BA%D1%83%D1%80%D1%81%D1%83_2022.pdf" target="_blank">Положении о конкурсе</a>. <br/>
      Обрати внимание, что для связи мы используем те контактные данные (телефон, e-mail), которые указаны в твоем личном кабинете на платформе <a href="https://большаяперемена.онлайн/" target="_blank">«Большая перемена»</a>. 
      <br/>
      Обязательно проверь, корректные ли данные указаны. Это можно сделать в разделе «Настройки».`;
      break;
    case FinishStepType.lessThreshold:
      title = 'Привет!';
      description = `К сожалению, ты набрал меньше 342 баллов, что не позволяет тебе пройти в следующий этап. 
      <br/>
      Но даже не думай расстраиваться, ведь это была хорошая тренировка для участия в других конкурсах. 
      Кстати, доступные тебе задания размещены в личном кабинете на платформе <a href="https://большаяперемена.онлайн/" target="_blank">«Большая перемена»</a>. Желаем тебе удачи!`;
      break;
  }

  return (
    <StyledFinishPage>
      <StyledFinishPageWrapper>
        <div />

        <StyledFinishPageWrapperText>
          <Text $textStyle={isMobile ? 'p36' : 'h2'}>{title}</Text>
          <Text displayAs='p' $textStyle={'p16'}>
            <TemplatedText isHTML>{description}</TemplatedText>
            {props.onNextClick && props.nextStageAvailable && (
              <StyledNextBtn onClick={props.onNextClick}>Продолжить</StyledNextBtn>
            )}
          </Text>
        </StyledFinishPageWrapperText>
        {isMobile && Image}
        {!isMobile && (
          <div>
            <SupportInfo isOnboarding />
          </div>
        )}
      </StyledFinishPageWrapper>
      {!isMobile && Image}
    </StyledFinishPage>
  );
};

export default FinishPage;
