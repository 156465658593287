/* eslint-disable max-len */
import {
  Step,
  SceneId,
  StepType,
  Scenario,
  ScenarioType,
  Interlocutors,
  SystemStepType,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';
import { images } from '../../tests/images';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const scenario_2_4_0: Scenario = {
  scenarioId: SceneId.scenario_2_4_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.CAT,
    title: 'Вжух',
    text: `Привет {{NAME}}, Гарин повсюду тебя ищет! Кажется, ему снова нужна твоя помощь. Летим на Космодром, он ждет нас.`,
    buttons: ['Отправиться на Космодром'],
  },
};

export const scenario_2_4_i: Scenario = {
  scenarioId: SceneId.scenario_2_4_i,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Эрмитаж',
    text: `Вы попадаете на Космодром. Внутри — белоснежные стены и ракеты, украшенные звёздами, огромные рельсы и система управления полётами. 

    Вдруг ты слышишь голос «{{NAME}}, это Гарин, Интеллект 2.0 отключает от работы все наши спутниковые устройства, нужно запустить ракету с GPS, чтобы восстановить сигнал».
    `,
    buttons: ['Поговорить с бортом управления ракетой Ангара'],
  },
};

export const scenario_2_4: Scenario = {
  scenarioId: SceneId.scenario_2_4,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    scene_id: 'scenario_2_4',
    startId: 'Q1',
    title: 'Космодром',
    steps: {
      Q1: {
        text: `Ты подбегаешь к пульту управления полётами. Видишь на мониторе сигнал и говоришь «Ангара, это {{NAME}}, приём».

          «{{NAME}} тебя слышу. Приём. Диктую алгоритм запуска: готовность к пуску 1 минута, космический аппарат готов, ключ на старт, протяжка, продувка, ключ на дренаж, наддув, земля-борт, ПУСК. Как поняли?».
          `,
        id: 'Q1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Команду поняли, выполняем',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        text: `Итак, ты у пульта. Твоя задача правильно расставить последовательность запуска. «Готовность к запуску 1 минута, космический аппарат готов…».`,
        id: 'Q2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ключ на старт, ключ на дренаж, протяжка, продувка, наддув, земля-борт, ПУСК',
            nextStep: 'Q2_1',
          },
          {
            id: 'b2',
            text: 'Ключ на старт, протяжка, продувка, ключ на дренаж, наддув, земля-борт, ПУСК',
            nextStep: 'Q2_2',
          },
          {
            id: 'b3',
            text: 'Протяжка, продувка, ключ на дренаж, наддув, земля-борт, ПУСК',
            nextStep: 'Q2_1',
          },
        ],
      },
      Q2_1: {
        text: `«Запуск запрещён!». Выберите верный алгоритм.`,
        id: 'Q2_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Попробовать заново',
            nextStep: 'Q1',
          },
        ],
      },
      Q2_2: {
        text: `Ты слышишь рёв турбин — это успех, ракета запущена. Сигнал из кабины пилота: «{{NAME}} это пилот, связь… прерывается. Ищите резервный… сигнал».`,
        id: 'Q2_2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Проверить схему связи в бортовом журнале',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        text: `Ты смотришь бортовой журнал «Площадка 1. Ангара»: связь по линии «Космодром - ОБ - Иннополис». 

          А что же такое ОБ?
          `,
        id: 'Q3',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Пройти тест на креативность',
            nextStep: 'CREATIVE_CUBE/0',
          },
        ],
      },

      ...splitStep({
        // ТЕСТ: КРЕАТИВНОСТЬ 2 кубик
        id: 'CREATIVE_CUBE',
        person: Interlocutors.PETROV,
        delay: 7000,
        type: StepType.message,
        text: `Как можно изменить мягкие кубики для того, чтобы детям стало интереснее играть с ним? Придумай самые удачные, оригинальные и необычные способы изменения кибиков. Не беспокойся о том, насколько сложно будет осуществить твои изменения. Думай только о том, как можно улучшить эту игрушку. Перечисли все возможные изменения мягких кубиков в указанных ниже полях. Напоминаю, у тебя на это есть 10 минут.

Записывай по одному варианту в поле ввода и обязательно жми кнопку «Отправить ответ». Для следующей твоей идеи появится новое поле ввода. То есть нужно писать и отправлять по одной идее за раз. Всего ты можешь предложить 15 вариантов улучшений, так что чем больше — тем лучше.

И не забудь, твои варианты должны быть приличными и осмысленными.`,
        nextStep: 'CREATIVE1',
      }),
      CREATIVE1: {
        person: Interlocutors.PETROV,
        id: 'CREATIVE1',
        type: StepType.message,
        image: { uri: images.cube, title: '' },
        text: `Ну все, время пошло! А вот и кубики, которые ты будешь улучшать. Пиши свои варианты!`,
        nextStep: 'CREATIVE2',
      },
      CREATIVE2: {
        id: 'CREATIVE2',
        person: Interlocutors.PETROV,
        type: StepType.system,
        _meta: {
          type: SystemStepType.limitedTimeInput,
          time: 10 * 60,
          maxAnswers: 15,
          exitStep: 'CREATIVE-EXIT/0',
        },
        nextStep: 'CREATIVE3',
      },
      CREATIVE3: {
        id: 'CREATIVE3',
        person: Interlocutors.PETROV,
        text: '',
        type: StepType.text_question,
        variable: 'CREATIVE_CUBE',
        nextStep: 'CREATIVE3',
      },
      ...splitStep({
        id: 'CREATIVE-EXIT',
        person: Interlocutors.PETROV,
        type: StepType.message,
        text: `Вот это да! Вот это крутые улучшения! Уверен, что с такими кубиками детям будет играть намного веселее! 😍
        
        ОБ — это Останкинская Башня.`,
        nextStep: 'CREATIVE_BOX/0',
      }),

      ...splitStep({
        // ТЕСТ: КРЕАТИВНОСТЬ 3 коробки
        id: 'CREATIVE_BOX',
        person: Interlocutors.PETROV,
        delay: 7000,
        type: StepType.message,
        text: `Почему люди выбрасывают пустые коробки?! Ведь из них можно сделать много интересного! Придумай как можно интересно и необычно использовать пустые коробки – любого размера и в любом количестве. Не ограничивай свою фантазию тем , что ты когда-то слышал или видел. Придумай столько способов использования коробок, сколько ты сможешь, и запиши их в полях для ответов ниже. `,
        nextStep: 'CREATIVE_BOX_1',
      }),
      CREATIVE_BOX_1: {
        person: Interlocutors.PETROV,
        id: 'CREATIVE_BOX_1',
        type: StepType.message,
        text: `Ну все, время пошло! Пиши свои варианты!`,
        nextStep: 'CREATIVE_BOX_2',
      },
      CREATIVE_BOX_2: {
        id: 'CREATIVE_BOX_2',
        person: Interlocutors.PETROV,
        type: StepType.system,
        _meta: {
          type: SystemStepType.limitedTimeInput,
          time: 10 * 60,
          maxAnswers: 15,
          exitStep: 'CREATIVE-BOX-EXIT/0',
        },
        nextStep: 'CREATIVE_BOX_3',
      },
      CREATIVE_BOX_3: {
        id: 'CREATIVE_BOX_3',
        person: Interlocutors.PETROV,
        text: '',
        type: StepType.text_question,
        variable: 'CREATIVE_BOX',
        nextStep: 'CREATIVE_BOX_3',
      },
      ...splitStep({
        id: 'CREATIVE-BOX-EXIT',
        person: Interlocutors.PETROV,
        type: StepType.message,
        text: `Вот это да! Никогда бы не подумал.`,
        nextStep: 'CREATIVE_TEA/0',
      }),

      ...splitStep({
        // ТЕСТ: КРЕАТИВНОСТЬ 3 чай
        id: 'CREATIVE_TEA',
        person: Interlocutors.PETROV,
        delay: 7000,
        type: StepType.message,
        text: `Ты любишь пить чай? Знаешь, что в мире производятся миллиарды пакетиков в год! Это же огромное количество. Придумай, как можно интересно и необычно использовать чайные пакетики – любого размера и в любом количестве. Не ограничивай свою фантазию тем, что ты когда-то слышал или видел. Придумай столько способов использования чайных пакетиков, сколько ты сможешь, и запиши их в полях для ответов ниже. Каждый способ запиши в отдельном поле. Время на выполнение задания - 10 минут `,
        nextStep: 'CREATIVE_TEA_1',
      }),
      CREATIVE_TEA_1: {
        person: Interlocutors.PETROV,
        id: 'CREATIVE_TEA_1',
        type: StepType.message,
        text: `Ну все, время пошло! Пиши свои варианты!`,
        nextStep: 'CREATIVE_TEA_2',
      },
      CREATIVE_TEA_2: {
        id: 'CREATIVE_TEA_2',
        person: Interlocutors.PETROV,
        type: StepType.system,
        _meta: {
          type: SystemStepType.limitedTimeInput,
          time: 10 * 60,
          maxAnswers: 15,
          exitStep: 'CREATIVE-TEA-EXIT/0',
        },
        nextStep: 'CREATIVE_TEA_3',
      },
      CREATIVE_TEA_3: {
        id: 'CREATIVE_TEA_3',
        person: Interlocutors.PETROV,
        text: '',
        type: StepType.text_question,
        variable: 'CREATIVE_TEA',
        nextStep: 'CREATIVE_TEA_3',
      },
      ...splitStep({
        id: 'CREATIVE-TEA-EXIT',
        person: Interlocutors.PETROV,
        type: StepType.message,
        text: `Вот это да! Никогда бы не подумал.`,
        nextStep: 'Q4',
      }),

      Q4: {
        text: `{{NAME}} отправляйтесь в Останкино, установите сигнал со спутником и передайте на Иннополис.`,
        id: 'Q4',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться в Останкино',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
