/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';
import interlocutors from '../../../interlocutors';

export const scenario_1_4: Scenario = {
  scenarioId: SceneId.scenario_1_4,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `Видел, что в библиотеке происходит? Чудеса, да и только! Ничего не понятно, но очень интересно.

    Пойдём теперь к озеру Байкал, спросим у живущей там Бабули, не видела ли она ничего странного`,
    buttons: ['Закрыть'],
  },
};
