/* eslint-disable max-len */
import {
  Interlocutors,
  QuestionButton,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../scenario';
import { splitStep } from '../../../utils/ScenarioUtils';

const buttons: QuestionButton[] = [
  {
    id: 'b1',
    text: 'Что тут все же произошло?',
    nextStep: 'Q5',
  },
  {
    id: 'b2',
    text: 'Где мы сейчас находимся?',
    nextStep: 'Q6/0',
  },
  {
    id: 'b3',
    text: 'Расскажи о себе',
    nextStep: 'Q7',
  },
  {
    id: 'b4',
    text: 'Что дальше?',
    nextStep: 'Q8',
  },
];

const EXIT_ID = 'EXIT_ID';

export const scenario_1_1: Scenario = {
  scenarioId: SceneId.scenario_1_1,
  type: ScenarioType.chat,
  data: {
    scene_id: 'scenario_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        type: StepType.text_question,
        id: 'Q1',
        person: Interlocutors.CAT,
        text: `Привет!
        Меня зовут Вжух, я говорящий кот парка «Большая перемена». А как тебя зовут?`,
        callToAction: 'Напиши свое имя',
        nextStep: 'Q2',
        variable: 'NAME',
      },
      Q2: {
        type: StepType.message,
        id: 'Q2',
        person: Interlocutors.CAT,
        text: `Я так рад, что нашел тебя, {{NAME}}! 😻 Видишь ли, у нас тут происходит что-то необъяснимое. Раньше парк «Большая перемена» был ярким и жизнерадостным местом с самыми уникальными и прекрасными объектами России.`,
        nextStep: 'Q2_2',
      },
      Q2_2: {
        type: StepType.message,
        id: 'Q2_2',
        person: Interlocutors.CAT,
        text: `Но одним утром все изменилось. Позитивные и деятельные обитатели парка начали испытывать скуку, и их творческие и интересные идеи пропали. Парк погрузился в туман и то тут, то там с достопримечательностями стало происходить что-то странное 🙀`,
        nextStep: 'Q2_3',
      },
      Q2_3: {
        type: StepType.question,
        id: 'Q2_3',
        person: Interlocutors.CAT,
        maxAnswers: 1,
        text: `Никто не знал, что произошло и только мне довелось видеть все собственными глазами. Я, знаешь ли, по ночам люблю охотиться, пока все спят. Я видел, как вместе с туманом, в парк пришло что-то еще, видел всполохи электричества и шипение. Один идти туда я боюсь, но разобраться в этом нужно 😿`,
        callToAction: 'Проводишь меня?',
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q3',
            text: 'Да, пойдем',
          },
          {
            id: 'b2',
            nextStep: 'Q4',
            text: 'Вот еще, пойду все разузнаю самостоятельно',
          },
        ],
      },
      Q3: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q3',
        person: Interlocutors.CAT,
        text: 'Как хорошо, что ты здесь! Вдвоем не так страшно. Пока мы идем, может, ты хочешь что-то узнать?',
        callToAction: 'Выбери вариант',
        buttons: buttons,
      },
      Q4: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q4',
        person: Interlocutors.CAT,
        text: 'Да у тебя храброе сердце, я вижу. Ну что ж, это здорово, но я все равно пойду с тобой. Пока мы идем, может ты хочешь что-то узнать?',
        callToAction: 'Выбери вариант',
        buttons: buttons,
      },
      Q5: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q5',
        person: Interlocutors.CAT,
        text: 'Как я и говорил раньше, наш парк погрузился в туман, экспонаты пострадали, я еле лапы унес на забор. Но мы все выясним.',
        callToAction: 'Выбери вариант',
        buttons: buttons,
      },
      ...splitStep({
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q6',
        person: Interlocutors.CAT,
        text: `Ты находишься в «Большой Перемене». Это уникальный парк, где собраны копии самых интересных и удивительных достопримечательностей России!

        Здесь так здорово, что многие, приехав в парк однажды, остаются здесь жить и работать... По крайней мере, раньше так было, до того, как пришел этот странный туман… 😿`,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q6_1',
            text: 'Ого ...',
          },
        ],
      }),
      Q6_1: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q6_1',
        person: Interlocutors.CAT,
        text: 'А сейчас здесь почти никого не осталось, некому присматривать за парком. После тумана большинство достопримечательностей пришли в негодность... Где-то поломало деревья, где-то барахлит электричество, а в каких-то точках пропала связь с внешним миром... Нужно обязательно привести здесь все в порядок! Работы непочатый край! 🙀',
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q6_2',
            text: 'Вот так дела!',
          },
        ],
      },
      Q6_2: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q6_2',
        person: Interlocutors.CAT,
        text: `Но мы не теряем надежду и знаем, что с твоей помощью парк обретет былой цветущий вид! 😻

        Если ты поможешь, мы в долгу не останемся. Может быть, ты даже сможешь поехать в Артек 😸`,
        callToAction: 'Выбери вариант',
        buttons: buttons,
      },
      Q7: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q7',
        person: Interlocutors.CAT,
        text: 'Я твой помощник, меня зовут кот Вжух. Сколько себя помню, гулял по этому парку с совсем малых лап 😸',
        callToAction: 'Выбери вариант',
        buttons: buttons,
      },
      Q8: {
        type: StepType.message,
        id: 'Q8',
        person: Interlocutors.CAT,
        text: 'Пойдем, я покажу тебе наш когда-то замечательный парк 😿',
        nextStep: EXIT_ID,
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
