import styled from 'styled-components';
import { BREAKPOINT_PHONE } from '../../constants/constants';

export const StyledLoader = styled.div`
  top: -40px;
  right: -25px;
  position: absolute;

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    top: -20px;
    right: -12px;
  }

  @-webkit-keyframes ball-spin-fade-loader {
    50% {
      opacity: 0.3;
      -webkit-transform: scale(0.4);
      transform: scale(0.4);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @keyframes ball-spin-fade-loader {
    50% {
      opacity: 0.3;
      -webkit-transform: scale(0.4);
      transform: scale(0.4);
    }

    100% {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  & > div:nth-child(1) {
    top: 25px;
    left: 0;
    -webkit-animation: ball-spin-fade-loader 1s -0.96s infinite linear;
    animation: ball-spin-fade-loader 1s -0.96s infinite linear;

    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      top: 12px;
    }
  }

  & > div:nth-child(2) {
    top: 18px;
    left: 18px;
    -webkit-animation: ball-spin-fade-loader 1s -0.84s infinite linear;
    animation: ball-spin-fade-loader 1s -0.84s infinite linear;

    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      top: 9px;
      left: 9px;
    }
  }

  & > div:nth-child(3) {
    top: 0;
    left: 25px;
    -webkit-animation: ball-spin-fade-loader 1s -0.72s infinite linear;
    animation: ball-spin-fade-loader 1s -0.72s infinite linear;

    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      left: 13px;
    }
  }

  & > div:nth-child(4) {
    top: -18px;
    left: 18px;
    -webkit-animation: ball-spin-fade-loader 1s -0.6s infinite linear;
    animation: ball-spin-fade-loader 1s -0.6s infinite linear;

    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      top: -9px;
      left: 9px;
    }
  }

  & > div:nth-child(5) {
    top: -25px;
    left: 0;
    -webkit-animation: ball-spin-fade-loader 1s -0.48s infinite linear;
    animation: ball-spin-fade-loader 1s -0.48s infinite linear;

    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      top: -13px;
    }
  }

  & > div:nth-child(6) {
    top: -18px;
    left: -19px;
    -webkit-animation: ball-spin-fade-loader 1s -0.36s infinite linear;
    animation: ball-spin-fade-loader 1s -0.36s infinite linear;

    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      top: -9px;
      left: -9px;
    }
  }

  & > div:nth-child(7) {
    top: 0;
    left: -25px;
    -webkit-animation: ball-spin-fade-loader 1s -0.24s infinite linear;
    animation: ball-spin-fade-loader 1s -0.24s infinite linear;

    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      left: -13px;
    }
  }

  & > div:nth-child(8) {
    top: 17px;
    left: -17px;
    -webkit-animation: ball-spin-fade-loader 1s -0.12s infinite linear;
    animation: ball-spin-fade-loader 1s -0.12s infinite linear;

    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      top: 9px;
      left: -9px;
    }
  }

  & > div {
    -webkit-animation-fill-mode: both;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background-color: #fff;
    margin: 2px;
    animation-fill-mode: both;

    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      width: 7px;
      height: 7px;
      margin: 1px;
    }
  }
`;
