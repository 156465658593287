/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const oboutInnopolice_1_1_0: Scenario = {
  scenarioId: SceneId.oboutInnopolice_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.PETROV,
    title: 'ИННОПОЛИС',
    text: `Ну наконец-то вы пришли! Я уже всё подготовил для запуска резервного питания. Только деталей Сан Саныча не хватает. Они у вас?`,
    buttons: ['Да, вот они, держите'],
  },
};

export const oboutInnopolice_1_1_1: Scenario = {
  scenarioId: SceneId.oboutInnopolice_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'ИННОПОЛИС',
    text: `Таинственное существо пропало, а вы так и не узнали тайны мистических событий, творящихся в парке Большая Перемена.

    Петрову понадобится какое-то время, чтобы наладить работу анализатора, а вам нужно немного отдохнуть от погони за тайнами парка.
    
    Возвращайся в «Большую Перемену» 30 апреля, чтобы узнать, кто стоит за аномалиями в парке и какие тайны скрывает белое существо с футбольного поля!
    `,
    buttons: [''],
  },
};
