import React from 'react';
import { MapSourceProps } from './MapSourceTypes';
import { StyledMapSource, StyledMapSourceImage, StyledMapSourceWrapper } from './MapSourceStyled';
import { useSelector } from 'react-redux';
import { selectScenarios } from '../../redux/user/userGetters';
import config from './config';
import { Fog } from '../../../common/shared/ui/fog';
import POINTS_CONFIG from '../Map/config';
import { IPoint } from '../../../common/shared/ui/fog';
import { checkPlaceOpened } from '../../resources/scenarios/checkPlaceOpened';

const MapSource = (props: MapSourceProps) => {
  const scenarioState = useSelector(selectScenarios);

  const POINTS = Object.entries(POINTS_CONFIG);
  const current_stage = props.currentStageName?.point;

  const FIRST_STAGE = 'mgu';
  const EXCLUDED_STAGES = [
    'train',
    'train2',
    'lakeBush',
    'innopoliceBush',
    'hersonesSteps',
    'hermitageBush',
  ];

  const fog = (nameOfStage: string, point: IPoint) => {
    return (
      <Fog
        key={nameOfStage}
        point={point}
        src={config.fog[props.stage]['desktop']}
        srcSet={`
        ${config.fog[props.stage]['mobile']} 480w,
        ${config.fog[props.stage]['laptop']} 1024w,
        ${config.fog[props.stage]['desktop']} 1920w
        `}
      />
    );
  };

  return (
    <StyledMapSourceWrapper $stage={props.stage}>
      <StyledMapSource $stage={props.stage} ref={props.innerRef}>
        {current_stage !== undefined &&
          POINTS.map((stage) => {
            const nameOfStage: string = stage[0];

            if (nameOfStage === current_stage) {
              return props.pins;
            }
            if (
              FIRST_STAGE !== nameOfStage &&
              !checkPlaceOpened(nameOfStage, scenarioState) &&
              !EXCLUDED_STAGES.includes(nameOfStage)
            ) {
              return fog(nameOfStage, stage);
            }
          })}
        <StyledMapSourceImage
          draggable={false}
          src={config.map[props.stage]['desktop']}
          srcSet={`
            ${config.map[props.stage]['mobile']} 480w,
            ${config.map[props.stage]['laptop']} 1024w,
            ${config.map[props.stage]['desktop']} 1920w
          `}
        />
      </StyledMapSource>
    </StyledMapSourceWrapper>
  );
};

export default MapSource;

// const [isHiddenFog, setHiddenFogState] = useState(!LocalStorage.get('fog'));

/* {config.fog[props.stage]['desktop'] && (
          <StyledMapSourceImage
            $transparent
            $hidden={isHiddenFog}
            draggable={false}
            src={config.fog[props.stage]['desktop']}
            srcSet={`
            ${config.fog[props.stage]['mobile']} 480w,
            ${config.fog[props.stage]['laptop']} 1024w,
            ${config.fog[props.stage]['desktop']} 1920w
          `}
          />
        )} */

// useMount(() => {
//   const isMustVisibleFog = false; // scenarioState.scenarios[SceneId.scenario_1_23];

//   if (isMustVisibleFog) {
//     LocalStorage.set('fog', 'true');
//     setTimeout(() => {
//       setHiddenFogState(false);
//     });
//   } else {
//     LocalStorage.delete('fog');
//     setHiddenFogState(true);
//   }
// });
