/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../../types/scenario';
import { TEST_TVOI_VIBOR_2 } from '../../tests/test_tvoi_vibor';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const lakeComeback_1_1: Scenario = {
  scenarioId: SceneId.lakeComeback_1_1,
  type: ScenarioType.chat,
  data: {
    title: 'Байкал',
    scene_id: 'lakeComeback_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q1',
        text: `Ой, это снова вы! Получилось у Петрова что-нибудь узнать про воду?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Бабушка, мы ещё не смогли отыскать Петрова',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        person: Interlocutors.CAT,
        id: 'Q2',
        text: `И теперь нам нужна твоя помощь. 

        Подари нам одну рыбешку. Не для меня, как обычно, а для дела`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Выручай, Бабушка!',
            nextStep: 'Q3',
          },
        ],
      },

      Q3: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q3',
        text: `Конечно, выручу вас. 

        Садитесь на берег рядом. Рыбалка — это дело неспешное.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо! А я не [[знал/знала]], что ты умеешь рыбачить',
            nextStep: 'Q4',
          },
        ],
      },

      Q4: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q4',
        text: `Да, мне очень нравится это занятие. А расскажи, чем бы ты [[хотел/хотела]] заниматься.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хмм… Нужно подумать',
            nextStep: 'TEST_TVOI_VIBOR_2',
          },
        ],
      },

      TEST_TVOI_VIBOR_2,

      Q5: {
        person: Interlocutors.GRANDMOTHER,
        id: 'Q5',
        text: `Клюёт! 

        Как быстро за приятной беседой время летит.
        
        Вот и ваша рыбка, берите.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо, ты очень выручила!',
            nextStep: EXIT_ID,
          },
          {
            id: 'b2',
            text: `Бабушка, а дай нам, пожалуйста, две рыбы. Вжух выглядит таким голодным, пусть и ему достанется`,
            nextStep: 'Q6',
          },
        ],
      },

      Q6: {
        person: Interlocutors.CAT,
        id: 'Q6',
        text: `{{name}}, спасибо большое!

        Я и вправду хотел есть, но стеснялся попросить целых две рыбы.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Угощайся, Вжух',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.SYSTEM,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.SYSTEM,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
