import colors from '../constants/colors';
import { BREAKPOINT_PHONE } from '../constants/constants';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html, body {
    height: 100%;
    background: white;
    font-family: 'Inter', sans-serif;

    /* TODO: посмотреть стили и отображение в мобильных браузерах */

    overscroll-behavior-y: contain;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    touch-action: manipulation;
  }
  .network_error {
    overflow: hidden !important;
  }

  .point-anchor {
    cursor:pointer;
    transition: color 250ms;

    text-decoration: underline;
    color: ${colors.blueButton};

    :hover {
      color: ${colors.blueShadow};
    }
  }

  #root {
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
  }

  img {
    -webkit-touch-callout: none;
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    width: 178px;
  }

  a:visited {
    color: rgb(0, 0, 238)
  }

  ::-webkit-input-placeholder {
    color: ${colors.placeholder};
  }

  :-ms-input-placeholder {
    color: ${colors.placeholder};
  }

  ::placeholder {
    color: ${colors.placeholder};
  }

  textarea {
    font-size: 15px;
    line-height: 1.5;
    min-height: 100px;
    resize: vertical;
    font-family: 'Inter', sans-serif;

    @media screen and (max-width: ${BREAKPOINT_PHONE}) {
      font-size: 13px;
    }
  }
`;

export default GlobalStyles;
