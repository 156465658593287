import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { selectAttachmentVideoModalShow, selectMobileMenuShow } from '../redux/app/appGetters';
import { setShowMobileMenuAsync, setShowVideoAttachmentModalAsync } from '../redux/app/appActions';

interface HookValue {
  isOpenMobileMenu: boolean;
  isOpenAttachmentVideoModal: boolean;

  openMobileMenu: () => void;
  closeMobileMenu: () => void;
  toggleMobileMenu: () => void;

  openAttachmentVideoModal: () => void;
  closeAttachmentVideoModal: () => void;
}

const useMobileMenuShow = (): HookValue => {
  const dispatch = useDispatch();
  const isOpenMobileMenu = useSelector(selectMobileMenuShow);
  const isOpenAttachmentVideoModal = useSelector(selectAttachmentVideoModalShow);

  const toggleMobileMenu = React.useCallback(() => {
    dispatch(setShowMobileMenuAsync(!isOpenMobileMenu));
  }, [isOpenMobileMenu, dispatch]);

  const closeMobileMenu = React.useCallback(() => {
    dispatch(setShowMobileMenuAsync(false));
  }, [dispatch]);

  const openMobileMenu = React.useCallback(() => {
    dispatch(setShowMobileMenuAsync(true));
  }, [dispatch]);

  const openAttachmentVideoModal = React.useCallback(() => {
    closeMobileMenu();
    dispatch(setShowVideoAttachmentModalAsync(true));
  }, [dispatch, closeMobileMenu]);

  const closeAttachmentVideoModal = React.useCallback(() => {
    dispatch(setShowVideoAttachmentModalAsync(false));
  }, [dispatch]);

  return {
    isOpenMobileMenu,
    isOpenAttachmentVideoModal,
    toggleMobileMenu,
    closeMobileMenu,
    openMobileMenu,
    openAttachmentVideoModal,
    closeAttachmentVideoModal,
  };
};

export default useMobileMenuShow;
