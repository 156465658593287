import styled, { keyframes } from 'styled-components';
import {
  BREAKPOINT_PHONE,
  BREAKPOINT_SMALL_PHONE,
  BREAKPOINT_TABLET,
} from '../../../common/shared/constants/constants';

import Logo from '../../components/Logo';
import { BaseProps, BaseStyled } from '../../../common/shared/styles/styledBase';

export const ErrorLogoStyled = styled(Logo)`
  width: 100%;
  height: auto;

  margin: 0 auto;
  max-width: 255px;

  @media screen and (max-width: ${BREAKPOINT_SMALL_PHONE}) {
    max-width: 180px;
  }
`;

export const ErrorTextStyled = styled(BaseStyled)<BaseProps>`
  margin-top: 40px;

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    margin-top: 20px;
  }
`;

export const LoginDeniedErrorTextStyled = styled(ErrorTextStyled)<BaseProps>`
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  white-space: pre-line;
`;

export const ErrorBlockStyled = styled.div`
  height: 60vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ErrorTowerStyled = styled.img`
  display: block;
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
`;

export const ErrorBottomStyled = styled.div`
  position: absolute;
  height: 40vh;
  bottom: 0;

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    height: 35vh;
  }
`;

export const ErrorBottomRelativeStyled = styled.div`
  position: relative;
  height: 40vh;

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    height: 35vh;
  }
`;

const cloudAnimation = keyframes`
  0% {
    transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
  }
  50% {
    transform: translateX(0px)translateY(-10px)rotate(0deg)scale(1,1);
  }
  100% {
    transform: translateX(0px)translateY(0px)rotate(0deg)scale(1,1);
  }
`;

const ErrorCloud = styled.img`
  display: block;
  position: absolute;
  max-width: 90px;
  animation: ${cloudAnimation} 3.2s infinite linear;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    max-width: 70px;
  }
`;
export const ErrorCloud1 = styled(ErrorCloud)`
  top: 10%;
  left: -70px;

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    max-width: 79px;
    top: 20%;
  }
  @media screen and (max-width: ${BREAKPOINT_SMALL_PHONE}) {
    max-width: 50px;
  }
`;
export const ErrorCloud2 = styled(ErrorCloud)`
  top: 55%;
  left: -70px;
  max-width: 70px;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    max-width: 58px;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    max-width: 33px;
  }
`;
export const ErrorCloud3 = styled(ErrorCloud)`
  top: 40%;
  right: -50px;
  max-width: 60px;

  @media screen and (max-width: ${BREAKPOINT_TABLET}) {
    max-width: 73px;
    right: 0;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    top: 60%;
    right: -70px;
    max-width: 47px;
  }
`;
