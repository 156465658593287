/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';
import interlocutors from '../../../interlocutors';

export const scenario_egg2_0: Scenario = {
  scenarioId: SceneId.scenario_egg2_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `И кто это додумался выкинуть в кусты такой ценный предмет?! Это же та самая недостающая деталь для тепловой пушки! 😻

Скорее неси ее Петрову и передай ему мой мурчащий привет!`,
    buttons: ['Закрыть'],
  },
};
