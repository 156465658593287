/* eslint-disable max-len */
import {
  Step,
  SceneId,
  Scenario,
  StepType,
  ScenarioType,
  Interlocutors,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';
import { images } from '../../tests/images';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

const person = Interlocutors.SYSTEM;

const CREATIVE_TEST: { [id: string]: Step } = {
  ...splitStep({
    person: Interlocutors.SYSTEM,
    id: 'CREATIVE_TEST',
    callToAction: 'Напиши и отправь',
    type: StepType.question,
    maxAnswers: 1,
    text: `Перед тобой несколько картин известных художников абстракционистов. Придумай им названия.

На обдумывание каждой картины у тебя будет 1 минута, поэтому не затягивай.

Напоминаю, названия не могут нарушать Положение о конкурсе, особенно пункт 1.6. Если хочешь изучить Положение, оно есть по <a href="https://bolshayaperemena.online/api/link/short/4beffe5b9d" target="_blank">ссылке</a>

В общем, твои ответы должны быть приличными и цензурными 😊

Придумай название для каждой картины, впиши в поле ниже и отправь. 

Обращаю внимание, что нужно обязательно нажать кнопку «Отправить ответ», до окончания времени на секундомере, иначе ответ не будет засчитан.`,
    buttons: [
      {
        id: 'b1',
        text: 'Начать',
        nextStep: 'CREATIVE_TEST_1',
      },
    ],
  }),
  CREATIVE_TEST_1: {
    person: Interlocutors.SYSTEM,
    id: 'CREATIVE_TEST_1',
    variable: 'CREATIVE_TEST_1',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_1, title: 'Казимир Северинович Малевич' },
    nextStep: 'CREATIVE_TEST_2',
    timeout: 60,
  },
  CREATIVE_TEST_2: {
    person: Interlocutors.SYSTEM,
    id: 'CREATIVE_TEST_2',
    variable: 'CREATIVE_TEST_2',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_2, title: 'Казимир Северинович Малевич' },
    nextStep: 'CREATIVE_TEST_3',
    timeout: 60,
  },
  CREATIVE_TEST_3: {
    person: Interlocutors.SYSTEM,
    id: 'CREATIVE_TEST_3',
    variable: 'CREATIVE_TEST_3',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_3, title: 'Л.С. Попова' },
    nextStep: 'CREATIVE_TEST_4',
    timeout: 60,
  },
  CREATIVE_TEST_4: {
    person: Interlocutors.SYSTEM,
    id: 'CREATIVE_TEST_4',
    variable: 'CREATIVE_TEST_4',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_4, title: 'создана по эскизам И.Г. Чашника.' },
    nextStep: 'CREATIVE_TEST_5',
    timeout: 60,
  },
  CREATIVE_TEST_5: {
    person: Interlocutors.SYSTEM,
    id: 'CREATIVE_TEST_5',
    variable: 'CREATIVE_TEST_5',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_5, title: 'И.Г. Чашник' },
    nextStep: 'CREATIVE_TEST_6',
    timeout: 60,
  },
  CREATIVE_TEST_6: {
    person: Interlocutors.SYSTEM,
    id: 'CREATIVE_TEST_6',
    variable: 'CREATIVE_TEST_6',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_6, title: 'В.В. Кандинский' },
    nextStep: 'CREATIVE_TEST_7',
    timeout: 60,
  },
  CREATIVE_TEST_7: {
    person: Interlocutors.SYSTEM,
    id: 'CREATIVE_TEST_7',
    variable: 'CREATIVE_TEST_7',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_7, title: 'В.В. Кандинский' },
    nextStep: 'CREATIVE_TEST_8',
    timeout: 60,
  },
  CREATIVE_TEST_8: {
    person: Interlocutors.SYSTEM,
    id: 'CREATIVE_TEST_8',
    variable: 'CREATIVE_TEST_8',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_8, title: 'В.В. Кандинский' },
    nextStep: 'CREATIVE_TEST_9',
    timeout: 60,
  },
  CREATIVE_TEST_9: {
    person: Interlocutors.SYSTEM,
    id: 'CREATIVE_TEST_9',
    variable: 'CREATIVE_TEST_9',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_9, title: 'Казимир Северинович Малевич' },
    nextStep: 'CREATIVE_TEST_10',
    timeout: 60,
  },
  CREATIVE_TEST_10: {
    person: Interlocutors.SYSTEM,
    id: 'CREATIVE_TEST_10',
    variable: 'CREATIVE_TEST_10',
    type: StepType.text_question,
    text: ``,
    image: { uri: images.creative_10, title: 'Казимир Северинович Малевич' },
    nextStep: 'Q7',
    timeout: 60,
  },
};

export const scenario_2_3_i: Scenario = {
  scenarioId: SceneId.scenario_2_3_i,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.CAT,
    title: 'Родина-мать',
    text: `«Вот и она — Родина-мать. Один из самых высоких монументов в мире. Отсюда мы точно сможем поймать сигнал, нужно только понять откуда конкретно».`,
    buttons: ['Подойти к монументу'],
  },
};

export const scenario_2_3: Scenario = {
  scenarioId: SceneId.scenario_2_3,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Родина-мать',
    scene_id: 'scenario_2_3',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Дверь в монумент закрыта. На ней электронный код. 
          Вы нажимаете зелёную кнопку вызова. 
          
          «Пожалуйста, выберите комбинацию для входа!» 
          `,
        callToAction: 'Подойти к монументу',
        id: 'Q1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Мухина, 1937, 24,5',
            nextStep: 'Q1_1',
          },
          {
            id: 'b2',
            text: 'Вучетич, 1967, 85',
            nextStep: 'Q1_2',
          },
          {
            id: 'b3',
            text: 'Бондаренко, 1980, 42,5',
            nextStep: 'Q1_3',
          },
        ],
      },
      Q1_1: {
        text: `«Комбинация неверна, доступ заблокирован!»`,
        callToAction: 'Мухина, 1937, 24,5',
        id: 'Q1_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Попробовать ещё раз',
            nextStep: 'Q1',
          },
        ],
      },
      Q1_3: {
        text: `«Комбинация неверна, доступ заблокирован!»`,
        callToAction: 'Бондаренко, 1980, 42,5',
        id: 'Q1_3',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Попробовать ещё раз',
            nextStep: 'Q1',
          },
        ],
      },
      Q1_2: {
        text: `Вы заходите внутрь статуи. Справа от входа видите кабинет, на котором написано «Лаборатория».`,
        callToAction: 'Вучетич, 1967, 85',
        id: 'Q1_2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Зайти в лабораторию',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        text: `Вы попадаете в лабораторию. На столе стоит компьютер с чертежами, на стене висят каски.`,
        callToAction: 'Зайти в лабораторию',
        id: 'Q2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Посмотреть чертежи',
            nextStep: 'Q2_1',
          },
        ],
      },
      Q2_1: {
        text: `На чертежах изображены все проходы внутри монумента, а также люки и лестницы. Вы видите, что в голове статуи есть люк, но чтобы туда попасть, нужно подняться по лестнице на шее.`,
        callToAction: 'Кажется, именно туда вам и нужно.',
        id: 'Q2_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Надеть каски',
            nextStep: 'Q2_2',
          },
        ],
      },
      Q2_2: {
        text: `Вы одеваете каску на себя и на Вжуха — внутри монумента много балок, нужно передвигаться в каске. И отправляетесь…`,
        callToAction: 'Надеть каски',
        id: 'Q2_2',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться в шею',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        text: `Вы поднимаетесь по узкой лестнице в шее и попадаете на макушку статуи — люк открыт. Сверху перед вами простирается весь парк и открывается вид на Волгу. 

          Вы включаете маршрутизатор и… сигнала нет, слышны только помехи. 
          
          «Вжух, сигнала нет, что же делать?» — спрашиваешь ты. 
          «Хм, кажется высота всё-таки недостаточна», — отвечает кот. 
          «Но ведь высота статуи 85 метров, как этого может быть недостаточно?»
          
          «Вжух, Высота статуи 85 метров вместе с мечом! Значит… нам надо попасть в меч», — понимаешь ты.
          `,
        callToAction: 'Отправиться в шею',
        id: 'Q3',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправиться в правую руку',
            nextStep: 'Q4',
          },
        ],
      },
      Q4: {
        text: `Правая рука представляет собой длинный широкий коридор. Тут нет выходов и нет люка, как в шее. Надо тут осмотреться.`,
        id: 'Q4',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Дойти до конца руки',
            nextStep: 'Q5',
          },
        ],
      },
      Q5: {
        text: `В самом дальнем уголке правой руки статуи вы видите небольшой люк. Но он такой маленький, что туда сможет пролезть только…`,
        id: 'Q5',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Отправить Вжуха в люк',
            nextStep: 'Q6',
          },
        ],
      },
      Q6: {
        text: `Вжух недовольно бормоча, крадётся по мечу. «{{NAME}} я на месте! Помоги настроить сигнал, а то у меня лапки».`,
        callToAction: 'Отправить Вжуха в люк',
        id: 'Q6',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Настроить сигнал',
            nextStep: 'CREATIVE_TEST/0',
          },
        ],
      },
      ...CREATIVE_TEST, // ТЕСТ: КРЕАТИВНОСТЬ 1
      Q7: {
        text: `«Сработало!» — акустика доносит до тебя голос кота. 

          «Приём, связь установлена», — ты слышишь Петрова.
          «Сигнал принял. Запускаю резервную копию», — отвечает Гарин.
          `,
        callToAction: 'Настроить сигнал',
        id: 'Q7',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Завершить миссию',
            nextStep: EXIT_ID,
          },
        ],
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
