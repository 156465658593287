import styled from 'styled-components';
import { ScenarioNameProps } from './ScenarioNameTypes';

export const StyledScenarioName = styled.div<ScenarioNameProps>`
  width: 100%;
  padding: 16px;
  border-radius: 16px;

  background: #ffffff;

  & > * + * {
    margin-top: 8px;
    line-height: 1;
  }
`;
