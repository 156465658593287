import { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { selectScenarios } from '../../redux/user/userGetters';
import { notSecondStageCompleted } from '../scenarioHelpers/stageChecks';
import { useIsSecondStageFirstActCompleted } from './useIsSecondStageFirstActCompleted';
import { useIsSecondStageSecondActCompleted } from './useIsSecondStageSecondActCompleted';
import { s2a3RequiredScenarios } from './requiredScenarios';

export function useIsSecondStageCompleted() {
  const scenarioState = useSelector(selectScenarios);
  const s2a1 = useIsSecondStageFirstActCompleted();
  const s2a2 = useIsSecondStageSecondActCompleted();

  return useMemo(() => {
    if (!scenarioState || !scenarioState.scenarios) {
      return false;
    }
    if (!s2a1) {
      return false;
    }
    if (!s2a2) {
      return false;
    }
    for (let i = 0; i < s2a3RequiredScenarios.length; i++) {
      for (let j = 0; j < s2a3RequiredScenarios[i].length; j++) {
        if (!scenarioState.scenarios[s2a3RequiredScenarios[i][j]]) {
          return false;
        }
      }
    }
    // return !notSecondStageCompleted(scenarioState);
    return true;
  }, [s2a1, s2a2, scenarioState]);
}
