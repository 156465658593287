import * as checks from './stageChecks';
import { ID, to } from './ID';
import { PointsOfInterest } from '../../types';
import { SceneId } from '../../resources/scenario';
import { createNavigateToPointLink, toArray } from './utils';
import { RunModalScenario, ScenarioHelperResponse } from './types';
import { ScenarioState } from '../../redux/scenario';

export function stage1(
  scenarioState: ScenarioState,
  runScenario: (id: SceneId) => void,
  runModalScenario: RunModalScenario,
): ScenarioHelperResponse {
  if (checks.notCatWelcome(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.catWelcome] },
    };
  }

  if (checks.notCityPresented(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.cityPresentation] },
    };
  }

  if (checks.notWelcomeTest(scenarioState)) {
    const mguNavigatePoint = createNavigateToPointLink('МГУ', PointsOfInterest.mgu);

    return {
      helpMessage: `Иди в ${mguNavigatePoint} на карте парка «Большая перемена». Там тебя ждет кое-что интересное.`,
      pointsHandlers: [
        {
          point: PointsOfInterest.mgu,
          handler: () =>
            runModalScenario(
              to[ID.welcomeTestIntro],
              to[ID.welcomeTest],
              to[ID.welcomeTest],
              scenarioState.scenarios,
            ),
        },
      ],
    };
  }

  const firstActHandlers: Partial<Record<PointsOfInterest, (() => void) | undefined>> = {};
  let universalHelpMessage;

  if (!scenarioState.scenarios?.[to[ID.welcomeTeacher]] && !checks.notWelcomeTest(scenarioState)) {
    universalHelpMessage = createNavigateToPointLink('МГУ', PointsOfInterest.mgu);

    firstActHandlers[PointsOfInterest.mgu] = () =>
      runModalScenario(
        to[ID.welcomeTeacherIntro],
        to[ID.welcomeTeacher],
        to[ID.welcomeTeacherComplete],
        scenarioState.scenarios,
      );
  }

  if (
    !scenarioState.scenarios?.[to[ID.welcomePetrov]] &&
    scenarioState.scenarios?.[to[ID.welcomeTeacher]]
  ) {
    universalHelpMessage = createNavigateToPointLink('Космодром', PointsOfInterest.space);

    firstActHandlers[PointsOfInterest.space] = () =>
      runModalScenario(
        to[ID.welcomePetrovIntro],
        to[ID.welcomePetrov],
        to[ID.welcomePetrovComplete],
        scenarioState.scenarios,
      );
  }

  if (
    !scenarioState.scenarios?.[to[ID.welcomeEntrepreneur]] &&
    scenarioState.scenarios?.[to[ID.welcomePetrov]]
  ) {
    universalHelpMessage = createNavigateToPointLink('Башня Останкино', PointsOfInterest.ostankino);

    firstActHandlers[PointsOfInterest.ostankino] = () =>
      runModalScenario(
        to[ID.welcomeEntrepreneurIntro],
        to[ID.welcomeEntrepreneur],
        to[ID.welcomeEntrepreneurComplete],
        scenarioState.scenarios,
      );
  }

  if (checks.notFirstActCompleted(scenarioState)) {
    return {
      helpMessage: `${universalHelpMessage}. Нажми на ссылку, чтобы перейти к новым заданиям и приготовься к тому, что тебе придётся разгадывать тайны и решать головоломки. 
        Удачи, отважный герой!`,
      pointsHandlers: toArray(firstActHandlers),
    };
  }

  if (checks.notCatFlaskHelpIntro(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.catFlaskHelpIntro], runOnSubmit: to[ID.catFlaskHelp] },
    };
  }

  if (checks.notCatFlaskHelp(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.catFlaskHelp], unsaved: true },
    };
  }

  const grandMomWelcomeHandler = {
    [PointsOfInterest.lake]: () =>
      runModalScenario(
        to[ID.welcomeGrandMomIntro],
        to[ID.welcomeGrandMom],
        to[ID.welcomeGrandMomComplete],
        scenarioState.scenarios,
      ),
  };

  if (
    checks.notGrandMomWelcome(scenarioState) &&
    scenarioState.scenarios?.[to[ID.welcomeEntrepreneur]]
  ) {
    const lakeNavigatePoint = createNavigateToPointLink(
      '«Природные достояния России»',
      PointsOfInterest.lake,
    );

    return {
      // eslint-disable-next-line max-len
      helpMessage: `Найди на карте парка «Большая перемена» горы и озеро с парусником. Это достопримечательность ${lakeNavigatePoint}. Тебе нужно идти туда.`,
      pointsHandlers: toArray({ ...grandMomWelcomeHandler }),
    };
  }

  // /**
  //  * заглушка
  //  */

  // return {
  //   pointsHandlers: [],
  //   autorunScenario: {
  //     id: to[ID.stop_1],
  //     unsaved: false,
  //   },
  // };

  const secondActHandlers: Partial<Record<PointsOfInterest, (() => void) | undefined>> = {};

  if (!scenarioState.scenarios?.[to[ID.sochiInfo]]) {
    secondActHandlers[PointsOfInterest.sochi] = () =>
      runModalScenario(
        to[ID.sochiIntro],
        to[ID.sochiInfo],
        to[ID.sochiInfoComplete],
        scenarioState.scenarios,
      );
  }

  if (
    !scenarioState.scenarios?.[to[ID.mguSecondAct]] &&
    scenarioState.scenarios?.[to[ID.sochiInfo]]
  ) {
    secondActHandlers[PointsOfInterest.mgu] = () =>
      runModalScenario(
        to[ID.mguSecondAct],
        to[ID.mguSecondAct],
        to[ID.mguSecondActComplete],
        scenarioState.scenarios,
      );
  }

  if (checks.notSecondActSochiCompleted(scenarioState)) {
    // Для линейности и правильного отображения подсказок Вжуха
    const customSecondActHandlers = {
      ...secondActHandlers,
    };

    const sochiNavigatePoint = createNavigateToPointLink(
      'Олимпийский парк',
      PointsOfInterest.sochi,
    );

    return {
      // eslint-disable-next-line max-len
      helpMessage: `Иди в ${sochiNavigatePoint} на карте парка «Большая перемена». Он находится справа от Космодрома на берегу реки.`,
      pointsHandlers: toArray({
        ...customSecondActHandlers,
      }),
    };
  }

  if (
    checks.notSecondActMguCompleted(scenarioState) &&
    scenarioState.scenarios?.[to[ID.sochiInfo]]
  ) {
    const mguNavigatePoint = createNavigateToPointLink('МГУ', PointsOfInterest.mgu);

    return {
      // eslint-disable-next-line max-len
      helpMessage: `Возвращайся в ${mguNavigatePoint} на карте парка «Большая перемена». Светлана Васильевна хочет тебе что-то сказать.`,
      pointsHandlers: toArray({
        ...secondActHandlers,
      }),
    };
  }

  if (checks.notmguAlonePopupIntro(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: {
        id: to[ID.mguAlonePopupIntro],
        runOnSubmit: to[ID.mguAlonePopupIntro],
      },
    };
  }

  // const test = true
  // !checks.notmguAlonePopupIntro(scenarioState) && checks.notFogStarted(scenarioState)
  if (!checks.notmguAlonePopupIntro(scenarioState) && checks.notFogStarted(scenarioState)) {
    const lakeNavigatePoint = createNavigateToPointLink(
      'Природные достояния России',
      PointsOfInterest.lake,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.lake]: () => runScenario(to[ID.grandMomThirdAct]),
      }),
      helpMessage: `{{NAME}}, как ты помнишь, я больше всех брожу по парку и знаю почти обо всем, что происходит.

Если понадобится помощь, кликни на меня и я расскажу, что знаю.

Например, сейчас тебе стоит сходить в ${lakeNavigatePoint}.`,
    };
  }

  if (checks.notFogStarted(scenarioState)) {
    return {
      pointsHandlers: toArray({
        [PointsOfInterest.lake]: () => runScenario(to[ID.grandMomThirdAct]),
      }),
    };
  }

  if (checks.notFogIntro(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.fogIntro] },
      delay: 5000,
    };
  }

  if (checks.fogIntroPopup(scenarioState) && !checks.notFogIntro(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.fogIntroPopup] },
    };
  }

  if (checks.notStartTeacherHelp(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.teacherLost] },
    };
  }

  // if (checks.notHelpTeacherPopup(scenarioState) && !checks.notStartTeacherHelp(scenarioState)) {
  //   return {
  //     pointsHandlers: [],
  //     autorunScenario: { id: to[ID.helpTeacherPopup] },
  //   };
  // }

  const helpPoint = {
    [PointsOfInterest.mgu]: () =>
      runModalScenario(
        to[ID.helpTeacherPopup],
        to[ID.helpTeacher],
        to[ID.helpTeacher],
        scenarioState.scenarios,
      ),
  };

  if (checks.notChosenHelpVariant(scenarioState)) {
    const mguNavigatePoint = createNavigateToPointLink('МГУ', PointsOfInterest.mgu);

    return {
      pointsHandlers: toArray(helpPoint),
      helpMessage: `{{NAME}}, скорее беги в ${mguNavigatePoint}!`,
    };
  }

  /**
   * заглушка
   */

  // return {
  //   pointsHandlers: [],
  //   autorunScenario: {
  //     id: to[ID.stop_1],
  //     unsaved: false,
  //   },
  // };

  if (checks.notGotRequiredRopeStair(scenarioState)) {
    const sochiNavigatePoint = createNavigateToPointLink(
      'Олимпийском парке',
      PointsOfInterest.sochi,
    );

    return {
      helpMessage: `Светлана Васильевна в беде! {{NAME}}, нужно найти Сан Саныча в ${sochiNavigatePoint}.`,
      pointsHandlers: toArray({
        ...helpPoint,
        [PointsOfInterest.sochi]: () => runScenario(to[ID.getRopeStair]),
      }),
    };
  }

  if (checks.notTeacherSaved(scenarioState)) {
    const mguNavigatePoint = createNavigateToPointLink('МГУ', PointsOfInterest.mgu);

    return {
      pointsHandlers: toArray(helpPoint),
      helpMessage: `{{NAME}}, вам с Сан Санычем лучше поторопиться обратно в ${mguNavigatePoint}.`,
    };
  }

  if (checks.notFirstStageCompleted(scenarioState)) {
    const mguNavigatePoint = createNavigateToPointLink('МГУ', PointsOfInterest.mgu);

    const pointsHandlers = {
      [PointsOfInterest.mgu]: () => runScenario(to[ID.teacherLastTest]),
    };
    return {
      pointsHandlers: toArray(pointsHandlers),
      helpMessage: `{{NAME}}, кажется, тебе надо сходить в ${mguNavigatePoint}.`,
    };
  }
  return {
    pointsHandlers: [],
  };
}
