/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import { CREATIVE_TEST_2 } from '../CREATIVE_TEST_2';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg3_hersones_1_1: Scenario = {
  scenarioId: SceneId.stg3_hersones_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Херсонес',
    scene_id: 'stg3_hersones_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Здесь мы точно сможем найти что-то удивительное даже для пришельцев.`,
        id: 'Q1',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А где это мы? Кажется, я здесь еще не [[бывал/бывала]] ',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        text: `Перед нами точная копия Херсонеса — города, основанного древними греками на юго-западном побережье Крыма в V веке до н. э. Это почти 2500 лет назад! Херсонес — единственный античный полис Северного Причерноморья, городская жизнь в котором непрерывно поддерживалась вплоть до конца XIV века. 

        Полисами в Древней Греции называли города с особой формой общественного устройства. В них все граждане имели определенные права и обязанности. А еще в полисах впервые появилась концепция равенства граждан перед законом, а важнейшие решения принимались с участием всех граждан мужского пола.`,
        id: 'Q2',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Древние греки были продвинутыми!?',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        text: `Не просто продвинутыми. Многие полисы имели демократический уклад, в том числе и Херсонес. Он принимал активное участие в общегреческих праздниках, спортивных состязаниях и вел активную внешнюю политику. `,
        id: 'Q3',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А что это за странные квадратные развалины?',
            nextStep: 'Q4',
          },
        ],
      },

      Q4: {
        text: `Это частично сохранившиеся руины жилых усадеб. В усадьбах велось земледелие, выращивались виноград и злаковые. `,
        id: 'Q4',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: `Если 2500 лет назад здесь жили древние греки, как же Херсонес стал частью нашей страны?`,
            nextStep: 'Q5',
          },
        ],
      },

      Q5: {
        text: `Дело в том, что в конце X века киевский князь Владимир после нескольких месяцев осады захватил город, и Херсонес стал частью Киевской Руси. Здесь же Владимир принял православие. 

        Видишь этот прекрасный собор? Он называется Владимирским и построен в XIX веке в память о крещении киевского князя Владимира. Вон там рядом с храмом стоит купель. По одной из версий именно в ней в 988 году крестили Владимира. Можно сказать, что с этого места началась православная история Руси.`,
        id: 'Q5',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Вот это да! Поистине историческое место!',
            nextStep: 'Q6',
          },
        ],
      },

      Q6: {
        text: `Пусть у нас в «Большой перемене» лишь копия Херсонеса, но здесь все равно ощущается особая атмосфера 😊`,
        id: 'Q6',
        person: Interlocutors.GUIDE,
        type: StepType.message,
        nextStep: 'Q7',
      },

      Q7: {
        text: `Женя, спасибо за такой увлекательный рассказ. Но давайте вернемся к поискам.`,
        id: 'Q7',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Смотрите, что я [[нашёл/нашла]] на полу. Женя, это же игрушечный слон? [[Не знал/Не знала]], что они были у древних греков.',
            nextStep: 'Q8',
          },
        ],
      },

      Q8: {
        text: `Да, это знакомые нам детские игрушки. Ну а в Древней Греции слонов очень почитали, ведь они помогали на поле боя!`,
        id: 'Q8',
        person: Interlocutors.GUIDE,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ух ты! Тогда я, кажется, знаю, чем удивить пришельцев. Расскажу им, как земляне любят играть!',
            nextStep: 'Q9',
          },
        ],
      },

      Q9: {
        text: `И не только люди. Я тоже люблю!`,
        id: 'Q9',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Квилл, что думаешь?',
            nextStep: 'Q10',
          },
        ],
      },

      Q10: {
        text: `Это интересно, ведь мой вид играть не умеет.
        
        Но только слоник какой-то слишком простой. Нам не удивить моих сородичей такой игрушкой. Но что если ты придумаешь, как изменить этого игрушечного слона для того, чтобы детям стало интереснее играть с ним?
        
        Только учти, что данный тест не приносит баллов в конкурсе!`,
        id: 'Q10',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо, давай попробуем так.',
            nextStep: 'CREATIVE_ELEPHANT/0',
          },
        ],
      },

      /// Здесь должен быть тест
      ...CREATIVE_TEST_2,

      Q11: {
        text: `Идеи игр и ваших игрушек, безусловно, очень интересны. Однако ничего полезного вашему виду они не приносят. А мы убеждены, что любая деятельность вида должна приносить ему пользу.

        Поэтому снова решение о трансформации вашего парка не изменяем — отклонить!
        `,
        id: 'Q11',
        person: Interlocutors.ALIENSHIP,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Но игрушки же тоже могут быть полезными! ',
            nextStep: 'Q12',
          },
        ],
      },

      Q12: {
        text: `Кажется, их в этом не убедить 😿

        Может, нам рассказать о чём-то более прикладном? Поищем еще!`,
        id: 'Q12',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо, давайте попробуем!',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
