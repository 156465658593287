import React from 'react';

import { Loader } from '../../../common/shared/ui/Loader';
import { PageLoaderStyled, LoaderInner, LoaderLogo } from './PageLoaderStyled';

import logo from '../../../assets/images/logoShort.svg';

const PageLoader = () => {
  return (
    <PageLoaderStyled>
      <LoaderInner>
        <LoaderLogo src={logo} alt='' />
        <Loader />
      </LoaderInner>
    </PageLoaderStyled>
  );
};

export default PageLoader;
