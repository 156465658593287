/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId, StepType, TestStep } from '../../scenario';
import interlocutors from '../../interlocutors';

const EXIT_ID = 'EXIT';
const TO_MAP_STEP = 'TO_MAP_STEP';

const person = Interlocutors.PETROV;

export const scenario_1_8_0: Scenario = {
  scenarioId: SceneId.scenario_1_8_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Космодром',
    person: Interlocutors.SYSTEM,
    text: `Вы подходите к макету космодрома «Восточный» в натуральную величину. Даже удивительно, как он поместился в этом парке.

    На платформе стоит величественная ракета, а у входа в здание сидит чем-то увлеченный парень. Вы начинаете с ним разговор...`,
    buttons: ['Начать'],
  },
};

const TEST_TVOI_VIBOR: TestStep = {
  external_id: 'test-tvoi-vybor-junior-210401',
  id: 'Q6',
  name: 'Твой выбор 1/5',
  nextStep: 'Q7',
  order: [
    {
      description: 'Ты можешь выбрать несколько вариантов из предложенных.',
      subtest: 'T2',
      question: 'Q1',
    },
  ],
  path: 'test-tvoi-vybor-junior-210401.json',
  person,
  type: StepType.test,
};

export const scenario_1_8: Scenario = {
  scenarioId: SceneId.scenario_1_8,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Космодром',
    scene_id: 'scenario_1_8',
    startId: 'Q1',
    steps: {
      Q1: {
        type: StepType.message,
        id: 'Q1',
        person,
        text: 'Привет! Ты же {{NAME}}, верно? А меня Петров зовут. Я местный изобретатель и инженер 👨‍🔬',
        nextStep: 'Q1_2',
      },
      Q1_2: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q1_2',
        person,
        text: 'Вжух мне про тебя рассказывал. Рад наконец-то познакомиться лично.',
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо, и мне приятно познакомиться',
            nextStep: 'Q2_1',
          },
          {
            id: 'b2',
            text: 'Да-да, а что тут происходит?',
            nextStep: 'Q2_1',
          },
        ],
      },
      Q2_1: {
        id: 'Q2_1',
        person,
        type: StepType.question,
        maxAnswers: 1,
        text: 'Ты же уже знаешь о том, что произошло в парке, верно? Из-за этого тумана все электронные замки на космодроме начали барахлить, и сотрудникам ничего не оставалось делать, как разойтись по домам. Вот пытаюсь починить замок и попасть внутрь 🙁',
        callToAction: 'Поможешь?',
        buttons: [
          {
            id: 'b1',
            text: 'Да, поехали!',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        person,
        type: StepType.message,
        id: 'Q3',
        nextStep: 'Q3_2',
        text: 'А тебе известно, что «Поехали!» — слово, которое произнес первый космонавт во время старта первого пилотируемого космического корабля? 👨‍🚀 ',
      },
      Q3_2: {
        person,
        type: StepType.message,
        id: 'Q3_2',
        nextStep: 'Q3_3',
        text: 'Этим космонавтом был наш соотечественник Юрий Гагарин, а произошло это 12 апреля 1961 года. Уже более 60 лет назад, только подумать!',
      },
      Q3_3: {
        person,
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q3_3',
        nextStep: 'Q3_2',
        text: 'Юрий Гагарин на космическом корабле «Восток-1» стартовал с космодрома Байконур. Кстати, мы сейчас как раз находимся в похожем космодроме. Гагарин впервые в мире совершил орбитальный полёт вокруг планеты Земля. Время его нахождения в космосе составило почти 2 часа 🚀',
        buttons: [
          {
            id: 'b1',
            text: 'Вот это да! Спасибо за новую информацию.',
            nextStep: 'Q4',
          },
          {
            id: 'b2',
            text: 'А я об этом знаю. Наверняка было очень круто!',
            nextStep: 'Q4',
          },
        ],
      },
      Q4: {
        type: StepType.message,
        id: 'Q4',
        person,
        text: 'Класс! Тогда давай к делу.',
        nextStep: 'Q5',
      },
      Q5: {
        type: StepType.message,
        id: 'Q5',
        person,
        text: 'Знаешь, здесь я видел людей разного толка. Кто-то изучает технологии, а кто-то жизнь. Так много специалистов работают совместно над большой задачей.',
        nextStep: 'Q5_1',
      },
      Q5_1: {
        type: StepType.question,
        maxAnswers: 1,
        id: 'Q5_1',
        person,
        text: 'Поделись, а с чем бы тебе было интересно работать?',
        buttons: [
          {
            id: 'b1',
            text: 'Ок',
            nextStep: 'Q6',
          },
        ],
      },
      Q6: TEST_TVOI_VIBOR,
      Q7: {
        type: StepType.message,
        id: 'Q7',
        person,
        text: '{{NAME}}, ты очень интересный человек и я верю, тебя ждет большое будущее 😉',
        nextStep: 'Q7_1',
      },
      Q7_1: {
        type: StepType.message,
        id: 'Q7_1',
        person,
        text: 'Так, электронный замок перезагружается, это займет какое-то время. Приходи позже. Увидимся!',
        nextStep: EXIT_ID,
      },
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_STEP]: {
        person,
        id: TO_MAP_STEP,
        type: StepType.to_map_step,
      },
    },
  },
};

export const scenario_1_8_1: Scenario = {
  scenarioId: SceneId.scenario_1_8_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.PETROV].name,
    person: Interlocutors.PETROV,
    text: 'А, это снова ты. Все еще не готово. Приходи позже.',
    buttons: ['Закрыть'],
  },
};
