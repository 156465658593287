import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FreezeState } from './../../hooks/useFrozenState';

export const initialState: FreezeState = {
  text: '',
  start: '',
  title: '',
  duration: 0,
  isOpened: false,
};

const appSlice = createSlice({
  name: 'freeze',
  initialState,
  reducers: {
    setFreezeState: (state, action: PayloadAction<FreezeState>) => {
      state.text = action.payload.text;
      state.start = action.payload.start;
      state.title = action.payload.title;
      state.duration = action.payload.duration;
      state.isOpened = action.payload.isOpened;
    },
  },
});

export default appSlice;
