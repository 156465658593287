/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../types/scenario';

export const scenario_0: Scenario = {
  scenarioId: SceneId.scenario_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Начало игры',
    text: `Ты просыпаешься в домике бабули в Херсонесе. Скрипят половицы, веточка яблони стучит в окно, пахнет морем и деревом. Светит майское солнце.
    
    «Доброе утро!» — слышишь ты и узнаешь голос, который только что разговаривал с тобой во сне. На одеяле сидит пушистый упитанный кот и смотрит прямо на тебя. Ты оглядываешься по сторонам, но больше в комнате никого нет.

    «Доброе утро!» — ухмыляясь, повторяет кот.`,
    buttons: ['Поговорить'],
  },
};
