/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';
import { CREATIVE_TEST_1 } from '../CREATIVE_TEST_1';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const stg3_hermitage_1_1: Scenario = {
  scenarioId: SceneId.stg3_hermitage_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Эрмитаж',
    scene_id: 'stg3_hermitage_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Я думаю, что нам стоит показать им искусство землян! Оно очень заинтересовало меня в вашем музее, ведь мой вид ничего подобного не создаёт.`,
        id: 'Q1',
        person: Interlocutors.ALIEN,
        type: StepType.message,
        nextStep: 'Q2',
      },

      Q2: {
        text: `Вот оно! Это именно то, что нужно. Посмотрите, какие чистые формы и линии.

        Показав моим сородичам эти картины, мы убедим их в уникальности землян, и они исправят достопримечательности парка.`,
        id: 'Q2',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хороший план, Квилл! ',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        text: `План, конечно, хороший. Но как же мы через радиоприёмник покажем пришельцам картины?`,
        id: 'Q3',
        person: Interlocutors.CAT,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Точно… 😰',
            nextStep: 'Q4',
          },
        ],
      },

      Q4: {
        text: `Эх, я совсем забыл, что у нас примитивная модель передатчика.

        Но всё элементарно, Вжух. Как я уже упоминал, мой вид не знаком с методикой живописи. Более того, мы предельно рациональны, поэтому любая выдумка для нас будет новой и удивительной.
        
        И вместо того, чтобы показать картины, мы просто прочитаем вслух их названия. Только здесь смущает одно…`,
        id: 'Q4',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Что, Квилл?',
            nextStep: 'Q5',
          },
        ],
      },

      Q5: {
        text: `Названия этих картин ни о чём не говорят и совсем не удивляют. Нам, определённо, нужны новые названия. 

        {{name}}, сейчас включим связь с моим кораблём. Посмотри на картины и придумай новые названия, а затем передай их на корабль.
        
        Только учти, что данный тест не приносит баллов в конкурсе!
        
        Справишься?`,
        id: 'Q5',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Я постараюсь!',
            nextStep: 'CREATIVE_TEST/0',
          },
        ],
      },

      // Тест на криативность - задания с картинами

      ...CREATIVE_TEST_1,

      Q6: {
        text: `Ваши наборы слов очень необычны, признаём. Однако они абсолютно бесполезны и являются непродуктивной тратой времени. Мы считаем, что достойный вид не должен заниматься подобными несущественными делами.

        Решение о трансформации вашего парка не изменяем — отклонить!`,
        id: 'Q6',
        person: Interlocutors.ALIENSHIP,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'За что же вы так с нами?',
            nextStep: 'Q7',
          },
        ],
      },

      Q7: {
        text: `Не слушай их, {{name}}! Мне твои названия понравились даже больше оригинальных. И мы придумаем, чем ещё удивить пришельцев!`,
        id: 'Q7',
        person: Interlocutors.CAT,
        type: StepType.message,
        nextStep: 'Q8',
      },

      Q8: {
        text: `Согласен с Вжухом, ты отлично поработал! 

        Но нам срочно нужна новая идея.`,
        id: 'Q8',
        person: Interlocutors.ALIEN,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо, друзья! Пойдёмте придумывать, что бы такое им ещё показать.',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
