/* eslint-disable max-len */
import { Step, StepType, Interlocutors, TestStep } from '../../../types/scenario';
import { splitStep } from '../../../utils/ScenarioUtils';

const TVOI_KRUGOZOR_T2_STEP: TestStep = {
  id: 'TVOI_KRUGOZOR_T2_STEP',
  person: Interlocutors.SYSTEM,
  type: StepType.test,
  external_id: '20240528-144022-3448',
  path: 'Тест_Твой_кругозор_Junior_t2024_2024_05_28.json',
  name: 'Твой кругозор 2/1',
  order: [
    { timeout: 60, question: 'Q1', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q2', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q4', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q5', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q6', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q10', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q11', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q13', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q14', subtest: 'T2', description: '' },
    { timeout: 60, question: 'Q20', subtest: 'T2', description: '' },
  ],
  nextStep: 'TVOI_KRUGOZOR_T2_FINISH/0',
};

export const TVOI_KRUGOZOR_T2_ON_CHECK: Record<string, Step> = {
  TVOI_KRUGOZOR_T2_STEP,
  ...splitStep({
    person: Interlocutors.SYSTEM,
    id: 'TVOI_KRUGOZOR_T2_FINISH',
    text: `Ну вот и весь тест 😊  А теперь пора вспомнить, на какую частоту нужно настроить контроллер?`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Ангара, МГУ — Херсонес - ЮНЕСКО',
        nextStep: 'Q1_3_1',
      },
      {
        id: 'b2',
        text: 'Ангара, Космодром - ОБ - Иннополис',
        nextStep: 'Q1_3_2',
      },
      {
        id: 'b3',
        text: 'Союз, Космодром - ОБ - Иннополис',
        nextStep: 'Q1_3_1',
      },
    ],
  }),
};
