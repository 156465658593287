/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../scenario';

export const scenario_0: Scenario = {
  scenarioId: SceneId.scenario_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Начало игры',
    text: `Ты заходишь в парк достопримечательностей России, и милое пушистое создание начинает с тобой разговор на человеческом языке…`,
    buttons: ['Поговорить'],
  },
};
