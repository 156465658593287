import React, { useCallback, useEffect, useState } from 'react';
import { Question, QuestionButton, StepType } from '../../../types/scenario';

import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import useViewport from '../../../hooks/useViewport';

import { Button } from '../../../../common/shared/ui/Button';
import { Box } from '../../../../common/shared/ui/Box';
import ChatAnswerRadio from './ChatAnswerRadio';
import ChatAnswerTextarea from './ChatAnswerTextarea';
import { prepareText } from '../../TemplatedText';

import {
  ChatButtonStyled,
  StyledInputToolbar,
  StyledInputToolbarWrapper,
} from './InputToolbarStyled';
import { InputToolbarProps } from './InputToolbarTypes';

import { routes } from '../../../../common/shared/constants/routes';
import { formatNickName } from '../../NickName/NickName';
import { InputToolbarModal } from '../../InputToolbarModal';
import { selectUserInfo } from '../../../redux/user/userGetters';
import { selectGlobalTimer, selectTimer } from '../../../redux/scenario/scenarioGetters';
import { addZero } from '../../../../common/shared/utils/addZero';

export interface OptionButton {
  text: string;
  value: string;
}

const InputToolbar = ({
  instantShow,
  lastStep,
  onAnswer,
  isCanAnswer,
  variables,
}: InputToolbarProps) => {
  const [value, setStateValue] = React.useState('');
  const [selectedButtons, setSelectedButtons] = React.useState<QuestionButton[]>([]);
  const history = useHistory();

  const setValue = (str: string) => {
    if (lastStep.type === 'text_question' && lastStep.variable === 'NAME') {
      setStateValue(formatNickName(str));
    } else {
      setStateValue(str);
    }
  };

  const { isMobile } = useViewport();
  const messageTimer = useSelector(selectTimer);
  const globalTimer = useSelector(selectGlobalTimer);
  const timer = Math.max(messageTimer, globalTimer);

  useEffect(() => {
    setStateValue('');
    setSelectedButtons([]);
  }, [lastStep]);

  const handleSend = useCallback(() => {
    switch (lastStep.type) {
      case StepType.text_question:
        onAnswer(value);
        break;

      case StepType.question:
        onAnswer(selectedButtons);
        break;

      case StepType.exit:
        history.replace(routes.map);
        break;
      case StepType.to_map_step:
        history.replace(routes.map);
        break;
      case StepType.to_lk_step:
        history.replace(routes.profile);
        break;
      default:
        console.error('unknown action');
        break;
    }
  }, [lastStep.type, onAnswer, value, selectedButtons, history]);

  const isEmpty = React.useMemo(() => {
    if (lastStep.type === StepType.question) {
      return selectedButtons.length === 0;
    }

    if (lastStep.type === StepType.text_question) {
      return !value.trim().length;
    }

    return false;
  }, [lastStep.type, selectedButtons.length, value]);

  const SubmitElement = React.useMemo(() => {
    const text =
      lastStep.type === StepType.exit || lastStep.type === StepType.to_map_step
        ? 'Перейти в парк'
        : lastStep.type === StepType.to_lk_step
          ? 'В профиль'
          : 'Отправить ответ';

    const minutes = addZero(Math.floor(timer / 60));
    const seconds = addZero(Math.floor(timer % 60));
    const timeout = !isMobile || !timer ? '' : `(${minutes}:${seconds})`;

    return (
      <ChatButtonStyled>
        <Button $style='blue' onClick={handleSend} disabled={isEmpty}>
          {text} {timeout}
        </Button>
      </ChatButtonStyled>
    );
  }, [handleSend, isEmpty, lastStep.type, isMobile, timer]);

  const toggleButton = useCallback(
    (button: string) => {
      if (!lastStep || lastStep.type !== StepType.question) {
        return;
      }
      const btn = (lastStep.buttons || []).find((b) => b.id === button);
      if (!btn) {
        return;
      }
      if (lastStep.maxAnswers === 1) {
        setSelectedButtons([btn]);
      } else {
        const cp = [...selectedButtons];
        const index = cp.findIndex((b) => b.id === button);
        if (index !== -1) {
          cp.splice(index, 1);
        } else {
          if (selectedButtons.length === lastStep.maxAnswers) {
            return;
          }
          cp.push(btn);
        }
        setSelectedButtons(cp);
      }
    },
    [lastStep, selectedButtons],
  );

  const handleSendModal = (selectedButtons: QuestionButton[]) => {
    onAnswer(selectedButtons);
  };

  const openModal = (options: OptionButton[]) => {
    const lastStepAny = lastStep as Question;
    InputToolbarModal.open(lastStepAny, options, handleSendModal);
  };

  const userInfo = useSelector(selectUserInfo);
  const [isToolbarVisible, setIsToolbarVisible] = useState(instantShow);
  useEffect(() => {
    if (isCanAnswer) {
      const timeout = setTimeout(() => {
        setIsToolbarVisible(true);
      }, 1000);
      return () => clearTimeout(timeout);
    } else {
      setIsToolbarVisible(false);
    }
  }, [isCanAnswer]);

  if (!isCanAnswer || !isToolbarVisible) {
    return null;
  }

  if (lastStep.type === StepType.text_question) {
    return (
      <StyledInputToolbar>
        <StyledInputToolbarWrapper>
          <ChatAnswerTextarea value={value} setValue={setValue} />
          {SubmitElement}
        </StyledInputToolbarWrapper>
      </StyledInputToolbar>
    );
  }

  if (lastStep.type === StepType.question) {
    const options = (lastStep.buttons || []).map((a) => ({
      text: prepareText(a.text, variables, userInfo?.profile?.female ? 'female' : 'male'),
      value: a.id,
    }));

    if (options.length > 5) {
      return (
        <StyledInputToolbar>
          <Box $justify='center'>
            <ChatButtonStyled>
              <Button $style='blue' onClick={() => openModal(options)}>
                Дать ответы
              </Button>
            </ChatButtonStyled>
          </Box>
        </StyledInputToolbar>
      );
    }

    return (
      <StyledInputToolbar>
        <StyledInputToolbarWrapper>
          <ChatAnswerRadio
            isHorizontal={lastStep.isHorizontal}
            options={options}
            value={selectedButtons}
            onToggle={toggleButton}
          />
          {SubmitElement}
        </StyledInputToolbarWrapper>
      </StyledInputToolbar>
    );
  }

  if (
    lastStep.type === StepType.exit ||
    lastStep.type === StepType.to_map_step ||
    lastStep.type === StepType.to_lk_step
  ) {
    return (
      <StyledInputToolbar>
        <StyledInputToolbarWrapper>{SubmitElement}</StyledInputToolbarWrapper>
      </StyledInputToolbar>
    );
  }

  return null;
};

export default InputToolbar;
