import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SceneId } from '../../resources/scenario';
import { useRunScenarioAsync } from '../useRunScenarioAsync';
import { ScenarioHelperResponse, ScenarioRunnerConfig } from './types';
import { openStage } from '../../pages/MapPage/MapPage';
import { selectUserInfo } from '../../redux/user/userGetters';

export function useAutorunScenarioEffect(
  autorunScenario?: ScenarioRunnerConfig,
  isForceStop?: boolean,
  delay?: number,
) {
  const runScenario = useRunScenarioAsync();
  const user = useSelector(selectUserInfo);

  const videoIsAttached = user?.files?.presentation;

  useEffect(() => {
    if (!isForceStop) {
      if (autorunScenario && openStage > 0) {
        const runAfterPause = (func: () => void, _delay = delay || 600) => {
          const timeout = setTimeout(func, _delay);
          return () => clearTimeout(timeout);
        };

        if (autorunScenario.unsaved) {
          return runAfterPause(() => {
            runScenario(SceneId.scenario_unsaved, () => runScenario(autorunScenario.id));
          });
        }

        return runAfterPause(() =>
          runScenario(autorunScenario.id, () => {
            if (autorunScenario.runOnSubmit) {
              runScenario(autorunScenario.runOnSubmit);
            }
          }),
        );
      }
    }
  }, [isForceStop, autorunScenario, runScenario]);
}
