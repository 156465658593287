/* eslint-disable max-len */
import {
  Conditions,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../scenario';
import interlocutors from '../../interlocutors';
import { splitStep } from '../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT';
const TO_MAP_STEP = 'TO_MAP_STEP';
const TO_LK_STEP = 'TO_LK_STEP';

const TK_TEST: TestStep = {
  id: 'TK',
  person: Interlocutors.CAT,
  type: StepType.test,
  external_id: 'test-tvoi-kod-junior',
  path: 'test-tvoi-kod-junior-210401.json',
  name: 'Твой Код 1/1',
  order: [
    {
      description: `Сейчас будет четыре варианта. Хотя бы один из них нужно отметить «Точно да» и один «Определенно нет». А вот и первый вопрос:`,
      subtest: 'T1',
      question: 'Q1',
    },
    {
      description:
        'Отлично! Идем дальше. В этом вопросе, как и в прошлом, нужно выбрать четыре варианта ответа. Хотя бы один надо отметить «Точно да» и один «Определенно нет».',
      subtest: 'T1',
      question: 'Q2',
    },
    {
      description:
        'Ничего сложного, правда? Продолжай отвечать так же: из четырех вариантов хотя бы один «Точно да» и один «Определенно нет»',
      subtest: 'T1',
      question: 'Q3',
    },
    {
      description:
        'Очень приятно узнать тебя поближе. Следующий вопрос и все те же условия: из четырех вариантов минимум один «Точно да» и один «Определенно нет».',
      subtest: 'T1',
      question: 'Q4',
    },
    {
      description:
        'О, абсолютно с тобой согласен! Я бы тоже выбрал такие же варианты. Продолжим с вопросами. Этот ничем не отличается от предыдущих: из четырех вариантов хотя бы один «Точно да» и один «Определенно нет».',
      subtest: 'T1',
      question: 'Q5',
    },
    {
      description:
        'Ты очень интересный человек! Здесь отвечай аналогично: из четырех минимум один вариант «Точно да» и один «Определенно нет».',
      subtest: 'T1',
      question: 'Q6',
    },
    {
      description:
        'А я вот никогда не бывал на физкультуре, котам не очень-то это положено. Вопросов осталось совсем немного. Сейчас тоже выбирай хотя бы один «Точно да» и один «Определенно нет». Вариантов все так же четыре.',
      subtest: 'T1',
      question: 'Q7',
    },
    {
      description: `Хоть на уроках я не бывал, но во время дискуссии веду себя так же. 

А здесь уже чуть посложнее, так как вариантов восемь. Не один, а минимум два нужно отнести к «Точно да» и два к «Определенно нет».`,
      subtest: 'T2',
      question: 'Q1',
    },
    {
      description:
        'Ну все, последний вопрос. Снова восемь вариантов, так что хотя бы на два ответь «Точно да» и на два «Определенно нет».',
      subtest: 'T2',
      question: 'Q2',
    },
  ],
  nextStep: 'intro',
};

export const scenario_1_3_1: Scenario = {
  scenarioId: SceneId.scenario_1_3_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `{{NAME}}, давай продолжим наше знакомство 😺

    Другие обитатели парка тоже хотят с тобой познакомиться. Я подготовил несколько вопросов и ответов к ним. Выбери, пожалуйста, насколько тот или иной вариант тебе подходит.`,
    buttons: ['Пойдем'],
  },
};

export const scenario_1_3: Scenario = {
  scenarioId: SceneId.scenario_1_3,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'МГУ',
    scene_id: 'scenario_1_3',
    startId: 'Q2',
    steps: {
      Q2: {
        type: StepType.question,
        id: 'Q2',
        person: Interlocutors.CAT,
        maxAnswers: 1,
        text: `Отвечай «Точно да», если вариант про тебя, и «Определенно нет», если на тебя это не похоже. А на случай трудностей есть вариант «Не знаю». Не переживай, все просто 😺`,
        buttons: [
          {
            id: 'b1',
            nextStep: 'TK',
            text: 'Хорошо',
          },
        ],
      },
      TK: TK_TEST,
      // видеовизитка
      intro: {
        type: StepType.message,
        id: 'intro',
        person: Interlocutors.CAT,
        text: ' {{NAME}}, другие обитатели парка хотят узнать о тебе кое-что еще. Запиши, пожалуйста, видеовизитку. Подробности этого задания ты увидишь в своем профиле.',
        callToAction: 'Отправь видеовизитку и возвращайся сюда.',
        nextStep: 'video',
      },
      video: {
        type: StepType.system,
        condition: {
          type: Conditions.IS_VIDEO_ATTACHED,
          variants: ['Q4', TO_LK_STEP],
        },
        id: 'video',
        person: Interlocutors.SYSTEM,
        _meta: {},
      },
      [TO_LK_STEP]: {
        person: Interlocutors.CAT,
        id: 'lkStep',
        type: StepType.to_lk_step,
      },
      Q4: {
        id: 'Q4',
        text: 'Ты молодец! Я так рад, что именно ты нам помогаешь! Спасибо, скоро о тебе узнают все 😻',
        person: Interlocutors.CAT,
        nextStep: 'Q7',
        type: StepType.message,
      },
      Q7: {
        id: 'Q7',
        text: 'Знаешь, из-за всех этих странностей, которые творятся в последнее время, местные обитатели стали настороженно относиться к новеньким… Мы решили быть более бдительными и разузнать о тебе побольше.',
        person: Interlocutors.CAT,
        nextStep: 'Q8',
        type: StepType.message,
      },
      Q8: {
        id: 'Q8',
        text: 'Расскажи, пожалуйста, о себе еще немного.',
        person: Interlocutors.CAT,
        nextStep: 'Q9',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Без проблем',
            nextStep: 'Q9',
          },
        ],
      },
      // Тест на коммуникативность 2/3 - Почему принял решение участвовать
      Q9: {
        id: 'Q9',
        person: Interlocutors.CAT,
        type: StepType.message,
        text: 'Ответь, пожалуйста, в 2-4 предложениях. Вариант «не знаю» не принимается 😺',
        nextStep: 'Q10',
      },
      Q10: {
        id: 'Q10',
        type: StepType.text_question,
        variable: 'TEST_COMMUNICATIVE_GOAL',
        person: Interlocutors.CAT,
        nextStep: 'Q10_1',
        text: 'Почему ты принял решение участвовать в конкурсе?',
      },
      Q10_1: {
        id: 'Q10_1',
        type: StepType.system,
        condition: {
          type: Conditions.IS_ABOUT_MYSELF_VALID,
          variants: ['Q11/0', 'Q10_2'],
        },
        _meta: {},
        person: Interlocutors.CAT,
        nextStep: 'Q11/0',
      },
      Q10_2: {
        id: 'Q10_2',
        type: StepType.message,
        person: Interlocutors.CAT,
        nextStep: 'Q9',
        text: 'Так не пойдет 😿. Попробуй снова',
      },
      ...splitStep({
        id: 'Q11',
        person: Interlocutors.CAT,
        type: StepType.message,
        text: `{{NAME}}, спасибо! Весь парк только о тебе и говорит! 😻

        После того, как я изучу и обдумаю твои ответы, расскажу о результатах в твоем профиле, который откроется чуть позже.`,
        nextStep: EXIT_ID,
      }),
      [TO_MAP_STEP]: {
        person: Interlocutors.SYSTEM,
        id: TO_MAP_STEP,
        type: StepType.to_map_step,
      },
      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
