/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const moscowCity_1_1_0: Scenario = {
  scenarioId: SceneId.moscowCity_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.CAT,
    title: 'Москва-Сити',
    text: `Не нравится мне наш новый спутник. Но ладно, Сан Саныч тут совсем близко работает — в Москва-Сити.

    Одна лапа здесь — другая там.`,
    buttons: ['Продолжить'],
  },
};
