/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NetworkStatus, NetworkStatusUtil } from '../../common/shared/utils/networkStatus';
import { ErrorPage } from '../pages/ErrorPage';

const NetworkFailedContainerStyled = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  background: #000;
`;
export function NetworkOverlay() {
  const [networkStatus, setNetworkStatus] = useState(NetworkStatus.online);
  useEffect(() => {
    NetworkStatusUtil.addListener((status) => {
      setNetworkStatus(status);
      const classes = document.body.className.split(' ');
      const errorClass = classes.indexOf('network_error');
      if (status === NetworkStatus.online) {
        if (errorClass !== -1) {
          classes.splice(errorClass, 1);
        }
      } else {
        if (errorClass === -1) {
          classes.push('network_error');
        }
      }
      document.body.className = classes.join(' ').trim();
    });
  });
  if (networkStatus === NetworkStatus.offline) {
    return (
      <NetworkFailedContainerStyled>
        <ErrorPage
          title=''
          description={`Похоже, у тебя возникли проблемы с интернетом, проверь соединение с интернетом и перезагрузи сайт.

В результате разрыва связи ты [[мог/могла]] потерять ответ на последний вопрос и тебе необходимо будет пройти его заново.`}
          hideButton
        />
      </NetworkFailedContainerStyled>
    );
  }
  return <div />;
}
