import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';
import { TEST_TVOI_KOD_3 } from '../../tests/test_tvoi_kod';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

const TK_TEST: TestStep = {
  id: 'TK_TEST',
  person: Interlocutors.SYSTEM,
  type: StepType.test,
  external_id: 'test-tvoi-kod-junior',
  path: 'test-tvoi-kod-junior-210401.json',
  name: 'Твой Код 1/1',
  order: [
    {
      description:
        'Восемь вариантов, так что хотя бы на два ответь «Точно да» и на два «Определенно нет».',
      subtest: 'T2',
      question: 'Q2',
    },
  ],
  nextStep: 'Q6',
};

export const olympicPark_1_1: Scenario = {
  scenarioId: SceneId.olympicPark_1_1,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Знакомство с Петровым',
    scene_id: 'olympicPark_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        id: 'Q1',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        text: `Привет! Ты же {{NAME}}, верно? А меня Петров зовут. Я местный изобретатель и инженер 👨‍🔬 

        Вжух мне про тебя рассказывал. Рад наконец-то познакомиться лично.`,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо, и мне приятно познакомиться!',
            nextStep: 'Q2',
          },
          {
            id: 'b2',
            text: 'Да-да, а что тут происходит?',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        id: 'Q2',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        text: `Тут у нас в парке мистика творится. Гравитационные аномалии, химические превращения воды в газировку, нейролингвистические трансформации. А ты ведь ещё с Сан Санычем не [[познакомился/познакомилась]] — верно?

        Из-за этого работа парка застопорилась. 
        
        И даже тут всё наперекосяк. Я пришёл погонять мяч, но трава на поле оказалась покрыта пятнами какой-то фиолетовой слизи.`,
        buttons: [
          {
            id: 'b1',
            text: 'Да, всё очень запутанно. А расскажите сначала, где это мы сейчас?',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        id: 'Q3',
        type: StepType.message,
        nextStep: 'Q4',
        text: `Мы сейчас в копии Олимпийского парка. Настоящий находится на берегу Черного моря в городе Сочи. `,
        person: Interlocutors.PETROV,
      },
      Q4: {
        id: 'Q4',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        text: `Это очень особенный город, ведь там есть и море, и горы! Летом можно загорать и купаться, а зимой хоть и светит яркое солнце, но снег в горах не тает, и можно кататься на лыжах и сноуборде! 😍`,
        buttons: [
          {
            id: 'b1',
            text: 'А я там [[был/была]]',
            nextStep: 'Q5',
          },
          {
            id: 'b2',
            text: 'Наверное, там очень круто отдыхать!',
            nextStep: 'Q8',
          },
        ],
      },
      Q5: {
        id: 'Q5',
        text: 'Как здорово! Тебе там понравилось?',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q6',
            text: 'Да, было классно!',
          },
          {
            id: 'b2',
            nextStep: 'Q7',
            text: 'Да как-то не очень',
          },
        ],
      },
      Q6: {
        id: 'Q6',
        person: Interlocutors.PETROV,
        nextStep: 'Q9',
        text: 'Там действительно здорово отдыхается 😊',
        type: StepType.message,
      },
      Q7: {
        id: 'Q7',
        person: Interlocutors.PETROV,
        type: StepType.message,
        nextStep: 'Q9',
        text: 'Ну что ж, и такое бывает 🤷‍️',
      },
      Q8: {
        id: 'Q8',
        person: Interlocutors.PETROV,
        type: StepType.message,
        nextStep: 'Q9',
        text: 'Да, там определенно стоит побывать! 😊',
      },
      Q9: {
        id: 'Q9',
        text: `В общем, именно из-за такого классного климата Сочи был выбран главным городом зимних Олимпийских игр 2014 года.`,
        nextStep: 'Q10',
        person: Interlocutors.PETROV,
        type: StepType.message,
      },
      Q10: {
        id: 'Q10',
        text: `Самое знаменитое сооружение парка — стадион «Фишт», он назван в честь горной вершины Кавказского хребта и построен в форме ракушки. Здесь были проведены потрясающие церемонии, посвященные началу и окончанию игр. Стадион настолько большой, что может вместить в себя население небольшого города!

        Именно в копии стадиона Фишт мы сейчас и находимся. Обычно мы тут играем в футбол с обитателями парка и гостями.`,
        nextStep: 'Q11',
        type: StepType.message,
        person: Interlocutors.PETROV,
      },
      Q11: {
        id: 'Q11',
        text: `Еще в сочинском олимпийском парке есть арены для соревнований по фигурному катанию, конькобежному спорту, хоккею, керлингу и многим другим видам спорта.`,
        type: StepType.question,
        person: Interlocutors.PETROV,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q12/0',
            text: 'А где и когда появились Олимпийские игры?',
          },
          {
            id: 'b2',
            nextStep: 'Q16',
            text: 'Спасибо за интересную информацию! Вам тут нужна помощь?',
          },
        ],
      },
      ...splitStep({
        id: 'Q12',
        text: `Первые Олимпийские игры прошли много веков назад в Древней Греции, а участвовать в них могли только мужчины.
        
        И по сей день Олимпийские игры известны во всем мире, и спортсмены из разных стран принимают в них участие, чтобы стать олимпийскими чемпионами.`,
        type: StepType.question,
        maxAnswers: 1,
        person: Interlocutors.PETROV,
        buttons: [
          {
            id: 'b1',
            text: 'Я тоже занимаюсь спортом!',
            nextStep: 'Q13',
          },
          {
            id: 'b2',
            text: 'Ничего себе, сколько этим играм уже лет!',
            nextStep: 'Q15',
          },
        ],
      }),
      Q13: {
        id: 'Q13',
        text: 'А я сразу понял, что ты [[спортсмен/спортсменка]]! 😍 Какой вид спорта?',
        type: StepType.text_question,
        callToAction: 'Напиши ниже',
        variable: 'SPORT',
        nextStep: 'Q14',
        person: Interlocutors.PETROV,
      },
      Q14: {
        id: 'Q14',
        text: 'Уважаю спортивных людей! Ты [[большой/большая]] молодец! Как я уже сказал, спорт — это здоровье! 💪',
        person: Interlocutors.PETROV,
        type: StepType.message,
        nextStep: 'Q16',
      },
      Q15: {
        id: 'Q15',
        nextStep: 'Q16',
        person: Interlocutors.PETROV,
        type: StepType.message,
        text: 'Да! Знаешь, спорт не зря всегда был в почете, ведь как я уже сказал, спорт — это здоровье! 💪',
      },
      Q16: {
        person: Interlocutors.PETROV,
        id: 'Q16',
        type: StepType.question,
        maxAnswers: 1,
        text: 'Ну, перейдём к делу?',
        buttons: [
          {
            id: 'b1',
            text: 'Да, давайте',
            nextStep: 'Q17',
          },
        ],
      },
      Q17: {
        id: 'Q17',
        type: StepType.question,
        text: `На этом поле сегодня вечером матч, юные ребята футболисты играют. А оно всё испачкано чем-то липким и фиолетовым.

        Я уже позвал рабочих, чтобы почистить поле, но твоя помощь тоже не помешает. Поможешь?`,
        person: Interlocutors.PETROV,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Конечно, давайте щётку!',
            nextStep: 'Q17_0',
          },
        ],
      },

      Q17_0: {
        id: 'Q17_0',
        type: StepType.question,
        text: `Здорово, спасибо! 

        А пока работаем, расскажешь о себе?`,
        person: Interlocutors.PETROV,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, спрашивайте',
            nextStep: 'START_TEST_TVOI_KOD_3',
          },
        ],
      },

      // Тест - Твой код 3/3
      TEST_TVOI_KOD_3: TEST_TVOI_KOD_3,

      Q20: {
        id: 'Q20',
        person: Interlocutors.PETROV,
        nextStep: 'Q21',
        text: `Фух! Быстро справились! 

        И собеседник ты интересный.
        
        Скажу по секрету — забрал себе образец этой слизи. Хочу пропустить через анализатор в лаборатории Иннополиса. Я думаю, что эта слизь как-то связана со всеми событиями в парке.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q21',
            text: 'Точно, так и есть! ',
          },
        ],
      },
      Q21: {
        id: 'Q21',
        type: StepType.question,
        nextStep: 'Q22',
        text: `А у нас тоже кое-что есть для анализа. Принесли воду из Байкала по просьбе Бабушки.`,
        person: Interlocutors.CAT,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, Петров, можно с вами в лабораторию?',
            nextStep: 'Q22',
          },
        ],
      },
      Q22: {
        id: 'Q22',
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        text: `Да, только есть ещё одно дело. В Иннополисе тоже происходит мистика — вырубило электропитание. `,
        buttons: [
          {
            id: 'b1',
            text: 'Так вот, почему мы не смогли открыть дверь в мастерскую!',
            nextStep: 'Q23',
          },
        ],
      },

      Q23: {
        id: 'Q23',
        text: `Да, в Иннополисе всё высокотехнологичное и требует электричества, даже двери.

        Но я всё предусмотрел — есть резервное питание на солнечных батареях. Только последние детали для них мне на днях должен был привезти Сан Саныч из Китая. Без деталей ничего не работает.`,
        person: Interlocutors.PETROV,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q24',
            text: 'А давайте мы сходим к нему, чтобы быстрее было?',
          },
        ],
      },

      // Здесь должен быть тест

      Q24: {
        id: 'Q24',
        person: Interlocutors.PETROV,
        text: `Хорошо, только вам надо запомнить одну важную вещь. Скажите Сан Санычу, что вам нужны «Иератаб Йонченлос Ялд Илатед». 
        
        Лучше записать.`,
        type: StepType.question,
        maxAnswers: 1,
        nextStep: 'Q25',
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q25',
            text: 'Ого! Это на китайском?',
          },
        ],
      },

      Q25: {
        id: 'Q25',
        person: Interlocutors.PETROV,
        nextStep: 'Q26',
        text: `Вроде того. Так он вас поймёт.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q26',
            text: 'Хорошо',
          },
        ],
      },

      Q26: {
        id: 'Q26',
        person: Interlocutors.CAT,
        nextStep: 'Q27',
        text: `У нас нет ни ручки, ни бумаги.

        Но название я запомнил. У котов превосходная память. Вот смотрите: «Иератаб Йонченлос Ялд Илатед».`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: 'Q27',
            text: 'Здорово! Молодец, Вжух!',
          },
        ],
      },

      Q27: {
        id: 'Q27',
        person: Interlocutors.PETROV,
        nextStep: 'Q28',
        text: `Ну ладно. Как заберете деталь, встретимся в лаборатории Иннополиса.

        Удачи!`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: EXIT_ID,
            text: 'Спасибо, до свидания! ',
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.SYSTEM,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.SYSTEM,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
