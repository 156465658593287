import styled, { css } from 'styled-components';
import { Box } from '../../../common/shared/ui/Box';
import colors from '../../../common/shared/constants/colors';

export const StyledMore = styled.div<{ $isOpened: boolean }>`
  display: flex;
  align-items: center;

  svg {
    ${({ $isOpened }) =>
      $isOpened
        ? css`
            transform: rotate(180deg);

            path {
              stroke: ${colors.blueButton};
            }
          `
        : ''};
  }

  & > * {
    margin-right: 4px;

    &:first-child {
      display: 'block';
    }

    &:last-child {
      margin-right: 0px;
    }
  }
`;

export const StyledChallengeTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  cursor: pointer;

  &:hover {
    ${StyledMore} > svg path {
      stroke: ${colors.blueButton};
    }
  }

  & > * {
    width: auto;
  }

  & > * + * {
    margin-top: 5px;
  }
`;

export const StyledChallengeBottomContainer = styled.div<{ $isOpened: boolean }>`
  display: ${({ $isOpened }) => ($isOpened ? 'flex' : 'none')};
  flex-direction: column;

  & > * {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const StyledChallengeUploadContainer = styled.div`
  width: 100%;

  & > div {
    padding: 0px;
  }
`;

export const StyledChallenge = styled(Box)`
  display: flex;
  direction: column;

  & > * {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;

export const StyledChallengeIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 5px;
`;
