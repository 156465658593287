/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  SystemStepType,
  Step,
} from '../../../../types/scenario';
import { splitStep } from 'src/sixthGrade/utils/ScenarioUtils';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

const CREATIVE_EXAMPLE: Record<string, Step> = {
  ...splitStep({
    person: Interlocutors.GRANDMOTHER,
    id: 'CREATIVE_EXAMPLE_1',
    text: `Обрати внимание, у тебя будет всего 5 минут. Записывай по одному варианту в поле ввода и обязательно жми кнопку «Отправить ответ». 

Для следующей твоей идеи появится новое поле ввода. То есть нужно писать и отправлять по одной идее за раз. Максимально ты можешь придумать 7 вариантов.`,
    type: StepType.question,
    maxAnswers: 1,
    callToAction: 'Поиграем?',
    buttons: [
      {
        id: 'b1',
        text: 'Да, давайте!',
        nextStep: 'CREATIVE_EXAMPLE_2',
      },
    ],
  }),
  CREATIVE_EXAMPLE_2: {
    person: Interlocutors.GRANDMOTHER,
    id: 'CREATIVE_EXAMPLE_2',
    type: StepType.message,
    text: `Придумай, пожалуйста, как можно больше интересных и необычных вариантов использования школьной линейки. Напоминаю, у тебя есть 5 минут. И не забудь, твои варианты должны быть приличными и осмысленными.`,
    nextStep: 'CREATIVE_EXAMPLE_3',
  },
  CREATIVE_EXAMPLE_3: {
    person: Interlocutors.GRANDMOTHER,
    id: 'CREATIVE_EXAMPLE_3',
    type: StepType.system,
    _meta: {
      type: SystemStepType.limitedTimeInput,
      time: 5 * 60,
      maxAnswers: 7,
      exitStep: 'Q8',
    },
    nextStep: 'CREATIVE_EXAMPLE_4',
  },
  CREATIVE_EXAMPLE_4: {
    id: 'CREATIVE_EXAMPLE_4',
    person: Interlocutors.GRANDMOTHER,
    text: '',
    type: StepType.text_question,
    variable: 'CREATIVE_EXAMPLE',
    nextStep: 'CREATIVE_EXAMPLE_4',
  },
};

export const stg2_lake_1_1: Scenario = {
  scenarioId: SceneId.stg2_lake_1_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Байкал',
    scene_id: 'stg2_lake_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Наконец вы вернулись! 

        Присаживайтесь на траву. Вжух, я для тебя рыбные косточки приготовила. Угощайся!
        
        Как ваши дела?`,
        id: 'Q1',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Всё хорошо! Но как поживает рыба в озере? Мы пока не узнали, что случилось с водой',
            nextStep: 'Q2',
          },
        ],
      },

      Q2: {
        text: `Ой, за рыбу я очень боюсь! Пока чувствует себя хорошо, но нужно бы её пересадить на время в аквариумы с чистой водой. Но где же у нас в парке взять столько аквариумов?`,
        id: 'Q2',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        nextStep: 'Q3',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Мы попробуем найти вам аквариумы! А Вы сможете нам помочь?',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        text: `Конечно, всегда рада! Что случилось?`,
        id: 'Q3',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        nextStep: 'Q4',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Мы друга потеряли. Он маленький, белый и пушистый. И совсем не разговаривает. Не бывал здесь случайно?',
            nextStep: 'Q4',
          },
        ],
      },

      Q4: {
        text: `Хмм… Да, видела такого необычного зверька. У него были такие голодные глаза, что я дала ему рыбу, а пока ходила ему за добавкой, его и след простыл.`,
        id: 'Q4',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        nextStep: 'Q5',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Жалко, что он так убежал. Нам очень нужно его найти! ',
            nextStep: 'Q5',
          },
        ],
      },

      Q5: {
        text: `Да, очень нужно!

        Но у нас есть ещё одно важное дело. {{NAME}} готовится к викторине, в которой обязательно должен ответить на все вопросы правильно. И там есть вопросы про Байкал.
        
        Расскажи, пожалуйста, про него ещё раз, чтобы мы точно всё запомнили!`,
        id: 'Q5',
        person: Interlocutors.CAT,
        type: StepType.message,
        nextStep: 'Q6',
      },

      Q6: {
        text: `Конечно, ребята!

        Озеро Байкал — самое глубокое на Земле и самое большое в России. Если на дно Байкала друг на друга поставить девять зданий МГУ, то шпиль последнего все равно не покажется из воды, настолько оно глубокое! 🤯
        
        В Байкале живёт множество уникальных рыб и растений и содержатся огромные запасы пресной воды — одна пятая всех мировых запасов!`,
        id: 'Q6',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Спасибо, очень интересно!',
            nextStep: 'Q7',
          },
        ],
      },
      Q7: {
        text: `На здоровье, ребята!

        Вспомнила ещё один случай с вашим зверьком. У меня радиоприёмник есть, старенький. Так малыш, как увидел его, начал сразу крутить, волну искать. Ничего интересного для себя он не нашёл, но прибор понравился. Я удивилась и обрадовалась его креативности и любознательности! 

        Кстати, в викторине и других испытаниях тебе тоже может пригодится способность мыслить и действовать нестандартно - то есть, эта самая креативность, так что предлагаю сейчас тебе быстренько размять мозги и ответить на один вопрос.`,
        id: 'Q7',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        callToAction: 'Ответишь?',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Конечно! Задавайте!',
            nextStep: 'Q7_1',
          },
        ],
      },
      Q7_1: {
        text: `Данный тест является тренировочным и не приносит баллов за конкурс.`,
        id: 'Q7_1',
        person: Interlocutors.SYSTEM,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ок',
            nextStep: 'CREATIVE_EXAMPLE_1/0',
          },
        ],
      },

      ...CREATIVE_EXAMPLE,

      Q8: {
        text: `Ну какие же вы молодцы! Если я ещё могу чем-то помочь, спрашивайте!`,
        id: 'Q8',
        person: Interlocutors.GRANDMOTHER,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Пока этого более чем достаточно! Хорошего Вам дня, до свидания.',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
