/* eslint-disable max-len */
import {
  Action,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  Step,
  StepType,
} from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

export const scenario_2_21: Scenario = {
  scenarioId: SceneId.scenario_2_21,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Мамаев курган',
    person: Interlocutors.SYSTEM,
    text: `Вы с Вжухом находитесь у большой возвышенности — Мамаева кургана. Место поражает своими масштабами и величеством. Вы видите много впечатляющих памятников, окруженных цветами.`,
    buttons: ['Пойдем!'],
  },
};

const ALT: Record<string, Step> = {
  ...splitStep({
    id: 'ALT1',
    person: Interlocutors.GRANDMOTHER,
    text: `Здравствуйте, мои хорошие! {{NAME}}, ты уже [[вернулся/вернулась]]! Ох, не поспела к твоему возвращению! Меня задержал мой старый школьный приятель Иннокентий… Видите ли, он передвигается на коляске. Иннокентий очень хотел полюбоваться красотами кургана и все было хорошо, пока мы не столкнулись с этой длиннющей лестницей! 🙁

Я попыталась помочь ему подняться, но через пару ступенек поняла, что сил у меня уже не так много, как хотелось бы… 😔

Я оставила его внизу, а сама поспешила к вам, потому что уже и так очень опоздала на встречу. Он сейчас совсем один внизу, ждет, что кто-нибудь ему поможет 😔`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Здравствуйте, Бабуля! [[Рад/Рада]] снова Вас видеть. Не беспокойтесь, я с радостью помогу Иннокентию подняться по лестнице. (Игровое время будет заморожено на 15 минут)',
        nextStep: 'ALT2',
      },
      {
        id: 'b2',
        text: 'Здравствуйте, Бабуля! Вижу, Вы все так же бодры и веселы! А давайте позовем на помощь полицейского, он поможет Иннокентию подняться сюда. (Игровое время будет заморожено на 5 минут)',
        nextStep: 'ALT2',
      },
      {
        id: 'b3',
        text: 'Здравствуйте, Бабуля, и Вы здесь, здорово! Сейчас у меня нет времени, чтобы помочь Иннокентию. (Игровое время не будет заморожено)',
        nextStep: 'ALT3',
      },
    ],
  }),
  ALT2: {
    person: Interlocutors.GRANDMOTHER,
    id: 'ALT2',
    type: StepType.message,
    text: `Спасибо огромное, {{NAME}}! Я знала, что могу рассчитывать на тебя! 😍`,
    nextStep: 'Q16',
  },
  ALT3: {
    person: Interlocutors.GRANDMOTHER,
    id: 'ALT3',
    type: StepType.message,
    text: `Да, действительно, [[школьник/школьница]] вряд ли сможет справиться с такой задачей… 😔`,
    nextStep: 'Q16',
  },
};

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const scenario_2_21_1: Scenario = {
  isRequired: true,
  scenarioId: SceneId.scenario_2_21_1,
  type: ScenarioType.chat,
  data: {
    title: 'Мамаев курган',
    scene_id: 'scenario_2_21_1',
    startId: 'Q0/0',
    steps: {
      ...splitStep({
        id: 'Q0',
        person: Interlocutors.CAT,
        text: `А вот и Мамаев курган — одна из самых памятных достопримечательностей нашей страны 😻

Настоящий Мамаев курган находится в самом центре Волгограда, но у нас тут точная копия и, конечно же, тоже есть памятник-ансамбль «Героям Сталинградской битвы».`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А что такое памятник-ансамбль?',
            nextStep: 'Q1',
          },
          {
            id: 'b2',
            text: 'Почему памятник в Волгограде, а битва Сталинградская?',
            nextStep: 'Q2',
          },
        ],
      }),
      ...splitStep({
        id: 'Q1',
        person: Interlocutors.CAT,
        text: `Памятник-ансамбль — это когда много памятников складываются в единую композицию. В данном случае, военной тематики.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Почему памятник в Волгограде, а битва Сталинградская?',
            nextStep: 'Q2',
          },
          {
            id: 'b2',
            text: 'Понятно',
            nextStep: 'Q3/0',
          },
        ],
      }),
      ...splitStep({
        id: 'Q2',
        person: Interlocutors.CAT,
        text: `Волгоград раньше назывался Сталинградом, с 1925 по 1961 год. За выдающиеся заслуги перед Родиной 1 мая 1945 года Сталинград был удостоен почетного звания города-героя.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'А что такое памятник-ансамбль?',
            nextStep: 'Q1',
          },
          {
            id: 'b2',
            text: 'Как интересно!',
            nextStep: 'Q3/0',
          },
        ],
      }),
      ...splitStep({
        id: 'Q3',
        person: Interlocutors.CAT,
        type: StepType.message,
        text: `Расположение мемориала неслучайно — за высоту 102,0 (так на военных картах обозначался курган) велись жесточайшие бои. Это была стратегическая точка, ведь с вершины хорошо виден весь город. 
        
        Главным монументом Мамаева кургана является грандиозная скульптура «Родина-мать зовет!». Она признана одной из самых высоких статуй в мире, ведь ее общая высота — аж 85 метров, только представь!
        
        Не менее значимое место кургана — Зал Воинской славы. Внутри горит Вечный огонь в виде скульптурной руки с факелом, а на стене есть надпись: «Да, были простыми смертными и мало кто уцелел из нас, но мы все выполнили свой патриотический долг перед священной Родиной Матерью». На стенах высечены имена погибших солдат. 
        
        Зал Воинской славы открыт для посетителей в любое время суток, так что обязательно посети это великое место, если будешь в Волгограде.`,
        nextStep: 'Q5',
      }),
      Q5: {
        person: Interlocutors.TEACHER,
        id: 'Q5',
        text: 'Привет, дорогие! Как хорошо, что вы здесь. Видите, у нас тут как раз приготовления к параду. Хоть люди потихоньку начинают возвращаться в парк, но рук все равно не хватает. Здесь надо все как следует подготовить к празднику 😔',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Я [[готов/готова]], что надо делать?',
            nextStep: 'Q6/0',
          },
          {
            id: 'b2',
            text: 'Пожалуй, погуляю еще по парку, так здесь стало хорошо и солнечно!',
            nextStep: TO_MAP_ID,
          },
        ],
      },
      ...splitStep({
        person: Interlocutors.TEACHER,
        id: 'Q6',
        text: `Для развития чувства патриотизма, приобщения к традициям и богатейшей культуре нашей страны, мы хотим провести праздничную викторину для школьников и проверить, кто же из ребят лучше всех знает историю.

Но вот только я не уверена понравятся ли ребятишкам вопросы. Ты можешь ответить на вопросы викторины, и сказать, насколько они хороши? 😊`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да, конечно! [[Рад/Рада]] помочь в таком важном деле!',
            nextStep: 'Q7/0',
          },
          {
            id: 'b2',
            text: 'Никогда не [[любил/любила]] историю, но так уж и быть, отвечу на Ваши вопросы',
            nextStep: 'Q7/0',
          },
        ],
      }),

      ...splitStep({
        id: 'Q7',
        person: Interlocutors.TEACHER,
        text: `Тогда начнем! 

Первый вопрос: сколько лет шла Великая Отечественная война?`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: '1 год',
            nextStep: 'Q7-1',
          },
          {
            id: 'b2',
            text: '4 года',
            nextStep: 'Q8',
          },
          {
            id: 'b3',
            text: '5 лет',
            nextStep: 'Q7-2',
          },
        ],
      }),
      'Q7-1': {
        id: 'Q7-1',
        person: Interlocutors.TEACHER,
        text: `Подумай еще. Это неверный ответ.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b2',
            text: '4 года',
            nextStep: 'Q8',
          },
          {
            id: 'b3',
            text: '5 лет',
            nextStep: 'Q7-2',
          },
        ],
      },
      'Q7-2': {
        id: 'Q7-2',
        person: Interlocutors.TEACHER,
        text: `Нет, это неверно. Но ты очень [[близок/близка]] к правильному ответу.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: '1 год',
            nextStep: 'Q7-1',
          },
          {
            id: 'b2',
            text: '4 года',
            nextStep: 'Q8',
          },
        ],
      },
      Q8: {
        person: Interlocutors.TEACHER,
        id: 'Q8',
        type: StepType.message,
        text: `Совершенно верно! Великая Отечественная Война шла с 1941 по 1945 год.`,
        nextStep: 'Q9',
      },
      Q9: {
        person: Interlocutors.TEACHER,
        id: 'Q9',
        text: 'Второй вопрос: частью какой войны была Великая Отечественная война?',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Частью Второй Мировой войны',
            nextStep: 'Q10',
          },
          {
            id: 'b2',
            text: 'Частью Первой Мировой войны',
            nextStep: 'Q9-1',
          },
          {
            id: 'b3',
            text: 'Частью Мамаева побоища',
            nextStep: 'Q9-2',
          },
        ],
      },
      'Q9-1': {
        person: Interlocutors.TEACHER,
        id: 'Q9-1',
        text: 'Почти. Дам тебе подсказку: Великая Отечественная Война была частью одной из Мировых войн, но не Первой.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Частью Второй Мировой войны',
            nextStep: 'Q10',
          },
          {
            id: 'b3',
            text: 'Частью Мамаева побоища',
            nextStep: 'Q9-2',
          },
        ],
      },
      'Q9-2': {
        person: Interlocutors.TEACHER,
        id: 'Q9-2',
        text: 'Мамаево побоище было задолго до ВОВ. Подумай еще.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Частью Второй Мировой войны',
            nextStep: 'Q10',
          },
          {
            id: 'b2',
            text: 'Частью Первой Мировой войны',
            nextStep: 'Q9-1',
          },
        ],
      },
      Q10: {
        person: Interlocutors.TEACHER,
        id: 'Q10',
        text: 'Да! Ты действительно отлично знаешь историю нашей Родины!',
        type: StepType.message,
        nextStep: 'Q11',
      },
      Q11: {
        person: Interlocutors.TEACHER,
        id: 'Q11',
        text: 'И последний вопрос: какой город России в годы Великой Отечественной войны выдержал почти 900-дневную осаду немецких войск?',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Сталинград, ныне Волгоград',
            nextStep: 'Q11-1',
          },
          {
            id: 'b2',
            text: 'Калининград',
            nextStep: 'Q11-2',
          },
          {
            id: 'b3',
            text: 'Ленинград, ныне Санкт-Петербург',
            nextStep: 'Q12',
          },
        ],
      },
      'Q11-1': {
        person: Interlocutors.TEACHER,
        id: 'Q11-1',
        text: 'Сталинград — один из важнейших городов-участников ВОВ. Однако блокада была не здесь.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b2',
            text: 'Калининград',
            nextStep: 'Q11-2',
          },
          {
            id: 'b3',
            text: 'Ленинград, ныне Санкт-Петербург',
            nextStep: 'Q12',
          },
        ],
      },
      'Q11-2': {
        person: Interlocutors.TEACHER,
        id: 'Q11-2',
        text: 'У этого города интересная судьба. До 1945 года он был частью Германии, но в ходе ВОВ стал частью СССР. Хоть он и участвовал в Великой Отечественной Войне, но блокады здесь не было.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Сталинград, ныне Волгоград',
            nextStep: 'Q11-1',
          },
          {
            id: 'b3',
            text: 'Ленинград, ныне Санкт-Петербург',
            nextStep: 'Q12',
          },
        ],
      },
      Q12: {
        person: Interlocutors.TEACHER,
        id: 'Q12',
        type: StepType.message,
        text: `Да, блокада началась в Ленинграде осенью 1941 года и продлилась до января 1944 года. Жители Ленинграда мужественно выдержали всю блокаду, но не сдали город врагу. `,
        nextStep: 'Q13',
      },

      Q13: {
        person: Interlocutors.TEACHER,
        id: 'Q13',
        text: 'Как ты быстро [[справился/справилась]] с викториной! Тебе понравились вопросы?',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            action: Action.GiveBand,
            text: 'Да, было очень интересно. [[Уверен/Уверена]], Ваша викторина очень понравится ребятишками парка!',
            nextStep: 'Q14',
          },
          {
            id: 'b2',
            action: Action.GiveBand,
            text: 'Вопросы показались мне немного сложными, но знать историю своей страны очень важно!',
            nextStep: 'Q14',
          },
        ],
      },
      Q14: {
        person: Interlocutors.TEACHER,
        id: 'Q14',
        type: StepType.message,
        text: `Здорово! Спасибо, что [[поделился/поделилась]] своим мнением. Теперь я уверена, что викторина пройдет хорошо! А за помощь я хочу вручить тебе Георгиевскую ленту — символ праздника Великой Победы.`,
        nextStep: 'Q15/0',
      },

      ...splitStep({
        person: Interlocutors.TEACHER,
        delay: 5000,
        id: 'Q15',
        text: `{{NAME}}, ты здорово показываешь себя в «Большой перемене». Впереди тебя ждут очень интересные задания по одному из направлений конкурса.

Наступил момент пригласить реального помощника. Им может стать педагог-наставник. Он сможет, например, подсказать, где искать нужную для выполнения заданий информацию.

В педагоги-наставники подойдет учитель, тренер, инструктор или другой взрослый, обучающий детей.

Он должен работать в образовательной организации Российской Федерации в 2022/2023 учебном году.

И должен быть зарегистрирован на сайте <a target="_blank" href="//bolshayaperemena.online">bolshayaperemena.online</a>
Подробнее, кто может быть наставником, почитай, пожалуйста, в <a target="_blank" href="//bolshayaperemena.online/api/link/short/136b22dd98">Положении о конкурсе</a>.
Выбирай в наставники человека, которого ты хорошо знаешь, и который сможет поддержать тебя в конкурсе. Скорее всего, на эту роль подойдет твой любимый учитель, спортивный тренер или руководитель кружка, в котором ты занимаешься.

Хорошо подумай, кто это может быть. Ведь после выбора наставника, ты не сможешь его поменять. На выбор у тебя есть время, поэтому не торопись.

Если ты точно знаешь, кого хочешь видеть своим наставником, предложи ему это в реальности. Если он согласен, то попроси его зарегистрироваться на сайте и сказать тебе его email регистрации.

Через несколько дней я напомню о выборе наставника, у тебя будет время, чтобы добавить его данные в личном кабинете. 

А пока не торопись. Хорошо подумай и сделай правильный выбор! 😊`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ок, я [[понял/поняла]]',
            nextStep: 'ALT1/0',
          },
        ],
      }),

      ...ALT,

      Q16: {
        person: Interlocutors.TEACHER,
        id: 'Q16',
        type: StepType.message,
        text: `Кстати, по пути сюда мы встретили Сан Саныча. Он придумал кое-что интересное к параду, но вот кажется с его задумкой не все идет гладко. Он выглядел обеспокоенно. Думаю, тебе стоит его навестить.`,
        nextStep: EXIT_ID,
      },

      [EXIT_ID]: {
        person: Interlocutors.TEACHER,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.TEACHER,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
