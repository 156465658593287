/* eslint-disable max-len */
import {
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
} from '../../../../types/scenario';

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

export const moscowCity_1_1: Scenario = {
  scenarioId: SceneId.moscowCity_1_1,
  type: ScenarioType.chat,
  data: {
    title: 'Москва-сити',
    scene_id: 'moscowCity_1_1',
    startId: 'Q1',
    steps: {
      Q1: {
        text: `Сан Саныч, привет!

        А мы по делу к тебе. По важному — от Петрова.`,
        id: 'Q1',
        type: StepType.question,
        person: Interlocutors.CAT,
        nextStep: 'Q2',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b2',
            text: 'Здравствуйте, рад знакомству!',
            nextStep: 'Q2',
          },
        ],
      },
      Q2: {
        text: `Ювтстевирп ,яьзурд!

        Ьщев яаннартс ьсаличюлкирп ,юинелажос к ,имакинтобар имигурд и йонм ос а. `,
        id: 'Q2',
        type: StepType.question,
        person: Interlocutors.ENTREPRENEUR,
        nextStep: 'Q3',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b2',
            text: 'Вжух… Что с Сан Санычем случилось?',
            nextStep: 'Q3',
          },
        ],
      },
      Q3: {
        text: `Я не знаю, но его речь точно не похожа на кошачий.`,
        id: 'Q3',
        type: StepType.question,
        person: Interlocutors.CAT,
        nextStep: 'Q4',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b2',
            text: 'Ладно, давай — говори название деталей Петрова',
            nextStep: 'Q4',
          },
        ],
      },
      Q4: {
        text: `Ээ..

        И.. Ие-ра-т.. а… Б? Б…
        
        Дальше я, кажется, забыл 😿 
        
        Встреча с этим странным существом с футбольного поля выбросила всё у меня из памяти!`,
        id: 'Q4',
        type: StepType.question,
        person: Interlocutors.CAT,
        nextStep: 'Q5',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b2',
            text: 'Вжух, ты же обещал запомнить! Что же мы теперь будем делать?',
            nextStep: 'Q5',
          },
        ],
      },
      Q5: {
        text: `Ьчер тёди хяератаб хикак о — юаминоп месвос ен ,яьзурд`,
        id: 'Q5',
        type: StepType.question,
        person: Interlocutors.ENTREPRENEUR,
        nextStep: 'Q6',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b2',
            text: 'А я, кажется, всё понял. Вжух, посмотри на часы на вон той башне!',
            nextStep: 'Q6',
          },
        ],
      },
      Q6: {
        text: `Идут в обратную сторону…

        Все обитатели Сити теперь живут задом наперед?`,
        id: 'Q6',
        type: StepType.question,
        person: Interlocutors.CAT,
        nextStep: 'Q7',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b2',
            text: 'И говорят тоже! У тебя есть диктофон? Такой, чтобы обратно проигрывал запись.',
            nextStep: 'Q7',
          },
        ],
      },
      Q7: {
        text: `Нет.

        Но я знаю, где есть. Пойдём скорее!`,
        id: 'Q7',
        type: StepType.question,
        person: Interlocutors.CAT,
        nextStep: 'Q8',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b2',
            text: 'Сан Саныч, рад знакомству! До свидания!',
            nextStep: 'Q8',
          },
        ],
      },
      Q8: {
        text: `Атябер ,ичертсв од!`,
        id: 'Q8',
        type: StepType.message,
        person: Interlocutors.ENTREPRENEUR,
        nextStep: EXIT_ID,
      },

      [EXIT_ID]: {
        person: Interlocutors.SYSTEM,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.SYSTEM,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};
