import React, { FC, useCallback } from 'react';

import { IconProps } from './IconTypes';
import { IconStyled } from './IconStyled';

import logo from '../../../../assets/images/logo.svg';
import ico_link from '../../../../assets/images/ico_link.svg';
import ico_chevron from '../../../../assets/images/ico_chevron.svg';
import chevron_small from '../../../../assets/images/chevron_small.svg';
import ico_chevron_back from '../../../../assets/images/ico_chevron_back.svg';

import upload from '../../../../assets/images/upload.svg';
import refresh from '../../../../assets/images/refresh.svg';
import download from '../../../../assets/images/download.svg';

const icoMapper: Record<string, string> = {
  logo,
  upload,
  download,
  refresh,
  ico_link,
  ico_chevron,
  chevron_small,
  ico_chevron_back,
};

export const Icon: FC<IconProps> = (props) => {
  const { icon = 'ico_link', ...baseProps } = props;

  const getIco = useCallback(() => {
    return icon && icoMapper[icon] ? icoMapper[icon] : icoMapper.logo;
  }, [icon]);

  return (
    <IconStyled {...baseProps}>
      <img src={getIco()} alt='' />
    </IconStyled>
  );
};
