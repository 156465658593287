/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_intro_1_1_0: Scenario = {
  scenarioId: SceneId.stg2_intro_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.SYSTEM,
    title: 'Система',
    text: `Вместе со Вжухом вы возвращаетесь в «Большую перемену» и вспоминаете недавние события. Ваш новый таинственный друг исчез и вы даже не успели ничего узнать про него, ведь анализатор, прибор Петрова, сломался.`,
    buttons: ['Продолжить'],
  },
};
