/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const scenario_3_0: Scenario = {
  scenarioId: SceneId.scenario_3_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Иннополис',
    text: `Взяв с собой ничего не понимающую Софью, вы отправляетесь в Иннополис: вам нужно срочно передать накопитель с «Интеллектом 2.0» Гарину.`,
    buttons: ['Отправиться к КВА'],
  },
};

export const scenario_3_0_1: Scenario = {
  scenarioId: SceneId.scenario_3_0_1,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'МГУ',
    text: 'В МГУ все идет свои чередом. Студенты и преподаватели вернулись к занятиям.',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_0_2: Scenario = {
  scenarioId: SceneId.scenario_3_0_2,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Башня Останкино',
    text: 'Местное телевидение работает исправно. Вещание отличное. Репортеры вернулись к работе. Помощь здесь не требуется. ',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_0_3: Scenario = {
  scenarioId: SceneId.scenario_3_0_3,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Космодром',
    text: 'Электронные замки пришли в норму. Рабочие вернулись к своим обязанностям, поэтому новых заданий для вас нет.',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_0_4: Scenario = {
  scenarioId: SceneId.scenario_3_0_4,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Природные достояния России',
    text: 'Пришла настоящая весна! Цветы распускаются, деревья зеленеют, птицы вьют гнезда. Семьи неспешно гуляют по благоустроенным тропинкам. Это все стало возможным благодаря вашей помощи! Спасибо, теперь здесь все впорядке.',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_0_5: Scenario = {
  scenarioId: SceneId.scenario_3_0_5,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Олимпийский парк в Сочи',
    text: 'Спортивная команда «Большой перемены» готовится к соревнованиям на благоустроенном стадионе. Детские спортивные площадки пользуются большой популярностью у обитателей. Все благодарны вам за помощь. Новых заданий нет.',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_0_6: Scenario = {
  scenarioId: SceneId.scenario_3_0_6,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Эрмитаж',
    text: 'Все драгоценные экспонаты вернулись на свои места в целости и сохранности. Эрмитажные коты привычно несут службу по поимке мышей. Залы вновь наполнились посетителями',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_0_7: Scenario = {
  scenarioId: SceneId.scenario_3_0_7,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Мост на остров Русский',
    text: 'Движение на мосту полностью восстановилось. Вашей помощи здесь не требуется.',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_0_8: Scenario = {
  scenarioId: SceneId.scenario_3_0_8,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Мамаев курган',
    text: 'Парад в честь Дня Победы прошел замечательно! Все остались в восторге от памятного клипа Сан Саныча. Обитатели «Большой перемены» были счастливы поучаствовать в таком важном празднике. Они передают благодарности за помощь в подготовке.',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_0_9: Scenario = {
  scenarioId: SceneId.scenario_3_0_9,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Москва-Сити',
    text: 'Центр предпринимательства «Большой перемены» наполнился энергией. Бизнесмены вернулись в свои светлые просторные офисы. Их головы снова начали переполнять классные идеи для развития и улучшения парка.',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_0_10: Scenario = {
  scenarioId: SceneId.scenario_3_0_10,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Поезд в «Артек»',
    text: 'Вы замечаете какие-то новые следы. Они не похожи на те, которые обычно оставляет Вжух...',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_0_11: Scenario = {
  scenarioId: SceneId.scenario_3_0_11,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Мост на остров Русский',
    text: 'Вы завороженно наблюдаете за колыханием крылышек прекрасной синей бабочки. Но, подойдя ближе, обнаруживаете, что это клок синей шерсти, развевающийся на ветру.',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_0_12: Scenario = {
  scenarioId: SceneId.scenario_3_0_12,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Иннополис',
    text: 'Научные сотрудники Иннополиса вернулись в лаборатории. Местная библиотека наполнилась редкими книгами. Вместе с солнышком идеи начинают возвращаться в парк. Будьте спокойны, работа в Иннополисе налаживается.',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_0_13: Scenario = {
  scenarioId: SceneId.scenario_3_0_13,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Херсонес',
    text: 'Вы видите прекрасную купель и от любопытства заглядываете внутрь, но там ничего нет.',
    buttons: ['Закрыть'],
  },
};

export const scenario_3_0_14: Scenario = {
  scenarioId: SceneId.scenario_3_0_14,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Херсонес',
    text: 'Солнце весело бликует на куполе Владимирского собора. Обитатели «Большой перемены» неспешно прогуливаются среди древних руин. Новых загадок пока нет.',
    buttons: ['Закрыть'],
  },
};
