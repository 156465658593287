import React, { FC } from 'react';
import { BoxProps } from './Types';
import { BoxStyled } from './BoxStyled';

export const Box: FC<BoxProps> = (props) => {
  const {
    $align = 'flex-start',
    $justify = 'flex-start',
    $direction = 'row',
    $isWrap = true,
    $isFullWidth = true,
    onClick,
    children,
    ...baseProps
  } = props;

  return (
    <BoxStyled
      $align={$align}
      $justify={$justify}
      $direction={$direction}
      $isWrap={$isWrap}
      $isFullWidth={$isFullWidth}
      onClick={onClick}
      {...baseProps}>
      {children}
    </BoxStyled>
  );
};
