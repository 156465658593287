import * as checks from './stageChecks';
import { ID, to } from './ID';
import { PointsOfInterest } from '../../types';
import { SceneId } from '../../resources/scenario';
import { createNavigateToPointLink, toArray } from './utils';
import { MapPinType, PointsClickHandlers, RunModalScenario, ScenarioHelperResponse } from './types';
import { skipScenario } from '../../redux/scenario/scenarioActions';
import { ScenarioState } from '../../redux/scenario';

export function stage2(
  scenarioState: ScenarioState,
  runScenario: (id: SceneId, onSubmit?: (btn: string) => void) => void,
  runModalScenario: RunModalScenario,
  dispatch: (action: (...args: any[]) => void) => void,
): ScenarioHelperResponse {
  if (checks.notSecondStageIntro(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: {
        id: to[ID.secondStageIntro],
        runOnSubmit: to[ID.catIntroSecondStage],
      },
    };
  }

  if (checks.notSecondStageIntroCompleted(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: {
        id: to[ID.catIntroSecondStage],
        unsaved: true,
      },
    };
  }

  if (checks.notSchemaRequested(scenarioState)) {
    const innopoliceNavigatePoint = createNavigateToPointLink(
      'Иннополис',
      PointsOfInterest.innopolice,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.innopolice]: () =>
          runModalScenario(
            to[ID.innopoliceIntro],
            to[ID.petrovSchemaRequest],
            to[ID.petrovSchemaRequest],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Иди в ${innopoliceNavigatePoint}. Там тебя ждет Петров.`,
    };
  }
  if (checks.notSafeOpened(scenarioState)) {
    const spaceNavigatePoint = createNavigateToPointLink('Космодром', PointsOfInterest.space);

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.space]: () =>
          runModalScenario(
            to[ID.rocketLost],
            to[ID.spaceSafeOpening],
            to[ID.spaceSafeOpening],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `{{NAME}}, иди на ${spaceNavigatePoint} и раздобудь у Сан Саныча микросхему.`,
    };
  }
  if (checks.notSchemaGiven(scenarioState)) {
    const innopoliceNavigatePoint = createNavigateToPointLink(
      'Иннополисе',
      PointsOfInterest.innopolice,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.innopolice]: () => runScenario(to[ID.giveSchema]),
      }),
      helpMessage: `{{NAME}}, нужно отдать микросхему Петрову, он в ${innopoliceNavigatePoint}.`,
    };
  }

  if (checks.notHermitagePictureSaved(scenarioState)) {
    const hermitageNavigatePoint = createNavigateToPointLink(
      'Эрмитаже',
      PointsOfInterest.hermitage,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.hermitage]: () =>
          runModalScenario(
            to[ID.hermitageIntro],
            to[ID.hermitagePictureSave],
            to[ID.hermitageEmpty2_act1],
            scenarioState.scenarios,
          ),
      }),
      helpMessage: `Кажется, в ${hermitageNavigatePoint} сейчас нужна твоя помощь...`,
    };
  }

  // Второй акт
  if (checks.notStage2Act2Announced(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: {
        id: to[ID.stage2Act2Announce],
      },
    };
  }

  if (checks.notStage2Act2InnopoliceVisited(scenarioState)) {
    const innopoliceNavigatePoint = createNavigateToPointLink(
      'Иннополисе',
      PointsOfInterest.innopolice,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.innopolice]: () => runScenario(to[ID.stage2Act2AnnounceInnopolice]),
      }),
      helpMessage: `Скорее найди Петрова в ${innopoliceNavigatePoint}!`,
    };
  }

  if (checks.notStage2Act2AnnounceCatToIsland(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.stage2Act2AnnounceCatToIsland] },
    };
  }

  if (checks.notAltruism2CompletedOrSkipped(scenarioState)) {
    const bridgeNavigatePoint = createNavigateToPointLink(
      'моста на остров Русский',
      PointsOfInterest.bridge,
    );

    return {
      pointsHandlers: toArray({
        [PointsOfInterest.bridge]: () => {
          if (scenarioState.scenarios[to[ID.altruism2Announce]]) {
            runScenario(to[ID.altruism2GrandmotherRoad]);
            return;
          }
          runScenario(to[ID.altruism2Announce], async (button) => {
            if (button === 'Поговорить с Бабулей') {
              runScenario(to[ID.altruism2GrandmotherRoad]);
            } else {
              await dispatch(skipScenario(to[ID.altruism2GrandmotherRoad]));
              runModalScenario(
                to[ID.bridgeBoxHelpAnnounce],
                to[ID.bridgeBoxHelp],
                to[ID.bridgeBoxHelp],
                scenarioState.scenarios,
              );
            }
          });
        },
      }),
      helpMessage: `Кажется, золотой болт видели в районе ${bridgeNavigatePoint}. Можешь начать свои поиски оттуда.`,
    };
  }

  if (checks.notBridgeBoxHelp(scenarioState)) {
    return {
      pointsHandlers: toArray({
        [PointsOfInterest.bridge]: () =>
          runModalScenario(
            to[ID.bridgeBoxHelpAnnounce],
            to[ID.bridgeBoxHelp],
            to[ID.bridgeBoxHelp],
            scenarioState.scenarios,
          ),
      }),
      autorunScenario: {
        id: to[ID.bridgeBoxHelp],
      },
    };
  }

  if (checks.notMoscowCity(scenarioState)) {
    const moscowCityNavigatePoint = createNavigateToPointLink(
      'Москва-Сити',
      PointsOfInterest.moscowCity,
    );

    return {
      helpMessage: `Сан Саныч ждет тебя с новостями вон в тех сверкающих небоскребах ${moscowCityNavigatePoint}.`,
      pointsHandlers: toArray({
        [PointsOfInterest.moscowCity]: () =>
          runModalScenario(
            to[ID.moscowCityAnnounce],
            to[ID.moscowCityTest],
            to[ID.moscowCityTest],
            scenarioState.scenarios,
          ),
      }),
    };
  }

  if (checks.notMoscowCityTestResult(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.moscowCityTestResult] },
    };
  }

  if (checks.notTwoDetailsReceivedShown(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.twoDetailsReceived] },
    };
  }

  const notTrainDetail = checks.notTrainEggDetailReceived(scenarioState);
  const notInnopoliceDetail = checks.notInnopoliceEggDetailReceived(scenarioState);

  if (notTrainDetail || notInnopoliceDetail) {
    const points: PointsClickHandlers = [
      {
        point: PointsOfInterest.innopolice,
        handler: () => runScenario(to[ID.innopoliceNeedMoreDetails]),
      },
    ];

    const innopoliceBushNavigatePoint = createNavigateToPointLink(
      'кустах рядом с Иннополисом',
      PointsOfInterest.innopoliceBush,
    );

    // eslint-disable-next-line max-len
    let helpMessage = `Я тут вчера ночью, ну, как обычно, прогуливался по парку… Бродил то тут, то там… то там, то тут. И заметил странный изогнутый предмет в ${innopoliceBushNavigatePoint}. 
    
    Я уж ночью не полез проверять, что там такое прячется! Но, возможно, эта изогнутая штуковина — то, что ты ищешь?..`;

    if (notTrainDetail) {
      const trainNavigatePoint = createNavigateToPointLink(
        'в районе поезда...',
        PointsOfInterest.train,
      );

      // eslint-disable-next-line max-len
      helpMessage = `Оставшиеся детали ты найдешь в самых неожиданных местах. Внимательнее исследуй карту. Кажется, я видел что-то похожее на деталь от пушки ${trainNavigatePoint}`;

      points.push({
        type: MapPinType.steps,
        point: PointsOfInterest.train,
        handler: () =>
          // Вы осматривайте поезд
          runModalScenario(
            to[ID.trainEggAnnounce],
            // scenario_egg1_0
            to[ID.trainEgg],
            // scenario_egg1
            to[ID.trainEgg],
            scenarioState.scenarios,
          ),
      });
    }
    if (notInnopoliceDetail) {
      points.push({
        type: MapPinType.steps,
        point: PointsOfInterest.innopoliceBush,
        handler: () => runScenario(to[ID.innopoliceDetailEgg]),
        // scenario_egg2_0
      });
    }
    return {
      pointsHandlers: [...points],
      helpMessage,
    };
  }

  if (checks.notAllDetailsReceivedShown(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.allDetailsReceived] },
    };
  }

  if (checks.notDetailsGiven(scenarioState)) {
    const innopoliceNavigatePoint = createNavigateToPointLink(
      'Иннополис',
      PointsOfInterest.innopolice,
    );

    return {
      helpMessage: `Поспеши в ${innopoliceNavigatePoint} и отдай детали Петрову, чтобы он закончил тепловую пушку.`,
      pointsHandlers: toArray({
        [PointsOfInterest.innopolice]: () => runScenario(to[ID.innopoliceGiveDetails]),
        // Тепловая пушка почти готова
      }),
    };
  }

  const spaceNavigatePoint = createNavigateToPointLink('Космодром', PointsOfInterest.space);
  const spaceHelpMessage = `Отправляйся с Петровым на ${spaceNavigatePoint}, чтобы запустить ракету.`;

  if (checks.notGrandmotherFriendHelp(scenarioState)) {
    return {
      pointsHandlers: [
        ...toArray({
          [PointsOfInterest.space]: () =>
            runModalScenario(
              to[ID.grandmotherFriendAltruismAnnounce],
              to[ID.grandmotherFriendAltruism],
              to[ID.grandmotherFriendAltruism],
              scenarioState.scenarios,
            ),
        }),
      ],
      helpMessage: spaceHelpMessage,
    };
  }

  // spaceHelpMessage дублируется это нормально, согласно сценарием

  if (checks.notRocketStarted(scenarioState)) {
    return {
      helpMessage: spaceHelpMessage,
      pointsHandlers: [
        ...toArray({
          [PointsOfInterest.space]: () =>
            runModalScenario(
              to[ID.newRocketAnnounce],
              to[ID.newRocketStart],
              to[ID.newRocketStart],
              scenarioState.scenarios,
            ),
        }),
      ],
    };
  }

  // /////////////////////////////////////////////////////////

  // акт 3
  const eggs: PointsClickHandlers = [];
  if (checks.notHermitageEgg(scenarioState)) {
    eggs.push({
      type: MapPinType.steps,
      point: PointsOfInterest.hermitageBush,
      handler: () => runScenario(to[ID.hermitageEgg]),
    });
  }
  if (checks.notMguEgg_1(scenarioState)) {
    eggs.push({
      type: MapPinType.steps,
      point: PointsOfInterest.mguBush,
      handler: () => runScenario(to[ID.mguEgg], () => runScenario(to[ID.mguEgg_1])),
    });
  }
  if (checks.notLakeEgg(scenarioState)) {
    eggs.push({
      type: MapPinType.steps,
      point: PointsOfInterest.lakeBush,
      handler: () => runScenario(to[ID.lakeEgg]),
    });
  }
  if (checks.notOstamnkinoEgg_1(scenarioState)) {
    eggs.push({
      type: MapPinType.steps,
      point: PointsOfInterest.ostankinoBush,
      handler: () => runScenario(to[ID.ostamnkinoEgg], () => runScenario(to[ID.ostamnkinoEgg_1])),
    });
  }

  const act3Points = [...eggs];
  if (checks.notStage2act3Started(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: {
        id: to[ID.stage2act3Announce],
        runOnSubmit: to[ID.catParadeInfo],
      },
    };
  }
  if (checks.notCatParadeInfo(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { unsaved: true, id: to[ID.catParadeInfo] },
    };
  }

  if (checks.notKurganQuest(scenarioState)) {
    const kurganNavigatePoint = createNavigateToPointLink('Мамаев курган', PointsOfInterest.kurgan);

    return {
      helpMessage: `Приходи к достопримечательности ${kurganNavigatePoint} парка «Большая перемена». Я уже там.`,
      pointsHandlers: [
        ...act3Points,
        ...toArray({
          [PointsOfInterest.kurgan]: () =>
            runModalScenario(
              to[ID.kurganInfo],
              to[ID.kurganQuest],
              to[ID.kurganQuest],
              scenarioState.scenarios,
            ),
        }),
      ],
    };
  }
  if (checks.notMoscowCityCinemaHelp(scenarioState)) {
    const moscowCityNavigatePoint = createNavigateToPointLink(
      'Москва-Сити',
      PointsOfInterest.moscowCity,
    );

    return {
      helpMessage: `Кажется, ты [[нужен/нужна]] Сан Санычу в ${moscowCityNavigatePoint} парка «Большая перемена»…`,
      pointsHandlers: [
        ...act3Points,
        ...toArray({
          [PointsOfInterest.moscowCity]: () =>
            runModalScenario(
              to[ID.moscowCityCinemaHelpAnnounce],
              to[ID.moscowCityCinemaHelp],
              to[ID.moscowCityCinemaHelp],
              scenarioState.scenarios,
            ),
        }),
      ],
    };
  }
  if (checks.notHermitageEgg(scenarioState)) {
    return {
      helpMessage: `Кажется, ты что-то [[пропустил/пропустила]] на карте. Поищи-ка хорошенько по кустам...`,
      pointsHandlers: [
        ...act3Points,
        {
          point: PointsOfInterest.innopolice,
          handler: () => runScenario(to[ID.innopoliceNeedCoins]),
        },
      ],
    };
  }
  if (checks.notInnopoliceAct3Finish(scenarioState)) {
    const innopoliceNavigatePoint = createNavigateToPointLink(
      'Иннополис',
      PointsOfInterest.innopolice,
    );

    return {
      // eslint-disable-next-line max-len
      helpMessage: `В лабораторию Иннополиса привезли новую машину для преобразования предметов. Петрову не терпится показать тебе этот удивительный аппарат!

      Иди в ${innopoliceNavigatePoint} на карте «Большой перемены».`,
      pointsHandlers: [
        ...act3Points,
        {
          point: PointsOfInterest.innopolice,
          handler: () => runScenario(to[ID.innopoliceAct3Finish]),
        },
      ],
    };
  }

  return {
    pointsHandlers: eggs,
  };
}
