import { useMount } from './useMount';

const useMountOverflowHidden = () => {
  useMount(
    () => {
      document.body.style.overflow = 'hidden';
    },
    () => (document.body.style.overflow = ''),
  );
};

export default useMountOverflowHidden;
