/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId, StepType } from '../../../scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

export const scenario_3_22: Scenario = {
  scenarioId: SceneId.scenario_3_22,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Парк «Большая перемена»',
    text: 'Вы выходите из Иннополиса, чтобы провести для Софии экскурсию по парку «Большая перемена», как вдруг встречаете встревоженного Сан Саныча. Он начинает разговор...',
    buttons: ['Продолжить'],
  },
};

const EXIT_ID = 'EXIT_ID';

export const scenario_3_22_1: Scenario = {
  scenarioId: SceneId.scenario_3_22_1,
  isRequired: true,
  type: ScenarioType.chat,
  data: {
    title: 'Парк «Большая перемена»',
    scene_id: 'scenario_3_22_1',
    startId: 'Q1',
    steps: {
      Q1: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q1',
        type: StepType.message,
        text: `Приветствую! Рад вас всех видеть! А это, должно быть, та самая хозяйка Малевича — София? 😊`,
        nextStep: 'Q2',
      },
      Q2: {
        person: Interlocutors.SOFIA,
        id: 'Q2',
        type: StepType.message,
        text: `Да, здравствуйте. Приятно познакомиться! Очень Вам благодарна, что помогли моему Малевичу 😊`,
        nextStep: 'Q3',
      },
      Q3: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'Q3',
        type: StepType.message,
        text: `Да что Вы, не стоит благодарности! 😊`,
        nextStep: 'Q4',
      },
      Q4: {
        person: Interlocutors.BLUE_CAT,
        id: 'Q4',
        type: StepType.message,
        text: 'Сан Саныч, Вы выглядите встревоженным… 😿',
        nextStep: 'ALT1',
      },
      ALT1: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'ALT1',
        text: `Я очень спешу на поезд, еду в срочную командировку. «Большая перемена» заработала в полном объеме, а это значит, что совсем скоро ребята отправятся на поезде в «Артек». Поэтому мне надо поехать туда и все подготовить. Да вот только переживаю за одно дело… 😔`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Да я тоже [[заметил/заметила]] Вашу обеспокоенность, Сан Саныч. У Вас все в порядке?',
            nextStep: 'ALT2/0',
          },
        ],
      },
      ...splitStep({
        person: Interlocutors.ENTREPRENEUR,
        id: 'ALT2',
        text: `Понимаешь, {{NAME}}, я уезжаю в командировку почти на сутки, поезд отправляется уже через полчаса. А я утром нашел совсем малюток-утят в Природных достояниях России на горе. Собирался попозже их выпустить к другим уткам, которые живут на озере, но из-за командировки я этого сделать не успею 🙁

Утята совсем еще крошки и сами еду добыть не могут. Оставил им немного, но она уже на исходе. Я их пока спрятал в углублении под кустом там же на горе, чтобы их случайно никто не нашел. В общем, нужно дойти до утят и покормить их 😔`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Если у них есть доступ к воде, то денек они точно продержатся. Не переживайте!  (Игровое время не будет заморожено)',
            nextStep: 'ALT3',
          },
          {
            id: 'b2',
            text: 'Давайте я позвоню стажеру Петрова и попрошу его покормить утят. Вы только расскажите мне, где их искать, а я передам стажеру. (Игровое время будет заморожено на 10 минут)',
            nextStep: 'ALT4',
          },
          {
            id: 'b3',
            text: 'Не волнуйтесь! Я с радостью покормлю утят. Рассказывайте, как их найти? (Игровое время будет заморожено на 1 час)',
            nextStep: 'ALT5',
          },
        ],
      }),
      ALT3: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'ALT3',
        text: `Спасибо, [[успокоил/успокоила]]! Воды я им оставил достаточно 😊 Мне пора бежать, а то опоздаю на поезд.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Счастливого пути!',
            nextStep: EXIT_ID,
          },
        ],
      },
      ALT4: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'ALT4',
        text: `Как ты хорошо [[придумал/придумала]]! Сам бы я не догадался. Теперь я могу спокойно ехать в командировку 😊 Мне пора бежать, а то опоздаю на поезд.`,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: '[[Рад/Рада]] помочь!',
            nextStep: EXIT_ID,
          },
        ],
      },
      ALT5: {
        person: Interlocutors.SYSTEM,
        id: 'ALT5',
        type: StepType.message,
        text: `Вы слышите телефонный звонок и берете трубку. Раздается благодарный голос Сан Саныча…`,
        nextStep: 'ALT6',
      },
      ALT6: {
        person: Interlocutors.ENTREPRENEUR,
        id: 'ALT6',
        text: 'Спасибо огромное за твою помощь, {{NAME}}! Уверен, что тебе понравилось возиться с милыми утятами 😊',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Мне было совсем не сложно помочь!',
            nextStep: EXIT_ID,
          },
        ],
      },

      [EXIT_ID]: {
        person: Interlocutors.TEACHER,
        id: EXIT_ID,
        type: StepType.exit,
      },
    },
  },
};
