import styled from 'styled-components';
import { colors } from '../../../common/shared/constants/colors';
import { Sidebar } from '../../widgets/Sidebar/Sidebar';
import { BREAKPOINT_SMALL_PHONE } from '../../../common/shared/constants/constants';

export const StyledMobileMenu = styled.div<{ $isActive: boolean }>`
  top: 85px;
  height: calc(100% - 85px);

  left: 0;
  position: fixed;
  z-index: 9999999;

  width: 100%;
  padding: 20px 14px 30px;

  flex-direction: column;
  display: ${(p) => (p.$isActive ? 'flex' : 'none')};
  background: ${colors.white};

  overflow: scroll;

  button {
    height: 40px;
    margin-top: 16px;
    padding-left: 24px;

    @media screen and (max-width: ${BREAKPOINT_SMALL_PHONE}) {
      padding-left: 12px;
    }
  }
`;

export const StyledSidebar = styled(Sidebar)`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
`;
