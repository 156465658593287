/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_mguQuiz_1_1_0: Scenario = {
  scenarioId: SceneId.stg2_mguQuiz_1_1_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.CAT,
    title: 'МГУ',
    text: `Мы, кажется, готовы к викторине.

    А значит — в МГУ!
    
    Все остальные дела — потом. 😁`,
    buttons: ['Продолжить'],
  },
};
