/* eslint-disable max-len */
import { Step, SceneId, Scenario, StepType, ScenarioType, Interlocutors } from '../../../scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';
import { TVOI_KRUGOZOR_G2 } from '../../tests/tvoi_krugozor_g2';

export const scenario_2_2: Scenario = {
  scenarioId: SceneId.scenario_2_2,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Космодром',
    text: `Вы находитесь у покрытого льдом Космодрома и замечаете, что ракета пропала. Вас приветствует обеспокоенный Сан Саныч…`,
    buttons: ['Продолжить'],
  },
};

const EXIT_ID = 'EXIT_ID';
const TO_MAP_ID = 'TO_MAP_ID';

const person = Interlocutors.ENTREPRENEUR;

const TEST_LOGIC: { [id: string]: Step } = {
  TEST_LOGIC_1: {
    person,
    id: 'TEST_LOGIC_1',
    text: 'Ну, начнем. Первый вопрос. У Олиной мамы пять дочек: Вика, Аня, Марта, Кира. Как зовут пятую?',
    type: StepType.question,
    callToAction: 'Выбери вариант',
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Соня',
        nextStep: 'TEST_LOGIC_1_2',
      },
      {
        id: 'b2',
        text: 'Таня',
        nextStep: 'TEST_LOGIC_1_3',
      },
      {
        id: 'b3',
        text: 'Оля',
        nextStep: 'TEST_LOGIC_1_4',
      },
    ],
  },

  TEST_LOGIC_1_2: {
    person,
    id: 'TEST_LOGIC_1_2',
    text: 'Прочти вопрос еще раз и будь внимательнее. Это не Соня.',
    type: StepType.question,
    callToAction: 'Выбери вариант',
    maxAnswers: 1,
    buttons: [
      {
        id: 'b2',
        text: 'Таня',
        nextStep: 'TEST_LOGIC_1_3',
      },
      {
        id: 'b3',
        text: 'Оля',
        nextStep: 'TEST_LOGIC_1_4',
      },
    ],
  },
  TEST_LOGIC_1_3: {
    person,
    id: 'TEST_LOGIC_1_3',
    text: 'Нет, это неверный ответ. Секрет кроется в первых трех словах вопроса.',
    type: StepType.question,
    callToAction: 'Выбери вариант',
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Соня',
        nextStep: 'TEST_LOGIC_1_2',
      },
      {
        id: 'b3',
        text: 'Оля',
        nextStep: 'TEST_LOGIC_1_4',
      },
    ],
  },
  TEST_LOGIC_1_4: {
    person,
    id: 'TEST_LOGIC_1_4',
    text: 'Ну конечно же Оля! Ты очень [[догадливый/догадливая]]!',
    type: StepType.message,
    nextStep: 'TEST_LOGIC_2',
  },

  TEST_LOGIC_2: {
    person,
    callToAction: 'Выбери вариант',
    id: 'TEST_LOGIC_2',
    text: 'Второй вопрос. В отеле 4 этажа. Чем выше этаж, тем больше людей там живет. На какой этаж чаще всего ездит лифт?',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Первый',
        nextStep: 'TEST_LOGIC_2_4',
      },
      {
        id: 'b2',
        text: 'Второй',
        nextStep: 'TEST_LOGIC_2_2',
      },
      {
        id: 'b3',
        text: 'Четвертый',
        nextStep: 'TEST_LOGIC_2_3',
      },
    ],
  },
  TEST_LOGIC_2_2: {
    person,
    callToAction: 'Выбери вариант',
    id: 'TEST_LOGIC_2_2',
    text: 'Ты [[был/была]] [[близок/близка]], но это неверный ответ.',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Первый',
        nextStep: 'TEST_LOGIC_2_4',
      },
      {
        id: 'b3',
        text: 'Четвертый',
        nextStep: 'TEST_LOGIC_2_3',
      },
    ],
  },
  TEST_LOGIC_2_3: {
    person,
    callToAction: 'Выбери вариант',
    id: 'TEST_LOGIC_2_3',
    text: 'Увы, но нет.',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Первый',
        nextStep: 'TEST_LOGIC_2_4',
      },
      {
        id: 'b2',
        text: 'Второй',
        nextStep: 'TEST_LOGIC_2_2',
      },
    ],
  },
  TEST_LOGIC_2_4: {
    person,
    id: 'TEST_LOGIC_2_4',
    text: 'Тебя не проведешь! Да, это правильный ответ!',
    type: StepType.message,
    nextStep: 'TEST_LOGIC_3',
  },

  TEST_LOGIC_3: {
    person,
    id: 'TEST_LOGIC_3',
    callToAction: 'Выбери вариант',
    text: 'Следующий вопрос. Укажите недостающее число: 2, 32, 332, ?, 33332.',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: '3322',
        nextStep: 'TEST_LOGIC_3_2',
      },
      {
        id: 'b2',
        text: '3232',
        nextStep: 'TEST_LOGIC_3_3',
      },
      {
        id: 'b3',
        text: '3332',
        nextStep: 'TEST_LOGIC_3_4',
      },
    ],
  },
  TEST_LOGIC_3_2: {
    person,
    id: 'TEST_LOGIC_3_2',
    callToAction: 'Выбери вариант',
    text: 'Это неверный ответ. Все намного проще, чем кажется.',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b2',
        text: '3232',
        nextStep: 'TEST_LOGIC_3_3',
      },
      {
        id: 'b3',
        text: '3332',
        nextStep: 'TEST_LOGIC_3_4',
      },
    ],
  },
  TEST_LOGIC_3_3: {
    person,
    id: 'TEST_LOGIC_3_3',
    callToAction: 'Выбери вариант',
    text: 'Нет, присмотрись к ряду чисел повнимательнее.',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: '3322',
        nextStep: 'TEST_LOGIC_3_2',
      },
      {
        id: 'b3',
        text: '3332',
        nextStep: 'TEST_LOGIC_3_4',
      },
    ],
  },
  TEST_LOGIC_3_4: {
    person,
    id: 'TEST_LOGIC_3_4',
    callToAction: 'Выбери вариант',
    text: 'Это правильный ответ! Ты почти у цели! ',
    type: StepType.message,
    nextStep: 'TEST_LOGIC_4',
  },

  TEST_LOGIC_4: {
    person,
    id: 'TEST_LOGIC_4',
    callToAction: 'Выбери вариант',
    text: 'И последний вопрос. На продажу выставили десять автомобилей. Все, кроме девяти, купили. Сколько автомобилей осталось?',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Один',
        nextStep: 'TEST_LOGIC_4_2',
      },
      {
        id: 'b2',
        text: 'Девять',
        nextStep: 'TEST_LOGIC_4_4',
      },
      {
        id: 'b3',
        text: 'Десять',
        nextStep: 'TEST_LOGIC_4_3',
      },
    ],
  },
  TEST_LOGIC_4_2: {
    person,
    id: 'TEST_LOGIC_4_2',
    callToAction: 'Выбери вариант',
    text: 'Хм, подумай еще немного.',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b2',
        text: 'Девять',
        nextStep: 'TEST_LOGIC_4_4',
      },
      {
        id: 'b3',
        text: 'Десять',
        nextStep: 'TEST_LOGIC_4_3',
      },
    ],
  },
  TEST_LOGIC_4_3: {
    person,
    id: 'TEST_LOGIC_4_3',
    callToAction: 'Выбери вариант',
    text: 'Не все так просто, это неверный ответ.',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Один',
        nextStep: 'TEST_LOGIC_4_2',
      },
      {
        id: 'b2',
        text: 'Девять',
        nextStep: 'TEST_LOGIC_4_4',
      },
    ],
  },
  TEST_LOGIC_4_4: {
    person,
    id: 'TEST_LOGIC_4_4',
    callToAction: 'Выбери вариант',
    text: 'Абсолютно верно! Сейф открыт! Ура!',
    type: StepType.message,
    nextStep: 'Q4/0',
  },
};
export const scenario_2_2_1: Scenario = {
  scenarioId: SceneId.scenario_2_2_1,
  type: ScenarioType.chat,
  isRequired: true,
  data: {
    title: 'Космодром',
    timeoutMessage: 'К сожалению, время для ответа истекло ️',
    scene_id: 'scenario_2_2_1',
    startId: 'Q1/0',
    steps: {
      ...splitStep({
        person,
        id: 'Q1',
        type: StepType.message,
        text: `Привет! Как хорошо, что ты снова здесь! Пока тебя не было, СТОЛЬКО ВСЕГО произошло! «Большая перемена» на глазах превращается в ледяное царство! Ох! Что же это здесь такое творится?! 😱

А еще, ты представляешь, пока лежал туман, кто-то украл с космодрома целую РАКЕТУ! Как такое вообще возможно? Ума не приложу! 😳`,
        nextStep: 'Q2/0',
      }),
      ...splitStep({
        person,
        id: 'Q2',
        type: StepType.message,
        text: `Ах да, я слышал от Петрова, что вам нужна особенная микросхема для создания тепловой пушки. Чтобы вытащить наш парк из этой снежной... кхм... «сказки». 

Какое совпадение! Я как раз на днях купил такую на деньги, выигранные в конкурсе!

Пойдем, я спрятал ее в своем самом надежном сейфе, ведь это очень дорогая штуковина! А у нас тут, сам видишь, стало не очень безопасно оставлять что-то без присмотра… 🤔`,
        nextStep: 'Q3_0',
      }),
      Q3_0: {
        id: 'Q3_0',
        text: 'Вы подходите к сейфу и видите, что это необычный сейф с цифровым кодом…',
        type: StepType.question,
        person: Interlocutors.SYSTEM,
        nextStep: 'Q3/0',
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Продолжить!',
            nextStep: 'Q3_1',
          },
        ],
      },
      Q3_1: {
        person,
        id: 'Q3_1',
        text: `Вот он! Мой самый надежный сейф! Я привез его из путешествия по Японии. Он создан по древней японской технологии, и открыть его можно только с помощью твоей эрудиции.

        Задания каждый раз разные и никогда не повторяются! Раньше такими сейфами пользовались императоры для хранения государственных реликвий, надежнее места в мире нет.

        Ты очень [[умный/умная]], поэтому мы точно справимся и откроем сейф!`,
        type: StepType.message,
        callToAction: 'Да ведь?',
        nextStep: 'Q3_2',
      },
      Q3_2: {
        id: 'Q3_2',
        text: 'Предлагаем тебе пройти тест на эрудицию в гуманитарной сфере. Он оценит уровень твоей любознательности и широту кругозора и займет не более 10 минут. Тебе будет предъявлено 10 заданий, в каждом из которых по 4 варианта ответа, и только один из них верный. Время на каждый вопрос ограничено (60 секунд). Обращай внимание на счетчики времени на экране! Не рекомендуем долго думать над вариантами, лучше ориентироваться на свои знания и предположения и давать оперативные ответы. И старайся не пользоваться никакими справочниками и дополнительными ресурсами, ведь тест оценивает именно твой уровень эрудиции. Также имей в виду, что ты можешь вернуться назад только на один вопрос, чтобы исправить свой ответ. Напоминаем тебе, что этот тест является тренировочным и не влияет на баллы в Конкурсе. А теперь вперед, и желаем успехов!',
        type: StepType.question,
        person: Interlocutors.SYSTEM,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Конечно!',
            nextStep: 'TVOI_KRUGOZOR_G2_STEP',
          },
        ],
      },

      // Вторая часть теста Твой кругозор 2\2

      ...TVOI_KRUGOZOR_G2,

      Q4: {
        person: Interlocutors.PETROV,
        id: 'Q4',
        type: StepType.message,
        text: `Да… Пришлось повозиться! Хороши все-таки древние японские технологии, ничем не уступают современным!
        
        А теперь держи микросхему и передай Петрову, чтобы поторопился со своей тепловой пушкой.`,
        nextStep: EXIT_ID,
      },

      [EXIT_ID]: {
        person: Interlocutors.CAT,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_ID]: {
        person: Interlocutors.CAT,
        id: TO_MAP_ID,
        type: StepType.to_map_step,
      },
    },
  },
};

export const scenario_2_2_4: Scenario = {
  scenarioId: SceneId.scenario_2_2_4,
  type: ScenarioType.modal,
  isRequired: true,
  data: {
    closable: false,
    person: Interlocutors.PETROV,
    title: 'Иннополис',
    text: `Ух ты! Микросхема у нас! Теперь я точно смогу сделать идеальную тепловую пушку и вернуть весну в «Большую перемену» 🥳  

За твою помощь я хочу подарить тебе 3D РУЧКУ последнего поколения! Береги ее, ведь она может создавать любые предметы!`,
    buttons: ['Закрыть'],
  },
};
