/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';

export const stg2_lakeOrderFromGranny_1_1_1: Scenario = {
  scenarioId: SceneId.stg2_lakeOrderFromGranny_1_1_1,
  type: ScenarioType.modal,
  data: {
    closable: true,
    person: Interlocutors.CAT,
    title: 'Байкал',
    text: `Посовещавшись, вы с Вжухом решили сначала рассказать Бабуле про воду и заодно передать предложение Сан Саныча сделать аквариумы для рыбы.`,
    buttons: ['Начать'],
  },
};
