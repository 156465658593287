/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../../../types/scenario';
import interlocutors from '../../../interlocutors';

export const lake_1_3: Scenario = {
  scenarioId: SceneId.lake_1_3,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `Ну, чего ты ждешь? Скорее делай персонажа! 😺`,
    buttons: ['Создать персонажа'],
  },
};
