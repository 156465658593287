import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Scenario, SceneId, Step } from '../../resources/scenario';

export interface ScenarioState {
  activeChatScenario?: Scenario<'ChatScenario'>;
  activeSceneHistory: Step[];
  timer: number;
  globalTimer: number;
  sceneVariables: Record<string, string>;
  scenarios: Partial<Record<SceneId, Step[]>>;
  startedScenarios: Partial<Record<SceneId, Step[]>>;
  hasUnsaved: boolean;
}

const initialState: ScenarioState = {
  activeChatScenario: undefined,
  activeSceneHistory: [],
  sceneVariables: {},
  timer: 0,
  globalTimer: 0,
  scenarios: {},
  startedScenarios: {},
  hasUnsaved: false,
};

const scenarioSlice = createSlice({
  name: 'scenario',
  initialState,
  reducers: {
    resetChatScenario: (state) => {
      state.timer = 0;
      state.activeSceneHistory = [];
      state.activeChatScenario = undefined;
    },
    setChatScenario: (state, action: PayloadAction<Scenario<'ChatScenario'>>) => {
      state.activeChatScenario = action.payload;
      state.activeSceneHistory = [action.payload.data.steps[action.payload.data.startId]];
    },
    setActiveSceneHistory: (state, action: PayloadAction<Step[]>) => {
      state.activeSceneHistory = action.payload;
    },
    setSceneVariables: (state, action: PayloadAction<Record<string, string>>) => {
      state.sceneVariables = action.payload;
    },
    setScenarioTimer: (state, action: PayloadAction<number>) => {
      state.timer = action.payload;
    },
    setGlobalScenarioTimer: (state, action: PayloadAction<number>) => {
      state.globalTimer = action.payload;
    },
    setAllScenarios: (
      state,
      action: PayloadAction<{
        scenarios: Partial<Record<SceneId, Step[]>>;
        startedScenarios: Partial<Record<SceneId, Step[]>>;
      }>,
    ) => {
      state.scenarios = action.payload.scenarios;
      state.startedScenarios = action.payload.startedScenarios;
    },
    setHasUnsaved: (state, action) => {
      state.hasUnsaved = action.payload;
    },
  },
});

export default scenarioSlice;
