/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../scenario';
import interlocutors from '../../interlocutors';

export const scenario_1_4: Scenario = {
  scenarioId: SceneId.scenario_1_4,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: 'Ну что, {{NAME}}, идем навстречу новым приключениям? Тебе доступны новые задания 😺',
    buttons: ['Отлично!'],
  },
};
