import React from 'react';
import { Item, ItemsBarProps } from './ItemsBarTypes';
import { StyledItemsBar, StyledItemsBarList } from './ItemsBarStyled';

import { Text } from '../../../common/shared/ui/Text';
import colors from '../../../common/shared/constants/colors';
import ItemsBarItem from '../ItemsBarItem';

const flexSkeleton: Item[] = new Array(14).fill({ icon: '' });

const ItemsBar = (props: ItemsBarProps) => {
  const content = React.useMemo(() => {
    return [...props.items, ...flexSkeleton]
      .filter((a) => !a.isHidden)
      .map((a, i) => (
        <ItemsBarItem
          data={a}
          isBackground={props.isBackground}
          active={props.active}
          onSelect={props.onSelect}
          key={`${props.title}-${i}`}
        />
      ));
  }, [props]);

  return (
    <StyledItemsBar>
      <Text
        $textStyle={props.isBlockTitle ? 'p18' : 'p16'}
        $fontWeight={props.isBlockTitle ? '800' : '400'}
        $color={props.isBlockTitle ? colors.title : colors.base50}>
        {props.title}
      </Text>
      <StyledItemsBarList>{content}</StyledItemsBarList>
    </StyledItemsBar>
  );
};

export default ItemsBar;
