/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../types/scenario';

export const scenario_0: Scenario = {
  scenarioId: SceneId.scenario_0,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Начало игры',
    text: `Ты попадаешь в Парк достопримечательностей и открытий «Большая перемена», только вместо шума и ярких огней парк пуст. Все замерло и будто ожидает чего-то…

    Вдалеке появляется пушистое рыжее облако, оно приближается, и ты видишь симпатичного рыжего кота.
    
    `,
    buttons: ['Поговорить'],
  },
};
