/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import { addZero } from '../../common/shared/utils/addZero';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { syncUnsavedScripts } from '../redux/user/userActions';
import { ErrorPageProps } from './ErrorPage/ErrorPageTypes';
import { useHistory } from 'react-router';
import { routes } from '../../common/shared/constants/routes';
import { Page } from '../../common/pages/Page';
import { Box } from '../../common/shared/ui/Box';
import {
  ErrorBlockStyled,
  ErrorBottomRelativeStyled,
  ErrorBottomStyled,
  ErrorCloud1,
  ErrorCloud2,
  ErrorCloud3,
  ErrorLogoStyled,
  ErrorTextStyled,
  ErrorTowerStyled,
} from './ErrorPage/ErrorPageStyled';
import { Text } from '../../common/shared/ui/Text';
import { TemplatedText } from '../components/TemplatedText';
import { Button } from '../../common/shared/ui/Button';
import { Icon } from '../../common/shared/ui/Icon';

const SpriteCloudL = '/onboarding_sprite_cloud1.svg';
const SpriteCloudR = '/onboarding_sprite_cloud2.svg';
const PicStep3 = '/onboarding_tower.svg';

const ErrorPage = (props: ErrorPageProps & { buttonEnabled: boolean }) => {
  const history = useHistory();

  const description = `Данные о прохождении последнего испытания не сохранились. Пожалуйста, попробуйте отправить их самостоятельно, нажав на кнопку «Отправить данные».

Обратите внимание, такая опция доступна с одного устройства — этого, так как информация о прохождении хранится только на нем.`;
  const { isErrorBoundary, onClick = () => history.push(routes.dashboard) } = props;

  return (
    <Page>
      <Box
        $maxW={isErrorBoundary ? '500px' : '460px'}
        $justify='center'
        $textAlign='center'
        $isCenter>
        <ErrorBlockStyled>
          <ErrorLogoStyled />
          <h1>
            <ErrorTextStyled $textStyle='h2' />
          </h1>
          <Text displayAs='div' $textStyle='p18' $mB='30px' $pL='15px' $pR='15px'>
            <TemplatedText>{description}</TemplatedText>
          </Text>
          <Button
            $style={props.buttonEnabled ? 'primary' : 'grey'}
            disabled={!props.buttonEnabled}
            $noborder
            $textStyle='p18'
            $fontWeight='500'
            onClick={onClick}>
            <Box $justify='space-between' $align='center' $textStyle={'p18'}>
              {props.btnText}
            </Box>
            <Icon icon='ico_chevron' />
          </Button>
        </ErrorBlockStyled>

        <ErrorBottomStyled>
          <ErrorBottomRelativeStyled>
            <ErrorCloud1 src={SpriteCloudL} />
            <ErrorCloud2 src={SpriteCloudL} />
            <ErrorCloud3 src={SpriteCloudR} />
            <ErrorTowerStyled src={PicStep3} />
          </ErrorBottomRelativeStyled>
        </ErrorBottomStyled>
      </Box>
    </Page>
  );
};

export function HasUnsavedPage() {
  const [time, setTime] = useState(59);
  useEffect(() => {
    if (time > 0) {
      setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    }
  }, [time]);
  const dispatch = useAppDispatch();
  const onButtonPress = useCallback(() => {
    setTime(59);
    dispatch(syncUnsavedScripts());
  }, [dispatch]);
  return (
    <ErrorPage
      btnText={time > 0 ? `Отправить через 0:${addZero(time)}` : 'Отправить данные'}
      onClick={onButtonPress}
      buttonEnabled={time === 0}
    />
  );
}
