import { SceneId } from '../../resources/scenario';
import { MapPinType, RunModalScenario, ScenarioHelperResponse } from './types';
import { PointsOfInterest } from '../../types';
import { ID, to } from './ID';
import * as checks from './stageChecks';
import { ScenarioState } from '../../redux/scenario';
import { createNavigateToPointLink } from './utils';
import { UserAppState } from '../../redux/user';
import { ChallengeKey } from '../../components/Challenges/config';
import store from '../../redux';
import { checkTestsAsync } from '../../redux/tests/testsActions';

export function stage3(
  scenarioState: ScenarioState,
  runScenario: (id: SceneId, onSubmit?: (btn: string) => void) => void,
  runModalScenario: RunModalScenario,
  dispatch: (action: (...args: any[]) => void) => void,
  userState: UserAppState,
): ScenarioHelperResponse {
  if (checks.notThirdStageStarted(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.stage3Start], runOnSubmit: to[ID.stage3StartInnopolice] },
    };
  }
  if (checks.notStage3InnopoliceFinished(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.stage3StartInnopolice], unsaved: true },
    };
  }
  const steps = [];
  if (!scenarioState.scenarios[to[ID.trainStage3]]) {
    steps.push({
      type: MapPinType.blueSteps,
      point: PointsOfInterest.train2,
      handler: () => runScenario(to[ID.trainStage3]),
    });
  }
  if (checks.notTrainStage3Finished(scenarioState)) {
    const trainNavigatePoint = createNavigateToPointLink('поезду', PointsOfInterest.train);

    return {
      // eslint-disable-next-line max-len
      helpMessage: `{{NAME}}, скорее иди с Бабулей к ${trainNavigatePoint}, наверняка там найдутся улики, доказывающие мою невиновность!`,
      pointsHandlers: [
        ...steps,
        {
          type: MapPinType.point,
          point: PointsOfInterest.train,
          handler: () =>
            runModalScenario(
              to[ID.trainStage3Quest],
              to[ID.trainStage3Quest],
              to[ID.trainStage3Quest],
              scenarioState.scenarios,
            ),
        },
      ],
    };
  }

  // if (checks.notBridgeBlueSteps(scenarioState)) {
  //   steps.push({
  //     type: MapPinType.blueSteps,
  //     point: PointsOfInterest.bridge,
  //     handler: () => runScenario(to[ID.bridgeBlueSteps]),
  //   });
  // }

  if (checks.notInnopolicePickChecked(scenarioState)) {
    const InnopoliceNavigatePoint = createNavigateToPointLink(
      'Иннополис',
      PointsOfInterest.innopolice,
    );

    return {
      // eslint-disable-next-line max-len
      helpMessage: `Идите с Бабулей в ${InnopoliceNavigatePoint} на карте парка «Большая перемена» и найдите там Петрова.`,
      pointsHandlers: [
        ...steps,
        {
          point: PointsOfInterest.innopolice,
          handler: () =>
            runModalScenario(
              to[ID.innopolicePickCheckInfo],
              to[ID.innopolicePickCheck],
              to[ID.innopolicePickCheck],
              scenarioState.scenarios,
            ),
        },
      ],
    };
  }

  if (checks.notHersonesStepsViewed(scenarioState)) {
    steps.push({
      type: MapPinType.blueSteps,
      point: PointsOfInterest.hersonesSteps,
      handler: () => runScenario(to[ID.hersonesBlueSteps]),
    });
  }
  if (checks.notHersonesCompleted(scenarioState)) {
    const hersonesNavigatePoint = createNavigateToPointLink('Херсонес', PointsOfInterest.hersones);

    return {
      // eslint-disable-next-line max-len
      helpMessage: `Идите с Петровым и Бабулей в ${hersonesNavigatePoint} на карте парка. Ниточки расследования ведут туда...`,
      pointsHandlers: [
        ...steps,
        {
          point: PointsOfInterest.hersones,
          handler: () =>
            runModalScenario(
              to[ID.hersonesInfo],
              to[ID.hersonesQuest],
              to[ID.hersonesQuest],
              scenarioState.scenarios,
            ),
        },
      ],
    };
  }

  // if (checks.notStage3Act2StartShown(scenarioState)) {
  //   return {
  //     pointsHandlers: [],
  //     autorunScenario: { id: to[ID.stage3Act2Start], runOnSubmit: to[ID.hersonesContinue] },
  //   };
  // }

  // if (checks.notHersonesStoryFinished(scenarioState)) {
  //   return {
  //     pointsHandlers: [],
  //     autorunScenario: { id: to[ID.hersonesContinue], unsaved: true },
  //   };
  // }

  if (checks.notMoscowCityCafeFinished(scenarioState)) {
    const moscowCityNavigatePoint = createNavigateToPointLink(
      'Москва-Сити',
      PointsOfInterest.moscowCity,
    );

    return {
      // eslint-disable-next-line max-len
      helpMessage: `Иди с Петровым, Бабулей и пушистым незнакомцем в ${moscowCityNavigatePoint} на карте парка «Большая перемена». Там уже все собрались и ждут вас с неожиданным синим гостем.`,
      pointsHandlers: [
        {
          point: PointsOfInterest.moscowCity,
          handler: () =>
            runModalScenario(
              to[ID.moscowCityCafeInfo],
              to[ID.moscowCityCafe],
              to[ID.moscowCityCafe],
              scenarioState.scenarios,
            ),
        },
      ],
    };
  }

  if (checks.notInnopoliceStoryFinished(scenarioState)) {
    const innopoliceNavigatePoint = createNavigateToPointLink(
      'Иннополис',
      PointsOfInterest.innopolice,
    );

    return {
      helpMessage: `Иди вместе со всеми в ${innopoliceNavigatePoint} на карте «Большой перемены».`,
      pointsHandlers: [
        {
          point: PointsOfInterest.innopolice,
          handler: () =>
            runModalScenario(
              to[ID.innopoliceContinueStoryInfo],
              to[ID.innopoliceContinueStory],
              to[ID.innopoliceContinueStory],
              scenarioState.scenarios,
            ),
        },
      ],
    };
  }

  if (checks.notSpaceStoryFinished(scenarioState)) {
    const spaceNavigatePoint = createNavigateToPointLink('Космодрому', PointsOfInterest.space);

    return {
      helpMessage: `Кажется, вам всем вместе нужно пройти к ${spaceNavigatePoint} в парке.`,
      pointsHandlers: [
        {
          point: PointsOfInterest.space,
          handler: () =>
            runModalScenario(
              to[ID.spaceContinueStoryInfo],
              to[ID.spaceContinueStory],
              to[ID.spaceContinueStory],
              scenarioState.scenarios,
            ),
        },
      ],
    };
  }

  if (checks.notTrainStoryFinished(scenarioState)) {
    const trainNavigatePoint = createNavigateToPointLink(
      'поезд «Большой перемены»',
      PointsOfInterest.train,
    );

    return {
      helpMessage: `Кажется, Синий зовет всех в ${trainNavigatePoint}, чтобы продолжить свой рассказ.`,
      pointsHandlers: [
        {
          point: PointsOfInterest.train,
          handler: () =>
            runModalScenario(
              to[ID.trainContinueStoryInfo],
              to[ID.trainContinueStory],
              to[ID.trainContinueStory],
              scenarioState.scenarios,
            ),
        },
      ],
    };
  }

  if (checks.notPatriotStopHandleCompleted(scenarioState)) {
    const patriotNavigatePoint = createNavigateToPointLink(
      'парк «Патриот»',
      PointsOfInterest.patriot,
    );

    return {
      helpMessage: `Иди вместе со всеми в ${patriotNavigatePoint} на карте «Большой перемены».`,
      pointsHandlers: [
        {
          point: PointsOfInterest.patriot,
          handler: () =>
            runModalScenario(
              to[ID.patriotInfo],
              to[ID.patriotStopHandle],
              to[ID.patriotStopHandle],
              scenarioState.scenarios,
            ),
        },
      ],
    };
  }

  // /**
  //  * заглушка
  //  */

  // return {
  //   pointsHandlers: [],
  //   autorunScenario: {
  //     id: to[ID.stop_1],
  //     unsaved: false,
  //   },
  // };

  // здесь должна быть заглушка

  if (checks.notTrainHandleRepaired(scenarioState)) {
    const trainNavigatePoint = createNavigateToPointLink('поезд', PointsOfInterest.train);

    return {
      helpMessage: `Кажется, вы вот-вот сможете починить ${trainNavigatePoint}! Беги скорее к нему на карте парка.`,
      pointsHandlers: [
        {
          point: PointsOfInterest.train,
          handler: () =>
            runModalScenario(
              to[ID.trainRepairHandleInfo],
              to[ID.trainRepairHandle],
              to[ID.trainRepairHandle],
              scenarioState.scenarios,
            ),
        },
      ],
    };
  }

  if (checks.notArchCompleted(scenarioState)) {
    const archNavigatePoint = createNavigateToPointLink('Триумфальной арке', PointsOfInterest.arch);

    return {
      // eslint-disable-next-line max-len
      helpMessage: `Пойдем к ${archNavigatePoint} с надписью «Большая перемена» в самой западной части карты. Это и есть главный вход в наш парк.`,
      pointsHandlers: [
        {
          point: PointsOfInterest.arch,
          handler: () =>
            runModalScenario(
              to[ID.archInfo],
              to[ID.archQuest],
              to[ID.archQuest],
              scenarioState.scenarios,
            ),
        },
      ],
    };
  }

  if (checks.notInnopoliceStage3Act2FinalCompleted(scenarioState)) {
    const InnopoliceNavigatePoint = createNavigateToPointLink(
      'Иннополис',
      PointsOfInterest.innopolice,
    );

    return {
      // eslint-disable-next-line max-len
      helpMessage: `Нам следует отправиться в ${InnopoliceNavigatePoint} парка «Большая перемена». Туда скоро придет София, чтобы забрать Малевича домой.`,
      pointsHandlers: [
        {
          point: PointsOfInterest.innopolice,
          handler: () =>
            runModalScenario(
              to[ID.innopoliceStage3Act2FinalInfo],
              to[ID.innopoliceStage3Act2Final],
              to[ID.innopoliceStage3Act2Final],
              scenarioState.scenarios,
            ),
        },
      ],
    };
  }

  if (checks.notStartStage3Act3(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.startStage3Act3], runOnSubmit: to[ID.welcomeSofia] },
    };
  }
  if (checks.notWelcomeSofia(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.welcomeSofia], unsaved: true },
    };
  }
  if (checks.notEntrepreneurDucksInfo(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.entrepreneurDucksInfo], runOnSubmit: to[ID.entrepreneurDucks] },
    };
  }
  if (checks.notEntrepreneurDucks(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.entrepreneurDucks], unsaved: true },
    };
  }
  // if (checks.notAboutInfo(scenarioState)) {
  //   return {
  //     pointsHandlers: [],
  //     autorunScenario: { id: to[ID.aboutInfo] },
  //   };
  // }
  // if (checks.notWaitShown(scenarioState)) {
  //   return {
  //     pointsHandlers: [],
  //     autorunScenario: { id: to[ID.waitTo21may] },
  //   };
  // }
  if (checks.notChallengesAnnounce(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.challengesAnnounce], runOnSubmit: to[ID.aboutChallenges] },
    };
  }
  if (checks.notAboutChallenges(scenarioState)) {
    return {
      pointsHandlers: [],
      autorunScenario: { id: to[ID.aboutChallenges], unsaved: true },
    };
  }

  // ОТКРЫТЬ ПОТОМ

  // (появляется после того, как игрок узнал инфу про вызовы, но ещё не прислал выполненные задания)
  // let challengesCompleted = false;
  // if (userState.challenges) {
  //   const challenges = Object.keys(userState.challenges);
  //   for (let i = 0; i < challenges.length; i++) {
  //     const challengeKey = challenges[i] as ChallengeKey;
  //     const challenge = userState.challenges[challengeKey];
  //     if (challenge && challenge.urlDoc && challenge.urlPost) {
  //       challengesCompleted = true;
  //     }
  //   }
  // }
  // if (!(userState.rightThingHistory && challengesCompleted)) {
  //   return {
  //     helpMessage:
  //       'Мы с обитателями парка ждем твои выполненные задания. Их ты можешь найти в своем профиле.',
  //     pointsHandlers: [],
  //   };
  // }

  // if (checks.notFinish(scenarioState)) {
  //   return {
  //     pointsHandlers: [],
  //     autorunScenario: { id: to[ID.finish] },
  //   };
  // }

  return { pointsHandlers: [] };
}
