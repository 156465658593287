/* eslint-disable max-len */
import {
  Conditions,
  Interlocutors,
  Scenario,
  ScenarioType,
  SceneId,
  StepType,
  TestStep,
} from '../../../types/scenario';
import interlocutors from '../../interlocutors';
import { splitStep } from '../../../utils/ScenarioUtils';

const EXIT_ID = 'EXIT';
const TO_MAP_STEP = 'TO_MAP_STEP';

const person = Interlocutors.GRANDMOTHER;

export const scenario_1_17_0: Scenario = {
  scenarioId: SceneId.scenario_1_17_0,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: 'Природные достояния России',
    person: Interlocutors.SYSTEM,
    text: `Вы подходите к достопримечательности «Природные достояния России» и полной грудью вдыхаете прекрасный свежий воздух! 

На берегу озера стоит задорная старушка и кормит уток. Вы начинаете с ней разговор...`,
    buttons: ['Начать'],
  },
};

const TEST_TVOI_VECTOR: TestStep = {
  maxAnswers: 2,
  external_id: 'test-tvoi-vektor-junior-210401',
  id: 'TEST_TVOI_VECTOR',
  name: 'Твой вектор 2/3',
  nextStep: 'Q17/0',
  order: [
    {
      description:
        'Хорошо, когда есть увлечения, они наполняют жизнь красками. Я вот люблю ухаживать за цветами, играть с внуками и разгадывать детективные загадки. А ты?',
      subtest: 'T1',
      question: 'Q1',
    },
    {
      description:
        'Знаешь, {{NAME}}, у тебя ведь сейчас очень интересный возраст. Тебе уже достаточно лет, чтобы начать задумываться о своем будущем, о том, как ты хочешь прожить свою жизнь. И при этом, у тебя впереди много времени, чтобы осуществить все свои задумки и желания.',
      subtest: 'T1',
      question: 'Q9',
    },
    {
      description: 'Хорошо, пойдем дальше.',
      subtest: 'T1',
      question: 'Q20',
    },
    {
      description: '{{NAME}}, а как у тебя дела со школой?',
      subtest: 'T1',
      question: 'Q27',
    },
    {
      description: 'Ты знаешь, что важно не только хорошо трудиться, но и хорошо отдыхать?',
      subtest: 'T1',
      question: 'Q28',
    },
    {
      description: '{{NAME}}, последний вопрос и закончим.',
      subtest: 'T1',
      question: 'Q36',
    },
  ],
  path: 'test-tvoi-vektor-junior-210401.json',
  person,
  type: StepType.test,
};

export const scenario_1_17: Scenario = {
  isRequired: true,
  scenarioId: SceneId.scenario_1_17,
  type: ScenarioType.chat,
  data: {
    title: 'Природные достояния России',
    scene_id: 'scenario_1_17',
    startId: 'Q1/0',
    steps: {
      ...splitStep({
        text: `Здравствуй, {{NAME}}! Меня зовут Бабуля 👵

Я уже давно живу в парке, наверное, одна из первых, кто сюда перебрался. Здесь такой прекрасный, чистый воздух и такие замечательные объекты! Были, конечно, до того как не пришел этот странный туман и все не испортил. 

Раньше сюда каждое лето приезжали счастливые ребятишки на большом блестящем поезде! Но из-за этого тумана ПОЕЗД СЛОМАЛСЯ и больше никто не приезжает! Эх, грустно видеть парк в таком состоянии 🙁

Ну да ничего, ты ведь здесь! 😊`,
        id: 'Q1',
        type: StepType.question,
        maxAnswers: 1,
        person,
        buttons: [
          {
            id: 'b1',
            text: 'Приятно познакомиться!',
            nextStep: 'Q2/0',
          },
          {
            id: 'b2',
            text: 'Конечно, вместе мы все исправим!',
            nextStep: 'Q2/0',
          },
        ],
      }),
      ...splitStep({
        type: StepType.message,
        id: 'Q2',
        person,
        text: `{{NAME}}, я уже столько про тебя слышала и так ждала встречи! Но вот беда, сегодня по невнимательности надела не те очки и совсем не могу разглядеть тебя 😣

Опиши себя, пожалуйста, чтобы я хоть представила, как ты выглядишь.`,
        nextStep: 'Q3',
      }),
      Q3: {
        person,
        id: 'Q3',
        type: StepType.question,
        maxAnswers: 1,
        text: 'Зайди в свой профиль и создай там персонажа 😊',
        callToAction: 'Сделай и возвращайся',
        buttons: [
          {
            id: 'b1',
            text: 'Готово',
            nextStep: 'Q3/1',
          },
        ],
      },
      'Q3/1': {
        type: StepType.system,
        condition: {
          type: Conditions.IS_AVATAR_CREATED,
          variants: ['Q5', 'Q4'],
        },
        id: 'Q3/1',
        person,
        _meta: {},
      },
      Q4: {
        text: 'Что-то я не вижу, попробуй еще раз.',
        nextStep: 'Q3',
        id: 'Q4',
        person,
        type: StepType.message,
      },
      Q5: {
        person,
        id: 'Q5',
        type: StepType.message,
        nextStep: 'Q7',
        text: 'Вижу-вижу 😊 Ох, ну какой же ты милый ребенок! 😍',
      },
      Q6: {
        person,
        id: 'Q6',
        type: StepType.message,
        nextStep: 'Q7',
        text: 'Ну ничего, еще успеется.',
      },
      Q7: {
        person,
        id: 'Q7',
        type: StepType.message,
        nextStep: 'Q8/0',
        text: 'Вообще, я наслышана о твоей помощи другим и мне она тоже не помешает.',
      },
      ...splitStep({
        id: 'Q8',
        person,
        nextStep: 'Q9',
        type: StepType.message,
        text: `Мы с тобой находимся в моем любимом месте парка. Ты наверняка знаешь, что природа нашей страны уникальна. В России находится 11 объектов Всемирного природного наследия ЮНЕСКО, а значит весь мир стремится их сохранить. 

Это природные заповедники, национальные парки, горные вершины, глубочайшие озера, ледники, бескрайние степи и даже действующие вулканы.`,
      }),
      Q9: {
        id: 'Q9',
        text: 'Например, в числе объектов ЮНЕСКО самые большие нетронутые леса Евразии — леса республики Коми. По размеру они даже немного больше Бельгии, только представь! 😲',
        person,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Ого!',
            nextStep: 'Q10',
          },
        ],
      },
      Q10: {
        id: 'Q10',
        text: 'Еще у нас есть озеро Байкал — самое глубокое на Земле и самое большое в России. Если на дно Байкала друг на друга поставить девять зданий МГУ, то шпиль последнего все равно не покажется из воды, настолько оно глубокое! 🤯',
        person,
        nextStep: 'Q11/0',
        type: StepType.message,
      },
      ...splitStep({
        id: 'Q11',
        text: `А ты знаешь об уникальном полуострове Камчатка? Он напоминает торт со свечками: так много там действующих вулканов! 🌋

Ну и, конечно же, нельзя не сказать об Эльбрусе — самой высокой горной вершине России и Европы. Она выше, чем десять Останкинских телебашен! Покорить Эльбрус стремятся альпинисты со всего мира, но это очень опасно. Подготовка к восхождению может занять долгие месяцы, а то и годы! 🤭

Ты бы [[хотел/хотела]] забраться на Эльбрус?`,
        person,
        type: StepType.question,
        maxAnswers: 1,
        callToAction: 'Что скажешь?',
        buttons: [
          {
            id: 'b1',
            text: 'Не знаю, не [[уверен/уверена]]',
            nextStep: 'Q12',
          },
          {
            id: 'b2',
            text: 'Да, это ведь очень интересное приключение!',
            nextStep: 'Q13',
          },
        ],
      }),
      Q12: {
        id: 'Q12',
        person,
        type: StepType.message,
        nextStep: 'Q14',
        text: 'Понимаю тебя. Это действительно очень непросто 😊',
      },
      Q13: {
        id: 'Q13',
        person,
        type: StepType.message,
        nextStep: 'Q14',
        text: 'Ох, ты действительно очень [[храбрый/храбрая]]! Вжух не соврал! 😍',
      },
      Q14: {
        id: 'Q14',
        person,
        text: 'В общем, раньше в этом месте было так красиво, а сейчас грустно смотреть… Ни цветов, ни птиц, даже деревьев нет 😭 ',
        callToAction: 'Ты же поможешь мне, да?',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Конечно, давайте начинать',
            nextStep: 'Q15',
          },
          {
            id: 'b2',
            text: 'Нет, извините, сейчас не могу',
            nextStep: TO_MAP_STEP,
          },
        ],
      },
      Q15: {
        id: 'Q15',
        text: 'Это замечательно 😊 А пока мы тут наводим порядок, ответь на несколько вопросов, пожалуйста, удовлетвори любопытство старушки.',
        person,
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            text: 'Хорошо',
            nextStep: 'Q16',
          },
        ],
      },
      Q16: {
        id: 'Q16',
        person,
        text: 'Выбирай не больше двух вариантов ответа из предложенных.',
        type: StepType.question,
        maxAnswers: 1,
        buttons: [
          {
            id: 'b1',
            nextStep: TEST_TVOI_VECTOR.id,
            text: 'Да, понятно',
          },
        ],
      },
      TEST_TVOI_VECTOR,
      ...splitStep({
        id: 'Q17',
        text: `Как незаметно пролетело время за интересной беседой! Вот мы с тобой и справились 😊

Теперь совсем другой вид. Будет, где душой отдохнуть. 

Ну, беги дальше, твоя помощь еще много где нужна. До встречи!`,
        person,
        nextStep: EXIT_ID,
        type: StepType.message,
      }),
      [EXIT_ID]: {
        person,
        id: EXIT_ID,
        type: StepType.exit,
      },
      [TO_MAP_STEP]: {
        person,
        id: TO_MAP_STEP,
        type: StepType.to_map_step,
      },
    },
  },
};

export const scenario_1_17_1: Scenario = {
  scenarioId: SceneId.scenario_1_17_1,
  type: ScenarioType.modal,
  data: {
    closable: false,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `{{NAME}}, вот и обещанный сюрприз! 🎉

Мы подготовили твой личный кабинет. Ты можешь создать своего персонажа и посмотреть предметы, которые были собраны в игре! 😺`,
    buttons: ['Далее'],
  },
};

export const scenario_1_17_2: Scenario = {
  scenarioId: SceneId.scenario_1_17_2,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.CAT].name,
    person: Interlocutors.CAT,
    text: `Ну, чего ты ждешь? Скорее делай персонажа! 😺`,
    buttons: ['Создать персонажа'],
  },
};

export const scenario_1_17_3: Scenario = {
  scenarioId: SceneId.scenario_1_17_3,
  type: ScenarioType.modal,
  data: {
    closable: true,
    title: interlocutors[Interlocutors.GRANDMOTHER].name,
    person: Interlocutors.GRANDMOTHER,
    text: `{{NAME}}, у меня тут пока все под контролем, не переживай. Помоги пока кому-нибудь еще.`,
    buttons: ['Закрыть'],
  },
};
