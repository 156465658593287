import { createSlice } from '@reduxjs/toolkit';
import { AppState, windowSizes } from './types';

const initialState: AppState = {
  shared: {},
  viewport: windowSizes.smallphone,

  isShowMobileMenu: false,
  isShowVideoAttachmentModal: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setViewport: (state, action) => {
      state.viewport = action.payload;
    },

    setShowMobileMenu: (state, action) => {
      state.isShowMobileMenu = Boolean(action.payload);
    },

    setShowVideoAttachmentModal: (state, action) => {
      state.isShowVideoAttachmentModal = Boolean(action.payload);
    },

    share: (state, action) => {
      state.shared = { ...state.shared, ...action.payload };
    },
  },
});

export default appSlice;
