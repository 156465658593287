import styled from 'styled-components';
import colors from '../../../../common/shared/constants/colors';
import { IChatAnswerStyled } from '../../chat/ChatAnswer/ChatAnswerTypes';
import {
  BREAKPOINT_PHONE,
  BREAKPOINT_TABLET,
  GAP,
} from '../../../../common/shared/constants/constants';

export const StyledInputToolbarWrapper = styled.div`
  padding: 16px 24px;
  border-radius: 16px;
  background: ${colors.grey4};
`;

export const StyledInputToolbar = styled.div<IChatAnswerStyled>`
  position: relative;

  padding: 0 14px 20px;

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    padding: 0 20px 20px;
  }

  @media screen and (min-width: ${BREAKPOINT_TABLET}) {
    padding: 0 30px 20px;
  }

  &:before {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-100%);
    width: 100%;
    height: 20px;

    pointer-events: none;
    content: '';
    display: block;
    background: linear-gradient(360deg, #ffffff 15%, rgba(255, 255, 255, 0.0001) 100%);

    @media screen and (min-width: ${BREAKPOINT_PHONE}) {
      height: 72px;
    }

    @media screen and (min-width: ${BREAKPOINT_TABLET}) {
      height: 92px;
    }
  }
`;

export const ChatTextAreaStyled = styled.textarea`
  width: 100%;
  background: none;
  border: none;
  resize: none;
  outline: none;
  max-height: 150px;
  font-family: inherit;
`;

export const ChatRadioStyled = styled.button<IChatAnswerStyled>`
  width: 100%;
  background: ${colors.white};
  border: 2px solid;
  text-align: left;
  outline: none;
  border-radius: ${GAP.m};
  margin: ${GAP.xxxs} 0;
  border-color: ${({ $current }) => ($current ? colors.blueMessage : colors.white)};
  padding: ${GAP.xs} ${GAP.ss};

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    padding: ${GAP.s} ${GAP.l};
  }
`;

export const ChatButtonStyled = styled.div`
  &:not(:first-child) {
    margin-top: 15px;

    @media screen and (min-width: ${BREAKPOINT_PHONE}) {
      margin-top: ${GAP.m};
    }
  }
`;
