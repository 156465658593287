/* eslint-disable max-len */
import { Interlocutors, Scenario, ScenarioType, SceneId } from '../../types/scenario';

export const scenario_unsaved: Scenario = {
  scenarioId: SceneId.scenario_unsaved,
  type: ScenarioType.modal,
  data: {
    closable: false,
    person: Interlocutors.SYSTEM,
    title: 'Внимание',
    text: `Вы не завершили выполнение задания`,
    buttons: ['Продолжить'],
  },
};
