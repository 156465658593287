import styled from 'styled-components';

import { BaseProps } from '../../../common/shared/styles/styledBase';
import { BoxStyled } from '../../../common/shared/ui/Box';
import { TextStyled } from '../../../common/shared/ui/Text';

import { BREAKPOINT_PHONE, BREAKPOINT_TABLET } from '../../../common/shared/constants/constants';

export const MapModalButtonsStyled = styled(BoxStyled)`
  margin-top: 8px;
  margin-left: -12px;

  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-top: 12px;
    margin-left: 12px;
  }

  @media screen and (max-width: ${BREAKPOINT_PHONE}) {
    & > * > div {
      max-width: 240px;
    }
  }

  @media screen and (min-width: ${BREAKPOINT_TABLET}) {
    margin-top: 18px;
  }
`;

export const MapModalTitleStyled = styled(TextStyled)<BaseProps>`
  max-width: calc(100% - 140px);
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    min-height: 120px;
  }

  @media screen and (min-width: ${BREAKPOINT_TABLET}) {
    max-width: calc(100% - 140px);
  }
`;

export const MapModalTextStyled = styled(TextStyled)<BaseProps>`
  overflow: auto;
`;

export const MapModalImgStyled = styled.img`
  position: absolute;
  bottom: 0;
  height: 120px;
  right: -20px;

  @media screen and (min-width: ${BREAKPOINT_PHONE}) {
    bottom: 0;
    right: -50px;
    height: 250px;
  }

  @media screen and (min-width: ${BREAKPOINT_TABLET}) {
    right: -50px;
  }
`;
