interface Image {
  uri: string;
  title: string;
}

export enum Interlocutors {
  'ME' = 'ME',
  'CAT' = 'CAT',
  'BLUE_CAT' = 'BLUE_CAT',
  'TEACHER' = 'TEACHER',
  'SOFIA' = 'SOFIA',
  'PETROV' = 'PETROV',
  'ENTREPRENEUR' = 'ENTREPRENEUR',
  'SYSTEM' = 'SYSTEM',
  'AI' = 'AI',
  'GRANDMOTHER' = 'GRANDMOTHER',
  'GUIDE' = 'GUIDE',
  'ALIEN' = 'ALIEN',
  'LIFT' = 'LIFT',
  'ALIENSHIP' = 'ALIENSHIP',
}

interface Normalized<T> {
  items: { [key: string]: T };
  order: string[];
}
export interface QuestionJSON {
  text: string;
  type: 'multiple' | 'multiple-slider' | 'single';
  image?: string;
  answers: {
    [key: string]: {
      text: string;
    };
  };
}
interface SubtestJSON {
  questions: Normalized<QuestionJSON>;
}

export interface TestJSON {
  subtests: Normalized<SubtestJSON>;
}

export enum Action {
  GiveBand,
  GiveBook,
  GivePick,
  Lost3dPen,
  LostPick,
  Give3dPen,
  LostRope,
  GiveStop,
  LostStop,
}

export enum Conditions {
  IS_VIDEO_ATTACHED = 'IS_VIDEO_ATTACHED',
  // IS_LINK_ATTACHED = 'IS_LINK_ATTACHED',
  IS_LINK_VALID = 'IS_LINK_VALID',
  IS_LAST_ANSWERS_VALID = 'IS_LAST_ANSWERS_VALID',
  IS_ABOUT_MYSELF_VALID = 'IS_ABOUT_MYSELF_VALID',
  IS_COM_TEST_STEP3_VALID = 'IS_COM_TEST_STEP3_VALID',
  IS_COM_TEST_STEP4_VALID = 'IS_COM_TEST_STEP4_VALID',
  IS_AVATAR_CREATED = 'IS_AVATAR_CREATED',
  IS_HELP_WITH_ENTREPRENEUR = 'IS_HELP_WITH_ENTREPRENEUR',
  IS_LOGIC_PART1_HAS_ANSWERS = 'IS_LOGIC_PART1_HAS_ANSWERS',
  HAS_COINS = 'HAS_COINS',
  HAS_ROPE = 'HAS_ROPE',
  IS_LOGIC_PART2_HAS_ANSWERS = 'IS_LOGIC_PART2_HAS_ANSWERS',

  // переменная для пароля в иннополис
  IS_PASSWORD_IN_INNOPOLICE_VALID = 'IS_PASSWORD_IN_INNOPOLICE_VALID',
}

export interface StepCondition {
  type: Conditions;
  // Если true то step из первого шага, Если false то второй
  variants: [string, string];
}

export type Step =
  | SystemStep
  | Message
  | TestStep
  | Question
  | TextQuestion
  | ExitStep
  | ToMapStep
  | ToLKStep
  | AnswerStep;

export enum StepType {
  message = 'message',
  question = 'question', // будут вопросы с кнопками
  text_question = 'text_question', // будут вопросы на которые потребует ответ текстом textarea
  test = 'test',
  exit = 'exit', // в тулбар только кнопка закрыть диалог
  answer = 'answer',
  system = 'system',
  to_map_step = 'to_map_step',
  to_lk_step = 'to_lk_step',
}

export enum SceneId {
  // Остановки
  'scenario_stop_1' = 'scenario_stop_1',

  'scenario_blanks' = 'scenario_blanks',

  // ЕСЛИ НЕ ЗАВЕРШИЛИ СЦЕНАРИЙ
  'scenario_unsaved' = 'scenario_unsaved',

  // ЗАПУСК КАРТЫ, КОГДА НЕ СОХРАРНЕН НИ ОДИН СЦЕНАРИЙ
  'scenario_0' = 'scenario_0',

  // ПЕРЕВОД ПО КНОПКЕ ИЗ МОДАЛКИ ПРЕДЫДУЩЕГО СЦЕНАРИЯ
  'scenario_1_1' = 'scenario_1_1',

  // ПОСЛЕ ВЫХОДА ИЗ ПРЕДЫДУЩЕГО СЦЕНАРИЯ
  'scenario_1_2' = 'scenario_1_2',

  // БОЛЬШОЕ ТЕСТИРОВАНИЕ
  'scenario_1_3_1' = 'scenario_1_3_1',

  'scenario_1_3' = 'scenario_1_3',

  // ПРИ ПОЯВЛЕНИИ НОВЫХ СЦЕНАРИЕВ
  'scenario_1_4' = 'scenario_1_4',

  // ЕСЛИ КЛИКАЕМ НА ЗАКРЫТУЮ ЛОКАЦИЮ
  'scenario_1_5_1' = 'scenario_1_5_1',
  'scenario_1_5_2' = 'scenario_1_5_2',
  'scenario_1_5_3' = 'scenario_1_5_3',

  // УЧИТЕЛЬ (МГУ)    2 ЧАСТЬ
  'scenario_1_7_0' = 'scenario_1_7_0',
  'scenario_1_7' = 'scenario_1_7',
  'scenario_1_7_1' = 'scenario_1_7_1',

  // БАЙКАЛ - БАБУЛЯ - ОЗЕРО   3 ЧАСТЬ
  'lake_1_1_0' = 'lake_1_1_0',
  'lake_1_1_1' = 'lake_1_1_1',

  'lake_1_1' = 'lake_1_1',

  'lake_1_2' = 'lake_1_2',
  'lake_1_3' = 'lake_1_3',
  'lake_1_4' = 'lake_1_4',

  // ИННОПОЛИС    4 ЧАСТЬ

  'innopolice_1_1_0' = 'innopolice_1_1_0',
  'innopolice_1_1_1' = 'innopolice_1_1_1',

  'innopolice_1_1' = 'innopolice_1_1',

  // ЭРМИТАЖ    5 ЧАСТЬ

  'hermitage_1_1_0' = 'hermitage_1_1_0',
  'hermitage_1_1_1' = 'hermitage_1_1_1',
  'hermitage_1_1' = 'hermitage_1_1',

  // ОЗЕРО - ВОЗВРАЩЕНИЕ К БАБУЛЕ   6 ЧАСТЬ

  'lakeComeback_1_1_0' = 'lakeComeback_1_1_0',
  'lakeComeback_1_1_1' = 'lakeComeback_1_1_1',
  'lakeComeback_1_1' = 'lakeComeback_1_1',

  // ЭРМИТАЖ - КОРМЛЕНИЕ МАЛЕВИЧА    7 ЧАСТЬ

  'hermitageFeeding_1_1_0' = 'hermitageFeeding_1_1_0',
  'hermitageFeeding_1_1' = 'hermitageFeeding_1_1',

  // ОЛИМПИЙСКИЙ ПАРК    8 ЧАСТЬ

  'olympicPark_1_1_0' = 'olympicPark_1_1_0',
  'olympicPark_1_1_1' = 'olympicPark_1_1_1',
  'olympicPark_1_1' = 'olympicPark_1_1',

  // ЗНАКОМСТВО С ИНОПЛАНЕТЯНИНОМ     9 ЧАСТЬ

  'alien_1_1_0' = 'alien_1_1_0',
  'alien_1_1_1' = 'alien_1_1_1',
  'alien_1_1' = 'alien_1_1',

  // МОСКВА - СИТИ      10 ЧАСТЬ

  'moscowCity_1_1_0' = 'moscowCity_1_1_0',
  'moscowCity_1_1_1' = 'moscowCity_1_1_1',
  'moscowCity_1_1' = 'moscowCity_1_1',

  // МГУ - ДИКТОФОН      11 ЧАТСТЬ

  'mguDictaphone_1_1_0' = 'mguDictaphone_1_1_0',
  'mguDictaphone_1_1_1' = 'mguDictaphone_1_1_1',
  'mguDictaphone_1_1' = 'mguDictaphone_1_1',

  // // МОСКВА СИТИ ЗНАКОМСТВО С САН САНЫЧОМ    12 ЧАСТЬ

  'oboutMoscow_1_1_0' = 'oboutMoscow_1_1_0',
  'oboutMoscow_1_1' = 'oboutMoscow_1_1',

  // // ИННОПОЛОЛИС - ПРО ИННОПОЛИС     13 ЧАСТЬ

  'oboutInnopolice_1_1_0' = 'oboutInnopolice_1_1_0',
  'oboutInnopolice_1_1_1' = 'oboutInnopolice_1_1_1',
  'oboutInnopolice_1_1' = 'oboutInnopolice_1_1',

  // ВТОРОЙ ЭТАП

  // ИНТРО

  'stg2_intro_1_1_0' = 'stg2_intro_1_1_0',
  'stg2_intro_1_1' = 'stg2_intro_1_1',

  // ИННОПОЛИС    1 ЧАСТЬ

  'stg2_innopolice_1_1_0' = 'stg2_innopolice_1_1_0',
  'stg2_innopolice_1_1' = 'stg2_innopolice_1_1',

  // МГУ        2 ЧАСТЬ

  'stg2_mgu_1_1_0' = 'stg2_mgu_1_1_0',
  'stg2_mgu_1_1_1' = 'stg2_mgu_1_1_1',
  'stg2_mgu_1_1' = 'stg2_mgu_1_1',
  'stg2_mgu_1_2' = 'stg2_mgu_1_2',

  // Байкал        3 ЧАСТЬ

  'stg2_lake_1_1_1' = 'stg2_lake_1_1_1',
  'stg2_lake_1_1' = 'stg2_lake_1_1',

  // Эрмитаж        4 ЧАСТЬ

  'stg2_hermitage_1_1_1' = 'stg2_hermitage_1_1_1',
  'stg2_hermitage_1_1' = 'stg2_hermitage_1_1',

  // Олимпийский парк        4 ЧАСТЬ

  'stg2_olympicPark_1_1_1' = 'stg2_olympicPark_1_1_1',
  'stg2_olympicPark_1_1' = 'stg2_olympicPark_1_1',

  // Москва Сити       5 ЧАСТЬ

  'stg2_moscowCity_1_1_1' = 'stg2_moscowCity_1_1_1',
  'stg2_moscowCity_1_1' = 'stg2_moscowCity_1_1',

  // МГУ          6 ЧАСТЬ

  'stg2_mguQuiz_1_1_0' = 'stg2_mguQuiz_1_1_0',
  'stg2_mguQuiz_1_1_1' = 'stg2_mguQuiz_1_1_1',
  'stg2_mguQuiz_1_1' = 'stg2_mguQuiz_1_1',

  // ИННОПОЛИС ПЕТРОВ          7 ЧАСТЬ

  'stg2_innopolicePetrov_1_1_1' = 'stg2_innopolicePetrov_1_1_1',
  'stg2_innopolicePetrov_1_1_0' = 'stg2_innopolicePetrov_1_1_0',
  'stg2_innopolicePetrov_1_1' = 'stg2_innopolicePetrov_1_1',

  // БАЙКАЛ ЗАКАЗ ОТ БАБУШКИ

  'stg2_lakeOrderFromGranny_1_1_0' = 'stg2_lakeOrderFromGranny_1_1_0',
  'stg2_lakeOrderFromGranny_1_1_1' = 'stg2_lakeOrderFromGranny_1_1_1',
  'stg2_lakeOrderFromGranny_1_1' = 'stg2_lakeOrderFromGranny_1_1',

  // МАМАЕВ КУРГАН Инопланетянин найден

  'stg2_kurganAlienFound_1_1_0' = 'stg2_kurganAlienFound_1_1_0',
  'stg2_kurganAlienFound_1_1_1' = 'stg2_kurganAlienFound_1_1_1',
  'stg2_kurganAlienFound_1_1' = 'stg2_kurganAlienFound_1_1',

  // МОСКВА СИТИ

  'stg2_moscowCityMeetingGranny_1_1_1' = 'stg2_moscowCityMeetingGranny_1_1_1',
  'stg2_moscowCityMeetingGranny_1_1' = 'stg2_moscowCityMeetingGranny_1_1',

  // ОСТАНКИНСКАЯ БАШНЯ

  'stg2_ostankinoAlienInvasion_1_1_0' = 'stg2_ostankinoAlienInvasion_1_1_0',
  'stg2_ostankinoAlienInvasion_1_1_1' = 'stg2_ostankinoAlienInvasion_1_1_1',
  'stg2_ostankinoAlienInvasion_1_1' = 'stg2_ostankinoAlienInvasion_1_1',

  // 3 ЭТАП

  // ОСТАНКИНКАЯ БАШНЯ

  'stg3_ostankino_1_1_1' = 'stg3_ostankino_1_1_1',
  'stg3_ostankino_1_1' = 'stg3_ostankino_1_1',

  // КОСМОДРОМ

  'stg3_space_1_1_0' = 'stg3_space_1_1_0',
  'stg3_space_1_1_0_i' = 'stg3_space_1_1_0_i',
  'stg3_space_1_1_1' = 'stg3_space_1_1_1',
  'stg3_space_1_1' = 'stg3_space_1_1',

  // ИННОПОЛИС

  'stg3_innopolice_1_1_1' = 'stg3_innopolice_1_1_1',
  'stg3_innopolice_1_1' = 'stg3_innopolice_1_1',

  // КОСМОДРОМ

  'stg3_spaceAlienShip_1_1_1' = 'stg3_spaceAlienShip_1_1_1',
  'stg3_spaceAlienShip_1_1' = 'stg3_spaceAlienShip_1_1',

  // ЭРМИТАЖ

  'stg3_hermitage_1_1_1' = 'stg3_hermitage_1_1_1',
  'stg3_hermitage_1_1' = 'stg3_hermitage_1_1',

  // ВСТРЕЧА С ЖЕНЕЙ

  'stg3_meetingWithZhenya_1_1_0' = 'stg3_meetingWithZhenya_1_1_0',
  'stg3_meetingWithZhenya_1_1' = 'stg3_meetingWithZhenya_1_1',

  // ХЕРСОНЕС

  'stg3_hersones_1_1_1' = 'stg3_hersones_1_1_1',
  'stg3_hersones_1_1' = 'stg3_hersones_1_1',

  // БАЙКАЛ

  'stg3_lake_1_1_0' = 'stg3_lake_1_1_0',
  'stg3_lake_1_1_1' = 'stg3_lake_1_1_1',
  'stg3_lake_1_1' = 'stg3_lake_1_1',

  // МГУ

  'stg3_mgu_1_1_1' = 'stg3_mgu_1_1_1',
  'stg3_mgu_1_1' = 'stg3_mgu_1_1',

  // КОСМОДРОМ

  'stg3_spaceMeetingWithAliens_1_1_0' = 'stg3_spaceMeetingWithAliens_1_1_0',
  'stg3_spaceMeetingWithAliens_1_1_1' = 'stg3_spaceMeetingWithAliens_1_1_1',
  'stg3_spaceMeetingWithAliens_1_1' = 'stg3_spaceMeetingWithAliens_1_1',

  // МОСТ НА РУССКИЙ ОСТРОВ

  'stg3_bridgeRussianIsland_1_1_0' = 'stg3_bridgeRussianIsland_1_1_0',
  'stg3_bridgeRussianIsland_1_1_1' = 'stg3_bridgeRussianIsland_1_1_1',
  'stg3_bridgeRussianIsland_1_1' = 'stg3_bridgeRussianIsland_1_1',

  //////////////////////////////////

  // Петров (космодром)
  'scenario_1_8_0' = 'scenario_1_8_0',
  'scenario_1_8' = 'scenario_1_8',
  'scenario_1_8_1' = 'scenario_1_8_1',
  // знакомство с бабулей
  'scenario_1_8_4' = 'scenario_1_8_4',
  'scenario_1_8_5' = 'scenario_1_8_5',
  'scenario_1_8_6' = 'scenario_1_8_6',
  // Предприниматель (Останкино)
  'scenario_1_9_0' = 'scenario_1_9_0',
  'scenario_1_9' = 'scenario_1_9',
  'scenario_1_9_1' = 'scenario_1_9_1',
  'scenario_1_9_2' = 'scenario_1_9_2',
  // финал
  'scenario_1_10' = 'scenario_1_10',
  // запуск 16 числа
  'scenario_1_14_0' = 'scenario_1_14_0',
  'scenario_1_14' = 'scenario_1_14',
  'scenario_1_14_1' = 'scenario_1_14_1',
  // Байкал - Бабуля
  'scenario_1_17_0' = 'scenario_1_17_0',
  'scenario_1_17' = 'scenario_1_17',
  'scenario_1_17_3' = 'scenario_1_17_3',
  // ЛК
  'scenario_1_17_1' = 'scenario_1_17_1',
  'scenario_1_17_2' = 'scenario_1_17_2',
  // Олимпийский парк
  'scenario_1_18_0' = 'scenario_1_18_0',
  'scenario_1_18' = 'scenario_1_18',
  'scenario_1_18_1' = 'scenario_1_18_1',
  // МГУ
  'scenario_1_19' = 'scenario_1_19',
  'scenario_1_19_1' = 'scenario_1_19_1',
  // эрмитаж
  'scenario_1_19_4' = 'scenario_1_19_4',
  'scenario_1_19_5' = 'scenario_1_19_5',
  // МГУ (возвращение в сад)
  'scenario_1_19_6' = 'scenario_1_19_6',
  'scenario_1_19_7' = 'scenario_1_19_7',

  'scenario_1_22' = 'scenario_1_22',
  // 3 акт МГУ учитель
  'scenario_1_23' = 'scenario_1_23',
  'scenario_1_24' = 'scenario_1_24',
  // 3 акт москва-сити
  'scenario_1_24_3' = 'scenario_1_24_3',
  'scenario_1_24_4' = 'scenario_1_24_4',
  // 3 акт парк патриот
  'scenario_1_24_5' = 'scenario_1_24_5',
  'scenario_1_24_6' = 'scenario_1_24_6',

  'scenario_1_25_0' = 'scenario_1_25_0',
  'scenario_1_25' = 'scenario_1_25',
  'scenario_1_25_1' = 'scenario_1_25_1',
  'scenario_1_25_2' = 'scenario_1_25_2',
  'scenario_1_25_4' = 'scenario_1_25_4',
  'scenario_1_26_1' = 'scenario_1_26_1',
  'scenario_1_26_2' = 'scenario_1_26_2',
  'scenario_1_26_3' = 'scenario_1_26_3',
  'scenario_1_26_4' = 'scenario_1_26_4',
  'scenario_1_30' = 'scenario_1_30',

  // Второй этап
  'scenario_2_1_i' = 'scenario_2_1_i',
  'scenario_2_1_Second' = 'scenario_2_1_Second',
  'scenario_2_1' = 'scenario_2_1',
  'scenario_2_2_i' = 'scenario_2_2_i',
  'scenario_2_2' = 'scenario_2_2',
  'scenario_2_3_i' = 'scenario_2_3_i',
  'scenario_2_3' = 'scenario_2_3',

  // 'scenario_2_0' = 'scenario_2_0',
  // 'scenario_2_0_1' = 'scenario_2_0_1',
  // 'scenario_2_1' = 'scenario_2_1',
  // 'scenario_2_2' = 'scenario_2_2',
  // 'scenario_2_2_1' = 'scenario_2_2_1',
  // 'scenario_2_2_4' = 'scenario_2_2_4',
  'scenario_2_4_0' = 'scenario_2_4_0',
  'scenario_2_4_i' = 'scenario_2_4_i',
  'scenario_2_4' = 'scenario_2_4',
  'scenario_2_5_i' = 'scenario_2_5_i',
  'scenario_2_5' = 'scenario_2_5',
  'scenario_2_6' = 'scenario_2_6',

  'scenario_2_7_i' = 'scenario_2_7_i',
  'scenario_2_7' = 'scenario_2_7',
  'scenario_2_8_i' = 'scenario_2_8_i',
  'scenario_2_8' = 'scenario_2_8',
  'scenario_2_9_i' = 'scenario_2_9_i',
  'scenario_2_9' = 'scenario_2_9',

  'scenario_2_5_1' = 'scenario_2_5_1',
  'scenario_2_5_2' = 'scenario_2_5_2',
  'scenario_2_5_3' = 'scenario_2_5_3',
  'scenario_2_5_4' = 'scenario_2_5_4',
  'scenario_2_5_5' = 'scenario_2_5_5',
  'scenario_2_5_6' = 'scenario_2_5_6',
  'scenario_2_5_7' = 'scenario_2_5_7',

  //'scenario_2_9' = 'scenario_2_9',
  //'scenario_2_9_1' = 'scenario_2_9_1',
  'scenario_2_9_2' = 'scenario_2_9_2',
  'scenario_2_10_0' = 'scenario_2_10_0',
  'scenario_2_10' = 'scenario_2_10',
  'scenario_2_10_1_0' = 'scenario_2_10_1_0',
  'scenario_2_10_1' = 'scenario_2_10_1',
  'scenario_2_11_0' = 'scenario_2_11_0',
  'scenario_2_11' = 'scenario_2_11',
  'scenario_2_11_4' = 'scenario_2_11_4',
  'scenario_2_11_5' = 'scenario_2_11_5',

  'scenario_egg1_0' = 'scenario_egg1_0',
  'scenario_egg1' = 'scenario_egg1',
  'scenario_egg2_0' = 'scenario_egg2_0',

  'scenario_2_12' = 'scenario_2_12',
  'scenario_2_13' = 'scenario_2_13',
  'scenario_2_14_0' = 'scenario_2_14_0',
  'scenario_2_14' = 'scenario_2_14',
  'scenario_2_15_0' = 'scenario_2_15_0',
  'scenario_2_15' = 'scenario_2_15',

  'scenario_2_16' = 'scenario_2_16',
  'scenario_2_16_1' = 'scenario_2_16_1',
  'scenario_2_16_2' = 'scenario_2_16_2',
  'scenario_2_16_3' = 'scenario_2_16_3',
  'scenario_2_16_4' = 'scenario_2_16_4',
  'scenario_2_16_5' = 'scenario_2_16_5',
  'scenario_2_16_0_5' = 'scenario_2_16_0_5',
  'scenario_2_16_1_5' = 'scenario_2_16_1_5',
  'scenario_2_16_1_3' = 'scenario_2_16_1_3',

  // третий акт
  'scenario_2_20' = 'scenario_2_20',
  'scenario_2_20_1' = 'scenario_2_20_1',
  'scenario_2_21' = 'scenario_2_21',
  'scenario_2_21_1' = 'scenario_2_21_1',
  'scenario_2_22' = 'scenario_2_22',
  'scenario_2_22_1' = 'scenario_2_22_1',
  'scenario_2_23' = 'scenario_2_23',
  'scenario_2_30' = 'scenario_2_30',

  'scenario_egg3_0' = 'scenario_egg3_0',
  'scenario_egg4_0' = 'scenario_egg4_0',
  'scenario_egg4_1' = 'scenario_egg4_1',
  'scenario_egg5_0' = 'scenario_egg5_0',
  'scenario_egg6_0' = 'scenario_egg6_0',
  'scenario_egg6_1' = 'scenario_egg6_1',

  // Третий этап

  // акт 1
  'scenario_3_1_i' = 'scenario_3_1_i',
  'scenario_3_1' = 'scenario_3_1',
  'scenario_3_2_i' = 'scenario_3_2_i',
  'scenario_3_2' = 'scenario_3_2',
  'scenario_3_3_i' = 'scenario_3_3_i',
  'scenario_3_3' = 'scenario_3_3',

  // акт 2
  'scenario_3_4_i' = 'scenario_3_4_i',
  'scenario_3_4' = 'scenario_3_4',
  'scenario_3_5_i' = 'scenario_3_5_i',
  'scenario_3_5' = 'scenario_3_5',
  'scenario_3_6_i' = 'scenario_3_6_i',
  'scenario_3_6' = 'scenario_3_6',

  // акт 3
  'scenario_3_7_i' = 'scenario_3_7_i',
  'scenario_3_7' = 'scenario_3_7',

  'scenario_3_0' = 'scenario_3_0',

  'scenario_3_0_1' = 'scenario_3_0_1',
  'scenario_3_0_2' = 'scenario_3_0_2',
  'scenario_3_0_3' = 'scenario_3_0_3',
  'scenario_3_0_4' = 'scenario_3_0_4',
  'scenario_3_0_5' = 'scenario_3_0_5',
  'scenario_3_0_6' = 'scenario_3_0_6',
  'scenario_3_0_7' = 'scenario_3_0_7',
  'scenario_3_0_8' = 'scenario_3_0_8',
  'scenario_3_0_9' = 'scenario_3_0_9',
  'scenario_3_0_10' = 'scenario_3_0_10',
  'scenario_3_0_11' = 'scenario_3_0_11',
  'scenario_3_0_13' = 'scenario_3_0_12',
  'scenario_3_0_12' = 'scenario_3_0_13',
  'scenario_3_0_14' = 'scenario_3_0_14',

  'scenario_3_3_0' = 'scenario_3_3_0',
  'scenario_3_4_0' = 'scenario_3_4_0',

  // второй акт
  'scenario_3_10' = 'scenario_3_10',
  'scenario_3_11' = 'scenario_3_11',
  'scenario_3_12_0' = 'scenario_3_12_0',
  'scenario_3_12' = 'scenario_3_12',
  'scenario_3_13_0' = 'scenario_3_13_0',
  'scenario_3_13' = 'scenario_3_13',
  'scenario_3_14_0' = 'scenario_3_14_0',
  'scenario_3_14' = 'scenario_3_14',
  'scenario_3_15_0' = 'scenario_3_15_0',
  'scenario_3_15' = 'scenario_3_15',
  'scenario_3_16_0' = 'scenario_3_16_0',
  'scenario_3_16' = 'scenario_3_16',
  'scenario_3_17_0' = 'scenario_3_17_0',
  'scenario_3_17' = 'scenario_3_17',
  'scenario_3_18_0' = 'scenario_3_18_0',
  'scenario_3_18' = 'scenario_3_18',
  'scenario_3_19_0' = 'scenario_3_19_0',
  'scenario_3_19' = 'scenario_3_19',
  'scenario_3_19_1' = 'scenario_3_19_1',

  // третий акт
  'scenario_3_20' = 'scenario_3_20',
  'scenario_3_21' = 'scenario_3_21',
  'scenario_3_22' = 'scenario_3_22',
  'scenario_3_22_1' = 'scenario_3_22_1',
  'scenario_3_23_0' = 'scenario_3_23_0',
  'scenario_3_23' = 'scenario_3_23',
  'scenario_3_23_01' = 'scenario_3_23_01',
  'scenario_3_23_02' = 'scenario_3_23_02',
  'scenario_3_23_1' = 'scenario_3_23_1',
  'scenario_3_24' = 'scenario_3_24',
}

export interface StepCore {
  delay?: number; // задержка отправки в мс
  type: StepType;
  action?: Action;
  id: string;
  person: Interlocutors;
  // Если сообщение не требует действие пользователя, а сразу переход на следующий шаг
  // Например, длинное сообщение разбитое на серию мелких
  nextStep?: string;
  condition?: StepCondition;
  callToAction?: string;

  date?: string;
  image?: Image;
  timeout?: number;
  isTimerDuplicate?: boolean;
}

interface TestOrderItem {
  subtest: string;
  question: string;
  description: string;
  timeout?: number;
}

export interface ExitStep extends StepCore {
  type: StepType.exit;
}

export interface ToMapStep extends StepCore {
  type: StepType.to_map_step;
  saveHistory?: boolean;
}
export interface ToLKStep extends StepCore {
  type: StepType.to_lk_step;
}

export enum SystemStepType {
  limitedTimeInput,
  limitedTimeGroup,
}
export interface SystemStep extends StepCore {
  type: StepType.system;
  text?: string;
  // минимальное количество ответов с таким текстом. В случае если по какому-то ключу меньше - повторить вопрос
  _meta: { [key: string]: any; type?: SystemStepType };
}

export interface AnswerStep extends StepCore {
  type: StepType.answer;
  person: Interlocutors.ME;
  date: string;
  answerType: 'button' | 'input';
  data: {
    value: string | QuestionButton[];
  };
}

export interface Message extends StepCore {
  type: StepType.message;
  // если в таком шаге пустой текст и Image, то сообщение не нужно выводить в чате.
  // Это поведение используется для condition
  text: string;

  nextStep: string;
}

export interface Question extends StepCore {
  isHorizontal?: boolean;
  type: StepType.question;
  text: string;
  // если 0 то безлимит
  maxAnswers: number;
  // Для ответа с текстовым вводом нужно указать следующий шаг
  nextStep?: string;
  buttons?: QuestionButton[];
}

export interface TextQuestion extends StepCore {
  type: StepType.text_question;
  text: string;
  inputText?: string;
  nextStep: string;
  buttons?: QuestionButton[];
  variable: string;
}

export interface QuestionButton {
  id: string;
  action?: Action;
  text: string;
  // ссылка на следующий шаг для этого ответа
  nextStep: string;
}

export interface TestStep extends StepCore {
  maxAnswers?: number;
  type: StepType.test;
  external_id: string;
  name: string;
  path: string;
  order: TestOrderItem[];
  // id следующего шага обязателен для теста
  nextStep: string;
}

export interface ChatScenario {
  // Используется для определения самого первого шага в сценарии
  // Если в качестве steps использовать массив, а не объект,
  // то началом сцены будет элемент с индексом 0
  startId: string;
  // Описание всех шагов сцены - объект, ключи которого - id шага
  steps: Record<string, Step>;
  scene_id: string;
  timeoutMessage?: string;
  title?: string;
}

export interface ModalScenario {
  person: Interlocutors;
  title: string;
  text: string;
  buttons: string[];
  closable: boolean;
  described?: string;
  buttonToExitTheGame?: boolean;
}

export enum ScenarioType {
  chat = 'chat',
  modal = 'modal',
}

export type Scenario<isChatScenario extends 'ChatScenario' | 'ModalScenario' | 'Both' = 'Both'> =
  isChatScenario extends 'ChatScenario'
    ? {
        type: ScenarioType;
        scenarioId: SceneId;
        isRequired?: boolean;
        data: ChatScenario;
      }
    : isChatScenario extends 'ModalScenario'
      ? {
          type: ScenarioType;
          scenarioId: SceneId;
          isRequired?: boolean;
          data: ModalScenario;
          buttonToExitTheGame?: boolean;
        }
      : {
          type: ScenarioType;
          scenarioId: SceneId;
          isRequired?: boolean;
          data: ChatScenario | ModalScenario;
          buttonToExitTheGame?: boolean;
        };
