import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { selectActiveTestResult } from '../redux/tests/testsGetters';
import { setActiveTestResultAsync } from '../redux/tests/testsActions';
import { TestItem } from './useTestsResults';
import { TestResultData } from '../redux/app/types';

export interface Hook {
  activeTestResult?: TestResultData;
  closeActiveTestResult: () => void;
  openActiveTestResult: (data: TestItem) => void;
}

const useActiveTestResult = (): Hook => {
  const dispatch = useDispatch();
  const activeTestResult = useSelector(selectActiveTestResult);

  const closeActiveTestResult = React.useCallback(() => {
    dispatch(setActiveTestResultAsync(undefined));
  }, [dispatch]);

  const openActiveTestResult = React.useCallback(
    (data: TestItem) => {
      dispatch(setActiveTestResultAsync(data));
    },
    [dispatch],
  );

  return { activeTestResult, closeActiveTestResult, openActiveTestResult };
};

export default useActiveTestResult;
