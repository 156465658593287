/* eslint-disable max-len */
import { Interlocutors, Step, StepType } from '../../../../types/scenario';
import { splitStep } from '../../../../utils/ScenarioUtils';

const person = Interlocutors.TEACHER;

export const VICT: Record<string, Step> = {
  // 1 вопрос

  VICT2: {
    id: 'VICT2',
    text: 'В каком году был построен МГУ?',
    type: StepType.question,
    person,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: '1963',
        nextStep: 'VICT2/2/0',
      },
      {
        id: 'b2',
        text: '1953',
        nextStep: 'VICT3',
      },
      {
        id: 'b3',
        text: '1973',
        nextStep: 'VICT2/2/0',
      },
    ],
  },

  ...splitStep({
    person,
    text: `Псс… Ну ты чего! Ответ же другой. Подумай ещё.`,
    id: 'VICT2/2',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Ладно',
        nextStep: 'VICT2',
      },
    ],
  }),

  // 2 вопрос

  VICT3: {
    id: 'VICT3',
    text: 'Какая часть мировых запасов пресной воды содержится в озере Байкал?',
    type: StepType.question,
    person,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: '5%',
        nextStep: 'VICT3/2/0',
      },
      {
        id: 'b2',
        text: '20%',
        nextStep: 'VICT4',
      },
      {
        id: 'b3',
        text: '50%',
        nextStep: 'VICT3/2/0',
      },
    ],
  },

  ...splitStep({
    id: 'VICT3/2',
    person,
    text: `Даю подсказку, это неверный вариант 😉`,
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Да, припоминаю',
        nextStep: 'VICT3',
      },
    ],
  }),

  // 3 вопрос

  VICT4: {
    text: 'По какой причине Зимний Дворец так называется?',
    person,
    id: 'VICT4',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: 'Императоры жили здесь зимой, а лето проводили в загородных летних резиденциях',
        nextStep: 'VICT5',
      },
      {
        id: 'b2',
        text: 'Дворец был построен зимой',
        nextStep: 'VICT4/2/0',
      },
      {
        id: 'b3',
        text: 'Лепнина, украшающая дворец, внешне напоминает снежинки и лёд',
        nextStep: 'VICT4/2/0',
      },
    ],
  },

  ...splitStep({
    id: 'VICT4/2',
    text: `Не то. Вспомни, что Малевич рассказывал, ну?`,
    person,
    type: StepType.message,
    nextStep: 'VICT4',
  }),

  // 4 вопрос

  VICT5: {
    text: 'Следующий вопрос. В каком году проходили зимние Олимпийские игры в Сочи? ',
    person,
    id: 'VICT5',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: '2010',
        nextStep: 'VICT5/2/0',
      },
      {
        id: 'b2',
        text: '2014',
        nextStep: 'VICT6',
      },
      {
        id: 'b3',
        text: '2018',
        nextStep: 'VICT5/2/0',
      },
    ],
  },

  ...splitStep({
    id: 'VICT5/2',
    text: `Псс… Ну ты чего! Ответ же другой. Подумай ещё.`,
    person,
    type: StepType.message,
    nextStep: 'VICT5',
  }),

  // 5 вопрос

  VICT6: {
    text: 'Высота самой высокой башни в Москва-Сити составляет 374 м. Сколько этажей в этой башне?',
    person,
    id: 'VICT6',
    type: StepType.question,
    maxAnswers: 1,
    buttons: [
      {
        id: 'b1',
        text: '37',
        nextStep: 'VICT6/2/0',
      },
      {
        id: 'b2',
        text: '95',
        nextStep: 'Q2',
      },
      {
        id: 'b3',
        text: '374',
        nextStep: 'VICT6/2/0',
      },
    ],
  },

  ...splitStep({
    id: 'VICT6/2',
    text: `1 этаж где-то 4 метра высотой. Просто подели.`,
    person,
    type: StepType.message,
    nextStep: 'VICT6',
  }),
};
